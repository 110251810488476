import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ActionButton from "../../Core/ActionButton";
import SmallProductCard from "../SmallProductCard";
import SectionTitle from "../SectionTitle";
import Box from "@material-ui/core/Box";
import React, {useState} from "react";
import {getUserId} from "../../../utils";
import {useFavorites} from "../../../hooks/favorites";
import {connect} from "react-redux";
import {addMemoToCart, addProductToCart} from "../../../store/cart/actions";

const NoOrders = ({ content, openCartDrawer,context }) => {
    const [favoriteProduct, setFavoriteProduct] = useState({});
    const userId = context.Cart.spoofingUserId || getUserId();
    useFavorites(favoriteProduct, [...content.relevantProducts,...content.lastViewedProducts], setFavoriteProduct, userId);
  return (
    <>
      <Grid item container spacing={2}>
        <Grid item>
          <Button
            onClick={openCartDrawer}
            variant={"contained"}
            style={{
              height: "56px",
              backgroundColor: "#fff",
              borderRadius: 2,
              fontWeight: "bold",
              boxShadow: "0px 0px 16px rgba(27, 28, 29, 0.1)",
            }}
          >
            MY BAG
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={"contained"}
            href={'/account/favorites'}
            style={{
              height: "56px",
              backgroundColor: "#fff",
              borderRadius: 2,
              fontWeight: "bold",
              boxShadow: "0px 0px 16px rgba(27, 28, 29, 0.1)",
            }}
          >
            MY FAVORITES
          </Button>
        </Grid>
      </Grid>
      <Box pt={2} />
      <div
        style={{
          marginBottom: 10,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2}>
          {content?.lastViewedProducts.map((product, index) => (
            <Grid item md={4} key={index}>
              <SmallProductCard handleFavorite={()=>setFavoriteProduct(product)} product={product} showBasket />
            </Grid>
          ))}
        </Grid>
      </div>
      <ActionButton title={"Recently Viewed"} actionPath={"/products/recently-viewed"}/>
      <Box pt={4} />
      <SectionTitle title={"We have a hunch you'll love these."} />
      <div
        style={{
          marginTop: 8,
          marginBottom: 10,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2}>
          {content?.relevantProducts?.map((product, index) => (
            <Grid item md={4} key={index}>
              <SmallProductCard handleFavorite={()=>setFavoriteProduct(product)} product={product} showBasket actionPath={"/products/more"}/>
            </Grid>
          ))}
        </Grid>
      </div>
        <Box mt={2} />
      <ActionButton title={"SEE MORE"} actionPath={"/products/more"}/>
    </>
  );
};

const mapStateToProps = (state) => {
    return {
        context: {
            Cart: state.Cart,
            Auth: state.Auth
        },
    };
};

export default connect(mapStateToProps, {
    addProductToCart,
    addMemoToCart
})(NoOrders)