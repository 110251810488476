import React from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import FormikTextField from "components/Core/FormikFields/FormikTextField";
import {GOOGLE_PLACES_API_KEY} from "config";

const FormikPlaces = (props) => {
  const { onPlaceSelected } = props;

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_PLACES_API_KEY,
    onPlaceSelected: onPlaceSelected,
    options: {
      types: ["geocode", "establishment"],
      componentRestrictions: { country: ["us", "ca", "mx"] },
    },
  });

  return (
    <>
      <FormikTextField
        inputRef={ref}
        disabled={true}
        {...props}
        {...props.rest}
      />
    </>
  );
};

export default FormikPlaces;
