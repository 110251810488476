import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  sectionTitle: {
    fontFamily: "Chronicle",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "20px",
    lineHeight: "140%",
    letterSpacing: "0.0125em",
    color: "#0D131D",
  },
});

function SectionTitle({ title }) {
  const classes = useStyles();
  return (
    <Typography
      variant={"h5"}
      style={{ marginBottom: 16 }}
      className={classes.sectionTitle}
    >
      {title}
    </Typography>
  );
}

export default SectionTitle;
