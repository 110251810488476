import {
Grid
} from '@material-ui/core'
import {useEffect, useState,useMemo} from 'react'
import {Formik} from 'formik'
import FormikTextField from 'components/Core/FormikFields/FormikTextField'
import FormikSelect from 'components/Core/FormikFields/FormikSelect'
import Dialog from 'components/Dialog/Dialog'
import {ADMIN_API_URL} from 'config'
import {getAxiosConfig} from 'utils'
import axios from 'axios'
import * as Yup from 'yup'
import {roleOptions} from '../../constants/enums'

const memberStatuses = [
  {
    id: 'ACTIVE',
    name: 'Active',
  },
  {
    id: 'DISABLED',
    name: 'Disabled',
  }
]

const userOriginValues = {
  EXTERNAL: 'EXTERNAL',
  INTERNAL: 'INTERNAL'
}

const userOrigin = [
  {
    id: userOriginValues.EXTERNAL,
    name: 'External'
  },
  {
    id: userOriginValues.INTERNAL,
    name: 'Internal'
  }
]

const EditUserDialog = ({open, setOpen, id, setTableData, tableData, isSalesRep}) => {

  const [isBusy, setIsBusy] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState()

  useEffect(()=>{
    setIsLoading(true);
    const url = `${ADMIN_API_URL}/users/profile/${id}`
    axios.get(url,getAxiosConfig(true))
      .then((response) => {
        if (response.status === 200) {
          setFormData(response.data)
        }
        setIsLoading(false);
      })
      .catch((err) => {
        // Silent error
        setIsLoading(false);
        console.log(err)
        throw err;
      });
  },[id])

  const handleClose = () => {
    setOpen(false)
  }

  const save = (values) => {
    setIsBusy(true)
    const url =`${ADMIN_API_URL}/users/profile/update/${id}`
    axios.put(url,values,getAxiosConfig(true))
      .then((response) => {
        if (response.status === 204) {
          const userIndex = tableData.findIndex((u)=>u.id===id)
          const newTableData = [...tableData]
          newTableData[userIndex] = {
            ...newTableData[userIndex],
            name: `${values.firstName} ${values.lastName}`,
            role: values.userRole,
            ...values
          }
          console.log(values)
          setTableData(newTableData)
        }
        setIsBusy(false);
        handleClose();
      })
      .catch((err) => {
        // Silent error
        setIsBusy(false);
        console.log(err)
        throw err;
      });
  }

  const validation = Yup.object().shape({
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
    userRole: Yup.string().required('This field is required'),
    userOrigin: Yup.string().required('This field is required'),
    memberStatus: Yup.string().required('This field is required'),
  })

  const userRole = useMemo(_ => {
    if (formData) {
      if (formData.userOrigin === 'EXTERNAL') {
        return roleOptions.filter(option => !option.internal);
      } else if (formData.userOrigin === 'INTERNAL') {
        return roleOptions.filter(option => option.internal);
      }
    }
    return roleOptions;
  }, [formData])

  const title = useMemo(_ => {
    if (formData) {
      const role = userRole.find(role => role.id === formData.userRole)
      return role ? role.name : ''
    }
    return ''
  }, [formData, userRole])

  // Clear user role when internal/external changes and a different role is selected
  useEffect(_ => {
    if (formData) {
      const isInternal = formData.userOrigin === userOriginValues.INTERNAL;
      const role = userRole.find(role => role.id === formData.userRole)
      if (!role || role.internal !== isInternal) {
        if (formData.userRole !== '') {
          setFormData(prevFormData => ({...prevFormData, userRole: ''}))
        }
      }
    }
  }, [formData, userRole]);

  if (isLoading||!formData) {
    return <> </>
  }

  return <>
    <Formik
      enableReinitialize={true}
      initialValues={{...formData}}
      validationSchema={validation}
      onSubmit={save}
    >{({values, errors, submitForm}) => (
      <Dialog
        onClose={handleClose}
        open={open}
        title={`${title} Details`}
        maxWidth="sm"
        primaryActionTitle={'SAVE'}
        secondaryActionTitle={'CANCEL'}
        primaryAction={submitForm}
        secondaryAction={()=>{handleClose()}}
        inProgress={isBusy}
        disableBackdropClick={isBusy}
        disableEscapeKeyDown={isBusy}

      >
        <form noValidate>

        <Grid item container spacing={2}>
          {/* Email */}
          <Grid item md={6} xs={12}>
            <FormikTextField
              variant={'outlined'}
              margin="none"
              size={'small'}
              fullWidth
              type={'text'}
              id={'email'}
              name={'email'}
              label={'email'}
              disabled={true}
            />
          </Grid>
          {/* Member Status */}
          <Grid item md={6} xs={12}>
            <FormikSelect
              variant={'outlined'}
              margin="none"
              fullWidth
              id={'memberStatus'}
              name={'memberStatus'}
              label={'Member status'}
              options={memberStatuses}
              disabled={isSalesRep}
            />
          </Grid>
          {/* User Origin */}
          <Grid item md={6} xs={12}>
            <FormikSelect
              variant={'outlined'}
              margin="none"
              fullWidth
              id={'userOrigin'}
              name={'userOrigin'}
              label={'User origin'}
              options={userOrigin}
              handleChange={value => setFormData(prevFormData => ({...prevFormData, userOrigin: value}))}
            />
          </Grid>
          {/* User Role */}
          <Grid item md={6} xs={12}>
            <FormikSelect
              variant={'outlined'}
              margin="none"
              fullWidth
              id={'userRole'}
              name={'userRole'}
              label={'User role'}
              options={userRole}
              disabled={isSalesRep}
            />
          </Grid>
          {/* First Name */}
          <Grid item md={6} xs={12}>
            <FormikTextField
              variant={'outlined'}
              margin="none"
              size={'small'}
              fullWidth
              type={'text'}
              id={'firstName'}
              name={'firstName'}
              label={'First name'}
            />
          </Grid>
          {/* Last name */}
          <Grid item md={6} xs={12}>
            <FormikTextField
              variant={'outlined'}
              margin="none"
              size={'small'}
              fullWidth
              type={'text'}
              id={'lastName'}
              name={'lastName'}
              label={'Last name'}
            />
          </Grid>

          <Grid item xs={12}>
            <FormikTextField
              variant={'outlined'}
              margin="none"
              size={'small'}
              fullWidth
              type={'text'}
              id={'title'}
              name={'title'}
              label={'Title (optional)'}
            />
          </Grid>
        </Grid>
        </form>
      </Dialog>)}
    </Formik>
  </>
}

export default EditUserDialog
