import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import {connect} from "react-redux";
import {loginSuccess, logoutUser} from "store/auth/account/actions";
import {setSpoofingId} from "store/cart/actions";
import {roleOptions} from 'constants/enums'

const Row = ({member, setSpoofingId}) => {
  const roleObj = roleOptions.find((r)=>r.id===member.accountType)
  return <>
    <Grid item xs={6} md={4}>{member.fullName}</Grid>

    <Grid item xs={6} md={3}>
      {roleObj?roleObj.name:'-'}
    </Grid>

    <Grid item xs={6} md={4} style={{overflow:'hidden', textOverflow:'ellipsis'}}>
      <a href={`mailto:${member.email}`} >{member.email}</a>
    </Grid>

    <Grid item xs={6} md={1} style={{cursor:'pointer', textDecoration:'underline'}} onClick={()=>{
      setSpoofingId(member.id)
      window.open(window.location.origin)
    }}>
      Spoof
    </Grid>
  </>
}

const AccountMembers = ({members,setSpoofingId}) => {

  return <Grid item container spacing={2}>

    <Grid item xs={12} md={12}>
      <Box fontSize={18} fontWeight={'bold'}>Account Members</Box>
    </Grid>

    <Grid item container spacing={2}>
      {members&&members.map((member)=><Row member={member} setSpoofingId={setSpoofingId}/>)}
    </Grid>

  </Grid>
}

const mapStateToProps = (state) => {
  const { spoofingUserId } = state.Cart;
  return {
    context: {
      Auth: state.Auth,
      loginSuccess,
      logoutUser,
      spoofingUserId,
    },
  };
};

export default connect(mapStateToProps, {
  setSpoofingId,
})(AccountMembers);
