import Slide1 from '../../assets/our-story/001.jpeg'
import Slide1_1 from '../../assets/our-story/001-1.jpeg'
import Slide1_2 from '../../assets/our-story/001-2.jpeg'
import Slide1_3 from '../../assets/our-story/001-3.jpeg'
import Slide1_4 from '../../assets/our-story/001-4.jpeg'
import Slide2 from '../../assets/our-story/002.jpeg'
import Slide2_2 from '../../assets/our-story/002-2.jpeg'
import Slide2_3 from '../../assets/our-story/002-3.jpeg'
import Slide3 from '../../assets/our-story/003.jpeg'
import Slide3_2 from '../../assets/our-story/003-2.png'
import Slide4 from '../../assets/our-story/004.jpeg'
import Slide4_2 from '../../assets/our-story/004-2.jpeg'
import Slide4_3 from '../../assets/our-story/004-3.jpeg'
import Slide4_4 from '../../assets/our-story/004-4.jpeg'
import Slide4_5 from '../../assets/our-story/004-5.png'

export const ourStorySlides = [
    {
        time: '1889',
        images: [Slide1, Slide1_1, Slide1_2, Slide1_3, Slide1_4],
        texts: [{
            index: 3,
            titles: ['The American Dream'],
            description: 'Paris-born Frederic Schumacher arrives in New York and founds his business in the booming metropolis at the turn of the century.',
            top: 40,
            left: 80,
            color: '#fff',
            maxWidth: '400px'
        }]
    },
    {
        time: '1898', images: [Slide2, Slide2_2, Slide2_3], texts: [
            {
                index: 1,
                titles: ['224 Park Avenue'],
                description: 'Schumacher opens a design house catering to the elite’s Gilded Age tastes, importing the most exquisite fabrics that Europe has to offer while pioneering the production of luxury textiles in America. \n Schumacher shapes his business into a quintessentially American company with a European flair.',
                color: '#fff',
                top: '30%',
                left: 40,
                maxWidth: '400px'
            },
            {
                index: 2,
                titles: ['The Most', 'Discerning Clientele'],
                description: 'Soon Schumacher fabrics line the most sumptuous rooms in the country, from private manses to custom commissions for grand properties like the Waldorf-Astoria Hotel.',
                color: '#000',
                top: '30%',
                left: '55%',
                maxWidth: '400px'
            },
        ]
    },
    {
        time: '1902', images: [Slide3, Slide3_2,], texts: [
            {
                index: 1,
                titles: ['The American Dream'],
                description: 'When President Roosevelt selects Stanford White to strip away the White House\'s Victorian decor, the renowned architect commissions Schumacher to design a shimmering satin lampas—a design that plays an integral role at 1600 Pennsylvania Avenue for decades to come.',
                color: '#fff',
                top: '20%',
                left: 60,
                maxWidth: '400px'
            },
        ]
    },
    {
        time: '1912', images: [Slide4, Slide4_2, Slide4_3, Slide4_4, Slide4_5,], texts: [
            {
                index: 1,
                titles: ['Second Generation'],
                description: 'Schumacher’s sole heir and nephew, Pierre Pozier, becomes Vice President of the firm. Pozier not only designs textiles but taps leading artists and designers to create outstanding Schumacher collections, beginning a rich legacy of collaboration that continues to this day.',
                color: '#000',
                bottom: 0,
                left: '20%',
                textAlign: 'center',
                maxWidth: '60%'
            },
        ]
    },
]