import {makeStyles} from '@material-ui/core/styles'
import {Box, Button, Container, Grid} from '@material-ui/core'
import {useParams} from 'react-router-dom'
import {addCountryCode, getAxiosConfig, getRoles, mapper, SmoothVerticalScrolling, useConfig} from '../../../utils'
import {ADMIN_API_URL} from '../../../config'
import {useCallback, useEffect, useRef, useState} from 'react'
import {ContactInformation} from '../../Account/CreateTradeAccount/steps/ContactInformationNew'
import axios from 'axios'
import {Form, Formik} from 'formik'
import ListIcon from '@material-ui/icons/List'
import {userRoles} from 'constants/enums'
import {useSnackbar} from 'notistack'
import {
  businessInformationFormDefaults,
  contactInformationFormDefaults, memberInformationFormDefaults,
  paymentAndShippingFormDefaults, salesTerritoryFormDefaults
} from 'constants/constants'

import {ErrorBox} from 'pages/Account/CreateTradeAccount/steps/common/ErrorBox'
import StickyBox from 'react-sticky-box'
import {TaxExemptListing} from 'pages/Admin/Applications/components/TaxExemptListing'
import {PaymentAndShipping} from 'pages/Admin/Applications/components/PaymentAndShipping'
import HeaderButton from 'pages/Admin/Applications/components/headerButton'
import {AccountOverview} from 'pages/Admin/Account/components/AccountOverview'
import AccountMembersEdit from 'pages/Admin/Account/components/AccountMembersEdit'
import SalesTerritory from 'pages/Admin/Applications/components/SalesTerritory'
import {connect} from 'react-redux'
import {parseAddresses} from 'pages/Admin/Account/addressParser'
import {MemberInformation} from 'pages/Account/CreateTradeAccount/steps/MemberInformationNew'
import {BusinessInformation} from 'pages/Account/CreateTradeAccount/steps/BusinessInformation'
import {ProofOfBusiness} from 'pages/Account/CreateTradeAccount/steps/ProofOfBusiness'
import {TaxExempt} from 'pages/Account/CreateTradeAccount/steps/TaxExempt'

const useStyles = makeStyles({
  anchors: {
    '& a': {
      color: '#000 !important'
    }
  }
})

function AccountEdit({history, context}) {
  const classes = useStyles()
  const {id} = useParams()
  const isNew = !!!id
  const {enqueueSnackbar} = useSnackbar()

  const [proofOfBusinessFiles, setProofOfBusinessFiles] = useState([])

  const [formData, setFormData] = useState({
    accountStatus: '',
    onHold: false,
  })

  const [countryId, setCountryId] = useState(1)
  const countries = context.Country.getAll.result

  const [states] = useConfig(`config/states/?countryId=${countryId}`, [])

  const [isBusy, setIsBusy] = useState(false)
  const [error, setError] = useState()

  const [isSalesRep, setIsSalesRep] = useState(false)

  useEffect(() => {
    const roles = getRoles()
    setIsSalesRep(roles.includes(userRoles.INTERNAL_SALES_REP))
  }, [])

  useEffect(() => {

    const url = `${ADMIN_API_URL}/accountDetails/${id}`
    axios.get(url, getAxiosConfig())
      .then((response) => {
        if (response.data) {

          // const data = {
          //   ...response.data,
          //   ae: response.data.ae || '',
          //   paymentTerms: response.data.paymentTerms || '',
          //   shippingMethod: response.data.shippingMethodId || '',
          //   salesTerritory: response.data.salesTerritory || '',
          //   fedTaxId: response.data.federalTaxId || '',
          //   businessTypes: response.data.businessType || '',
          //   country: response.data.country || '',
          //   onHold: response.data.onHold,
          //   accountStatus: response.data.tradeAccountStatus,
          //   currencyCode: response.data.tradeAccountApplication.currencyCodeId || 1,
          //   taxExemptForms: response.data.tradeAccountApplication.taxExemptForms,
          //
          // }

          const data = {
            ...response.data,
            ...mapper(response.data, [
              'ae', 'paymentTerms', 'shippingMethod',
              'salesTerritory', 'businessType',
              'country', 'businessName', 'businessFocus', 'businessLink', 'businessOwner',
              'firstNameOwner', 'lastNameOwner', 'emailOwner',
              {
                key: 'shippingMethod',
                map: 'shippingMethodId',
                defaultValue: ''
              },
              {
                key: 'fedTaxId',
                map: 'federalTaxId',
                defaultValue: ''
              }
            ], ''),
            ...mapper(response.data, ['taxExemptForms'], []),
            ...mapper(response.data.tradeAccountApplication, ['creditLimit', {
              key: 'currencyCode',
              map: 'currencyCodeId',
              defaultValue: 0
            }], 0),
            ...mapper(response.data.tradeAccountApplication, ['thirdPartyBilling', 'otherBusinessType'], ''),
          }

          setFormData(data)

          const businessContact = data.contacts.find((c) => c.contactType === 'BUSINESS')

          setProofOfBusinessFiles(data.proofOfBusinessFiles)

          let noProof = (!data.proofOfBusinessFiles ||
            (data.proofOfBusinessFiles && data.proofOfBusinessFiles.length === 0)) &&
            !data.fedTaxId && !data.businessLink

          setFormDataMemberBusinessInformation({
            ...data,
            typeOfProof: data.fedTaxId ? 'taxId' : 'other',
          })

          setFormDataMemberInformation({
            ...data,
            country: businessContact.countryId,
            focusOnResidentialHospitality: data.focusOnResidentialHospitality === 'true',
            skipProofOfBusiness: noProof,
            typeOfProof: data.fedTaxId ? 'taxId' : 'other',
            businessOwner: data.businessOwner
          })

          setFormDataBusinessInformation({
            ...data,
            country: businessContact.countryId,
            focusOnResidentialHospitality: data.focusOnResidentialHospitality === 'true',
            skipProofOfBusiness: noProof,
            typeOfProof: data.fedTaxId ? 'taxId' : 'other',
          })

          setCountryId(businessContact.countryId)
          setCountry(businessContact.countryId)

          let contactInformation = {
            country: businessContact.countryId,
            countryId: businessContact.countryId,
          }

          // Parse addresses
          parseAddresses(contactInformation, data)

          setFormDataContactInformation(contactInformation)

          setFormDataPaymentAndShipping({
            ...mapper(response.data, [
              {key: 'terms', map: 'terms', defaultValue: 1}
            ]),
            ...mapper(response.data.tradeAccountApplication, ['creditLimit', {
              key: 'currencyCode',
              map: 'currencyCodeId',
              defaultValue: 0
            }], 0),
            ...mapper(response.data.tradeAccountApplication, ['thirdPartyBilling', 'otherBusinessType'], '')
          })

          setFormDataSalesTerritory({
            ...mapper(response.data, [
              'salesTerritory', 'ae', 'notes'
            ])
          })

        }
        setIsBusy(false)
      })
      .catch((err) => {
        // Silent error
        setIsBusy(false)
        // if (err.response && err.response.data && err.response.data.message) {
        //   setError(err.response.data)
        // }
        enqueueSnackbar(`An error occurred while loading the account`, {variant: 'error'})
        throw err
      })


    // eslint-disable-next-line
  }, [])

  const formMemberBusinessInformationRef = useRef()
  const formBusinessInformationRef = useRef()
  const formMemberInformationRef = useRef()
  const formContactInformationRef = useRef()
  const formPaymentAndShipping = useRef()
  const formSalesTerritory = useRef()
  const formOverview = useRef()

  const [formDataMemberBusinessInformation, setFormDataMemberBusinessInformation] = useState({...businessInformationFormDefaults})
  const [formDataMemberInformation, setFormDataMemberInformation] = useState({...memberInformationFormDefaults})
  const [formDataBusinessInformation, setFormDataBusinessInformation] = useState({...businessInformationFormDefaults})
  const [formDataContactInformation, setFormDataContactInformation] = useState({...contactInformationFormDefaults})
  const [formDataPaymentAndShipping, setFormDataPaymentAndShipping] = useState({...paymentAndShippingFormDefaults})
  const [formDataSalesTerritory, setFormDataSalesTerritory] = useState({...salesTerritoryFormDefaults})

  const [formDataTaxExempt, setFormDataTaxExempt] = useState()
  const [taxExemptFiles, setTaxExemptFiles] = useState({})
  const [taxExemptState, setTaxExemptState] = useState(1)

  const setCountry = useCallback(id => {
    setFormData(prevFormData => ({...prevFormData, country: id}))
    setFormDataContactInformation(prevFormData => ({...prevFormData, country: id}))
    setCountryId(id)
    // eslint-disable-next-line
  }, [])

  // const setNotes = useCallback(notes => setFormDataSalesTerritory(prevFormData => ({...prevFormData, notes}), []));

  const save = async (close, overrides) => {

    setIsBusy(true)

    // Validate each form
    let forms = [
      formBusinessInformationRef,
      formMemberBusinessInformationRef,
      formMemberInformationRef,
      formContactInformationRef,
      formSalesTerritory,
      formPaymentAndShipping
    ]

    // await forms.forEach((f)=>f.submitForm())

    await formMemberBusinessInformationRef.current.submitForm()
    await formBusinessInformationRef.current.submitForm()
    await formMemberInformationRef.current.submitForm()
    await formContactInformationRef.current.submitForm()
    await formSalesTerritory.current.submitForm()
    await formPaymentAndShipping.current.submitForm()

    const valid =
      formMemberBusinessInformationRef.current.isValid &&
      formBusinessInformationRef.current.isValid &&
      formMemberInformationRef.current.isValid &&
      formSalesTerritory.current.isValid &&
      formPaymentAndShipping.current.isValid &&
      formContactInformationRef.current.isValid

    // Scroll to errors
    if (!valid) {
      const firstError = forms.find((f)=>Object.keys(f.current.errors).length!==0)
      if (firstError) {
        const fieldName = Object.keys(firstError.current.errors)[0]
        const fieldElement = document.getElementById(fieldName)
        SmoothVerticalScrolling(fieldElement,600,'top')
      }
      // additional logging
      console.log(forms.forEach((f)=>console.log(f.current.errors)))
      setIsBusy(false)
    }

    // If valid, transform data and submit
    if (valid) {

      // Transform for payload
      const {
        title,
        businessType,
        focusOnResidentialHospitality,
        businessName,
        sameAsBusinessAddressBilling,
        sameAsBusinessAddressShipping,
        businessState,
        businessCity,
        businessZipCode,
        businessAddressLine1,
        businessAddressLine2,
        businessLink,
        fedTaxId,
        shippingState,
        shippingCity,
        shippingZipCode,
        shippingAddressLine1,
        shippingAddressLine2,
        shippingName,
        billingState,
        billingCity,
        billingZipCode,
        billingAddressLine1,
        billingAddressLine2,
        billingName,
        businessFocus,
        terms,
        creditLimit,
        thirdPartyBilling,
        salesTerritory,
        ae,
        notes,
        businessId,
        billingId,
        shippingId,
        email,
        onHold,
        otherBusinessType,
        accountStatus,
        businessPhoneNumber,
        shippingPhoneNumber,
        billingPhoneNumber,
        firstNameOwner,
        lastNameOwner,
        emailOwner,
      } = {
        ...formData,
        ...formDataMemberBusinessInformation,
        ...formDataPaymentAndShipping,
        ...formDataContactInformation,
        ...formSalesTerritory.current.values,
        ...formPaymentAndShipping.current.values,
        ...formContactInformationRef.current.values,
        ...formOverview.current.values,
        ...formMemberBusinessInformationRef.current.values,
        ...overrides // this is used for direct save with changes
      }

      const {
        priceLevel,
        discount,
        tradeDiscount
      } = {...formPaymentAndShipping.current.values}


      let payLoad = {
        onHold: onHold,
        'title': title,
        'businessFocus': businessFocus,
        'businessType': businessType,
        'businessLink': businessLink,
        'focusOnResidentialHospitality': focusOnResidentialHospitality,
        'federalTaxId': fedTaxId,
        'contacts': [],
        tradeAccountStatus: accountStatus,
        // "taxExemptForms": [],
        // 'proofOfBusinessFiles': [],
        rep: formSalesTerritory.current.values.rep,
        discount: discount,
        priceLevel: priceLevel,
        paymentTermsId: terms,
        tradeDiscount: tradeDiscount,
        shippingMethodId: formPaymentAndShipping.current.values.shippingMethod,
        businessName: formBusinessInformationRef.current.values.businessName,
        firstNameOwner,
        lastNameOwner,
        emailOwner,
        businessOwner: formMemberInformationRef.current.values.businessOwner,
        salesTerritory: salesTerritory,
        ae: ae,
        accountNotes: notes ? notes : [],
        status: overrides && overrides.status ? overrides.status : formData.status,
        taxExemptForms: formData.taxExemptForms,
        // skipProofOfBusiness: !!skipProofOfBusiness,
        tradeAccountApplication: {
          thirdPartyBilling: thirdPartyBilling,
          'otherBusinessType': businessType === 1 ? otherBusinessType : '',
          currencyCodeId: formPaymentAndShipping.current.values.currencyCode,
          creditLimit: creditLimit ? parseFloat(creditLimit) : 0
        }
      }

      formData.contacts.forEach((c) => {
        if ((c.id !== businessId) && (c.id !== shippingId) && (c.id !== billingId)) {
          payLoad.contacts.push(c)
        }
      })

      // Business Address
      payLoad.contacts.push({
        'name': businessName,
        'contactType': 'BUSINESS',
        'email': email,
        'phoneNumber': addCountryCode(countries, countryId, businessPhoneNumber),
        'addressLine1': businessAddressLine1,
        'addressLine2': businessAddressLine2,
        'zipCode': businessZipCode,
        'city': businessCity,
        'stateId': businessState ? businessState : null,
        'countryId': countryId,
        'id': businessId
      })

      // Billing Address
      if (!sameAsBusinessAddressBilling) {
        payLoad.contacts.push({
          'name': billingName,
          'contactType': 'BILLING',
          'email': email,
          'phoneNumber': addCountryCode(countries, countryId, billingPhoneNumber),
          'addressLine1': billingAddressLine1,
          'addressLine2': billingAddressLine2,
          'zipCode': billingZipCode,
          'city': billingCity,
          'stateId': billingState ? billingState : null,
          'countryId': countryId,
          'id': billingId
        })
      }

      // Shipping Address
      if (!sameAsBusinessAddressShipping) {
        payLoad.contacts.push({
          'name': shippingName,
          'contactType': 'SHIPPING',
          'email': email,
          'phoneNumber': addCountryCode(countries, countryId, shippingPhoneNumber),
          'addressLine1': shippingAddressLine1,
          'addressLine2': shippingAddressLine2,
          'zipCode': shippingZipCode,
          'city': shippingCity,
          'stateId': shippingState ? shippingState : null,
          'countryId': countryId,
          'id': shippingId
        })
      }

      const url = `${ADMIN_API_URL}/accountDetails/${id}`
      setIsBusy(true)

      axios.put(url, payLoad, getAxiosConfig())
        .then((response) => {
          setIsBusy(false)
          if (response.status === 200) {
            enqueueSnackbar(`Trade account saved successfully`, {variant: 'success'})

            if (close) {
              history.push('/admin/account-management')
            }
          }

        })
        .catch((err) => {
          // Silent error
          setIsBusy(false)
          enqueueSnackbar(`An error occurred while saving the application form`, {variant: 'error'})

          if (err.response && err.response.data && err.response.data.message) {
            console.log(err.response.data.message)
            setError(err.response.data)
          }
          throw err
        })

    }

  }

  // Set form as read-only if account is Sales Representative
  const disabled = isSalesRep
  const warnEdit = true

  return (
    <Container style={{position: 'relative'}}>
      <Box pt={4}/>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box fontSize={48} fontFamily={'Chronicle Display'}> {
            formDataContactInformation && formDataContactInformation.businessName &&
            <> {formDataContactInformation.businessName} </>} </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid item container justify={'flex-end'} spacing={3}>
            {/*<Grid item>*/}
            {/*  <HeaderButton label={`Edit Account Details`} to={`/admin/account-details/edit/${id}`} icon={EditIcon}/>*/}
            {/*</Grid>*/}
            <Grid item>
              <HeaderButton label={`Go to accounts list`} to={`/admin/account-management/accounts`} icon={ListIcon}/>
            </Grid>
          </Grid>
        </Grid>

      </Grid>

      <Formik
        initialValues={{...formData}}
        enableReinitialize={true}
        onSubmit={(values) => {
          save().then(r => {
          })
        }}
      >
        {({values, errors}) => (<Form noValidate>

          <Box mt={6}/>

          <Grid container spacing={2} style={{minHeight: '100vh'}}>

            <StickyBox offsetTop={20} offsetBottom={20}
                       style={{height: '300px', display: 'block', paddingRight: 16, backgroundColor: 'white'}}>
              <Grid item xs={12}>

                <Grid item container justify='flex-end'>
                  <Grid item>
                    <Box fontSize={16} pr={10} pt={2} className={classes.anchors}>
                      <p><a href={'#AccountOverview'}> Account Overview </a></p>
                      <p><a href={'#MembersBusinessInformation'}> Member & Business Information </a></p>
                      <p><a href={'#ContactInformation'}> Business Contact Information </a></p>
                      <p><a href={'#TaxExempt'}>Tax Exempt</a></p>
                      <p><a href={'#PaymentAndShipping'}>Payment & Shipping</a></p>
                      <p><a href={'#SalesTerritory'}>Sales Territory & Notes</a></p>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </StickyBox>

            {formData && formData.accountNumber && <>
              <Grid item xs={12} md={7}>

                <Box pt={6}/>

                <Grid item container spacing={2}>

                  {formData && <Grid item xs={12} id={'AccountOverview'}>
                    <AccountOverview formData={formData} setFormData={setFormData}
                                     formRef={formOverview} members={formData.accountMembers} disabled={disabled}/>
                  </Grid>}


                  <AccountMembersEdit members={formData.accountMembers} disabled={disabled}/>


                  <Grid item xs={12} id={'MembersBusinessInformation'}>
                    {formDataBusinessInformation && <BusinessInformation
                      formRef={formBusinessInformationRef}
                      setFormData={setFormDataBusinessInformation}
                      formData={formDataBusinessInformation}
                      countries={countries}
                      setCountryId={setCountry}
                      countryId={countryId}
                      proofOfBusinessFiles={proofOfBusinessFiles}
                      setProofOfBusinessFiles={setProofOfBusinessFiles}
                      allowUserEdit={isNew}
                      isUserView={false}
                      disabled={disabled}
                      locked={warnEdit}
                      isAccountEdit={true}
                    />}
                  </Grid>

                  <Grid item xs={12} id={'MembersBusinessInformation'}>
                    {formDataMemberBusinessInformation && <ProofOfBusiness
                      formRef={formMemberBusinessInformationRef}
                      setFormData={setFormDataMemberBusinessInformation}
                      formData={formDataMemberBusinessInformation}
                      countries={countries}
                      setCountryId={setCountry}
                      countryId={countryId}
                      proofOfBusinessFiles={proofOfBusinessFiles}
                      setProofOfBusinessFiles={setProofOfBusinessFiles}
                      allowUserEdit={isNew}
                      isUserView={false}
                      validatePhone={isNew}
                      disabled={disabled}
                      locked={warnEdit}
                      isAccountEdit={true}
                    />}
                  </Grid>

                  <Grid item xs={12} id={'MembersBusinessInformation'}>
                    {formDataMemberInformation && <MemberInformation
                      formRef={formMemberInformationRef}
                      setFormData={setFormDataMemberInformation}
                      formData={formDataMemberInformation}
                      countries={countries}
                      setCountryId={setCountry}
                      countryId={countryId}
                      proofOfBusinessFiles={proofOfBusinessFiles}
                      setProofOfBusinessFiles={setProofOfBusinessFiles}
                      allowUserEdit={isNew}
                      isUserView={false}
                      isAccountEdit={true}
                      disabled={disabled}
                      locked={warnEdit}
                    />}
                  </Grid>


                  <Grid item xs={12} id={'ContactInformation'}>
                    {formDataContactInformation && <ContactInformation
                      formRef={formContactInformationRef}
                      formData={formDataContactInformation}
                      setFormData={setFormDataContactInformation}
                      countries={countries}
                      states={states}
                      countryId={countryId}
                      setCountryId={setCountryId}
                      disabled={disabled}
                      validatePhone={isNew}
                      locked={warnEdit}
                    />}
                  </Grid>

                </Grid>

                <Grid item container spacing={2}>

                  <Grid item xs={12} id={'TaxExempt'}>
                    {!isNew && <TaxExemptListing formData={formData}/>}
                    {isNew && <TaxExempt
                      isUserView={false}
                      formData={formDataTaxExempt}
                      setFormData={setFormDataTaxExempt}
                      setTaxExemptFiles={setTaxExemptFiles}
                      taxExemptFiles={taxExemptFiles}
                      setTaxExemptState={setTaxExemptState}
                      taxExemptState={taxExemptState}
                      states={states}
                      countryId={countryId}
                      disabled={disabled}
                      locked={warnEdit}
                    />}
                  </Grid>

                  <Grid item xs={12} id={'PaymentAndShipping'}>
                    {formDataPaymentAndShipping && <PaymentAndShipping formData={formDataPaymentAndShipping}
                                                                       setFormData={setFormDataPaymentAndShipping}
                                                                       formRef={formPaymentAndShipping}
                                                                       status={formData.status} disabled={disabled}
                                                                       locked={warnEdit}/>}
                  </Grid>

                  <Grid item xs={12} id={'SalesTerritory'}>
                    {formDataSalesTerritory &&
                    <SalesTerritory formData={formDataSalesTerritory} setFormData={setFormDataSalesTerritory}
                                    formRef={formSalesTerritory} disabled={disabled} locked={warnEdit}/>}
                  </Grid>

                </Grid>


                <Box pt={4}/>

                {!disabled && <Grid item container spacing={2} justify='flex-end'>
                  <Grid item xs={6}>
                    <Button variant='contained' color='secondary' size='large' fullWidth type='submit'
                            disabled={isBusy}>SAVE
                      CHANGES</Button>
                  </Grid>
                </Grid>}

              </Grid>
            </>}

          </Grid>

          <ErrorBox error={error}/>

        </Form>)}
      </Formik>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    context: {
      Auth: state.Auth,
      Country: state.Country,
      SalesTerritory: state.SalesTerritory,
    },
  }
}

export default connect(mapStateToProps, {})(AccountEdit)
