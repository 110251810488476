import React from "react";
import Text from "../../../components/Text/Text";
import Box from "@material-ui/core/Box";

function Heading({ title, children, mb = 3 }) {
  return (
    <Box
      mb={mb}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Text variant={"h6"} bold style={{ fontSize: 20 }}>
        {title}
      </Text>
      <Box>{children}</Box>
    </Box>
  );
}

export default Heading;
