import Typography from "@material-ui/core/Typography";
import { Box, Container, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import OrderCard from "./OrderCard";
import {
  useCheckout,
  useCheckoutSteps,
  useCheckoutValidation,
  useCurrentCart,
  useDeliveryOptions,
  useOrder,
} from "../../hooks/cart";
import Steps from "../../components/Steps/Steps";
import ShippingAddress from "./ShippingAddress";
import { TabPanel } from "../../components/Core/TabPanel";
import { fadeAnimation } from "../../constants/animations";
import Delivery from "./Delivery";
import { orderItemType } from "../../constants/enums";
import Payment from "./Payment";
import {
  useAddresses,
  useAddressesByType,
  usePaymentMethods,
  useTaxExemptions,
} from "../../hooks/account";
import Review from "./Review";
import { useProfileIds } from "../../hooks/auth";
import {useCountries} from "../../hooks/config";

function Checkout() {
  const [step, setStep] = useState(0);
  const [triggerTaxRefresh, setTriggerTaxRefresh] = useState(0)
  // Get current cart operations and state
  const { cart, processingCart, numberOfItems, handleUpdateItem } = useCurrentCart();

  // Fetch tax exemption regions
  const taxExemptions = useTaxExemptions(triggerTaxRefresh);

  // Get countries
  const countries = useCountries(["us", "ca", "mx"])

  // Init the address controller
  const addressContext = useAddresses();

  // Get addresses separated by contact type
  const addresses = useAddressesByType();

  // Init Payment Method controller
  const paymentMethodContext = usePaymentMethods();

  // // Get cart items by type
  // const { memos, products } = useCartItems(cart);

  const {
    hasMemo,
    hasProduct,
    order,
    selectedMemos,
    selectedProducts,
    numberOfSelectedItems,
  } = useCheckout(cart);

  const {
    shippingAddressId,
    paymentMethodId,
    productShippingMethodId,
    memoShippingMethodId,
  } = order;

  // Get delivery options for each item type
  const [memoDeliveryOptions] = useDeliveryOptions(
    selectedMemos,
    orderItemType.SAMPLE
  );
  const [productDeliveryOptions] = useDeliveryOptions(
    selectedProducts,
    orderItemType.PRODUCT
  );

  // Support spoofing mode
  const { activeUserTradeAccountId } = useProfileIds();

  // Get order amounts
  const [processingOrder, amounts, submitOrder] = useOrder(cart, triggerTaxRefresh);

  // Get steps based on products
  const { steps, skipPayment } = useCheckoutSteps(
    hasProduct,
    amounts.totalAmount
  );

  // Validate wizard steps
  const disabledStep = useCheckoutValidation(
    steps,
    skipPayment,
    processingCart,
    numberOfSelectedItems,
    shippingAddressId,
    memoShippingMethodId,
    productShippingMethodId,
    paymentMethodId,
    hasMemo,
    hasProduct
  );

  // Reset wizard if type of checkout is changed
  useEffect(() => {
    if (hasProduct && !productShippingMethodId && step > 1) setStep(1)
  }, [hasProduct, productShippingMethodId, step]);

  // Redirect if no items in basket
  // if (numberOfSelectedItems === 0) {
  //   return <Redirect to={"/"} />;
  // }

  return (
    <Container maxWidth={"lg"}>
      <Box mt={2} />
      <Typography
        variant="h4"
        style={{ textTransform: "capitalize", fontSize: 56 }}
      >
        Checkout
      </Typography>
      <Box mt={4} />
      <Grid container spacing={8}>
        <Grid item md={8} container direction={"column"} spacing={2}>
          <Grid item>
            <Steps
              steps={steps}
              onChange={setStep}
              value={step}
              direction={"row"}
              disableStep={disabledStep}
            />
          </Grid>
          <Grid item>
            <TabPanel index={0} value={step} animation={fadeAnimation}>
              <ShippingAddress
                context={addressContext}
                addresses={addresses.shipping}
                countries={countries}
                tradeAccountId={activeUserTradeAccountId}
                setStep={setStep}
                numberOfSelectedItems={numberOfSelectedItems}
              />
            </TabPanel>
            <TabPanel index={1} value={step} animation={fadeAnimation}>
              <Delivery
                hasMemo={hasMemo}
                hasProduct={hasProduct}
                setStep={setStep}
                memoDeliveryOptions={memoDeliveryOptions}
                productDeliveryOptions={productDeliveryOptions}
              />
            </TabPanel>
            {/* Payment step should rendered only if the order has a cost*/}
            <TabPanel
              index={skipPayment ? null : 2}
              value={step}
              animation={fadeAnimation}
            >
              <Payment
                addresses={addresses}
                context={paymentMethodContext}
                addressContext={addressContext}
                tradeAccountId={activeUserTradeAccountId}
                setStep={setStep}
                countries={countries}
              />
            </TabPanel>
            <TabPanel
              index={skipPayment ? 2 : 3}
              value={step}
              animation={fadeAnimation}
            >
              <Review
                memos={selectedMemos}
                products={selectedProducts}
                order={order}
                addresses={addresses.shipping}
                handleUpdateItem={handleUpdateItem}
                paymentMethods={paymentMethodContext.getAll.result}
                processingOrder={processingOrder}
                memoDeliveryOptions={memoDeliveryOptions}
                productDeliveryOptions={productDeliveryOptions}
                processingCart={processingCart}
              />
            </TabPanel>
          </Grid>
        </Grid>
        <Grid item md={4}>
          <OrderCard
            amounts={amounts}
            tradeAccountId={activeUserTradeAccountId}
            taxExemptions={taxExemptions}
            memos={selectedMemos}
            numberOfItems={numberOfItems}
            numberOfSelectedItems={numberOfSelectedItems}
            products={selectedProducts}
            submitOrder={submitOrder}
            processingOrder={processingOrder}
            processingCart={processingCart}
            canSubmit={step >= (skipPayment ? 2 : 3)}
            setTriggerTaxRefresh={setTriggerTaxRefresh}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Checkout;
