import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import React, {useMemo} from "react";
import PropTypes from "prop-types";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Text from "../Text/Text";
import Button from "../Button/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Link } from "react-router-dom";
import c from "classnames";
import UnderlinedLink from "../UnderlinedLink";
import {downloadFile} from "../../utils";
import {useProfile} from "../../hooks/auth";
import {userRoles} from "../../constants/enums";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    textTransform: "uppercase",
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    width: "100%",
    height: "auto",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  actionButton: {
    fontWeight: "bold",
    height: 60,
    width: "100%",
  },
  specialBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "48px",
    border: "4px double #54585a",
  },
  smallButton: {
    height: "max-content",
  },
  titleCell: {
    paddingLeft: 0,
  },
  cell: {
    border: 0,
  },
  bodyCell: {
    paddingTop: 0,
    paddingBottom: 0,
    borderLeft: "1px solid black",
  },
  collaboratorCardImage: {
    height: 100,
  },
  collaboratorCardBadge: {
    position: "absolute",
    top: 15,
    left: -10,
    backgroundColor: theme.palette.secondary.main,
    padding: "2px 4px",
    fontSize: 12,
  },
  specialItemRow: {
    display: "flex",
    alignItems: "center",
  },
}));

const Detail = ({ attribute }) => {
  return (
    <Grid item md={6} xs={12}>
      <Box display={"flex"} flexDirection={"column"}>
        <Text variant={"body1"} bold>
          {attribute.label}
        </Text>
        {attribute.path ? (
          <Text variant={"body1"}>
            <UnderlinedLink style={{ color: "inherit" }} to={attribute.path}>
              {attribute.value}
            </UnderlinedLink>
          </Text>
        ) : (
          <Text variant={"body1"}>{attribute.value}</Text>
        )}
      </Box>
    </Grid>
  );
};

function downloadHospitality() {
  downloadFile("https://schumacher-webassets.s3.us-east-1.amazonaws.com/GetToKnowSchuHospitality.pdf", "Hospitality.pdf")
}

function Specs({ availability, details, hospitality,openAvailability,setOpenAvailability}) {
  const classes = useStyles();
  const {
    visibleProfile,
  } = useProfile();
  const permissions = useMemo(() => {
    if (visibleProfile) {
      return visibleProfile;
    }
    return { role: userRoles.NON_SIGNED };
  }, [visibleProfile]);

  const {  hasActivity } = permissions;
  const [expanded, setExpanded] = React.useState("panel1");

  React.useEffect(()=>{
    if (openAvailability)
      setExpanded('panel2')
  },[openAvailability])

  const handleChange = (panel) => (event, isExpanded) => {
    if(panel!=='panel2')
      setOpenAvailability(false)
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root} id="availability">
      <Accordion
        variant="outlined"
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel1" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Text className={classes.heading}>Product Details</Text>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {details.map((attribute) => (
              <Detail attribute={attribute} />
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {hasActivity &&
      <Accordion
          variant="outlined"
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
      >
        <AccordionSummary
            expandIcon={expanded === "panel2" ? <RemoveIcon/> : <AddIcon/>}
            aria-controls="availability"
        >
          <Text className={classes.heading}>Availability</Text>
        </AccordionSummary>
        <AccordionDetails>
          <Table size="small">
            <TableBody>
              {availability?.map((available) => (
                  <>
                    <TableRow>
                      <TableCell
                          variant={"head"}
                          className={c(classes.titleCell, classes.cell)}
                      >
                        <Text variant={'body1'}>{available?.value}</Text>
                      </TableCell>
                      {/*<TableCell className={c(classes.titleCell, classes.cell)}>*/}
                      {/*  {available.value}*/}
                      {/*</TableCell>*/}
                    </TableRow>
                    {/*{available?.details.map((availabilityDetail) => (*/}
                    {/*  <TableRow>*/}
                    {/*    <TableCell className={c(classes.cell, classes.bodyCell)}>*/}
                    {/*      {availabilityDetail.code}*/}
                    {/*    </TableCell>*/}
                    {/*    <TableCell className={c(classes.cell, classes.bodyCell)}>*/}
                    {/*      {availabilityDetail.value}*/}
                    {/*    </TableCell>*/}
                    {/*  </TableRow>*/}
                    {/*))}*/}
                  </>
              ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
      }
      <Accordion
        variant="outlined"
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel3" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Text className={classes.heading}>
            Hospitality / Commercial Grade
          </Text>
        </AccordionSummary>
        <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>
          <Text variant={"h5"}>Did You Know ?</Text>
          <Text variant={"body1"}>{hospitality}</Text>
        </AccordionDetails>
      </Accordion>
      <Accordion
        variant="outlined"
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel4" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Text className={classes.heading}>Downloads</Text>
        </AccordionSummary>
        <AccordionDetails>
          {/*<Button size="small" className={classes.smallButton}>*/}
          {/*  <GetAppIcon fontSize={"small"} />*/}
          {/*  Digital Memo*/}
          {/*</Button>*/}
          <Button
            size="small"
            className={classes.smallButton}
            style={{ marginLeft: "auto" }}
            onClick={downloadHospitality}
          >
            <GetAppIcon fontSize={"small"} />
            Schumacher Hospitality
          </Button>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

function SpecialBox({ specialText, specialAttributes }) {
  const classes = useStyles();
  return (
    <Box className={classes.specialBox}>
      <Text variant="h4">What Makes It Special</Text>
      <Box mt={2} />
      <Text variant="body1">{specialText}</Text>
      <Box mt={2} />
      <Grid container spacing={2} direction={"column"}>
        {specialAttributes.map((special) => (
          <Grid item key={special.id}>
            <div className={classes.specialItemRow}>
              <img
                src={special.smallImageUrl}
                style={{ width: 60, height: 60, marginRight: 16 }}
                alt={"what makes it special"}
              />
              <Text variant={"body1"}>{special.value}</Text>
            </div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

function DesignerLinkCard({ collaborator }) {
  const classes = useStyles();
  return (
    <Box
      mt={2}
      p={3}
      alignItems={"center"}
      display={"flex"}
      border={"1px solid black"}
      borderColor={"#54585a"}
      position={"relative"}
    >
      <Box className={classes.collaboratorCardBadge}>Our Collaborator</Box>
      <img
        className={classes.collaboratorCardImage}
        src={collaborator.portraitPhotoUrl}
        alt={collaborator.nameId}
      />
      <Box display={"flex"} flexDirection={"column"} p={2}>
        <Text
          color={"textSecondary"}
          variant={"h5"}
          style={{ fontWeight: 400 }}
        >
          {collaborator.firstName} {collaborator.lastName}
        </Text>
        <Button
          style={{ padding: 0 }}
          size={"small"}
          component={Link}
          to={`/collaborators/${collaborator.slug}`}
        >
          GET TO KNOW {collaborator.getToKnowName}
        </Button>
      </Box>
    </Box>
  );
}

function ProductDetails(props) {
  const {
    specialText,
    details,
    availability,
    hospitality,
    collaborator,
    specialAttributes,
    openAvailability,
    setOpenAvailability
  } = props;
  return (
    <Grid container spacing={4}>
      <Grid item md={6} xs={12}>
        <SpecialBox
          specialText={specialText}
          specialAttributes={specialAttributes}
        />
        {collaborator && <DesignerLinkCard collaborator={collaborator} />}
      </Grid>
      <Grid item md={6} xs={12}>
        <Specs
          details={details}
          availability={availability}
          hospitality={hospitality}
          openAvailability={openAvailability}
          setOpenAvailability={setOpenAvailability}
        />
      </Grid>
    </Grid>
  );
}

ProductDetails.propTypes = {
  specialText: PropTypes.string.isRequired,
  details: PropTypes.array.isRequired,
  collaborator: PropTypes.object,
  availability: PropTypes.array,
  hospitality: PropTypes.string,
};

ProductDetails.defaultProps = {};

export default ProductDetails;
