import Box from "@material-ui/core/Box";
import {Grid} from "@material-ui/core";
import {withRouter} from "react-router-dom";

const HeaderButton = ({label, to, icon:Icon, history, ...props}) => {
  return <Box style={{textTransform: 'uppercase', cursor: 'pointer'}}
              {...props}
              onClick={() => {
                history.push(to)
              }}>
    <Grid container spacing={1} justify='center' alignContent='center' alignItems='center'>
      <Grid item>{label}</Grid>
      <Grid item>
        <Box mt={1}>
          <Icon />
        </Box>
      </Grid>
    </Grid>
  </Box>
}

export default withRouter(HeaderButton)