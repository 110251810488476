import React from "react";
import Button from "../Button/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {},
  label: {
    textTransform: "none",
    fontWeight: "normal"
  },
});

function UnderlinedButton({ children, onClick, ...rest }) {
  const classes = useStyles();

  return (
    <Button
      size={"small"}
      onClick={onClick}
      style={{ textDecoration: "underline" }}
      classes={{ label: classes.label }}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default UnderlinedButton;
