import {Box, Container, Grid} from "@material-ui/core";
import Text from "../../components/Text/Text";
import AnchorLink from "react-anchor-link-smooth-scroll";
import ScrollBackToTop from "../../components/ScrollBackToTop";

const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']


const glossary = [
    {
        'A': [{
            title: 'Aubusson',
            description: 'Fine, hand-woven tapestry used for wall hangings or carpets. Named after the famous French village where they were originally made.'
        }]
    },
    {
        'B': [
            {
                title: 'Basket Weave',
                description: 'Plain weave where two or more warp yarns interlace with the same balance of filler yarns so that the fabric surface resembles a basket.'
            },
            {
                title: 'Batik',
                description: 'An ancient Japanese resist printing technique. Wax is blocked on the cloth to cover the design before dyeing and after the wax is removed by a washing procedure the design takes shape.'
            },
            {
                title: 'Batiste',
                description: 'A translucent plain-weave sheer fabric made with fine long staple cotton.'
            },
            {
                title: 'Bleeding',
                description: 'A printing imperfection where the dye-stuff runs from the screen of one area onto the motif of another.'
            },
            {
                title: 'Block Printing',
                description: 'A hand-printing process where the motifs have been carved on wooden blocks. The dye is applied to the fabric from these blocks in a procedure similar to the rubber stamp technique.'
            },
            {
                title: 'Blotch',
                description: 'A screen used in printing that fills in the ground (or white) remaining after the full design has been printed. This becomes the fabric’s background color.'
            },
            {
                title: 'Brocatelle',
                description: 'A heavy fabric similar in appearance to a damask. The filler yarns (often linen) give it an embossed look. Originally it was made to imitate 19th century Italian tooled leather.'
            },
            {
                title: 'Burn-Out or Etched Printing',
                description: 'The application of an acid solution to dissolve an opaque fiber from a translucent sheer of blended yarns. After this process, the desired motifs appear in silhouette on the surface of the fabric.'
            },
        ]
    },
    {
        'C': [
            {
                title: 'Calendering',
                description: 'The procedure of pressing fabric between heated and rotating cylinders to give a smooth glossy surface.'
            },
            {
                title: 'Casement Cloth',
                description: 'A light-weight textile made in a combination of fibers usually dyed in light neutral colors.'
            },
            {
                title: 'Chenille',
                description: 'A novelty yarn with a fuzzy surface which creates a soft hand when woven into fabric. Origin: French, chenille—caterpillar.'
            },
            {
                title: 'Chinoiserie',
                description: 'A novelty yarn with a fuzzy surface which creates a soft hand when woven into fabric. Origin: French, chenille—caterpillar.'
            },
            {
                title: 'Chintz',
                description: 'A plain weave cotton fabric printed in multi-color. It often, but not necessarily, has a glazed finish. Origin: Hindu word meaning spotted.'
            },
            {
                title: 'Color Flag',
                description: 'A series of small swatches attached to a large full patterned sample which illustrate the complete color line or colorways.'
            },
            {title: 'Color Line', description: 'The range of available colors of a solid or printed fabric.'},
            {
                title: 'Corduroy',
                description: 'A cloth made with cut-pile ribs (or wales) running the length or width of the fabric. The ribs are produced by wefts yarns that are carried over the fabric face and then cut. Origin: French, Corded du Roi—rope of the king.'
            },
            {
                title: 'Cotton',
                description: 'A vegetable fiber composed of pure cellulose. It is soft and absorbent, and takes dyes and special finishes extremely well. Strong and durable, it has excellent resistance to piling and abrasion. Mercerization enhances all these inherent qualities.'
            },
            {
                title: 'Crewel Embroidery',
                description: 'An embroidery made with colored wool yarns stitched on unbleached cotton or linen, usually in a vine or leaf formation with floral details added. Its popularity began in England during the late 17th century.'
            },
        ]
    },
    {
        'D': [
            {
                title: 'Damask',
                description: 'A patterned fabric with a reversible design of contrasting satin and dull surfaces. Most commonly woven in silk, cotton or linen, it may, however, consist of a combination of these or other fibers. Origin: Damascus in Asia Minor.'
            },
            {
                title: 'Dimensional Stability',
                description: 'The degree to which a fabric will retain its original shape in various atmospheric conditions.'
            },
            {
                title: 'Direct Dyes',
                description: 'A category of dyes that are used on cellulosics and need no fixatives to secure them to the fabric.'
            },
            {
                title: 'Dobby',
                description: 'Small symmetrical figures in a regular repeat formed by adjustment of the harness attachment (chain) on a plain loom. Origin: Dobbin, a work horse—“Dobby boy” sat on top of the loom and drew up the warp threads.'
            },
            {
                title: 'Dupion',
                description: 'A silk reeled from double cocoons or dupions. This yarn has excellent tensil strength.'
            },
        ]
    },
    {
        'E': [
            {
                title: 'Embossed',
                description: 'An effect obtained by rolling fabric between engraved cylinders so that the design appears in relief on the face of the cloth.'
            },
        ]
    },
    {
        'F': [
            {
                title: 'Faille',
                description: 'A fabric of the rep variety where the construction of pronounced cross-ribs gives a corded effect.'
            },
            {title: 'Felt', description: 'An unwoven cloth of matted layers (usually wool) randomly arranged.'},
            {title: 'Figured Velvet', description: 'A patterned velvet formed by contrast in cut and uncut loops.'},
            {
                title: 'Frieze or Frise',
                description: 'Firm fabric with pile of uncut loops on the surface. Origin: French, frisé-curled.'
            },
        ]
    },
    {
        'G': [
            {
                title: 'Gauze',
                description: 'A light weight sheer in a plain weave which is translucent and somewhat transparent. Origin: Gaza, Palestine.'
            },
            {
                title: 'Glazed Chintz',
                description: 'Printed or plain cotton fabric with resin (starch or wax) finish applied by pressure over heated steam rollers resulting in a polished surface.'
            },
            {
                title: 'Greige Goods',
                description: 'Plain fabric coming directly off the loom before it has been bleached or finished. Used mainly for printing.'
            },
            {
                title: 'Gros Point',
                description: 'A non directional pile fabric that is warp-looped. It is hard-wearing and extremely resilient. Made of wool or synthetic fibers, it has larger loops than a frieze and resembles the ground area of needlepoint.'
            },
        ]
    },
    {
        'H': [
            {
                title: 'Herring-Bone',
                description: 'A twill weave in a zig-zag pattern. Alternating direction in threading the loom makes the chevron design run selvage to selvage.'
            },
        ]
    },
    {
        'I': [
            {
                title: 'Imberline',
                description: 'An effect produced by laying a variety of colors in the warp which reveals a stripe running through the overall design of the fabric. Origin: Adapted from cloth of the uniforms worn by the Swiss Guard, who serve the Vatican.'
            },
        ]
    },
    {
        'J': [
            {
                title: 'Jacquard Loom',
                description: 'A weaving device that manipulates a series of perforated cards that are attached to the top of the loom. The lifting or lowering of the warp that results make the most intricate designs possible. This revolutionary technique was developed in France by Joseph Jacquard at the turn of the 19th Century.'
            },
        ]
    },
    {
        'L': [
            {
                title: 'Leno',
                description: 'Construction used in all good quality open mesh casement cloths. The warp yarns arranged in pairs twist one around the other over the filling yarn making the figure eight. The interlocking (chain) prevents the yarns from slipping. Origin: French, lin-flax.'
            },
            {
                title: 'Lightfastness',
                description: 'A fabric being tested for lightfastness is exposed to the equivalent of an arc welder\'s torch at a distance of 12 to 18 inches. The fabric is removed at regular intervals and compared to an unexposed control sample to assess fading. The light from this test is much more intense than what the sun produces. Human skin could not withstand being exposed to this light at this distance even for one hour. Now imagine 500 or 1,500 hours, and the fabric does not fade! 1,500 hours is the standard for Bella Dura and Sunbrella. Lightfassness hours do not equate to sunlight hours. Typical indoor upholstery products are expected not to fade when exposed to this light for 40 hours, and indoor drapery for 60 hours. We have all been told not to look directly at an arc-welding torch when it is in use. That very bright light will damage your eyes. This damage is caused by two elements in that light: UV and Infrared (heat). UV and Infrared are the two components that cause fabric to fade. There is a great deal of confusion surrounding the interpretation of lightfastness hours in the design industry. The real question that designers need to have answered is how long will it last? The current trend in the industry is now to offer warranties for lightfastness on outdoor goods. The warranty gives the client the peace-of-mind that they need when specifying these products for their projects. You will find such a warranty on many of the Cote d\'Azur patterns. The warranties vary slightly in terms of length of time, but provide significant assurance against fading.'
            },
            {
                title: 'Linen',
                description: 'A cellulose yarn made from natural flax fibers. It is especially noted for its strength, texture and luster. Cool to the touch although lacking in resilience, it easily creases.'
            },
        ]
    },
    {
        'M': [
            {
                title: 'Matelasse',
                description: 'A double-woven cloth using two sets of warp and filler threads for an embossed pattern in one color that simulates a quilted effect.'
            },
            {
                title: 'Mercerized',
                description: 'A high-quality finishing process to cotton yarn where the application of caustic soda and tension develop a smooth lustrous surface.'
            },
            {
                title: 'Mohair',
                description: 'A wool-like fiber derived from the fleece of the Angora goat. It is renowned for its luxurious soft quality yet extremely hard wearing characteristic.'
            },
            {
                title: 'Moire',
                description: 'Fabric with “watered mark” motif which is achieved by application of intense but uneven pressure from the heated cylinders to a folded dampened rep cloth. The crushing process creates the irregular pattern.'
            },
        ]
    },
    {
        'N': [
            {
                title: 'Natural Fiber',
                description: 'Any textile fiber manufactured from an animal or vegetable source. Cotton, linen, silk and wool are the foremost examples.'
            },
            {
                title: 'Needlepoint',
                description: 'Hand embroidery in petit or gros point stitch on a canvas foundation.'
            },
            {
                title: 'Nylon',
                description: 'A synthetic fiber known for its resistance to abrasion, inherent elasticity and strength which make it ideal for use in upholstery fabrics. Nylon fabrics have a tendency to pill easily and to attract surface soil.'
            },
        ]
    },
    {
        'O': [
            {
                title: 'Ombre',
                description: 'A fabric made by laying in wefts of yarn that are closely colored hues that after weaving created a shaded effect. Origin: French, ombre—shadow.'
            },
            {
                title: 'Organzine',
                description: 'The best quality of silk yarn and the most costly to produce. It is twisted from many strands of raw silk and is used particularly for the warp in fine taffeta and damasks.\n'
            },
            {
                title: 'Outline Quilting',
                description: 'A hand guided quilting in which the stitching follows the motifs of the design in a printed fabric.\n'
            },
        ]
    },
    {
        'P': [
            {
                title: 'Percale',
                description: 'A superior quality plain-weave cloth of closely set combed and carded long staple cotton.'
            },
            {
                title: 'Petit Point',
                description: 'A needle point stitch made on cancas with one foundation thread in contrast to two or more threads of a gros point.'
            },
            {title: 'Piece Dyed', description: 'Cloth that is dyed in a vat by the bolt (full piece) after weaving.'},
            {
                title: 'Pilling or Shredding',
                description: 'When the filament breaks away from the yarn in a fabric or carpet due to friction or abrasion leaving residue on the surface of the fabric.'
            },
            {
                title: 'Plain Weave',
                description: 'The most basic method of interlocking warp and weft threads to form a cloth. Each filling thread passes alternately under and over the warp yarn to make a balanced construction. Also known as a Tabby, this is a strong weave and generally inexpensive to produce.'
            },
            {
                title: 'Polished Cotton',
                description: 'A combed and carded fabric in satin construction which has been calendered to give a high luster to the surface.'
            },
            {
                title: 'Polyester',
                description: 'A synthetic polymer fiber that is manufactured from coal, water and petroleum. It is strong and durable making a wrinkle resistant fabric. Unable to absorb moisture (to breath) it has a tendency to pill.'
            },
        ]
    },
    {
        'R': [
            {
                title: 'Railroad',
                description: 'To turn a fabric in a direction where the selvages are in a horizontal position. In a plain fabric or when the design is non directional, you can avoid making seams when the width of the goods will accommodate the height required. Some upholstery fabrics are designed in this manner to be used exclusively for furniture.'
            },
            {
                title: 'Rayon',
                description: 'The first synthetic fiber, rayon is derived from cellulose (a substance forming the framework of plants). Produced in 1884 by de Chardonnet, a French scientist, it has the basic characteristics of both silk and cotton. Viscose rayon which is used in many decorative fabrics is of a superior quality and is considered the best silk substitute.'
            },
            {
                title: 'Rep or Repp',
                description: 'A plain weave fabric produced by weaving large filling yarns through fine warp threads which result in distinct ribs running from selvage to selvage.'
            },
            {
                title: 'Repeat',
                description: 'One complete pattern of the fabric measured vertically and/or horizontally.'
            },
            {
                title: 'Resist or Reserve Printing',
                description: 'A general term for printing with a dye-resistant substance leaving only the background colored after a washed finish. Originally an 18th century method where a resist paste inhibited the indigo dye.'
            },
            {
                title: 'Roller Printing',
                description: 'A technique first developed in 1783 done with engraved metal cylinders. Each color of the design requires a separate cylinder. Sometimes referred to as cylinder or machine printing.'
            },
            {
                title: 'Rotary-Screen Printing',
                description: 'A process where the cloth moves under a machine operated series of fast moving tubes. The dyes are exuded from the inside through the pattern which perforates the tube. Each color requires a separate tube.'
            },
        ]
    },
    {
        'S': [
            {
                title: 'Sail Cloth',
                description: 'A plain woven cotton duck of medium weight that is piece dyed and usually comes in a wide range of colors.'
            },
            {
                title: 'Sateen',
                description: 'A satin weave fabric “usually” made of mercerized cotton in a light weight construction that is primarily used for drapery linings.'
            },
            {
                title: 'Satin',
                description: 'Very smooth, lustrous face with duller back on a fabric created by majority of warp yarns showing on the surface. Origin: China, zaytun (tzut’ing)—silk.'
            },
            {
                title: 'Satin Weave',
                description: 'A basic weave where the face of the fabric is almost entirely warp threads on the surface. Fabric made in this construction drapes well, but may be prone to snagging if too loosely woven with floats that are extra long.'
            },
            {
                title: 'Screen Printing',
                description: 'A hand or machine table printing process in which a stenciled screen held in a frame is positioned on the cloth and color is applied with a squeegee. Separate screens are required for each color of the pattern.'
            },
            {
                title: 'Selvage',
                description: 'The lengthwise edges of a piece of cloth, often a different weave of heavier threads. Intended to prevent raveling.'
            },
            {
                title: 'Sheers',
                description: 'Light weight translucent fabrics used mainly for undercurtains and casement treatments.'
            },
            {
                title: 'Shuttleless Loom',
                description: 'The filling yarns used in this machine are carried across the warp by a rapier instead of a shuttle. This allows much faster production. The resulting selvages have a cut edge which resembles fringe.'
            },
            {
                title: 'Silk',
                description: 'The natural protein fiber unwound from the cocoon of the silkworm. Silk is noted for its resilience, affinity for dyes and strength when woven into a fabric. It has a fine luxurious appearance but is very sensitive to light and is the most costly natural yarn.'
            },
            {
                title: 'Strie',
                description: 'A very fine irregular streaked effect made by a slight variance in the color of warp yarns. Origin: French – streaked.'
            },
            {
                title: 'Strike-Off',
                description: 'A trial sample of printed fabric made to indicate and verify color and pattern before printing quantity.'
            },
        ]
    },
    {
        'T': [
            {
                title: 'Tabby',
                description: 'A plain weave construction in which one warp thread passes over and under a single weft thread. The threads of the warp and weft are of the same size and set with the same number per square inch thereby resulting in a balanced weave.'
            },
            {
                title: 'Table Printing',
                description: 'A form of screen printing in which the cloth is stretched and secured to the top of a table and the screens are moved down the table either by hand or machine, pattern repeat by pattern repeat.'
            },
            {
                title: 'Taffeta',
                description: 'A plain weave that is reversible because the same size yarns are used for the warp and filler. The firm construction is light weight which gives the resulting fabric a crisp hand (feeling). Origin: Persian, taftan—to twist.'
            },
            {
                title: 'Tapestry',
                description: 'An intricate weave employing several sets of heavy filler yarns on a single warp which produce a multi-colored pattern. Originally made with large scale scenic designs that frequently illustrated a tale. They were used as decorative wall hangings but also provided insulation. Origin: Greek, tap’es—rug.'
            },
            {
                title: 'Toile',
                description: 'A refined cotton or linen print usually monochrome with intricately detailed pattern. The designs originally etched on copper rollers often depicted bucolic or rustic settings.'
            },
            {
                title: 'Toile de Jouy',
                description: 'Printed fabric made at Jouy in France by Philippe Oberkampf from 1760 to 1815. They were usually printed on white or off-white grounds in monotone red, blue, green or black.'
            },
            {
                title: 'Tussah',
                description: 'A rough silk extruded from the cocoons of uncultivated silkworms. Slubs appear in the yarn as it is spun which leave uneven depths of color especially after dyeing. Therefore fabric woven with tussah will have an irregular surface.'
            },
            {
                title: 'Twill',
                description: 'A basic weave where the filler threads pass over two or more ends in a regular progression. This creates a diagonal pattern. Origin: Scotland, twill—to make a diagonal effect.'
            },
        ]
    },
    {
        'U': [
            {
                title: 'Union Cloth',
                description: 'A cloth most often used for printing that is woven with blended yarns. The filler is usually twisted linen and cotton and the warp is generally cotton.'
            },
        ]
    },
    {
        'V': [
            {
                title: 'Velvet',
                description: 'A smooth fabric with a surface that is a short thick pile. This is manufactured by weaving two cloths face to face simultaneously, which are then cut apart by the shuttle knife as they come off the loom. The fabric is rich in appearance and soft to the touch.'
            },
        ]
    },
    {
        'W': [
            {
                title: 'Warp or End',
                description: 'The threads of a textile that run vertically through the loom and are parallel to the selvage.'
            },
            {
                title: 'Warp Print',
                description: 'A fabric where the design has been printed on the warp before it has been woven. This results in a pattern with an indistinct image similar to the technique of impressionist painting.'
            },
            {
                title: 'Weft or Filling',
                description: 'The horizontal yarns in a cloth which run selvage to selvage across the fabric.'
            },
            {
                title: 'Wool',
                description: 'The fiber made from the fleece of sheep. Noted for its elasticity and luster, it has an affinity for accepting rich color when dyed. Wool fibers vary in crimp, length and thickness. Wool fabrics are good insulators. The yarns are frequently spun from fleece of several breed of sheep.'
            },
        ]
    },
    {
        'Y': [
            {
                title: 'Yarn Dyed',
                description: 'Cloth that is woven with yarns that have been dyed prior to weaving. Most good quality fabrics are yarn dyed.'
            },
        ]
    },
]


const AlphabetLetters = () => {

    return <Grid container spacing={2}>
        {alphabet.map((letter) => {
            return <Grid item key={letter} style={{userSelect: 'none', cursor: 'pointer',height:60}}>
                <Text variant={'h5'}>
                    <AnchorLink
                        href={`#${letter}`}
                        style={{textDecoration:'none'}}
                    >
                        {letter}
                    </AnchorLink>
                </Text>
            </Grid>
        })}
    </Grid>
}

const DecoratingGlossary = () => {

    return <Container>
        <Box py={2}>
            <Text variant="h4" style={{textTransform: "capitalize"}}>
                Decorating Glossary
            </Text>
        </Box>
        <Box mt={2}>
            <AlphabetLetters/>
        </Box>
        <Box mt={2}>
            {glossary.map((term) => {
                return <Grid container direction={'row'} spacing={8} id={`${Object.keys(term)}`}>
                    <Grid item xs={1}>
                        <Text variant={'h5'}>{Object.keys(term)}</Text>
                    </Grid>
                    <Grid item container xs={11} spacing={2}>
                        {term[Object.keys(term)]?.map((termBasedOnLetter) => {
                            return <Grid item xs={6}>
                                <Text variant={'body1'} bold>{termBasedOnLetter.title}</Text>
                                <Text variant={'body1'} style={{marginTop: 5}}>{termBasedOnLetter.description}</Text>
                            </Grid>
                        })}
                    </Grid>
                </Grid>
            })}
        </Box>
        <ScrollBackToTop/>
    </Container>
}

export default DecoratingGlossary
