import PropTypes from "prop-types";
import { CardActionArea, Grid } from "@material-ui/core";
import Text from "../Text/Text";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontSize: 12,
    color: "inherit",
    marginBottom: 8
  },
  active: {
    borderBottom: "4px solid " + theme.palette.secondary.main,
  },
  next: {
    borderBottom: "4px solid #D9DADB",
    color: "#969798"
  },
}));

const Steps = (props) => {
  const classes = useStyles();
  const { steps, onChange, value, disableStep } = props;
  return (
    <Grid container direction={"row"} spacing={1}>
      {steps.map((step, index) => {
        let extraClass;
        if (index <= value) extraClass = classes.active;
        else extraClass = classes.next;

        return (
          <Grid item style={{ flex: 1 }}>
            <CardActionArea
              disabled={index >= disableStep}
              className={clsx(classes.root, extraClass)}
              onClick={() => onChange(index)}
            >
              <Text
                variant={"body1"}
                className={(classes.text)}
                bold={index === value}
              >
                {step}
              </Text>
            </CardActionArea>
          </Grid>
        );
      })}
    </Grid>
  );
};

Steps.propTypes = {
  steps: PropTypes.array.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disableStep: PropTypes.number,
};

Steps.defaultProps = {
  direction: "row",
};

export default Steps;
