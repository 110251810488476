import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { logoutUser } from "../../../store/auth/account/actions";
import { useCurrentCart } from "../../../hooks/cart";
import { useSnackbar } from "notistack";
import { BounceLoader } from "react-spinners";
import {openCartDrawer} from "../../../store/element/actions";
import Button from "../../Button/Button";

const useStyles = makeStyles({
  label: {
    // "&:hover": {
    //   fontWeight: "bold",
    // },
    fontSize: (props) => props.size || 12,
    textTransform: "capitalize",
  },
});

function CartButton({context, openCartDrawer}) {
  const { numberOfItems, monitoring } = useCurrentCart();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    if (monitoring) {
      enqueueSnackbar("Your cart is managed right now by another user");
    }
  }, [enqueueSnackbar, monitoring]);

  if (!context.Auth.isAuth) {
    return null
  }

  return (
    <Button classes={{label: classes.label}} onClick={() => openCartDrawer(1)}>
      {monitoring && (
        <BounceLoader
          css={"margin-right: 4px;"}
          color={"red"}
          loading
          size={15}
        />
      )}
      <b>Bag ({numberOfItems})</b>
    </Button>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      Cart: state.Cart,
      Auth: state.Auth
    },
  };
};
export default connect(mapStateToProps, { logoutUser, openCartDrawer })(CartButton);
