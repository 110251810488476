import {Box, Container, Grid, Typography} from "@material-ui/core";
import {connect} from "react-redux";
import {apiError} from "../../../store/auth/account/actions";
import Step from "../../../components/Step";
import {useEffect, useState} from "react";
import {addCountryCode, getAxiosConfig, mapper, parseJwt, useConfig} from '../../../utils'
import TermsStep from "./steps/TermsStep";
import axios from "axios";
import {API_URL} from "config";
import {withRouter} from 'react-router-dom'
import {ContactInformation} from "./steps/ContactInformationNew";
import {TaxExempt} from "pages/Account/CreateTradeAccount/steps/TaxExempt";
import {ErrorBox} from "pages/Account/CreateTradeAccount/steps/common/ErrorBox";
import {createTradeAccountDefaultValues} from "pages/Account/CreateTradeAccount/defaults";
import {legalTerms, tradeProgramAccordion, tradeProgramTaxExemptAccordion, USCountryID} from 'constants/constants'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { styled } from "@material-ui/core";
import {Accordion as MuiAccordion} from '@material-ui/core';
import {AccordionSummary as MuiAccordionSummary} from '@material-ui/core';
import {AccordionDetails as MuiAccordionDetails} from '@material-ui/core';
import {MemberInformation} from "pages/Account/CreateTradeAccount/steps/MemberInformationNew";
import {ProofOfBusiness} from "pages/Account/CreateTradeAccount/steps/ProofOfBusiness";
import {BusinessInformation} from "pages/Account/CreateTradeAccount/steps/BusinessInformation";
import {parseAddresses} from 'pages/Admin/Account/addressParser'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: 'none',
  // border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  // marginLeft: -theme.spacing(2),
  fontFamily: 'Chronicle',
  fontWeight: 600,
  padding: theme.spacing(1),
  backgroundColor: 'none',
  // theme.palette.mode === 'dark'
  //   ? 'rgba(255, 255, 255, .05)'
  //   : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIcon': {
    border: '1px solid #000000',
    borderRadius: '50%',
    padding: theme.spacing(0.5)
    // marginRight: theme.spacing(1)
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    fontFamily: 'Chronicle',
    marginLeft: theme.spacing(3),
    paddingLeft: 0,
    '& .MuiTypography-root': {
      fontWeight: 'bold',
      fontFamily: 'Chronicle',
      fontSize: '1.1rem'
    },
    '& MuiTypography-h5': {
      fontSize: 16
    }
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(7),
  paddingTop: 0,
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
  border: 'none'
}));

const CreateTradeAccount = (props) => {
  const {validatingUser, history, context} = props
  const userAuth = localStorage.getItem("authUser");
  const userInfo = userAuth ? parseJwt(JSON.parse(userAuth).access_token) : {};

  const user = {
    firstName: userInfo.name.split(" ")[0],
    lastName: userInfo.name.split(" ")[1],
    email: userInfo.email,
  };
  const [savedData, setSavedData] = useState(false);

  const [formData, setFormData] = useState(
    {
      ...createTradeAccountDefaultValues,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      firstNameOwner: '',
      lastNameOwner: '',
      emailOwner: '',
      typeOfProof: 'taxId',
      businessOwner: true,
      businessInformationSubmitted: false,
      contactInformationSubmitted: false,
      memberInformationSubmitted: false,
      proofOfBusinessSubmitted: false,
      firstLoad: true,
      skipOwner: false,
    });
  const [countryId, setCountryId] = useState(1);
  const [step, setStep] = useState(1);
  const [accepted, setAccepted] = useState(false);

  const [states] = useConfig(`config/states/?countryId=${countryId}`, []);
  // const [countries] = useConfig(`/config/countries`, []);
  const countries = context.Country.getAll.result

  // Upload dialogs
  const [proofOfBusinessFiles, setProofOfBusinessFiles] = useState([]);
  const [taxExemptFiles, setTaxExemptFiles] = useState({});
  const [taxExemptState, setTaxExemptState] = useState(1);

  // Prefilled values / states
  const isUSA = countryId === USCountryID;
  const [focus, setFocus] = useState(formData.businessFocus)

  // Handling states
  const [error, setError] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  // Grab country on load
  useEffect(() => {
    const url = `${API_URL}/geolocation`;
    axios
      .get(url, getAxiosConfig())
      .then((response) => {
        if (response.data && response.data.id) {
          setCountryId(response.data.id);
          setFormData((prevFormData) => ({
            ...prevFormData,
            country: response.data.id,
          }));
        }
        setIsBusy(false);
      })
      .catch((err) => {
        // Silent error
        setIsBusy(false);
        if (err.response && err.response.data && err.response.data.message) {
          console.log(err.response.data);
        }
        throw err;
      });

    // eslint-disable-next-line
  }, []);

  // Grab any saved applications if any
  useEffect(()=>{
    if (!savedData) {
      const url = `${API_URL}/trade-account/application/default`;
      axios
        .get(url, getAxiosConfig())
        .then((response) => {
          if (response.data && response.data.id) {
            let data = {
              ...response.data,
              ...mapper(response.data, [
                'ae','paymentTerms','shippingMethod', 'currencyCode',
                'salesTerritory', 'fedTaxId','businessType',
                'country', 'businessName', 'businessFocus', 'businessLink', 'otherBusinessType', 'businessPhone',
                'firstNameOwner', 'lastNameOwner', 'emailOwner'
              ],'')
            }

            const businessContact = data.contacts.find((c) => c.contactType === 'BUSINESS')

            let contactInformation = {
              country: businessContact?.countryId,
              countryId: businessContact?.countryId,
            }

            // Parse addresses
            if (data.contacts&&data.contacts.length!==0) {
              parseAddresses(contactInformation, data)
            }

            data = {
              ...data,
              ...contactInformation,
              businessName: (response.data.businessName||''),
              businessInformationSubmitted: Boolean(data.businessName),
              memberInformationSubmitted: true,
              contactInformationSubmitted: Boolean(data.contacts.length),
              businessOwner: response.data.businessOwner===null?null:Boolean(response.data.businessOwner),
              skipOwner: response.data.businessOwner===null?false:!Boolean(response.data.businessOwner) && !(data.firstNameOwner && data.lastNameOwner && data.emailOwner),
              proofOfBusinessSubmitted: (Boolean(data.fedTaxId)||Boolean(data.businessLink)),
              typeOfProof: Boolean(data.fedTaxId)?'taxId':'other',
              uploadedFiles: Boolean(data.proofOfBusinessFiles.length),
              focusOnResidentialHospitality: response.data.focusOnResidentialHospitality==='true'
            }

            setProofOfBusinessFiles(data.proofOfBusinessFiles)

            setSavedData(response.data);
            setFormData((prevFormData) => ({
              ...prevFormData,
              ...data
            }));

          }
          setIsBusy(false);
        })
        .catch((err) => {
          // Silent error
          setIsBusy(false);
          if (err.response&&err.response.data.status===409) {
            history.push('/join-trade-program/success')
          }
          if (err.response && err.response.data && err.response.data.message) {
            console.log(err.response.data);
          }
          throw err;
        });
    }
    // eslint-disable-next-line
  },[])

  // Cleanup on change step
  useEffect(() => {
    setError(false);
  }, [step]);

  const submitForm = (save) => {
    // Transform for payload

    const {
      title,
      businessType,
      focusOnResidentialHospitality,
      businessName,
      sameAsBusinessAddressBilling,
      sameAsBusinessAddressShipping,
      businessState,
      businessCity,
      businessZipCode,
      businessAddressLine1,
      businessAddressLine2,
      businessLink,
      shippingState,
      shippingCity,
      shippingZipCode,
      shippingAddressLine1,
      shippingAddressLine2,
      billingState,
      billingCity,
      billingZipCode,
      billingAddressLine1,
      billingAddressLine2,
      skipProofOfBusiness,
      fedTaxId,
      otherBusinessType,
      businessFocus,
      firstNameOwner,
      lastNameOwner,
      emailOwner,
      skipOwner,
      businessOwner,
      businessPhoneNumber,
      shippingPhoneNumber,
      billingPhoneNumber,
    } = formData;

    let payLoad = {
      title: title,
      businessFocus: businessFocus,
      businessType: businessType,
      businessName,
      businessOwner,
      otherBusinessType: businessType==='1'?otherBusinessType:'',
      businessLink: "",
      focusOnResidentialHospitality: focusOnResidentialHospitality,
      fedTaxId: fedTaxId,
      contacts: [],
      taxExemptForms: [],
      proofOfBusinessFiles: [],
      skipProofOfBusiness: skipProofOfBusiness,
      termsConditions: legalTerms,
    };

    // If not skipped owner , and is not the owner (we should have fields)
    if (!skipOwner&&!businessOwner) {
      payLoad = {
        ...payLoad,
        firstNameOwner: firstNameOwner?firstNameOwner:null,
        lastNameOwner: lastNameOwner?lastNameOwner:null,
        emailOwner: emailOwner?emailOwner:null
      }
    }

    const taxExemptStates = Object.keys(taxExemptFiles);
    if (taxExemptStates.length !== 0) {
      taxExemptStates.forEach((state) => {
        payLoad.taxExemptForms.push({
          stateId: state,
          files: [...taxExemptFiles[state].map((f) => f.id)],
        });
      });
    }

    if (!skipProofOfBusiness) {
      payLoad.proofOfBusinessFiles = [...proofOfBusinessFiles.map((f) => f.id)];
      payLoad.businessLink = businessLink;
    }

    if (businessAddressLine1) {
      // Business Address
      payLoad.contacts.push({
        name: '',
        contactType: "BUSINESS",
        email: user.email,
        phoneNumber: addCountryCode(countries, countryId, businessPhoneNumber),
        addressLine1: businessAddressLine1,
        addressLine2: businessAddressLine2,
        zipCode: businessZipCode,
        city: businessCity,
        stateId: businessState ? businessState : null,
        countryId: countryId,
      });
    }


    // Billing Address
    if (!sameAsBusinessAddressBilling) {
      payLoad.contacts.push({
        name: '',
        contactType: "BILLING",
        email: user.email,
        phoneNumber: addCountryCode(countries, countryId, billingPhoneNumber),
        addressLine1: billingAddressLine1,
        addressLine2: billingAddressLine2,
        zipCode: billingZipCode,
        city: billingCity,
        stateId: billingState ? billingState : null,
        countryId: countryId,
      });
    }

    // Shipping Address
    if (!sameAsBusinessAddressShipping) {
      payLoad.contacts.push({
        name: '',
        contactType: "SHIPPING",
        email: user.email,
        phoneNumber: addCountryCode(countries, countryId, shippingPhoneNumber),
        addressLine1: shippingAddressLine1,
        addressLine2: shippingAddressLine2,
        zipCode: shippingZipCode,
        city: shippingCity,
        stateId: shippingState ? shippingState : null,
        countryId: countryId,
      });
    }

    const applicationId = savedData.id

    let url = `${API_URL}/trade-account/application/${applicationId}/submit`;
    let reqType = 'post'

    if (save) {
      url = `${API_URL}/trade-account/application/${applicationId}`;
      payLoad.id = applicationId;
      reqType = 'put'
    } else {

    }

    setIsBusy(true);

    axios[reqType](url, payLoad, getAxiosConfig())
      .then((response) => {
        if (response.status === 200) {
          if (!save) {
            history.push('/join-trade-program/success')
          }
        }
        setIsBusy(false);
      })
      .catch((err) => {
        // Silent error
        setIsBusy(false);
        if (err.response && err.response.data && err.response.data.message) {
          console.log(err.response.data.message);
          setError(err.response.data);
        }
        throw err;
      });
  };

  // Scroll to top on page change (step)
  useEffect(() => {
    if (step) {
      window.scrollTo(0, 0);
    }
  }, [step]);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const saveApplication = async () => {
    submitForm(true)
  }

  useEffect(()=>{
    if (!formData.firstLoad) {
      submitForm(true)
    }
    // eslint-disable-next-line
  },[formData])

  const caSteps = {
    BUSINESS_INFORMATION: 'Business Information',
    MEMBER_INFORMATION: 'Member Information',
    CONTACT_INFORMATION: 'Contact Information',
    TAX_EXEMPT: 'Tax Exempt',
    BUSINESS_PROOF: 'Business Proof',
    TERMS: 'Terms & Conditions'
  }

  const stepsGeneral = [caSteps.BUSINESS_INFORMATION, caSteps.MEMBER_INFORMATION, caSteps.CONTACT_INFORMATION]
  const hasTaxExemption = isUSA && (formData.businessFocus === "RESIDENTIAL"||formData.businessFocus === "COMMERCIAL")
  const stepNames = [...stepsGeneral, ...(hasTaxExemption?[caSteps.TAX_EXEMPT]:[]),caSteps.BUSINESS_PROOF, caSteps.TERMS]

  if (!savedData) return <Container style={{minHeight: '80vh'}}> </Container>

  return (
    <>
      <Container style={{minHeight: '80vh'}}>
        <>
          <Box py={1}>
          </Box>
          <Grid container>
            <Grid item xs={12} md={6}>

              <Typography variant="h1" style={{fontWeight: 500, fontFamily: 'Chronicle', fontSize:32, paddingLeft: 8}}>
                Join Schumacher <br/> Trade Program
              </Typography>

              {stepNames[step-1]==='Terms & Conditions'&&<>

                <Box style={{fontWeight: 400, fontFamily: 'Chronicle', fontSize:'1.1rem', padding: 8, paddingRight: 64}}>
                  <p>
                    Congratulations! You are almost there!
                  </p>
                  <p>
                    Once you submit the application, our team will review it and get back to you within 2 business days or sooner.
                  </p>
                  <p>
                    In the meantime, start exploring the our broad selection and add what you like into Favorites.
                  </p>
                </Box>

              </>}

              {stepNames[step-1]==='Business Proof'&&<>
                <Box style={{fontWeight: 400, fontFamily: 'Chronicle', fontSize:'1.1rem', padding: 8, paddingRight: 64}}>
                  <p>
                    Help us verify you as a business to start enjoying all the Schumacher Trade Program benefits sooner!
                  </p>
                </Box>
              </>}

              {stepNames[step-1]==='Tax Exempt'&&<>

                <Box style={{fontWeight: 400, fontFamily: 'Chronicle', fontSize:'1.1rem', padding: 8, paddingRight: 64}}>
                  <p>Schumacher is required to charge sales tax on all of our products.</p> <br/>
                  <p>The good news? Submitting your tax exemption certificates is a breeze.</p>
                </Box>

                <Box pr={4}>
                  <div>
                    {tradeProgramTaxExemptAccordion.map((panel,i)=>{
                      return <Accordion expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)}>
                        <AccordionSummary aria-controls={`panel{i}d-content`} id={`panel{i}d-header`}>
                          <Typography>{panel.header}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box fontFamily={'Chronicle'} fontSize={18}>
                            {panel.content}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    })}
                  </div>
                </Box>
              </>}

              {step===3&&<Box style={{fontWeight: 400, fontFamily: 'Chronicle', fontSize:'1.1rem', padding: 8, paddingRight: 64}}>
                <p>
                  We promise to always deliver every package to where it’s meant to be. We can send billing information to a different address if needed.
                </p>
              </Box>}

              {step===2&&<Box style={{fontWeight: 400, fontFamily: 'Chronicle', fontSize:'1.1rem', padding: 8, paddingRight: 64}}>
                <p>
                  We want to always serve you the best we can. Let us get to know more about you.
                </p>
              </Box>}

              {step===1&&<Box style={{fontWeight: 400, fontFamily: 'Chronicle', fontSize:'1.1rem', padding: 8, paddingRight: 64}}>
                <p>
                  Our unique trade benefits are intended to help interior design professionals like you and your business to thrive.
                </p>
              </Box>}

              {step===1&&<Box pr={4}>
                <div>
                  {tradeProgramAccordion.map((panel,i)=>{
                    return <Accordion expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)}>
                      <AccordionSummary aria-controls={`panel{i}d-content`} id={`panel{i}d-header`}>
                        <Typography>{panel.header}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box fontFamily={'Chronicle'} fontSize={18}>
                          {panel.content}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  })}
                </div>
              </Box>}

            </Grid>

            <Grid item xs={12} md={6}>
              <Grid item xs={12}>
                <Box pt={5} pb={8}>
                  <Step
                    noOfSteps={hasTaxExemption ? 6 : 5}
                    currentStep={step}
                    names={stepNames}
                  />
                </Box>

              </Grid>

              {/* STEP 1 */}
              {step === 1 && (
                <Grid item container spacing={2}>
                  <BusinessInformation
                    setFormData={setFormData}
                    formData={formData}
                    setFocus={setFocus}
                    step={step}
                    setStep={setStep}
                    disableActions={validatingUser}
                    saveApplication={saveApplication}
                  />
                </Grid>
              )}

              {/* STEP 2 */}
              {step === 2 && (
                <Grid item container spacing={2}>
                  <MemberInformation
                    setFormData={setFormData}
                    formData={formData}
                    setFocus={setFocus}
                    countries={countries}
                    step={step}
                    setStep={setStep}
                    user={user}
                    setCountryId={setCountryId}
                    countryId={countryId}
                    proofOfBusinessFiles={proofOfBusinessFiles}
                    setProofOfBusinessFiles={setProofOfBusinessFiles}
                    accepted={accepted}
                    disableActions={validatingUser}
                    validatePhone={true}
                    saveApplication={saveApplication}
                  />
                </Grid>
              )}

              {/* STEP 3 */}
              {step === 3 && (
                <Grid item container spacing={2}>
                  <ContactInformation
                    formData={formData}
                    setFormData={setFormData}
                    step={step}
                    setStep={setStep}
                    countries={countries}
                    states={states}
                    accepted={accepted}
                    countryId={countryId}
                    setCountryId={setCountryId}
                    saveApplication={saveApplication}
                  />
                </Grid>
              )}

              {/* Flow with Tax exempt */}

              {hasTaxExemption && (
                <>
                  {/* STEP 4 */}
                  {step === 4 && (
                    <Grid item container spacing={2}>
                      <TaxExempt
                        setTaxExemptFiles={setTaxExemptFiles}
                        taxExemptFiles={taxExemptFiles}
                        setTaxExemptState={setTaxExemptState}
                        taxExemptState={taxExemptState}
                        states={states}
                        countryId={countryId}
                        setStep={setStep}
                        step={step}
                        formData={formData}
                        setFormData={setFormData}
                        isBusy={isBusy}
                        accepted={accepted}
                        saveApplication={saveApplication}
                      />
                    </Grid>
                  )}

                  {/* STEP 5 */}
                  {step === 5 && (
                    <Grid item container spacing={2}>

                      <ProofOfBusiness
                        setFormData={setFormData}
                        formData={formData}
                        setFocus={setFocus}
                        countries={countries}
                        step={step}
                        setStep={setStep}
                        user={user}
                        setCountryId={setCountryId}
                        countryId={countryId}
                        proofOfBusinessFiles={proofOfBusinessFiles}
                        setProofOfBusinessFiles={setProofOfBusinessFiles}
                        accepted={accepted}
                        disableActions={validatingUser}
                        validatePhone={true}
                        saveApplication={saveApplication}
                      />
                    </Grid>
                  )}

                  {/* STEP 6 */}
                  {step === 6 && (
                    <Grid item container spacing={2}>

                      <TermsStep
                        focus={focus}
                        isUSA={isUSA}
                        accepted={accepted}
                        setAccepted={setAccepted}
                        setStep={setStep}
                        step={step}
                        submitForm={submitForm}
                        isBusy={isBusy}
                        saveApplication={saveApplication}
                        setFormData={setFormData}
                      />
                    </Grid>
                  )}
                </>
              )}

              {/* Flow without Tax exempt */}
              {(!isUSA || formData.businessFocus === "HOSPITALITY") && (
                <>

                  {/* STEP 4 */}
                  {step === 4 && (
                    <Grid item container spacing={2}>

                      <ProofOfBusiness
                        setFormData={setFormData}
                        formData={formData}
                        setFocus={setFocus}
                        countries={countries}
                        step={step}
                        setStep={setStep}
                        user={user}
                        setCountryId={setCountryId}
                        countryId={countryId}
                        proofOfBusinessFiles={proofOfBusinessFiles}
                        setProofOfBusinessFiles={setProofOfBusinessFiles}
                        accepted={accepted}
                        disableActions={validatingUser}
                        validatePhone={true}
                        saveApplication={saveApplication}
                      />
                    </Grid>
                  )}

                  {/* STEP 5 */}
                  {step === 5 && (
                    <Grid item container spacing={2}>

                      <TermsStep
                        accepted={accepted}
                        setAccepted={setAccepted}
                        setStep={setStep}
                        step={step}
                        submitForm={submitForm}
                        isBusy={isBusy}
                        saveApplication={saveApplication}
                        setFormData={setFormData}
                      />
                    </Grid>
                  )}
                </>
              )}

              {error && <ErrorBox error={error}/>}
            </Grid>
          </Grid>
        </>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => {
  const {error, loading, isAuth, userDetails, validatingUser} = state.Auth;
  return {error, loading, isAuth, userDetails, validatingUser, context: { Country: state.Country} };
};

export default withRouter(connect(mapStateToProps, {apiError})(CreateTradeAccount));
