import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import ActivityCard from "../ActivityCard";
import GreetingText from "../GreetingText";

const useStyles = makeStyles({
  wrapper: {
    height: "100%",
  },
});

const NoActivities = ({ noActivities, visibleProfile }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.wrapper} lg={4} md={3} sm={6} xs={12} item>
      <GreetingText
        text={"Start with these:"}
        greeting={"Welcome,"}
        user={visibleProfile}
      />
      <Grid container direction={"column"} spacing={2}>
        {noActivities.map((dummy, i) => {
          return (
            <Grid key={i} item>
              <ActivityCard
                activity={dummy}
                actionPath={dummy.actionPath}
                href={dummy.href}
                target={dummy.target}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default NoActivities;
