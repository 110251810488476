import {Box, Collapse, Container, Grid} from "@material-ui/core";
import Text from "../../components/Text/Text";
import React, {useState} from "react";


const faqsLeft = [
    {
        question: 'How can I purchase Schumacher products?',
        answer: `<p>Our products are sold to the trade and can be purchased online or through our network of showrooms and authorized agents.</p>
<p>If you are an interior designer and have trade and web accounts with Schumacher, you can <a href="#" onclick='document.getElementById("signInButton").click()'>log in</a> to purchase items online.</p>
<p>If you are an interior designer and have a trade account with Schumacher but no web account, you can <a href = "/signup">register online.</a></p>
<p>If you are an interior designer but do not yet have a trade account with Schumacher, you can <a href = "/signup">apply online.</a></p>
<p>If you are not a trade professional, please contact an <a href="/pages/find-designer">interior designer </a> or a <a href="/pages/our-showroom">Schumacher showroom</a> in your area for a referral.</p>
<p>For any questions, please call our customer service department at <strong>800-523-1200</strong> or contact the Schumacher showroom nearest you.</p>`
    },
    {
        question: 'How do I open a trade account with Schumacher?',
        answer: `<p>A Schumacher trade account allows you to purchase the full range of our products. To open a trade account, you must have a resale number.</p>
                <p>You can apply for a trade account online.</p>
                <p>You can also apply through the Schumacher showroom nearest you.</p>
                <p>For any questions, please contact Customer Service at <br/><strong>800-523-1200</strong>.</p>
                <p>*All applications are subject to review and approval by Schumacher.</p>`
    },
    {
        question: 'How do I check the status of my order?',
        answer: `<p>You can log into your Schumacher web account to check the status of your order. You can also contact our customer service department at <strong>800-523-1200</strong>.</p>
                 <p>If you have a web account with Schumacher, you can check the status of your order in the Orders tab of your My Schumacher page.</p>
                 <p>If you do not have a web account, you can register online.</p>
                <p>For any questions, please call our customer service department at <strong>800-523-1200</strong> or contact the Schumacher showroom nearest you.</p>`
    },
    {
        question: 'How can I check stock and pricing on your products?',
        answer: `<p>Log into your Schumacher web account to check stock and pricing on our full inventory of fabrics and wallcoverings. You can also contact our customer service department at <strong>800-523-1200</strong>. If you are an interior designer and have trade and web accounts with Schumacher, you can log in to find stock and price information on individual product pages. You can also access the Stock Availability tab in your My Schumacher page. If you are an interior designer and have a trade account with Schumacher but no web account, you can register online. If you are an interior designer but do not yet have a trade account with Schumacher, you can apply online. If you are not a trade professional, please contact an interior designer or a Schumacher showroom in your area for a referral.</p>
                <p>For any questions, please call our customer service department at <strong>800-523-1200</strong> or contact the Schumacher showroom nearest you.</p>`
    },
    {
        question: 'How do I find out who my sales representative is?',
        answer: `<p>Please call Customer Service at <strong>800-523-1200</strong> or contact the Schumacher showroom nearest you.</p>`
    },
]

const faqsRight = [
    {
        question: 'Do you provide custom design services?',
        answer: `<p>Yes, we are a premiere source of custom fabrics, wallcoverings, and trim for the design community. To learn more about our custom work—including commercial, residential, and historic restoration projects—please visit our Custom Product page or contact our Contract Department at <strong>800-683-0032</strong>.</p>`
    },
    {
        question: 'How do I submit a claim for damaged and/or defective product?',
        answer: `<p>All claims should be submitted in writing with a detailed explanation of the problem.
                Please include your account number, account name, reference number, contact name, and telephone number.
                Please email <a href = 'mailto:claims@fsco.com'>claims@fsco.com</a> or contact our Claims Department at <strong>800-523-1200</strong>.</p>`
    },
    {
        question: 'Where can I purchase Patterson Flynn Martin Floorcoverings?',
        answer: `<p>Patterson Flynn Martin floorcoverings are distributed exclusively through the company’s showrooms in design centers across the country. For more information, call <strong>212.688.7700</strong> or visit <a href="https://www.pattersonflynn.com/" target="_blank">www.pattersonflynn.com</a>.</p>`
    },
    {
        question: 'Why work with an interior designer?',
        answer: `<p>Interior designers don’t just make rooms look good—they make rooms work. A refined aesthetic is the most obvious asset in a good designer’s tool kit, but designers also know the furnishings market inside and out. And perhaps most importantly, they understand how to plan and execute functional spaces, balancing a room’s technical limitations and performance requirements to create a beautiful, livable space. Working with a designer will not only save you time, but also help you avoid costly mistakes (and the accompanying stress), drawing on their talent and resources to bring your vision to life. Learn more about selecting the right designer for you.</p>`
    },
]

const FaqHeader = ({question, answer}) => {
    const [open, setOpen] = useState(false)

    return <Grid item xs={11} style={{marginTop:10}}>
        <Text onClick={() => setOpen(!open)} variant={'h5'} style={{userSelect:'none',cursor:'pointer'}}>{question}</Text>
        <Collapse in={open}>
            <Text variant={'body1'}><div dangerouslySetInnerHTML={{__html: answer}}/></Text>
            <Text variant={'body1'} onClick={() => setOpen(!open)} style={{textDecoration:'underline',userSelect:'none',cursor:'pointer'}}>View Less</Text>
        </Collapse>
    </Grid>
}

const Faq = (props) => {
    return <Container>
        <Box py={2}>
            <Text variant="h4" style={{textTransform: "capitalize"}}>
                FAQs
            </Text>
        </Box>
        <Box>
            <Grid container spacing={4}>
                <Grid item sm={6}>
                    {faqsLeft.map((faq, idx) => {
                        return <FaqHeader question={faq.question} answer={faq.answer} key={idx}/>
                    })}
                </Grid>
                <Grid item sm={6}>
                    {faqsRight.map((faq, idx) => {
                        return <FaqHeader question={faq.question} answer={faq.answer} key={idx}/>
                    })}
                </Grid>
            </Grid>
        </Box>
    </Container>
}

export default Faq