import React, { useEffect, useState } from "react";
import { fetchData, getRoles } from "../../utils";
import {
  Box,
  Container, CssBaseline,
  Fab,
  Fade,
  Grid, MuiThemeProvider,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useAppTheme } from "../../constants/theme";
import Text from "../../components/Text/Text";
import { ADMIN_API_URL, API_URL } from "../../config";
import { Edit, Save } from "@material-ui/icons";
import { userRoles } from "../../constants/enums";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { updateData } from "reactcoregk/utils";
import { useSnackbar } from "notistack";
import {Editor, initState} from "./pageBuilder";

function loadState(blockString) {
  try {
    const stored = JSON.parse(blockString);
    return initState(stored);
  } catch (e) {
    return [];
  }
}

function ArticleSkeleton() {
  return (
    <Grid container spacing={2} style={{ marginTop: 16 }}>
      <Grid item xs={12}>
        <Skeleton variant={"rect"} height={50} width={400} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant={"rect"} width={"100%"} height={400} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant={"rect"} width={"100%"} height={400} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant={"rect"} height={40} width={200} />
        <Box mt={2} />
        <Skeleton variant={"rect"} height={200} width={"100%"} />
        <Box mt={2} />
        <Skeleton variant={"rect"} height={60} width={100} />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  actions: {
    position: "absolute",
    bottom: 0,
    right: 0,
    margin: 16,
    display: "flex",
    flexDirection: "column",
  },
  root: {
    minHeight: "calc(100vh - 80px)",
  },
});

export default function DynamicPage(props) {
  const classes = useStyles();
  const slug = props.match.params.slug;
  const [state, setState] = useState([]);
  const theme = useAppTheme();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState({});
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    fetchData(`${API_URL}/pages/${slug}`)
      .then((data) => {
        setLoading(false);
        setPage(data);
      })
      .catch((ex) => {
        setLoading(false);
      });
  }, [slug]);

  const onEdit = () => {
    setEditing(true);
  };

  const onSave = async () => {
    const url = `${ADMIN_API_URL}/manage/pages/${page.id}`;
    setSaving(true);
    try {
      const body = JSON.stringify(state);
      const payload = {
        ...page,
        body,
      };
      await updateData(url, payload);
      setEditing(false);
      enqueueSnackbar((page?.title || "Page") + "updated.", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
    setSaving(false);
  };

  const onDiscard = () => {
    setEditing(false);
    const newState = loadState(page?.body);
    setState(newState);
  };

  useEffect(() => {
    const newState = loadState(page?.body);
    setState(newState);
  }, [page?.body]);

  const renderAdminControls = () => {
    if (getRoles()[0] !== userRoles.ADMIN) return null;
    return (
      <div className={classes.actions}>
        <Fade in={editing}>
          <Fab
            color={"secondary"}
            onClick={onSave}
            disabled={loading || saving}
            style={{ marginBottom: 8 }}
          >
            <Save />
          </Fab>
        </Fade>
        {editing && (
          <Fab
            color={"primary"}
            disabled={loading || saving}
            onClick={onDiscard}
          >
            <CloseIcon />
          </Fab>
        )}
        {!editing && (
          <Fab
            color={"secondary"}
            disabled={loading || saving}
            onClick={onEdit}
          >
            <Edit />
          </Fab>
        )}
      </div>
    );
  };

  return (
    <>
      <CssBaseline/>
      <Container className={classes.root}>
        {loading && <ArticleSkeleton />}
        {!loading && (
          <Box py={2}>
            <Text variant="h4" style={{ textTransform: "capitalize" }}>
              {page?.title}
            </Text>
          </Box>
        )}
        <MuiThemeProvider theme={theme}>
          <Box my={editing ? 4 : 1}>
            <Editor
                state={state}
                setState={setState}
                isEditing={editing}
                mediaSourceUpdate={null}
                clearSourceUpdate={() => {}}
                onSelectMedia={() => {}}
            />
          </Box>
        </MuiThemeProvider>
        {renderAdminControls()}
      </Container>
    </>
  );
}
