// import MaterialTable from "material-table";
import {Grid} from "@material-ui/core";
// import TableContainer from "@material-ui/core/TableContainer";
// import Table from "@material-ui/core/Table";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import TableBody from "@material-ui/core/TableBody";
// import {makeStyles} from "@material-ui/styles";
import {Pagination} from "@material-ui/lab";
import ProductDialog from "../Catalog/ProductDialog";
import {useEffect, useState} from "react";
// import CatalogFilters from "../../components/Core/CatalogFilters";
import DataTable from "../../components/DataTable/DataTable";

const adminData = {
    "content": [
        {
            "id": 7,
            "itemNumber": "179700",
            "masterSeriesId": 5,
            "category": {
                "id": 2,
                "name": "FABRIC"
            },
            "name": "TRACING STRIPES",
            "description": "Hand-done vertical stripes make a bold and graphic statement that’s softened just enough by the echo of delicate tracing. Also a wallcovering.",
            "colorName": "SKY",
            "priceUsd": 138.0,
            "priceCad": 172.5,
            "currency": {
                "name": "USD",
                "code": "U+0024",
                "symbol": "$"
            },
            "images": [
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/179700.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/179700.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/179700-1.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/179700-1.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/179700-2.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/179700-2.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/179700-3.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/179700-3.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/179700-4.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/179700-4.jpg"
                }
            ]
        },
        {
            "id": 8,
            "itemNumber": "179692",
            "masterSeriesId": 6,
            "category": {
                "id": 2,
                "name": "FABRIC"
            },
            "name": "CIRCUIT",
            "description": "A continuous line interspersed with elegantly balanced abstract shapes turns this design into a Calder-esque masterpiece done in beautiful, sophisticated colorways. Also a wallcovering.",
            "colorName": "CARBON",
            "priceUsd": 124.0,
            "priceCad": 155.0,
            "currency": {
                "name": "USD",
                "code": "U+0024",
                "symbol": "$"
            },
            "images": [
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/179692.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/179692.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/179692-1.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/179692-1.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/179692-2.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/179692-2.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/179692-3.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/179692-3.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/179692-4.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/179692-4.jpg"
                }
            ]
        },
        {
            "id": 9,
            "itemNumber": "80270",
            "masterSeriesId": 7,
            "category": {
                "id": 2,
                "name": "FABRIC"
            },
            "name": "DAKOTA STRIPE",
            "description": "This medium-scale woven cotton stripe recalls wide open skies and desert vistas. A combination of weaving and embroidery puts a sophisticated spin on the camp-blanket vibes.",
            "colorName": "NEUTRAL",
            "priceUsd": 119.0,
            "priceCad": 149.0,
            "currency": {
                "name": "USD",
                "code": "U+0024",
                "symbol": "$"
            },
            "images": [
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/80270.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/80270.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/80270-1.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/80270-1.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/80270-2.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/80270-2.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/80270-3.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/80270-3.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/80270-4.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/80270-4.jpg"
                }
            ]
        },
        {
            "id": 10,
            "itemNumber": "179580",
            "masterSeriesId": 8,
            "category": {
                "id": 2,
                "name": "FABRIC"
            },
            "name": "ABELINO",
            "description": "With hand-drawn, irregular zigzags and dots, Abelino is a small-scale, Art Deco-influenced geometric camel-and-black fabric with a unique artistic flair. Part of our Copacabana collection. Also available as a wallcovering.",
            "colorName": "CAMEL & BLACK",
            "priceUsd": 88.0,
            "priceCad": 110.0,
            "currency": {
                "name": "USD",
                "code": "U+0024",
                "symbol": "$"
            },
            "images": [
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/179580.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/179580.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/179580-1.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/179580-1.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/179580-2.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/179580-2.jpg"
                }
            ]
        },
        {
            "id": 11,
            "itemNumber": "WOOD001",
            "masterSeriesId": 9,
            "category": {
                "id": 2,
                "name": "FABRIC"
            },
            "name": "WOODPERRY",
            "description": "Classic yet modern, Woodperry is an updated take on a botanical stripe. Woven in England, the natural linen ground gives this artful, leafy pattern subtlety and depth. Also available as a wallcovering.",
            "colorName": "BLUE",
            "priceUsd": 98.0,
            "priceCad": 122.5,
            "currency": {
                "name": "USD",
                "code": "U+0024",
                "symbol": "$"
            },
            "images": [
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/WOOD001.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/WOOD001.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/WOOD001-1.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/WOOD001-1.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/WOOD001-2.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/WOOD001-2.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/WOOD001-3.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/WOOD001-3.jpg"
                }
            ]
        },
        {
            "id": 12,
            "itemNumber": "177380",
            "masterSeriesId": 10,
            "category": {
                "id": 2,
                "name": "FABRIC"
            },
            "name": "ROLLING HILLS",
            "description": "Charming animals gambol within a naïve landscape in this whimsical full-coverage print. This design preserves the rustic simplicity of the embroidered original, which dates to 1960.",
            "colorName": "BLUES",
            "priceUsd": 140.0,
            "priceCad": 167.5,
            "currency": {
                "name": "USD",
                "code": "U+0024",
                "symbol": "$"
            },
            "images": [
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/177380.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/177380.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/177380-1.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/177380-1.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/177380-2.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/177380-2.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/177380-3.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/177380-3.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/177380-4.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/177380-4.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/177380-5.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/177380-5.jpg"
                }
            ]
        },
        {
            "id": 13,
            "itemNumber": "174452",
            "masterSeriesId": 11,
            "category": {
                "id": 2,
                "name": "FABRIC"
            },
            "name": "PYNE HOLLYHOCK",
            "description": "This chintz pattern was famously used by designer Albert Hadley for his client Nancy Pyne. It's one of our most sought-after designs. Also available as a wallcovering.",
            "colorName": "INDIGO",
            "priceUsd": 134.0,
            "priceCad": 160.0,
            "currency": {
                "name": "USD",
                "code": "U+0024",
                "symbol": "$"
            },
            "images": [
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/174452.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/174452.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/174452-1.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/174452-1.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/174452-2.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/174452-2.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/174452-3.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/174452-3.jpg"
                }
            ]
        },
        {
            "id": 14,
            "itemNumber": "173272",
            "masterSeriesId": 12,
            "category": {
                "id": 2,
                "name": "FABRIC"
            },
            "name": "CHIANG MAI DRAGON",
            "description": "An instant hit from the moment we introduced it, this is one of our best-loved designs. The chinoiserie motif was inspired by an Art Deco print. Also available as a wallcovering.",
            "colorName": "CHINA BLUE",
            "priceUsd": 164.0,
            "priceCad": 192.5,
            "currency": {
                "name": "USD",
                "code": "U+0024",
                "symbol": "$"
            },
            "images": [
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/173272.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/173272.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/173272-1.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/173272-1.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/173272-2.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/173272-2.jpg"
                },
                {
                    "thumbnailUrl": "https://s3.amazonaws.com/schumacher-webassets/thumb/173272-3.jpg",
                    "largeUrl": "https://s3.amazonaws.com/schumacher-webassets/173272-3.jpg"
                }
            ]
        }
    ],
    "pageable": {
        "sort": {
            "sorted": false,
            "unsorted": true,
            "empty": true
        },
        "offset": 0,
        "pageNumber": 0,
        "pageSize": 20,
        "paged": true,
        "unpaged": false
    },
    "last": true,
    "totalPages": 1,
    "totalElements": 8,
    "numberOfElements": 8,
    "number": 0,
    "sort": {
        "sorted": false,
        "unsorted": true,
        "empty": true
    },
    "first": true,
    "size": 20,
    "empty": false,
}

// const useStyles = makeStyles({
//     table: {
//         minWidth: 650,
//     },
// });

const ProductCatalog = () => {
    const [selectedProduct,setSelectedProduct] = useState()
    const [openPreview, setOpenPreview] = useState()
    // const [activeFilters, setActiveFilters] = useState([])
    // const [categoryFilter, setCategoryFilter] = useState('')
    //
    // const classes = useStyles();

    useEffect(()=>{
        if (selectedProduct) {
            setOpenPreview(true)
        }
    },[selectedProduct])

    const dataMapping = [
        {
            label: "Item number",
            key: "itemNumber"
        },
        {
            label: "Category",
            key: "category.name"
        },
        {
            label: "Name",
            key: "name"
        },
        {
            label: "Description",
            key: "description"
        },
        {
            label: "Color",
            key: "colorName"
        },
        {
            label: "Price",
            key: "priceUsd"
        }
    ]

    return <>
        <Grid container spacing={2} style={{padding:36}}>
            <Grid item xs={12}>
                {/*<CatalogFilters*/}
                {/*    setActiveFilters={setActiveFilters}*/}
                {/*    activeFilters={activeFilters}*/}
                {/*    filters={categoryFilter?.filters || []}*/}
                {/*/>*/}
            </Grid>
            <Grid item xs={12}>
                <DataTable mapping={dataMapping} data={adminData.content} />
            </Grid>
            <Grid item container justify='flex-end' spacing={2}>
                <Grid item>
                    <Pagination count={10} />
                </Grid>
            </Grid>
        </Grid>
        <ProductDialog
            open={openPreview}
            handleClose={() => {
                setOpenPreview(false)
                setSelectedProduct(null)
            }}
            itemNumber={selectedProduct?.itemNumber}
        />
    </>
}
export default ProductCatalog
