import React from "react";
import Dialog from "./Dialog/Dialog";
import Text from "./Text/Text";
import Box from "@material-ui/core/Box";
import { FSCO_EMAIL, FSCO_PHONE } from "../config";

function ContactUsDialog({ open, handleClose }) {
  return (
    <Dialog open={open} onClose={handleClose} title={"Contact Us"}>
      <Text variant={"body1"}>
        Questions about this product? Give us a call or email us to get more
        info about stock, ordering or anything else you might need to know.
      </Text>
      <Box display={"flex"} my={2}>
        <Box mr={1}>
          <Text variant={"body1"} bold>
            {FSCO_PHONE}
          </Text>
        </Box>
        <Text variant={"body1"}>
          <a href={`mailto:${FSCO_EMAIL}`}>{FSCO_EMAIL}</a>
        </Text>
      </Box>
    </Dialog>
  );
}

export default ContactUsDialog;
