export const orderItemType = {
  PRODUCT: "PRODUCT",
  SAMPLE: "SAMPLE",
  RESERVATION: "RESERVATION",
};

export const orderItemStatus = {
  RECEIVED: 1,
  PROCESSING: 2,
  SHIPPED: 3,
  DELIVERED: 4,
  CANCELLED: 5,
  BACKORDERED: 6
}

export const orderItemStatusOptions = [
  {
    id: orderItemStatus.RECEIVED,
    name: "Received"
  },
  {
    id: orderItemStatus.PROCESSING,
    name: "Processing"
  },
  {
    id: orderItemStatus.SHIPPED,
    name: "Shipped"
  },
  {
    id: orderItemStatus.DELIVERED,
    name: "Delivered"
  },
  {
    id: orderItemStatus.CANCELLED,
    name: "Canceled"
  },
  {
    id: orderItemStatus.BACKORDERED,
    name: "Back-ordered"
  },
]

export const relationshipType = {
  MASTER_SERIES: "MASTER_SERIES",
  SIMILAR_TO: "SIMILAR_TO",
  ALSO_AVAILABLE_IN: "ALSO_AVAILABLE_IN",
  SIZE_VARIATION: "SIZE_VARIATION",
};

export const addressContactType = {
  BUSINESS: "BUSINESS",
  BILLING: "BILLING",
  SHIPPING: "SHIPPING",
};

export const paymentMethodOption = {
  PAY_NOW: 1,
  PAY_BY_TERM: 2,
};

export const userRoles = {
  ADMIN: 'ADMIN',
  WEB_USER: 'WEB_USER',
  TRADE_ACCOUNT_OWNER: 'TRADE_ACCOUNT_OWNER',
  TRADE_ACCOUNT_MEMBER: 'TRADE_ACCOUNT_MEMBER',
  INTERNAL_SALES_REP: 'INTERNAL_SALES_REP',
  ADMIN_MERCHANDISE: 'ADMIN_MERCHANDISE',
  ADMIN_CREATIVE_MARKETING: 'ADMIN_CREATIVE_MARKETING',
  NON_SIGNED: 'NON_SIGNED',
}

export const CHECKOUT_STEPS = ["SHIP TO", "SHIPPING", "PAYMENT", "REVIEW & SUBMIT"];

export const roleOptions = [
  {
    id: userRoles.WEB_USER,
    name: 'Web user',
    internal: false
  },
  {
    id: userRoles.TRADE_ACCOUNT_OWNER,
    name: 'Account Owner',
    internal: false
  },
  {
    id: userRoles.TRADE_ACCOUNT_MEMBER,
    name: 'Account Member',
    internal: false
  },
  {
    id: userRoles.INTERNAL_SALES_REP,
    name: 'Internal Sales Repr.',
    internal: true
  },
  {
    id: userRoles.ADMIN,
    name: 'Admin',
    internal: true
  },
  {
    id: userRoles.ADMIN_MERCHANDISE,
    name: 'Admin (Merchandise)',
    internal: true
  },
  {
    id: userRoles.ADMIN_CREATIVE_MARKETING,
    name: 'Admin (Creative Marketing)',
    internal: true
  }
]

export const applicationStatus = {
  NEW_APPLICATION: "NEW_APPLICATION",
  CLOSED : "CLOSED",
  FIRST_VETTING: "FIRST_VETTING",
  SECOND_VETTING: "SECOND_VETTING",
  DECLINED: "DECLINED",
  APPROVED : "APPROVED",
  TAX_FORM_REQUESTED_REM1: "TAX_FORM_REQUESTED_REM1",
  TAX_FORM_REQUESTED_REM2 : "TAX_FORM_REQUESTED_REM2"
}

export const userCategory = {
  DESIGNER: "DESIGNER",
  TRADE_PROFESSIONAL : "TRADE_PROFESSIONAL",
  DESIGN_ENTHUSIAST: "DESIGN_ENTHUSIAST",
  HOME_OWNER: "HOME_OWNER",
  DESIGN_PROFESSIONAL : "DESIGN_PROFESSIONAL"
}

export const userCategorySignUp = [
  {
    id: userCategory.DESIGN_PROFESSIONAL,
    name: 'Yes, I own or work for an interior design company',
  },
  {
    id: userCategory.DESIGN_ENTHUSIAST,
    name: 'No, I’m a design enthusiast',
  },
  {
    id: userCategory.HOME_OWNER,
    name: 'No, I’m a homeowner',
  }
]

export const applicationStatusOptions = [
  {
    id: "NEW_APPLICATION",
    name: "New Application"
  },
  {
    id: "FIRST_VETTING",
    name: "Sent vetting reminder #1"
  },
  {
    id: "SECOND_VETTING",
    name: "Sent vetting reminder #2"
  },
  {
    id: "TAX_FORM_REQUESTED_REM1",
    name: "Tax Form Requested Reminder #1"
  },
  {
    id: "TAX_FORM_REQUESTED_REM2",
    name: "Tax Form Requested Reminder #2"
  },
  {
    id: "CLOSED",
    name: "Closed application. Did not respond."
  },
  {
    id: "DECLINED",
    name: "Rejected Application"
  },
  {
    id: "APPROVED",
    name: "Approved Application"
  }
]

export const contactTypes = [
  'BUSINESS',
  'BILLING',
  'SHIPPING',
]

export const currencySymbols = {
  1: '$',
  2: 'AU$',
  3: 'CA$',
  4: 'Fr.',
  5: '¥',
  6: '€',
  7: 'NZ$',
  8: 'ZAR',
  9: 'S$',
  10: '£'
}

export const userStatus = {
  DISABLED: "DISABLED",
  ACTIVE: "ACTIVE"
}

export const userStatusOptions = [
  {
    id: "DISABLED",
    name: "Disabled"
  },
  {
    id: "ACTIVE",
    name: "Active"
  }
]

export const accountStatusOptions = [
  {
    id: "ACTIVE",
    name: "Active"
  },
  {
    id: "INACTIVE",
    name: "Inactive"
  }
]

export const businessTypes = {
  RESIDENTIAL: "RESIDENTIAL",
  HOSPITALITY: "HOSPITALITY",
  COMMERCIAL: "COMMERCIAL"
}

export const businessTypesOptions = [
  {
    id: businessTypes.RESIDENTIAL,
    name: "Residential Projects"
  },
  {
    id: businessTypes.HOSPITALITY,
    name: "Hospitality Projects"
  },
  {
    id: businessTypes.COMMERCIAL,
    name: "Commercial Projects"
  }
]

export const phoneStatus = {
  PHONE_NUMBER_EXISTS: 'PHONE_NUMBER_EXISTS',
  NO_ERROR : 'NO_ERROR'
}

export const catalogFilter = {
  CATEGORY: "CATEGORY",
  NEW_ARRIVAL: "NEW_ARRIVAL",
  FAVORITES: "FAVORITES",
  GLOBAL_SEARCH: "GLOBAL_SEARCH",
}
