import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import ActionCard from "../Cards/ActionCard/ActionCard";
import Carousel from "react-material-ui-carousel";

const useStyles = makeStyles({
  carouselElement: {
    width: "100%",
  },
});

function HomeCarousel({ cards }) {
  const classes = useStyles();
  return (
    <Carousel
      navButtonsProps={{
        // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
        style: {
          width: 35,
          height: 35,
          backgroundColor: "#ffffff9e",
          color: "black",
        },
      }}
      className={classes.carouselElement}
      indicators={false}
      autoPlay={false}
      navButtonsAlwaysVisible
    >
      {cards.map(({ text, image, action, actionPath }, i) => (
        <>
          <Grid item md={12} sm={12} xs={12} key={i}>
            <ActionCard image={image} title={text} actionName={action} actionPath={actionPath} />
          </Grid>
        </>
      ))}
    </Carousel>
  );
}

export default HomeCarousel;
