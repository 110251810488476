import PropTypes from "prop-types";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {openExternalProps} from "utils";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 300,
    backgroundColor: theme.palette.secondary.light
  },
  root: {
    maxWidth: 300,
    borderRadius: 0,
    color: '#1B1C1D'
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  focus: {
    display: 'none'
  }
}));

const CollectionBox = (props) => {
  const classes = useStyles(props);
  const { collectionImage, link, title, openExternally } = props;
  const linkProps = openExternally?openExternalProps:null
  return (
    <Card elevation={0} className={classes.root}>
      <CardActionArea component={Link} to={link} classes={{focusHighlight: classes.focus}} {...linkProps}>

        <Grid item container justify='left' alignItems='center' alignContent='center' spacing={2} className={classes.media}>
          <Grid item xs={12}>
            {collectionImage&&<CardMedia
              className={classes.media}
              image={collectionImage}
              title={title}
            />}
            {!collectionImage && (
              <Box p={4} fontSize={26} fontWeight={300} fontFamily={'Chronicle Display'}>
                {title}
              </Box>
            )}
          </Grid>
        </Grid>

        <Box className={classes.actions} py={1} pt={2} fontFamily={'Chronicle Display'} fontSize={20}>
          {title}
        </Box>
      </CardActionArea>
    </Card>
  );
};

CollectionBox.propTypes = {
  collectionImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["primary", "secondary"]),
};

CollectionBox.defaultProps = {
  variant: "primary",
};

export default CollectionBox;
