import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Image1 from "assets/images/Hero-Tile-1-4-Signed-out-view/Hero-Tile-1.jpg";
import Image2 from "assets/images/Hero-Tile-1-4-Signed-out-view/Hero-Tile-2.jpg";
import Image3 from "assets/images/Hero-Tile-1-4-Signed-out-view/Hero-Tile-3.jpg";
import Image4 from "assets/images/Hero-Tile-1-4-Signed-out-view/Hero-Tile-4.jpg";
import ActionCard from "../../Cards/ActionCard/ActionCard";

const useStyles = makeStyles({
  media: {
    height: 0,
    paddingTop: "125%", // 4:5
  },
  wrapper: {
    height: "100%",
  },
  root: {
    borderRadius: "0 !important",
  },
});

const cards = [
  {
    text: "New Arrivals",
    image: Image1,
    actionName: "Shop What's New",
    actionPath: "/new-arrivals",
  },
  {
    text: "Trending Now",
    image: Image2,
    actionName: "Shop Our Mystique Collection",
    actionPath: "/collections/mystique",
  },
  {
    text: "Exquisite Trims",
    image: Image3,
    actionName: "Be On the Cutting Edge",
    actionPath: "/catalog/3",
  },
  {
    text: "Iconic Schumacher",
    image: Image4,
    actionName: "Shop Schumacher Classics",
    actionPath: "/collections/schumacher-classics",
  },
];

function NonSignedIn() {
  const classes = useStyles();
  return (
    <Grid className={classes.wrapper} container spacing={4}>
      {cards.map(({ text, image, actionName, actionPath }, i) => (
        <Grid item md={3} sm={6} xs={12} key={i}>
          <ActionCard
            actionName={actionName}
            actionPath={actionPath}
            title={text}
            image={image}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default NonSignedIn;
