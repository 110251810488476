import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Fade,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useRef, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import PropTypes from "prop-types";
import Text from "../Text/Text";
import Button from "../Button/Button";
import { fetchData } from "../../utils";
import CloseIcon from "@material-ui/icons/Close";
import FooterSocialMedia from "components/Footer/SocialMedia";
import TypeWriter from "components/TypeWriter/TypeWriter";
import { discoverOptions } from "constants/constants";
import DiscoverButton from "components/DiscoverButton/DiscoverButton";
import axios from "axios";

const paperStyles = {
  borderRadius: 6,
  marginTop: 14,
};

const useStyles = makeStyles((theme) => ({
  searchBox: {
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    borderRadius: 30,
    paddingLeft: theme.spacing(2),
    fontSize: 16,
    position: "relative",
    color: "black",
    height: 56,
  },
  searchButton: {
    borderRadius: 30,
    minWidth: 114,
    height: 48,
    marginBottom: -1,
    marginRight: -26,
  },
  searchButtonLabel: {
    fontWeight: "bold",
  },
  clearButton: {
    marginRight: -26,
    marginBottom: -1,
  },
}));

function renderOption(option, inputValue,showFooter) {
  const matches = match(option._name, inputValue);
  const parts = parse(option._name, matches);
  return (
    <Text variant={"body1"} style={{ display: "flex", alignItems: "center" }}>
      <SearchIcon style={{ marginRight: 8 }} />
      <div>
        {(!option._extra && option._attribute && inputValue && showFooter) &&
        <>Shop <span style={{fontWeight:600}}>{option._attribute.join(',')}</span> by color | </>}
        {parts.map((part, index) => (
          <span
            key={index}
            style={{
              fontWeight: part.highlight ? 700 : 400,
            }}
          >
            {part.text}
          </span>
        ))}
      </div>
      {option._extra && (

        <Text
          color={"textSecondary"}
          bold
          style={{ fontSize: 12, marginLeft: 4, marginBottom: -2 }}
        >
         <span
             style={{
               fontWeight: 400,
             }}
         >
           {option._attribute &&
           <Text  variant={"body1"} style={{display: 'inline'}}>Shop <span style={{fontWeight:600}}>{option._attribute.join(',')}</span> by {option._shopByType} | </Text>}
          </span> {option._suggestedTerm?option._suggestedTerm:option._extra}
        </Text>
      )}
    </Text>
  );
}

const SearchBox = (props) => {
  const {
    url,
    nameProp,
    valueProp,
    onSubmit,
    placeholder,
    initialQuery,
    onFocus,
    autoOpen,
    autoSubmit,
    autoSubmitDelay,
    namePropFallback,
    valuePropFallback,
    extraProp,
    submitLabel,
    showIcon,
    animateRecommendations,
    showHeader,
    showFooter,
    hasText = () => false,
  } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(Boolean(autoOpen));
  const [options, setOptions] = useState([]);
  const [busy, setBusy] = useState(false);
  // const loading = open && options.length === 0;
  const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState(initialQuery || "");
  const initRef = useRef(false);
  const [placeholderVar, setPlaceholderVar] = useState("");
  const [focused, setFocused] = React.useState(false);
  const onInputFocus = () => setFocused(true);
  const onInputBlur = () => setFocused(false);

  // Allow for request cancellation through axios
  const CancelToken = axios.CancelToken;
  const cancel = useRef();
  const noCatSelected = new URLSearchParams(window.location.search).get("featuredPlacementId")===""

  useEffect(() => {
    (async () => {
      setBusy(true);
      if (inputValue === "" && noCatSelected) {
        hasText(false);
      }
      try {
        if (cancel.current) {
          cancel.current();
          setOptions([
            {
              categoryId: null,
              categoryName: null,
              collaboratorSlug: null,
              collectionSlug: null,
              name: null,
              productItemNumber: null,
              term: inputValue,
              _name: inputValue,
            },
          ]);
        }
        const response = await fetchData(url + inputValue, {
          cancelToken: new CancelToken(function executor(c) {
            cancel.current = c; // keep reference for cancellation
          }),
        });
        const resultData = response.content || response;
        const optionsResults = resultData.map((item) => ({
          ...item,
          _id: item[valueProp] || item[valuePropFallback],
          _name: item[nameProp] || item[namePropFallback],
          _extra: item[extraProp],
          _attribute:item["attributes"] || null,
          _shopByType:item["shopByType"] || null,
          _suggestedTerm:item["suggestedTerm"] || null,
        }));
        setOptions(optionsResults);
      } catch (e) {}
      setBusy(false);
    })();

    //disabling hasNext
    // eslint-disable-next-line
  }, [
    nameProp,
    inputValue,
    url,
    valueProp,
    // loading,
    namePropFallback,
    valuePropFallback,
    extraProp,
    CancelToken,
  ]);

  // Custom container with options (includes social media footer)
  const DropdownContainer = ({ children }) => (
    <Paper style={paperStyles}>
      {!inputValue && showHeader && (
        <Box
          width={"100%"}
          display="flex"
          justifyContent="flex-start"
          alignContent="center"
          alignItems="center"
          p={2}
          pt={3}
          pb={1}
        >
          <Box
            display="flex"
            fontFamily={"Chronicle Display"}
            fontSize={22}
            color={"#1B1C1D"}
          >
            What does your heart desire today?
          </Box>
        </Box>
      )}

      {children}

      {!inputValue && showFooter && (
        <Box
          width={"100%"}
          display="flex"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          p={4}
        >
          <Box display="flex">
            <FooterSocialMedia footerStyle={"dark"} />
          </Box>
        </Box>
      )}
    </Paper>
  );

  function renderEndAdornment() {
    if (open) {
      if (onSubmit && inputValue) {
        return (
          <Fade in={Boolean(inputValue)}>
            <Button
              onClick={() => {
                onSubmit(inputValue);
                setOpen(false);
              }}
              disabled={!onSubmit}
              classes={{
                root: classes.searchButton,
                label: classes.searchButtonLabel,
              }}
              color="secondary"
              variant="contained"
            >
              {submitLabel}
            </Button>
          </Fade>
        );
      }
      return null;
    }
    if (inputValue) {
      return (
        <IconButton
          className={classes.clearButton}
          onClick={() => {
            setInputValue("");
            hasText(inputValue === "" || !noCatSelected);
            onSubmit && onSubmit("");
          }}
        >
          <CloseIcon />
        </IconButton>
      );
    }
    return null;
  }

  const submitRef = useRef(null);
  const inputRef = useRef(null);

  const goToSearchInput = () => {
    if (inputRef) {
      inputRef.current.focus();
    }
    setOpen(true);
  };

  return (
    <Box className={classes.searchBox}>
      {showIcon && <DiscoverButton appearAt={230} action={goToSearchInput} />}
      {!open && animateRecommendations && (
        <TypeWriter setText={setPlaceholderVar} strings={discoverOptions} />
      )}
      <Autocomplete
        // freeSolo
        // autoSelect
        disableClearable
        value={value}
        id="product-search"
        style={{ flex: 1 }}
        noOptionsText={'No suggestions'}
        open={open && options?.length>0}
        onOpen={() => setOpen(true)}
        onClose={(e) => {
          // Ignore links in options
          if (e && e.relatedTarget && e.relatedTarget.tagName === "A") {
            setTimeout(() => {
              setOpen(false);
            }, 500);
          } else {
            setOpen(false);
          }
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            onSubmit(e.target.value);
            setOpen(false);
          }
        }}
        onChange={(event, newValue) => {
          setValue(newValue);
          onSubmit && onSubmit(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          clearTimeout(submitRef.current);
          if (initRef.current) {
            setInputValue(newInputValue);
            hasText(true);
            if (autoSubmit) {
              submitRef.current = setTimeout(() => {
                onSubmit(newInputValue);
              }, autoSubmitDelay);
            }
          }
        }}
        onFocus={() => {
          initRef.current = true;
          onFocus && onFocus();
          onInputFocus();
        }}
        onBlur={onInputBlur}
        filterOptions={(options, state) => options}
        PaperComponent={DropdownContainer}
        getOptionSelected={(option, value) => option._id === value}
        getOptionLabel={(option) => option._name || ""}
        options={options}
        clearOnEscape={false}
        // getOptionDisabled={(option) => option.social}
        clearOnBlur={false}
        renderOption={(option, { inputValue }) =>
          renderOption(option, inputValue,showFooter)
        }
        loading={busy}
        renderInput={(params) => (
          <TextField
            {...params}
            disableUnderline
            placeholder={
              !focused
                ? !animateRecommendations
                  ? placeholder
                  : open
                  ? placeholder
                  : `Discover ${placeholderVar}`
                : ""
            }
            autoFocus={autoOpen}
            id="input-with-icon-adornment"
            style={{ fontSize: 12 }}
            inputRef={inputRef}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: renderEndAdornment(),
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fontSize: 18 }} />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default SearchBox;

SearchBox.propTypes = {
  url: PropTypes.string.isRequired,
  nameProp: PropTypes.string,
  extraProp: PropTypes.string,
  namePropFallback: PropTypes.string,
  valueProp: PropTypes.string,
  valuePropFallback: PropTypes.string,
  onSubmit: PropTypes.func,
  onOptionSelect: PropTypes.func,
  autoSubmit: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  initialQuery: PropTypes.string,
  autoOpen: PropTypes.bool,
  autoSubmitDelay: PropTypes.number,
};

SearchBox.defaultProps = {
  nameProp: "name",
  valueProp: "id",
  placeholder: "Search...",
  initialQuery: "",
  autoSubmitDelay: 0,
  submitLabel: "Search",
  showIcon: false,
  animateRecommendations: false,
  showHeader: false,
  showFooter: false,
};
