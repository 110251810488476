import { orderItemType } from "../constants/enums";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCurrentCart } from "./cart";
import {fetchData, getUserId} from "../utils";
import { ApiEndpoint } from "../store/@core/endpoint";
import { EntityType } from "../store/@core/entityType";
import { createMap } from "reactcoregk/utils";
import { productAttributes } from "../constants/constants";
import {API_URL} from "../config";
import {useDispatch, useSelector} from "react-redux";
import {addMemoToCart, addProductToCart} from "../store/cart/actions";

export const useProduct = (itemNumber, isAuth) => {
  const [product, setProduct] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const userId = getUserId()

  useEffect(() => {
    (async () => {
      if (!itemNumber) return;
      setIsLoading(true);
      try {
        const productsUrl = isAuth?`${API_URL}/users/${userId}/products` : ApiEndpoint[EntityType.Product];
        const response = await fetchData(`${productsUrl}/${itemNumber}`);
        setProduct(response);
      } catch (e) {
        enqueueSnackbar(e.message, { variant: "error" });
      }
      setIsLoading(false);
    })();
  }, [enqueueSnackbar, itemNumber, isAuth,userId]);

  return {
    product,
    isLoading,
  };
};

export const useMemosAdded = (product, activeCart) => {
  const addedMemoItem = activeCart?.items.find(
    (item) =>
      item.type === orderItemType.SAMPLE && item.productId === product.id
  );
  return addedMemoItem?.quantity || 0;
};

export const useProductCart = (product) => {
  const dispatch = useDispatch();
  const cartContext = useSelector((state) => state.Cart)

  const {
    currentCartId,
    memo,
    product: productState,
    orderItemUpdate,
  } = cartContext;
  const { enqueueSnackbar } = useSnackbar();

  const {
    cart,
    numberOfItems,
    handleUpdateItem,
    handleRemoveItem,
    processingCart,
  } = useCurrentCart();

  const handleError = useCallback((error) => {
    if (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, [enqueueSnackbar])

  const addedMemoItem = cart?.items.find(
    (item) =>
      item.type === orderItemType.SAMPLE && item.productId === product.id
  );
  const addedProductItem = cart?.items.find(
    (item) =>
      item.type === orderItemType.PRODUCT && item.productId === product.id
  );
  const memosAdded = addedMemoItem?.quantity || 0;
  const productsAdded = addedProductItem?.quantity || 0;

  const handleAddProduct = useCallback(() => {
      dispatch(addProductToCart({
        productId: product.id,
        quantity: product.quantityMinimum,
        cartId: currentCartId,
        notes: "",
      }, handleError));
    },
    [dispatch, currentCartId, handleError, product.id, product.quantityMinimum]
  );

  const handleAddMemo = useCallback(() => {
     dispatch(addMemoToCart({
        productId: product.id,
        quantity: 1,
        cartId: currentCartId,
        notes: "",
      }, handleError));
    },
    [dispatch, currentCartId, handleError, product.id]
  );


  return {
    cart,
    numberOfItems,
    handleAddProduct,
    handleAddMemo,
    handleRemoveItem,
    handleUpdateItem,
    processingCart,
    memosAdded,
    productsAdded,
    addingMemo: memo.isLoading,
    addingProduct: productState.isLoading,
    updating: orderItemUpdate.isLoading,
    addedMemoItem,
    addedProductItem,
  };
};

export const useProductRules = (product) => {
  function has(attr) {
    return attr?.value[0]?.value === "true";
  }

  return useMemo(() => {
    const attrs = product.attributes || [];

    const attributeMap = createMap(attrs, "name");
    const sampleSupported = has(attributeMap.get("sampleSupported"));
    const digitalMemoAvailable = has(attributeMap.get("digitalMemoAvailable"));
    const repeatInfoAvailable = has(attributeMap.get("repeatInfoAvailable"));
    const reserveAvailable = has(attributeMap.get("reserveAvailable"));
    const purchaseAvailable = has(attributeMap.get("purchaseAvailable"));
    const orderAvailable = has(attributeMap.get("orderAvailable"));
    const cfaAvailable = has(attributeMap.get("cfaAvailable"));
    const backOrderAvailable = has(attributeMap.get("backOrderAvailable"));
    const generatedTearsheet = has(attributeMap.get("generatedTearsheet"));
    const sampleBackOrderAvailable = has(
      attributeMap.get("sampleBackOrderAvailable")
    );
    const priceAvailable = has(attributeMap.get("priceAvailable"));
    const inventoryAvailable = has(attributeMap.get("inventoryAvailable"));
    const sampleAvailable = has(attributeMap.get("sampleAvailable"));
    const directShipAvailable = has(attributeMap.get("directShipAvailable"));
    const quoteAvailable = has(attributeMap.get("quoteAvailable"));
    const tearsheetAvailable = has(attributeMap.get("tearsheetAvailable"));

    //TODO: To be changed filtered by slug | Manolis
    const priceBy = product?.attributes?.find((a)=>a.slug ==='priced-by')?.value[0]?.value
    return {
      sampleSupported,
      digitalMemoAvailable,
      repeatInfoAvailable,
      reserveAvailable,
      purchaseAvailable,
      orderAvailable,
      cfaAvailable,
      backOrderAvailable,
      sampleBackOrderAvailable,
      priceAvailable,
      generatedTearsheet,
      inventoryAvailable,
      sampleAvailable,
      directShipAvailable,
      quoteAvailable,
      tearsheetAvailable,
      priceBy,
    };
  }, [product.attributes]);
};

const hospitality =
  "The experts at Schumacher Hospitality can reinterpret the product you see here into a contract grade equivalent that meets all the demands of your commercial or hospitality project.";

export const useProductInfo = (product) => {

  const array = product?.attributes || [];
  const attributeMap = createMap(array, "slug");
  const availability = attributeMap.get("availability")?.value || [];

  const details = useMemo(() => {

    const result = array
      .filter((attr) => productAttributes.property_names.includes(attr.name))
      .map((attr) => ({
        label: attr.name,
        value: attr.value.map((x) => x.value).toString(),
      }));
    if (product.collection)
      result.push({
        label: "Collection",
        value: product.collection.name,
        path: `/collections/${product.collection.slug}`,
      });
    return result;
    // eslint-disable-next-line
  }, [product]);

  const specialAttributes = useMemo(() => {
    const array = product?.attributes || [];
    return array.find((attr) => attr.slug === "special-attribute")?.value || [];
  }, [product]);

  return {
    details,
    hospitality,
    availability,
    specialAttributes,
  };
};
