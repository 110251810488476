import {Box, Container, Grid} from "@material-ui/core";
import Text from "../../components/Text/Text";
import SocialMedia from "../../components/Footer/SocialMedia";
import {useState} from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import {contactUsForm} from "../../constants/forms";
import Button from "../../components/Button/Button";
import FormFields from "../../components/FormFields";

const ContactUs = () => {
    const [initialValues,] = useState({email: '', name: '', subject: '', message: ''})
    const contactValidation = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('This field is required'),
        name: Yup.string().required('This field is required'),
        subject: Yup.string().required('This field is required'),
        message: Yup.string().required('This field is required'),
    });
    return <Container>
        <Box py={2}>
            <Text variant="h4" style={{textTransform: "capitalize"}}>
                Contact Us
            </Text>
        </Box>
        <Box>
            <Grid container spacing={2}>
                <Grid item sm={5} xs={12} spacing={2}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Text variant={'body1'}>Our Customer Service team is available Monday through Friday, 9 am
                                to 6
                                pm EST.</Text>
                        </Grid>
                        <Grid item>
                            <Text variant={'body1'}>Have a question? Give us a call at <span
                                style={{textDecoration: 'underline', fontWeight: 600}}>1 800-523-1200</span> or email us
                                at <a
                                    href="mailto:info@fsco.com"
                                    style={{textDecoration: 'underline', fontWeight: 600}}>info@fsco.com</a>.</Text>
                        </Grid>
                        <Grid item>
                            <Text variant={'body1'}>Visit <a href={'/pages/our-showroom'}><span
                                style={{textDecoration: 'underline', fontWeight: 600}}>Our Showrooms</span></a> page to find
                                or contact a showroom near you.</Text>
                        </Grid>
                        <Grid item>
                            <SocialMedia footerStyle={'dark'}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={5} xs={12} style={{marginLeft: 'auto'}}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validateOnBlur={false}
                        validationSchema={contactValidation}
                    >
                        {(formik) => (
                            <FormFields form={contactUsForm} busy={false} validateOnBlur={false}>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        size={"large"}
                                        color={"secondary"}
                                        variant={"contained"}
                                        type={"submit"}
                                    >
                                        Send
                                    </Button>
                                </Grid>
                            </FormFields>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Box>
    </Container>
}

export default ContactUs