import React, {Fragment} from "react";
import Drawer from "@material-ui/core/Drawer";
import {connect} from "react-redux";
import {closeCartDrawer, openCartDrawer} from "../store/element/actions";
import CartWidget from "./CartWidget/CartWidget";
import {useCurrentCart} from "../hooks/cart";
import {updateCart,} from "../store/cart/actions";
import {makeStyles} from "@material-ui/core/styles";
import {useProgress} from "reactcoregk/hooks";

const useStyles = makeStyles({
  paper: {
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    overflow: "visible",
  },
});

function CartDrawer(props) {
  const {
    context,
    cartDrawerOn,
    closeCartDrawer,
    updateCart,
    openCartDrawer,
  } = props;
  const classes = useStyles();
  const { cartUpdate, memo, product } = context.Cart
  const updatingCart = cartUpdate.isLoading;

  // Open the cart drawer when a new item is added
  useProgress(null, () => openCartDrawer(1), null, memo);
  useProgress(null, () => openCartDrawer(2), null, product);

  const {
    cart,
    handleRemoveItem,
    processingCart,
    handleUpdateItem,
    handleRemoveItems,
  } = useCurrentCart();

  return (
    <Fragment>
      <Drawer
        classes={{ paper: classes.paper }}
        anchor={"right"}
        open={Boolean(cartDrawerOn)}
        onClose={closeCartDrawer}
      >
        <CartWidget
          cart={cart}
          cartDrawerOn={cartDrawerOn}
          cartContext={context.Cart}
          handleRemoveItem={handleRemoveItem}
          handleRemoveItems={handleRemoveItems}
          processingCart={processingCart}
          handleUpdateCart={updateCart}
          updatingCart={updatingCart}
          handleUpdateItem={handleUpdateItem}
          closeCartDrawer={closeCartDrawer}
        />
      </Drawer>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    cartDrawerOn: state.Element.cartDrawerOn,
    context: {
      Cart: state.Cart,
    },
  };
};
export default connect(mapStateToProps, {
  closeCartDrawer,
  updateCart,
  openCartDrawer
})(CartDrawer);
