import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Box, Grid, Typography} from '@material-ui/core'
import Button from "@material-ui/core/Button";
import SmallProductCard from "../SmallProductCard";
import ActionButton from "../../Core/ActionButton";
import GreetingText from "../GreetingText";
import SectionTitle from "../SectionTitle";
import { Link } from "react-router-dom";
import {useFavorites} from "../../../hooks/favorites";
import {getUserId} from "../../../utils";
import {connect} from "react-redux";
// import {addMemoToCart, addProductToCart} from "../../../store/cart/actions";

const useStyles = makeStyles({
  wrapper: {
    height: "100%",
  },
});

const WithActivities = ({ content, visibleProfile,context }) => {
  const classes = useStyles();
    const [favoriteProduct, setFavoriteProduct] = useState({});
    const userId = context.Cart.spoofingUserId || getUserId();
    useFavorites(favoriteProduct, [...content.relevantProducts,...content.lastViewedProducts], setFavoriteProduct, userId);
  return (
    <Grid className={classes.wrapper} md={4} xs={12} item>
      <GreetingText
          greeting={"Welcome,"}
        user={visibleProfile}
        secondLine={"Unlock your trade benefits today."}
      />
      {visibleProfile.hasNonSubmittedTradeAccountApplication&&<Button
        component={Link}
        to={"/account/verify"}
        variant={"contained"}
        style={{
          height: "56px",
          backgroundColor: "#fff",
          borderRadius: 2,
          fontWeight: "bold",
          boxShadow: "0px 0px 16px rgba(27, 28, 29, 0.1)",
        }}
      >
        Complete your account application
      </Button>
      }
      {visibleProfile.hasPendingTradeAccountApplication&&!visibleProfile.hasNonSubmittedTradeAccountApplication&&<>
        <Typography fontSize={24} fontWeight={'bold'} style={{textTransform:'uppercase'}}>Your application is pending review</Typography>
      </>}
      <Box mt={2} />
      <SectionTitle title={"You’ve got great taste."} />
      <Grid container spacing={2}>
        {content?.lastViewedProducts.map((product, index) => (
          <Grid item md={4} key={index}>
            <SmallProductCard handleFavorite={()=>setFavoriteProduct(product)} product={product} />
          </Grid>
        ))}
      </Grid>
      <Box mt={1}>
      <ActionButton title={"Recently Viewed"} actionPath={"/products/recently-viewed"}/>
      </Box>
      <Box mt={3} />
      <SectionTitle title={"We have a hunch you'll love these."} />
      <Grid container spacing={2}>
        {content?.relevantProducts.map((product, index) => (
          <Grid item md={4} key={index}>
            <SmallProductCard handleFavorite={()=>setFavoriteProduct(product)} product={product} />
          </Grid>
        ))}
      </Grid>
      <Box mt={1} />
      <ActionButton title={"See more"} actionPath={"/products/more"}/>
    </Grid>
  );
};
const mapStateToProps = (state) => {
    return {
        context: {
            Cart: state.Cart,
            Auth: state.Auth
        },
    };
};

export default connect(mapStateToProps, {})(WithActivities);
