import {useEffect, useRef, useState} from "react";

const TypeWriter = ({setText, strings=['']}) => {

  const [currentStringIndex, setCurrentStringIndex] = useState(null)
  const [currentString, setCurrentString] = useState(strings[0])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [state, setState] = useState('TYPING')

  const typeSpeed = 100,
    backSpeed = 60,
    backDelay = 700,
    startDelay = 500;

  const typingTimeOut = useRef()

  const typeLetter = () => {
    setText((prevText)=>prevText+currentString[currentIndex])
    setCurrentIndex((i)=>i+1)
  }

  const typeNextLetter = () => {
    typingTimeOut.current = setTimeout(typeLetter, typeSpeed)
  }

  useEffect(()=>{
    if (state==='TYPING') {
      if (currentIndex!==currentString.length) {
        typeNextLetter()
      } else {
        setTimeout(startErasing, backDelay)
      }
    } else if (state==='ERASING') {
      if (currentIndex!==0) {
        eraseNextLetter()
      } else {
        setState('TYPING')
        setTimeout(startNextString, startDelay)
      }
    }
    // eslint-disable-next-line
  },[currentIndex, currentString])

  const startErasing = () => setState('ERASING')

  const eraserTimeOut = useRef()

  const eraseLetter = () => {
    setText((prevText)=>prevText.substr(0, prevText.length-1))
    setCurrentIndex((i)=>i-1)
  }

  const eraseNextLetter = () => {
    eraserTimeOut.current = setTimeout(eraseLetter, backSpeed)
  }

  useEffect(()=>{
    if (state==='ERASING') {
      eraseNextLetter()
    }
    // eslint-disable-next-line
  },[state])

  useEffect(()=>{
    if (currentStringIndex!==null) {
      setCurrentString(strings[currentStringIndex]?strings[currentStringIndex]:'')
    }
    // eslint-disable-next-line
  },[currentStringIndex])

  const startNextString = () => {
    setCurrentStringIndex((prevValue)=>{
      const nextValue = prevValue+1
      if (strings.length<=nextValue) {
        return 0
      } else {
        return nextValue
      }
    })
  }

  useEffect(()=>{
    setText('')
    return ()=>{
      clearTimeout(typingTimeOut)
      clearTimeout(eraserTimeOut)
      setText('')
      setCurrentString('')
    }
    // eslint-disable-next-line
  },[])

  return null;
}

export default TypeWriter