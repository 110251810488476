import { useSelector } from "react-redux";
import { useMemo } from "react";

export const useCountries = (restrictions) => {
  const countries = useSelector((state) => state.Country.getAll.result);

  return useMemo(() => {
    if (restrictions) {
      return countries.filter((x) => restrictions.includes(x.code.toLowerCase()));
    }
    return countries;
  }, [countries, restrictions]);
};
