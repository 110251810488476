import axios from "axios";
import {handleError} from "reactcoregk/store/api";
import {API_URL} from "../../../config";
// import md5 from 'md5';

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};


// Login Method
const postLogin = async (data) => {
  // const config = {
  //   headers: {
  //     Authorization: `${data.username}:${md5(data.password)}`
  //   }
  // }
  const url = API_URL + "/account/signin";
  return axios
    .post(url, data)
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

//Logout Method
const postLogout = async (history) => {
  // const config = {
  //   headers: {
  //     Authorization: `${data.username}:${md5(data.password)}`
  //   }
  // }
  const url = API_URL + "/token/logout";
  return axios
    .post(url, {})
    .then((response) => {
      localStorage.removeItem('authUser')
        history.push('/sign-in')
    })
    .catch(handleError);
};

// postForgetPwd
const postForgetPwd = (data) => {
  const url = API_URL + "/account/forgot-password";

  return axios
    .post(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response;
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
};

// postResetPwd
const postResetPwd = (data) => {
  const url = API_URL + "/account/reset-password";

  return axios
      .post(url, data)
      .then((response) => {
        if (response.status === 400 || response.status === 500)
          throw response;
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
};

export {
  getLoggedInUser,
  isUserAuthenticated,
  postLogin,
  postForgetPwd, postResetPwd,
  postLogout
};
