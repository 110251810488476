import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Collapse, Grid, IconButton, TextField } from "@material-ui/core";
import Text from "../../../components/Text/Text";
import Box from "@material-ui/core/Box";
import { ProductItemCell } from "../../../components/ProductViews";
import { EditOutlined, ExpandLess, ExpandMore } from "@material-ui/icons";
import MemoIcon from "assets/icons/memo.png";
import ProductIcon from "assets/icons/product.png";
import { toCurrency } from "../../../utils";
import { QuantitySelect } from "../../../components/CartWidget/OrderItemsList";
import { useDispatch } from "react-redux";
import {
  setOrderMemoPackingNotes,
  setOrderProductPackingNotes,
} from "../../../store/cart/actions";
import Pluralize from "pluralize";
import {orderItemType} from "../../../constants/enums";

const useStyles = makeStyles({
  root: {},
  container: {
    borderBottom: "1px solid #3B3C3E",
  },
  collapseButton: {
    border: "1px solid",
    width: 40,
    height: 40,
    marginRight: 16,
  },
  editIcon: {
    marginRight: 4,
    "&:hover": {
      cursor: "pointer",
    },
  },
});

function Header({ toggle, show, icon, type }) {
  const classes = useStyles();
  return (
    <Grid container direction={"row"} wrap={"nowrap"}>
      <Grid item>
        <IconButton
          onClick={toggle}
          color={"primary"}
          className={classes.collapseButton}
        >
          {show ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Grid>
      <Grid item xs={12} container className={classes.container}>
        <Grid item style={{ width: 250 }}>
          <Text bold style={{ display: "flex", alignItems: "center" }}>
            <img src={icon} alt={"SAMPLE"} width={"24px"} height={"24px"} />
            {type} ITEM
          </Text>
        </Grid>
        <Grid item style={{ flex: 1, marginRight: 8 }}>
          <Text bold>ITEM NOTES</Text>
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <Text bold>QUANTITY</Text>
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <Text bold>ITEM TOTAL</Text>
        </Grid>
      </Grid>
    </Grid>
  );
}

function Item({ item, handleUpdateItem, busy }) {
  const classes = useStyles();
  const [openQuantity, setOpenQuantity] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [notes, setNotes] = useState("");

  useEffect(() => {
    if (openNotes) {
      setNotes(item.notes);
    }
  }, [item.notes, openNotes]);

  return (
    <Box my={1}>
      <Grid container alignItems={"center"}>
        <Grid item style={{ width: 52 }} />
        <Grid item style={{ width: 250 }}>
          <ProductItemCell item={item} />
        </Grid>
        <Grid item style={{ flex: 1, marginLeft: 8, marginRight: 8 }}>
          {openNotes ? (
            <TextField
              label={"Notes"}
              value={notes}
              multiline
              rowsMax
              variant={"outlined"}
              margin={"dense"}
              disabled={busy}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleUpdateItem({ ...item, notes }, () =>
                    setOpenNotes(false)
                  );
                }
              }}
              onChange={(e) => setNotes(e.target.value)}
            />
          ) : (
            <Box
              display={"flex"}
              alignItems={"center"}
              onClick={() => setOpenQuantity(true)}
            >
              <EditOutlined
                onClick={() => setOpenNotes(true)}
                fontSize={"small"}
                className={classes.editIcon}
              />
              <Text>{item.notes}</Text>
            </Box>
          )}
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <Box display={"flex"} alignItems={"center"}>
            {!openQuantity ? (
              <>
                <EditOutlined
                  fontSize={"small"}
                  className={classes.editIcon}
                  onClick={() => setOpenQuantity(true)}
                />
                <Text>{item.quantity}</Text>
              </>
            ) : (
              <QuantitySelect
                cartItem={item}
                handleUpdateItem={(updated) => {
                  handleUpdateItem(updated, () => setOpenQuantity(false));
                }}
                busy={busy}
              />
            )}
            <Text style={{marginLeft: 4}}>{Pluralize(item.type === orderItemType.SAMPLE ? "Item" :item.pricedBy, item.quantity)}</Text>
          </Box>
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <Text bold>{toCurrency(item.netAmount, true)}</Text>
        </Grid>
      </Grid>
    </Box>
  );
}

function DeliveryOptions({ shippingMethod, notes, setNotes, busy, title }) {
  return (
    <Box mt={4}>
      <Grid container>
        <Grid item style={{ width: 250, marginLeft: 52 }}>
          <Text bold>Shipping Method</Text>
          <Text>{shippingMethod}</Text>
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <TextField
            fullWidth
            label={title}
            value={notes}
            multiline
            rowsMax
            variant={"outlined"}
            margin={"dense"}
            disabled={busy}
            onChange={(e) => setNotes(e.target.value)}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

function ItemList({
  memos,
  products,
  handleUpdateItem,
  busy,
  order,
  memoDeliveryOptions,
  productDeliveryOptions,
}) {
  const [openMemos, setOpenMemos] = useState(false);
  const [openProducts, setOpenProducts] = useState(false);
  const disptach = useDispatch();
  const memoShipping = memoDeliveryOptions.find(
    (x) => x.id === order.memoShippingMethodId
  );
  const productShipping = productDeliveryOptions.find(
    (x) => x.id === order.productShippingMethodId
  );

  const handleMemoPacking = useCallback(
    (notes) => {
      disptach(setOrderMemoPackingNotes(notes));
    },
    [disptach]
  );

  const handleProductPacking = useCallback(
    (notes) => {
      disptach(setOrderProductPackingNotes(notes));
    },
    [disptach]
  );

  return (
    <div>
      {memos[0] && (
        <Box>
          <Header
            type={"MEMO"}
            icon={MemoIcon}
            show={openMemos}
            toggle={() => setOpenMemos(!openMemos)}
          />
          <Collapse in={openMemos}>
            {memos.map((memo) => (
              <Item
                busy={busy}
                item={memo}
                handleUpdateItem={handleUpdateItem}
              />
            ))}
          </Collapse>
          <DeliveryOptions
            title={"Memo Packing Notes"}
            notes={order.memoPackingNotes}
            setNotes={handleMemoPacking}
            shippingMethod={memoShipping?.name}
          />
        </Box>
      )}
      {products[0] && (
        <Box mt={memos[0] ? 3 : 0}>
          <Header
            type={"PRODUCT"}
            icon={ProductIcon}
            show={openProducts}
            toggle={() => setOpenProducts(!openProducts)}
          />
          <Collapse in={openProducts}>
            {products.map((product) => (
              <Item
                busy={busy}
                item={product}
                handleUpdateItem={handleUpdateItem}
              />
            ))}
          </Collapse>
          <DeliveryOptions
            title={"Product Packing Notes"}
            notes={order.productPackingNotes}
            setNotes={handleProductPacking}
            shippingMethod={productShipping?.name}
          />
        </Box>
      )}
    </div>
  );
}

export default ItemList;
