import { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  CardActionArea,
  Checkbox,
  Fade,
  Grid,
  Hidden,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Favorite, FavoriteBorderOutlined } from "@material-ui/icons";
import PropTypes from "prop-types";
import Button from "../../Button/Button";
import { Skeleton } from "@material-ui/lab";
import { combineStrings, openExternalProps, toCurrency } from "../../../utils";
import UnderlinedLink from "../../UnderlinedLink";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { useAsyncErrorCallback } from "../../../hooks/general";
import Text from "../../Text/Text";
import { createMap } from "reactcoregk/utils";
import Highlighter from "react-highlight-words";


// const getHeight = (size) => {
//   switch (size) {
//     case "small":
//       return "175px";
//     case "auto":
//       return "auto";
//     default:
//       return "275px";
//   }
// };

const useStyles = makeStyles((theme) => ({
  root: {},
  image: {
    width: "100%",
    height: "100%",
    aspectRatio:'1/1',
    objectFit: "cover",
    borderRadius: theme.borderRadius,
  },
  title: {
    fontWeight: "bold",
    color: "inherit",
    textOverflow:'clip'
  },
  overlapButton: {
    minWidth: "80%",
    maxWidth: "90%",
    backgroundColor: "white",
  },
  overlapContainer: {
    position: "absolute",
    top: 0,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const CheckboxWithYellowCheck = withStyles({
  root: {
    "&$checked": {
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
      },
      "& .MuiIconButton-label:after": {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "#FFD700",
        zIndex: -1,
      },
    },
  },
  checked: {},
})(Checkbox);

const categoryType = (product, props, isExcluded) => {
  const { categoryId } = props.match.params;
  const attrs = product.attributes || [];
  const attributeMap = createMap(attrs, "name");

  if (categoryId !== "7" && categoryId !== undefined) {
    if (!isExcluded)
      return attributeMap?.get("Type")?.value[0]?.value
        ? attributeMap?.get("Type")?.value[0]?.value.toUpperCase()
        : product.category.name;
    else return product.category.name;
  } else if (categoryId === "7") {
    if (attributeMap?.get("Type")?.value[0]?.value.toUpperCase() === "PILLOWS")
      return "PILLOWS";
    else return "ACCESSORIES";
  } else {
    return product.category.name;
  }
};

const RenderProductName = ({name, term})=>{
  return <Highlighter
      highlightStyle={{backgroundColor:'#fcf9e8',padding:'2px 0 2px 2px'}}
      searchWords={term.split(' ')}
      autoEscape={true}
      textToHighlight={name}
  />
//   const parts = name.split(new RegExp(`(${term})`));
//   console.log(parts)
//   return <span>{parts.map(part => part.toLowerCase() === term.toLowerCase() ? <span style={{backgroundColor:'#fcf9e8',paddingTop:4,paddingBottom:4}}>{part}</span> : part)}</span>;  // return <span>
}

const ProductImage = ({thumbnail, classes}) =>{

  return <Box style={{width:'100%',height:'100%',position:'absolute'}}>
    {thumbnail ?
        <img
            className={classes.image}
            alt="product"
            src={thumbnail}
        />
        :
        <Grid container style={{background: '#FCF9E8',width:'100%',aspectRatio:'1/1',position:'relative',zIndex:0}} just justify={'center'} alignItems={'center'} alignContent={'center'}>
          <Grid item style={{zIndex:10}}>
            <Text variant={'body1'} style={{fontFamily:'Gotham',color:'#3B3C3E',fontSize:'16px',fontWeight:300, textAlign:'center'}}>IMAGE IS COMING SOON (IICS)</Text>
          </Grid>
        </Grid>
    }

  </Box>
}

function ProductCard(props) {
  const {
    product,
    handlePreview,
    toggleFavorite,
    isLoading,
    handleMemo,
    sampleAvailable,
    sampleSupported,
    memosAdded,
    isAuth,
    favoritesSelection,
    handleSelectedFavoriteToMemo,
    openExternally,
    isExcluded,
    highlightTerm
  } = props;

  const classes = useStyles(props);
  const [focused, setFocused] = useState(false);
  const attrs = product.attributes || [];
  const attributeMap = createMap(attrs, "name");
  const linkProps = openExternally ? openExternalProps : null;

  function has(attr) {
    return attr?.value[0]?.value === "true";
  }
  const isValid =
    has(attributeMap.get("sampleSupported")) &&
    has(attributeMap.get("sampleAvailable"));
  const showMemo = sampleSupported && sampleAvailable && handleMemo;
  return (
    <Grid container direction="column">
      {isLoading ? (
        <Skeleton variant={"rect"} className={classes.image} height={280} />
      ) : (
        <CardActionArea
          className={classes.image}
          onMouseOver={() => setFocused(true)}
          onMouseLeave={() => setFocused(false)}
          style={{ position: "relative" ,height:'100%'}}
          href={`/catalog/products/${product.itemNumber}`}
          target={"_blank"}
          {...linkProps}
        >
          <ProductImage thumbnail = {product?.imageUrl} classes={classes}/>
          <HoverButtons
            isLoading={isLoading}
            product={product}
            handlePreview={handlePreview}
            handleMemo={handleMemo}
            memosAdded={memosAdded}
            focused={focused}
            showMemo={showMemo}
          />
        </CardActionArea>
      )}
      <ActionButtons
        isLoading={isLoading}
        product={product}
        handlePreview={handlePreview}
        handleMemo={handleMemo}
        memosAdded={memosAdded}
        showMemo={showMemo}
      />
      {isLoading ? (
        <Box>
          <Skeleton variant={"text"} width={"80%"} height={32} />
          <Skeleton variant={"text"} width={"60%"} height={24} />
        </Box>
      ) : (
        <Fragment>
          <div style={{ display: "flex", alignItems: "center", marginTop: 4 }}>
            <Typography variant="body1">
              {highlightTerm?<RenderProductName name={categoryType(product, props, isExcluded)} term={highlightTerm}/>:categoryType(product, props, isExcluded)}
            </Typography>
            {isAuth && favoritesSelection ? (
              <IconButton
                disabled={!isValid}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSelectedFavoriteToMemo();
                }}
                size="small"
                style={{
                  marginLeft: "auto",
                  color: !product.isFavorite ? "" : "#FFD700",
                }}
              >
                <CheckboxWithYellowCheck
                  disabled={!isValid}
                  onChange={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  checked={product.checked}
                />{" "}
              </IconButton>
            ) : (
              isAuth && (
                <IconButton
                  onClick={toggleFavorite}
                  size="small"
                  style={{
                    marginLeft: "auto",
                    color: !product.isFavorite ? "" : "#FFD700",
                  }}
                >
                  {!product.isFavorite ? (
                    <FavoriteBorderOutlined />
                  ) : (
                    <Favorite />
                  )}
                </IconButton>
              )
            )}
          </div>
          <Typography
            component={UnderlinedLink}
            to={`/catalog/products/${product.itemNumber}`}
            variant="body1"
            className={classes.title}
          >
            {highlightTerm?<RenderProductName name={product.name} term={highlightTerm}/>:product.name}
          </Typography>
          <Typography variant="body1">
            {highlightTerm?<><RenderProductName name={product.colorName} term={highlightTerm}/>
            {` ${product.itemNumber}`} </>: combineStrings(" ",product.colorName, product.itemNumber)}
          </Typography>
          {product.priceUsd && (
            <Typography variant="body1">
              {toCurrency(product.priceUsd)}
            </Typography>
          )}
        </Fragment>
      )}
    </Grid>
  );
}

function ActionButtons({
  isLoading,
  showMemo,
  handlePreview,
  product,
  handleMemo,
  memosAdded,
}) {
  const [loading, setLoading] = useState(false);
  const handleCallback = useAsyncErrorCallback(() => setLoading(false));

  if (isLoading) return null;
  return (
    <Hidden smUp implementation="css">
      <Box mt={1}>
        {handlePreview && (
          <Button
            variant="outlined"
            fullWidth
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handlePreview(product);
            }}
          >
            Quick View
          </Button>
        )}
        {showMemo && (
          <Fragment>
            <Box my={1} />
            <Button
              variant="outlined"
              fullWidth
              size="large"
              loading={loading}
              // loading={addingMemo}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setLoading(true);
                handleMemo(product, handleCallback);
              }}
            >
              <Text variant={"inherit"} noWrap color={"inherit"}>
                {memosAdded > 0 ? `Added memo (${memosAdded})` : "Get memo"}
              </Text>
            </Button>
          </Fragment>
        )}
      </Box>
    </Hidden>
  );
}

function HoverButtons({
  isLoading,
  focused,
  showMemo,
  handlePreview,
  product,
  handleMemo,
  memosAdded,
}) {
  const [loading, setLoading] = useState(false);
  const handleCallback = useAsyncErrorCallback(() => setLoading(false));

  const classes = useStyles();
  if (isLoading) return null;
  return (
    <Hidden xsDown implementation="css">
      <Fade in={focused}>
        <div className={classes.overlapContainer}>
          {handlePreview && (
            <Button
              variant="contained"
              size="large"
              className={classes.overlapButton}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handlePreview(product);
              }}
            >
              Quick View
            </Button>
          )}
          {showMemo && (
            <Fragment>
              <Box my={1} />
              <Button
                variant="contained"
                size="large"
                loading={loading}
                // loading={addingMemo}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setLoading(true);
                  handleMemo(product, handleCallback);
                }}
                className={clsx(
                  classes.overlapButton,
                  memosAdded && classes.active
                )}
              >
                <Text variant={"inherit"} noWrap color={"inherit"}>
                  {memosAdded > 0 ? `Added memo (${memosAdded})` : "Get memo"}
                </Text>
              </Button>
            </Fragment>
          )}
        </div>
      </Fade>
    </Hidden>
  );
}

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  handlePreview: PropTypes.func,
  handleMemo: PropTypes.func,
  isLoading: PropTypes.bool,
  toggleFavorite: PropTypes.func,
  size: PropTypes.oneOf(["normal", "small", "auto"]),
  isAuth: PropTypes.bool,
};

ProductCard.defaultProps = {
  size: "normal",
  activeCart: {
    items: [],
  },
};

export default withRouter(ProductCard);
