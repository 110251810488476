import React, { Fragment, useCallback, useEffect, useState } from "react";
import { combineStrings, fetchData, toCurrency } from "../../../utils";
import { ADMIN_API_URL } from "../../../config";
import {
  Box,
  Container,
  IconButton,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Paging } from "reactcoregk/models";
import moment from "moment";
import { Pagination } from "@material-ui/lab";
import Text from "../../../components/Text/Text";
import { useSnackbar } from "notistack";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import Button from "../../../components/Button/Button";
import ProductIcon from "assets/icons/product.png";
import MemoIcon from "assets/icons/memo.png";
import {
  orderItemStatus,
  orderItemStatusOptions,
  orderItemType,
} from "../../../constants/enums";
import Checkbox from "../../../components/Checkbox/Checkbox";
import Dialog from "../../../components/Dialog/Dialog";
import BaseSelect from "../../../components/Core/BaseSelect";
import { updateData } from "reactcoregk/utils";

function ItemsTable({ subOrder, handleRefresh }) {
  const { warehouseLocation } = subOrder;
  const [selected, setSelected] = useState([]);
  const [openSet, setOpenSet] = useState(false);
  const [changeStatus, setChangeStatus] = useState(orderItemStatus.RECEIVED);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const handleSelect = useCallback((id) => {
    setSelected((prevState) =>
      prevState.includes(id)
        ? prevState.filter((x) => x !== id)
        : [...prevState, id]
    );
  }, []);

  const handleChangeStatus = useCallback(() => {
    setLoading(true);
    updateData(`${ADMIN_API_URL}/items-status/`, {
      ids: selected,
      status: changeStatus,
    })
      .then(() => {
        setLoading(false);
        handleRefresh();
        enqueueSnackbar("Items updated", { variant: "success" });
        setOpenSet(false);
        setSelected([]);
      })
      .catch((ex) => {
        setLoading(false);
        enqueueSnackbar(ex.message, { variant: "error" });
      });
  }, [changeStatus, enqueueSnackbar, handleRefresh, selected]);

  return (
    <Fragment>
      <TableRow style={{ backgroundColor: "#FCF9E8" }}>
        <TableCell>
          <Text noWrap bold>
            ID: #{subOrder.id}
          </Text>
          <Text noWrap>
            {combineStrings(
              ", ",
              warehouseLocation.city,
              warehouseLocation.stateName,
              warehouseLocation.countryName
            )}
          </Text>
          <Text noWrap>
            <b>Stripe ID:</b> {subOrder.stripeId}
          </Text>
        </TableCell>
        <TableCell>{toCurrency(subOrder.netAmount, true)}</TableCell>
        <TableCell>{toCurrency(subOrder.shippingCosts, true)}</TableCell>
        <TableCell>{toCurrency(subOrder.cutCharges)}</TableCell>
        <TableCell>{toCurrency(subOrder.taxAmount)}</TableCell>
        <TableCell>{toCurrency(subOrder.totalAmount, true)}</TableCell>
        <TableCell/>
        <TableCell>
          <Button
            disabled={selected.length === 0}
            fullWidth
            onClick={() => setOpenSet(true)}
            color={"secondary"}
            variant={"contained"}
          >
            Set
          </Button>
        </TableCell>
      </TableRow>
      {subOrder.items.map((item) => (
        <TableRow>
          <TableCell colSpan={6}>
            <Box display={"flex"} alignItems={"center"}>
              <img
                src={
                  item.itemType === orderItemType.PRODUCT
                    ? ProductIcon
                    : MemoIcon
                }
                alt={"item"}
                width={"24px"}
                height={"24px"}
              />
              <Box ml={3}>
                <Text>#{item.id}</Text>
                <Text>{item.productName}</Text>
                <Text bold>{item.skuNumber}</Text>
              </Box>
            </Box>
          </TableCell>
          <TableCell>
            {orderItemStatusOptions.find((x) => x.id === item.status)?.name ||
              "N/A"}
          </TableCell>
          <TableCell align={"center"}>
            <Checkbox
              color="secondary"
              checked={selected.includes(item.id)}
              onChange={() => handleSelect(item.id)}
            />
          </TableCell>
        </TableRow>
      ))}
      <Dialog
        open={openSet}
        onClose={() => setOpenSet(false)}
        title={"Update Status"}
        secondaryActionTitle={"Cancel"}
        secondaryAction={() => setOpenSet(false)}
        primaryAction={handleChangeStatus}
        primaryActionColor={"secondary"}
        primaryActionVariant={"contained"}
        primaryActionTitle={"Update"}
        inProgress={loading}
        showProgress
        maxWidth={"xs"}
      >
        <BaseSelect
          onChange={(e) => setChangeStatus(e.target.value)}
          value={changeStatus}
          controlId={"status"}
          options={orderItemStatusOptions}
        />
      </Dialog>
    </Fragment>
  );
}

function Orders(props) {
  const [orders, setOrders] = useState(new Paging());
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(0);
  const [expanded, setExpanded] = useState(null);

  const handleExpand = (id) => {
    if (expanded === id) {
      setExpanded(null);
    } else {
      setExpanded(id);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData(`${ADMIN_API_URL}/orders?page=${page}`)
      .then((data) => {
        setOrders(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [page, forceRefresh]);

  return (
    <Container maxWidth={"lg"}>
      <Box mt={2} />
      <Text variant="h4" style={{ textTransform: "capitalize", fontSize: 56 }}>
        Orders
      </Text>
      <Box mt={2} />
      <TableContainer>
        <Table size={"small"} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" />
              <TableCell align="left">User Name</TableCell>
              <TableCell align="left">Order Details</TableCell>
              <TableCell align="left">Shipment Details</TableCell>
              <TableCell align="left">Payment Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.content.map((row) => {
              // const items = row.suborders
              //   .map((x) => ({ ...x, itemImageUrl: x.imageUrl }))
              //   .flat();
              const {
                billingAddress,
                paymentMethod,
                shippingAddress,
                orderNumber,
                id,
                createdAt,
                firstName,
                suborders,
                lastName,
              } = row;

              return (
                <Fragment>
                  <TableRow key={row.name}>
                    <TableCell align="left">
                      <IconButton
                        disabled={suborders.length === 0}
                        onClick={() => handleExpand(id)}
                      >
                        {id === expanded ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </TableCell>
                    <TableCell align="left">
                      <Text>{combineStrings(" ", firstName, lastName)}</Text>
                    </TableCell>
                    <TableCell align="left">
                      <Text>#{id}</Text>
                      <Text bold>{orderNumber}</Text>
                      <Text>
                        {moment(createdAt)
                          .local()
                          .format("MM/DD/yyyy HH:mm:ss")}
                      </Text>
                    </TableCell>
                    <TableCell align="left">
                      <Text bold>
                        {combineStrings(
                          " ",
                          shippingAddress.name,
                          shippingAddress.surname
                        )}
                      </Text>
                      <Text>Ship To: {shippingAddress.addressLine1}</Text>
                      <Text>
                        {combineStrings(
                          ", ",
                          shippingAddress.city,
                          shippingAddress.state,
                          shippingAddress.country
                        )}
                      </Text>
                    </TableCell>
                    <TableCell align="left">
                      <Text bold>{paymentMethod?.maskedCardNumber}</Text>
                      <Text>{billingAddress?.addressLine1}</Text>
                      <Text>
                        {combineStrings(
                          ", ",
                          billingAddress?.city,
                          billingAddress?.state,
                          billingAddress?.country
                        )}
                      </Text>
                    </TableCell>
                  </TableRow>
                  {expanded === id && (
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        style={{ padding: "0px 0px 0px 96px" }}
                      >
                        <Table size={"small"} aria-label="simple table">
                          <TableHead>
                            <TableRow style={{ backgroundColor: "lightgrey" }}>
                              <TableCell align="left">Suborders</TableCell>
                              <TableCell align="left">Subtotal</TableCell>
                              <TableCell align="left">Shipping Cost</TableCell>
                              <TableCell align="left">
                                Additional Charges
                              </TableCell>
                              <TableCell align="left">Sales Tax</TableCell>
                              <TableCell align="left">Total Amount</TableCell>
                              <TableCell align="left">Status</TableCell>
                              <TableCell align="center">Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.suborders.map((subOrder, index) => (
                              <ItemsTable
                                key={index}
                                subOrder={subOrder}
                                handleRefresh={() =>
                                  setForceRefresh((i) => i + 1)
                                }
                              />
                            ))}
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
        {loading && (
          <Box
            style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
            height="100%"
            width="100%"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            justifyItems="center"
            display="flex"
            position={"absolute"}
            left={0}
            top={0}
          >
            <Box display="flex" fontSize={20}>
              Loading...{" "}
            </Box>
          </Box>
        )}
      </TableContainer>
      <Box display={"flex"} justifyContent={"flex-end"} my={2}>
        <Pagination
          count={orders.totalPages}
          page={page}
          onChange={(e, value) => setPage(value - 1)}
        />
      </Box>
    </Container>
  );
}

export default Orders;
