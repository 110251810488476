import {Fab} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme)=>({
  root: {},
  searchFab: {
    zIndex:999,
    position: 'fixed',
    right: 60,
    top: 100,
    backgroundColor:'#FFF595',
    color: '#000',
    '&:hover': {
      backgroundColor: '#FFD700'
    }
  }
}));

const DiscoverButton = ({appearAt=330, action}) => {
  const classes = useStyles();
  const [top, setTop] = useState(0);
  const [visible, setVisible] = useState(false);

  useEffect(()=>{
    const fabTop = fabRef.current.offsetTop;
    const threshold = appearAt-fabTop;

    if (top>threshold&&!visible) {
      setVisible(true)
    } else if (top<threshold&&visible) {
      setVisible(false)
    }
  },[top,appearAt,visible])

  const ticking = useRef(false)
  const fabRef = useRef()

  const throttleScroll = function(e) {
    let yPos = window.scrollY;

    if (!ticking.current) {
      window.requestAnimationFrame(function() {
        setTop(yPos);
        ticking.current = false;
      });

      ticking.current = true;
    }
  }

  useEffect(()=>{
    document.addEventListener('scroll', throttleScroll);
    return ()=> {
      document.removeEventListener('scroll', throttleScroll)
    }
  },[])

  return <> <Fab color="primary" aria-label="add" onClick={()=>action&&action()} className={classes.searchFab} ref={fabRef} style={{opacity: visible?1:0}}>
    <SearchIcon />
  </Fab> </>
}

export default DiscoverButton