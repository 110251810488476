import BaseProductGrid from "pages/BaseProductGrid";
import { API_URL } from "../../config";

const productsUrl = `${API_URL}/products/`;

export default function ShopByPage(props) {
  const { slug, attributeId } = props.match.params;

  const breadcrumbs = [
    { name: "Shop By", path: "#" },
    { name: slug, path: "#" },
  ];

  return (
    <BaseProductGrid
      productsUrl={productsUrl}
      breadcrumbs={breadcrumbs}
      params={`?attributeId=${attributeId}`}
    />
  );
}
