import {CLOSE_CART_DRAWER, OPEN_CART_DRAWER, TOGGLE_CART_DRAWER} from "./actionTypes";

export const toggleCartDrawer = () => {
    return {
        type: TOGGLE_CART_DRAWER,
    }
}

export const openCartDrawer = (activeTab) => {
    return {
        type: OPEN_CART_DRAWER,
        activeTab: activeTab
    }
}

export const closeCartDrawer = () => {
    return {
        type: CLOSE_CART_DRAWER,
    }
}
