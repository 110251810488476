import {Box} from '@material-ui/core'
import WarnEditDialog from 'components/Core/FormikFields/WarnEditDialog'

const WarnEditOverlay = ({showWarning, setShowWarning, setFocus}) => {

  const handleCloseWarning = () => {
    setShowWarning(null)
  }

  const setEdit = () => {
    setShowWarning(false)
    if (setFocus) {
      setFocus(true)
    }
  }

  return <>
    {showWarning===null&&<Box style={{position:'absolute', width:'100%', height: '100%', zIndex:2, cursor:'pointer'}} onClick={()=>setShowWarning(true)}> </Box>}
    <WarnEditDialog open={showWarning} setOpen={setShowWarning} onYes={setEdit} handleClose={handleCloseWarning}/>
  </>
}

export default WarnEditOverlay
