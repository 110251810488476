
import {
  Box,
  Typography
} from '@material-ui/core'
import Dialog from "components/Dialog/Dialog";


const TaxExemptInfoDialog = ({open, setOpen, handleCancel}) => {

  const handleClose = () => {
    setOpen(false)
  }

  return <>
    <Dialog
      onClose={handleClose}
      open={open}
      title={'Important Information'}
      maxWidth="sm"
      primaryActionTitle={'UPLOAD TAX EXEMPT FORMS'}
      secondaryActionTitle={'CANCEL'}
      primaryAction={()=>{handleClose()}}
      secondaryAction={()=>{handleClose(); handleCancel()}}
      primaryActionColor={"secondary"}
      primaryActionVariant={"contained"}
    >
      <Typography variant='h6' style={{ fontWeight:800 }}>
        Important Information
      </Typography>

      <Box>
        <p>
          To qualify tax exemption:
        </p>
        <ul>
          <li>
            You must provide tax exemption documents for the origin state of work and the destination state of work.
          </li>
          <li>
            If there’s an intermediate place where work is being performed before it reaches the final destination, you must provide tax exempt documentation for that intermediate state.
          </li>
        </ul>
      </Box>

    </Dialog>
  </>
}

export default TaxExemptInfoDialog
