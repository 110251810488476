import {Box, CardActionArea, Grid} from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx"
import Text from "../Text/Text";

function getDimens(size) {
  switch (size) {
    case "sm":
      return 50;
    case "md":
      return 64;
    case "lg":
      return 70;
    default:
      return 50;
  }
}

const useStyles = makeStyles({
  root: {},
  image: {
    width: "100%",
    height: "auto",
  },
  thumbnail: {
    height: (props) => getDimens(props.size),
    width: (props) => getDimens(props.size),
  },
  activeThumb: {
    border: "2px solid black",
    padding: 2,
  },
});

export function ImagePicker(props) {
  const classes = useStyles(props);
  const {
    isLoading,
    images,
    selectedIndex,
    setSelectedIndex,
    direction,
    loadingItems,
  } = props;


  const ProductImage = ({thumbnail,classes}) =>{
    return <Box style={{background: thumbnail?'white':'#FCF9E8',width:'100%',height:'100%',position:'relative'}} alignContent={'center'} justifyContent={'center'} alignItems={'center'}>
      {thumbnail ?
          <img
              className={classes.image}
              alt="product"
              src={thumbnail}
          />
          :
          <Box style={{marginTop:0,paddingTop:'40%',width:'100%'}}>
            <Text variant={'body1'} style={{fontFamily:'Gotham',color:'#3B3C3E',fontSize:'8px',fontWeight:300,textAlign:'center'}}>IICS</Text>
          </Box>
      }

    </Box>
  }


  return (
    <Grid container direction={direction} spacing={2}>
      {isLoading
        ? [...Array(loadingItems)].map((k, index) => (
            <Grid item key={index}>
              <Skeleton variant={"rect"} className={classes.thumbnail} />
            </Grid>
          ))
        : images.map((image, index) => (
            <Grid item key={index}>
              <CardActionArea
                disabled={!setSelectedIndex}
                onClick={() => setSelectedIndex(index)}
                className={clsx(
                  classes.thumbnail,
                  index === selectedIndex && classes.activeThumb,
                )}
              >
                {image ?
                    <CardMedia
                        style={{width: "100%", height: "100%"}}
                        image={image}
                    />
                    :
                    <ProductImage classes={classes} thumbnail={image}/>
                }
              </CardActionArea>
            </Grid>
          ))}
    </Grid>
  );
}
ImagePicker.defaultProps = {
  size: "sm",
  direction: "row",
  images: [],
  selectedIndex: 0,
  loadingItems: 4,
};

ImagePicker.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  direction: PropTypes.oneOf(["row", "column"]),
  images: PropTypes.array,
  selectedIndex: PropTypes.number,
  setSelectedIndex: PropTypes.func,
  isLoading: PropTypes.bool,
  loadingItems: PropTypes.number,
};
