import { Box } from "@material-ui/core";
import Dialog from "../../components/Dialog/Dialog";
import {
  useProduct,
  useProductCart,
  useProductRules,
} from "../../hooks/product";
import ProductView from "../../components/ProductView/ProductView";
import { connect } from "react-redux";
import {
  deleteProductFromCart,
  updateProductOfCart,
} from "../../store/cart/actions";
import { Fragment, useState } from "react";
import Text from "../../components/Text/Text";
import UnderlinedLink from "../../components/UnderlinedLink";
import { useFavorites } from "../../hooks/favorites";

function ProductDialog(props) {
  const {
    handleClose,
    open,
    itemNumber,
    context,
    products,
  } = props;
  const { isAuth } = context.Auth;
  const { product, isLoading } = useProduct(itemNumber, isAuth);
  const rules = useProductRules(product);
  const [favoriteProduct, setFavoriteProduct] = useState({});

  useFavorites(favoriteProduct, products, setFavoriteProduct);

  const {
    memosAdded,
    productsAdded,
    addingMemo,
    addingProduct,
    updating,
    handleAddMemo,
    handleAddProduct,
  } = useProductCart(product);

  function renderTitle() {
    const path = `/catalog/products/${product.itemNumber}`;
    return (
      <Text>
        <UnderlinedLink to={path}>View full details</UnderlinedLink>
      </Text>
    );
  }

  return (
    <Fragment>
      <Dialog
        onClose={handleClose}
        open={open}
        title={renderTitle()}
        maxWidth="md"
      >
        <ProductView
          showVariants={false}
          showAvailability={false}
          showHelp={false}
          showDescription
          isLoading={isLoading}
          handleGetMemo={handleAddMemo}
          handleOrder={handleAddProduct}
          product={product}
          isAuth={context.Auth.isAuth}
          canOrder={context.Auth.canOrder}
          memosAdded={memosAdded}
          productsAdded={productsAdded}
          addingMemo={addingMemo}
          addingProduct={addingProduct}
          disableControls={updating}
          handleFavorite={() => setFavoriteProduct(product)}
          {...rules}
        />
        <Box mt={2} />
      </Dialog>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      Auth: state.Auth,
    },
  };
};

export default connect(mapStateToProps, {
  updateProductOfCart,
  deleteProductFromCart,
})(ProductDialog);
