import {Checkbox as MuiCheckbox, withStyles} from "@material-ui/core";

const Checkbox = withStyles({
    root: {
        "&$checked": {
            "& .MuiIconButton-label": {
                position: "relative",
                zIndex: 0,
            },
            "& .MuiIconButton-label:after": {
                content: '""',
                left: 4,
                top: 4,
                height: 15,
                width: 15,
                position: "absolute",
                backgroundColor: "#FFD700",
                zIndex: -1,
            },
        },
    },
    checked: {},
})(MuiCheckbox);

export default Checkbox
