import {Box, Container} from "@material-ui/core";
import Text from "../../components/Text/Text";
import OurStorySlider from "./components/OurStorySlider";

const OurStory = () =>{
    return <Container>
        <Box py={2}>
            <Text variant="h4" style={{textTransform: "capitalize"}}>
                Our Story
            </Text>
        </Box>
        <Box>
            <OurStorySlider/>
        </Box>
    </Container>
}

export default OurStory