import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {entityType} from "./module";
import {
    addMemoActions,
    addProductActions,
    deleteProductActions, deleteProductsActions,
    getOpenCartActions,
    spoofingUserActions,
    updateCartActions,
    updateProductActions
} from "./actions";
import {ActionStates, buildAsyncActionType} from "reactcoregk/store/actions";
import {
    ADD_MEMO_TO_CART,
    ADD_PRODUCT_TO_CART, DELETE_MULTI_PRODUCT_FROM_CART,
    DELETE_PRODUCT_FROM_CART,
    GET_OPEN_CARTS,
    SET_SPOOFING_USER_ID,
    UPDATE_CART,
    UPDATE_PRODUCT_OF_CART
} from "./actionTypes";
import {
    addProductToCartApi,
    deleteProductFromCartApi, deleteProductsFromCartApi,
    fetchOpenCartsApi, getSpoofingUserProfile,
    setSpoofingUserId,
    updateCartApi,
    updateProductOfCartApi
} from "./api";

export * as api from './api'

function* addProduct({payload}) {
    const {callback, ...data} = payload
    try {
        const response = yield call(addProductToCartApi, data);
        const updated = yield call(fetchOpenCartsApi);
        yield put(getOpenCartActions.success(updated));
        yield put(addProductActions.success(response));
        if (callback) callback(null, response)
    } catch (error) {
        yield put(addProductActions.failure(error.message));
        if (callback) callback(error.message, null)
    }
}

function* addMemo({payload}) {
    const {callback, ...data} = payload
    try {
        const response = yield call(addProductToCartApi, data);
        const updated = yield call(fetchOpenCartsApi);
        yield put(getOpenCartActions.success(updated));
        yield put(addMemoActions.success(response));
        if (callback) callback(null, response)
    } catch (error) {
        yield put(addMemoActions.failure(error.message));
        if (callback) callback(error.message, null)
    }
}

function* deleteProduct({payload}) {
    try {
        const response = yield call(deleteProductFromCartApi, payload);
        const updated = yield call(fetchOpenCartsApi);
        yield put(getOpenCartActions.success(updated));
        yield put(deleteProductActions.success(response));
    } catch (error) {
        yield put(deleteProductActions.failure(error.message));
    }
}

function* deleteProducts({payload}) {
    try {
        const response = yield call(deleteProductsFromCartApi, payload);
        const updated = yield call(fetchOpenCartsApi);
        yield put(getOpenCartActions.success(updated));
        yield put(deleteProductsActions.success(response));
    } catch (error) {
        yield put(deleteProductsActions.failure(error.message));
    }
}

function* updateProduct({payload}) {
    const {data, callback} = payload
    try {
        const response = yield call(updateProductOfCartApi, data);
        const updated = yield call(fetchOpenCartsApi);
        yield put(getOpenCartActions.success(updated));
        yield put(updateProductActions.success(response));
        if (callback) callback(null, response)
    } catch (error) {
        yield put(updateProductActions.failure(error.message));
        if (callback) callback(error.message, null)
    }
}

function* updateCart({payload}) {
    try {
        const response = yield call(updateCartApi, payload);
        const updated = yield call(fetchOpenCartsApi);
        yield put(getOpenCartActions.success(updated));
        yield put(updateCartActions.success(response));
    } catch (error) {
        yield put(updateCartActions.failure(error.message));
    }
}

function* fetchCarts() {
    try {
        const response = yield call(fetchOpenCartsApi);
        yield put(getOpenCartActions.success(response));
    } catch (error) {
        yield put(getOpenCartActions.failure(error.message));
    }
}

function* addSpoofing({payload}) {
    try {
        yield call(setSpoofingUserId, payload);
        if (payload) {
            const profile = yield call(getSpoofingUserProfile, payload);
            yield put(spoofingUserActions.success(profile));
        }
    } catch (error) {
        yield put(spoofingUserActions.failure(error.message));
    }
}

export function* watchNewProduct() {
    const actionType = buildAsyncActionType(entityType, ADD_PRODUCT_TO_CART, ActionStates.request);
    yield takeEvery(actionType, addProduct);
}

export function* watchRemoveProduct() {
    const actionType = buildAsyncActionType(entityType, DELETE_PRODUCT_FROM_CART, ActionStates.request);
    yield takeEvery(actionType, deleteProduct);
}

export function* watchRemoveProducts() {
    const actionType = buildAsyncActionType(entityType, DELETE_MULTI_PRODUCT_FROM_CART, ActionStates.request);
    yield takeEvery(actionType, deleteProducts);
}

export function* watchUpdateProduct() {
    const actionType = buildAsyncActionType(entityType, UPDATE_PRODUCT_OF_CART, ActionStates.request);
    yield takeEvery(actionType, updateProduct);
}

export function* watchCartFetch() {
    const actionType = buildAsyncActionType(entityType, GET_OPEN_CARTS, ActionStates.request);
    yield takeEvery(actionType, fetchCarts);
}

export function* watchCartUpdate() {
    const actionType = buildAsyncActionType(entityType, UPDATE_CART, ActionStates.request);
    yield takeEvery(actionType, updateCart);
}

export function* watchNewMemo() {
    const actionType = buildAsyncActionType(entityType, ADD_MEMO_TO_CART, ActionStates.request);
    yield takeEvery(actionType, addMemo);
}

export function* watchNewSpoofing() {
    const actionType = buildAsyncActionType(entityType, SET_SPOOFING_USER_ID, ActionStates.request);
    yield takeEvery(actionType, addSpoofing);
}

function* saga() {
    yield all([
        fork(watchCartFetch),
        fork(watchNewProduct),
        fork(watchNewMemo),
        fork(watchRemoveProduct),
        fork(watchRemoveProducts),
        fork(watchUpdateProduct),
        fork(watchCartUpdate),
        fork(watchNewSpoofing),
    ]);
}

export default saga;
