import { Box, CardActionArea } from "@material-ui/core";
import Text from "./Text/Text";
import { Link } from "react-router-dom";
import UnderlinedLink from "./UnderlinedLink";
import { combineStrings } from "../utils";
import Tooltip from "./Tooltip/Tooltip";

export function filterRelated(related, type) {
  return related.filter((x) => x.relationshipType === type);
}

export function Spec({ name, value }) {
  return (
    <Text variant={"body1"} style={{ marginRight: 16 }}>
      {name}: <b>{value}</b>
    </Text>
  );
}

export function getStock(quantityAvailable) {
  switch (true) {
    case quantityAvailable === 0:
      return <Text style={{ color: "#DF0006" }}>Arriving Soon</Text>;
    case quantityAvailable < 10 && quantityAvailable > 0:
      return (
        <Text style={{ color: "orange" }}>Only {quantityAvailable} left</Text>
      );
    default:
      return null;
    // return (
    //   <Text style={{ color: "green" }}>
    //     {quantityAvailable} items in stock
    //   </Text>
    // );
  }
}

export function ProductItemCell({ item }) {
  return (
    <Box display={"flex"} alignItems={"center"}>
      <Box display={"flex"} overflow={"hidden"}>
        <CardActionArea
          component={Link}
          style={{
            padding: 0,
            width: "max-content",
            height: "max-content",
            alignSelf: "center",
            lineHeight: 1,
          }}
          to={`/catalog/products/${item.itemNumber}`}
        >
          <img
            src={item.imageUrl}
            style={{ width: 80, objectFit: "contain" }}
            alt={item.productName}
          />
        </CardActionArea>
        <div
          style={{
            marginLeft: 16,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Text variant="body1">{item.categoryName}</Text>
          <Tooltip title={item.productName} placement={"top"}>
            <span>
              <Text
                component={UnderlinedLink}
                to={`/catalog/products/${item.itemNumber}`}
                variant="body1"
                noWrap
                style={{ fontWeight: "bold", color: "inherit" }}
              >
                {item.productName}
              </Text>
            </span>
          </Tooltip>
          <Text variant="body1">
            {combineStrings(" ", item.colorName, item.itemNumber)}
          </Text>
        </div>
      </Box>
    </Box>
  );
}
