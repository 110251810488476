import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import {Box, Container, Divider, Grid, ListItem, Tooltip, Typography} from "@material-ui/core";
import NavigationLink from "../NavigationLink";
import PropTypes from "prop-types";
import {getTextColor} from "../../utils";
import {StickyHeader} from "../../hooks/sticky";

const getNavBg = (props, theme) => {
    if (props.variant === "light") {
        if (props.reverseColors) {
            return theme.palette.secondary.mainNav;
        }
        return "#fff";
    } else {
        if (props.reverseColors) {
            return theme.palette.primary.main;
        }
        return theme.palette.secondary.mainNav;
    }
};

const getTopBg = (props, theme) => {
    if (props.variant === "light") {
        if (props.reverseColors) {
            return "#fff";
        }
        return theme.palette.secondary.main;
    } else {
        if (props.reverseColors) {
            return theme.palette.secondary.main;
        }
        return theme.palette.secondary.main;
    }
};

const useStyles = makeStyles((theme) => {
    return {
        nav: {
            boxShadow:
                "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
            background: (props) => getNavBg(props, theme),
            color: (props) => getTextColor(getNavBg(props, theme)),
        },
        topLinkButton: {
            color: "inherit",
        },
        topLink: {
            fontSize: 10,
            background: (props) => getTopBg(props, theme),
            color: (props) => getTextColor(getTopBg(props, theme)),
            marginBottom: "-2px",
        },
        header: {
            background: (props) => getNavBg(props, theme),
            boxShadow:
                "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
        },
        link: {
            fontSize: "1.3rem",
            fontWeight: 500,
            color: (props) => getTextColor(getNavBg(props, theme)),
        },
        label: {
            fontSize: 10,
            textTransform: "none",
        },
        logo: {
            fontSize: "3rem",
            fontWeight: 500,
            marginBottom: 4,
            textDecoration: "none",
            color: (props) => getTextColor(getNavBg(props, theme)),
        },
    };
});


function Navigation(props) {
    const {topLinks, navLinks, CartButton, isAuth, canOrder, isSticky} = props;
    const classes = useStyles(props);

    function filterAuth(x) {
        if (x.showType === "guest") {
            return !isAuth;
        } else if (x.showType === "auth") {
            return isAuth;
        }
        return x;
    }

    const renderMenu = (children) => (
        <div style={{display: "flex", flexDirection: "column"}}>
            {children.map(({name, path, href}) => (
                <ListItem key={name} style={{padding: 4}}>
                    <NavigationLink
                        style={{textAlign: "left"}}
                        fullWidth
                        highlightSelected
                        to={path}
                        href={href}
                        className={classes.link}
                    >
                        {name}
                    </NavigationLink>
                </ListItem>
            ))}
        </div>
    );

    function withChildren(link) {
        if (!link.children?.length)
            return (
                <NavigationLink
                    highlightSelected
                    to={link.path}
                    href={link.href}
                    className={classes.link}
                >
                    {link.name}
                </NavigationLink>
            );
        return (
            <Tooltip
                placement="bottom-start"
                interactive
                title={renderMenu(link.children)}
            >
                <div>
                    <NavigationLink
                        fullWidth
                        highlightSelected
                        to={link.path}
                        href={link.href}
                        className={classes.link}
                    >
                        {link.name}
                    </NavigationLink>
                </div>
            </Tooltip>
        );
    }

    function renderTopLinks() {
        return topLinks.filter(filterAuth).map((link) => {
            if (Boolean(link.customEl)) {
                const {customEl: Element} = link;
                return <Element/>;
            } else if (Boolean(link.component)) {
                const {component: Component, ...props} = link;
                return <Component {...props} />;
            }
            return (
                <NavigationLink
                    to={link.path}
                    href={link.href}
                    className={classes.topLinkButton}
                    size={10}
                >
                    {link.name.toUpperCase()}
                </NavigationLink>
            );
        });
    }

    function renderNavLinks() {
        return navLinks.filter(filterAuth).map((link) => {
            if (Boolean(link.customEl)) {
                const {customEl: Element} = link;
                return <Element/>;
            } else if (Boolean(link.component)) {
                const {component: Component, ...props} = link;
                return <Component {...props} />;
            }
            return withChildren(link);
        });
    }

    const {ref, isStickySub} = StickyHeader();

    return (
        <div ref={ref} className={isSticky? [ classes.nav, isStickySub ? 'stickyHeader':''].join(' ') : classes.nav}>
            <Box className={classes.topLink}>
                <Container maxWidth="xl">
                    <Grid container justify="space-between">
                        {renderTopLinks()}
                    </Grid>
                </Container>
            </Box>
            <Divider/>
            <Box className={classes.header}>
                <Container maxWidth="xl">
                    <Grid container alignItems="center">
                        <Typography
                            variant="h2"
                            className={classes.logo}
                            component={Link}
                            to="/"
                        >
                            S
                        </Typography>
                        <Box style={{flex: 1, display: "flex", justifyContent: "center"}}>
                            {renderNavLinks()}
                        </Box>
                        {canOrder && <CartButton/>}
                    </Grid>
                </Container>
            </Box>
        </div>
    );
}

export default Navigation;

Navigation.defaultProps = {
    variant: "dark",
    reverseColors: false,
    topLinks: [],
    navLinks: [],
};

Navigation.propTypes = {
    topLinks: PropTypes.array.isRequired,
    navLinks: PropTypes.array.isRequired,
    isAuth: PropTypes.bool,
    canOrder: PropTypes.bool,
    CartButton: PropTypes.object,
    variant: PropTypes.oneOf(["dark", "light"]),
    reverseColors: PropTypes.bool,
};
