import AccountProfileLayout from "../components/AccountProfileLayout";
import {Box, Divider, FormControl, Grid, IconButton, MenuItem, Select, TextField, useTheme} from "@material-ui/core";
import Tabs from "components/Tabs/Tabs";
import React, {useEffect, useState} from "react";
import {fetchData, getRoles} from "../../../utils";
import {API_URL} from "../../../config";
import {isUserAuthenticated} from "../../../store/auth/account/api";
import moment from "moment";
import {useProfileIds} from "../../../hooks/auth";
import {KeyboardArrowDownRounded} from "@material-ui/icons";
import OrderHistoryDataTable from "./components/OrderHistoryDataTable";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Text from "../../../components/Text/Text";
import Popover from "../../../components/Popover/Popover";
import {Link} from "react-router-dom";
import {userRoles} from "../../../constants/enums";
import SearchIcon from "@material-ui/icons/Search";
import Button from "../../../components/Button/Button";
import {Pagination} from "@material-ui/lab";

export const ProductsThumbnails = (props) => {
    const {items} = props
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        items.length > 3 && setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return <div style={{width: '100%'}} onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                aria-haspopup="true"
                aria-owns={open ? 'mouse-over-popover' : undefined}>
        <Grid container spacing={1}
              alignContent={'center'}
              alignItems={'center'}>
            {items && items.length > 0 && items.slice(0, 3).map((i, idx) => {
                return <Grid key={`${i.itemId}-${idx}`} item style={{width: "40px", height: "40px"}}>
                    <img height={'100%'}
                         src={i.itemImageUrl}
                         alt={i.itemId}/>
                </Grid>
            })}
            {items?.length > 3 && <Text>+{items?.length - 3}</Text>}
        </Grid>
        <Popover
            open={open}
            anchorEl={anchorEl}
            containerStyles={{maxWidth: 260}}
            onClose={handlePopoverClose}
            placement={'center'}
            disableRestoreFocus>
            <Grid container spacing={1} alignContent={'center'} alignItems={'center'}>
                {items.map((i, idx) => {
                    return <Grid key={`${i.itemId}-${idx}`} item style={{width: "40px", height: "40px"}} component={Link}
                                 to={`/catalog/products/${i.itemNumber}`}><img
                        height={'100%'}
                        src={i.itemImageUrl} alt={i.itemId}/></Grid>
                })}
            </Grid>
        </Popover>
    </div>
}

const OrderHistory = (props) => {
    const {activeUserTradeAccountId} = useProfileIds()
    const [activeTab, setActiveTab] = useState(0)
    const [ordersData, setOrdersData] = useState([])
    const [counters, setCounters] = useState(0)
    const [selectedRow, setSelectedRow] = useState(-1)
    const [orderDetails, setOrderDetails] = useState({})
    const [loadingDetails, setLoadingDetails] = useState(false)
    const [tradeMembers, setTradeMembers] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectedMember, setSelectedMember] = useState(-1)
    const isAuth = isUserAuthenticated()
    const role = getRoles()
    const isTradeAccountOwner = role?.includes(userRoles.TRADE_ACCOUNT_OWNER)
    const [data,setData] = useState([])
    const [page,setPage] = useState(0)
    const [query, setQuery] = useState(null)
    useEffect(() => {
        if (isAuth && activeUserTradeAccountId) {
            setIsLoading(true)
            fetchData(`${API_URL}/trade-account/${activeUserTradeAccountId}/members`).then((res) => {
                setTradeMembers(res)
                setIsLoading(false)
                setSelectedMember(-1)
            }).catch((e) => {
                setIsLoading(false)
            })
        }

    }, [isAuth, activeUserTradeAccountId])

    const dataMapping = [
        {
            label: '',
            key: 'id',
            transform: (orderId => {
                return <IconButton style={{border: '1px solid black'}} size={'small'}
                                   onClick={() => getOrderDetails(orderId)}>
                    {selectedRow === orderId ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownRounded/>}
                </IconButton>
            })
        },

        {
            label: 'Order date',
            key: 'createdAt',
            transform: (items => {
                return moment(items).format('MM/DD/YYYY')
            })
        },
        {
            label: 'Order No.',
            key: 'id',
            // transform: (orderId)=>{
            //     return padLeadingZeros(orderId,5)
            // }
        },
        {
            label: 'Status',
            key: 'status',
            attributes: {
                style: {maxWidth: 150}
            },
            transform: (items) => {
                return items.map((item) => {
                    return <Box fullWidth>
                        <Text>{item}</Text>
                    </Box>
                })
            }
        },
        {
            label: 'Project',
            key: 'project',
        },
        {
            label: 'PO NO.',
            key: 'poNumber',
        },
        {
            label: 'Grand Total',
            key: 'grandTotal',
            transform: (total) => {
                return `$${total.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            }
        },
        {
            label: 'Invoice',
            key: 'invoice',
            transform: (invoice,row)=>{
                if (row.status.length === 1 && (row.status[0] === 'In Process' || row.status[0] === 'Cancelled'))
                    return <></>
                else if (row.status.find((s)=>s.includes('Shipped')))
                return <Button color={'primary'} variant={'outlined'} size={'small'} style={{width:118,height:31}}>Download</Button>
                return <Button color={'primary'} variant={'outlined'} size={'small'} style={{width:118,height:31}}>Pay Invoice</Button>
            }
        },
        {
            label: 'Item',
            key: 'items',
            transform: (items) => <ProductsThumbnails items={items}/>

        }
    ]

    const handleSelectedMember = (e) => {
        const value = e.target.value
        setSelectedRow(-1)
        setSelectedMember(value)
    }

    useEffect(() => {
        if (activeUserTradeAccountId) {
            setIsLoading(true)
            fetchData(`${API_URL}/trade-account/${activeUserTradeAccountId}/orders/history?memberId=${selectedMember === -1 ? '' : selectedMember}&page=${page+1}&term=${query?query:''}`).then((res) => {
                setOrdersData(res.content)
                setData(res)
                setCounters(res.totalElements)
                setSelectedRow(-1)
                setIsLoading(false)
            }).catch((e) => {
                setIsLoading(false)
            })
        }
        // eslint-disable-next-line
    }, [selectedMember,page, activeUserTradeAccountId])

    useEffect(() => {
        if (query !== null) {
            const delayDebounceFn = setTimeout(() => {
                const urlSearch = `${API_URL}/trade-account/${activeUserTradeAccountId}/orders/history?memberId=${selectedMember === -1 ? '' : selectedMember}&term=${query}`
                setIsLoading(true)
                fetchData(urlSearch).then((res) => {
                    setOrdersData(res.content)
                    setCounters(res.totalElements)
                    setSelectedRow(-1)
                    setData(res)
                    setIsLoading(false)
                }).catch((e) => {
                    setIsLoading(false)
                })
            }, 600)
            return () => clearTimeout(delayDebounceFn)
        }
        // eslint-disable-next-line
    }, [query])

    const getOrderDetails = (orderId) => {
        if (selectedRow === orderId) {
            setSelectedRow(-1)
        } else {
            setSelectedRow(orderId)
            setLoadingDetails(true)
            fetchData(`${API_URL}/order-details/${orderId}`).then((res) => {
                setOrderDetails(res)
                setLoadingDetails(false)
            })
        }
    }

    // function padLeadingZeros(num, size) {
    //     let s = num+"";
    //     while (s.length < size) s = "0" + s;
    //     return s;
    // }


    const theme = useTheme();

    const headerAttributes = {
        style: {
            backgroundColor: theme.palette.secondary.light,
            color: '#000',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        }
    }

    return <AccountProfileLayout
        title={'Order History'}
        {...props}>


        <Divider style={{height: 1, backgroundColor: "#1B1C1D"}}/>
        <Grid container alignItems={'center'} spacing={2}>
            <Grid item xs={4}>
                <Tabs value={activeTab} setValue={setActiveTab} hideDividerBottom={true} hideDividerTop={true}
                      tabs={['All orders']} counters={[counters]}/>
            </Grid>
            {isTradeAccountOwner &&
            <>
                <Grid item xs={3}>
                    <FormControl
                        fullWidth
                        variant="outlined"
                        margin={"dense"}
                    >
                        <Select
                            value={selectedMember}
                            onChange={handleSelectedMember}
                        >
                            <MenuItem key={-1} value={-1}>
                                Orders from All Members
                            </MenuItem>
                            {tradeMembers.map((member) => {
                                return <MenuItem key={member.id}
                                                 value={member.id}>{`${member.firstName} ${member.lastName}`}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        variant={"outlined"}
                        margin={"dense"}
                        fullWidth
                        disableUnderline
                        InputProps={{
                            startAdornment: <SearchIcon/>,
                        }}
                        placeholder={"Search Orders by any keyword"}
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                </Grid>
            </>
            }
        </Grid>
        <Divider style={{height: 1, backgroundColor: "#1B1C1D"}}/>

        <OrderHistoryDataTable mapping={dataMapping} data={ordersData} isLoading={isLoading}
                               loadingDetails={loadingDetails}
                               expandThisRow={selectedRow} orderDetails={orderDetails}
                               headerCellAttributes={headerAttributes}/>
        <Pagination style={{float:'right',marginTop:20}} count={data.totalPages} page={page+1} onChange={(e,value)=> {
            window.scrollTo(0, 0);
            setPage(value - 1)
        }}/>

    </AccountProfileLayout>
}

export default OrderHistory
