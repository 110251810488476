import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid, Typography} from '@material-ui/core'
import ActionButton from "../../Core/ActionButton";
import ActivityCard from "../ActivityCard";
import SectionTitle from "../SectionTitle";
import Image3 from "assets/images/User-Column-Web-Features/User-Column-FavoritesFeature.jpg";
import Image2 from "assets/images/User-Column-Social/User-Column-YouTube.jpg";
import Image1 from "assets/images/User-Column-Trade-Program/User-Column-ApplyTA.jpg";
import GreetingText from "../GreetingText";

const useStyles = makeStyles({
  wrapper: {
    height: "100%",
  },
});

const activities = [
  {
    image: Image1,
    title: "Want to have it all?",
    text:
      "Our trade program offers access to one-on-one personal help, live product inventory, exclusive net pricing and more.",
  },
  {
    image: Image2,
    title: "Step Inside the Dreamiest Homes",
    text:
      "Our video tours give you an exclusive peek at designers’ own dwellings.",
    action: "Watch on Youtube",
    href: "https://www.youtube.com/channel/UCsViJuCYqtWXpDFeadQHKLA"
  },
  {
    image: Image3,
    title: "Save Your Favorites",
    text: "Bookmark items for later and organize by project.",
    action: "MY Favorites",
    actionPath:'/account/favorites',
  },
];

const NoActivities = ({ visibleProfile }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.wrapper} md={4} xs={12} item>
      <GreetingText
        greeting={"Welcome,"}
        text={"Great to have you here"}
        user={visibleProfile}
      />
      <ActivityCard activity={activities[0]} />
      <Box mt={1} />
      {visibleProfile.hasNonSubmittedTradeAccountApplication&&<ActionButton title={"Complete your account application"} actionPath={"/account/verify"}/>}
      {visibleProfile.hasPendingTradeAccountApplication&&!visibleProfile.hasNonSubmittedTradeAccountApplication&&<>
      <Typography fontSize={24} fontWeight={'bold'} style={{textTransform:'uppercase'}}>Your application is pending review</Typography>
      </>}
      <Box mt={3} />
      <SectionTitle title={"From the design community"} />
      <ActivityCard activity={activities[1]} />
      <Box mt={4} />
      <SectionTitle title={"You've got great taste."} />
      <ActivityCard activity={activities[2]} />
    </Grid>
  );
};
export default NoActivities;
