import {
Grid
} from '@material-ui/core'
import {useEffect, useState,useMemo} from 'react'
import {Formik} from 'formik'
import FormikTextField from 'components/Core/FormikFields/FormikTextField'
import FormikSelect from 'components/Core/FormikFields/FormikSelect'
import Dialog from 'components/Dialog/Dialog'
import {ADMIN_API_URL} from 'config'
import {getAxiosConfig} from 'utils'
import axios from 'axios'
import * as Yup from 'yup'
import {roleOptions, userRoles} from 'constants/enums'
import { useSnackbar } from "notistack";

const memberStatuses = [
  {
    id: 'ACTIVE',
    name: 'Active',
  },
  {
    id: 'DISABLED',
    name: 'Disabled',
  }
]

const userOriginValues = {
  EXTERNAL: 'EXTERNAL',
  INTERNAL: 'INTERNAL'
}

const userOrigin = [
  {
    id: userOriginValues.EXTERNAL,
    name: 'External'
  },
  {
    id: userOriginValues.INTERNAL,
    name: 'Internal'
  }
]

const EditNewMemberDialog = ({open, setOpen, id, accountId, setData, data, isSalesRep}) => {

  const [isBusy, setIsBusy] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState()
  const { enqueueSnackbar } = useSnackbar();

  useEffect(()=>{
    if (id) {
      setIsLoading(true);
      const url = `${ADMIN_API_URL}/accountDetails/${accountId}/members/${id}`
      axios.get(url,getAxiosConfig(true))
        .then((response) => {
          if (response.status === 200) {
            setFormData(response.data)
          } else {
            handleClose();
            enqueueSnackbar(`User not found.`, {variant:'error'});
          }
          setIsLoading(false);
        })
        .catch((err) => {
          // Silent error
          handleClose();
          enqueueSnackbar(`User not found.`, {variant:'error'});
          setIsLoading(false);
          console.log(err)
          throw err;
        });
    }
    //eslint-disable-next-line
  },[id])

  const handleClose = () => {
    setOpen(false)
  }

  const save = (values) => {
    setIsBusy(true)
    const url = id?`${ADMIN_API_URL}/accountDetails/${accountId}/members/${id}`:`${ADMIN_API_URL}/accountDetails/${accountId}/members`
    const method = id?'put':'post'

    axios[method](url,values,getAxiosConfig(true))
      .then((response) => {
        if (response.status === 200) {
        // Change Data if existing
          if (id) {
            const memberIndex = data.findIndex((m)=>m.id===id)

            if (memberIndex!==-1) {
              const newMembers = [...data]
              newMembers[memberIndex] = {...newMembers[memberIndex], ...values, fullName: `${values.firstName} ${values.lastName}`}
              setData(newMembers)
            }
          }
          enqueueSnackbar(`Successfully ${id?'saved':'added new'} member.`, {variant:'success'});
        }

        if (response.status===201) {
            const newMembers = [...data]
            newMembers.push({...response.data, fullName: `${response.data.firstName} ${response.data.lastName}`})
            setData(newMembers)
        }

        setIsBusy(false);
        handleClose();

      })
      .catch((err) => {
        // Silent error
        if (err.response.data && err.response.data.message) {
          enqueueSnackbar(err.response.data.message, {variant:'error'});
        }
        setIsBusy(false);
        console.log(err)
        throw err;
      });
  }

  const validation = Yup.object().shape({
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
    accountType: Yup.string().required('This field is required'),
    userOrigin: Yup.string().required('This field is required'),
    memberStatus: id?Yup.string().required('This field is required'):null
  })

  const accountType = useMemo(_ => {
    if (formData) {
      if (formData.userOrigin === 'EXTERNAL') {
        return roleOptions.filter(option => !option.internal);
      } else if (formData.userOrigin === 'INTERNAL') {
        return roleOptions.filter(option => option.internal);
      }
    }
    return roleOptions;
  }, [formData])

  const title = useMemo(_ => {
    if (formData) {
      const role = accountType.find(role => role.id === formData.accountType)
      return role ? role.name : ''
    }
    return ''
  }, [formData, accountType])

  // Clear user role when internal/external changes and a different role is selected
  useEffect(_ => {
    if (formData) {
      const isInternal = formData.userOrigin === userOriginValues.INTERNAL;
      const role = accountType.find(role => role.id === formData.accountType)
      if (!role || role.internal !== isInternal) {
        if (formData.accountType !== '') {
          setFormData(prevFormData => ({...prevFormData, accountType: ''}))
        }
      }
    }
  }, [formData, accountType]);

  if (isLoading) {
    return <> </>
  }

  return <>
    <Formik
      enableReinitialize={true}
      initialValues={id?formData:{
        userOrigin: 'INTERNAL',
        email: '',
        firstName: '',
        lastName: '',
        title: '',
        accountType: userRoles.WEB_USER
      }}
      validationSchema={validation}
      onSubmit={save}
    >{({values, errors, submitForm}) => (
      <Dialog
        onClose={handleClose}
        open={open}
        title={`${title} Details`}
        maxWidth="sm"
        primaryActionTitle={'SAVE'}
        secondaryActionTitle={'CANCEL'}
        primaryAction={submitForm}
        secondaryAction={()=>{handleClose()}}
        inProgress={isBusy}
        disableBackdropClick={isBusy}
        disableEscapeKeyDown={isBusy}
      >
        <form noValidate>

        <Grid item container spacing={2}>
          {/* Email */}
          <Grid item md={id?6:12} xs={12}>
            <FormikTextField
              variant={'outlined'}
              margin="none"
              size={'small'}
              fullWidth
              type={'text'}
              id={'email'}
              name={'email'}
              label={'Email'}
              disabled={!!id}
            />
          </Grid>
          {/* Member Status */}
          {!!id&&<Grid item md={6} xs={12}>
            <FormikSelect
              variant={'outlined'}
              margin="none"
              fullWidth
              id={'memberStatus'}
              name={'memberStatus'}
              label={'Member status'}
              options={memberStatuses}
              disabled={isSalesRep}
            />
          </Grid>}
          {/* User Origin */}
          <Grid item md={6} xs={12}>
            <FormikSelect
              variant={'outlined'}
              margin="none"
              fullWidth
              id={'userOrigin'}
              name={'userOrigin'}
              label={'User origin'}
              options={userOrigin}
              handleChange={value => setFormData(prevFormData => ({...prevFormData, userOrigin: value}))}
            />
          </Grid>
          {/* User Role */}
          <Grid item md={6} xs={12}>
            <FormikSelect
              variant={'outlined'}
              margin="none"
              fullWidth
              id={'accountType'}
              name={'accountType'}
              label={'User role'}
              options={accountType}
              disabled={isSalesRep}
            />
          </Grid>
          {/* First Name */}
          <Grid item md={6} xs={12}>
            <FormikTextField
              variant={'outlined'}
              margin="none"
              size={'small'}
              fullWidth
              type={'text'}
              id={'firstName'}
              name={'firstName'}
              label={'First name'}
            />
          </Grid>
          {/* Last name */}
          <Grid item md={6} xs={12}>
            <FormikTextField
              variant={'outlined'}
              margin="none"
              size={'small'}
              fullWidth
              type={'text'}
              id={'lastName'}
              name={'lastName'}
              label={'Last name'}
            />
          </Grid>

          <Grid item xs={12}>
            <FormikTextField
              variant={'outlined'}
              margin="none"
              size={'small'}
              fullWidth
              type={'text'}
              id={'title'}
              name={'title'}
              label={'Title (optional)'}
            />
          </Grid>
        </Grid>
        </form>
      </Dialog>)}
    </Formik>
  </>
}

export default EditNewMemberDialog
