import {Box, Container, Grid, Typography} from '@material-ui/core'
import {connect} from "react-redux";
import {apiError} from "../../../store/auth/account/actions";
import axios from 'axios'
import {API_URL} from 'config'
import {useEffect, useState} from 'react'
import {useSnackbar} from 'notistack'
import {parseJwt} from 'utils'
import {Link} from "react-router-dom";

const Verify = ({history}) => {
  const {enqueueSnackbar} = useSnackbar();

  const [isBusy, setIsBusy] = useState(false)
  const [data,setData] = useState(false)

  const userAuth = localStorage.getItem('authUser')
  const userInfo = userAuth ? parseJwt(JSON.parse(userAuth).access_token) : {}

  const email = userInfo ? userInfo.email : ''

  const resendConfirmation = () => {
    if (isBusy) return false;
    const url = `${API_URL}/account/send-confirmation`

    axios.post(url, {email})
      .then((response) => {
        if (response.status === 200) {
          // enqueueSnackbar(`The verification e-mail was sent successfully`, {variant: 'success'});
          console.log(`The verification e-mail was sent successfully`)
        }
        setData(true);
        setIsBusy(false);
      })
      .catch((err) => {
        enqueueSnackbar(`There was an error sending the verification e-mail.`, {variant: 'error'});
        setIsBusy(false);
        throw err;
      });
  }

  const getConfirmation = () => {
    if (isBusy) return false;
    const url = `${API_URL}/account/confirmation-status?email=${email}`

    axios.get(url)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.confirmed) {
            setData(true)
            history.push('/account/create-trade-account')
          } else {
            resendConfirmation()
          }
        }
        setIsBusy(false);
      })
      .catch((err) => {
        resendConfirmation();
        setIsBusy(false);
        throw err;
      });
  }

  useEffect(() => {
    getConfirmation()
    // eslint-disable-next-line
  }, [])

  if (!data) {
    return <Container style={{minHeight:'80vh'}}> </Container>
  }

  return (
    <>
      <Container>

        <Grid item xs={12} md={7}>
          <Box mt={6}/>
          <Typography variant="h1" style={{fontWeight: 300, fontFamily: 'Chronicle', fontSize: 48}}>
            Verify Your Email
          </Typography>
          <Box pt={3} style={{fontFamily: 'Chronicle', fontSize: 18}}>

            <p>To ensure a valid email is associated with your pending trade account, click the verification button in
              the email we sent to <b>{email}.</b> This helps keep your account secure. </p>

            <p>No email in your inbox or spam folder? <Box
              style={{textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer', opacity: isBusy ? 0.3 : 1}}
              onClick={resendConfirmation}>Resend the verification email.</Box></p>

            <p>If you mistyped your email when signing up, please create a <Link to={'/signup'}>new account.</Link></p>

          </Box>
        </Grid>

      </Container>
    </>
  );
};
const mapStateToProps = (state) => {
  const {error, loading, isAuth} = state.Auth;
  return {error, loading, isAuth};
};

export default connect(mapStateToProps, {apiError})(Verify);
