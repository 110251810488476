import PropTypes from "prop-types";
import { Box, CardMedia } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import var1 from "../../../images/pr2.jpeg";

const useStyles = makeStyles(theme => ({
  image: {
    height: 0,
    paddingTop: "100%", // 1:1
    // borderRadius: theme.borderRadius
  },
  actions: {
    position: "absolute",
    right: 0,
    bottom: 12,
    marginRight: 4,
    marginBottom: 8,
  },
  tag: {
    minWidth: "90px",
    width: "auto",
    height: "60px",
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50px",
    boxShadow: "0px 0px 16px rgba(27, 28, 29, 0.1)",
    color: "#1B1C1D",
    padding: "16px 24px",
    fontFamily: "Chronicle",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "20px",
    lineHeight: "140%",
    textAlign: "center",
    letterSpacing: "0.0125em",
  },
  button: {
    backgroundColor: "#25282A",
    padding: 4,
    margin: 4,
    color: "white",
    "&:hover": {
      backgroundColor: "black",
    },
  },
}));

const CategoryCard = ({ category, image = var1 }) => {
  const classes = useStyles();
  return (
    <Box position={"relative"}>
      <CardMedia className={classes.image} image={image} alt={"project"} />
      <Box className={classes.actions}>
        <div className={classes.tag}>{category}</div>
      </Box>
    </Box>
  );
};

export default CategoryCard;

CategoryCard.propTypes = {
  project: PropTypes.object.isRequired,
};

CategoryCard.defaultProps = {};
