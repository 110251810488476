import { makeStyles } from "@material-ui/core/styles";
import { Fragment } from "react";
import Button from "./Button/Button";
import { ExitToApp } from "@material-ui/icons";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100vh",
    position: "fixed",
    border: "4px solid #0CD400",
    overflow: "auto",
    zIndex: 1,
  },
  button: {
    color: "#0CD400",
    position: "fixed",
    bottom: 0,
    right: 0,
    fontWeight: "bold",
    margin: 8,
  },
});

export function SpoofingWrapper(props) {
  const { active, children, exitSpoofing } = props;
  const classes = useStyles();

  return active ? (
    <div className={classes.root}>
      {children}
      <Button
        onClick={exitSpoofing}
        href={window.location.origin}
        endIcon={<ExitToApp />}
        className={classes.button}
      >
        EXIT SPOOFING MODE
      </Button>
    </div>
  ) : (
    <Fragment>{children}</Fragment>
  );
}
