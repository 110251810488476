import {contactTypes} from 'constants/enums'
import {extractStringByIndex} from 'utils'

export const parseAddresses = (contactInformation, data) => {
  contactTypes.forEach((type) => {

    const varPrefix = type.toLowerCase()
    contactInformation[`sameAsBusinessAddress${varPrefix[0].toUpperCase()}${varPrefix.slice(1)}`] = false

    // Get data
    let contact = data.contacts.find((c) => c.contactType === type)
    if (!contact) {
      contact = data.contacts.find((c) => c.contactType === contactTypes[0]) // BUSINESS (default)
      contactInformation[`sameAsBusinessAddress${varPrefix[0].toUpperCase()}${varPrefix.slice(1)}`] = true
    }

    // Set fields
    const varNames = ['name', 'addressLine1', 'addressLine2', 'zipCode', 'city', 'id']
    varNames.forEach((varName) => {
      let value = contact[varName]
      let key = `${varName[0].toUpperCase()}${varName.slice(1)}`
      contactInformation[`${varPrefix}${key}`] = value ? value : ''
    })
    contactInformation[`${varPrefix}State`] = contact.stateId ? contact.stateId : ''
    contactInformation[`${varPrefix}PhoneNumber`] = contact.phoneNumber ? extractStringByIndex(contact.phoneNumber,1) : ''
  })
}

