import {ErrorMessage, useField} from "formik";
import {Box, CircularProgress, FormControl, InputAdornment, InputLabel, MenuItem, Select} from "@material-ui/core";
import {useContext, useState} from 'react'
import {FormikContext} from "components/FormikDisabler";
import WarnEditOverlay from 'components/Core/FormikFields/WarnEditOverlay'

const FormikSelect = (props) => {
  const { options, required = false, handleChange, loading, warnEdit, ...rest} = props;
  const [field, meta, helpers] = useField(props.name);
  const {disabled, locked} = useContext(FormikContext)

  const [showWarning, setShowWarning] = useState(null)
  const isDisabled = props.disabled || disabled

  return (
    <FormControl
      fullWidth
      required={required}
      variant="outlined"
      error={meta.error && meta.touched}
      disabled={disabled||props.disabled}
      // helperText={"Test"}
      style={{position:'relative'}}
    >
      {(warnEdit||locked)&&!isDisabled&&<WarnEditOverlay showWarning={showWarning} setShowWarning={setShowWarning} />}
      <InputLabel id="schumacher-select-outlined-label">
        {props.label}
      </InputLabel>
      <Select
        {...field}
        {...rest}
        value={options && options.length ? field.value : ''}
        onChange={(e) => {
          helpers.setValue(e.target.value)
          if (handleChange) handleChange(e.target.value)
        }}
        endAdornment={
          <InputAdornment position="end" style={{marginRight:20}}>
            {loading&&<CircularProgress size={20} />}
          </InputAdornment>
        }
        disabled={disabled||props.disabled}
      >
        {/*{!required && (*/}
        {/*  <MenuItem key={0} value={0}>*/}
        {/*    None*/}
        {/*  </MenuItem>*/}
        {/*)}*/}

        {options.map((option, index) => {
          return (
            <MenuItem key={index} value={option.id}>
              {option.name}
            </MenuItem>
          );
        })}
      </Select>
      <ErrorMessage name={field.name} render={msg => <Box pl={1} pt={1} style={{textTransform:'uppercase',color:'#DF0006', fontWeight: 'bold'}}>{msg}</Box>} />
    </FormControl>
  );
};

export default FormikSelect;
