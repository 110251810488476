import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import ContactDetails from 'pages/Admin/Account/common/ContactDetails'
import {useConfig} from 'utils'
import {Checkbox, FormControlLabel, withStyles} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {businessTypesOptions} from 'constants/enums'

const CheckboxWithYellowCheck = withStyles({
  root: {
    "&$checked": {
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
        color: "#FFD700",
      },      "& .MuiIconButton-label:after": {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "#FFD700",
        zIndex: -1
      },

    }
  },
  checked: {}
})(Checkbox);

const useStyles = makeStyles((theme) => ({
  formControlLabel : {
    fontSize: 14,
    marginTop: 16
  }
}))

const BusinessInformation = ({businessInfo}) => {
  const [businessTypes] = useConfig(`config/business-types`, []);

  const businessAddress = businessInfo?businessInfo.businessAddress:false
  const {businessFocus,businessType,federalTaxId,businessLink, firstNameOwner, lastNameOwner, emailOwner, businessName, focusOnResidentialHospitality} = businessInfo?businessInfo:''
  const classes = useStyles();

  return <Grid item container spacing={2}>

    <Grid item xs={12} md={12}>
      <Box fontSize={18} fontWeight={'bold'}>Business Information</Box>
    </Grid>

    <Grid item xs={12} md={5}>
      <Box fontWeight={'bold'}>Business Name</Box>
    </Grid>
    <Grid item xs={12} md={7}>
      {businessName}
    </Grid>

    <Grid item xs={12} md={5}>
      <Box fontWeight={'bold'}>Primary Focus</Box>
    </Grid>

    <Grid item xs={12} md={7}>
      {businessTypesOptions.find((f)=>f.id===businessFocus)?.name}<br/>

      <FormControlLabel classes={{label:classes.formControlLabel}}
        control={
          <CheckboxWithYellowCheck
            color="primary"
            id={"focusOnResidentialHospitality"}
            name={"focusOnResidentialHospitality"}
            checked={focusOnResidentialHospitality}
            disabled={true}
          />
        }
        label={"Have both residential & hospitality projects"}
      />

    </Grid>

    <Grid item xs={12} md={5}>
      <Box fontWeight={'bold'}>Business Type</Box>
    </Grid>
    <Grid item xs={12} md={7}>
      {(businessType&&businessTypes)?businessTypes[businessType]?.name:''}
    </Grid>

    <Grid item xs={12} md={5}>
      <Box fontWeight={'bold'}>Tax Registration ID</Box>
    </Grid>
    <Grid item xs={12} md={7}>
      {federalTaxId}
    </Grid>

    <Grid item xs={12} md={5}>
      <Box fontWeight={'bold'}>Business Owner/<br/>Principal</Box>
    </Grid>
    <Grid item xs={12} md={7}>
      {firstNameOwner} {lastNameOwner} <br/>
      {emailOwner}
    </Grid>

    <Grid item xs={12} md={5}>
      <Box fontWeight={'bold'}>Web Link</Box>
    </Grid>
    <Grid item xs={12} md={7}>
      {businessLink}
    </Grid>

    <Grid item xs={12} md={5}>
      <Box fontWeight={'bold'}>Business Phone No.</Box>
    </Grid>
    <Grid item xs={12} md={7}>
      {businessAddress.phoneNumber}
    </Grid>

    <Grid item xs={12} md={5}>
      <Box fontWeight={'bold'}>Business Address</Box>
    </Grid>

    <ContactDetails contact={businessAddress} />

  </Grid>
}

export default BusinessInformation

