import { Grid, Typography } from "@material-ui/core";
import ActionButton, {Hyperlink} from "components/Core/ActionButton";
import FooterImage from 'assets/images/Footer/Footer-Image.jpg'

const FooterWidget = () => {
  return (
    <Grid item container style={{ marginTop: 40 }} xs={12} md={10} spacing={2}>
      <Grid item>
        <Typography
          variant={"h3"}
          style={{
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "32px",
            lineHeight: "112.5%",
          }}
        >
          A Must-Have for Every Designer's Library
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant={"body1"}
          style={{
            color: "white",
            fontWeight: "normal",
            lineHeight: "112.5%",
            textAlign: "left",
          }}
        >
          Packed with inspiration images and organized into helpful categories,
          our Schumacher Library of Wallcoverings and Printed Fabrics catalogs
          make it easer than ever to browse our complete portfolio.
        </Typography>
      </Grid>
      <Grid item component={Hyperlink} href={'https://shop.fredericmagazine.com/products/the-schumacher-library-of-wallcoverings-fourth-edition?variant=39535690285218'} target={'_blank'}>
        <img
          src={FooterImage}
          width={"100%"}
          alt={"widget"}
        />
      </Grid>
      <Grid item>
        <ActionButton
          title={"Request a Catalog today"}
          actionColor={"#fff"}
          href={'https://shop.fredericmagazine.com/products/the-schumacher-library-of-wallcoverings-fourth-edition?variant=39535690285218'}
          target={'_blank'}
        />
      </Grid>
    </Grid>
  );
};

export default FooterWidget;
