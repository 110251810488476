import FormikTextField from "components/Core/FormikFields/FormikTextField";
import FormikSelect from "components/Core/FormikFields/FormikSelect";
import FormikCheckbox from "components/Core/FormikFields/FormikCheckbox";
import FormikPasswordField from "components/Core/FormikFields/FormikPasswordField";

export function getFormView(prop, index, busy) {
  switch (prop.type) {
    case "select":
      return (
        <FormikSelect
          variant={prop.variant}
          placeholder={"Select"}
          margin="none"
          required={prop.required}
          aria-required={prop.required}
          label={prop.label}
          id={prop.id}
          name={prop.id}
          options={prop.options}
          autoFocus={index === 0}
          disabled={busy}
        />
      );
    case "textarea":
      return (
        <FormikTextField
          variant={prop.variant}
          margin="none"
          multiline
          rows={8}
          placeholder={prop.label}
          fullWidth
          required={prop.required}
          aria-required={prop.required}
          type={prop.type || "text"}
          id={prop.id}
          name={prop.id}
          label={prop.label}
          autoFocus={index === 0}
          disabled={busy}
        />
      );
    case "checkbox":
      return (
        <FormikCheckbox
          label={prop.label}
          id={prop.id}
          name={prop.id}
          disabled={busy}
        />
      );
    case "password":
      return (
          <FormikPasswordField
              variant={prop.variant}
              margin="none"
              size={"small"}
              placeholder={prop.label}
              fullWidth
              required={prop.required}
              aria-required={prop.required}
              type={prop.type || "text"}
              id={prop.id}
              name={prop.id}
              label={prop.label}
              autoFocus={index === 0}
              disabled={busy}
          />
      );
    case "custom":
      return prop.element;
    default:
      return (
        <FormikTextField
          variant={prop.variant}
          margin="none"
          size={"small"}
          placeholder={prop.label}
          fullWidth
          required={prop.required}
          aria-required={prop.required}
          type={prop.type || "text"}
          id={prop.id}
          name={prop.id}
          label={prop.label}
          autoFocus={index === 0}
          disabled={busy}
        />
      );
  }
}
