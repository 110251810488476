import { Box, CardMedia, Grid, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Hyperlink } from "./Core/ActionButton";

const useStyles = makeStyles((theme) => ({
  button: {
    fontFamily: "Gotham",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "15px",
    lineHeight: "125%",
    letterSpacing: "0.01em",
    color: "#1B1C1D",
  },
  media: {
    height: 0,
    paddingTop: "75%", // 4:3
    // borderRadius: theme.borderRadius
  },
  title: {
    fontFamily: "Chronicle",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "56px",
    lineHeight: "100%",
    letterSpacing: "-0.025em",
    color: "#1B1C1D",
  },
}));
const Feature = ({
  title,
  description,
  size = "large",
  color = "#394d48",
  variant = "contained",
  image,
  cta = "See all bookprints",
  direction = { direction: "row" },
  href,
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} alignItems="center" direction={direction}>
      <Grid item md={6} sm={12}>
        {/*<img style={{ width: "100%" }} src={image} alt="membership" />*/}
        <CardMedia className={classes.media} image={image} title="membership" />
      </Grid>
      <Grid item md={6} sm={12}>
        <Box p={8}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h1" className={classes.title}>
                {title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography component="p">{description}</Typography>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                color={"secondary"}
                component={Hyperlink}
                variant={variant}
                size={size}
                href={href}
              >
                {cta}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Feature;
