import { EntityType } from "./entityType";
import { addressContactType } from "../../constants/enums";

export const Schema = {
  [EntityType.User]: {
    name: "",
  },
  [EntityType.PaymentMethod]: {
    number: null,
    expirationMonth: null,
    expirationYear: null,
    cvc: null,
    holderName: "",
    sameAsShipping: true,
    isDefault: false,
  },
  [EntityType.Address]: {
    contactType: addressContactType.BUSINESS,
    email: "",
    phoneNumber: "",
    addressLine1: "",
    addressLine2: "",
    zipCode: "",
    city: "",
    state: null,
    country: null,
    countryId: 0,
    stateId: 0,
    name: "",
    surname: "",
    friendlyName: "",
    attention: "",
    isDefault: false,
  },
};
