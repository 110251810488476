import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { CardActionArea } from "@material-ui/core";
import {
  FiberManualRecord,
  FiberManualRecordOutlined,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  card: {
    border: "1px solid #3B3C3E",
    borderRadius: 8,
    display: "flex"
  },
  container: {
    padding: 32,
  },
}));

const CheckoutCard = (props) => {
  const classes = useStyles();
  const { onClick, checked, children, disabled, direction } = props;
  const spacing = direction.includes("column") ? 4 : 8
  return (
    <Card variant={"outlined"} className={classes.card}>
      <CardActionArea
        className={classes.container}
        onClick={onClick}
        disabled={disabled}
      >
        <Grid container alignItems={"center"} wrap={"nowrap"} direction={direction} spacing={spacing}>
          <Grid item>
            {checked ? <FiberManualRecord color={"secondary"} /> : <FiberManualRecordOutlined />}
          </Grid>
          <Grid item style={{flex: 1}}>{children}</Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

CheckoutCard.propTypes = {
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  direction: PropTypes.oneOf(["row", "column", "column-reverse", "row-reverse"])
};

CheckoutCard.defaultProps = {
  direction: "row"
}

export default CheckoutCard;
