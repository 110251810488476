import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Text from "../../components/Text/Text";
import {
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Grid,
  Typography,
} from "@material-ui/core";
import ActionButton, { Hyperlink } from "../../components/Core/ActionButton";
import OtherTiles1 from "../../assets/images/Other-Content-Tiles/Bottom-Tile-1.jpg";
import OtherTiles2 from "../../assets/images/Other-Content-Tiles/Bottom-Tile-2.jpg";
import OtherTiles3 from "../../assets/images/Other-Content-Tiles/Bottom-Tile-3.jpg";

const useStyles = makeStyles((theme) => ({
  missContainer: {
    width: "100%",
    marginTop: 40,
    paddingBottom: 50,
    background: theme.palette.secondary.light,
  },
  root: {
    width: "100%",
  },
  media: {
    height: 0,
    paddingTop: "125%", // 4:5
    maxWidth: "100%",
    // borderRadius: theme.borderRadius
  },
  card: {
    background: "transparent",
    // borderRadius: theme.borderRadius,
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    textTransform: "uppercase",
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const cards = [
  {
    text: "Blues",
    actionName: "More Classic Blues Ahead",
    actionPath: "/shop-by/blues/2608170",
    image: OtherTiles1,
  },
  {
    text: "Pinks",
    image: OtherTiles2,
    actionPath: "/shop-by/pinks/2608274",
    actionName: "Shop Blush to Hot Pink",
  },
  {
    text: "Greens",
    image: OtherTiles3,
    actionPath: "/shop-by/greens/2608273",
    actionName: "Explore Statement-Making Greens",
  },
];

function ShopByGrid(props) {
  const classes = useStyles();
  return (
    <div className={classes.missContainer}>
      <div>
        <Text
          variant="h3"
          color={"textPrimary"}
          style={{
            fontWeight: "300",
            fontSize: "32px",
            textAlign: "center",
            paddingBottom: 24,
            paddingTop: 64,
          }}
        >
          Shop by Color
        </Text>
        <Grid
          // className={classes.wrapper}
          container
          spacing={10}
          wrap="wrap"
          style={{ maxWidth: 1280, marginLeft: "auto", marginRight: "auto" }}
        >
          {cards.map(({ text, image, actionPath, actionName }) => (
            <Grid item style={{ flex: 1 }}>
              <Card className={classes.card} elevation={0} square>
                <CardActionArea className={classes.card} component={Hyperlink} actionPath={actionPath}>
                  <CardMedia
                    className={classes.media}
                    image={image}
                    title={text}
                  />
                </CardActionArea>
                <CardActions style={{ paddingLeft: 0 }}>
                  <Grid container direction={"column"}>
                    <Grid item>
                      <Typography
                        style={{
                          fontFamily: "Chronicle",
                          fontWeight: 400,
                          lineHeight: "140%",
                          fontSize: 20,
                          letterSpacing: "0.0125em",
                        }}
                      >
                        {text}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <ActionButton title={actionName} actionPath={actionPath} />
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default ShopByGrid;
