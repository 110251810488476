const primaryLight = {
  main: "#000",
  contrastText: "#fff"
};

const secondaryLight = {
  main: "#FFD700",
  light:"#fcf9e8",
  mainNav: "#fff",
  lightNav:"#fff",
  contrastText: "#54585a"
};

const errorColor = {
  main: "#FA8072"
};

const primaryDark = {
  main: "#FFD700"
  // contrastText: "#181D30"
};
const secondaryDark = {
  main: "#FFD700",
  mainNav: "#FFD700",
  lightNav:"#FFD700",
  // contrastText: "#181D30"
};

const backgroundLight = {
  paper: "#fff",
  default: "#fff",
  grey: "#C5C5C5"
};
const backgroundDark = {
  // default: "#384354",
  paper: "#181818",
  default: "#121212"
};

const defaultColorDark = {
  main: "#121212",
  contrastText: "#fff"
};
const defaultColorLight = {
  main: "#FCFCFC",
  light: "#ffffff",
  dark: "#ececec",
  contrastText: "#3A415B"
};

const textColor = {
  primary: "#3B3C3E",
  secondary: "#8A8B8CFF"
};

const textColorDark = {
  primary: "#fff",
  secondary: "#8A8B8CFF"
};

const greenDark = {
  primary: "#394d48",
};
const greenLight = {
  primary: "#93aa83",
};
const lightBrown = {
  primary:"#acad94"
};
const black = {
  primary:"#1B1C1D"
};

export {
  primaryLight,
  secondaryLight,
  primaryDark,
  greenLight,
  secondaryDark,
  backgroundDark,
  backgroundLight,
  defaultColorLight,
  defaultColorDark,
  errorColor,
  textColor,
  greenDark,
  lightBrown,
  black,
  textColorDark
};
