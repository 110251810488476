import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import HeroTileLeft from "assets/images/Hero-Tile-Left-V1/Hero-Tile-Left-V1.jpg";
import ActionCard from "../../Cards/ActionCard/ActionCard";
import HomeCarousel from "../HomeCarousel";
import NoActivities from "./NoActivities";
import HeroTitleRight from "assets/images/Hero-Tile-Right-V1/Hero-Tile-right-V1-1.jpg";
import HeroTitleRight2 from "assets/images/Hero-Tile-Right-V1/Hero-Tile-right-V1-2.jpg";
import Social1 from 'assets/images/User-Column-Social/User-Column-Instagram.jpg'
import Social2 from 'assets/images/User-Column-Social/User-Column-Pinterest.jpg'
import Social3 from 'assets/images/User-Column-Social/User-Column-YouTube.jpg'
import Social4 from 'assets/images/User-Column-Social/User-Column-Blog.jpg'

const useStyles = makeStyles({
  wrapper: {
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    // maxWidth: 1280,
  },
});

const dummyNoActivities = [
  {
    title: "Get Inspired",
    text: "Check out truly sublime spaces.",
    action: "Follow us on Instagram",
    href: "https://www.instagram.com/schumacher1889/",
    image: Social1,
    target:'_blank'
  },
  {
    title: "Dive Into Our Moodboards",
    text: "See what we’re pinning right now.",
    action: "Visit us on Pinterest",
    href: "https://www.pinterest.com/schumacher1889/",
    image: Social2,
    target:'_blank'
  },
  {
    title: "Step Inside the Dreamiest Homes",
    text: "Our video tours give you an exclusive peek at designers’ own dwellings.",
    action: "Watch on Youtube",
    href: "https://www.youtube.com/channel/UCsViJuCYqtWXpDFeadQHKLA",
    image: Social3,
    target:'_blank'
  },
  {
    title: "Explore the World of Schumacher",
    text: "Get the inside scoop behind our new collections, design ideas and more.",
    action: "Read on our blog",
    href:
      "https://fredericmagazine.com/2021/07/painted-ceilings-decoraing-ideas/",
    image: Social4,
    target:'_blank'
  },
];

const cards = [
  {
    text: "Recommendations for You",
    action: "Shop Our Jacaranda Collection",
    actionPath: "/collections/jacaranda",
    image: HeroTitleRight,
  },
  {
    text: "Recommendations for You",
    action: "Explore Our Bohemia Collection",
    actionPath: "/collections/bohemia",
    image: HeroTitleRight2,
  },
];

function SignedInNonTradeWLoginNoActivities(props) {
  const { content, visibleProfile } = props;
  const classes = useStyles();
  return (
    <Grid
      className={classes.wrapper}
      container
      md={12}
      spacing={10}
      justify={"center"}
    >
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <ActionCard
          image={HeroTileLeft}
          title={"New Arrivals"}
          actionName={"Shop What's New"}
          actionPath={"/new-arrivals"}
        />
      </Grid>
      <Grid item className={classes.wrapper} lg={4} md={4} sm={6} xs={12}>
        <HomeCarousel cards={cards} />
      </Grid>
      <NoActivities noActivities={dummyNoActivities} content={content} visibleProfile={visibleProfile}/>
    </Grid>
  );
}

export default SignedInNonTradeWLoginNoActivities;
