import {Fragment} from "react";
import {Redirect, Route} from "react-router-dom";
import {getCredentials, getRoles} from "../utils";

const AppRoute = ({
                      path: Path,
                      component: Component,
                      layout: Layout,
                      submenu: SubMenu,
                      isSticky=false,
                      title,
                      back,
                      isAuthProtected,
                      roles,
                      ...rest
                  }) => (
    <Route
        {...rest}
        render={(props) => {
            if (isAuthProtected && !getCredentials()) {
                return (
                    <Redirect to={{pathname: "/", state: {from: props.location}}}/>
                );
            }

            // If role is required, make sure user is allowed
            const userRoles = getRoles();
            if (roles && roles.length !== 0) {
                const isAllowed = roles.some((r) => userRoles.includes(r));
                if (!isAllowed)
                    return (
                        <Redirect to={{pathname: "/", state: {from: props.location}}}/>
                    );
            }

            const Wrapper = Layout || Fragment;

            return (
                <Wrapper title={title} isSticky={isSticky}>
                    <Component {...props} />
                </Wrapper>
            );
        }}
    />
);

export default AppRoute;
