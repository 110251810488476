export const EntityType = {
  User: "User",
  Filter: "Filter",
  NavigationMenu: "NavigationMenu",
  Product: "Product",
  Collection: "Collection",
  Collaborator: "Collaborator",
  Account: "Account",
  Cart: "Cart",
  Address: "Address",
  PaymentMethod: "PaymentMethod",
  State: "State",
  Country: "Country",
  ShippingMethod: "ShippingMethod",
  Page: "Page",
  SalesTerritory: 'SalesTerritory'
};
