import {Box, Container, Grid, Typography} from "@material-ui/core";
import {Formik} from "formik";
import FormFields from "../../../components/FormFields";
import { resetPwdForm} from "../../../constants/forms";
import Button from "../../../components/Button/Button";
import {connect} from "react-redux";
import {apiError, loginUser, resetPassword, resetPasswordSuccess} from "../../../store/auth/account/actions";
import * as Yup from "yup";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const ResetPassword = (props) => {

    const {
        error,
        loading,
        resetPassword,
        hasResetPassword,
        loginUser
    } = props;

    const [password,setPassword] = useState('');

    const resetValidation = Yup.object().shape({
        password: Yup.string().min(8,'AT LEAST 8 CHARACTERS').matches(/\W+/, "AT LEAST 1 SPECIAL CHARACTER").required('This field is required'),
        confirmpassword: Yup.string().required('This field is required').when('password', {
            is: val => (!!(val && val.length > 0)),
            then: Yup.string().oneOf(
                [Yup.ref('password')],
                'Must match the inputs above'
            )
        })})

    let token = useQuery().get('token')
    let email = useQuery().get('email')

    useEffect(()=>{
        if (hasResetPassword) {
            loginUser({email,password})
        }
        // eslint-disable-next-line
    },[hasResetPassword])

    return (
      <>
        <Container>
            <Box mt={6} />
            <Typography variant="h4" style={{ fontFamily:'Chronicle' }}>
              Reset Password
            </Typography>

            {!hasResetPassword&&<>
                <Box maxWidth={440} fontSize={18} fontFamily={'Chronicle'}>
                <p>
                    Your new password must be different from previous used password and must contain at least 8 characters with 1 special character.                </p>
            </Box>
                <Box maxWidth={440} mt={4}>
                <Formik
                    initialValues={{}}
                    onSubmit={(values) => {
                        resetPassword({password: values.password, token});
                        setPassword(values.password);
                    }}
                    validationSchema={resetValidation}
                >
                    {(formik,isValid) => (
                        <FormFields form={resetPwdForm} busy={false}>
                            <Grid item xs={12}>
                                <Box mt={2} />
                                <Button
                                    fullWidth
                                    disabled={loading}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"contained"}
                                    type={"submit"}
                                >
                                    RESET PASSWORD
                                </Button>
                            </Grid>
                            {error&&<Grid item xs={12}>
                                <Box style={{textTransform:'uppercase', color:'red'}} pl={1}> {error} </Box>
                            </Grid>}
                        </FormFields>
                    )}
                </Formik>
            </Box>
            </>}

            {hasResetPassword&&<>

                <Box maxWidth={440} fontSize={18} fontFamily={'Chronicle'}>
                    <p>You’ve created your new password successfully! </p>
                </Box>

                <Box mt={2} />

                <Box maxWidth={440}>
                    <Button
                        fullWidth
                        size={"large"}
                        color={"primary"}
                        variant={"contained"}
                        href={'/'}
                    >
                        START EXPLORING
                    </Button>
                </Box>
            </>}

            <Box mt={2} />

            <Grid>
            </Grid>
        </Container>
      </>
  );
};
const mapStateToProps = (state) => {
    const { error, loading, isAuth, hasResetPassword  } = state.Auth;
    return { error, loading, isAuth, hasResetPassword };
};

export default connect(mapStateToProps, { resetPassword, apiError, loginUser, resetPasswordSuccess })(ResetPassword);
