import {all, call, fork, put, takeEvery} from "redux-saga/effects";
// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  VALIDATE_USER,
} from "./actionTypes";
import {
  apiError,
  loginSuccess,
  forgotPasswordSuccess,
  resetPasswordSuccess,
  validateUserSuccess,
  validateUserFailure, logoutUserSuccess
} from "./actions";
//Include Both Helper File with needed methods
import {postForgetPwd, postLogin, postResetPwd} from "./api";
import axios from "axios";
import {fetchData, getToken} from "../../../utils";
import {ApiEndpoint} from "../../@core/endpoint";
import {EntityType} from "../../@core/entityType";

function* loginUser({ payload: { user } }) {
  try {
    const { email, password, onSuccess} = user;
    const response = yield call(postLogin, { email, password });
    localStorage.setItem("authUser", JSON.stringify(response));
    axios.defaults.headers.common = { Authorization: getToken() };
    const profile = yield call(fetchData, ApiEndpoint[EntityType.Account] + "/profile");
    // const userDetails = yield call(fetchData, ApiEndpoint[EntityType.Account]);
    onSuccess && onSuccess();
    yield put(loginSuccess(profile));
    // history.push("/");
  } catch (error) {
    yield put(apiError(error.message));
  }
}
function* validateUser() {
  try {
    const url = ApiEndpoint[EntityType.Account] + "/profile"
    const profile = yield call(fetchData, url);
    yield put(loginSuccess(profile));
    yield put(validateUserSuccess());
    // history.push("/");
  } catch {
    yield put(validateUserFailure());
    localStorage.removeItem("authUser")
    sessionStorage.removeItem("authUser");
  }
}

function* logoutUser() {
  try {
    localStorage.removeItem("authUser");
    sessionStorage.removeItem("authUser");
    axios.defaults.headers.common = {
      Authorization: getToken()
    };
    yield put(logoutUserSuccess())
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function* forgotPassword({ payload: { email } }) {
  try {
    //eslint-disable-next-line
    const response = yield call(postForgetPwd, email);
    yield put(forgotPasswordSuccess({}));
    // history.push("/");
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function* resetPassword({ payload: { password,token } }) {
  try {
    // eslint-disable-next-line
    const response = yield call(postResetPwd, {password, token});
    yield put(resetPasswordSuccess(true));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export function* watchUserValidation() {
  yield takeEvery(VALIDATE_USER, validateUser);
}

function* authSaga() {
  yield all([
      fork(watchUserLogin),
      fork(watchUserLogout),
      fork(watchForgotPassword),
      fork(watchResetPassword),
      fork(watchUserValidation)
  ]);
}

export default authSaga;
