import React from "react";
import { CardMedia, Grid } from "@material-ui/core";
import ActionButton from "../Core/ActionButton";
import Text from "../Text/Text";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  border: {
    // borderRadius: theme.borderRadius,
  },
}));

function ActivityCard({ activity }) {
  const classes = useStyles()
  return (
    <Grid
      container
      alignItems={"center"}
      justify={"flex-start"}
      alignContent={"center"}
      direction={"row"}
      spacing={1}
    >
      <Grid item style={{ maxWidth: 144 }}>
        <CardMedia image={activity.image} className={classes.border} style={{ height: 128, width: 128 }} />
      </Grid>
      <Grid
        item
        container
        direction={"column"}
        alignItems={"flex-start"}
        justify={"center"}
        style={{ flex: 1 }}
      >
        <Grid item style={{ marginBottom: 4 }}>
          <Text bold style={{ fontSize: 16 }}>
            {activity.title}
          </Text>
        </Grid>
        <Grid item>
          <Text variant={"body1"} style={{ fontSize: 15, whiteSpace: 'pre-line' }}>
            {activity.text}
          </Text>
        </Grid>
        {activity.action && (
          <Grid item>
            <ActionButton
              title={activity.action}
              href={activity.href}
              actionPath={activity.actionPath}
              target={activity.target}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default ActivityCard;
