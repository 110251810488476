import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import ActionButton from "../../Core/ActionButton";
import SmallProductCard from "../SmallProductCard";
import Image1 from "assets/images/User-Column-Rep&Showroom/User-Column-Rep.jpg";
import Image2 from "assets/images/User-Column-Rep&Showroom/User-Column-Showroom.jpg";
import SectionTitle from "../SectionTitle";
import ActivityCard from "../ActivityCard";
import GreetingText from "../GreetingText";

const useStyles = makeStyles({
  wrapper: {
    height: "100%",
  },
  sectionTitle: {
    fontFamily: "Chronicle",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "20px",
    lineHeight: "140%",
    letterSpacing: "0.0125em",
    color: "#0D131D",
  },
});

const activities = [
  {
    title: "Meet Your Sales Rep",
    text: "Representative’s Name \n 123-456-7890 \n name@fsco.com",
    image: Image1,
  },
  {
    title: "Find Your Showroom",
    text: "Showroom Location \n 123-456-7890 \n name@fsco.com",
    image: Image2,
  },
];

const NoActivities = ({ content, visibleProfile }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.wrapper}
      lg={4}
      md={4}
      sm={6}
      xs={12}
      item
    >
      <Grid item>
        <GreetingText
          greeting={"Welcome,"}
          user={visibleProfile}
        />
      </Grid>
      <Grid item container spacing={2}>
        {activities.map((dummy, i) => {
          return (
            <Grid key={i} item>
              <ActivityCard activity={dummy} />
            </Grid>
          );
        })}
      </Grid>
      <Box mt={2} mb={4}>
        <ActionButton title={"We’re always here to help"} />
      </Box>
      <Box width={'100%'}>
      <SectionTitle title={"Experience the real thing."} />
      </Box>
      <Grid container spacing={2}>
        {content?.relevantProducts?.map((product, index) => (
          <Grid item md={4} key={index}>
            <SmallProductCard product={product} showBasket />
          </Grid>
        ))}
      </Grid>
      <Box mt={2}>
      <ActionButton title={"order memos"} actionPath={"/products/more"}/>
      </Box>
    </Grid>
  );
};

export default NoActivities;
