import React, { Fragment, useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Heading from "../Common/Heading";
import { Box, Grid, TextField } from "@material-ui/core";
import Text from "../../../components/Text/Text";
import { createMap } from "reactcoregk/utils";
import { combineStrings } from "../../../utils";
import { useDispatch } from "react-redux";
import {
  setOrderNotes,
  setOrderPoNumber,
  setOrderProjectName,
} from "../../../store/cart/actions";
import ItemList from "./ItemList";
import { paymentMethodOption as paymentOption } from "constants/enums";

const useStyles = makeStyles({
  root: {},
  text: {
    marginTop: 2,
    marginBottom: 2,
  },
});

function formatCard(card) {
  return card?.substring(card.length - 4, card.length);
}

function Review(props) {
  const classes = useStyles();
  const {
    order,
    addresses,
    paymentMethods,
    memos,
    products,
    handleUpdateItem,
    processingOrder,
    processingCart,
    memoDeliveryOptions,
    productDeliveryOptions,
  } = props;
  const dispatch = useDispatch();
  const {
    shippingAddressId,
    paymentMethodId,
    shippingAddress,
    notes,
    poNumber,
    projectName,
    paymentMethodOption,
  } = order;

  const addressMap = useMemo(() => createMap(addresses), [addresses]);
  const paymentMethodMap = useMemo(() => createMap(paymentMethods), [
    paymentMethods,
  ]);
  const address = addressMap.get(shippingAddressId) || shippingAddress;
  const payment = paymentMethodMap.get(paymentMethodId);

  const handleChangeNotes = useCallback(
    (e) => {
      dispatch(setOrderNotes(e.target.value));
    },
    [dispatch]
  );

  const handleChangeProject = useCallback(
    (e) => {
      dispatch(setOrderProjectName(e.target.value));
    },
    [dispatch]
  );

  const handleChangePoNumber = useCallback(
    (e) => {
      dispatch(setOrderPoNumber(e.target.value));
    },
    [dispatch]
  );

  return (
    <div>
      <Heading title={"Order Details"} />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <Text bold>Ship to</Text>
          <Box mt={2} />
          <Text className={classes.text} bold>
            {combineStrings(" ", address?.name, address?.surname)}
          </Text>
          {address?.businessName && (
            <Text className={classes.text} bold>
              {address?.businessName}
            </Text>
          )}
          <Text className={classes.text}>{address?.addressLine1}</Text>
          <Text className={classes.text}>
            {combineStrings(
              ", ",
              address?.city,
              address?.state,
              address?.country
            )}
          </Text>
          <Box mt={3} />
          <Text bold>Payment Method</Text>
          <Box mt={2} />
          {paymentMethodOption === paymentOption.PAY_NOW ? (
            <Fragment>
              <Text className={classes.text}>{payment?.holderName}</Text>
              <Text className={classes.text}>
                Card ending in {formatCard(payment?.maskedCardNumber)}
              </Text>
            </Fragment>
          ) : (
            <Fragment>
              <Text className={classes.text}>Pay By Terms</Text>
            </Fragment>
          )}
        </Grid>
        <Grid item xs={12} sm={8}>
          <Text bold>Additional Notes</Text>
          <Box mt={1} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label={"Order Notes"}
                variant={"outlined"}
                color={"primary"}
                multiline
                rows={10}
                value={notes}
                onChange={handleChangeNotes}
              />
            </Grid>
            <Grid
              item
              xs={6}
              container
              direction={"column"}
              justify={"flex-start"}
              spacing={2}
            >
              <Grid item>
                <TextField
                  label={"PO No."}
                  margin={"dense"}
                  variant={"outlined"}
                  color={"primary"}
                  value={poNumber}
                  onChange={handleChangePoNumber}
                />
              </Grid>
              <Grid item>
                <TextField
                  margin={"dense"}
                  label={"Project Name"}
                  variant={"outlined"}
                  color={"primary"}
                  value={projectName}
                  onChange={handleChangeProject}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box mt={5} />
      <ItemList
        handleUpdateItem={handleUpdateItem}
        memos={memos}
        busy={processingOrder || processingCart}
        products={products}
        order={order}
        memoDeliveryOptions={memoDeliveryOptions}
        productDeliveryOptions={productDeliveryOptions}
      />
    </div>
  );
}

export default Review;
