import Popper from "@material-ui/core/Popper";
import {
  Box,
  ClickAwayListener,
  Container,
  Grow,
  Paper,
} from "@material-ui/core";
import PropTypes from "prop-types";

function Popover(props) {
  const { open, anchorEl, handleClose , autoDismiss, placement, containerStyles} = props;

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      transition
      placement={placement}
      style={{ zIndex: 3 }}
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <Container
                style={{
                  maxHeight: "100vh",
                  overflow: "auto",
                  position: "relative",
                  ...containerStyles
                }}
                onClick={() => {
                  if (autoDismiss) {
                    handleClose();
                  }
                }}
              >
                <Box py={2}>{props.children}</Box>
              </Container>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

export default Popover;

Popover.propTypes = {
  children: PropTypes.element,
  handleClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.object.isRequired,
  autoDismiss: PropTypes.bool,
  placement: PropTypes.string,
  containerStyles: PropTypes.object
};

Popover.defaultProps = {
  clickAway: true,
  autoDismiss: false,
  placement: "bottom-end"
};
