import {Box, Container, Grid, Typography} from "@material-ui/core";
import {Form, Formik} from "formik";
import {connect} from "react-redux";
import {apiError, loginUser, resetPassword, resetPasswordSuccess} from "store/auth/account/actions";
import * as Yup from "yup";
import {Link} from "react-router-dom";
import {useState} from "react";
import Button from "@material-ui/core/Button";
import {API_URL} from "config";
import axios from "axios";
import FormikTextField from "components/Core/FormikFields/FormikTextField";
import FormikPasswordField from "components/Core/FormikFields/FormikPasswordField";
import FormikSelect from "components/Core/FormikFields/FormikSelect";
import {userCategory, userCategorySignUp} from "constants/enums";
import FormikCheckbox from "components/Core/FormikFields/FormikCheckbox";
import {makeStyles} from "@material-ui/core/styles";
import {openExternalProps} from "utils";

const signUpValidation = Yup.object().shape({
  firstName: Yup.string().required('This field is required'),
  lastName: Yup.string().required('This field is required'),
  password: Yup.string().min(8,'AT LEAST 8 CHARACTERS').matches(/\W+/, "AT LEAST 1 SPECIAL CHARACTER").required('This field is required'),
  email: Yup.string().email('Invalid email address').required('This field is required'),
  category: Yup.string().required('This field is required')
})

const useStyles = makeStyles((theme) => ({
  checkBoxStyle: {
    '& .MuiFormControlLabel-label': {
      fontSize: 15
    }
  }
}))

const SignUp = (props) => {

  const {
    loginUser,
    history
  } = props;

  const [isBusy, setIsBusy] = useState(false);
  const [error, setError] = useState(false);
  const classes = useStyles();

  const onSubmit = (values) => {

    const url = API_URL + "/account/signup";

    const {firstName, lastName, email, password, category} = values

    let data = {
      firstName,
      lastName,
      email,
      password,
      category
    }

    setError(false);
    setIsBusy(true);

    axios.post(url, data)
      .then((response) => {
        if (response.status === 200) {
          const onSuccess = () => {
            setIsBusy(false);
            if (category===userCategory.DESIGN_PROFESSIONAL) {
              history.push(`/join-trade-program`)
            } else {
              history.push(`/`)
            }
          }
          loginUser({email, password, onSuccess})
        } else {
          setIsBusy(false);
        }
      })
      .catch((err) => {
        setError(err.response.data.message);
        setIsBusy(false);
        throw err;
      });

  }

  return (
    <>
      <Container>
        <Box mt={6}/>

        <Grid item container>

          <Grid item md={4}>
            <Box style={{fontFamily: 'Chronicle', fontSize: 56}}>
              Sign Up
            </Box>
            <Box pt={5}>
              <Typography variant='h5' fontSize={20}>
                Create a web login to save your browsing history and favorite items. You'll also enjoy a more
                personalized experience throughout our website.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={1}> </Grid>

          <Grid item md={7}>
            <Box mt={15} />
            <Formik initialValues={{
              category: "",
              firstName: "",
              lastName: "",
              tradeAccNo: "",
              zipCode: "",
              email: "",
              password: "",
              tos: false
            }}
                    validationSchema={signUpValidation}
                    onSubmit={onSubmit}>
              {({values, isValid}) => (
                <Form noValidate>
                  <Grid
                    container
                    direction={"row"}
                    spacing={4}
                    justify={"flex-end"}
                    alignItems={"flex-start"}
                  >

                    <Grid item sm={6} xs={12}>
                      <FormikTextField
                        variant={"outlined"}
                        margin="none"
                        size={"small"}
                        fullWidth
                        type={"text"}
                        id={"firstName"}
                        name={"firstName"}
                        label={"First Name"}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormikTextField
                        variant={"outlined"}
                        margin="none"
                        size={"small"}
                        fullWidth
                        type={"text"}
                        id={"lastName"}
                        name={"lastName"}
                        label={"Last Name"}
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <FormikTextField
                        variant={"outlined"}
                        margin="none"
                        size={"small"}
                        fullWidth
                        type={"text"}
                        id={"email"}
                        name={"email"}
                        label={"Email"}
                        autoComplete={"off"}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormikPasswordField
                        variant={"outlined"}
                        margin="none"
                        size={"small"}
                        fullWidth
                        type={"password"}
                        id={"password"}
                        name={"password"}
                        label={"Password"}
                        autoComplete={"off"}
                        inputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormikSelect
                        variant={'outlined'}
                        margin="none"
                        fullWidth
                        id={'category'}
                        name={'category'}
                        label={'Are you an interior design professional?'}
                        options={userCategorySignUp}
                      />
                    </Grid>

                    <Grid item container>

                      <Box className={classes.checkBoxStyle}>
                        <FormikCheckbox
                          label={<>By clicking ‘Create Login’ you agree to our <Link to={"/pages/privacy"} {...openExternalProps} >Privacy Policy</Link> and <Link to={'/pages/terms'} {...openExternalProps}>Terms and Conditions.</Link> Opt out
                            of email marketing any time by clicking Unsubscribe at the bottom of any of our emails.</>}
                          id={'tos'}
                          name={'tos'}
                        />
                      </Box>


                      {/*<label style={{display: 'flex'}}>*/}
                      {/*  <Grid item>*/}
                      {/*    <Field type="checkbox" name="tos"/>*/}
                      {/*  </Grid>*/}
                      {/*  <Grid item xs>*/}
                      {/*    <Box display='flex' pl={1} mt={0.1} style={{userSelect: 'none', cursor: 'pointer'}}>*/}
                      {/*      By clicking ‘Create Login’ you agree to our Privacy Policy and Terms and Conditions. Opt out*/}
                      {/*      of email marketing any time by clicking Unsubscribe at the bottom of any of our emails.*/}
                      {/*    </Box>*/}
                      {/*  </Grid>*/}
                      {/*</label>*/}
                    </Grid>

                    <Grid item xs={12} sm={12} md={values.applyForTradeAccount ? 9 : 6}>
                      <Button
                        fullWidth
                        variant={"contained"}
                        color={"primary"}
                        type={"submit"}
                        size={"large"}
                        disabled={!values.tos || isBusy || !isValid}
                      >
                        CREATE LOGIN
                      </Button>
                    </Grid>

                    {error && <Grid item xs={12}>
                      <Box style={{textTransform: 'uppercase', color: 'red'}} pl={1}> {error} </Box>
                    </Grid>}

                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>

        </Grid>

        <Box mt={2}/>

        <Grid>
        </Grid>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => {
  const {error, loading, isAuth, hasResetPassword} = state.Auth;
  return {error, loading, isAuth, hasResetPassword};
};

export default connect(mapStateToProps, {resetPassword, apiError, loginUser, resetPasswordSuccess})(SignUp);
