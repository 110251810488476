import Dialog from '../Dialog/Dialog'
import {
  Box,
  Link,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@material-ui/core'
import {useDropzone} from "react-dropzone";
import {getAxiosConfig} from "../../utils";
import RootRef from '@material-ui/core/RootRef';
import {useState} from "react";
import List from "@material-ui/core/List";
import {API_URL} from "../../config";
import axios from "axios";

const ProofOfBusinessDialog = ({open, setOpen, setFiles, files, setFieldValue}) => {

  const [loadedFiles, setLoadedFiles] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  const onDrop = async (acceptedFiles) => {
    setLoadedFiles([...loadedFiles,...acceptedFiles])
  }

  const uploadSelected = () => {

    const url = `${API_URL}/files/upload`

    const formData = new FormData();

    loadedFiles.forEach((file)=>formData.append('files', file))

    setIsBusy(true)

    axios.post(url,formData,getAxiosConfig(true))
      .then((response) => {
        if (response.status === 200) {
          setFiles([...files, ...response.data])
          setFieldValue('uploadedFiles',true)
        }
        setIsBusy(false);
        handleClose();
      })
      .catch((err) => {
        // Silent error
        setIsBusy(false);
        console.log(err)
        throw err;
      });

  }

  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const {ref, ...rootProps} = getRootProps()

  const handleClose = () => {
    setOpen(false)
    setLoadedFiles([])
  }
  return <>
    <Dialog
      onClose={handleClose}
      open={open}
      title={'Proof of Business'}
      maxWidth="sm"
      primaryActionTitle={'UPLOAD'}
      secondaryActionTitle={'CANCEL'}
      primaryAction={()=>{uploadSelected()}}
      secondaryAction={()=>{handleClose()}}
      inProgress={isBusy}
      disableBackdropClick={isBusy}
      disableEscapeKeyDown={isBusy}
    >
      <Typography variant='h6' style={{ fontWeight:800 }}>
        Qualifying Document
      </Typography>

      <Box>
        <Box mt={2} mb={2} style={{border: '1px solid #000', borderRadius: 16, minHeight: 160}}>

          {loadedFiles.length!==0&&<>

            <List dense={true}>
              {loadedFiles.map((file,i)=>{
                return <ListItem>
                  <ListItemText style={{paddingRight: 16}}
                    primary={file.name}
                  />
                  <ListItemSecondaryAction>
                    <Link to={"#"} style={{cursor:'pointer'}} onClick={()=>{
                      let newFiles = [...loadedFiles];
                      newFiles.splice(i, 1);
                      setLoadedFiles(newFiles);
                    }}> <b>Remove</b> </Link>
                  </ListItemSecondaryAction>
                </ListItem>
              })}
            </List>

          </>}

          <RootRef rootRef={ref}>
            <Box m={3} textAlign='center' lineHeight={2} {...rootProps}>
              <input name='image' type='file' {...getInputProps()} accept='.png, .jpg, .jpeg, .tiff, .pdf'/>
              {loadedFiles.length===0&&<>Drag and drop images (jpeg, tiff, png files) or digital PDF file(s) into this box or <br/></>}
              <span style={{fontWeight:'bold',textDecoration:'underline', cursor:'pointer'}}>Browse Files</span>
            </Box>
          </RootRef>

        </Box>
        <Box fontSize={12} p={1} pt={0} fontWeight='600'>TOTAL SIZE OF UPLOADING FILES CANNOT EXCEED 10MB</Box>
      </Box>

    </Dialog>
  </>
}

export default ProofOfBusinessDialog
