import { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";
import { Paging } from "reactcoregk/models";
import ProductCardWithHooks from "components/Cards/ProductCard/ProductCartWithHooks";
import { fetchData } from "../../utils";
import ProductCard from "../../components/Cards/ProductCard/ProductCard";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ProductDialog from "../Catalog/ProductDialog";
import PropTypes from "prop-types";
import { useCurrentCart } from "../../hooks/cart";
import { useFavorites } from "../../hooks/favorites";
import {PuffLoader} from "react-spinners";

const useStyles = makeStyles({
  root: {},
  menuButton: {
    fontWeight: "bold",
    fontSize: 12,
  },
});

function BaseProductGrid(props) {
  const classes = useStyles();
  const {
    context,
    productsUrl,
    breadcrumbs,
    initialState,
    pageableFormatter,
    params,
    collectionCategories
  } = props;

  const [openPreview, setOpenPreview] = useState(false);
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(initialState);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const { isAuth } = context.Auth;
  const [page, setPage] = useState(0)
  const [favoriteProduct, setFavoriteProduct] = useState({});
  useFavorites(favoriteProduct, products, setFavoriteProduct);

  const pageableObject = useMemo(() => {
    if (pageableFormatter) {
      return pageableFormatter(results);
    }
    return results;
  }, [pageableFormatter, results]);

  const { handleAddMemo, cart } = useCurrentCart();
  const handlePreview = useCallback((product) => {
    setSelectedProduct(product);
    setOpenPreview(true);
  }, []);

  const handleNext = useCallback(async () => {
    try {
      setLoading(true)
      setPage(pageableObject.number+1)
      const q = params
        ? `${params}&page=${pageableObject.number + 1}`
        : `?page=${pageableObject.number + 1}`;
      const url = `${productsUrl}${q}`;
      const response = await fetchData(url);
      setResults(response);
      setProducts((prevState) => [
        ...prevState,
        ...pageableFormatter(response).content,
      ]);
    } catch (ex) {}
    setLoading(false)
  }, [params, pageableObject.number, productsUrl, pageableFormatter]);

  useEffect(() => {
    (async () => {
      setResults(initialState);
      setProducts([]);
      try {
        setLoading(true);
        setPage(0)
        const url = `${productsUrl}${params || ""}`;
        const response = await fetchData(url);
        setResults(response);
        setProducts(pageableFormatter(response).content);
        collectionCategories(response.categories)
      } catch (e) {}
      setLoading(false);
    })();
    //eslint-disable-next-line
  }, [initialState, pageableFormatter, params, productsUrl, isAuth]);

  function renderGridLoader() {
    return (
      <Grid container spacing={4}>
        {[...Array(8)].map((v, i) => (
          <Grid key={i} item md={3} sm={6} xs={12}>
            <ProductCard isLoading product={{}} />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Box>
      <Container>
        <Box mt={4} />
        <Breadcrumbs path={breadcrumbs} />
        <Box mt={2} />
        <Toolbar>
          <Typography variant="body1">
            {pageableObject.totalElements} Results
          </Typography>
          <div style={{ margin: "auto" }} />
          <Button className={classes.menuButton}>
            Sort by <ExpandMoreIcon />
          </Button>
        </Toolbar>
        {/*<InfiniteScroll*/}
        {/*  dataLength={products.length} //This is important field to render the next data*/}
        {/*  next={handleNext}*/}
        {/*  scrollThreshold={0.6}*/}
        {/*  hasMore={pageableObject.number + 1 < pageableObject.totalPages}*/}
        {/*  style={{ width: "100%", overflow: "hidden" }}*/}
        {/*  loader={renderGridLoader()}*/}
        {/*>*/}
          {loading && page === 0?(
            renderGridLoader()
          ) : (
            <Grid container spacing={4}>
              {products.map((product) => (
                <Grid item md={3} sm={6} xs={12}>
                  <ProductCardWithHooks
                    product={product}
                    handlePreview={handlePreview}
                    handleMemo={handleAddMemo}
                    activeCart={cart}
                    toggleFavorite={() => setFavoriteProduct(product)}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        {(pageableObject.number + 1 < pageableObject.totalPages) &&
        <Grid container alignItems={'center'} alignContent={'center'} justify={'center'}
              style={{marginTop: 40}}>
          <Grid item>
            <Button variant={'contained'} color={'secondary'}
                    style={{width:250}}
                    disabled={(pageableObject.number + 1 > pageableObject.totalPages) || loading} onClick={handleNext}
                    size={'large'}>{loading ? <>Loading <PuffLoader size={10} css={"margin-left:8px;"} loading={true}/></> : 'Load More...'}</Button>
          </Grid>
        </Grid>
        }
        {/*</InfiniteScroll>*/}
      </Container>
      <ProductDialog
        open={openPreview}
        handleClose={() => setOpenPreview(false)}
        itemNumber={selectedProduct?.itemNumber}
        products={products}
      />
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      Cart: state.Cart,
      Auth: state.Auth,
    },
  };
};

export default connect(mapStateToProps, {})(BaseProductGrid);

BaseProductGrid.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  productsUrl: PropTypes.string.isRequired,
  initialState: PropTypes.object,
  collectionCategories:PropTypes.array,
  pageableFormatter: PropTypes.func,
  params: PropTypes.string,
};

BaseProductGrid.defaultProps = {
  initialState: new Paging(),
  pageableFormatter: (res) => res,
};
