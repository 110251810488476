import AccountProfileLayout from "../components/AccountProfileLayout";

const UsersProfile = (props) =>{
    return <AccountProfileLayout
        title={'UsersProfile'}
        {...props}>
        Users Profile
    </AccountProfileLayout>
}

export default UsersProfile