import Button from "../Button/Button";
import Text from "../Text/Text";
import { Box, Grid } from "@material-ui/core";
import { loginForm } from "../../constants/forms";
import { Formik } from "formik";
import FormFields from "../FormFields";
import { connect } from "react-redux";
import { apiError, loginUser } from "../../store/auth/account/actions";
import {useEffect, useState} from "react";
import * as Yup from 'yup';
import FormikCheckbox from "components/Core/FormikFields/FormikCheckbox";
import {Link} from "react-router-dom";

function SignInForm(props) {
  const {
    apiError,
    loginUser,
    error,
    loading,
    isAuth,
    setOpenSignIn,
    setOpenForgot
  } = props;

  const [initialValues, setInitialValues] = useState({email:'',password:'', rememberMe:true})

  useEffect(()=>{
    const rememberMe = localStorage.getItem('rememberme')
    if (rememberMe) {
      setInitialValues({...JSON.parse(rememberMe)})
    }
  },[])

  const signInValidation = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required')
  });

  useEffect(() => {
    apiError("");
  }, [apiError]);

  useEffect(() => {
    if (isAuth) {
      setOpenSignIn(false);
    }
  }, [isAuth, setOpenSignIn]);

  // useAlert(error, "error")

  return (
    <Box>
      <Text variant={"h5"}>Sign In</Text>
      <Box mt={1} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box fontSize={14} pt={1} pb={1}>
            Don’t have a login with us? <Link to={"/signup"} style={{fontWeight:'bold'}} onClick={()=>setOpenSignIn(false)}>SIGN UP.</Link>
          </Box>
        </Grid>
      </Grid>
      <Box style={{ width: 340 }} mt={1}>
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validateOnBlur={false}
            onSubmit={(values) => {
              const payLoad = {
                email: values.email,
                password: values.password
              }

              const onSuccess = () => {
                if (values.rememberMe) {
                  localStorage.setItem('rememberme',JSON.stringify({email: values.email, password: values.password, rememberMe: values.rememberMe}))
                } else {
                  localStorage.removeItem('rememberme')
                }
              }

              loginUser({...payLoad, onSuccess})

            }}
            validationSchema={signInValidation}
        >
          {(formik) => (
            <FormFields form={loginForm} busy={false} validateOnBlur={false}>
              {error&&<Grid item xs={12}>
                <Box mt={-1.5} style={{textTransform:'uppercase', color:'#DF0006', fontWeight: 'bold'}} pl={1.5}> {error} </Box>
              </Grid>}
              <Grid item xs={12}>
                <Button
                  fullWidth
                  disabled={loading}
                  size={"large"}
                  color={"secondary"}
                  variant={"contained"}
                  type={"submit"}
                >
                  Sign in
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Box pl={1}>
                  <FormikCheckbox
                    label={'Remember me'}
                    id={'rememberMe'}
                    name={'rememberMe'}
                  />
                </Box>
              </Grid>
            </FormFields>
          )}
        </Formik>
      </Box>
      <Box p={1} pt={2} fontSize={14}>Forgot password? Reset <Link href="#" style={{color:"black", fontWeight:"bold"}} onClick={()=>{ setOpenSignIn(false); setOpenForgot(true);}}>HERE.</Link></Box>
    </Box>
  );
}

const mapStateToProps = (state) => {
  const { error, loading, isAuth } = state.Auth;
  return { error, loading, isAuth };
};

export default connect(mapStateToProps, { loginUser, apiError })(SignInForm);
