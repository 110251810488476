import { withRouter } from 'react-router-dom';
import {Box} from '@material-ui/core'

const Step = ({ noOfSteps, currentStep, height=5, selectedColor='#FFD700', backgroundColor='#F0F0F0', names }) => {
  return <Box display='flex'>
      {Array.from(Array(noOfSteps).keys()).map((s,i)=>{

        let style = {
          height: height,
          width: '100%',
          backgroundColor: i===currentStep-1?selectedColor:backgroundColor,
          marginRight: i+1===noOfSteps?0:4
        }

        if (i===0) {
          style = {
            ...style,
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4
          }
        } else if (i===noOfSteps-1) {
          style = {
            ...style,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
          }
        }

        return <>
          <Box display='flex' flexDirection='column' style={{marginRight: i+1===noOfSteps?0:4}}>
            <Box display='flex' textAlign={'center'} height={'100%'} p={1} style={{textTransform:'uppercase', fontWeight:(i===currentStep-1)?'bold':'inherit'}}>{names?names[i]:'&nbsp;'}</Box>
            <Box style={style} key={i}>&nbsp;</Box>
          </Box>
        </>


      })

      }
    </Box>
}

export default withRouter(Step);
