import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useFetch } from "reactcoregk/hooks";
import { ApiEndpoint } from "../../../store/@core/endpoint";
import { EntityType } from "../../../store/@core/entityType";
import { Box, Container, Divider, Grid } from "@material-ui/core";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import CollaboratorCard from "../../../components/CollaboratorCard/CollaboratorCard";
import { renderCollaboratorsLoader } from "../index";
import Text from "../../../components/Text/Text";
import { Skeleton } from "@material-ui/lab";
import { fetchData } from "../../../utils";
import CollaboratorProducts from "./CollaboratorProducts";
import {connect} from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {},
  image: {
    width: "100%",
    height: "auto",
    borderRadius: theme.borderRadius,
  },
  largeTitle: {
    fontSize: 54,
    textTransform: "uppercase",
  },
  quote: {
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: 20,
  },
  quoteWrap: {
    color: theme.palette.secondary.main,
  },
  lifeStyleImage: {
    width: "100%",
    maxWidth: 720,
    alignSelf: "center",
    margin: "auto",
    height: "auto",
  },
  photoBy: {
    textAlign: "right",
    fontStyle: "italic",
    fontSize: 14,
  },
}));

const schema = {
  id: 0,
  slug: "",
  firstName: "",
  lastName: "",
  getToKnowName: "",
  biography: "",
  quote: "",
  portraitPhotoUrl: "",
  portraitPhotoBy: "",
  lifestylePhotoUrl: "",
  lifestylePhotoBy: "",
  collection: {
    id: 0,
    slug: "",
    name: "",
    lookBookUrl: "",
  },
  products: [],
};

function CollaboratorDetails(props) {
  const classes = useStyles();
  const slug = props.match.params.slug;
  const listUrl = ApiEndpoint[EntityType.Collaborator];
  const [collaborator, setCollaborator] = useState(schema);
  const [loading, setLoading] = useState(false);
  const [loadingList, collaborators] = useFetch(listUrl, {}, []);
  const { isAuth } = props

  useEffect(() => {
    (async () => {
      setLoading(true);
      const url = `${ApiEndpoint[EntityType.Collaborator]}/${slug}`;
      try {
        const response = await fetchData(url);
        setCollaborator(response);
      } catch (e) {}
      setLoading(false);
    })();
  }, [slug, isAuth]);

  const {
    portraitPhotoUrl,
    firstName,
    lastName,
    getToKnowName,
    quote,
    biography,
    lifestylePhotoUrl,
    portraitPhotoBy,
    lifestylePhotoBy,
  } = collaborator;

  const fullName = `${firstName} ${lastName}`;

  return (
    <Box>
      <Container>
        <Box mt={4} />
        <Breadcrumbs
          path={[
            { name: "COLLABORATORS", path: "/collaborators" },
            { name: slug.split('-').join(' ').toUpperCase(), path: "#" },
          ]}
        />
        <Box mt={2} />
        <Grid container spacing={4}>
          <Grid item sm={4} xs={12}>
            {loading ? (
              <Skeleton
                variant={"rect"}
                className={classes.image}
                height={400}
              />
            ) : (
              <img
                className={classes.image}
                alt={getToKnowName}
                src={portraitPhotoUrl}
              />
            )}
          </Grid>
          <Grid item sm={8} xs={12}>
            {loading ? (
              <Skeleton variant={"rect"} className={classes.largeTitle} />
            ) : (
              <Text
                color={"secondary"}
                variant={"h1"}
                className={classes.largeTitle}
              >
                {fullName}
              </Text>
            )}
            <Box mt={3} />
            {loading ? (
              <Skeleton variant={"text"} className={classes.quote} />
            ) : (
              <Text color={"textSecondary"} className={classes.quote}>
                <span className={classes.quoteWrap}>“</span>
                {quote}
                <span className={classes.quoteWrap}>”</span>
              </Text>
            )}
            <Box mt={1} />
            {loading ? (
              <Skeleton variant={"rect"} width={100} />
            ) : (
              <Text color={"textSecondary"} style={{ fontStyle: "italic" }}>
                -{fullName}
              </Text>
            )}
            <Box mt={3} />
            {loading ? (
              <Skeleton variant={"rect"} height={200} />
            ) : (
              <Text variant={"body1"}>{biography}</Text>
            )}
          </Grid>
        </Grid>
        <Box mt={8} display={"flex"} justifyContent={"center"}>
          {loading ? (
            <Skeleton height={600} className={classes.lifeStyleImage} />
          ) : (
            <img
              alt={"lifestyle"}
              src={lifestylePhotoUrl}
              className={classes.lifeStyleImage}
            />
          )}
        </Box>
        <Box mt={2} />
        {lifestylePhotoBy && portraitPhotoBy && !loading && (
          <Text className={classes.photoBy} color={"textSecondary"}>
            {portraitPhotoBy ? "Portrait by " + portraitPhotoBy + ", " : ""}
            {lifestylePhotoBy ? "Photography by " + lifestylePhotoBy : ""}.
          </Text>
        )}
        <Box mt={4} />

        <CollaboratorProducts collaborator={collaborator} loading={loading} />
        <Box my={8}>
          <Divider />
        </Box>
        {loadingList ? (
          renderCollaboratorsLoader()
        ) : (
          <Grid container spacing={4}>
            {collaborators.map((collaborator) => (
              <Grid item md={3} sm={6} xs={12}>
                <CollaboratorCard collaborator={collaborator} />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return { isAuth: state.isAuth};
};
export default connect(mapStateToProps, {})(CollaboratorDetails)
