import { ApiEndpoint } from "../../../store/@core/endpoint";
import { EntityType } from "../../../store/@core/entityType";
import BaseProductGrid from "../../BaseProductGrid";
import { Paging } from "reactcoregk/models";
import {useTitle} from "../../../hooks/router";
import {useState} from "react";
import {capitalizeFirstLetter} from "../../../utils";
const schema = {
  id: null,
  slug: "",
  name: "",
  lookBookUrl: "",
  products: new Paging(),
};

function pageableFormatter(response) {
  return {
    ...response.products,
  };
}

export default function CollectionDetails(props) {
  const { slug } = props.match.params;
  const [dynamicTitle,setDynamicTitle] = useState(`${slug.split('-').join(' ').toUpperCase()} - Collection`)
  const productsUrl = `${ApiEndpoint[EntityType.Collection]}/${slug}`;
  const breadcrumbs = [
    { name: "COLLECTIONS", path: "/collections" },
    { name: slug.split('-').join(' ').toUpperCase(), path: "#" },
  ];

  const addCategoriesToTitle = (categoryArray) =>{
    const categories = categoryArray.map((cat)=>capitalizeFirstLetter(cat.toLowerCase()))
    setDynamicTitle(`${slug.split('-').join(' ').toUpperCase()} - ${categories.splice(0,2).join(',')} - Collection`)
  }

  useTitle(dynamicTitle)

  return (
    <BaseProductGrid
      productsUrl={productsUrl}
      breadcrumbs={breadcrumbs}
      initialState={schema}
      pageableFormatter={pageableFormatter}
      collectionCategories={addCategoriesToTitle}
    />
  );
}
