import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {Hyperlink} from "./Core/ActionButton";

const useStyles = makeStyles({
  label: {
    // "&:hover": {
    //   fontWeight: "bold",
    // },
    fontSize: (props) => props.size || 12,
    textTransform: "capitalize",
    textAlign: "left"
  },
});

function NavigationLink(props) {
  const classes = useStyles(props);
  return (
    <Button
      classes={{ root: classes.root, label: classes.label }}
      component={Hyperlink}
      actionPath={props.to}
      href={props.href}
      style={props.style}
      aria-label={props['aria-label']}
      activeStyle={{
        fontWeight: props.highlightSelected ? "bold" : "initial",
      }}
      className={props.className}
      fullWidth={props.fullWidth}
    >
      {props.children}
    </Button>
  );
}

export default NavigationLink;
