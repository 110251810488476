import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Box,
  Chip,
  Collapse,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TuneIcon from "@material-ui/icons/Tune";
import { useState } from "react";
import { ExpandLess } from "@material-ui/icons";
import Text from "../Text/Text";
import { List } from "react-virtualized";
import Popover from "../Popover/Popover";
import { Skeleton } from "@material-ui/lab";
import { StickySub } from "../../hooks/sticky";
import { getRoles } from "../../utils";
import { userRoles } from "../../constants/enums";

const useStyles = makeStyles({
  menuButton: {
    fontWeight: "bold",
    fontSize: 12,
  },
  toolbar: {
    minHeight: 40,
    padding: 0,
  },
});

function DropDown({
  filter,
  handleSelect,
  attributes,
  handleDeleteAttribute,
  loadingFilters,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleFilter = (e, opt) => {
    if (attributes.includes(opt.id.toString())) handleDeleteAttribute(opt.id);
    else handleSelect(opt.id);
    handleClose(e);
  };

  const validOptions = filter.options.filter((x) => x.count > 0);
  validOptions.forEach((p) => (p.value = p.value.toLowerCase()));
  const maxOptionValue =
    validOptions.length > 0
      ? validOptions.reduce((a, b) => {
          return a.value.length > b.value.length ? a : b;
        })
      : validOptions[0];
  let maxOptionCount = 0;
  let maxOptionWidth = 0;
  let maxHeight = validOptions.length > 10 ? 300 : validOptions.length * 35
  if (maxOptionValue?.value) {
    maxOptionCount = maxOptionValue.count + 2;
    maxOptionWidth = Math.ceil(
      maxOptionCount
        ? maxOptionValue.value.length * 8.15 +
            maxOptionCount.toString().length * 9.75 +
            74
        : 180
    );
  }
  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) {
    const opt = validOptions[index];
    return (
      <div style={{ ...style }} key={key}>
        <MenuItem
          disabled={opt.count === 0}
          key={opt.id}
          onClick={(e) => handleFilter(e, opt)}
        >
          <Text
            bold={attributes.includes(opt.id?.toString())}
            style={{ textTransform: "capitalize" }}
          >
            {opt.value} <b style={{ fontSize: 12 }}>({opt.count})</b>
          </Text>
        </MenuItem>
      </div>
    );
  }

  return (
    <div>
      <Button
        className={classes.menuButton}
        style={{display:validOptions.length === 0?'none':''}}
        aria-controls="simple-menu"
        aria-haspopup="true"
        disabled={validOptions.length === 0 || loadingFilters}
        onClick={handleClick}
      >
        {filter.typeName}
        {!open ? <ExpandMoreIcon /> : <ExpandLess />}
      </Button>
      <Popover
        open={open}
        containerStyles={{ padding: 0 }}
        placement={"bottom-start"}
        handleClose={handleClose}
        anchorEl={anchorEl}
      >
        <div style={{ height: maxHeight, width: maxOptionWidth }}>
          <List
            width={maxOptionWidth}
            height={maxHeight}
            rowCount={validOptions.length}
            rowHeight={35}
            rowRenderer={rowRenderer}
          />
        </div>
      </Popover>
    </div>
  );
}

function AllFilters({
  allFilters,
  toggleFilter,
  defaultFilterIds,
  favoritesPage,
  toggleSelectFavorites,
  toggleSelect,
  setToggleSelect,
  selectedFavorites,
  addSelectionsToBag,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickFavoritesPage = () => {
    if (toggleSelect === false) {
      toggleSelectFavorites(!toggleSelect);
      setToggleSelect(!toggleSelect);
    } else {
      addSelectionsToBag();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleFilter = (e, opt) => {
    toggleFilter(opt.typeId);
  };
  const disableAddSelectionsButton = toggleSelect && !selectedFavorites;
  return (
    <div>
      {favoritesPage && !getRoles().includes(userRoles.WEB_USER) ? (
        <Button
          className={classes.menuButton}
          style={{
            width: 352,
            height: 56,
            backgroundColor: toggleSelect ? "#FFF595" : "",
            color: toggleSelect ? (selectedFavorites ? "" : "#969798") : "",
          }}
          color={toggleSelect ? "" : "primary"}
          variant={toggleSelect ? "contained" : "outlined"}
          aria-controls="simple-menu"
          aria-haspopup="true"
          disabled={disableAddSelectionsButton}
          onClick={handleClickFavoritesPage}
        >
          {!toggleSelect
            ? "SELECT FAVORITES FOR MEMO"
            : "ADD SELECTIONS TO BAG"}
        </Button>
      ) : (
        <Button
          className={classes.menuButton}
          style={{ width: 140 }}
          aria-controls="simple-menu"
          aria-haspopup="true"
          disabled={allFilters.length === 0}
          onClick={handleClick}
        >
          <TuneIcon /> All Filters
        </Button>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {allFilters.map((filter) => {
          const exist = defaultFilterIds.includes(filter.typeId);
          return (
            <MenuItem key={filter.typeId} onClick={(e) => handleFilter(e, filter)}>
              <Text bold={exist}>{filter.typeName}</Text>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

function CatalogFilters({
  visibleFilters,
  allFilters,
  handleAddAttribute,
  handleDeleteAttribute,
  attributes,
  defaultFilterIds,
  toggleFilter,
  loadingFilters,
  isSticky = false,
  favoritesPage = false,
  toggleSelectFavorites = false,
  toggleSelect = false,
  setToggleSelect,
  handleSelectAllFavorites,
  clearSelectedFavorites,
  selectedFavorites,
  addSelectionsToBag,
}) {
  const classes = useStyles();
  const { ref, isStickySub, headerShown } = StickySub(false, true);

  return (
    <AppBar
      position="static"
      color="transparent"
      elevation={0}
      ref={ref}
      className={
        isSticky
          ? [
              isStickySub ? "sticky" : "",
              headerShown ? "stickyWithHeader" : "",
            ].join(" ")
          : null
      }
    >
      <Divider style={{ height: 1, backgroundColor: "#1B1C1D" }} />
      <Toolbar className={classes.toolbar}>
        <Box py={1} display={"flex"} alignItems={"center"} flex={1}>
          <Box display={"flex"} flexWrap={"wrap"} style={{ flex: 1 }}>
            {loadingFilters && visibleFilters.length === 0
              ? [...Array(3)].map((k, i) => (
                  <Skeleton
                    variant={"circle"}
                    style={{ borderRadius: 30, marginRight: 8 }}
                    width={120}
                    height={36}
                  />
                ))
              : visibleFilters.map((filter) => (
                  <DropDown
                    filter={filter}
                    handleSelect={handleAddAttribute}
                    attributes={attributes}
                    handleDeleteAttribute={handleDeleteAttribute}
                    loadingFilters={loadingFilters}
                  />
                ))}
          </Box>
          <AllFilters
            allFilters={allFilters}
            toggleFilter={toggleFilter}
            defaultFilterIds={defaultFilterIds}
            favoritesPage={favoritesPage}
            toggleSelectFavorites={toggleSelectFavorites}
            setToggleSelect={setToggleSelect}
            toggleSelect={toggleSelect}
            selectedFavorites={selectedFavorites}
            addSelectionsToBag={addSelectionsToBag}
          />
        </Box>
      </Toolbar>
      <Divider style={{ height: 1, backgroundColor: "#1B1C1D" }} />
      {attributes?.length <= 0 && toggleSelect && (
        <Grid
          container
          spacing={2}
          style={{ marginTop: 23, marginLeft: -32, paddingBottom: 20 }}
          justify={"flex-end"}
        >
          <Grid item onClick={handleSelectAllFavorites}>
            <Typography
              style={{
                fontFamily: "Gotham",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16px",
                lineHeight: "125%",
                textAlign: "center",
                letterSpacing: "0.01em",
                textDecorationLine: "underline",
                cursor: "pointer",
              }}
            >
              Select all on this page
            </Typography>
          </Grid>
          <Grid
            item
            onClick={() => {
              setToggleSelect(false);
              clearSelectedFavorites();
            }}
          >
            <Typography
              style={{
                fontFamily: "Gotham",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16px",
                lineHeight: "125%",
                textAlign: "center",
                letterSpacing: "0.01em",
                textDecorationLine: "underline",
                cursor: "pointer",
              }}
            >
              Cancel
            </Typography>
          </Grid>
        </Grid>
      )}
      <Collapse in={attributes.length > 0}>
        <Box
          px={3}
          mt={2}
          display={"flex"}
          flexWrap={"wrap"}
          style={{ minHeight: 50 }}
        >
          <Grid container spacing={2} alignItems={"center"}>
            {allFilters[0] &&
              attributes.map((attributeId) => {
                const filter = allFilters.find((filter) => {
                  return filter?.options.find(
                    (opt) => opt.id === parseInt(attributeId)
                  );
                });

                const option = filter?.options.find(
                  (x) => x.id === parseInt(attributeId)
                );

                const label = (
                  <Box display={"flex"}>
                    <Text bold style={{ fontSize: 14 }}>
                      {filter?.typeName}
                    </Text>
                    <Text
                      style={{
                        marginLeft: 4,
                        fontSize: 14,
                        fontStyle: "italic",
                      }}
                    >
                      {option?.value}
                    </Text>
                  </Box>
                );

                return (
                  <Grid item>
                    <Chip
                      color={"primary"}
                      variant={"outlined"}
                      label={label}
                      onDelete={() => handleDeleteAttribute(attributeId)}
                    />
                  </Grid>
                );
              })}
            {attributes?.length > 0 && toggleSelect && (
              <Grid item style={{ marginLeft: "auto" }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography
                      style={{
                        fontFamily: "Gotham",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "125%",
                        textAlign: "center",
                        letterSpacing: "0.01em",
                        textDecorationLine: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Select all on this page
                    </Typography>
                  </Grid>
                  <Grid item onClick={() => setToggleSelect(false)}>
                    <Typography
                      style={{
                        fontFamily: "Gotham",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "125%",
                        textAlign: "center",
                        letterSpacing: "0.01em",
                        textDecorationLine: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Cancel
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Collapse>
    </AppBar>
  );
}

export default CatalogFilters;
