import axios from "axios";
import ApiHandler from "reactcoregk/models/apiHandler";
import {useEffect, useState} from "react";
import {ADMIN_API_URL, API_URL} from './config'

axios.defaults.headers.common = {
  Authorization: getToken()
};

export function getDefaultHeaders() {
  return {
    Authorization: getToken()
  };
}

export function getAxiosConfig(file) {
  let customHeaders = {headers:getDefaultHeaders()}
  if (file) {
    customHeaders['Content-Type'] = 'multipart/form-data'
  }

  return customHeaders
}

export const getDefaultApiHandler = () =>
    new ApiHandler(false, true, getDefaultHeaders());

export function getCredentials() {
  const permUserAuth = localStorage.getItem("authUser");
  const tempUserAuth = sessionStorage.getItem("authUser");
  return tempUserAuth || permUserAuth
}

export function getToken() {
  const credentials = getCredentials()
  const token = credentials ? JSON.parse(credentials).access_token : null;
  return token ? `Bearer ${token}` : null
}

export function getUserId() {
  const token = getToken()
  if (!token) return null
  return parseJwt(token)?.sub
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function SmoothVerticalScrolling(e, time, where) {
  const eTop = e.getBoundingClientRect().top;
  const eAmt = eTop / 100;
  let curTime = 0;
  while (curTime <= time) {
    window.setTimeout(SVS_B, curTime, eAmt, where);
    curTime += time / 100;
  }
}

export function SVS_B(eAmt, where) {
  if(where === "center" || where === "")
    window.scrollBy(0, eAmt / 2);
  if (where === "top")
    window.scrollBy(0, eAmt);
}

export function toCurrency(amount, showFree) {
  const price = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  }).format(amount);
  return amount === 0 && showFree ? "FREE" : price
}

export function toCadCurrency(amount,showFree) {
  const price = Intl.NumberFormat("en-CA", {
    style: "currency",
    currencyDisplay:'symbol',
    currency:"CAD"
  }).format(amount);
  return amount === 0 && showFree ? "FREE" : price
}

export function getUsername() {
  const token = getToken();
  return token ? parseJwt(token)?.name : null;
}

export const getNameOrDefault = (map, id, prop = "name") =>
    map.has(id) ? map.get(id)[prop] : "-";

export function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
      atob(base64)
          .split("")
          .map(function (c) {
            return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
          })
          .join("")
  );

  return JSON.parse(jsonPayload);
}

export function getRoles() {
  const token = getToken()
  if (!token) return []
  return parseJwt(token)?.roles || []
}

function handleError(err) {
  if (err.response) {
    const errorMsg = err.response.data.message;
    if (errorMsg) throw new Error(err.response.data.message);
    throw new Error(
        `Something went wrong. Error code: ${err.request.status}.`
    );
  }
  throw new Error("Something went wrong.");
}

export const fetchData = (url, opts= {}) =>
    axios
        .get(url,opts)
        .then((res) => res.data)
        .catch(handleError);

export const postData = (url, data, opts) =>
    axios
        .post(url, data, opts)
        .then((res) => res.data)
        .catch(handleError);

export const deleteData = (url, data) =>
    axios.delete(url, { data }).catch(handleError);

export const getArrayFrom = (enumeration) => {
  const array = [];
  for (const prop in enumeration) {
    array.push({
      label: enumeration[prop],
      value: prop
    });
  }
  return array;
};

export const createOptions = (array, labelProp, valueProp = "id") => {
  return array.map((x) => ({
    label: x[labelProp],
    value: x[valueProp]
  }));
};

export function makeid(length = 10) {
  let result = "";
  const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function downloadFile(url, download) {
  var link = document.createElement('a');
  link.href = url;
  link.download = download;
  link.target = "_blank"
  link.dispatchEvent(new MouseEvent('click'));
}

export function kFormatter(num) {
  return Math.abs(num) > 999
      ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k`
      : Math.sign(num) * Math.abs(num);
}

export function poll(callBack, seconds) {
  callBack();
  return window.setInterval(callBack, seconds * 1000);
}

export function validateStr(str) {
  let regex = new RegExp("[A-Z]+"); // Check for uppercase first
  if (regex.test(str) === true) {
    regex = new RegExp("[0-9]+"); // Now we check for numbers
    if (regex.test(str) === true) {
      regex = new RegExp("[a-z]+"); // checking now for lowercase
      if (regex.test(str) === true) {
        return 1;
      }
      return 2;
    }
    return 3;
  }
  return 4;
}

export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function updateURLParameter(url, param, paramVal) {
  let newAdditionalURL = "";
  let tempArray = url.split("?");
  const baseURL = tempArray[0];
  const additionalURL = tempArray[1];
  let temp = "";
  if (additionalURL) {
    tempArray = additionalURL.split("&");
    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split("=")[0] !== param) {
        newAdditionalURL += temp + tempArray[i];
        temp = "&";
      }
    }
  }

  const rows_txt = `${temp}${param}=${paramVal}`;
  return `${baseURL}?${newAdditionalURL}${rows_txt}`;
}

export function removeParam(url, key) {
  var rtn = url.split("?")[0],
      param,
      params_arr = [],
      queryString = (url.indexOf("?") !== -1) ? url.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
}

export const readPriceUsd = (product) => {
  const {currency} = product
  return `${toCurrency(product.priceUsd)} ${currency?.name}`
}

export const readPriceCad = (product) => {
  return `${toCadCurrency(product.priceCad)} CAD`
}

export const useConfig = (endpoint, defaultValue=null, admin) => {
  const [config, setConfig] = useState(defaultValue);

  const url = `${admin?ADMIN_API_URL:API_URL}/${endpoint}`

  useEffect(()=>{
    axios.get(url)
        .then((response) => {
          if (response.status === 200) {
            setConfig(response.data)
          }
        })
        .catch((err) => {
          // Silent error
          console.log(err)
          throw err;
        });
  },[url])

  return [config]
}

export const useFetch = (url, initialState) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(initialState);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!url) return
    setIsLoading(true)
    fetchData(url)
        .then((data) => {
          setData(data);
          setError(null);
        })
        .catch((ex) => {
          setError(ex);
          setData(initialState);
        });
  }, [initialState, url]);

  return [data, isLoading, error];
};

export const truncateString = (str, num) =>{
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}
export function getTextColor(backgroundHexColor) {
  let hexcolor = backgroundHexColor.replace("#", "");

  if (!/^(?:[0-9a-f]{3}){1,2}$/i.test(hexcolor)) {
    throw new Error("Invalid hex color value: " + backgroundHexColor);
  }

  if (hexcolor.length === 3) {
    hexcolor = [...hexcolor].reduce((acc, char) => acc + char + char, "");
  }

  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 125 ? "black" : "white";
}

export const combineStrings = (divider, ...strings) => {
  return [...strings].filter(x => x).join(divider)
}

export const extractStringByIndex = (string, index) => {
  const split = string.split(' ')

  if (split.length>index) {
    split.splice(0,index)
    return split.join(' ')
  } else {
    return string
  }
}

export const addCountryCode = (countries, countryId, number) => {
  const countryObj = countries.find((c)=>c.id===countryId)
  if (countryObj) {
    return `${countryObj.countryCode} ${number?number:''}`
  }

  return number
}

export const openExternalProps = {
  target : "_blank",
  rel:"noopener noreferrer"
}

export const safeVal = val => val !== null && val !== undefined ? val : ""

export const mapper = (data, keys=[], defaultValue='') => {
  let mappedObj = {}
  keys.forEach((item)=>{
    if (typeof(item)==='object') {
      let key = item.map || item.key
      mappedObj[item.key]=data[key]||item.defaultValue
    } else {
      mappedObj[item]=data[item]||defaultValue
    }
  })
  return mappedObj
}
