import { Box, Divider } from "@material-ui/core";
import { Fragment } from "react";
import Button from "../Button/Button";
import Text from "../Text/Text";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => {
  return {
    tabLabel: {
      // fontWeight: "bold",
      textTransform: "none",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderBottom: (props) =>
        props.variant === "underlined" ? `4px solid transparent` : "none",
    },
    tabLabelActive: {
      // fontWeight: "bold",
      textTransform: "none",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderBottom: (props) =>
        props.variant === "underlined"
          ? `4px solid ${theme.palette.secondary.main}`
          : "none",
    },
    tabInactive: {
      paddingLeft: 9,
      paddingRight: 9,
    },
    divider: {
      backgroundColor: "#1B1C1D",
    },
    tabActive: {
      // fontWeight: "bold",
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor: (props) =>
        props.variant === "underlined"
          ? "inherit"
          : theme.palette.secondary.light,
    },
  };
});

export function TabButton(props) {
  const { active, value, setValue, label, startIcon, classes } = props;
  return (
    <Button
      size={"large"}
      style={{ height: 48, marginRight: 8 }}
      classes={{
        root: active === value ? classes.tabActive : classes.tabInactive,
        label: active === value ? classes.tabLabelActive : classes.tabLabel,
      }}
      onClick={() => setValue(value)}
      startIcon={startIcon}
    >
      <Text variant={"h4"} style={{ fontSize: 20 }} bold={value === active}>
        {label}
      </Text>
    </Button>
  );
}

const Tabs = (props) => {
  const classes = useStyles(props);
  const { value, setValue, tabs, hideDividerTop, hideDividerBottom, counters } =
    props;
  return (
    <Fragment>
      {!hideDividerTop && <Divider className={classes.divider} />}
      <Box display={"flex"} py={1} minHeight={64}>
        {tabs.map((tab, index) => {
          // Add counters if any (in counters=[] same order)
          let label = tab;
          if (counters) {
            if (counters[index] && counters[index] !== 0) {
              label = `${tab} (${counters[index]})`;
            } else {
              return null;
            }
          }

          return (
            <TabButton
              active={value}
              value={index}
              setValue={setValue}
              label={label}
              classes={classes}
            />
          );
        })}
      </Box>
      {!hideDividerBottom && <Divider className={classes.divider} />}
    </Fragment>
  );
};

Tabs.propTypes = {
  setValue: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["underlined", "default"]),
  value: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired,
  hideDividerTop: PropTypes.bool,
  hideDividerBottom: PropTypes.bool,
  underlined: PropTypes.bool,
};

Tabs.defaultProps = {
  variant: "default",
};

export default Tabs;
