import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import ActionButton from "../../Core/ActionButton";
import Card from "@material-ui/core/Card";
import { Link } from "react-router-dom";
import SectionTitle from "../../HomeSections/SectionTitle";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "125%", // 4:5
  },
  border: {
    // borderRadius: theme.borderRadius,
  },
}));

function ActionCard({ title, image, actionName, actionPath }) {
  const classes = useStyles();
  return (
    <Card elevation={0} style={{ borderRadius: 0 }}>
      <SectionTitle title={title} />
      <CardActionArea component={Link} to={actionPath} className={classes.border}>
        <CardMedia className={clsx(classes.media, classes.border)} image={image} title={title} />
      </CardActionArea>
      <CardActions style={{ paddingLeft: 0 }}>
        <ActionButton title={actionName} actionPath={actionPath} />
      </CardActions>
    </Card>
  );
}

export default ActionCard;
