import {
  Facebook,
  Instagram,
  LinkedIn,
  Pinterest,
  YouTube,
} from "@material-ui/icons";
import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

const socialMediaIcons = [
  { icon: <Instagram />, link: "https://www.instagram.com/schumacher1889" },
  { icon: <Pinterest />, link: "https://www.pinterest.com/schumacher1889/" },
  {
    icon: <YouTube />,
    link: "https://www.youtube.com/channel/UCsViJuCYqtWXpDFeadQHKLA",
  },
  { icon: <Facebook />, link: "https://www.facebook.com/schumacher1889" },
  { icon: <LinkedIn />, link: "https://www.linkedin.com/company/f.-schumacher-&-co./" },
];
const useStyles = makeStyles((theme) => ({
  socialButton: {
    width: 40,
    height: 40,
    borderRadius: 20,
    border: "1px solid",
  },
  title: {
    fontFamily: "Chronicle",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "20px",
    lineHeight: "140%",
    letterSpacing: "0.0125em",
  },
  light: {
    color: "#fff",
  },
  dark: {
    color: "#1B1C1D",
  },
  lightButton: {
    color: "#fff",
    borderColor: "white",
  },
  darkButton: {
    color: "#1B1C1D",
    borderColor: "#1B1C1D",
  },
}));

const FooterSocialMedia = (props) => {
  const { title, footerStyle = "light", ...rest } = props;
  const classes = useStyles();
  return (
    <Grid container spacing={1} {...rest}>
      {title && (
        <Grid item>
          <Typography
            varianth={"h5"}
            className={clsx(
              classes.title,
              footerStyle === "light" ? classes.light : classes.dark
            )}
          >
            {title}
          </Typography>
        </Grid>
      )}
      <Grid item container spacing={4}>
        {socialMediaIcons.map((social, i) => {
          return (
            <Grid item key={`social-${i}`}>
              <IconButton
                className={clsx(
                  classes.socialButton,
                  footerStyle === "light"
                    ? classes.lightButton
                    : classes.darkButton
                )}
                size={"medium"}
                href={social.link}
                target={"_blank"}
              >
                {social.icon}
              </IconButton>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default FooterSocialMedia;
