import {Box, Container, Grid, Typography} from "@material-ui/core";
import UsersProfileNavigation from "./UsersProfileNavigation";
import {makeStyles} from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    root: {},
    menuButton: {
        fontWeight: "bold",
        fontSize: 12,
    },
    bgLight: {
        backgroundColor: theme.palette.secondary.lightNav,
        paddingBottom: 8,
    },
    customH1:{
        fontFamily: "Chronicle",
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: "56px",
        lineHeight: "100%"
    }
}));
const AccountProfileLayout = (props) => {
    const classes = useStyles()

    return <Box style={{marginTop: 10}}>
        <div className={classes.bgLight}>
            <Box py={2}>
                <Container maxWidth="lg">
                    <UsersProfileNavigation {...props}/>
                    <Grid item style={{marginTop:32,marginBottom:32}}>
                        <Typography variant={'h1'} className={classes.customH1}>{props.title}</Typography>
                    </Grid>
                    {props.children}
                </Container>
            </Box>
        </div>
    </Box>
}
export default AccountProfileLayout