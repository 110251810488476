import PropTypes from "prop-types";
import CheckoutCard from "../CheckoutCard/CheckoutCard";
import Text from "../../Text/Text";
import { combineStrings } from "../../../utils";
import { Box, Grid, IconButton } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  text: {
    marginTop: 2,
    marginBottom: 2,
  },
  default: {
    color: "#FE7A00",
    fontSize: 16,
    textTransform: "uppercase",
  },
});

const AddressCard = (props) => {
  const classes = useStyles();
  const { onClick, checked, disabled, address, onEdit } = props;
  const {
    name,
    surname,
    state,
    city,
    country,
    phoneNumber,
    email,
    businessName,
    addressLine1,
    friendlyName,
    isDefault,
  } = address || {};

  return (
    <CheckoutCard checked={checked} disabled={disabled} onClick={() => onClick && onClick(address)}>
      <Grid container alignItems={"flex-start"}>
        <Grid item xs={7} container direction={"column"}>
          <Text className={classes.text} bold>
            {combineStrings(" ", name, surname)}
          </Text>
          {businessName && (
            <Text className={classes.text} bold>
              {businessName}
            </Text>
          )}
          <Text className={classes.text}>{addressLine1}</Text>
          <Text className={classes.text}>
            {combineStrings(", ", city, state, country)}
          </Text>
          <Text className={classes.text}>
            {combineStrings("  |  ", phoneNumber, email)}
          </Text>
        </Grid>
        <Grid
          item
          xs={5}
          container
          direction={"row"}
          wrap={"nowrap"}
          alignItems={"center"}
          justify={"flex-end"}
        >
          <Grid item container spacing={1} direction={"column"} alignItems={"flex-end"}>
            {isDefault && (
              <Grid item>
                <Text bold className={classes.default}>
                  Default
                </Text>
              </Grid>
            )}
            {friendlyName && (
              <Grid item>
                <Text bold style={{textAlign: "right"}}>{friendlyName}</Text>
              </Grid>
            )}
          </Grid>
          <Box mx={2} />
          <Grid item>
            <Grid item>
              <IconButton
                size={"small"}
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit && onEdit(address);
                }}
              >
                <EditOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CheckoutCard>
  );
};

AddressCard.propTypes = {
  checked: PropTypes.bool.isRequired,
  onEdit: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  address: PropTypes.object,
};

export default AddressCard;
