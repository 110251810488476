import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Divider, Fab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Text from "../Text/Text";
import Button from "../Button/Button";
import { toCurrency } from "../../utils";
import { useAmounts, useCartItems, useCheckout } from "../../hooks/cart";
import { Link } from "react-router-dom";
import OrderItemList from "./OrderItemsList";
import AccordionList from "./AccordionsList";
import { Close, InfoOutlined } from "@material-ui/icons";
import Tooltip from "../Tooltip/Tooltip";
import Dialog from "../Dialog/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { openCheckout } from "../../store/cart/actions";
import { orderItemType } from "../../constants/enums";
import clsx from "clsx";
import Tabs from "../Tabs/Tabs";

const useStyles = makeStyles((theme) => ({
  root: {
    // overflow: "hidden",
    minWidth: 1024,
    height: "100vh",
    padding: 32,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  list: {
    flex: 1,
    maxHeight: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  disableScroll: {
    overflow: "hidden",
  },
  footer: {
    paddingTop: 16,
    display: "flex",
    alignItems: "center",
  },
  cartName: {
    color: theme.palette.text.primary + "!Important",
    opacity: 1,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  closeIcon: {
    position: "absolute",
    left: 0,
    top: 0,
    width: 36,
    height: 36,
    marginLeft: -18,
    marginTop: 18,
  },
  divider: {
    backgroundColor: "#1B1C1D",
  },
}));

function CartBody({
  memos,
  handleRemoveItem,
  handleRemoveItems,
  handleUpdateItem,
  processingCart,
  products,
  activeTab,
  amounts,
  dirtyChanged,
  closeCartDrawer,
  checkoutAvailable,
  setSelectedProductIds,
  setSelectedMemoIds,
  expandedType,
  setExpandedType,
}) {
  switch (activeTab) {
    case 0:
      return (
        <OrderItemList
          items={memos}
          handleRemoveItem={handleRemoveItem}
          handleRemoveItems={handleRemoveItems}
          handleUpdateItem={handleUpdateItem}
          processingCart={processingCart}
          dirtyChanged={dirtyChanged}
          closeCartDrawer={closeCartDrawer}
          checkoutAvailable={checkoutAvailable}
          type={orderItemType.SAMPLE}
          activeTab={activeTab}
          setRootSelectedIds={setSelectedMemoIds}
        />
      );
    case 1:
      return (
        <OrderItemList
          items={products}
          handleRemoveItem={handleRemoveItem}
          handleRemoveItems={handleRemoveItems}
          handleUpdateItem={handleUpdateItem}
          processingCart={processingCart}
          dirtyChanged={dirtyChanged}
          closeCartDrawer={closeCartDrawer}
          checkoutAvailable={checkoutAvailable}
          type={orderItemType.PRODUCT}
          activeTab={activeTab}
          setRootSelectedIds={setSelectedProductIds}
        />
      );
    default:
      return (
        <AccordionList
          processingCart={processingCart}
          handleUpdateItem={handleUpdateItem}
          handleRemoveItem={handleRemoveItem}
          handleRemoveItems={handleRemoveItems}
          memos={memos}
          products={products}
          amounts={amounts}
          dirtyChanged={dirtyChanged}
          closeCartDrawer={closeCartDrawer}
          checkoutAvailable={checkoutAvailable}
          activeTab={activeTab}
          setSelectedProductIds={setSelectedProductIds}
          setSelectedMemoIds={setSelectedMemoIds}
          expandedType={expandedType}
          setExpandedType={setExpandedType}
        />
      );
  }
}

export const CheckoutButton = ({ processingCart, to, ...rest }) => (
  <Button
    disabled={processingCart}
    component={Link}
    to={to}
    size={"large"}
    color={"primary"}
    variant={"outlined"}
    {...rest}
  >
    {rest.children}
  </Button>
);

const tabLabels = ["Memos", "Products", "All"];

const CartWidget = (props) => {
  const classes = useStyles(props);
  const {
    cart,
    processingCart,
    handleRemoveItem,
    handleUpdateItem,
    closeCartDrawer,
    cartDrawerOn,
    handleRemoveItems,
  } = props;

  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [selectedMemoIds, setSelectedMemoIds] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [dirtyIds, setDirtyIds] = useState({});
  const [newTabIndex, setNewTabIndex] = useState(0);
  const [openNewTabConfirmation, setOpenNewTabConfirmation] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const checkoutAvailable = useSelector((state) => state.Cart.order.isOpen);
  const [expandedType, setExpandedType] = useState(orderItemType.SAMPLE);
  const { selectedMemos, selectedProducts } = useCheckout(cart);

  useEffect(() => {
    // Cart drawer on possible values (false, 0, 1, 2) false = not initialized, 0 = closed, 1 = Memos, 2 = Products, 3 = All tabs
    if (cartDrawerOn) setActiveTab(cartDrawerOn - 1);
    else setActiveTab(0);
  }, [cartDrawerOn]);

  const amounts = useAmounts(cart);

  const { memos, products } = useCartItems(cart);

  const itemIds = useMemo(
    () => [...memos, ...products].map((x) => x.id),
    [memos, products]
  );
  const memoIds = useMemo(() => memos.map((x) => x.id), [memos]);
  const productIds = useMemo(() => products.map((x) => x.id), [products]);

  const handleDiscardAndChangeTab = useCallback(() => {
    setDirtyIds({});
    setActiveTab(newTabIndex);
    setOpenNewTabConfirmation(false);
  }, [newTabIndex]);

  const handleNewTab = useCallback(
    (index) => {
      const editable =
        Object.keys(dirtyIds)
          .map((x) => dirtyIds[x])
          .filter((x) => x).length > 0;
      if (editable) {
        setWarningMessage("without saving your edits");
        setNewTabIndex(index);
        setOpenNewTabConfirmation(true);
      } else if (selectedMemoIds[0] || selectedProductIds[0]) {
        setWarningMessage("and clear your selections");
        setNewTabIndex(index);
        setOpenNewTabConfirmation(true);
      } else {
        setActiveTab(index);
      }
    },
    [dirtyIds, selectedMemoIds, selectedProductIds]
  );

  const dirtyChanged = useCallback((id, bool) => {
    setDirtyIds((prevState) => ({
      ...prevState,
      [id]: bool,
    }));
  }, []);

  const tabs = useMemo(() => {
    const total = toCurrency(amounts.netAmount);
    return [
      `${tabLabels[0]} (${memos.length})`,
      `${tabLabels[1]} (${products.length}) - ${total}`,
      `${tabLabels[2]} (${cart?.items.length || 0}) - ${total}`,
    ];
  }, [amounts.netAmount, cart?.items.length, memos.length, products.length]);

  const handleCheckoutMemos = useCallback(() => {
    closeCartDrawer();
    dispatch(openCheckout(memoIds));
  }, [closeCartDrawer, dispatch, memoIds]);

  const handleCheckoutProducts = useCallback(() => {
    closeCartDrawer();
    dispatch(openCheckout(productIds));
  }, [closeCartDrawer, dispatch, productIds]);

  const handleCheckoutSelected = useCallback(() => {
    closeCartDrawer();
    dispatch(openCheckout([...selectedMemoIds, ...selectedProductIds]));
  }, [closeCartDrawer, dispatch, selectedMemoIds, selectedProductIds]);

  const handleCheckoutAll = useCallback(() => {
    closeCartDrawer();
    dispatch(openCheckout(itemIds));
  }, [closeCartDrawer, dispatch, itemIds]);

  function renderAllOrSelected() {
    if (selectedProductIds[0] || selectedMemoIds[0]) {
      return (
        <CheckoutButton
          processingCart={processingCart}
          to={"/checkout"}
          color={"secondary"}
          variant={"contained"}
          onClick={handleCheckoutSelected}
        >
          Selected Items
        </CheckoutButton>
      );
    }
    return (
      <CheckoutButton
        processingCart={processingCart}
        to={"/checkout"}
        color={"secondary"}
        variant={"contained"}
        onClick={handleCheckoutAll}
      >
        All Items in Bag
      </CheckoutButton>
    );
  }

  function renderFooter() {
    if (
      activeTab === 0 ||
      (activeTab === 2 && expandedType !== orderItemType.PRODUCT)
    ) {
      if (!memoIds[0]) return null
      return (
        <>
          <CheckoutButton
            processingCart={processingCart}
            to={"/checkout"}
            onClick={handleCheckoutMemos}
          >
            Memos Only
          </CheckoutButton>
          <Box mx={1} />
          {renderAllOrSelected()}
        </>
      );
    } else if (
      activeTab === 1 ||
      (activeTab === 2 && expandedType === orderItemType.PRODUCT)
    ) {
      if (!productIds[0]) return null
      return (
        <>
          <CheckoutButton
            processingCart={processingCart}
            onClick={handleCheckoutProducts}
            to={"/checkout"}
            startIcon={
              <Tooltip
                title={"Shipping and tax will be calculated at checkout."}
              >
                <InfoOutlined />
              </Tooltip>
            }
          >
            Products Only
          </CheckoutButton>
          <Box mx={1} />
          {renderAllOrSelected()}
        </>
      );
    }
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div>
          <div style={{ display: "flex", padding: "16px 0px" }}>
            <Text variant={"h4"}>
              Items in My Bag ({cart?.items.length || 0}) -{" "}
              {toCurrency(amounts.netAmount)}
            </Text>
          </div>
          <Tabs
            variant={"underlined"}
            value={activeTab}
            setValue={handleNewTab}
            tabs={tabs}
          />
        </div>
        <div
          className={clsx(
            classes.list,
            activeTab === 2 && classes.disableScroll
          )}
        >
          <CartBody
            products={products}
            memos={memos}
            handleRemoveItem={handleRemoveItem}
            handleUpdateItem={handleUpdateItem}
            processingCart={processingCart}
            activeTab={activeTab}
            amounts={amounts}
            dirtyChanged={dirtyChanged}
            closeCartDrawer={closeCartDrawer}
            handleRemoveItems={handleRemoveItems}
            selectedMemos={selectedMemos}
            selectedProducts={selectedProducts}
            checkoutAvailable={checkoutAvailable}
            setSelectedMemoIds={setSelectedMemoIds}
            setSelectedProductIds={setSelectedProductIds}
            expandedType={expandedType}
            setExpandedType={setExpandedType}
          />
        </div>
        <Divider className={classes.divider} />
        <div className={classes.footer}>
          <Text variant={"h4"}>Check out:</Text>
          <Box ml={"auto"} />
          {renderFooter()}
        </div>
      </div>
      <Fab
        onClick={closeCartDrawer}
        color={"primary"}
        className={classes.closeIcon}
      >
        <Close />
      </Fab>
      <Dialog
        onClose={() => setOpenNewTabConfirmation(false)}
        open={openNewTabConfirmation}
        title={`Do you want to go to ${tabLabels[newTabIndex]} ${warningMessage}?`}
        primaryAction={handleDiscardAndChangeTab}
        maxWidth={"sm"}
        fullWidth
        hideCloseIcon
        primaryActionTitle={"Yes"}
        secondaryAction={() => setOpenNewTabConfirmation(false)}
        secondaryActionTitle={"Cancel"}
      />
    </div>
  );
};

CartWidget.propTypes = {
  handleRemoveItem: PropTypes.func.isRequired,
  handleUpdateItem: PropTypes.func.isRequired,
  handleRemoveItems: PropTypes.func.isRequired,
  handleUpdateCart: PropTypes.func,
  processingCart: PropTypes.bool,
  updatingCart: PropTypes.bool,
};

CartWidget.defaultProps = {};

export default CartWidget;
