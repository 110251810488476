import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Grid, TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Text from "../../../components/Text/Text";
import Button from "../../../components/Button/Button";
import { Add, LocationOnOutlined } from "@material-ui/icons";
import StepContent from "../Common/StepContent";
import AddressForm from "./AddressForm";
import { addressContactType } from "../../../constants/enums";
import { useEntityProgress, useForm } from "reactcoregk/hooks";
import { Schema } from "../../../store/@core/schema";
import { EntityType } from "../../../store/@core/entityType";
import Dialog from "../../../components/Dialog/Dialog";
import { useAlert } from "../../../hooks/alert";
import { updateAddress } from "../../../store/address/actions";
import { useDispatch, useSelector } from "react-redux";
import {setOrderSaveAddress, setOrderShippingAddress} from "../../../store/cart/actions";
import AddressCard from "../../../components/Cards/AddressCard/AddressCard";
import { TabPanel } from "../../../components/Core/TabPanel";
import { fadeAnimation } from "../../../constants/animations";
import Heading from "../Common/Heading";
import SearchIcon from "@material-ui/icons/Search";

function ShippingAddress(props) {
  const { context, addresses, countries, tradeAccountId, setStep, numberOfSelectedItems } = props;
  const [currentView, setCurrentView] = useState(0);
  const dispatch = useDispatch();
  const [entity, setEntity] = useState(Schema[EntityType.Address]);
  const [form, handleChange, setForm] = useForm(Schema[EntityType.Address]);
  const [query, setQuery] = useState("");
  const [openAddressChange, setOpenAddressChange] = useState(false);
  const shippingAddressId = useSelector((state) => state.Cart.order.shippingAddressId);
  const shippingAddress = useSelector((state) => state.Cart.order.shippingAddress);

  const defaultAddress = addresses.find((x) => x.isDefault);
  const lastAddress = addresses.find((x) => x.accessedAt);
  const selectedAddress = addresses.find((x) => x.id === shippingAddressId);
  const topAddress = shippingAddress || selectedAddress || defaultAddress || lastAddress;

  const handleAddress = useCallback(
    (address) => {
      dispatch(setOrderShippingAddress(address));
    },
    [dispatch]
  );

  const handleShouldSaveAddress = useCallback((bool) => {
    dispatch(setOrderSaveAddress(bool))
  }, [dispatch])

  const handleNext = () => {
    if (currentView === 2) {
      if (form.id && form.id !== -1) {
        dispatch(
            updateAddress(
                { ...form, contactType: addressContactType.SHIPPING },
                tradeAccountId
            )
        );
      } else {
        handleAddress({...form, id: -1 })
        setStep(prevState => prevState + 1)
        handleShouldSaveAddress(true)
      }
    } else {
      setStep((prevState) => prevState + 1);
    }
  };

  const nextTitle = useMemo(() => {
    if (currentView === 2) {
      if (entity && entity.id && entity.id !== -1) {
        return "Save changes";
      }
      return "Save & Continue";
    }
    return "Next";
  }, [entity, currentView]);

  useEffect(() => {
    setForm(entity);
  }, [entity, setForm]);

  useEffect(() => {
    if (!shippingAddressId && addresses[0]) {
      if (defaultAddress) {
        handleAddress(defaultAddress);
      } else {
        handleAddress(addresses[0]);
      }
    }
  }, [topAddress, handleAddress, shippingAddressId, addresses, defaultAddress]);

  const handleAddressSelect = useCallback(
    (address) => {
      if (!address.isDefault) {
        setEntity(address);
        setOpenAddressChange(true);
      } else {
        handleAddress(address);
      }
    },
    [handleAddress]
  );

  const handleCloseModal = (approve) => {
    if (approve) {
      dispatch(updateAddress({ ...entity, isDefault: true }, tradeAccountId));
    }
    handleAddress(entity);
    setOpenAddressChange(false);
    setCurrentView(0);
  };

  const handleEdit = useCallback((address) => {
    setEntity(address);
    setForm(address)
    setCurrentView(2);
  }, [setForm]);

  const dismissDialogs = useCallback(() => {
    setOpenAddressChange(false);
    setCurrentView(0);
  }, []);

  const [inProgress, errorMessage] = useEntityProgress(context, dismissDialogs);

  useAlert(errorMessage, "error");

  const recentlyAddresses = addresses.filter((x) => x.accessedAt);

  const handleGoBack = () => {
    setCurrentView(0);
  };

  useEffect(() => window.scrollTo(0, 0), [currentView]);

  const handleContinueWithoutSaving = useCallback(() => {
    handleAddress({...form, id: -1 })
    setStep(prevState => prevState + 1)
    handleShouldSaveAddress(false)
  }, [form, handleAddress, handleShouldSaveAddress, setStep])

  const shouldRenderContinueWithoutSaving = () => {
    return currentView === 2 && (!entity.id || entity.id === -1)
  }

  const disableNext = () => {
    if (inProgress || !shippingAddressId || (currentView !== 2 && !numberOfSelectedItems)) {
      return true
    }
    if (currentView === 2) {
      const required = ["name", "surname", "addressLine1", "countryId", "city", "zipCode"]
      return !!Object.keys(form).find(prop => required.includes(prop) && !form[prop])
    }
  }

  return (
    <StepContent
      onBack={currentView && handleGoBack}
      onNext={() => handleNext(true)}
      nextTitle={nextTitle}
      onContinue={shouldRenderContinueWithoutSaving() && handleContinueWithoutSaving}
      disabledNext={disableNext()}
    >
      <TabPanel index={0} value={currentView} animation={fadeAnimation}>
        <Grid container direction={"column"}>
          <Heading title={"Shipping Address"} />
          {topAddress && (
            <AddressCard
              address={topAddress}
              checked
              onEdit={() => handleEdit(topAddress)}
            />
          )}
          <Box mt={2} />
          {addresses[0] && (
            <Button
              onClick={() => setCurrentView(1)}
              color={"primary"}
              style={{ alignSelf: "flex-start" }}
              startIcon={<LocationOnOutlined />}
            >
              Use Another Address
            </Button>
          )}
          <Button
            style={{ alignSelf: "flex-start" }}
            onClick={() => {
              setForm(Schema[EntityType.Address]);
              setCurrentView(2);
            }}
            color={"primary"}
            startIcon={<Add />}
          >
            Add New Address
          </Button>
        </Grid>
      </TabPanel>
      <TabPanel index={1} value={currentView} animation={fadeAnimation}>
        <Fragment>
          <Grid container spacing={2}>
            <Grid item>
              <Heading title={"Recently Used"} />
            </Grid>
            {recentlyAddresses.slice(0, 2).map((address, index) => (
              <Grid item xs={12} key={index}>
                <AddressCard
                  address={address}
                  checked={address.id === shippingAddressId}
                  onClick={handleAddressSelect}
                  onEdit={handleEdit}
                />
              </Grid>
            ))}
          </Grid>
          <Box mt={1} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Heading title={"All Addresses"} mb={1}>
                <TextField
                  variant={"outlined"}
                  margin={"dense"}
                  InputProps={{
                    startAdornment: <SearchIcon />,
                  }}
                  label={"Search Address"}
                  disabled={inProgress}
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </Heading>
              <Button
                onClick={() => {
                  setForm(Schema[EntityType.Address]);
                  setCurrentView(2);
                }}
                color={"primary"}
                startIcon={<Add />}
              >
                Add New Address
              </Button>
            </Grid>
            {addresses
              .filter((x) =>
                [
                  x.name || "",
                  x.addressLine1 || "",
                  x.addressLine2 || "",
                  x.state || "",
                  x.city || "",
                  x.country || "",
                ].find((value) =>
                  value?.toLowerCase()?.includes(query.toLowerCase())
                )
              )
              .map((address, index) => (
                <Grid item xs={12} key={index}>
                  <AddressCard
                    address={address}
                    checked={address.id === shippingAddressId}
                    onClick={handleAddressSelect}
                    onEdit={handleEdit}
                  />
                </Grid>
              ))}
          </Grid>
        </Fragment>
      </TabPanel>
      <TabPanel index={2} value={currentView} animation={fadeAnimation}>
        <AddressForm
          form={form}
          setForm={setForm}
          handleChange={handleChange}
          countries={countries}
          inProgress={inProgress}
        />
      </TabPanel>
      <Dialog
        onClose={() => setOpenAddressChange(false)}
        open={openAddressChange}
        title={"Change Address"}
        maxWidth={"xs"}
        primaryAction={() => handleCloseModal(true)}
        primaryActionTitle={"Make it default"}
        secondaryAction={() => handleCloseModal(false)}
        secondaryActionTitle={"No"}
        inProgress={inProgress}
      >
        <Text variant={"body1"} style={{ textAlign: "center" }}>
          Do you want to set{" "}
          <b>{entity.friendlyName || entity.addressLine1 || "this address"}</b>{" "}
          as
          <b> default</b> for future orders?
        </Text>
      </Dialog>
    </StepContent>
  );
}

export default ShippingAddress;
