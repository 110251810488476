import {Form, Formik} from 'formik'
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core'
import FormikTextField from "components/Core/FormikFields/FormikTextField";
import FormikSelect from "components/Core/FormikFields/FormikSelect";
import FormikCheckbox from "components/Core/FormikFields/FormikCheckbox";
import * as Yup from "yup";
import { useConfig } from "utils";
import { SectionTitle } from "pages/Account/CreateTradeAccount/steps/common/SectionTitle";
import {businessTypesOptions} from "constants/enums";
import {FormikContext} from "components/FormikDisabler";

export const BusinessInformation = (props) => {
  const {
    setFormData,
    formData,
    step,
    setStep,
    formRef,
    disableActions,
    isUserView=true,
    disabled=false,
    locked=false,
    isAccountEdit=false
  } = props;

  const [businessTypes] = useConfig(`config/business-types`, []);

  const step1Validation = Yup.object().shape({
    businessName: Yup.string().required('This field is required'),
    businessType: Yup.string().required("Please select one of the options."),
    businessFocus: Yup.string().required("Please select one of the options."),
  });

  return (
    <>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={formData}
        validationSchema={step1Validation}
        onSubmit={(values) => {
          setFormData(prevFormData => ({...prevFormData, ...values, businessInformationSubmitted: true, firstLoad:false }))
          if (setStep) setStep(step + 1);
        }}
        validateOnChange={true}
      >
        {({ values, isValid, dirty, errors, submitForm}) => (
          <Form noValidate>
            <FormikContext.Provider value={{disabled,locked}}>
              <Grid item container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 700, marginTop: 16 }}
                >
                  Business Information
                  {/*{JSON.stringify(errors)}*/}
                </Typography>
              </Grid>

                <Grid item xs={12}>
                  <SectionTitle>{isUserView?`About Your Business`:`About the Business`}</SectionTitle>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormikTextField
                    variant={"outlined"}
                    margin="none"
                    size={"small"}
                    fullWidth
                    type={"text"}
                    id={"businessName"}
                    name={"businessName"}
                    label={"Business Name"}
                  />
                </Grid>

              <Grid item xs={12} md={12}>
                <FormikSelect
                  variant={"outlined"}
                  margin="none"
                  fullWidth
                  id={"businessType"}
                  name={"businessType"}
                  label={"Business Type"}
                  options={businessTypes}
                />
              </Grid>

              {values.businessType===1&&<Grid item xs={12} md={12}>
                <FormikTextField
                  variant={"outlined"}
                  margin="none"
                  size={"small"}
                  fullWidth
                  type={"text"}
                  id={"otherBusinessType"}
                  name={"otherBusinessType"}
                  label={"Specify Other (optional)"}
                />
              </Grid>}

                <Grid item xs={12} md={12}>
                  <FormikSelect
                    variant={"outlined"}
                    margin="none"
                    fullWidth
                    id={"businessFocus"}
                    name={"businessFocus"}
                    label={"Primary Business Focus"}
                    options={businessTypesOptions}
                  />
                </Grid>

                {!isAccountEdit&&<Grid item xs={12}>
                <FormikCheckbox
                  label={
                    isUserView ? "My company focuses on both residential & hospitality projects" :
                      "This company focuses on both residential & hospitality projects"
                  }
                  id={"focusOnResidentialHospitality"}
                  name={"focusOnResidentialHospitality"}
                />
              </Grid>}

              {setStep && (
                <Grid
                  item
                  container
                  spacing={2}
                  justify="center"
                  alignContent="center"
                  alignItems="flex-start"
                >
                  <Grid item xs={12} md={6}>
                    &nbsp;
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid item container spacing={2}>
                      <Grid item xs={12}>
                        <Button
                          size="large"
                          variant={"contained"}
                          color='secondary'
                          fullWidth
                          type={"submit"}
                          disabled={disableActions || !isValid || (!dirty && !formData.businessInformationSubmitted)}
                        >
                          {" "}
                          NEXT{" "}
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          size="large"
                          variant={"outlined"}
                          fullWidth
                          onClick={()=>{
                            setFormData(prevFormData => ({...prevFormData, ...values, businessInformationSubmitted: true, firstLoad:false }))
                          }}
                          disabled={disableActions || !isValid || (!dirty && !formData.businessInformationSubmitted)}
                        >
                          {" "}
                          {"SAVE APPLICATION"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}

            </Grid>
            </FormikContext.Provider>
          </Form>
        )}
      </Formik>
    </>
  );
};
