import { Grid } from "@material-ui/core";
import { getFormView } from "./form-views";
import { Form } from "formik";

function FormContainer(props) {
  const { form, busy, children } = props;
  return (
    <Form noValidate>
      <Grid
        container
        direction={"row"}
        spacing={2}
        justify={"center"}
        alignItems={"center"}
      >
        {form.map((input, index) => (
          <Grid item md={input.col || 12} xs={12}>
            {getFormView(input, index, busy)}
          </Grid>
        ))}
        {children}
      </Grid>
    </Form>
  );
}

export default FormContainer;
