import {ErrorMessage, useField} from "formik";
import {Box, CircularProgress, FormControl, InputAdornment, TextField} from '@material-ui/core'
import {FormikContext} from "components/FormikDisabler";
import {useContext, useState} from 'react'
import {CheckCircleOutlined} from "@material-ui/icons";
import {Link} from 'react-router-dom'
import {openExternalProps} from 'utils'
import WarnEditOverlay from 'components/Core/FormikFields/WarnEditOverlay'

const FormikTextField = (props) => {
  const { required = false, type, variant = "standard", currency, countryCode, loading, error, valid, inputRef, hidden, link, warnEdit} = props;
  const [field, meta] = useField(props.name);
  const currencyAdornment = <InputAdornment position="start">{currency}</InputAdornment>
  const countryCodeAdornment = <InputAdornment position="start">{countryCode}</InputAdornment>
  const {disabled, locked} = useContext(FormikContext)
  const [showWarning, setShowWarning] = useState(null)
  const isDisabled = props.disabled || disabled

  const hasError = (meta.error && meta.touched) || error
  return (
    <FormControl fullWidth required={required} error={hasError} style={{position:'relative'}}>
      {(warnEdit||locked)&&(!isDisabled)&&<WarnEditOverlay showWarning={showWarning} setShowWarning={setShowWarning} />}
      <TextField
        style={{display:hidden?'none':'initial'}}
        id="outlined-basic"
        variant={variant}
        type={type}
        inputRef={inputRef}
        {...field}
        {...props}
        {...props.rest}
        error={hasError}
        InputProps={{
          // onFocus:warn,
          startAdornment: (
            currency?currencyAdornment:countryCode?countryCodeAdornment:null
          ),
          endAdornment: (
            <InputAdornment position="end" style={{marginRight:8}}>
              {loading&&<CircularProgress size={20} />}
              {valid&&!loading&&<CheckCircleOutlined style={{color:'green'}}/>}
              {link&&<Link to={{pathname:`//${link}`}} {...openExternalProps}>View link</Link>}
            </InputAdornment>
          )
        }}
        disabled={props.disabled||disabled}
        // helperText={meta.error}
      />
        <ErrorMessage name={field.name} render={msg => <Box pl={1} pt={0.5} style={{textTransform:'uppercase',color:'#DF0006', fontWeight:'bold'}}>{msg}</Box>} />
    </FormControl>
  );
};

export default FormikTextField;
