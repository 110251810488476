import { Skeleton } from "@material-ui/lab";
import { Container, Grid } from "@material-ui/core";

const SkeletonSection = () => {
  return (
    <Grid item xs={3} container direction={"row"} spacing={1}>
      <Grid item xs={6}>
        <Skeleton variant={"rect"} color={"#cecece"} height={10} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          variant={"rect"}
          color={"#cecece"}
          height={400}
        />
      </Grid>
      <Grid item xs={8}>
        <Skeleton variant={"rect"} color={"#cecece"} height={10} />
      </Grid>
    </Grid>
  );
};

const HomeSkeleton = (props) => {
  return (
    <Container maxWidth={"xl"}>
      <Grid container spacing={4} direction={"row"}>
        <SkeletonSection />
        <SkeletonSection />
        <SkeletonSection />
        <SkeletonSection />
      </Grid>
    </Container>
  );
};

export default HomeSkeleton;
