import { Box, ClickAwayListener, Container, Fade } from "@material-ui/core";
import Promotion from "../../components/Promotion";
import { useCallback, useState } from "react";
import { connect } from "react-redux";
import {
  useHomePage,
  useIsAuth,
  useProfile,
  useProfileIds,
} from "../../hooks/auth";
import UserColumn from "./UserColumn";
import Features from "./Features";
import Products from "./Products";
import ShopByGrid from "./ShopByGrid";
import Branding from "./Branding";
import { API_URL } from "../../config";
import { openCartDrawer } from "../../store/element/actions";
import { useKeyDown } from "../../hooks/general";
import SearchBox from "../../components/Searchbox/SearchBox";

const promo1 = {
  href:
    "https://www.youtube.com/watch?v=A9Nx3h1cNlE&list=PLGoqBioaSO6NFKP-AVcCIHxGNTYmLa2Fw&index=9",
  actionTitle: "Listen to Gil's Secrets in Our Latest Webinar",
  content:
    "Deep Dive with Dara: How To Give New Architecture Old-World Charm with Gil Schafer.",
};
const promo2 = {
  href:
    "https://shop.fredericmagazine.com/products/frederic-magazine-subscription",
  actionTitle: "Subscribe Now!",
  content:
    "Fall in love with Frederic! Our glossy magazine is a must-read for design lovers.",
};

const Home = (props) => {
  const { openCartDrawer, location } = props;
  const isAuth = useIsAuth();
  const { activeUserId } = useProfileIds();
  const {
    visibleProfile,
    validatingSpoofingUser,
    validatingUser,
  } = useProfile();

  // Escape
  useKeyDown(27, () => setOpenSearch(false));

  const loadingVisible = validatingSpoofingUser || validatingUser;
  const [content, loadingContent] = useHomePage(activeUserId);
  const [openSearch, setOpenSearch] = useState(
    location.search.includes("search")
  );

  const getProductsBasedOnSearch = useCallback(
    (query) => {
      if (query.categoryId) {
        props.history.push(`/catalog/${query.categoryId}?term=${query.term || ""}`);
      } else if (query.productItemNumber) {
        props.history.push(`/catalog/products/${query.productItemNumber}`);
      } else if (query.collectionSlug) {
        props.history.push(
          `/collections/${query.collectionSlug}?term=${query.term || ""}`
        );
      } else if (query.term) {
        props.history.push(`/discover?search=${query.term}`);
      } else if (query.name) {
        props.history.push(`/discover?search=${query.name}`);
      } else if (query) {
        props.history.push(`/discover?search=${query}`);
      }
    },
    [props.history]
  );

  return (
    <>
      <Container maxWidth={"xl"}>
        <Box mt={6} />
        <div style={{ maxWidth: 992, margin: "auto" }}>
          <ClickAwayListener onClickAway={() => setOpenSearch(false)}>
            <div>
              <SearchBox
                url={`${API_URL}/search/suggestions?term=`}
                nameProp={"term"}
                valueProp={"term"}
                namePropFallback={"name"}
                valuePropFallback={"name"}
                extraProp={"categoryName"}
                onSubmit={getProductsBasedOnSearch}
                placeholder={`Discover Schumacher Classics`}
                initialQuery={""}
                onFocus={() => setOpenSearch(true)}
                autoOpen={Boolean(openSearch)}
                showIcon={true}
                showHeader
                showFooter
                animateRecommendations
              />
            </div>
          </ClickAwayListener>
        </div>
        <Box mt={6} />
        <Fade in={Boolean(!openSearch)}>
          <div>
            <UserColumn
              content={content}
              isAuth={isAuth}
              validatingUser={loadingVisible}
              visibleProfile={visibleProfile}
              openCartDrawer={() => openCartDrawer(3)}
            />
          </div>
        </Fade>
      </Container>
      {Boolean(!openSearch) && (
        <div>
          <Promotion
            href={promo1.href}
            actionTitle={promo1.actionTitle}
            content={promo1.content}
          />
          <Features />
          <Products content={content} loadingContent={loadingContent} />
          <ShopByGrid />
          <Promotion
            style={{ margin: 0 }}
            href={promo2.href}
            actionTitle={promo2.actionTitle}
            content={promo2.content}
            promoStyle={"dark"}
          />
          <Box mt={10} />
          <Branding />
        </div>
      )}
    </>
  );
};

export default connect(null, { openCartDrawer })(Home);
