export const productAttributes = {
    characteristic_names: [
        "Availability",
        "Other",
        "WC Available",
        "Motif",
        "Abrasion Tags",
        "Type",
        "Designer",
        "Scale Tags",
        "Size",
        "FAB Available",
        "Style",
        "Application",
        "Content Tags",
        "Color",
        "Collection",
    ],
    property_names: [
        "Item Width",
        "Item Height",
        "Author",
        "Publisher",
        "Signed Copy",
        "Item Depth",
        "Pre-Pasted",
        "Sold By",
        "Abrasion",
        "Yards Per Roll",
        "Priced By",
        "Scale",
        "Yards Per Repeat",
        "Light Fastness",
        "Railroaded ",
        "Horizontal Repeat",
        "Flame Test",
        "Pretrim",
        "Country of Finish",
        "Content",
        "Country of Origin",
        "Vertical Repeat",
        "Match",
    ],
    special_attribute_names: [
        "Wide Width",
        "Reversible",
        "Stain Resistant",
        "Indoor/Outdoor",
        "High Performance",
        "Made by Hand",
    ],
    meta_names: [
        "sampleSupported",
        "digitalMemoAvailable",
        "repeatInfoAvailable",
        "reserveAvailable",
        "purchaseAvailable",
        "orderAvailable",
        "cfaAvailable",
        "backOrderAvailable",
        "generatedTearsheet",
        "sampleBackOrderAvailable",
        "priceAvailable",
        "inventoryAvailable",
        "sampleAvailable",
        "directShipAvailable",
        "quoteAvailable",
        "tearsheetAvailable",
    ],
};

export const declineReasons = [
    'Did not respond to vetting for extended period (need to establish how long)',
    'Did not send tax form for extended period (need to establish how long)',
    'Does not meet requirements.',
    'Duplicate Account'
]

export const USCountryID = 840
export const CACountryID = 124
export const PRCountryID = 630

export const contactInformationFormDefaults = {
    sameAsBusinessAddressShipping: true,
    sameAsBusinessAddressBilling: true,

    country: '',

    businessCity: '',
    businessAddressLine1: '',
    businessZipCode: '',
    businessState: '',
    businessPhoneNumber: '',

    billingName: '',
    billingAddressLine1: '',
    billingZipCode: '',
    billingCity: '',
    billingState: '',
    billingPhoneNumber: '',

    shippingName: '',
    shippingAddressLine1: '',
    shippingZipCode: '',
    shippingCity: '',
    shippingState: '',
    shippingPhoneNumber: ''
}

export const businessInformationFormDefaults = {
    businessName: '',
    businessType: '',
    businessFocus: 'RESIDENTIAL',
    otherBusinessType: '',
    typeOfProof: 'taxId',
    focusOnResidentialHospitality: ''
}

export const memberInformationFormDefaults = {
    firstName: '',
    lastName: '',
    email: '',
    businessOwner: '',
    firstNameOwner: '',
    lastNameOwner: '',
    emailOwner: ''
}

export const paymentAndShippingFormDefaults = {
    terms: 0,
    currencyCode: 1,
    thirdPartyBilling: 'Third party billing',
    creditLimit: "0",
    shippingMethod: 8,
    tradeDiscount: "0.0",
    priceLevel: 'WHOLESALE',
    discount: '',
}

export const salesTerritoryFormDefaults = {
    salesTerritory: '',
    ae: '',
    notes: [],
    rep: ''
}

export const discoverOptions = [
    'Blue',
    'Laid Back',
    'Indoor & Outdoor',
    'Pink',
    'Panels & Murals',
    'Caroline Z Hurley',
    'Neutral',
    'Stripes',
    'Molly Mahon',
    'Hand Blocked'
]

export const tradeProgramTaxExemptAccordion = [
    {
        header: 'Get Free Avalara Integration',
        content: ''
    },
    {
        header: 'Start Shopping and Saving in No Time',
        content: ''
    }
]

export const tradeProgramAccordion = [
    {
        header: 'Get Free Memos',
        content: 'We understand how important it is to get ready for your next client meetings. You can get memos for free after your trade account is set up.'
    },
    {
        header: 'Reserve & Purchase Products',
        content: 'With a Schumacher trade account, you can reserve a product in advance to secure the inventory before getting confirmation from your client for final purchase.'
    },
    {
        header: 'Buy at Exclusive Net Prices',
        content: 'Enjoy designer discount across all of our products by joining our trade program. '
    },
    {
        header: 'Access Real-Time Product Inventory',
        content: 'You\'ll also have access to real-time stock information on our website to take actions needed to meet your project timeline.'
    },
    {
        header: 'Have Designated Personal Help',
        content: 'Your rep will become your partner and personal concierge, with a complete set of keys to our comprehensive product lines and services.'
    },
    {
        header: 'Gain Marketing Opportunities',
        content: 'We celebrate great designs and talents. Interior Designers have the opportunity to be featured on our website and social channels to help build reputation and attract potential leads.'
    }
]

export const legalTerms = `Legal Notice

            Your use of this web site and the content contained herein is subject to all of the terms and conditions stated in this Legal Notice. By accessing this web site and/or using such materials, you agree to be bound by each provision of this Legal Notice. No other agreement relating to this site will be effective and any attempt by you to create such an agreement between us shall be invalid. This Legal Notice may be changed by us at any time without notice to you and, therefore, it is your responsibility to check this Legal Notice each time you access this web site.
            Copyright Notice

            The content of this web site, including all text, photographs and graphics, and its compilation are the property of F. Schumacher & Co. or its content suppliers, are protected by U.S. and international copyright laws and may not be used except as set forth below. The designs featured on our products are the sole and exclusive property of F. Schumacher & Co. and/or are subject to copyright protection. You do not have the right, and agree not, to copy, reproduce, modify, adapt or otherwise use such designs in any manner, except as a purchaser and user of our products and then only in accordance with applicable law. All software used on this site is the property of F. Schumacher & Co. or its software providers.
            Trademark Notice

            SCHUMACHER®, GREEFF®, PATTERSON, FLYNN & MARTIN®, and DECORATORS WALK® are trademarks of F. Schumacher & Co. and may not be used in any way without our prior written permission. We vigorously enforce our rights in our trademarks and other intellectual property to the fullest extent permitted by law. All other trademarks not owned by F. Schumacher & Co. which appear on this site are the property of their respective owners.

            Use

            You are authorized to use the material on this site for personal, non-commercial uses only. You may download and print copies of this material for such use as long as you do not make any changes to it and do not remove any proprietary notices. You may not distribute copies of this material, for a fee or otherwise, and you may not in any way modify or create derivative works from the material contained in this site without our written permission. Except as expressly stated in this notice, no express or implied rights to this material are granted to you.

            Privacy

            Your use of this web site is also subject to our Privacy Policy, which is set forth below. Subject to that policy, you agree that any communication you send us via e-mail, posted by you on this site or otherwise forwarded to us may be treated by us as non-confidential and used by us, both within our company and without, in our discretion. For this reason, you should not send or post any comments that you wish to keep confidential or do not want us to use.

            Links with Third Party Sites

            This site may contain links to sites owned or operated by parties other than F. Schumacher & Co. Such links are provided for your reference only. F. Schumacher & Co. does not control outside sites and is not responsible for their content or operation. The inclusion of links to an outside site does not imply any endorsement of the material on the site or, unless expressly disclosed otherwise, any sponsorship, affiliation or association with its owner, operator or sponsor, nor does the inclusion of such links imply that F. Schumacher & Co. is authorized to use any trade name, trademark, logo, legal or official seal or copyrighted symbol that may be reflected in the linked site. You are prohibited from linking any other web site or other electronic medium to this site or any other site owned or operated by F. Schumacher & Co. without our express written permission.

            Product Display

            We endeavor to display the products and other images on our site as accurately as possible. However, since we cannot control the capabilities and limitations of your monitor, we do not guarantee that your monitor’s display of any color, texture or detail of any actual merchandise will be accurate.

            Disclaimer

            ALL MATERIAL, INCLUDING THE TEXT, PICTURES AND STORE REFERENCES, ON THIS SITE IS PROVIDED “AS IS.” TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO SUCH MATERIAL, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT AND FREEDOM FROM COMPUTER VIRUS, AND ALL WARRANTIES WHICH MAY BE IMPLIED FROM COURSE OF CONDUCT OR COURSE OF DEALING. WE DO NOT REPRESENT THAT THE INFORMATION CONTAINED ON THIS SITE IS ACCURATE, COMPLETE OR CURRENT. ALL PRICES AND PRODUCTS ARE SUBJECT TO CHANGE. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.

            Limitation of Liability

            WE SHALL HAVE NO LIABILITY FOR ANY DAMAGES DUE TO ERRORS OR OMISSIONS IN THE CONTENT OF THIS SITE OR ANY DELAY OR INABILITY TO USE THIS SITE EXPERIENCED BY YOU, OR DAMAGE TO YOUR COMPUTER OR DATA OR ANY OTHER DAMAGES YOU MAY INCUR IN CONNECTION WITH YOUR ACCESS TO AND/OR USE OF THIS SITE. IN NO EVENT SHALL WE BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING IN ANY WAY OUT OF YOUR USE OF THIS SITE OR THE MATERIALS CONTAINED HEREIN, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL WE BE LIABLE FOR DAMAGES DUE TO CIRCUMSTANCES BEYOND OUR REASONABLE CONTROL.
            SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.

            Miscellaneous

            We reserve the right to terminate your access to this site or any part of it in the event you violate any agreement or obligation you may have with us, including this Legal Notice, or if you have engaged in any conduct which may violate applicable law or be harmful to us or our property or any third party or its property.

            The agreement between us as set forth in this Legal Notice shall be governed by and construed in accordance with the laws of the State of New York without regard to conflicts of law principles thereof. Any dispute between us arising out of or relating to your use of this web site, including your purchase of any of our products, shall be exclusively subject to the jurisdiction of the New York State and Federal courts sitting in New York County, New York, and by using this site you hereby consent to such jurisdiction.`

