export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const ADD_MEMO_TO_CART = 'ADD_MEMO_TO_CART';
export const DELETE_PRODUCT_FROM_CART = 'DELETE_PRODUCT_FROM_CART';
export const DELETE_MULTI_PRODUCT_FROM_CART = 'DELETE_MULTI_PRODUCT_FROM_CART';
export const UPDATE_PRODUCT_OF_CART = 'UPDATE_PRODUCT_OF_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const GET_OPEN_CARTS = 'GET_OPEN_CARTS';
export const SET_SPOOFING_USER_ID = 'SET_SPOOFING_USER_ID';
export const SET_MONITORING = 'SET_MONITORING';
export const OPEN_CHECKOUT = 'OPEN_CHECKOUT';
export const CLOSE_CHECKOUT = 'CLOSE_CHECKOUT';
export const TOGGLE_ORDER_ITEM = 'TOGGLE_ORDER_ITEM';
export const SET_ORDER_ITEM_IDS = 'SET_ORDER_ITEM_IDS';
export const SET_ORDER_PRODUCT_SHIPPING_METHOD_ID = 'SET_ORDER_PRODUCT_SHIPPING_METHOD_ID';
export const SET_ORDER_MEMO_SHIPPING_METHOD_ID = 'SET_ORDER_MEMO_SHIPPING_METHOD_ID';
export const SET_ORDER_PAYMENT_METHOD_ID = 'SET_ORDER_PAYMENT_METHOD_ID';
export const SET_ORDER_SHIPPING_ADDRESS = 'SET_ORDER_SHIPPING_ADDRESS';
export const SET_ORDER_SAVE_ADDRESS = 'SET_ORDER_SAVE_ADDRESS';
export const SET_ORDER_NOTES = 'SET_ORDER_NOTES';
export const SET_ORDER_PO_NUMBER = 'SET_ORDER_PO_NUMBER';
export const SET_ORDER_PROJECT_NAME = 'SET_ORDER_PROJECT_NAME';
export const SET_ORDER_MEMO_PACKING_NOTES = 'SET_ORDER_MEMO_PACKING_NOTES';
export const SET_ORDER_PRODUCT_PACKING_NOTES = 'SET_ORDER_PRODUCT_PACKING_NOTES';
export const SET_ORDER_PAYMENT_METHOD_OPTION = 'SET_ORDER_PAYMENT_METHOD_OPTION';
