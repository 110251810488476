import {Box, Grid} from '@material-ui/core'

export const ErrorBox = ({error}) => {
  if (!error) return <></>
  return <>
    <Grid item xs={12}>
      <Box style={{textTransform:'uppercase', color:'red'}} pl={1} mt={3} title={error.message}>

        {error.errors&&error.errors.length!==0&&error.errors.map((e)=>{
          return <span> {e.field} {e.defaultMessage}  </span>
        })}

        {error.message&&<span>{error.message}</span>}

      </Box>
    </Grid>
  </>
}
