import Text from "components/Text/Text";
import { Box, Button, Grid, Input } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.black,
  },
  searchBox: {
    marginTop: 25,
    alignItems: "center",
    border: "1px solid white",
    borderRadius: 30,
    paddingLeft: theme.spacing(1),
    position: "relative",
  },
  searchButton: {
    background: theme.palette.headerColor,
    borderRadius: 30,
    // marginLeft: 8,
  },
  responsiveButton: {
    width: 142,
    [theme.breakpoints.down("md")]: {
      width: "220px !important",
    },
  },
  media: {
    width: 200,
    height: 200,
  },
  card: {
    width: 200,
    height: 200,
    background: "transparent",
    borderRadius: 0,
    marginRight: 30,
  },
  subtitle: {
    color: "#fff",
    fontFamily: "Fredoka One, cursive",
    textTransform: "uppercase",
  },
  container: {
    marginTop: 100,
    color: "#fff",
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  inputRoot: {
    color: "white",
  },
  signButton: {
    width: "188px",
    padding: "15px 26px",
  },
  label: {
    fontFamily: "Gotham",
    fontWeight: "bold",
    fontSize: "15px",
    color: "black",
  },
  placeholder: {
    color: "white",
  },
}));

const FooterSignup = (props) => {
  const classes = useStyles();
  return (
    <Grid item container xs={12} md={10}>
      <Grid item>
        <Text
          gutterBottom
          variant="h3"
          style={{
            marginTop: 10,
            fontSize: "32",
            lineHeight: "112.5%",
            color: "white",
            fontWeight: "300",
            textAlign: "left",
          }}
        >
          Never Miss a New Collection!
        </Text>
      </Grid>
      <Grid item>
        <Text
          gutterBottom
          variant="body1"
          style={{
            color: "white",
            fontWeight: "normal",
            lineHeight: "112.5%",
            textAlign: "left",
          }}
        >
          We release products weekly! Subscribe to our Style Dispatch for early
          access to new collections, exclusive event invitations (virtual and
          in-person!), inspiration and more.
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.searchBox} fullWidth>
          <Input
            fullWidth
            disableUnderline={true}
            placeholder={"Email Address"}
            classes={{ root: classes.inputRoot, input: classes.placeholder }}
            endAdornment={
              <Button
                classes={{
                  root: classes.signButton,
                  containedSecondary: classes.responsiveButton,
                  label: classes.label,
                }}
                color="secondary"
                variant="contained"
              >
                Sign Me up
              </Button>
            }
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default FooterSignup;
