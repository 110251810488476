import Dialog from 'components/Dialog/Dialog'
import Typography from '@material-ui/core/Typography'


const WarnEditDialog = ({open, setOpen, onYes, handleClose}) => {

  return <Dialog
    onClose={handleClose}
    open={open}
    // title={`Reset Password`}
    maxWidth="sm"
    primaryActionTitle={'YES'}
    secondaryActionTitle={'CANCEL'}
    primaryAction={onYes}
    secondaryAction={handleClose}
  >
    <Typography fontSize={24}>
      Are you sure you want to make this edit?
    </Typography>
  </Dialog>
}

export default WarnEditDialog
