import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Text from "../Text/Text";
import { useState } from "react";
import { Skeleton } from "@material-ui/lab";
import {Link} from "react-router-dom";
import {openExternalProps} from "utils";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    // maxWidth: 300,
    cursor: "pointer",
    textDecoration: "none"
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    marginBottom: 8,
  },
  imageBorder: {
    borderBottom: "5px solid #FFD700",
    marginTop: -5,
    zIndex: 1,
  },
  image: {
    height: 280,
    borderTopLeftRadius: theme.borderRadius,
    borderTopRightRadius: theme.borderRadius,
    objectFit: "cover",
    filter: "grayscale(100%)",
    "&:hover": {
      filter: "none",
    },
  },
}));

const CollaboratorCard = (props) => {
  const { collaborator, isLoading, openExternally } = props;
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const linkProps = openExternally?openExternalProps:null

  return (
    <Box
      className={classes.root}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      component={Link}
      to={`/collaborators/${collaborator.slug}`}
      {...linkProps}
    >
      <div className={classes.imageWrapper}>
        {isLoading ? (
          <Skeleton variant={"rect"} className={classes.image} height={280} />
        ) : (
          <img
            className={classes.image}
            src={collaborator.portraitPhotoUrl}
            alt={collaborator.name}
            style={{ filter: !hover ? "grayscale(100%)" : "none" }}
          />
        )}
        {!isLoading && (
          <div
            className={classes.imageBorder}
            style={{ borderColor: !hover ? "#FFD700" : "transparent" }}
          />
        )}
      </div>
      {isLoading ? (
        <Skeleton width={"100%"} height={32} />
      ) : (
        <Text uppercase variant={"h5"} style={{ marginBottom: 8 }}>
          {`${collaborator.firstName} ${collaborator.lastName}`}
        </Text>
      )}
      {!isLoading && (
        <div
          className={classes.imageBorder}
          style={{ borderColor: hover ? "#FFD700" : "transparent" }}
        />
      )}
    </Box>
  );
};

CollaboratorCard.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  collaborator: PropTypes.object.isRequired,
  isLoading: PropTypes.bool
};

CollaboratorCard.defaultProps = {};

export default CollaboratorCard;
