import BaseProductGrid from "pages/BaseProductGrid";
import { API_URL } from "../../config";
import {getUserId} from "../../utils";
import {connect} from "react-redux";

function RecommendedProducts({spoofingUserId}) {
  const breadcrumbs = [{ name: "More Products", path: "#" }];

  const userId = spoofingUserId || getUserId()
  const url = `${API_URL}/users/${userId}/products/related`

  return (
    <BaseProductGrid productsUrl={url} breadcrumbs={breadcrumbs} />
  );
}
const mapStateToProps = (state) => {
  const { spoofingUserId } = state.Cart;

  return {
    spoofingUserId
  };
};
export default connect(mapStateToProps, {})(RecommendedProducts)
