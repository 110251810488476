import {
    API_ERROR,
    LOGIN_SUCCESS,
    LOGIN_USER,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    VALIDATE_USER,
    VALIDATE_USER_SUCCESS,
    VALIDATE_USER_FAILURE
} from './actionTypes';

export const loginUser = (user, history, options, onSuccess) => {
    return {
        type: LOGIN_USER,
        payload: { user, history, options, onSuccess }
    }
}

export const validateUser = () => {
    return {
        type: VALIDATE_USER,
        payload: { }
    }
}

export const validateUserSuccess = () => {
    return {
        type: VALIDATE_USER_SUCCESS,
        payload: { }
    }
}


export const validateUserFailure = () => {
    return {
        type: VALIDATE_USER_FAILURE,
        payload: {}
    }
}

export const loginSuccess = (user) => {
    return {
        type: LOGIN_SUCCESS,
        payload: user
    }
}

export const logoutUser = (history) => {
    return {
        type: LOGOUT_USER,
        payload: { history }
    }
}

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {}
    }
}

export const forgotPassword = (email) => {
    return {
        type: FORGOT_PASSWORD,
        payload: {email}
    }
}

export const forgotPasswordSuccess = () => {
    return {
        type: FORGOT_PASSWORD_SUCCESS,
        payload: {}
    }
}

export const resetPassword = (values) => {
    return {
        type: RESET_PASSWORD,
        payload: {...values}
    }
}

export const resetPasswordSuccess = (data) => {
    return {
        type: RESET_PASSWORD_SUCCESS,
        payload: data
    }
}

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}

