import React from "react";
import CheckoutButtons from "./CheckoutButtons";

function StepContent(props) {
  const {
    onBack,
    onNext,
    nextTitle,
    backTitle,
    disabledNext,
    onContinue,
  } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          // maxHeight: "calc(100vh - 330px)",
          // overflowY: "auto",
          // overflowX: "hidden",
        }}
      >
        <div>{props.children}</div>
      </div>
      <CheckoutButtons
        onBack={onBack}
        onNext={onNext}
        backTitle={backTitle}
        nextTitle={nextTitle}
        disabledNext={disabledNext}
        onContinue={onContinue}
      />
    </div>
  );
}

export default StepContent;
