import { EntityType } from "../../../store/@core/entityType";
import "react-credit-cards/es/styles-compiled.css";
import {
  Checkbox as MuiCheckbox,
  CircularProgress,
  Collapse,
  Container,
  FormControlLabel,
  Grid,
  InputAdornment,
  Link,
  TextField,
  withStyles,
} from "@material-ui/core";
import { ApiEndpoint } from "../../../store/@core/endpoint";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { combineStrings, fetchData, postData, safeVal } from "../../../utils";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import GooglePlacesAutoComplete from "../../../components/Core/GooglePlacesAutoComplete";
import { createMap } from "reactcoregk/utils";
import BaseSelect from "../../../components/Core/BaseSelect";
import Text from "../../../components/Text/Text";
import { API_URL } from "../../../config";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import WarningIcon from "@material-ui/icons/Warning";
import Tooltip from "../../../components/Tooltip/Tooltip";

const useStyles = makeStyles({
  root: {
    maxWidth: 580,
    margin: 0,
    padding: 0,
  },
});

function readProperty(property, place) {
  if (!place.address_components) return null;
  return place.address_components.find((x) =>
    x.types.find((item) => item === property)
  );
}

const Checkbox = withStyles({
  root: {
    "&$checked": {
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
      },
      "& .MuiIconButton-label:after": {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "#FFD700",
        zIndex: -1,
      },
    },
  },
  checked: {},
})(MuiCheckbox);

const AddressForm = ({
  form,
  handleChange,
  setForm,
  countries,
  inProgress,
}) => {
  const classes = useStyles();

  const [autoComplete, setAutoComplete] = useState(true);
  const [states, setStates] = useState([]);
  const [upsValidation, setUpsValidation] = useState(null);
  const [validating, setValidating] = useState(false);
  const [, setLoadingStates] = useState(false);
  const countryMap = useMemo(() => createMap(countries, "code"), [countries]);
  const countryMapById = useMemo(() => createMap(countries, "id"), [countries]);
  const stateMap = useMemo(() => createMap(states, "id"), [states]);
  const cancel = useRef();

  const openAutoComplete = useCallback(() => {
    setAutoComplete(true);
  }, []);

  const closeAutoComplete = useCallback(() => {
    setAutoComplete("disabled");
  }, []);

  const isEmpty = useMemo(
    () =>
      !form.addressLine1 &&
      !form.addressLine2 &&
      !form.city &&
      !form.state &&
      !form.zipCode &&
      !form.countryId,
    [
      form.addressLine1,
      form.addressLine2,
      form.city,
      form.countryId,
      form.state,
      form.zipCode,
    ]
  );

  useEffect(() => {
    if (cancel.current) {
      cancel.current();
    }
    (async () => {
      setValidating(true);
      const countryCode = countryMapById.get(form.countryId)?.code;
      if (isEmpty || countryCode !== "US") return;
      const payload = {
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        addressLine3: "",
        city: form.city,
        countryCode: countryCode,
        stateProvinceCode: stateMap.get(form.stateId)?.code,
        postalCode: form.zipCode,
        cstomerContext: "",
      };
      const config = {
        cancelToken: new axios.CancelToken(function executor(c) {
          cancel.current = c; // keep reference for cancellation
        }),
      };
      try {
        const response = await postData(
          `${API_URL}/ups/address-validation`,
          payload,
          config
        );
        setUpsValidation(response);
        setValidating(false);
      } catch (ex) {
        // setValidating(false);
      }
    })();
  }, [
    isEmpty,
    countryMap,
    form.addressLine1,
    form.addressLine2,
    form.city,
    form.countryId,
    form.state,
    form.zipCode,
    form.stateId,
    stateMap,
    countryMapById,
  ]);

  const handlePlaceSelection = useCallback(
    async (place) => {
      // initialize on place change
      const zipCode = readProperty("postal_code", place);
      const state = readProperty("administrative_area_level_1", place);
      const city = readProperty("locality", place);
      const country = readProperty("country", place);
      const route = readProperty("route", place);
      const streetNo = readProperty("street_number", place);
      const addressLine1 =
        combineStrings(" ", streetNo?.long_name, route?.long_name) ||
        city?.long_name ||
        state?.long_name ||
        country?.long_name;

      const payload = {
        zipCode: zipCode?.short_name,
        city: city?.short_name,
        addressLine1,
      };

      if (country) {
        const appCountry = countryMap.get(country.short_name);
        const url = `${ApiEndpoint[EntityType.State]}?countryId=${
          appCountry.id
        }`;
        payload.countryId = appCountry?.id;
        try {
          const states = await fetchData(url);
          const appState =
            states.find((x) => x.code === state?.short_name) ||
            states[0]?.id ||
            null;
          payload.stateId = appState?.id;
        } catch (ex) {}
      }

      setForm((prevState) => ({
        ...prevState,
        ...payload,
      }));
    },
    [countryMap, setForm]
  );

  useEffect(() => {
    (async () => {
      if (form.countryId) {
        const url = `${ApiEndpoint[EntityType.State]}?countryId=${
          form.countryId
        }`;
        setLoadingStates(true);
        try {
          const res = await fetchData(url);
          setStates(res);
        } catch (ex) {
          setStates([]);
        }
        setLoadingStates(false);
      }
    })();
  }, [form.countryId]);

  const handleChangeCountry = useCallback(
    (e) => {
      const countryId = e.target.value;
      setForm((prevState) => ({
        ...prevState,
        countryId,
        stateId: null,
      }));
    },
    [setForm]
  );

  const handleSuggestionSelect = useCallback(
    (suggestion) => {
      setForm((prevState) => {
        const addressLine1 = suggestion.AddressLines[0];
        const addressLine2 = suggestion.AddressLines[1];
        const city = suggestion.City;
        const state = suggestion.StateProvinceCode;
        const countryId = countryMap.get(suggestion.CountryCode)?.id;
        return {
          ...prevState,
          addressLine2,
          addressLine1,
          city,
          state,
          countryId,
        };
      });
    },
    [countryMap, setForm]
  );

  const isUS = countryMapById.get(form.countryId)?.code === "US";
  const applyUpsValidation = upsValidation !== null && !isEmpty && isUS;

  return (
    <Container maxWidth={false} className={classes.root}>
      <Text bold>{form.id ? "Edit Address" : "Add New Shipping Address"}</Text>
      <Box mt={2} />
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            variant={"outlined"}
            margin={"dense"}
            label={"First Name"}
            disabled={inProgress}
            value={safeVal(form.name)}
            onChange={handleChange("name")}
            type="text"
            autoComplete={autoComplete}
            onFocus={openAutoComplete}
            onBlur={closeAutoComplete}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            variant={"outlined"}
            margin={"dense"}
            label={"Last Name"}
            disabled={inProgress}
            value={safeVal(form.surname)}
            onChange={handleChange("surname")}
            autoComplete={autoComplete}
            onFocus={openAutoComplete}
            onBlur={closeAutoComplete}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={"outlined"}
            margin={"dense"}
            label={"Business Name (Optional)"}
            disabled={inProgress}
            value={safeVal(form.businessName)}
            onChange={handleChange("businessName")}
            autoComplete={autoComplete}
            onFocus={openAutoComplete}
            onBlur={closeAutoComplete}
          />
        </Grid>
        <Grid item xs={12}>
          <GooglePlacesAutoComplete
            fullWidth
            variant={"outlined"}
            margin={"dense"}
            label={"Address Line 1"}
            disabled={inProgress}
            value={form.addressLine1}
            onChange={handleChange("addressLine1")}
            onPlaceSelected={handlePlaceSelection}
            autoComplete={autoComplete}
            InputProps={{
              endAdornment: applyUpsValidation && (
                <InputAdornment position="start">
                  {validating ? (
                    <CircularProgress size={10} />
                  ) : upsValidation?.isValid ? (
                    <Tooltip title={"Valid Address"} placement={"top"}>
                      <VerifiedUserIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title={"Invalid Address"} placement={"top"}>
                      <WarningIcon />
                    </Tooltip>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Collapse in={applyUpsValidation && !upsValidation?.isValid}>
            <Alert severity={"warning"}>
              <Box display={"flex"} flexDirection={"column"}>
                {`Address could not be validated with UPS. ${
                  upsValidation?.suggestions[0] ? "Did you mean?" : ""
                }`}
                <Collapse in={upsValidation?.suggestions.length > 0}>
                  <Box display={"flex"} flexDirection={"column"}>
                    {upsValidation?.suggestions.map((suggestion, index) => (
                      <Link
                        href={"#"}
                        key={index}
                        onClick={() => handleSuggestionSelect(suggestion)}
                      >
                        {combineStrings(
                          ", ",
                          suggestion.AddressLines[0],
                          suggestion.postalCode,
                          suggestion.city,
                          suggestion.stateProvinceCode
                        )}
                      </Link>
                    ))}
                  </Box>
                </Collapse>
              </Box>
            </Alert>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={"outlined"}
            margin={"dense"}
            label={"Address Line 2 (Unit, Suite, Apartment, Floor) (Optional)"}
            disabled={inProgress}
            value={safeVal(form.addressLine2)}
            onChange={handleChange("addressLine2")}
            autoComplete={autoComplete}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <BaseSelect
            variant={"outlined"}
            label={"Country"}
            margin={"dense"}
            value={safeVal(form.countryId)}
            hasNone
            placeholder={"Select Country"}
            options={countries}
            onChange={handleChangeCountry}
            controlId={"countryId"}
            autoComplete={autoComplete}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            variant={"outlined"}
            margin={"dense"}
            label={"Zipcode"}
            disabled={inProgress}
            value={safeVal(form.zipCode)}
            onChange={handleChange("zipCode")}
            autoComplete={autoComplete}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            variant={"outlined"}
            margin={"dense"}
            label={"City"}
            disabled={inProgress}
            value={safeVal(form.city)}
            onChange={handleChange("city")}
            autoComplete={autoComplete}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <BaseSelect
            variant={"outlined"}
            hasNone
            label={"State"}
            margin={"dense"}
            value={safeVal(form.stateId)}
            placeholder={"Select State"}
            options={states}
            onChange={handleChange("stateId")}
            controlId={"stateId"}
            labelProp={"code"}
            autoComplete={autoComplete}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            variant={"outlined"}
            margin={"dense"}
            label={"Email (Optional)"}
            disabled={inProgress}
            value={safeVal(form.email)}
            onChange={handleChange("email")}
            autoComplete={autoComplete}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            type={"number"}
            variant={"outlined"}
            margin={"dense"}
            label={"Phone Number (Optional)"}
            disabled={inProgress}
            value={safeVal(form.phoneNumber)}
            onChange={handleChange("phoneNumber")}
            autoComplete={autoComplete}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            variant={"outlined"}
            margin={"dense"}
            label={"Address Nickname (Optional)"}
            disabled={inProgress}
            value={safeVal(form.friendlyName)}
            onChange={handleChange("friendlyName")}
            autoComplete={autoComplete}
          />
        </Grid>
        {form.id && form.id !== -1 && (
          <Grid item sm={6} xs={12}>
            <FormControlLabel
              margin={"dense"}
              control={
                <Checkbox
                  checked={form.isDefault}
                  onChange={handleChange("isDefault")}
                  disabled={inProgress}
                  inputProps={{ "aria-label": "primary checkbox" }}
                  color={"secondary"}
                />
              }
              label="Set as Default"
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default AddressForm;
