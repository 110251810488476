import {Box, Container, Typography} from "@material-ui/core";

function NotFound(props) {
  return (
    <Container>
      <Box display={"flex"} justifyContent={"center"} mt={4}>
        <Typography variant="h4">Not found</Typography>
      </Box>
    </Container>
  );
}

export default NotFound;
