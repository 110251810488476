import {useFetch} from "reactcoregk/hooks";
import {useEffect, useState} from "react";
import {API_URL} from "config";

export const useCounters = (term='') => {
  const [counters, setCounters] = useState()
  const [, data] = useFetch(`${API_URL}/search/count?term=${term?term:''}`)

  useEffect(()=>{
    if (data) {
      const productsCount = data.find((c)=>c.type==='PRODUCT')?.count
      const collectionsCount = data.find((c)=>c.type==='COLLECTION')?.count
      const collaboratorsCount = data.find((c)=>c.type==='COLLABORATOR')?.count

      setCounters({
        productsCount, collectionsCount, collaboratorsCount
      })
    }
  },[data])

  return {
    ...counters
  }
}
