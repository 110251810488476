import {deleteData, fetchData, getParameterByName, getUserId, postData} from "../../utils";
import {API_URL} from "../../config";
import {updateData} from "reactcoregk/utils";

const getId = () => {
    const spoofingId = getSpoofingUserId()
    const userId = getUserId()
    return spoofingId || userId
}

export const fetchOpenCartsApi = async () => {
    return fetchData(`${API_URL}/users/${getId()}/carts/open`)
};

export const updateCartApi = async (cart) => {
    return updateData(`${API_URL}/users/${getId()}/carts/${cart.id}`, cart)
};

export const addProductToCartApi = async ({cartId, ...payload}) => {
    return postData(`${API_URL}/users/${getId()}/carts/${cartId}/items`, payload)
};

export const updateProductOfCartApi = async (payload) => {
    return updateData(`${API_URL}/users/${getId()}/carts/${payload.cartId}/items/${payload.itemId}`, payload)
};

export const deleteProductFromCartApi = async ({cartId, itemId}) => {
    return deleteData(`${API_URL}/users/${getId()}/carts/${cartId}/items/${itemId}`)
};
export const deleteProductsFromCartApi = async ({cartId, itemIds}) => {
    const url = `${API_URL}/users/${getId()}/carts/${cartId}/items`
    const requests = itemIds.map(itemId => deleteData(`${url}/${itemId}`))
    return Promise.all(requests)
};

export const getSpoofingUserProfile = (userId) => {
    return fetchData(`${API_URL}/users/${userId}/profile`)
}

export function initSpoofingId() {
    const paramId = getParameterByName("userId");
    if (paramId) {
        sessionStorage.setItem("spoofingUserId", paramId)
    }
}

export function getSpoofingUserId() {
    const id = sessionStorage.getItem("spoofingUserId")
    // Safety check
    if (id === "null") {
        sessionStorage.removeItem("spoofingUserId")
        return null
    }
    return id || null
}

export function setSpoofingUserId(id) {
    if (!id)
        sessionStorage.removeItem("spoofingUserId")
    else
        sessionStorage.setItem("spoofingUserId", id)
}
