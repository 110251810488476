import {makeStyles} from '@material-ui/core/styles'
import {Divider, Grid, Typography} from "@material-ui/core";

import {useLocation} from 'react-router-dom'
import {Fragment} from "react";


const useStyles = makeStyles((theme) => ({
    tab: {
        fontFamily: "Gotham",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "125%",
        letterSpacing: "0.01em",
        color: "#1B1C1D",
        cursor:'pointer',
    },
    active: {
        fontFamily: "Gotham",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "12px",
        lineHeight: "125%",
        letterSpacing: "0.01em",
        color: "#1B1C1D",
        cursor:'pointer',
    }
}))

const menuItems = [
    // {route: '/account/profile', title: 'Account Profile'},
    {route: '/account/order-history', title: 'Order History'},
    // {route: '/account/wallet', title: 'Wallet'},
    // {route: '/account/projects', title: 'Projects'},
    {route: '/account/favorites', title: 'Favorites'},
]

const UsersProfileNavigation = (props) => {
    const classes = useStyles()
    const location = useLocation()
    return <Grid container spacing={1} alignContent={'center'} alignItems={'center'}>
        {menuItems.map((menuItem, idx) => {
            return <Fragment key={idx}>
                <Grid item>
                    <Typography variant={'h5'}
                                className={location.pathname === menuItem.route ? classes.active : classes.tab}
                                onClick={()=>props.history.push(menuItem.route)}
                    >{menuItem.title}</Typography>
                </Grid>
                {idx !== menuItems.length - 1 &&
                <Grid item>
                    <Divider style={{backgroundColor: 'black', width: 1, height: '12px'}}/>
                </Grid>
                }
            </Fragment>
        })
        }
    </Grid>
}

export default UsersProfileNavigation