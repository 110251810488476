import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ActionButton from "../../Core/ActionButton";
import Button from "@material-ui/core/Button";
import SmallProductCard from "../SmallProductCard";
import SectionTitle from "../SectionTitle";
import Box from "@material-ui/core/Box";
import {connect} from "react-redux";
import {addMemoToCart, addProductToCart} from "../../../store/cart/actions";

//TODO: To be replaced with real data
const dummyPurchaseOrders =[
    {id:'68314624',status:'Processing',delayed:false,type:'Order'},
    {id:'36880091',status:'Shipped',delayed:false,type:'Order'},
    {id:'22540836',status:'Due 7/31/21',delayed:true,type:'Invoice'},
]
const WithOrders = ({ content, openCartDrawer }) => {
  return (
    <>
      <Grid
        item
        alignItems={"center"}
        justify={"flex-start"}
        alignContent={"flex-start"}
        direction={"column"}
        spacing={4}
        style={{ borderTop: "1px solid black" }}
      >
        {dummyPurchaseOrders.map((order) => {
          return (
            <Grid
              item
              container
              style={{
                borderBottom: "1px solid black",
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 6,
                paddingRight: 6,
              }}
              justify={"flex-start"}
              alignContent={"flex-start"}
              alignItems={"stretch"}
            >
                <Grid item container>
              <Grid item xs={7}>
                <Typography variant={"p"}>
                    {order.type}:{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                      textDecorationStyle: "dotted",
                        userSelect:'none',
                        cursor:'pointer'
                    }}
                  >
                    {order.id}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={'auto'}>
                <Typography variant={"p"} align={"left"}>
                  Status: <span style={{color:order.delayed?'red':''}}>{order.status}</span>
                </Typography>
              </Grid>
                </Grid>
            </Grid>
          );
        })}
      </Grid>
      <ActionButton title={"VIEW ORDER HISTORY"} actionPath={'/account/order-history'} style={{ marginTop: 20 }} />
      <Box mt={4} />
      <SectionTitle title={"Pick up where you left off."} />
      <Grid item container spacing={2}>
        <Grid item>
          <Button
            onClick={openCartDrawer}
            variant={"contained"}
            style={{
              height: "56px",
              backgroundColor: "#fff",
              borderRadius: 2,
              fontWeight: "bold",
              boxShadow: "0px 0px 16px rgba(27, 28, 29, 0.1)",
            }}
          >
            MY BAG
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={"contained"}
            href={'/account/favorites'}
            style={{
              height: "56px",
              backgroundColor: "#fff",
              borderRadius: 2,
              fontWeight: "bold",
              boxShadow: "0px 0px 16px rgba(27, 28, 29, 0.1)",
            }}
          >
            MY FAVORITES
          </Button>
        </Grid>
      </Grid>
      <div
        style={{
          marginTop: 20,
          marginBottom: 10,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2}>
          {content?.lastViewedProducts.map((product, index) => (
            <Grid item md={4} key={index}>
              <SmallProductCard product={product} showBasket={true} />
            </Grid>
          ))}
        </Grid>
      </div>
      <ActionButton title={"Recently Viewed"} actionPath={"/products/recently-viewed"}/>
    </>
  );
};

const mapStateToProps = (state) => {
    return {
        context: {
            Cart: state.Cart,
            Auth: state.Auth
        },
    };
};

export default connect(mapStateToProps, {
    addProductToCart,
    addMemoToCart
})(WithOrders);
