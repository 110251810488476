import Grid from '@material-ui/core/Grid'

const ContactDetails = ({contact}) => {

  if (!contact) return <>
    <Grid item xs={12} md={7}> Same as Business Address </Grid>
  </>

  return <>
    <Grid item xs={12} md={7}>
      <Grid item container>
        <Grid item xs={12}>
          {contact.addressLine1}
        </Grid>
        <Grid item xs={12}>
          {contact.addressLine1}
        </Grid>
        <Grid item xs={12}>
          {contact.addressLine2}
        </Grid>
        <Grid item xs={12}>
          {contact.city}, {contact.state?`${contact.state},`:''} {contact.zipCode}
        </Grid>
        <Grid item xs={12}>
          {contact.countryId}
        </Grid>
      </Grid>
    </Grid>
  </>
}

export default ContactDetails
