import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import ActionButton from "components/Core/ActionButton";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
    backgroundColor: theme.palette.secondary.light,
  },
  light: {
    color: "#000",
    backgroundColor: theme.palette.secondary.light,
  },
  dark: {
    color: "#fff",
    backgroundColor: "#000",
  },
}));

function Promotion(props) {
  const { content, actionTitle, promoStyle = "light", actionPath, href } = props;
  const classes = useStyles();
  return (
    <Box
      style={props.style}
      py={8}
      my={8}
      className={clsx(
        classes.root,
        promoStyle === "light" ? classes.light : classes.dark
      )}
    >
      <Grid
        container
        alignItems={"center"}
        justify={"center"}
        alignContent={"center"}
        direction={"column"}
      >
        <Grid item xs={12} md={7}>
          <Typography
            variant="h4"
            style={{ textAlign: "center", textTransform: "uppercase" }}
          >
            {content}
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: 18 }}>
          <ActionButton
            title={actionTitle}
            actionColor={promoStyle === "light" ? null : "white"}
            actionPath={actionPath}
            href={href}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Promotion;
