import PropTypes from "prop-types";
import CheckoutCard from "../CheckoutCard/CheckoutCard";
import Text from "../../Text/Text";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { toCurrency } from "../../../utils";

const useStyles = makeStyles({
  text: {
    marginTop: 8,
  },
});

const DeliveryOptionCard = (props) => {
  const classes = useStyles();
  const { onClick, checked, disabled, deliveryOption, hideEta } = props;
  const { name, costUsd, eta } = deliveryOption;

  return (
    <CheckoutCard
      checked={checked}
      disabled={disabled}
      onClick={() => onClick(deliveryOption)}
      direction={"column-reverse"}
    >
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item>
          <Text>{name}</Text>
        </Grid>
        {!hideEta && (
          <Grid item>
            <Text style={{textAlign: "center"}}>({eta})</Text>
          </Grid>
        )}
        <Grid item>
          <Text className={classes.text} bold>
            {toCurrency(costUsd, true)}
          </Text>
        </Grid>
      </Grid>
    </CheckoutCard>
  );
};

DeliveryOptionCard.propTypes = {
  checked: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  deliveryOption: PropTypes.object,
  hideEta: PropTypes.bool,
};

export default DeliveryOptionCard;
