import {Box, Button, Container, Grid, Typography} from '@material-ui/core'
import {connect} from "react-redux";
import {apiError} from "../../../store/auth/account/actions";
import axios from 'axios'
import {API_URL} from 'config'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

const RequestToJoin = () => {
  const [error,setError] = useState(false)
  const [success,setSuccess] = useState(false)
  const [isBusy, setIsBusy] = useState(false)

  const {id} = useParams()

  const sendRequest = () => {
    if (isBusy) return false;
    const url = `${API_URL}/trade-account/${id}/join`

    axios.post(url )
      .then((response) => {
        if (response.status === 200) {
          setSuccess(true)
        } else {
          setError(true)
        }
        setIsBusy(false);
      })
      .catch((err) => {
        setError(err.response?.data?.message)
        setIsBusy(false);
        throw err;
      });
  }


  useEffect(() => {
    sendRequest()
    // eslint-disable-next-line
  }, [])

  if (error!==false&&!success) {
    return <>
        <Container style={{minHeight:'50vh'}}>

          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <Box mt={6}/>
              <Typography variant="h1" style={{fontWeight: 300, fontFamily: 'Chronicle', fontSize: 32}}>
                There was an error sending the request. Please try again.
              </Typography>
            </Grid>
            <Grid item xs={12} style={{color: 'red'}}>
              {error!==true&&<>{error}</>}
            </Grid>
          </Grid>
        </Container>
    </>
  }

  if (!success) return <Container style={{minHeight:'50vh'}}> </Container>

  return (
    <>
      <Container style={{minHeight:'50vh'}}>

        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Box mt={6}/>
            <Typography variant="h1" style={{fontWeight: 300, fontFamily: 'Chronicle', fontSize: 32}}>
              Request Sent
            </Typography>
            <Box pt={3} style={{fontFamily: 'Chronicle', fontSize: 18}}>
              <p>We’ve sent an email to the account owner regarding your request and we will let you know once the owner approves the request.</p>
              <p>In the meantime, discover what’s new.</p>
            </Box>
          </Grid>

          <Grid item container>
            <Grid item xs={4}>
              <Button size='large' variant='contained' color='secondary' fullWidth href={'/new-arrivals'}>
                NEW ARRIVALS
              </Button>
            </Grid>
          </Grid>
        </Grid>

      </Container>
    </>
  );
};
const mapStateToProps = (state) => {
  const {error, loading, isAuth} = state.Auth;
  return {error, loading, isAuth};
};

export default connect(mapStateToProps, {apiError})(RequestToJoin);
