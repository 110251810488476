import {Collapse, Link} from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import Box from '@material-ui/core/Box'
import React from 'react'

const UPSSuggestionBox = ({validation, handleSuggestionSelect}) => {

  return <Collapse in={validation}>
    <Alert severity={validation?.isValid ? "success" : "warning"}>
      <Box display={"flex"} flexDirection={"column"}>
        {validation?.isValid
          ? "Address validated with UPS."
          : `Address could not be validated with UPS. ${validation.suggestions&&validation.suggestions.length!==0?`Did you mean?`:``}`}
        <Collapse
          in={
            validation?.isValid === false &&
            validation.suggestions&&validation.suggestions.length > 0
          }
        >
          <Box display={"flex"} flexDirection={"column"}>
            {validation.suggestions&&validation.suggestions
              .map((suggestion, index) => (
                <Link
                  href={"#"}
                  key={index}
                  onClick={() => handleSuggestionSelect(suggestion)}
                >
                  {suggestion.AddressLines[0]}
                </Link>
              ))}
          </Box>
        </Collapse>
      </Box>
    </Alert>
  </Collapse>
}

export default UPSSuggestionBox
