import { Box } from "@material-ui/core";
import Navigation from "../components/Core/AppBar/AppBar";
import ScrollToTop from "../components/ScrollToTop";
import TitleWrapper from "./TitleWrapper";
import ErrorBoundary from "../components/Core/ErrorBoundary";

function HeaderLayout({ title, children, isSticky }) {
  return (
    <TitleWrapper title={title}>
      <ScrollToTop />
      <Box
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Navigation isSticky={isSticky} />
        <ErrorBoundary>
          <Box style={{ flex: 1 }}>{children}</Box>
        </ErrorBoundary>
      </Box>
    </TitleWrapper>
  );
}

export default HeaderLayout;
