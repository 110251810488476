import {Container} from "@material-ui/core";
import ProductCatalog from "./ProductCatalog";
import {useParams} from 'react-router-dom'

function Admin(props) {
    const {page} = useParams();

    return (
        <Container>
            {page==='product-catalog'&&<ProductCatalog />}
        </Container>
    );
}

export default Admin;
