import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid, IconButton, Typography} from "@material-ui/core";
import {getStock, Spec, filterRelated} from "../ProductViews";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PinterestIcon from "@material-ui/icons/Pinterest";
import GetAppIcon from "@material-ui/icons/GetApp";
import {Favorite, FavoriteBorderOutlined} from "@material-ui/icons";
import {Link} from "react-router-dom";
import {ImagePicker} from "../ImagePicker/ImagePicker";
import {useCallback, useEffect, useMemo, useState} from "react";
import AttributePicker from "../AttributePicker/AttributePicker";
import PropTypes from "prop-types";
import Text from "../Text/Text";
import Button from "../Button/Button";
import {ProductViewer} from "../ProductViewer/ProductViewer";
import {capitalizeFirstLetter, readPriceCad, readPriceUsd} from "../../utils";
import {Skeleton} from "@material-ui/lab";
import {relationshipType} from "../../constants/enums";
import ContactUsDialog from "../ContactUsDialog";
import AnchorLink from "react-anchor-link-smooth-scroll";

const useStyles = makeStyles({
    root: {},
    image: {
        width: "100%",
        height: "auto",
    },
    row: {
        display: "flex",
        alignItems: "center",
    },
    actionButton: {
        // fontWeight: "bold",
        minWidth: 210,
        width: "max-content",
    },
    buttonLink: {
        fontWeight: "bold",
        marginLeft: 1,
    },
    specialBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "48px",
        border: "4px double #54585a",
    },
    smallButton: {
        height: "max-content",
    },
});

function ProductLinks({isLoading,image={imageUrl: ''},title,itemNumber}) {
    const classes = useStyles();
    if (isLoading) {
        return (
            <Box mt={2} display={"flex"} alignItems={"center"}>
                <Skeleton variant={"circle"} width={24} height={24}/>
                <Box mx={1}/>
                <Skeleton variant={"circle"} width={24} height={24}/>
                <Box flex={1}/>
                <Skeleton variant={"rect"} width={100} height={24}/>
                <Box mx={1}/>
                <Skeleton variant={"rect"} width={100} height={24}/>
            </Box>
        );
    }

    return (
        <Box mt={2} display={"flex"} alignItems={"center"}>
            <IconButton
            href={`mailto:?subject=Check this out from Schumacher!&body=Check out ${title} on Schumacher https://fschumacher.com/catalog/products/${itemNumber}`}
            >
                <MailOutlineIcon/>
            </IconButton>
            {image &&
            <IconButton style={{marginLeft: 8}}
                        onClick={(e)=>{
                        e.preventDefault()
                        window.open(`https://pinterest.com/pin/create/button/?url=https://fschumacher.com&media=${image?.largeUrl}&description=${title}`,'Add this pin | Schumacher' , 'width=400, height=400')}
                        }>
                <PinterestIcon/>
            </IconButton>
            }
            <Button
                size="small"
                className={classes.smallButton}
                style={{marginLeft: "auto"}}
                disabled={!image.largeUrl}
            >
                <a href={image?.largeUrl} target={'_blank'} rel={'noreferrer'} style={{textDecoration:'none'}}> <GetAppIcon style={{fontSize: 14, marginRight: 4}}/> Selected Image</a>
            </Button>
            {/*<Button*/}
            {/*  size="small"*/}
            {/*  className={classes.smallButton}*/}
            {/*  style={{ marginLeft: 8 }}*/}
            {/*>*/}
            {/*  Digital Memo*/}
            {/*</Button>*/}
        </Box>
    );
}

function ProductView(props) {
    const classes = useStyles();

    const {
        product,
        handleOrder,
        // handleReserve,
        handleGetMemo,
        // handleRequestQuote,
        memosAdded,
        productsAdded,
        handleAvailability,
        attributes,
        handleFavorite,
        // reserveAvailable,
        purchaseAvailable,
        orderAvailable,
        priceAvailable,
        sampleAvailable,
        // quoteAvailable,
        showDescription,
        showVariants,
        showAvailability,
        showHelp,
        onVariantChanged,
        isLoading,
        isAuth,
        canOrder,
        addingProduct,
        addingMemo,
        disableControls,
        priceBy
    } = props;

    const {name, itemNumber, designer} = product;
    const [selectedVariation, setSelectedVariation] = useState(0);
    const [activeSize, setActiveSize] = useState(0);
    const [openContact, setOpenContact] = useState(false);
    const relatedProducts = useMemo(() => product?.relatedProducts || [], [
        product?.relatedProducts,
    ]);
    const variants = relatedProducts.map((product) => product.thumbnailImageUrl);

    // Trigger the sign in popup
    const triggerSignIn = useCallback(() => {
        const signInButton = document.getElementById("signInButton");
        signInButton.click()
    }, [])
    const [selectedThumb,setSelectedThumb] = useState(0)
    const {
        masterSeries,
        // alsoAvailable,
        // similarTo,
        // sizeVariations
    } = useMemo(() => {
        return {
            masterSeries: filterRelated(relatedProducts, relationshipType.MASTER_SERIES),
            // alsoAvailable: filterRelated(relatedProducts, relationshipType.ALSO_AVAILABLE_IN),
            // similarTo: filterRelated(relatedProducts, relationshipType.SIMILAR_TO),
            // sizeVariations: filterRelated(relatedProducts, relationshipType.SIZE_VARIATION),
        };
    }, [relatedProducts]);

    const handleChangeVariant = (newIndex) => {
        setSelectedVariation(newIndex);
        onVariantChanged && onVariantChanged(relatedProducts[newIndex]);
    };

    useEffect(() => {
        const newIndex = masterSeries.findIndex((x) => x.itemNumber === itemNumber);
        if (newIndex !== -1) {
            setSelectedVariation(newIndex);
        }
    }, [itemNumber, masterSeries]);

    function renderProductInformation() {
        return (
            <Grid container direction="column">
                <Text variant="body1" style={{fontSize: 20}} color={"textSecondary"}>
                    {itemNumber}
                </Text>
                <div className={classes.row}>
                    <Text variant="h4">{name}</Text>
                    {isAuth && (
                        <IconButton
                            onClick={handleFavorite}
                            style={{
                                marginLeft: "auto",
                                color: !product.isFavorite ? "" : "#FFD700",
                            }}
                        >
                            {!product.isFavorite ? <FavoriteBorderOutlined/> : <Favorite/>}
                        </IconButton>
                    )}
                </div>
                <Box mt={3}/>
                {designer && (
                    <Text variant={"body1"}>
                        <Link style={{color: "inherit"}} to={`/designers/${designer.id}`}>
                            by {designer.name}
                        </Link>
                    </Text>
                )}
                <div className={classes.row}>
                    <Spec name="Color" value={product.colorName}/>
                    <Spec name="Type" value={product.category?.name}/>
                </div>
                {showVariants && variants && (
                    <Box mt={2}>
                        <ImagePicker
                            images={variants}
                            selectedIndex={selectedVariation}
                            setSelectedIndex={handleChangeVariant}
                        />
                    </Box>
                )}
                {showDescription && (
                    <Box mt={4}>
                        <Typography
                            variant="h4"
                            style={{fontSize: 20, fontWeight: "bold"}}
                        >
                            What Makes This Special
                        </Typography>
                        <Typography variant="body1">{product.description}</Typography>
                    </Box>
                )}
                {attributes && attributes[0] && (
                    <Box mt={4}>
                        <AttributePicker
                            setActiveOption={setActiveSize}
                            activeOption={activeSize}
                            attributes={attributes}
                        />
                    </Box>
                )}
                {isAuth && priceAvailable && product.priceUsd && (
                    <Box mt={4}>
                        <div>
                            <Text
                                variant="body1"
                                style={{fontSize: 20, fontWeight: "bold"}}
                            >
                                {readPriceUsd(product)} <span style={{
                                fontWeight: 'bold',
                                fontSize: 16
                            }}>{priceBy ? `per ${capitalizeFirstLetter(priceBy.toLowerCase())}` : ''}</span>
                            </Text>
                            {product.priceCad > 0 &&
                            <Text
                                variant="body1"
                                style={{fontSize: 14, fontWeight: "bold"}}
                            >or {readPriceCad(product)}
                                <span>{priceBy ? ` per ${capitalizeFirstLetter(priceBy.toLowerCase())}` : ''}</span></Text>
                            }
                        </div>
                    </Box>
                )}
                {isAuth && canOrder && orderAvailable && (
                    <Box mt={2}>
                        <Text variant="body1">
                            Minimum Order Quantity: <b>{product.quantityMinimum}</b>
                        </Text>
                        <Text variant="body1">
                            Order Increment: <b>{product.quantityIncrement}</b>
                        </Text>
                    </Box>
                )}
                <Box mt={2}/>
                {isAuth && getStock(product.quantityAvailable)}
                <Box mt={4}/>
                <Grid container spacing={2} direction={"column"}>
                    {isAuth && canOrder && sampleAvailable && (
                        <Grid item md={8} xs={12} lg={6}>
                            <Button
                                onClick={handleGetMemo}
                                className={classes.actionButton}
                                loading={addingMemo}
                                disabled={
                                    disableControls || memosAdded.quantityMaximum <= memosAdded
                                }
                                variant="contained"
                                size="large"
                                color="primary"
                            >
                                <Text noWrap variant={"inherit"} color={"inherit"}>
                                    {memosAdded > 0 ? `ADDED MEMO (${memosAdded})` : "GET MEMO"}
                                </Text>
                            </Button>
                        </Grid>
                    )}
                    {isAuth && canOrder && orderAvailable && (
                        <Grid item md={8} xs={12} lg={6}>
                            <Button
                                className={classes.actionButton}
                                onClick={handleOrder}
                                loading={addingProduct}
                                disabled={
                                    disableControls ||
                                    product.quantityMaximum <
                                    productsAdded + product.quantityIncrement
                                }
                                variant="contained"
                                size="large"
                                color="secondary"
                            >
                                <Text noWrap variant={"inherit"}>
                                    {productsAdded > 0
                                        ? `ADDED PRODUCTS (${productsAdded})`
                                        : "ORDER PRODUCT"}
                                </Text>
                            </Button>
                        </Grid>
                    )}
                    {/*{isAuth && quoteAvailable && (*/}
                    {/*  <Grid item md={8} xs={12} lg={6}>*/}
                    {/*    <Button*/}
                    {/*      onClick={handleRequestQuote}*/}
                    {/*      className={classes.actionButton}*/}
                    {/*      disabled={disableControls}*/}
                    {/*      size="large"*/}
                    {/*    >*/}
                    {/*      REQUEST A QUOTE*/}
                    {/*    </Button>*/}
                    {/*  </Grid>*/}
                    {/*)}*/}
                    {/*{isAuth && reserveAvailable && !orderAvailable && (*/}
                    {/*  <Grid item md={8} xs={12} lg={6}>*/}
                    {/*    <Button*/}
                    {/*      onClick={handleReserve}*/}
                    {/*      size="large"*/}
                    {/*      disabled={disableControls}*/}
                    {/*      color="primary"*/}
                    {/*    >*/}
                    {/*      <BookmarkBorder />*/}
                    {/*      RESERVE PRODUCT*/}
                    {/*    </Button>*/}
                    {/*  </Grid>*/}
                    {/*)}*/}
                    {!isAuth && (
                        <Box>
                            <Text style={{marginBottom: 8}}>
                                To see pricing and place orders
                            </Text>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                onClick={triggerSignIn}
                                className={classes.actionButton}
                                fullWidth
                                size="large"
                            >
                                SIGN IN
                            </Button>
                        </Box>
                    )}
                </Grid>
                <Box mt={4}/>
                {isAuth && showAvailability && purchaseAvailable && (
                    <AnchorLink
                        offset={'10'}
                        href={`#availability`}
                        style={{textDecoration:'none'}}
                    >
                    <InfoButton
                        onClick={handleAvailability}
                        title={"Need it fast?"}
                        actionName={"CHECK AVAILABILITY"}
                    />
                    </AnchorLink>
                )}
                {isAuth && showHelp && (
                    <InfoButton
                        onClick={() => setOpenContact(true)}
                        title={"Need help with your order?"}
                        actionName={"CONTACT US"}
                    />
                )}
            </Grid>
        );
    }

    function renderInfoLoader() {
        return (
            <Grid container direction="column">
                <Skeleton variant={"text"} width={150} height={24}/>
                <div className={classes.row}>
                    <Skeleton variant={"text"} width={280} height={48}/>
                    <IconButton onClick={handleFavorite} style={{marginLeft: 16}}>
                        <Skeleton variant={"circle"} width={36} height={36}/>
                    </IconButton>
                </div>
                <Box mt={3}/>
                <div className={classes.row}>
                    <Skeleton variant={"text"} width={80} height={26}/>
                    <Box mx={1}/>
                    <Skeleton variant={"text"} width={80} height={26}/>
                    <Box mx={1}/>
                    <Skeleton variant={"text"} width={120} height={26}/>
                </div>
                {showVariants && (
                    <Box mt={1}>
                        <ImagePicker isLoading={true} loadingItems={8}/>
                    </Box>
                )}
                {showDescription && (
                    <Box mt={4}>
                        <Skeleton variant={"text"} width={280} height={26}/>
                        <Box mt={1}/>
                        <Skeleton variant={"rect"} width={"100%"} height={120}/>
                    </Box>
                )}
                <Box mt={3}/>
                <Grid container direction={"column"} spacing={2}>
                    <Grid item>
                        <Skeleton
                            variant={"rect"}
                            style={{borderRadius: 30}}
                            height={40}
                            width={220}
                        />
                    </Grid>
                    <Grid item>
                        <Skeleton
                            variant={"rect"}
                            style={{borderRadius: 30}}
                            height={40}
                            width={220}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
                <ProductViewer
                    isLoading={isLoading}
                    images={product.images || []}
                    direction="column-reverse"
                    setSelectedThumb={setSelectedThumb}
                />
                <ProductLinks isLoading={isLoading} image={product && product.images && product?.images[selectedThumb]} title={product.name} itemNumber={product.itemNumber}/>
            </Grid>
            <Grid item md={6} xs={12}>
                {isLoading ? renderInfoLoader() : renderProductInformation()}
            </Grid>
            <ContactUsDialog
                open={openContact}
                handleClose={() => setOpenContact(false)}
            />
        </Grid>
    );
}

function InfoButton({onClick, title, actionName, ...rest}) {
    const classes = useStyles();

    return (
        <div style={{display: "flex", alignItems: "center"}}>
            <Text component="p">{title}</Text>
            <Button {...rest} onClick={onClick} className={classes.buttonLink} color="primary">
                {actionName}
            </Button>
        </div>
    );
}

ProductView.propTypes = {
    product: PropTypes.object.isRequired,
    attributes: PropTypes.array,
    memosAdded: PropTypes.number,
    productsAdded: PropTypes.number,
    handleGetMemo: PropTypes.func,
    handleOrder: PropTypes.func,
    handleReserve: PropTypes.func,
    handleRequestQuote: PropTypes.func,
    handleAvailability: PropTypes.func,
    handleFavorite: PropTypes.func,
    showAvailability: PropTypes.bool,
    showHelp: PropTypes.bool,
    showDescription: PropTypes.bool,
    showVariants: PropTypes.bool,
    isLoading: PropTypes.bool,
    reserveAvailable: PropTypes.bool,
    purchaseAvailable: PropTypes.bool,
    orderAvailable: PropTypes.bool,
    priceAvailable: PropTypes.bool,
    sampleAvailable: PropTypes.bool,
    quoteAvailable: PropTypes.bool,
    onVariantChanged: PropTypes.func,
    isAuth: PropTypes.bool,
    addingMemo: PropTypes.bool,
    addingProduct: PropTypes.bool,
    disableControls: PropTypes.bool,
};

ProductView.defaultProps = {
    product: {},
    isLoading: false,
    showDescription: false,
    showAvailability: true,
    showHelp: true,
    showVariants: true,
    reserveAvailable: false,
    purchaseAvailable: false,
    orderAvailable: false,
    priceAvailable: false,
    sampleAvailable: false,
    quoteAvailable: false,
};

export default ProductView;
