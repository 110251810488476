import {makeStyles} from "@material-ui/core/styles";
import {
    Box,
    Container,
    Grid,
    Typography,
    Avatar,
    Divider,
} from "@material-ui/core";
import Text from "components/Text/Text";

import FooterSignup from "components/Footer/SignUp";
import FooterSocialMedia from "components/Footer/SocialMedia";
import FooterWidget from "components/Footer/Widget";
import DeliveryIcon from "assets/icons/delivery.png";
import CustomizationIcon from "assets/icons/customization.png";
import FlameIcon from "assets/icons/flame.png";
import PaperIcon from "assets/icons/paper.png";
import ShoppingIcon from "assets/icons/shopping.png";
import WaterIcon from "assets/icons/water.png";
import {Link} from "react-router-dom";
import {getRoles} from "../../utils";
import {userRoles} from "../../constants/enums";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.black,
    },
    searchBox: {
        display: "flex",
        width: 341,
        marginTop: 25,
        alignItems: "center",
        border: "1px solid white",
        borderRadius: 30,
        paddingLeft: theme.spacing(1),
        position: "relative",
    },
    searchButton: {
        background: theme.palette.headerColor,
        borderRadius: 30,
        width: 142,
        // marginLeft: 8,
        marginLeft: "auto",
    },
    label: {
        fontFamily: "Fredoka One, cursive",
    },
    media: {
        width: 200,
        height: 200,
    },
    card: {
        width: 200,
        height: 200,
        background: "transparent",
        borderRadius: 0,
        marginRight: 30,
    },
    subtitle: {
        fontFamily: "Chronicle",
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: "20px",
        lineHeight: "140%",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        letterSpacing: "0.0125em",
        color: "#FFFFFF",
        textTransform: "uppercase",
    },
    container: {
        marginTop: 100,
        color: "#fff",
    },
    large: {
        width: theme.spacing(15),
        height: theme.spacing(15),
    },
    footerH5: {
        marginTop: 30,
        color: "white",
        textAlign: "left",
        fontFamily: "Chronicle",
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: "20px",
        lineHeight: "140%",
        letterSpacing: "0.0125em",
        textDecoration: "none",
    },
    footerH6: {
        marginTop: 10,
        color: "white",
        fontFamily: "Gotham",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "125%",
        letterSpacing: "0.01em",
        textAlign: "left",
        textDecoration: "none",
    },
}));

const services = [
    {title: "Personal Shopping", icon: ShoppingIcon},
    {title: "Fast-Than-Ever Delivery", icon: DeliveryIcon},
    {title: "Water & Stain Protectants", icon: WaterIcon},
    {title: "Flame Resistance", icon: FlameIcon},
    {title: "Fabric- and Paper-Backing Options", icon: PaperIcon},
    {title: "Bespoke Customization", icon: CustomizationIcon},
];

const footerLeft = [
    {
        text: "Inside Schumacher",
        size: "h5",
    },
    {
        text: "Our Story",
        size: "h6",
        // link:'/pages/our-heritage'
        link: '/our-story'
    },
    {
        text: "Our Mission",
        size: "h6",
        link: "/pages/our-mission",
    },
    {
        text: "Blog",
        size: "h6",
        link: "/pages/blog",
    },
    // {
    //     text: "F. Schumacher & Co.",
    //     size: "h6",
    //     link: "/pages/fschumacher-co",
    // },
    {
        text: "Careers",
        size: "h6",
        href:'https://schumacher.recruitee.com/'
    },
    {
        text: "Privacy Policy",
        size: "h6",
        link:'/pages/privacy'
    },
    {
        text: "Terms and Conditions",
        size: "h6",
        link:'/pages/terms'
    },
    {
        text: "Community",
        size: "h5",
    },
    {
        text: "Collaborators",
        size: "h6",
        link: "/collaborators",
    },
    {
        text: "Designer Highlights",
        size: "h6",
        link: "/pages/designer-highlights",
    },
    {
        text: "Find a Designer",
        size: "h6",
        link:'/pages/find-designer'
    },
    {
        text: "Webinars & Events",
        size: "h6",
        href: "https://www.youtube.com/playlist?list=PLGoqBioaSO6OHD5WsBrZpDhKqU3CpoQow",
    },
    {
        text: "Get Inspired",
        size: "h5",
    },
    {
        text: "Deep Dive with Dara",
        size: "h6",
        href: "https://www.youtube.com/playlist?list=PLGoqBioaSO6NFKP-AVcCIHxGNTYmLa2Fw",
    },
    {
        text: "Schumacher House Tours",
        size: "h6",
        href: "https://www.youtube.com/playlist?list=PLGoqBioaSO6PxNgFg9im4Nr_jdcr9cWR4",
    },
    {
        text: "Federic Magazine",
        size: "h6",
        href: "https://fredericmagazine.com/",
    },

];

const footerRight = [
    {
        text: "To Trade",
        size: "h5",
    },
    {
        text: "Trade Program",
        size: "h6",
        link:'/pages/trade-program'
    },
    {
        text: "Hospitality & Contract",
        size: "h6",
        link: "/pages/hospitality",
    },
    {
        text: "We’re Here to Help",
        size: "h5",
    },
    {
        text: "Contact Us",
        size: "h6",
        link: "/contact-us",
    },
    {
        text: "Our Showroom",
        size: "h6",
        link:'/pages/our-showroom'
    },
    {
        text: "FAQs",
        size: "h6",
        link: "/faq",
    },
    // {
    //     text: "Shipping",
    //     size: "h6",
    //     link: "/pages/shipping",
    // },
    {
        text: "Decorating Glossary",
        size: "h6",
        link: "/decorating-glossary",
    },
    {
        text: "Returns & Exchanges",
        size: "h6",
        link: "/pages/returns",
    },
    {
        text: "Resources",
        size: "h5",
    },
    {
        text: "Product Catalogs",
        size: "h6",
        href: "https://shop.fredericmagazine.com/products/the-schumacher-library-of-wallcoverings-fourth-edition?variant=39535690285218",
    },
    {
        text: "Lookbooks",
        size: "h6",
        link: "/pages/lookbooks",
    },
];

function Service({label, serviceIcon}) {
    const classes = useStyles();
    return (
        <Box
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Avatar
                className={classes.large}
                style={{backgroundColor: "transparent"}}
            >
                <img src={serviceIcon} height={"100%"} alt={label}/>
            </Avatar>
            <Typography
                style={{
                    marginTop: 10,
                    fontFamily: "Chronicle",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: "20px",
                    lineHeight: "140%",
                    letterSpacing: "0.0125em",
                    textAlign: "center",
                    maxWidth: 220,
                    color: "#fff",
                }}
            >
                {label}
            </Typography>
        </Box>
    );
}

function Footer() {
    const classes = useStyles();
    const role = getRoles()[0];

    function filterRole(x) {
        if (x.showType === "webUser") {
            return role === userRoles.WEB_USER;
        }
        return x;
    }

    return (
        <Box py={6} mt={8} className={classes.root}>
            <Typography variant="h4" style={{textAlign: "center", color: "#fff"}}>
                At Your Service
            </Typography>
            <Typography
                className={classes.subtitle}
                variant="h5"
                style={{textAlign: "center", marginTop: 8}}
            >
                WE’VE GOT YOU COVERED
            </Typography>
            <Box mt={4}/>
            <Container maxWidth="xl">
                <Grid container justify="space-between">
                    {services.map((service, i) => (
                        <Grid item key={`service-${service.title}`} component={Link} to={`/services/#${service.title.toLowerCase().split(' ').join('-')}`}>
                            <Service
                                label={service.title}
                                serviceIcon={service.icon}
                                key={`service-${i}`}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Box mt={4}/>
                <Divider style={{background: "#fff"}}/>
            </Container>
            <Container className={classes.container}>
                <Grid container justify="space-between">
                    <Grid item lg="6" md="6" xs="12">
                        <FooterSignup/>
                        <FooterSocialMedia style={{marginTop: 40}}/>
                        <FooterWidget/>
                    </Grid>
                    <Grid style={{display: "flex"}} item lg="6" md="6" xs="12">
                        <Grid item lg="6" md="6" xs="12">
                            {footerLeft.filter(filterRole).map(({text, size, link, href}) => (
                                <Text
                                    gutterBottom
                                    variant={size}
                                    className={
                                        size === "h5" ? classes.footerH5 : classes.footerH6
                                    }
                                >
                                    {link ? (
                                        <Link
                                            to={link}
                                            className={
                                                size === "h5" ? classes.footerH5 : classes.footerH6
                                            }
                                        >
                                            {text}
                                        </Link>
                                    ) : (
                                        href ? (
                                                <a href={href} rel="noreferrer" className={
                                                    size === "h5" ? classes.footerH5 : classes.footerH6
                                                } target={'_blank'}>{text}</a>
                                            ) :
                                            (text)
                                    )}
                                </Text>
                            ))}
                        </Grid>
                        <Grid item lg="6" md="6" xs="12">
                            {footerRight.map(({text, link, size,href}) => (
                                <Text
                                    gutterBottom
                                    variant={size}
                                    className={
                                        size === "h5" ? classes.footerH5 : classes.footerH6
                                    }
                                >
                                    {link ? (
                                        <Link
                                            to={link}
                                            className={
                                                size === "h5" ? classes.footerH5 : classes.footerH6
                                            }
                                        >
                                            {text}
                                        </Link>
                                    ) : (href ? (
                                                <a href={href} rel="noreferrer" className={
                                                    size === "h5" ? classes.footerH5 : classes.footerH6
                                                } target={'_blank'}>{text}</a>
                                            ) :
                                            (text)
                                    )}
                                </Text>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Box mt={4}/>
            </Container>
        </Box>
    );
}

export default Footer;
