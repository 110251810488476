import React from "react";
import { Container } from "@material-ui/core";
import Feature from "../../components/Feature";
import FeatureImage1 from "../../assets/images/Editorial-Slots/Editorial-Slot-1.jpg";
import FeatureImage2 from "../../assets/images/Editorial-Slots/Editorial-Slot-2.jpg";

function Features(props) {
  return (
    <Container>
      <Feature
        image={FeatureImage1}
        title="Why Scenic Murals Should Be in Your Designer Toolkit"
        description="The tradition of deploying narrative murals to invigorate a space can be traced back at least to the ancient city of Pompeii. More than two thousand years later, interior decorators still turn to this potent medium for everything from bringing tiny powder rooms to life to turning living rooms into sweeping fantastical, escapes."
        cta={"Designer Tips Ahead >"}
        href={
          "https://fredericmagazine.com/2020/12/scenic-wallcoverings-panoramic-narrative-wall-murals-iksel/"
        }
      />
      <Feature
        direction="row-reverse"
        image={FeatureImage2}
        title="Inside the Magical World of Artist Colette Cosentino"
        description="In her studio in Santa Barbara, California, the painter Colette Cosentino creates enormous works full of dreaminess and wonder. Here, we peer into the studio doors for a look at the brilliant woman behind the brush."
        cta={"Read About Her Dreamy Murals Here"}
        href={"https://fredericmagazine.com/2021/06/colette-cosentino-painter/"}
      />
    </Container>
  );
}

export default Features;
