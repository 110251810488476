import {entityType} from "./module";
import {createDeleteMethod, createGetAllMethod, createPostMethod} from "reactcoregk/store/actions";
import {getDefaultHeaders} from "../../utils";
import ApiHandler from "reactcoregk/models/apiHandler";
import {API_URL} from "../../config";

const getAllApiHandler = (tradeAccountId) => {
    const endpoint = `${API_URL}/trade-account/${tradeAccountId}/payment-methods`
    return new ApiHandler(false, true, getDefaultHeaders(), "default", "", endpoint)
}

const createApiHandler = (tradeAccountId) => {
    const endpoint = `${API_URL}/trade-account/${tradeAccountId}/payment-methods`
    return new ApiHandler(false, false, getDefaultHeaders(), "default", "", endpoint)
}

const deleteApiHandler = (tradeAccountId) => {
    const endpoint = `${API_URL}/trade-account/${tradeAccountId}/payment-methods`
    return new ApiHandler(false, false, getDefaultHeaders(), "default", "", endpoint)
}

export const getAllPaymentMethods = (tradeAccountId) => createGetAllMethod(entityType, "", getAllApiHandler(tradeAccountId))
export const createPaymentMethod = (payload, tradeAccountId) => createPostMethod(entityType, payload, createApiHandler(tradeAccountId))
export const deletePaymentMethod = (payload, tradeAccountId) => createDeleteMethod(entityType, payload, deleteApiHandler(tradeAccountId))

