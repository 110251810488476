import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import ContactDetails from 'pages/Admin/Account/common/ContactDetails'



const ContactInformation = ({addresses}) => {
  const {shippingAddress, billingAddress} = addresses

  return  <Grid item container spacing={2}>

    <Grid item xs={12} md={12}>
      <Box fontSize={18} fontWeight={'bold'}>Contact Information</Box>
    </Grid>

    <Grid item xs={12} md={5}>
      <Box fontWeight={'bold'}>Primary Billing Address</Box>
    </Grid>

    <ContactDetails contact={billingAddress} />

    <Grid item xs={12} md={5}>
      <Box fontWeight={'bold'}>Default Shipping Address</Box>
    </Grid>

    <ContactDetails contact={shippingAddress} />

  </Grid>
}

export default ContactInformation
