import PropTypes from "prop-types";
import { Button as MuiButton, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import c from "classnames";

const useStyles = makeStyles({
  button: {
    display: "inline-flex",
    alignItems: "center",
  },
  label: {
    fontWeight: "bold",
  },
});

const Button = (props) => {
  const { children, className, loading, disabled, ...rest } = props;
  const classes = useStyles(props);

  return (
    <MuiButton
      className={c(classes.button, className)}
      endIcon={loading && <CircularProgress size={10} />}
      disabled={loading || disabled}
      classes={{ label: classes.label }}
      {...rest}
    >
      {children}
    </MuiButton>
  );
};

Button.propTypes = {
  children: PropTypes.element,
  onClick: PropTypes.func,
  className: PropTypes.string,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "outlined", "contained"]),
  color: PropTypes.oneOf(["primary", "secondary"]),
};

Button.defaultProps = {
  size: "medium",
  variant: "text",
};

export default Button;
