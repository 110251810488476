import AccountProfileLayout from "../components/AccountProfileLayout";

const Wallet = (props) =>{
    return <AccountProfileLayout
        title={'Wallet'}
        {...props}>
        Wallet
    </AccountProfileLayout>
}

export default Wallet