import { useSnackbar } from "notistack";
import { useEffect } from "react";

export const useAlert = (message, variant) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
      if (message) {
          enqueueSnackbar(message, { variant });
      }
  }, [enqueueSnackbar, message, variant]);

  return [closeSnackbar]
};
