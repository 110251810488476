import {ErrorMessage, useField} from "formik";
import {Box, Checkbox, FormControlLabel, withStyles} from '@material-ui/core'
import {useContext, useState} from 'react'
import {FormikContext} from "components/FormikDisabler";
import WarnEditOverlay from 'components/Core/FormikFields/WarnEditOverlay'
import FormControl from '@material-ui/core/FormControl'

const CheckboxWithYellowCheck = withStyles({
  root: {
    "&$checked": {
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
        color: "#FFD700"
      },      "& .MuiIconButton-label:after": {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "#FFD700",
        zIndex: -1
      }

    }
  },
  checked: {}
})(Checkbox);

const FormikCheckbox = (props) => {
  const { required = false, warnEdit} = props;
  const [field, meta] = useField(props.name);
  const {disabled, locked} = useContext(FormikContext)
  const [showWarning, setShowWarning] = useState(null)
  const isDisabled = props.disabled || disabled

  return (<FormControl fullWidth style={{position:'relative'}}>
      {(warnEdit||locked)&&!isDisabled&&<WarnEditOverlay showWarning={showWarning} setShowWarning={setShowWarning} />}
      <FormControlLabel
          control={
              <CheckboxWithYellowCheck
                  color="primary"
                  {...field}
                  {...props}
                  {...props.rest}
                  checked={!!field.value}
                  error={meta.error && meta.touched}
                  // helperText={meta.error}
                  required={required}
                  disabled={disabled||props.disabled}
              />
          }
          label={props.label}
      >

      </FormControlLabel>
    <ErrorMessage name={field.name} render={msg => <Box pl={1} pt={1} style={{textTransform:'uppercase',color:'red'}}>{msg}</Box>} />
    </FormControl>
)}

export default FormikCheckbox;
