import {Box, Grid, MenuItem, Select, TableCell} from '@material-ui/core'
import PropTypes from "prop-types";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles} from "@material-ui/styles";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FormControl from '@material-ui/core/FormControl'
import CloseIcon from '@material-ui/icons/Close';
// import {StickySub} from 'hooks/sticky'
import React from 'react'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    borderRadius: 0,
  },
  th: {
    backgroundColor: '#5A5B5C',
    textTransform: 'uppercase',
    color: '#fff',
    paddingTop: 8,
    paddingBottom: 8
  },
  row: {
    borderBottom: 'none',
    boxSizing: 'border-box',
    background: 'none'
  },
  rowHover: {
    position: 'relative',
    // cursor: 'pointer',
    // border: '1px solid rgba(0,0,0,0)',
    boxSizing: 'border-box',
    '&:hover': {
      // border: '1px solid red !important',
      background: '#f6f6f6'
    },
    '&:hover #actions': {
      display: 'flex'
    }
  },
  action: {
    display: 'none',
    position: 'absolute',
    top: 0,
    right: '2px',
    height: '100%',
    border: '1px solid #cdcdcd',
    width: 300,
    boxSizing: 'border-box',
    overflow: 'hidden',
    background: 'white'
  },
  formControl: {
    // overflow:'hidden'
    // minWidth: 120,
  },
  selectEmpty: {
    marginTop: 16,
  },
  filter: {
    color: 'white',
    padding: 0,
    border: 'none',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&:focus': {
      backgroundColor: 'initial'
    }
  },
  filterOutline: {
    border: 'none',
  },
  arrowIcon: {
    display: 'none'
  }
});

// TODO: Document sorting, mapping, etc.

function DataTable(props) {

  const {data, mapping, isLoading, order, setOrder, filters, setFilters, rowAction, rowClick=false, headerCellAttributes={}} = props;
  const classes = useStyles();

  const changeOrder = (field) => {
    const fieldOrder = order.findIndex((f)=>f.field===field)
    if (fieldOrder!==-1) {
      const newOrder = [...order]
      newOrder[fieldOrder].asc = !newOrder[fieldOrder].asc
      setOrder(newOrder)
    } else {
      setOrder([...order, {'field':field, asc: true}])
    }
  }

  const handleFilter = (key, value) => {
    setFilters({...filters, [key]:value})
  }

  // const { ref, isStickySub, headerShown } = StickySub(true, true);

  return (
      <TableContainer style={{position:'relative', minHeight:350}}>
        <Table className={classes.table}>
            {/*<div*/}
            {/*  position="static"*/}
            {/*  className={[*/}
            {/*    isStickySub ? "sticky" : "",*/}
            {/*    headerShown ? "stickyWithHeader" : "",*/}
            {/*  ].join(" ")}*/}
            {/*  ref={ref}*/}
            {/*>*/}
            <TableHead>
              <TableRow>
                {mapping.map((cell)=>{
                  const fieldOrder = order&&order.length!==0&&order.find((f)=>f.field===cell.key)

                  return <TableCell {...cell.attributes} classes={{head:classes.th}} {...headerCellAttributes} onClick={()=>{
                    if (fieldOrder&&!cell.filters) changeOrder(cell.key)
                  }}>

                    <Grid item container style={{cursor:fieldOrder?'pointer':'default'}} alignContent='center' alignItems='center'>
                      <Grid item xs={(cell.filters||fieldOrder)?10:12} style={{overflow:'hidden'}}>

                        {cell.filters&&<>
                          <FormControl className={classes.formControl}>
                            <Select
                              value={filters[cell.key]?filters[cell.key]:''}
                              onChange={(e)=>handleFilter(cell.key, e.target.value)}
                              displayEmpty
                              classes={{root:classes.filter, outlined: classes.filterOutline, icon:classes.arrowIcon,}}
                              disableUnderline
                            >
                              <MenuItem value="">
                                {cell.label}
                              </MenuItem>
                              {cell.filters.map((f)=><MenuItem value={f}>{f}</MenuItem>)}
                            </Select>
                          </FormControl>
                        </>}

                        {!cell.filters&&<>
                          {cell.label}
                        </>}

                      </Grid>

                      {(cell.filters||fieldOrder)&&<Grid item xs={2}>
                        {cell.filters&&<Box mt={0.7}>
                          {filters&&filters[cell.key]&&filters[cell.key]!==''?<> <CloseIcon onClick={()=>{ setFilters({...filters, [cell.key]:''}) }} style={{cursor:'pointer'}} /> </>:<KeyboardArrowDownIcon />}
                      </Box>}

                        {fieldOrder&&!cell.filters&&<Box mt={0.7} zIndex={9999}>
                          {fieldOrder.asc?<>
                            <KeyboardArrowUpIcon />
                          </>:<>
                            <KeyboardArrowDownIcon />
                          </>}
                        </Box>}

                      </Grid>}

                    </Grid>

                  </TableCell>
                })}
              </TableRow>
            </TableHead>
          {/*</div>*/}
          <TableBody>
            {data&&data.map((row)=>{

              const RowAction = rowAction

                return <TableRow key={row.name} {...row.attributes} classes={{root:classes.rowHover}} style={{cursor: rowClick?'pointer':'default'}} onClick={()=>rowClick&&rowClick(row)}>
                  {mapping.map((cell)=>{

                    let value = ''

                    if (cell.key.includes('.')) {
                      const keys = cell.key.split('.')
                      value = row
                      keys.forEach((key)=>{
                        value = value[key]
                      })
                    } else {
                      value = row[cell.key]
                    }

                    if (cell.transform) {
                      value = cell.transform(value,row)
                    }

                    return <TableCell {...cell.attributes} classes={{root:classes.row}}>
                      {value}
                    </TableCell>
                  })}

                  {rowAction&&<Box className={classes.action} id="actions">
                      <RowAction row={row} />
                  </Box>}

                </TableRow>
              })
            }
          </TableBody>
        </Table>

        {data.length===0&&!isLoading&&
        <Box minHeight={350} width='100%' justifyContent='center' alignContent='center' alignItems='center' justifyItems='center' display='flex'>
          <Box display='flex' fontSize={20}>No data available </Box>
        </Box>}

        {isLoading&&<Box style={{backgroundColor:'rgba(255,255,255,0.7)'}} height='100%' width='100%' justifyContent='center' alignContent='center' alignItems='center' justifyItems='center' display='flex' position={'absolute'} left={0} top={0}>
          <Box display='flex' fontSize={20}> Loading... </Box>
        </Box>}

      </TableContainer>
  );
}

export default DataTable;

DataTable.defaultProps = {

};

DataTable.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object,
  mapping: PropTypes.object
};
