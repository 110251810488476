import {motion} from "framer-motion";
import PropTypes from "prop-types";
import React from "react";

export function TabPanel(props) {
    const { children, value, index, animation, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{ flex: 1 }}
            {...other}
            className="wrapper"
        >
            {value === index && (
                <motion.div
                    variants={animation}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    {children}
                </motion.div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    animation: PropTypes.object.isRequired
};
