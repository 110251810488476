import {Fragment, useCallback, useEffect, useState} from "react";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import ProductDialog from "../Catalog/ProductDialog";
import { connect } from "react-redux";
import ProductView from "../../components/ProductView/ProductView";
import ProductDetails from "../../components/ProductDetails/ProductDetails";
import {
  useProduct,
  useProductCart,
  useProductInfo,
  useProductRules,
} from "../../hooks/product";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { useTitle } from "../../hooks/router";
import { useFavorites } from "../../hooks/favorites";
import {fetchData} from "../../utils";
// import { filterRelated } from "../../components/ProductViews";
// import { relationshipType } from "../../constants/enums";
import ProductCardWithHooks from "../../components/Cards/ProductCard/ProductCartWithHooks";
import {API_URL} from "../../config";
// import {ApiEndpoint} from "../../store/@core/endpoint";
// import {EntityType} from "../../store/@core/entityType";
import {useIsAuth, useProfileIds} from "../../hooks/auth";

function ProductPage(props) {
  const [openPreview, setOpenPreview] = useState(false);
  const { history, context } = props;
  const { canOrder } = context.Auth;
  const itemNumber = props.match.params.itemNumber;
  const isAuth = useIsAuth()
  const { product, isLoading } = useProduct(itemNumber, isAuth);
  const {
    details,
    availability,
    hospitality,
    specialAttributes,
  } = useProductInfo(product);

  const rules = useProductRules(product);
  const [favoriteProduct, setFavoriteProduct] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});
  const {activeUserId} = useProfileIds()

  // const relatedProducts = useMemo(() => product?.relatedProducts || [], [
  //   product?.relatedProducts,
  // ]);

  // const similarToProducts = useMemo(
  //   () => filterRelated(relatedProducts, relationshipType.SIMILAR_TO),
  //   [relatedProducts]
  // );
  const [openAvailability,setOpenAvailability] = useState(false)
  const [similarToProducts,setSimilarToProducts] = useState([])
  useEffect(() => {
    (async () => {
      const relatedUrl = isAuth
          ? `${API_URL}/users/${activeUserId}/products/${itemNumber}/related`
          : `${API_URL}/products/${itemNumber}/related`;
      try{
        const response = await fetchData(relatedUrl);
        setSimilarToProducts(response)
      }
      catch (e)
      {
        console.log(e)
      }
    })()
  }, [activeUserId, isAuth,itemNumber])

  useFavorites(favoriteProduct, null, setFavoriteProduct, activeUserId);

  useTitle(product?.name || itemNumber);

  const {
    memosAdded,
    productsAdded,
    addingMemo,
    addingProduct,
    updating,
    handleAddMemo,
    cart,
    handleAddProduct,
  } = useProductCart(product);

  const handlePreview = useCallback((product) => {
    setSelectedProduct(product);
    setOpenPreview(true);
  }, []);

  const onVariantChanged = (relatedProduct) => {
    history.push(`/catalog/products/${relatedProduct.itemNumber}`);
  };

  const path = product?.category
    ? [
        {
          name: product.category.name,
          path: `/catalog/${product.category.id}`,
        },
        { name: product.name, path: "#" },
      ]
    : [];

  return (
    <Container>
      <Box mt={4} />
      <Breadcrumbs path={path} />
      <Box mt={2} />
      <Grid container spacing={4} alignItems={"flex-start"}>
        <Grid item style={{ flex: 1, position: "relative" }}>
          <ProductView
            onVariantChanged={onVariantChanged}
            isLoading={isLoading}
            handleGetMemo={handleAddMemo}
            handleOrder={handleAddProduct}
            product={product}
            isAuth={isAuth}
            canOrder={canOrder}
            memosAdded={memosAdded}
            productsAdded={productsAdded}
            addingMemo={addingMemo}
            addingProduct={addingProduct}
            disableControls={updating}
            handleFavorite={() => setFavoriteProduct(product)}
            handleAvailability={()=>setOpenAvailability(true)}
            {...rules}
          />
          <ProductDetails
            details={details}
            availability={availability}
            collaborator={product.collaborator}
            specialText={product.description}
            hospitality={hospitality}
            specialAttributes={specialAttributes}
            openAvailability={openAvailability}
            setOpenAvailability={setOpenAvailability}
          />
        </Grid>
      </Grid>
      {similarToProducts && (
        <Fragment>
          <Box mt={8} mb={2}>
            <Typography variant="h4" style={{ textAlign: "center" }}>
              You May Also Like
            </Typography>
          </Box>
          <Grid container spacing={4}>
            {similarToProducts.map((product, i) => (
              <Grid item lg={2} md={3} sm={4} xs={12}>
                <ProductCardWithHooks
                  product={product}
                  handlePreview={handlePreview}
                  handleMemo={handleAddMemo}
                  activeCart={cart}
                  size={"auto"}
                  toggleFavorite={() => setFavoriteProduct(product)}
                />
              </Grid>
            ))}
          </Grid>
        </Fragment>
      )}
      <ProductDialog
        open={openPreview}
        handleClose={() => setOpenPreview(false)}
        itemNumber={selectedProduct?.itemNumber}
        products={similarToProducts}
      />
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      Cart: state.Cart,
      Auth: state.Auth,
    },
  };
};

export default connect(mapStateToProps, {})(ProductPage);
