import { Box, Button, Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

export default function AttributePicker(props) {
  const { attributes, activeOption, setActiveOption } = props;
  return (
    <Box>
      <Typography variant="body1">
        <b>Select a Size</b>
      </Typography>
      <Box mt={1} />
      <Grid container spacing={2}>
        {attributes.map((size, i) => (
          <Grid item key={i}>
            <Button
              size="large"
              style={{ width: 110 }}
              onClick={() => setActiveOption(i)}
              color="primary"
              variant={activeOption === i ? "contained" : "outlined"}
            >
              {size.toUpperCase()}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
AttributePicker.defaultProps = {
  activeOption: 0,
  attributes: [],
};

AttributePicker.propTypes = {
  activeOption: PropTypes.number.isRequired,
  setActiveOption: PropTypes.func.isRequired,
  attributes: PropTypes.arrayOf(PropTypes.string).isRequired,
};
