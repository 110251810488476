import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Box, Collapse, Dialog as MuiDialog } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import BusyIndicator from "../Core/BusyIndicator";
import Text from "../Text/Text";
import ErrorBoundary from "../Core/ErrorBoundary";

const spacing = 6;

const styles = (theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(2),
    // paddingLeft: theme.spacing(spacing),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    fontSize: 24,
  },
  dialog: {
    MuiDialog: {
      backdropFilter: {
        filter: "blur(3px)",
      },
    },
  },
});

const useStyles = makeStyles({
  actionButton: {
    flex: 1,
    minHeight: 44,
    wordWrap: "no-wrap",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, hideClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Text variant="h4" className={classes.title}>
        {children}
      </Text>
      {onClose && !hideClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(spacing),
    position: "relative",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(spacing),
  },
}))(MuiDialogActions);

function Dialog(props) {
  const dialogClasses = useStyles();
  const {
    open,
    onClose,
    title,
    maxWidth,
    fullWidth,
    primaryAction,
    primaryActionTitle,
    primaryActionColor,
    primaryActionVariant,
    primaryActionDisabled = false,
    secondaryAction,
    secondaryActionColor,
    secondaryActionVariant,
    secondaryActionTitle,
    inProgress,
    isInvalid,
    errorMessage,
    warningMessage,
    dismissError,
    showProgress,
    // blurOnBackdrop,
    maxContentHeight,
    disableBackdropClick,
    TransitionComponent,
    keepMounted,
    classes,
    disableEscapeKeyDown,
    hideCloseIcon,
    hideBackdrop,
    disablePortal,
  } = props;

  return (
    <MuiDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      disableBackdropClick={disableBackdropClick}
      classes={classes}
      TransitionComponent={TransitionComponent}
      keepMounted={keepMounted}
      disablePortal={disablePortal}
      hideBackdrop={hideBackdrop}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={onClose}
        hideClose={hideCloseIcon}
      >
        {title}
      </DialogTitle>
      <DialogContent
        style={{ maxHeight: maxContentHeight, position: "relative" }}
      >
        <BusyIndicator busy={showProgress && inProgress} />
        <Collapse in={Boolean(errorMessage)}>
          <Alert
            severity="error"
            onClose={dismissError}
            style={{ marginBottom: 8 }}
          >
            {errorMessage}
          </Alert>
        </Collapse>
        <Collapse in={Boolean(warningMessage)}>
          <Alert
            severity="warning"
            // onClose={dismissError}
            style={{ marginBottom: 8 }}
          >
            {warningMessage}
          </Alert>
        </Collapse>
        <ErrorBoundary>{props.children}</ErrorBoundary>
      </DialogContent>
      {(Boolean(primaryAction) || Boolean(secondaryAction)) && (
        <DialogActions style={{ padding: 16 }}>
          {Boolean(secondaryAction) && (
            <Button
              // autoFocus
              onClick={secondaryAction}
              variant={secondaryActionVariant}
              color={secondaryActionColor}
              className={dialogClasses.actionButton}
            >
              {secondaryActionTitle}
            </Button>
          )}
          {Boolean(secondaryAction && primaryAction) && <Box mx={1} />}
          {Boolean(primaryAction) && (
            <Button
              disabled={inProgress || isInvalid}
              variant={primaryActionVariant}
              // autoFocus
              onClick={primaryAction}
              color={primaryActionColor}
              className={dialogClasses.actionButton}
              disabed={primaryActionDisabled}
            >
              {primaryActionTitle}
            </Button>
          )}
        </DialogActions>
      )}
    </MuiDialog>
  );
}

export default Dialog;

Dialog.defaultProps = {
  fullWidth: true,
  primaryActionTitle: "Confirm",
  primaryActionColor: "primary",
  secondaryActionColor: "primary",
  primaryActionVariant: "outlined",
  secondaryActionVariant: "outlined",
  secondaryActionTitle: "Cancel",
  showProgress: false,
  disableBackdropClick: false,
  keepMounted: false,
  hideCloseIcon: false,
  maxContentHeight: "auto",
};

Dialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false]),
  maxContentHeight: PropTypes.string,
  fullWidth: PropTypes.bool,
  hideCloseIcon: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  keepMounted: PropTypes.bool,
  primaryAction: PropTypes.func,
  primaryActionTitle: PropTypes.string,
  secondaryAction: PropTypes.func,
  secondaryActionTitle: PropTypes.string,
  inProgress: PropTypes.bool,
  isInvalid: PropTypes.bool,
  errorMessage: PropTypes.string,
  dismissError: PropTypes.func,
  showProgress: PropTypes.bool,
  blurOnBackdrop: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  TransitionComponent: PropTypes.element,
};
