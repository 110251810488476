import { Tooltip as MuiTooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Text from "../Text/Text";

const useStyles = makeStyles({
  paper: {
    borderRadius: 40,
    padding: 16
  },
  title: {
    fontSize: 14,
    textAlign: "center"
  },
});

function Tooltip(props) {
  const classes = useStyles();
  return (
    <MuiTooltip
      classes={{ tooltip: classes.paper }}
      title={<Text className={classes.title}>{props.title}</Text>}
      placement={props.placement}
    >
      {props.children}
    </MuiTooltip>
  );
}

export default Tooltip;

Tooltip.defaultProps = {
  placement: "bottom",
};

Tooltip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  placement: PropTypes.oneOf(["top", "bottom", "left", "right"]),
};
