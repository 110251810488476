import {makeStyles} from '@material-ui/core/styles'
import {Box, Button, Container, Grid, Menu, MenuItem, Typography} from '@material-ui/core'
import {useParams} from 'react-router-dom'
import {addCountryCode, getAxiosConfig, getRoles, mapper, SmoothVerticalScrolling, useConfig} from '../../../utils'
import {ADMIN_API_URL} from '../../../config'
import {useCallback, useEffect, useRef, useState} from 'react'
import {ContactInformation} from '../../Account/CreateTradeAccount/steps/ContactInformationNew'
import axios from 'axios'
import {TaxExemptListing} from './components/TaxExemptListing'
import {PaymentAndShipping} from './components/PaymentAndShipping'
import {Form, Formik} from 'formik'
import ListIcon from '@material-ui/icons/List'
import FormikSelect from 'components/Core/FormikFields/FormikSelect'
import {applicationStatus, applicationStatusOptions, userRoles} from 'constants/enums'
import {useSnackbar} from 'notistack'
import {
  businessInformationFormDefaults,
  contactInformationFormDefaults,
  declineReasons, memberInformationFormDefaults,
  paymentAndShippingFormDefaults, salesTerritoryFormDefaults
} from 'constants/constants'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {ErrorBox} from 'pages/Account/CreateTradeAccount/steps/common/ErrorBox'
import {TaxExempt} from "pages/Account/CreateTradeAccount/steps/TaxExempt";
import StickyBox from "react-sticky-box";
import HeaderButton from "pages/Admin/Applications/components/headerButton";
import {connect} from "react-redux";
import SalesTerritory from "pages/Admin/Applications/components/SalesTerritory";
import {getAllCountries} from "store/country/actions";
import {getAllSalesTerritories} from "store/salesTerritory/actions";
import {ProofOfBusiness} from "pages/Account/CreateTradeAccount/steps/ProofOfBusiness";
import {MemberInformation} from "pages/Account/CreateTradeAccount/steps/MemberInformationNew";
import {BusinessInformation} from "pages/Account/CreateTradeAccount/steps/BusinessInformation";
import {EmailOutlined} from '@material-ui/icons'
import {parseAddresses} from 'pages/Admin/Account/addressParser'

const useStyles = makeStyles({
  anchors: {
    '& a': {
      color: '#000 !important'
    }
  }
})

function ApplicationEditNew({history, context}) {
  const classes = useStyles()
  const {id} = useParams()
  const isNew = !!!id
  const {enqueueSnackbar} = useSnackbar()

  const [proofOfBusinessFiles, setProofOfBusinessFiles] = useState([])

  const [formData, setFormData] = useState({
    status: '',
  })

  const [countryId, setCountryId] = useState(1)
  // const [countries] = useConfig(`config/countries`, [])
  const countries = context.Country.getAll.result;

  const [states] = useConfig(`config/states/?countryId=${countryId}`, [])

  const [isBusy, setIsBusy] = useState(false)
  const [error, setError] = useState()

  const [isSalesRep, setIsSalesRep] = useState(false)

  useEffect(()=>{
    const roles = getRoles();
    setIsSalesRep(roles.includes(userRoles.INTERNAL_SALES_REP))
  },[])

  useEffect(() => {
    if (isNew) {
      setFormData({
        status: applicationStatus.NEW_APPLICATION
      })
    }

    if (!isNew) {
      const url = `${ADMIN_API_URL}/trade-account/application/${id}`
      axios.get(url, getAxiosConfig())
        .then((response) => {
          if (response.data && response.data.id) {

            const data = {
              ...response.data,
              ...mapper(response.data, [
                'ae','paymentTerms','shippingMethod', 'currencyCode',
                'salesTerritory', 'fedTaxId','businessType',
                'country', 'businessName', 'businessFocus', 'businessLink', 'otherBusinessType', 'businessOwner',
                'firstNameOwner', 'lastNameOwner', 'emailOwner'
              ],''),
              ...mapper(response.data.issuer,['title', 'firstName', 'lastName','email'],''),
            }

            setFormData(data)

            const businessContact = data.contacts.find((c) => c.contactType === 'BUSINESS')

            setProofOfBusinessFiles(data.proofOfBusinessFiles)

            let noProof = (!data.proofOfBusinessFiles ||
              (data.proofOfBusinessFiles && data.proofOfBusinessFiles.length === 0)) &&
              !data.fedTaxId && !data.businessLink

            setFormDataMemberBusinessInformation({
              ...data,
              typeOfProof: data.fedTaxId?'taxId':'other',
              uploadedFiles: Boolean(data.proofOfBusinessFiles)
            })

            setFormDataMemberInformation({
              ...data,
              country: businessContact.countryId,
              focusOnResidentialHospitality: data.focusOnResidentialHospitality === 'true',
              skipProofOfBusiness: noProof,
              typeOfProof: data.fedTaxId?'taxId':'other',
              businessOwner: !(data.firstNameOwner && data.lastNameOwner && data.emailOwner)
            })

            setFormDataBusinessInformation({
              ...data,
              country: businessContact.countryId,
              focusOnResidentialHospitality: data.focusOnResidentialHospitality === 'true',
              skipProofOfBusiness: noProof,
              typeOfProof: data.fedTaxId?'taxId':'other',
            })

            setCountryId(businessContact.countryId)
            setCountry(businessContact.countryId)

            let contactInformation = {
              country: businessContact.countryId,
              countryId: businessContact.countryId,
            }

            // Parse addresses
            parseAddresses(contactInformation, data)

            setFormDataContactInformation(contactInformation)

            setFormDataPaymentAndShipping({
              ...mapper(response.data,[
                {key: 'creditLimit', defaultValue: 0},
                {key: 'terms', map: 'paymentTerms', defaultValue:1},
                'currencyCode', 'shippingMethod', 'thirdPartyBilling'
              ])
            })

            setFormDataSalesTerritory({
              ...mapper(response.data,[
                'salesTerritory', 'ae', 'notes'
              ])
            })
          }
          setIsBusy(false)
        })
        .catch((err) => {
          // Silent error
          setIsBusy(false)
          if (err.response && err.response.data && err.response.data.message) {
            setError(err.response.data)
          }
          throw err
        })
    }

    // eslint-disable-next-line
  }, [])

  const formMemberBusinessInformationRef = useRef()
  const formBusinessInformationRef = useRef()
  const formMemberInformationRef = useRef()
  const formContactInformationRef = useRef()
  const formPaymentAndShipping = useRef()
  const formSalesTerritory = useRef()

  const [formDataMemberBusinessInformation, setFormDataMemberBusinessInformation] = useState({...businessInformationFormDefaults })
  const [formDataMemberInformation, setFormDataMemberInformation] = useState({...memberInformationFormDefaults })
  const [formDataBusinessInformation, setFormDataBusinessInformation] = useState({...businessInformationFormDefaults })
  const [formDataContactInformation, setFormDataContactInformation] = useState({...contactInformationFormDefaults})
  const [formDataPaymentAndShipping, setFormDataPaymentAndShipping] = useState({
    ...paymentAndShippingFormDefaults})
  const [formDataSalesTerritory, setFormDataSalesTerritory] = useState({...salesTerritoryFormDefaults})
  const [formDataTaxExempt, setFormDataTaxExempt] = useState()
  const [taxExemptFiles, setTaxExemptFiles] = useState({});
  const [taxExemptState, setTaxExemptState] = useState(1);

  const setCountry = useCallback(id => {
    setFormData(prevFormData => ({...prevFormData, country: id}))
    setFormDataContactInformation({
      ...formContactInformationRef.current.values,
      country: id,
    })
    setCountryId(id)
    // eslint-disable-next-line
  }, [])

  const save = async (close, overrides) => {

    setIsBusy(true)

    // Validate each form
    let forms = [
      formMemberInformationRef,
      formMemberBusinessInformationRef,
      formBusinessInformationRef,
      formContactInformationRef,
      formSalesTerritory,
      formPaymentAndShipping
    ]

    await formMemberBusinessInformationRef.current.submitForm()
    await formBusinessInformationRef.current.submitForm()
    await formMemberInformationRef.current.submitForm()
    await formContactInformationRef.current.submitForm()
    await formSalesTerritory.current.submitForm()
    await formPaymentAndShipping.current.submitForm()

    const valid =
      formMemberBusinessInformationRef.current.isValid &&
      formBusinessInformationRef.current.isValid &&
      formMemberInformationRef.current.isValid &&
      formSalesTerritory.current.isValid &&
      formPaymentAndShipping.current.isValid &&
      formContactInformationRef.current.isValid

    // Scroll to errors
    if (!valid) {
      const firstError = forms.find((f)=>Object.keys(f.current.errors).length!==0)
      if (firstError) {
        const fieldName = Object.keys(firstError.current.errors)[0]
        const fieldElement = document.getElementById(fieldName)
        SmoothVerticalScrolling(fieldElement,600,'top')
      }
      // additional logging
      console.log(forms.forEach((f)=>console.log(f.current.errors)))
      setIsBusy(false)
    }

    // If valid, transform data and submit
    if (valid) {

      // Transform for payload
      const {
        title,
        sameAsBusinessAddressBilling,
        sameAsBusinessAddressShipping,
        businessState,
        businessCity,
        businessZipCode,
        businessAddressLine1,
        businessAddressLine2,
        businessLink,
        fedTaxId,
        shippingState,
        shippingCity,
        shippingZipCode,
        shippingAddressLine1,
        shippingAddressLine2,
        shippingName,
        billingState,
        billingCity,
        billingZipCode,
        billingAddressLine1,
        billingAddressLine2,
        billingName,
        skipProofOfBusiness,
        terms,
        creditLimit,
        thirdPartyBilling,
        currencyCode,
        shippingMethod,
        salesTerritory,
        ae,
        notes,
        businessId,
        billingId,
        shippingId,
        firstName,
        lastName,
        email,
        otherBusinessType,
        billingPhoneNumber,
        shippingPhoneNumber,
        businessPhoneNumber
      } = {
        ...formData,
        ...formDataMemberBusinessInformation,
        ...formDataPaymentAndShipping,
        ...formDataContactInformation,
        ...formContactInformationRef.current.values,
        ...formMemberInformationRef.current.values,
        ...formBusinessInformationRef.current.values,
        ...formMemberBusinessInformationRef.current.values,
        ...formSalesTerritory.current.values,
        ...formPaymentAndShipping.current.values,
        ...overrides // this is used for direct save with changes
      }

      const {
        firstNameOwner,
        lastNameOwner,
        emailOwner,
        businessOwner
      } = {...formMemberInformationRef.current.values}

      const {
        businessName,
        businessFocus,
        businessType,
        focusOnResidentialHospitality
      } = {...formBusinessInformationRef.current.values}

      let payLoad = {
        'title': title,
        businessName,
        businessFocus,
        businessType,
        // 'businessName':formBusinessInformationRef.current.values.businessName,
        // 'businessFocus': businessFocus,
        // 'businessType': businessType,
        'otherBusinessType': businessType===1?otherBusinessType:'',
        'businessLink': '',
        'focusOnResidentialHospitality': focusOnResidentialHospitality,
        'fedTaxId': fedTaxId,
        'contacts': [],
        firstNameOwner: !businessOwner?firstNameOwner:'',
        lastNameOwner: !businessOwner?lastNameOwner:'',
        emailOwner: !businessOwner?emailOwner:'',
        // "taxExemptForms": [],
        'proofOfBusinessFiles': [],
        'rejectionReason': formData.rejectionReason ? formData.rejectionReason : '',
        // termsConditions: legalTerms,
        paymentTerms: terms,
        creditLimit: creditLimit?parseFloat(creditLimit):0,
        currencyCode: currencyCode,
        shippingMethod: shippingMethod,
        thirdPartyBilling: thirdPartyBilling,
        salesTerritory: salesTerritory,
        ae: ae,
        notes: notes,
        status: overrides && overrides.status ? overrides.status : formData.status,
        skipProofOfBusiness: !!skipProofOfBusiness
      }

      if (isNew) {
        payLoad.taxExemptForms = []
        const taxExemptStates = Object.keys(taxExemptFiles)
        if (taxExemptStates.length!==0) {
          taxExemptStates.forEach((state)=>{
            payLoad.taxExemptForms.push({
              stateId: state,
              files: [...taxExemptFiles[state].map((f)=>f.id)]
            })
          })
        }
      }

      if (!skipProofOfBusiness) {
        if (proofOfBusinessFiles&&proofOfBusinessFiles.length) {
          payLoad.proofOfBusinessFiles = [
            ...proofOfBusinessFiles.map((f) => f.id)
          ]
        }

        payLoad.businessLink = businessLink
        payLoad.fedTaxId = fedTaxId
      } else {
        payLoad.proofOfBusinessFiles = []
        payLoad.businessLink = ''
        payLoad.fedTaxId = ''
      }

      // if new, append user details
      if (isNew) {
        payLoad = {...payLoad,
          'firstName': firstName,
          'lastName': lastName,
          'email': email
        }
      }

      // Business Address
      payLoad.contacts.push({
        'name': '',
        'contactType': 'BUSINESS',
        'email': email,
        'phoneNumber': addCountryCode(countries, countryId, businessPhoneNumber),
        'addressLine1': businessAddressLine1,
        'addressLine2': businessAddressLine2,
        'zipCode': businessZipCode,
        'city': businessCity,
        'stateId': businessState ? businessState : null,
        'countryId': countryId,
        'id': businessId
      })

      // Billing Address
      if (!sameAsBusinessAddressBilling) {
        payLoad.contacts.push({
          'name': billingName,
          'contactType': 'BILLING',
          'email': email,
          'phoneNumber': addCountryCode(countries, countryId, billingPhoneNumber),
          'addressLine1': billingAddressLine1,
          'addressLine2': billingAddressLine2,
          'zipCode': billingZipCode,
          'city': billingCity,
          'stateId': billingState ? billingState : null,
          'countryId': countryId,
          'id': billingId
        })
      }

      // Shipping Address
      if (!sameAsBusinessAddressShipping) {
        payLoad.contacts.push({
          'name': shippingName,
          'contactType': 'SHIPPING',
          'email': email,
          'phoneNumber': addCountryCode(countries, countryId, shippingPhoneNumber),
          'addressLine1': shippingAddressLine1,
          'addressLine2': shippingAddressLine2,
          'zipCode': shippingZipCode,
          'city': shippingCity,
          'stateId': shippingState ? shippingState : null,
          'countryId': countryId,
          'id': shippingId
        })
      }

      const url = isNew?`${ADMIN_API_URL}/trade-account/application`:`${ADMIN_API_URL}/trade-account/application/${id}`
      const method = isNew?'post':'put'
      setIsBusy(true)

      axios[method](url, payLoad, getAxiosConfig())
        .then((response) => {
          setIsBusy(false)
          if (response.status === 204||response.status === 201) {
            enqueueSnackbar(`Application saved successfully`, {variant: 'success'})

            if (close||isNew) {
              history.push('/admin/account-management')
            }
          }

        })
        .catch((err) => {
          // Silent error
          setIsBusy(false)
          enqueueSnackbar(`An error occurred while saving the application form`, {variant: 'error'})

          if (err.response && err.response.data && err.response.data.message) {
            console.log(err.response.data.message)
            setError(err.response.data)
          }
          throw err
        })

    }

  }

  const openAccount = () => {
    setFormData(prevFormData => ({...prevFormData, status: applicationStatus.APPROVED}))
    save(true, {status: applicationStatus.APPROVED}).then(() => {})
  }

  const [anchorElDecline, setAnchorElDecline] = useState(null)

  const handleDecline = (e) => {
    setAnchorElDecline(e.currentTarget)
  }

  const handleDeclineClose = (reason) => {
    if (reason===false) {
      setAnchorElDecline(null)
      return
    }
    const reasonPayLoad = {rejectionReason: reason ? reason : '', status: applicationStatus.DECLINED}
    setFormData(prevFormData => ({...prevFormData, ...reasonPayLoad}))
    save(true, reasonPayLoad).then(() => {})
    setAnchorElDecline(null)
  }

  // Set form as read-only if account is Sales Representative
  const disabled = isSalesRep
  const warnEdit = !isNew

  return (
    <Container style={{position: 'relative'}}>
      <HeaderButton icon={ListIcon} label={'Go Back to Application List'} to={'/admin/account-management'} top={0} right={0} position={'absolute'} />

      <Formik
        initialValues={{...formData}}
        enableReinitialize={true}
        onSubmit={(values) => {
          save().then(r => {
          })
        }}
      >
        {({values, errors}) => (<Form noValidate>

          <Box mt={6}/>

          <Grid container spacing={2} style={{minHeight:'100vh'}}>

            <StickyBox offsetTop={20} offsetBottom={20} style={{height:'300px', display:'block',paddingRight:16, backgroundColor:'white'}}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography variant="h4" style={{fontWeight: 700}}>
                  Trade Account Application
                </Typography>
              </Grid>
              <Grid item container justify='flex-end'>
                <Grid item>
                  <Box fontSize={16} pr={10} pt={2} className={classes.anchors}>
                    <p><a href={'#MembersBusinessInformation'}> Member & Business Information </a></p>
                    <p><a href={'#ContactInformation'}> Business Contact Information </a></p>
                    <p><a href={'#TaxExempt'}>Tax Exempt</a></p>
                    <p><a href={'#PaymentAndShipping'}>Payment & Shipping</a></p>
                    <p><a href={'#SalesTerritory'}>Sales Territory & Notes</a></p>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            </StickyBox>

            {formData && formData.status && <>
              <Grid item xs={12} md={7}>

                <Box pt={6}/>

                <Grid item container spacing={2}>

                  <Grid item xs={12}>
                    <FormikSelect
                      variant={'outlined'}
                      margin="none"
                      fullWidth
                      id={'status'}
                      name={'status'}
                      label={'Application Status'}
                      options={applicationStatusOptions}
                      handleChange={value => setFormData(prevFormData => ({...prevFormData, status: value}))}
                      disabled={isNew||disabled}
                      warnEdit={warnEdit}
                    />
                  </Grid>

                  <Grid item xs={12} id={'MembersBusinessInformation'}>
                    {formDataMemberInformation && <MemberInformation
                      formRef={formMemberInformationRef}
                      setFormData={setFormDataMemberInformation}
                      formData={formDataMemberInformation}
                      countries={countries}
                      setCountryId={setCountry}
                      countryId={countryId}
                      proofOfBusinessFiles={proofOfBusinessFiles}
                      setProofOfBusinessFiles={setProofOfBusinessFiles}
                      allowUserEdit={isNew}
                      isUserView={false}
                      disabled={disabled}
                      locked={warnEdit}
                    />}
                  </Grid>

                  <Grid item xs={12} id={'MembersBusinessInformation'}>
                    {formDataBusinessInformation && <BusinessInformation
                      formRef={formBusinessInformationRef}
                      setFormData={setFormDataBusinessInformation}
                      formData={formDataBusinessInformation}
                      countries={countries}
                      setCountryId={setCountry}
                      countryId={countryId}
                      proofOfBusinessFiles={proofOfBusinessFiles}
                      setProofOfBusinessFiles={setProofOfBusinessFiles}
                      allowUserEdit={isNew}
                      isUserView={false}
                      disabled={disabled}
                      locked={warnEdit}
                    />}
                  </Grid>

                  <Grid item xs={12} id={'MembersBusinessInformation'}>
                    {formDataMemberBusinessInformation && <ProofOfBusiness
                      formRef={formMemberBusinessInformationRef}
                      setFormData={setFormDataMemberBusinessInformation}
                      formData={formDataMemberBusinessInformation}
                      countries={countries}
                      setCountryId={setCountry}
                      countryId={countryId}
                      proofOfBusinessFiles={proofOfBusinessFiles}
                      setProofOfBusinessFiles={setProofOfBusinessFiles}
                      allowUserEdit={isNew}
                      isUserView={false}
                      validatePhone={isNew}
                      disabled={disabled}
                      locked={warnEdit}
                    />}
                  </Grid>

                  <Grid item xs={12} id={'ContactInformation'}>
                    {formDataContactInformation && <ContactInformation
                      formRef={formContactInformationRef}
                      formData={formDataContactInformation}
                      setFormData={setFormDataContactInformation}
                      countries={countries}
                      states={states}
                      countryId={countryId}
                      setCountryId={setCountryId}
                      disabled={disabled}
                      validatePhone={isNew}
                      locked={warnEdit}
                    />}
                  </Grid>

                </Grid>

                <Grid item container spacing={2}>

                  <Grid item xs={12} id={'TaxExempt'}>
                    {!isNew&&<TaxExemptListing formData={formData}/>}
                    {isNew&&<TaxExempt
                      isUserView={false}
                      formData={formDataTaxExempt}
                      setFormData={setFormDataTaxExempt}
                      setTaxExemptFiles = {setTaxExemptFiles}
                      taxExemptFiles = {taxExemptFiles}
                      setTaxExemptState = {setTaxExemptState}
                      taxExemptState = {taxExemptState}
                      states={states}
                      countryId={countryId}
                      disabled={disabled}
                      locked={warnEdit}
                    />}
                  </Grid>

                  <Grid item xs={12} id={'PaymentAndShipping'}>
                    {formDataPaymentAndShipping && <PaymentAndShipping formData={formDataPaymentAndShipping}
                                                                       setFormData={setFormDataPaymentAndShipping}
                                                                       formRef={formPaymentAndShipping}
                                                                       status={formData.status} disabled={disabled} locked={warnEdit} />}
                  </Grid>

                  <Grid item xs={12} id={'SalesTerritory'}>
                    {formDataSalesTerritory &&
                    <SalesTerritory formData={formDataSalesTerritory} setFormData={setFormDataSalesTerritory} formRef={formSalesTerritory} disabled={disabled} locked={warnEdit} />}
                  </Grid>

                </Grid>

                <Grid item xs={12}>
                  <FormikSelect
                    variant={'outlined'}
                    margin="none"
                    fullWidth
                    id={'status'}
                    name={'status'}
                    label={'Application Status'}
                    options={applicationStatusOptions}
                    handleChange={value => setFormData(prevFormData => ({...prevFormData, status: value}))}
                    disabled={isNew||disabled}
                    warnEdit={warnEdit}
                  />
                </Grid>

                {isNew&&!disabled&&<>
                  <Box pt={4}/>

                  <Grid item container spacing={2} justify='flex-end'>
                    <Grid item xs={6}>
                      <Button variant='contained' color='secondary' size='large' fullWidth onClick={()=>save()} disabled={isBusy}>SEND EMAIL VERIFICATION</Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Box textAlign='right'>
                        Once the account’s email is verified, the application will be submitted.
                      </Box>
                    </Grid>
                  </Grid>

                </>}

              </Grid>
            </>}

          </Grid>

          <Box pt={isNew?1:6}/>

          <ErrorBox error={error}/>

          {!isNew&&!disabled&&<Grid container spacing={2}>

            <Grid item xs={12} md={4}>

            </Grid>

            <Grid item xs={12} md={7}>
              <Grid item container spacing={2}>
                <Grid item xs={6} md={1}>
                  <Button variant='outlined' size='large' disabled={isBusy} href={`mailto: ${formDataMemberBusinessInformation.email}`}
                  style={{minWidth:10, width:10}}>
                    <EmailOutlined />
                  </Button>
                </Grid>
                <Grid item xs={6} md={5}>
                  <Button variant='outlined' size='large' fullWidth type='submit' disabled={isBusy}>SAVE CHANGES</Button>
                </Grid>

                <Grid item xs={6} md={6}>
                  <Grid item container spacing={2}>
                    <Grid item xs={6} md={12}>
                      <Button variant='contained' color='secondary' size='large' fullWidth disabled={isBusy} onClick={() => openAccount()}>
                        OPEN ACCOUNT
                      </Button>
                    </Grid>
                    <Grid item xs={6} md={12}>
                      <Button variant='outlined' size='large' fullWidth disabled={isBusy} onClick={handleDecline}>
                        DECLINE <ExpandMoreIcon/>
                      </Button>

                      <Menu
                        id="reasons-menu"
                        anchorEl={anchorElDecline}
                        keepMounted
                        open={Boolean(anchorElDecline)}
                        onClose={() => handleDeclineClose(false)}
                        anchorOrigin={{vertical: 'top', horizontal: 'top',}}
                        anchorPosition={{top: 500}}
                      >
                        {declineReasons.map((reason) => <MenuItem
                          onClick={() => handleDeclineClose(reason)}>{reason}</MenuItem>)}
                      </Menu>
                    </Grid>
                  </Grid>
                </Grid>


              </Grid>
            </Grid>




          </Grid>}

          </Form>)}
      </Formik>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    context: {
      Auth: state.Auth,
      Country: state.Country
    }
  };
};

export default connect(mapStateToProps, {getAllCountries, getAllSalesTerritories})(ApplicationEditNew);
