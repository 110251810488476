import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useState } from "react";
import Heading from "./Common/Heading";
import Box from "@material-ui/core/Box";
import { Divider, Grid } from "@material-ui/core";
import Text from "../../components/Text/Text";
import { getAxiosConfig, toCurrency, useConfig } from "../../utils";
import MemoIcon from "assets/icons/memo.png";
import ProductIcon from "assets/icons/product.png";
import Button from "../../components/Button/Button";
import { useDispatch } from "react-redux";
import { openCartDrawer } from "../../store/element/actions";
import TaxExemptFormDialog from "../../components/TradeAccount/TaxExemptFormDialog";
import { API_URL } from "../../config";
import axios from "axios";
import Dialog from "../../components/Dialog/Dialog";

const useStyles = makeStyles((theme) => ({
  col: {
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "right",
  },
  highlight: {
    padding: "16px !Important",
    backgroundColor: theme.palette.secondary.light,
  },
  default: {
    padding: "8px 16px !Important",
  },
  divider: {
    backgroundColor: theme.palette.text.primary,
  },
  clickable: {
    textDecoration: "underline",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const OrderCard = (props) => {
  const classes = useStyles();

  const {
    amounts,
    // taxRegions,
    memos,
    products,
    numberOfSelectedItems,
    numberOfItems,
    submitOrder,
    processingOrder,
    processingCart,
    canSubmit,
    taxExemptions,
    setTriggerTaxRefresh,
  } = props;

  const dispatch = useDispatch();
  const [taxExemptOpen, setTaxExemptOpen] = useState(false);
  const [states] = useConfig(`config/states/?countryId=840`, []);
  const [taxExemptFiles, setTaxExemptFiles] = useState({});
  const [taxExemptState, setTaxExemptState] = useState(1);
  const [openTaxInfo, setOpenTaxInfo] = useState(false);
  const [openTaxLearnMor, setOpenTaxLearnMor] = useState(false);
  const [deletingFileState, setDeletingFileState] = useState(false);

  const deleteFileState = (id) => {
    const url = `${API_URL}/files/${id}`;
    setDeletingFileState(true);
    axios
      .delete(url, getAxiosConfig())
      .then((response) => {
        if (response.status === 200) {
          let newFiles = [...taxExemptFiles[taxExemptState]];
          let fileIndex = newFiles.findIndex((f) => f.id === id);
          newFiles.splice(fileIndex, 1);

          let newStates = { ...taxExemptFiles };
          newStates[taxExemptState] = [...newFiles];

          setTaxExemptFiles({ ...newStates });
        }
        setDeletingFileState(false);
      })
      .catch((err) => {
        // Silent error
        console.log(err);
        setDeletingFileState(false);
        throw err;
      });
  };

  // const [openTax, setOpenTax] = useState(false);

  const {
    netAmount,
    taxAmount,
    totalAmount,
    shippingCost,
    cutCharges,
  } = amounts;

  const handleDrawerOpen = useCallback(
    (activeTab) => {
      dispatch(openCartDrawer(activeTab));
    },
    [dispatch]
  );

  return (
    <Box>
      <Heading title={"Order Summary"} />
      <Grid container direction={"column"}>
        <Grid
          item
          container
          justify={"space-between"}
          alignItems={"center"}
          className={classes.highlight}
          style={{ marginBottom: 4 }}
        >
          <Grid item xs={6}>
            <Text bold>ITEM DETAILS</Text>
          </Grid>
          <Grid item xs={6} className={classes.col}>
            <Text bold>
              {numberOfSelectedItems} OUT OF {numberOfItems} BAG ITEMS
            </Text>
          </Grid>
        </Grid>
        {memos.length > 0 && (
          <Grid
            item
            container
            justify={"space-between"}
            className={classes.default}
          >
            <Grid item xs={6}>
              <Text
                bold
                className={classes.clickable}
                onClick={() => handleDrawerOpen(1)}
              >
                <img
                  src={MemoIcon}
                  alt={"SAMPLE"}
                  width={"24px"}
                  height={"24px"}
                />
                {memos.length} MEMOS
              </Text>
            </Grid>
            <Grid item xs={6} className={classes.col}>
              <Text bold>FREE</Text>
            </Grid>
          </Grid>
        )}
        {products.length > 0 && (
          <Grid
            item
            container
            justify={"space-between"}
            className={classes.default}
          >
            <Grid item xs={6}>
              <Text
                bold
                className={classes.clickable}
                onClick={() => handleDrawerOpen(2)}
              >
                <img
                  src={ProductIcon}
                  alt={"PRODUCT"}
                  width={"24px"}
                  height={"24px"}
                />
                {products.length} PRODUCTS
              </Text>
            </Grid>
            <Grid item xs={6} className={classes.col}>
              <Text bold>{toCurrency(netAmount, true)}</Text>
            </Grid>
          </Grid>
        )}
        {(memos.length > 0 || products.length > 0) && (
          <Grid item className={classes.default}>
            <Divider className={classes.divider} />
          </Grid>
        )}
        <Grid
          item
          container
          justify={"space-between"}
          className={classes.default}
        >
          <Grid item xs={6}>
            <Text bold>Subtotal</Text>
          </Grid>
          <Grid item xs={6} className={classes.col}>
            <Text bold>{toCurrency(netAmount, true)}</Text>
          </Grid>
        </Grid>
        {cutCharges > 0 && (
          <Grid
            item
            container
            justify={"space-between"}
            className={classes.default}
          >
            <Grid item xs={6}>
              <Text bold>Cut charge</Text>
            </Grid>
            <Grid item xs={6} className={classes.col}>
              <Text bold>{toCurrency(cutCharges)}</Text>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          container
          justify={"space-between"}
          className={classes.default}
        >
          <Grid item xs={6}>
            <Text bold>Shipping</Text>
          </Grid>
          <Grid item xs={6} className={classes.col}>
            <Text bold>{toCurrency(shippingCost, true)}</Text>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justify={"space-between"}
          className={classes.default}
        >
          <Grid item xs={6}>
            <Text bold>Estimated Tax</Text>
          </Grid>
          <Grid item xs={6} className={classes.col}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-end"}
            >
              <Text bold>{toCurrency(taxAmount, true)}</Text>
              {taxAmount && (
                <Text
                  onClick={() => setOpenTaxInfo(true)}
                  style={{ marginTop: 4 }}
                  bold
                  className={classes.clickable}
                >
                  Tax Exempt?
                </Text>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justify={"space-between"}
          alignItems={"center"}
          className={classes.highlight}
          style={{ marginTop: 4 }}
        >
          <Grid item xs={6}>
            <Text bold>PAYMENT TOTAL</Text>
          </Grid>
          <Grid item xs={6} className={classes.col}>
            <Text bold style={{ fontSize: 24 }}>
              {toCurrency(totalAmount, true)}
            </Text>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2} />
          {canSubmit && (
            <Button
              fullWidth
              variant={"contained"}
              color={"secondary"}
              loading={processingOrder}
              disabled={processingCart}
              onClick={submitOrder}
              size={"large"}
            >
              Place Order
            </Button>
          )}
        </Grid>
      </Grid>
      <TaxExemptFormDialog
        open={taxExemptOpen}
        setOpen={setTaxExemptOpen}
        files={taxExemptFiles}
        setFiles={setTaxExemptFiles}
        selectedState={taxExemptState}
        setSelectedState={setTaxExemptState}
        states={states}
        deletingFile={deletingFileState}
        deleteFile={deleteFileState}
        onUploadSuccess={() => setTriggerTaxRefresh((prev) => prev + 1)}
      />
      <Dialog
        primaryActionColor={"secondary"}
        primaryActionVariant={"contained"}
        primaryActionTitle={taxExemptions[0] ? "Add another state" : "Yes"}
        secondaryActionTitle={taxExemptions[0] ? "Cancel" : "No"}
        secondaryAction={() => setOpenTaxInfo(false)}
        primaryAction={() => {
          setOpenTaxInfo(false);
          if (taxExemptions[0]) {
            setTaxExemptOpen(true);
          } else {
            setOpenTaxLearnMor(true);
          }
        }}
        onClose={() => setOpenTaxInfo(false)}
        open={openTaxInfo}
        title={"Tax Exempt"}
      >
        {taxExemptions[0] ? (
          <div>
            <Text>You’re tax exempt from the following states:</Text>
            <Box mt={3} />
            {taxExemptions.map((tax) => (
              <Text
                bold
              >{`${tax.region}, expires on ${tax.expirationDate}`}</Text>
            ))}
            <Box mt={3} />
            <Text>
              Learn more about circumstances that might affect your tax
              exemption{" "}
              <b
                onClick={() => {
                  setOpenTaxInfo(false);
                  setOpenTaxLearnMor(true);
                }}
              >
                HERE
              </b>
              .
            </Text>
          </div>
        ) : (
          <Text>
            We don’t have your tax exempt information yet. Do you want to upload
            tax exempt forms now?
          </Text>
        )}
      </Dialog>
      <Dialog
        primaryActionColor={"secondary"}
        primaryActionVariant={"contained"}
        primaryActionTitle={"Upload Tax Exempt Forms"}
        secondaryActionTitle={"Cancel"}
        secondaryAction={() => setOpenTaxLearnMor(false)}
        primaryAction={() => {
          setOpenTaxLearnMor(false);
          setTaxExemptOpen(true);
        }}
        onClose={() => setOpenTaxLearnMor(false)}
        open={openTaxLearnMor}
        title={"Important Information"}
      >
        <Text bold>To qualify tax exemption:</Text>
        <Box mt={3}>
          <ul>
            <li>
              <Text>
                You must provide tax exemption documents for the origin state of
                work and the destination state of work.
              </Text>
            </li>
            <li style={{ marginTop: 24 }}>
              <Text>
                If there’s an intermediate place where work is being performed
                before it reaches the final destination, you must provide tax
                exempt documentation for that intermediate state.
              </Text>
            </li>
          </ul>
        </Box>
      </Dialog>
    </Box>
  );
};

export default OrderCard;
