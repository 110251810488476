import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import {connect} from "react-redux";
import {loginSuccess, logoutUser} from "store/auth/account/actions";
import {setSpoofingId} from "store/cart/actions";
import {roleOptions, userRoles} from 'constants/enums'
import {SectionTitle} from 'pages/Account/CreateTradeAccount/steps/common/SectionTitle'
import {Typography} from '@material-ui/core'
import {useEffect, useState} from 'react'
import EditNewMemberDialog from 'pages/Admin/Account/EditNewMemberDialog'
import {getRoles} from 'utils'
import {useParams} from 'react-router-dom'

const Row = ({member, setEditUser, editUser, disabled}) => {
  const roleObj = roleOptions.find((r)=>r.id===member.accountType)
  return <>
    <Grid item xs={6} md={4}>{member.fullName}</Grid>

    <Grid item xs={6} md={3} style={{overflowWrap: 'break-word'}}>
      {roleObj?roleObj.name:'-'}
    </Grid>

    <Grid item xs={6} md={4} style={{overflowWrap: 'break-word'}}>
      <a href={`mailto:${member.email}`}>{member.email}</a>
    </Grid>

    {!disabled&&<Grid item xs={6} md={1} onClick={()=>{if (editUser) return false; setEditUser(member.id)}} style={{cursor:'pointer', textDecoration:'underline', opacity: editUser?0.3:1}}>
      Edit
    </Grid>}
  </>
}

const byAccountType = (a) => {
  if (a.accountType===userRoles.TRADE_ACCOUNT_OWNER) {
    return -1
  } else {
    return 1
  }
}

const AccountMembersEdit = ({members,setSpoofingId,disabled}) => {

  const [membersList, setMembersList] = useState(members)
  const [showAll, setShowAll] = useState(false)
  const [editUser, setEditUser] = useState(false)
  const [addUser, setAddUser] = useState(false)

  const [isSalesRep, setIsSalesRep] = useState(false)

  const {id} = useParams()

  const closeDialog = () => {
    setAddUser(false)
    setEditUser(false)
  }

  useEffect(()=>{
    const roles = getRoles();
    setIsSalesRep(roles.includes(userRoles.INTERNAL_SALES_REP))

    window.scrollTo(0,0)
  },[])

  return <Grid item container spacing={2}>

    <Grid item xs={12} md={12}>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          style={{ fontWeight: 700, marginTop: 16 }}
        >
          Member & Business Information
        </Typography>
      </Grid>

      <Box pt={2}/>

      <Grid item container justify='space-between'>
        <Grid item>
          <SectionTitle>
            Account Members {membersList&&membersList.length>0?`(${membersList.length})`:''}
          </SectionTitle>
        </Grid>
        <Grid item>
          {!disabled&&<Box fontWeight='bold' style={{cursor:'pointer'}} onClick={()=>{setAddUser(true)}}>ADD A MEMBER +</Box>}
        </Grid>
      </Grid>

    </Grid>

    <Grid item container spacing={2}>
      {membersList&&membersList.sort(byAccountType).slice(0,showAll?membersList.length:2).map((member)=><Row member={member} setSpoofingId={setSpoofingId} editUser={editUser} setEditUser={setEditUser} key={member.id} disabled={disabled} />)}
      {(editUser||addUser)&&<EditNewMemberDialog open={editUser||addUser} setOpen={closeDialog} accountId={id} id={addUser?false:editUser} data={membersList} setData={setMembersList} isSalesRep={isSalesRep} />}
    </Grid>

    {!showAll&&membersList.length>2&&<Grid item xs={'auto'} onClick={()=>setShowAll(true)} style={{cursor:'pointer',textDecoration:'underline'}}>
      View All Account Members
    </Grid>}

    {showAll&&membersList.length>2&&<Grid item xs={'auto'} onClick={()=>setShowAll(false)} style={{cursor:'pointer',textDecoration:'underline'}}>
      Hide All Account Members
    </Grid>}

    <Box pb={6} />

  </Grid>
}

const mapStateToProps = (state) => {
  const { spoofingUserId } = state.Cart;
  return {
    context: {
      Auth: state.Auth,
      loginSuccess,
      logoutUser,
      spoofingUserId,
    },
  };
};

export default connect(mapStateToProps, {
  setSpoofingId,
})(AccountMembersEdit);
