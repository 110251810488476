import {
  API_ERROR,
  LOGIN_SUCCESS,
  LOGIN_USER,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  VALIDATE_USER,
  VALIDATE_USER_SUCCESS, VALIDATE_USER_FAILURE,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  isAuth: false,
  profilePicture: null,
  userDetails: null,
  resetPassword: false,
  validatingUser: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        isAuth: true,
        userDetails: action.payload,
        canOrder: action.payload.role !== 'WEB_USER'
      };
      break;
    case LOGOUT_USER:
      state = { ...state };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state, isAuth: false, canOrder: false, userDetails: null };
      break;
    case FORGOT_PASSWORD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FORGOT_PASSWORD_SUCCESS:
      state = {
        ...state,
        state,
      };
      break;
    case RESET_PASSWORD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        hasResetPassword: action.payload,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case VALIDATE_USER:
      state = { ...state, validatingUser: true };
      break;
    case VALIDATE_USER_SUCCESS:
      state = { ...state, validatingUser: false };
      break;
    case VALIDATE_USER_FAILURE:
      state = { ...state, validatingUser: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
