import {Form, Formik} from 'formik'
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core'
import FormikTextField from "components/Core/FormikFields/FormikTextField";
import FormikSelect from "components/Core/FormikFields/FormikSelect";
import FormikCheckbox from "components/Core/FormikFields/FormikCheckbox";
import * as Yup from "yup";
import { SectionTitle } from "pages/Account/CreateTradeAccount/steps/common/SectionTitle";
import {FormikContext} from "components/FormikDisabler";

export const MemberInformation = (props) => {
  const {
    setFormData,
    formData,
    step,
    setStep,
    formRef,
    disableActions,
    allowUserEdit=false,
    memberInformation=true,
    disabled=false,
    isUserView=true,
    locked=false,
    isAccountEdit=false
  } = props;

  const businessOwnerOptionsUser = [
    {
      id: true,
      name: 'Yes, I\'m the Business Owner / Principal.'
    },
    {
      id: false,
      name: 'No, I\'m an employee of the company.'
    }
  ]

  const businessOwnerOptionsAdmin = [
    {
      id: true,
      name: 'Yes, this member is the Business Owner / Principal.'
    },
    {
      id: false,
      name: 'No, this member is an employee of the company.'
    }
  ]

  const step1Validation = Yup.object().shape({
    firstNameOwner: Yup.string().when("businessOwner", {
      is: (val) => val==='false',
      then: Yup.string().when("skipOwner", {
        is: (val) => !val,
        then: Yup.string().required("This field is required"),
      })
    }),
    lastNameOwner:  Yup.string().when("businessOwner", {
      is: (val) => val==='false',
      then: Yup.string().when("skipOwner", {
        is: (val) => !val,
        then: Yup.string().required("This field is required"),
      })
    }),
    emailOwner:  Yup.string().when("businessOwner", {
      is: (val) => val==='false',
      then: Yup.string().when("skipOwner", {
        is: (val) => !val,
        then: Yup.string().email('Invalid email format').required("This field is required"),
      })
    }),
    businessOwner: Yup.string().required('Please select one of the options')
  });

  return (
    <>

      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={formData}
        validationSchema={step1Validation}
        onSubmit={(values) => {
          setFormData(prevFormData => ({...prevFormData, ...values, memberInformationSubmitted: true }))
          if (setStep) setStep(step + 1);
        }}
        validateOnChange={true}
      >
        {({ values, isValid, dirty,errors, setFieldValue}) => (
          <Form noValidate>
            <FormikContext.Provider value={{disabled,locked}}>
              <Grid item container spacing={3}>

                {!isAccountEdit&&<Grid item xs={12}>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: 700, marginTop: 16 }}
                  >
                    Member Information
                  </Typography>
                  {/*{JSON.stringify(errors)}*/}
                </Grid>}

                {memberInformation&&<>

                  <Grid item xs={12}>
                    <SectionTitle>
                      {isUserView?`About Yourself`:isAccountEdit?`About the Business Owner or Principal`:`About the member`}
                    </SectionTitle>
                  </Grid>

                  {!isAccountEdit&&<>

                    <Grid item xs={12} md={6}>
                      <FormikTextField
                        variant={"outlined"}
                        margin="none"
                        size={"small"}
                        fullWidth
                        type={"text"}
                        id={"firstName"}
                        name={"firstName"}
                        label={"First name"}
                        disabled={!allowUserEdit}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormikTextField
                        variant={"outlined"}
                        margin="none"
                        size={"small"}
                        fullWidth
                        type={"text"}
                        id={"lastName"}
                        name={"lastName"}
                        label={"Last name"}
                        disabled={!allowUserEdit}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <FormikTextField
                        variant={"outlined"}
                        margin="none"
                        size={"small"}
                        fullWidth
                        type={"text"}
                        id={"email"}
                        name={"email"}
                        label={"Email"}
                        disabled={!allowUserEdit}
                      />
                    </Grid>

                  </>}

                  <Grid item xs={12} md={12}>
                    <FormikSelect
                      variant={"outlined"}
                      margin="none"
                      fullWidth
                      id={"businessOwner"}
                      name={"businessOwner"}
                      label={isUserView?`Are you the Business Owner or Principal?`:`Is this member the business Owner or Principal?`}
                      options={isUserView?businessOwnerOptionsUser:businessOwnerOptionsAdmin}
                    />
                  </Grid>

                  {values.businessOwner===false&&<>

                    <Grid item xs={12}>
                      <SectionTitle>
                        Provide Information About the Business Owner or Principal
                      </SectionTitle>
                    </Grid>

                    <Grid item xs={6}>
                      <FormikTextField
                        variant={"outlined"}
                        margin="none"
                        size={"small"}
                        fullWidth
                        type={"text"}
                        id={"firstNameOwner"}
                        name={"firstNameOwner"}
                        label={"First name"}
                        disabled={values.skipOwner}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <FormikTextField
                        variant={"outlined"}
                        margin="none"
                        size={"small"}
                        fullWidth
                        type={"text"}
                        id={"lastNameOwner"}
                        name={"lastNameOwner"}
                        label={"Last name"}
                        disabled={values.skipOwner}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormikTextField
                        variant={"outlined"}
                        margin="none"
                        size={"small"}
                        fullWidth
                        type={"text"}
                        id={"emailOwner"}
                        name={"emailOwner"}
                        label={"Email"}
                        disabled={values.skipOwner}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormikCheckbox
                        label={"Skip for now"}
                        id={"skipOwner"}
                        name={"skipOwner"}
                      />
                    </Grid>

                  </>}

                </>}

                {setStep && (
                  <Grid
                    item
                    container
                    spacing={2}
                    justify="center"
                    alignContent="center"
                    alignItems="flex-start"
                  >
                    <Grid item xs={12} md={6}>
                      {step !== 1 && (
                        <>
                          <Button
                            size="large"
                            variant={"outlined"}
                            fullWidth
                            onClick={() => setStep(step - 1)}
                          >
                            GO BACK
                          </Button>{" "}
                        </>
                      )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Button
                            size="large"
                            variant={"contained"}
                            color='secondary'
                            fullWidth
                            type={"submit"}
                            onClick={() => {
                            }}
                            disabled={disableActions || !isValid || (!dirty && !formData.memberInformationSubmitted) }
                          >
                            {" "}NEXT{" "}
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            size="large"
                            variant={"outlined"}
                            fullWidth
                            onClick={()=>{
                              setFormData(prevFormData => ({...prevFormData, ...values, memberInformationSubmitted: true, firstLoad:false }))
                            }}
                            disabled={disableActions || !isValid || (!dirty && !formData.memberInformationSubmitted) }
                          >
                            {" "}
                            {"SAVE APPLICATION"}
                          </Button>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>
                )}
              </Grid>
            </FormikContext.Provider>
          </Form>
        )}
      </Formik>
    </>
  );
};
