import PropTypes from "prop-types";
import CardActionArea from "@material-ui/core/CardActionArea";
import Card from "@material-ui/core/Card";
import Text from "../Text/Text";
import { Box, CardMedia } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import DefaultImage from 'assets/images/collections.png'

const useStyles = makeStyles((theme) => ({
  border: {
    borderRadius: theme.borderRadius,
  },
}));

const FeaturedPlacementCard = (props) => {
  const classes = useStyles()
  const { featuredPlacement, handleClick, active, loading } = props;
  return (
    <Box my={2}>
      <Card variant={"outlined"} className={classes.border}>
        <CardActionArea
          disabled={!handleClick || loading}
          onClick={() => handleClick(featuredPlacement)}
        >
          {loading ? (
            <Skeleton variant={"rect"} width={"100%"} height={"300px"} />
          ) : (
            <CardMedia image={featuredPlacement.imageUrl || DefaultImage} style={{height: '100%',width:'100%',aspectRatio:'1/1'}}/>
          )}
          <Box p={2}>
            {loading ? (
              <Skeleton variant={"rect"} width={"70%"} height={22} />
            ) : (
              <Text bold={active} noWrap style={{ maxWidth: '100%' }}>
                {featuredPlacement.title.toUpperCase()}
              </Text>
            )}
          </Box>
        </CardActionArea>
      </Card>
    </Box>
  );
};

FeaturedPlacementCard.propTypes = {
  featuredPlacement: PropTypes.object.isRequired,
  active: PropTypes.bool,
  handleClick: PropTypes.func,
  loading: PropTypes.bool,
};

export default FeaturedPlacementCard;
