import { Box, Grid, Typography } from "@material-ui/core";
import { SectionTitle } from "../../../Account/CreateTradeAccount/steps/common/SectionTitle";
import { Form, Formik, validateYupSchema, yupToFormErrors } from "formik";
import FormikSelect from "../../../../components/Core/FormikFields/FormikSelect";
import FormikTextField from "../../../../components/Core/FormikFields/FormikTextField";
import { useConfig } from "utils";
import * as Yup from "yup";
import {applicationStatus, currencySymbols} from 'constants/enums'
import { useEffect, useState } from "react";
import {FormikContext} from "components/FormikDisabler";

const getConditionalCheck = (name) =>
  Yup.mixed().test({
    name: name,
    test: function (val) {
      // This needs to be a function since YUP binds it to this
      if (this.options.context.status === applicationStatus.APPROVED) {
        return !!val; // Value must be supplied
      }
      return true;
    },
    message: "This field is required when status is approved",
  });

const validation = Yup.object().shape({
  terms: getConditionalCheck("terms"),
  currencyCode: getConditionalCheck("currencyCode"),
  shippingMethod: getConditionalCheck("shippingMethod"),
  tradeDiscount: Yup.string().matches(/[+-]?([0-9]*[.])?[0-9]+/, { excludeEmptyString: true, message: 'Please enter a valid number' })
});

const useDefault = (form, setForm, prop, list) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (list[0] && !form[prop] && !initialized) {
      setForm((prevForm) => ({
        ...prevForm,
        [prop]: list[0].id,
      }));
      setInitialized(true);
    }
  }, [form, initialized, list, prop, setForm]);
};

export const PaymentAndShipping = ({
  formData,
  setFormData,
  formRef,
  status,
  tradeAccount=false,
  disabled=false,
  locked=false
}) => {
  const [shippingMethods] = useConfig(`shipping-methods`, []);
  const [termsOptions] = useConfig(`config/payment-terms`, []);
  const [currencyCodes] = useConfig(`config/currencies`, []);
  const [discounts] = useConfig(`config/discounts`, [],true)
  const [priceLevels] = useConfig(`config/price-levels`, [],true)
  // const [salesReps] = useConfig(`config/sales-reps`, [],true)

  // Set default values
  useDefault(formData, setFormData, "terms", termsOptions);
  useDefault(formData, setFormData, "shippingMethod", shippingMethods);
  useDefault(formData, setFormData, "currencyCode", currencyCodes);
  useDefault(formData, setFormData, "priceLevel", priceLevels);
  useDefault(formData, setFormData, "discount", discounts);

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ ...formData }}
      enableReinitialize={true}
      validate={async (value) => {
        try {
          await validateYupSchema(value, validation, true, { status });
        } catch (err) {
          return yupToFormErrors(err);
        }
        return {};
      }}
      onSubmit={(values) => {
        setFormData((prevFormData) => ({ ...prevFormData, ...values }));
      }}
    >
      {({ values, errors }) => (
        <Form noValidate>
          <FormikContext.Provider value={{disabled, locked}}>
            <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{ fontWeight: 700, marginTop: 16 }}
              >
                Payment & Shipping
              </Typography>
            </Grid>

            <Box pt={6} />

            <Grid item xs={12}>
              <SectionTitle>Default Payment</SectionTitle>
            </Grid>

            <Grid item container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormikSelect
                  variant={"outlined"}
                  margin="none"
                  fullWidth
                  id={"terms"}
                  name={"terms"}
                  label={"Terms"}
                  options={termsOptions}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormikTextField
                  variant={"outlined"}
                  margin="none"
                  size={"small"}
                  fullWidth
                  type={"text"}
                  id={"creditLimit"}
                  name={"creditLimit"}
                  label={"Credit Limit"}
                  currency={currencySymbols[values.currencyCode]}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormikSelect
                  variant={"outlined"}
                  margin="none"
                  fullWidth
                  id={"currencyCode"}
                  name={"currencyCode"}
                  label={"Currency Code"}
                  options={currencyCodes}
                />
              </Grid>

              {tradeAccount&&<>
                <Grid item container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <FormikSelect
                      variant={"outlined"}
                      margin="none"
                      fullWidth
                      id={"priceLevel"}
                      name={"priceLevel"}
                      label={"Price Level"}
                      options={priceLevels}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormikSelect
                      variant={"outlined"}
                      margin="none"
                      fullWidth
                      id={"discount"}
                      name={"discount"}
                      label={"Discount"}
                      options={discounts}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormikTextField
                      variant={"outlined"}
                      margin="none"
                      size={"small"}
                      fullWidth
                      type={"text"}
                      id={"tradeDiscount"}
                      name={"tradeDiscount"}
                      label={"Trade Discount"}
                    />
                  </Grid>
                </Grid>
              </>}

              <Grid item xs={12}>
                <SectionTitle>Default Shipping</SectionTitle>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormikSelect
                  variant={"outlined"}
                  margin="none"
                  fullWidth
                  id={"shippingMethod"}
                  name={"shippingMethod"}
                  label={"Shipping Method"}
                  options={shippingMethods}
                  disabled={shippingMethods.length === 0}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormikTextField
                  variant={"outlined"}
                  margin="none"
                  size={"small"}
                  fullWidth
                  type={"text"}
                  id={"thirdPartyBilling"}
                  name={"thirdPartyBilling"}
                  label={"Third Party Billing"}
                />
              </Grid>
            </Grid>
          </Grid>
          </FormikContext.Provider>
        </Form>
      )}
    </Formik>
  );
};
