import Box from "@material-ui/core/Box";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {ourStorySlides} from "../constants";
import {useEffect, useMemo, useState} from "react";
import {Fade, Grid} from "@material-ui/core";
import Text from "../../../components/Text/Text";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        maxHeight: '720px',
        height: 'auto',
        width: 'auto',
        maxWidth: '1212px',
        position: 'relative',
        aspectRatio: '16/9'
    },
    img: {
        width: '100%',
        objectFit: "cover",
        // position: 'relative',
        zIndex: 8
    }
}))

const SlideText = ({titles, description, year,color}) => {
    return <Fade in={true} key={`${year}-${titles[0]}`} timeout={900}>
        <div style={{width:'auto'}}>
            <Text variant={'h5'} style={{color: color}}>{year}</Text>
            {titles.map((title)=>{
             return <Text variant={'h5'} style={{color: color}} uppercase>{title}</Text>
            })}
            <Text variant={'body1'} style={{color: color, fontSize: 12,marginTop:20}} uppercase>{description}</Text>
        </div>
    </Fade>
}

const TimeSlideShow = ({images, texts, classes, transition, timelineYear}) => {

    const [currentIndex, setCurrentIndex] = useState(0)
    const [showText,setShowText] = useState(-1)

    useEffect(()=>{
        if (texts.find((t)=>t.index===currentIndex)!==undefined)
            setShowText(texts.find((t)=>t.index===currentIndex))
        // eslint-disable-next-line
    },[currentIndex,])

    useEffect(() => {
        setCurrentIndex(0)
        setShowText(-1)
    }, [timelineYear])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (currentIndex === images?.length - 1) {
                clearTimeout(timer)
            } else {
                setCurrentIndex(currentIndex + 1)
            }
        }, 2800)
        // eslint-disable-next-line
    }, [currentIndex])

    return <>
        <div style={{position: 'absolute', top: showText.top,bottom:showText.bottom,textAlign:showText.textAlign, maxWidth:showText.maxWidth, left: showText.left, zIndex: 200}}>
            {currentIndex >= showText.index &&
            <SlideText titles={showText.titles} year={timelineYear}
                       description={showText.description} color={showText.color}/>
            }
        </div>
            <div style={{position: 'relative', top: 0, left: 0}}>
                {images && images.map((image, idx) => {
                    return <img className={[classes.img, 'fade-in-image'].join(' ')}
                                key={`${currentIndex}-${timelineYear}`}
                                style={{position: 'absolute', display: currentIndex === idx ? '' : 'none'}}
                                src={images[idx]} alt={timelineYear}/>
                })}
            </div>
    </>
}

const OurStorySlider = () => {
    const classes = useStyles()

    const [timelineYear, setTimelineYear] = useState('1889')

    const transition = useMemo(() => {
        return true
        // eslint-disable-next-line
    }, [timelineYear])

    return <Box className={classes.wrapper}>
        <TimeSlideShow images={ourStorySlides.find((s) => s.time === timelineYear).images} timelineYear={timelineYear}
                       transition={transition} texts={ourStorySlides.find((s) => s.time === timelineYear).texts}
                       classes={classes}/>
        <Grid container style={{
            color: 'white',
            width: '38px',
            fontSize: '16px',
            userSelect: 'none',
            top: 18,
            right: 16,
            zIndex: 10,
            position: "absolute",
        }} direction={'column'}>
            {ourStorySlides.map((s) => {
                return <Grid item key={s.time} style={{
                    backgroundColor: timelineYear === s.time ? '#c80f4d' : '',
                    padding: '2px 4px 2px 10px',
                    cursor: 'pointer',
                    '&:hover':{
                        backgroundColor: '#c80f4d',
                        padding: '2px 4px 2px 10px',
                    }
                }} onClick={() => setTimelineYear(s.time)}>
                    <Text variant={'body1'} style={{fontSize:13,color:'white'}}> {s.time}</Text>
                </Grid>
            })}
        </Grid>
    </Box>
}


export default React.memo(OurStorySlider)