import { Box } from "@material-ui/core";
import { AdminNavigation } from "../components/Admin/AdminNavigation";
import TitleWrapper from "./TitleWrapper";
import ScrollToTop from "components/ScrollToTop";
import { connect } from "react-redux";
import { getAllSalesTerritories } from "store/salesTerritory/actions";
import { useDependency } from "reactcoregk/hooks";
import ErrorBoundary from "../components/Core/ErrorBoundary";

function AdminLayout({ children, title, context, getAllSalesTerritories }) {
  useDependency(context.SalesTerritory, getAllSalesTerritories);

  return (
    <TitleWrapper title={title}>
      <ScrollToTop />
      <Box
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <AdminNavigation />
        <ErrorBoundary>
          <Box style={{ flex: 1 }}>{children}</Box>
        </ErrorBoundary>
      </Box>
    </TitleWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      Auth: state.Auth,
      SalesTerritory: state.SalesTerritory,
    },
  };
};

export default connect(mapStateToProps, {
  getAllSalesTerritories,
})(AdminLayout);
