import Grid from '@material-ui/core/Grid'
import {Box, ListItem, ListItemText} from '@material-ui/core'
import {getUsername, useConfig} from 'utils'
import moment from 'moment'
import List from '@material-ui/core/List'

const AccountInformation = ({accountInfo}) => {

  const [termsOptions] = useConfig(`config/payment-terms`, []);

  const {onHold, accountNumber,salesTerritory,ae,rep,paymentTermsId,priceLevel,discount,tradeDiscount, businessName, taxExemptForms} = accountInfo?accountInfo:''

  return <Grid item container spacing={2}>

    <Grid item container justify='space-between' alignContent='center'>
      <Grid item >
        <Box fontSize={18} fontWeight={'bold'}>Account Information</Box>
      </Grid>

      <Grid item>
        {onHold&&<Box fontSize={16} fontWeight={'bold'} color={'red'}>On Finance Hold</Box>}
      </Grid>
    </Grid>

    <Grid item xs={12} md={6}>
      <Box fontWeight={'bold'}>Account Name</Box>
    </Grid>
    <Grid item xs={12} md={6}>
      {businessName}
    </Grid>

    <Grid item xs={12} md={6}>
      <Box fontWeight={'bold'}>Account No</Box>
    </Grid>
    <Grid item xs={12} md={6}>
      {accountNumber}
    </Grid>

    <Grid item xs={12} md={6}>
      <Box fontWeight={'bold'}>Sales Territory</Box>
    </Grid>
    <Grid item xs={12} md={6}>
      {salesTerritory}
    </Grid>

    <Grid item xs={12} md={6}>
      <Box fontWeight={'bold'}>AE</Box>
    </Grid>
    <Grid item xs={12} md={6}>
      {ae}
    </Grid>

    <Grid item xs={12} md={6}>
      <Box fontWeight={'bold'}>Rep</Box>
    </Grid>
    <Grid item xs={12} md={6}>
      {rep}
    </Grid>

    <Grid item xs={12} md={6}>
      <Box fontWeight={'bold'}>Pay Terms</Box>
    </Grid>
    <Grid item xs={12} md={6}>
      {termsOptions&&termsOptions.find((t)=>t.id===paymentTermsId)?.name}
    </Grid>

    <Grid item xs={12} md={6}>
      <Box fontWeight={'bold'}>Price Level</Box>
    </Grid>
    <Grid item xs={12} md={6}>
      {priceLevel}
    </Grid>

    <Grid item xs={12} md={6}>
      <Box fontWeight={'bold'}>Discount</Box>
    </Grid>
    <Grid item xs={12} md={6}>
      {discount}
    </Grid>

    <Grid item xs={12} md={6}>
      <Box fontWeight={'bold'}>Tax Exempt</Box>
    </Grid>
    <Grid item xs={12} md={6}>
      {taxExemptForms&&taxExemptForms.map((item) =>
        <Grid item container spacing={2} key={item.id}>
          <Grid item xs={12}>
            <List dense={true} disablePadding>
              {item.files.map((file, i) => {
                return <ListItem key={i} disableGutters>
                  <ListItemText style={{paddingRight: 16}}
                    // eslint-disable-next-line
                                primary={<Box >
                                  <Grid item container alignItems='center'>
                                    <Grid item>
                                      {item.expired&&
                                      <Box style={{color:'#DF0006'}}>
                                        {item.stateName} expired on {item.expiryDate}
                                      </Box>
                                      }
                                      {item.pending&&
                                      <Box style={{color:'#FE7A00'}}>
                                        {item.stateName} pending
                                      </Box>
                                      }
                                      {!item.expired&&!item.pending&&
                                      <Box>
                                        {item.stateName} expires on {item.expiryDate}
                                      </Box>
                                      }
                                    </Grid>
                                  </Grid>
                                </Box>}
                  />
                </ListItem>
              })}
            </List>
          </Grid>

        </Grid>
      )}
    </Grid>

    <Grid item xs={12} md={6}>
      <Box fontWeight={'bold'}>Trade Discount</Box>
    </Grid>
    <Grid item xs={12} md={6}>
      {tradeDiscount}
    </Grid>

    <Grid item xs={12}>
      <Box fontWeight={'bold'}>Account Notes</Box>
    </Grid>

    <Grid item xs={12}>
      <Box width={'100%'} minHeight={150} style={{borderRadius:16, border:'1px solid #000', overflowY:'scroll'}}>
        {accountInfo&&accountInfo.accountNotes&&accountInfo.accountNotes.length!==0&&<Box p={1} maxHeight={150}>
          {accountInfo.accountNotes.map((note)=>{
            return <Box p={1}>[ {note.userName?note.userName:getUsername()} - {moment(note.timestamp).format("MM/DD/yyyy HH:mm:ss")}] {note.text}</Box>
          })}
        </Box>}
      </Box>
    </Grid>

  </Grid>
}

export default AccountInformation
