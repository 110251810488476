import { EntityType } from "../../../store/@core/entityType";
import "react-credit-cards/es/styles-compiled.css";
import { Grid, TextField } from "@material-ui/core";
import { ApiEndpoint } from "../../../store/@core/endpoint";
import { useCallback, useEffect, useMemo, useState } from "react";
import { combineStrings, fetchData, safeVal } from "../../../utils";
import { createMap } from "reactcoregk/utils";
import GooglePlacesAutoComplete from "../../../components/Core/GooglePlacesAutoComplete";
import BaseSelect from "../../../components/Core/BaseSelect";

function readProperty(property, place) {
  return place.address_components.find((x) =>
    x.types.find((item) => item === property)
  );
}

const PaymentAddressForm = ({
  form,
  handleChange,
  setForm,
  countries,
  inProgress,
}) => {
  const [states, setStates] = useState([]);
  const [, setLoadingStates] = useState(false);
  const countryMap = useMemo(() => createMap(countries, "code"), [countries]);
  const [autoComplete, setAutoComplete] = useState(true);

  const openAutoComplete = useCallback(() => {
    setAutoComplete(true);
  }, []);

  const closeAutoComplete = useCallback(() => {
    setAutoComplete("disabled");
  }, []);

  const handlePlaceSelection = useCallback(
    async (place) => {
      // initialize on place change
      const zipCode = readProperty("postal_code", place);
      const state = readProperty("administrative_area_level_1", place);
      const city = readProperty("locality", place);
      const country = readProperty("country", place);
      const route = readProperty("route", place);
      const streetNo = readProperty("street_number", place);
      const addressLine1 =
        combineStrings(" ", streetNo?.long_name, route?.long_name) ||
        city?.long_name ||
        state?.long_name ||
        country?.long_name;

      const payload = {
        zipCode: zipCode?.short_name,
        city: city?.short_name,
        addressLine1,
      };

      if (country) {
        const appCountry = countryMap.get(country.short_name);
        const url = `${ApiEndpoint[EntityType.State]}?countryId=${
          appCountry.id
        }`;
        payload.countryId = appCountry?.id;
        try {
          const states = await fetchData(url);
          const appState =
            states.find((x) => x.code === state?.short_name) ||
            states[0]?.id ||
            null;
          payload.stateId = appState?.id;
        } catch (ex) {}
      }

      setForm((prevState) => ({
        ...prevState,
        ...payload,
      }));
    },
    [countryMap, setForm]
  );

  useEffect(() => {
    (async () => {
      if (form.countryId) {
        const url = `${ApiEndpoint[EntityType.State]}?countryId=${
          form.countryId
        }`;
        setLoadingStates(true);
        try {
          const res = await fetchData(url);
          setStates(res);
        } catch (ex) {
          setStates([]);
        }
        setLoadingStates(false);
      }
    })();
  }, [form.countryId]);

  const handleChangeCountry = useCallback(
    (e) => {
      const countryId = e.target.value;
      setForm((prevState) => ({
        ...prevState,
        countryId,
        stateId: null,
      }));
    },
    [setForm]
  );

  return (
    <Grid container spacing={2}>
      <Grid item sm={6} xs={12}>
        <TextField
          fullWidth
          variant={"outlined"}
          margin={"dense"}
          label={"First Name"}
          disabled={inProgress}
          value={safeVal(form.name)}
          onChange={handleChange("name")}
          autoComplete={autoComplete}
          onFocus={openAutoComplete}
          onBlur={closeAutoComplete}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          fullWidth
          variant={"outlined"}
          margin={"dense"}
          label={"Last Name"}
          disabled={inProgress}
          value={safeVal(form.surname)}
          onChange={handleChange("surname")}
          autoComplete={autoComplete}
          onFocus={openAutoComplete}
          onBlur={closeAutoComplete}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant={"outlined"}
          margin={"dense"}
          label={"Business Name (Optional)"}
          disabled={inProgress}
          value={safeVal(form.businessName)}
          onChange={handleChange("businessName")}
          autoComplete={autoComplete}
          onFocus={openAutoComplete}
          onBlur={closeAutoComplete}
        />
      </Grid>
      <Grid item xs={12}>
        <GooglePlacesAutoComplete
          fullWidth
          variant={"outlined"}
          margin={"dense"}
          label={"Address Line 1"}
          disabled={inProgress}
          value={safeVal(form.addressLine1)}
          onChange={handleChange("addressLine1")}
          onPlaceSelected={handlePlaceSelection}
          autoComplete={autoComplete}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant={"outlined"}
          margin={"dense"}
          label={"Address Line 2 (Unit, Suite, Apartment, Floor) (Optional)"}
          disabled={inProgress}
          value={safeVal(form.addressLine2)}
          onChange={handleChange("addressLine2")}
          autoComplete={autoComplete}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <BaseSelect
          variant={"outlined"}
          label={"Country"}
          margin={"dense"}
          value={safeVal(form.countryId)}
          hasNone
          options={countries}
          onChange={handleChangeCountry}
          placeholder={"Select Country"}
          controlId={"countryId"}
          autoComplete={autoComplete}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          fullWidth
          variant={"outlined"}
          margin={"dense"}
          label={"Zipcode"}
          disabled={inProgress}
          value={safeVal(form.zipCode)}
          onChange={handleChange("zipCode")}
          autoComplete={autoComplete}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          fullWidth
          variant={"outlined"}
          margin={"dense"}
          label={"City"}
          disabled={inProgress}
          value={safeVal(form.city)}
          onChange={handleChange("city")}
          autoComplete={autoComplete}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <BaseSelect
          variant={"outlined"}
          hasNone
          label={"State"}
          margin={"dense"}
          value={safeVal(form.stateId)}
          options={states}
          onChange={handleChange("stateId")}
          placeholder={"Select State"}
          controlId={"stateId"}
          labelProp={"code"}
          autoComplete={autoComplete}
        />
      </Grid>
    </Grid>
  );
};

export default PaymentAddressForm;
