import { useEffect, useState } from "react";
import {fetchData, getUserId} from "../utils";
import { API_URL } from "../config";
import {useSelector} from "react-redux";

export const useHomePage = (visibleProfileId) => {
  const [content, setContent] = useState({
    content: {
      key: "HOME_PAGE",
      value: {},
    },
    lastViewedProducts: [],
    purchaseOrders: [],
    relevantProducts: [],
  });
  const [loadingContent, setLoadingContent] = useState(false);

  useEffect(() => {
    const url = visibleProfileId
      ? `${API_URL}/users/${visibleProfileId}/homepage`
      : `${API_URL}/homepage`;
    setLoadingContent(true);
    fetchData(url)
      .then((res) => {
        setContent(res);
        setLoadingContent(false);
      })
      .catch(() => setLoadingContent(false));
  }, [visibleProfileId]);

  return [content, loadingContent];
};

export const useIsAuth = () => {
  return useSelector(state => state.Auth.isAuth)
}

export const useProfileIds = () => {
  return useSelector(state => {
    const spoofingUserId = state.Cart.spoofingUserId
    const tradeAccountId = state.Auth.userDetails?.tradeAccountId
    const spoofingTradeAccountId = state.Cart.spoofingUserDetails?.tradeAccountId
    return {
      spoofingUserId,
      userId: getUserId(),
      tradeAccountId,
      spoofingTradeAccountId,
      activeUserId: spoofingUserId || getUserId(),
      activeUserTradeAccountId: spoofingTradeAccountId || tradeAccountId
    }
  })
}

export const useProfile = () => {
  return useSelector(state => {
    const spoofingProfile = state.Cart.spoofingUserDetails
    const profile = state.Auth.userDetails
    return {
      profile,
      spoofingProfile,
      validatingUser: state.Auth.validatingUser,
      validatingSpoofingUser: state.Cart.validatingSpoofingUser,
      visibleProfile: spoofingProfile || profile
    }
  })
}
