import {Box, Button, Checkbox, Grid, Typography} from '@material-ui/core'
import {legalTerms} from 'constants/constants'
import {Link} from "react-router-dom";
import {openExternalProps} from "utils";

const TermsStep = ({accepted, setAccepted, setStep, step, submitForm, isBusy, setFormData}) => {

  return <>
    <Grid item xs={12}>
      <Typography variant='h5' style={{ fontWeight:700, marginTop: 8 }}>
        Terms and Conditions
      </Typography>
    </Grid>

    <Box>
      <Box mt={2} mb={2} style={{border: '1px solid #000', borderRadius: 16, maxHeight: '30vh', overflowY:'scroll'}}>
          <Box m={3} style={{whiteSpace:'pre-line'}}>
            {legalTerms}
          </Box>
      </Box>

      <Box p={1} pt={0} pb={2} fontSize={15}>

        <Grid item container>
          <label style={{display:'flex'}}>
            <Grid item>
              <Checkbox
                color="primary"
                checked={accepted}
                onClick={(e)=>setAccepted(!accepted)}
              />            </Grid>
            <Grid item xs>
              <Box pl={1} mt={1.4} style={{userSelect:'none',cursor:'pointer'}}>
                By clicking ‘Submit Application’ you agree to our <Link to={"/pages/privacy"} {...openExternalProps} >Privacy Policy</Link> and <Link to={'/pages/terms'} {...openExternalProps}>Terms and Conditions.</Link> Opt out of email marketing any time by clicking Unsubscribe at the bottom of any of our emails.
              </Box>
            </Grid>
          </label>
        </Grid>


      </Box>

      <Box mt={6}/>

      <Grid item container spacing={2} justify='center' alignContent='center' alignItems='flex-start'>

        <Grid item xs={12} md={6}>
          {step !== 1 && <><Button size='large' variant={'outlined'} fullWidth
                                   onClick={() => setStep(step - 1)}>GO BACK</Button> </>}
        </Grid>

        <Grid item xs={12} md={6}>

          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Button size='large' variant={'contained'} color={'secondary'} fullWidth onClick={() => { submitForm()
              }} disabled={(!accepted)||isBusy}> SUBMIT APPLICATION </Button>
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*  <Button*/}
            {/*    size="large"*/}
            {/*    variant={"outlined"}*/}
            {/*    fullWidth*/}
            {/*    onClick={()=>{*/}
            {/*      setFormData(prevFormData => ({...prevFormData, ...values}))*/}
            {/*    }}*/}
            {/*    disabled={(!accepted)||isBusy}*/}
            {/*  >*/}
            {/*    {" "}*/}
            {/*    {"SAVE APPLICATION"}*/}
            {/*  </Button>*/}
            {/*</Grid>*/}
          </Grid>


        </Grid>

      </Grid>

    </Box>
  </>
}

export default TermsStep
