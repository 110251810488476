import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import OrderItemList from "./OrderItemsList";
import { orderItemType } from "../../constants/enums";

const useAccordionStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default function AccordionList({
  memos,
  products,
  processingCart,
  handleRemoveItem,
  handleRemoveItems,
  handleUpdateItem,
  amounts,
  dirtyChanged,
  closeCartDrawer,
  checkoutAvailable,
  activeTab,
  setSelectedMemoIds,
  setSelectedProductIds,
  expandedType,
  setExpandedType,
}) {
  const classes = useAccordionStyles();

  return (
    <div className={classes.root}>
      <OrderItemList
        items={memos}
        handleRemoveItem={handleRemoveItem}
        handleRemoveItems={handleRemoveItems}
        handleUpdateItem={handleUpdateItem}
        processingCart={processingCart}
        closeCartDrawer={closeCartDrawer}
        checkoutAvailable={checkoutAvailable}
        type={orderItemType.SAMPLE}
        activeTab={activeTab}
        collapsable
        setRootSelectedIds={setSelectedMemoIds}
        expandedType={expandedType}
        setExpandedType={setExpandedType}
        dirtyChanged={dirtyChanged}
      />
      <OrderItemList
        amounts={amounts}
        items={products}
        handleRemoveItem={handleRemoveItem}
        handleRemoveItems={handleRemoveItems}
        handleUpdateItem={handleUpdateItem}
        processingCart={processingCart}
        closeCartDrawer={closeCartDrawer}
        checkoutAvailable={checkoutAvailable}
        type={orderItemType.PRODUCT}
        activeTab={activeTab}
        collapsable
        setRootSelectedIds={setSelectedProductIds}
        expandedType={expandedType}
        setExpandedType={setExpandedType}
        dirtyChanged={dirtyChanged}
      />
    </div>
  );
}
