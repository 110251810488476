import { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Collapse,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ProductDialog from "./ProductDialog";
import CatalogFilters from "../../components/Core/CatalogFilters";
import { connect } from "react-redux";
import ProductCard from "../../components/Cards/ProductCard/ProductCard";
import {
  fetchData,
  getParameterByName,
  updateURLParameter,
} from "../../utils";
import { Paging } from "reactcoregk/models";
import { ApiEndpoint } from "../../store/@core/endpoint";
import { EntityType } from "../../store/@core/entityType";
import {
  useAttributes,
  useCatalogFilters,
  useCatalogTabTitle,
  useNewArrivalsProductSorting,
} from "../../hooks/catalog";
import { ExpandLess } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import Text from "../../components/Text/Text";
import ProductCardWithHooks from "components/Cards/ProductCard/ProductCartWithHooks";
import FeaturedPlacementCard from "../../components/FeaturePlacementCard/FeaturedPlacementCard";
import GridCarousel from "../../components/GridCarousel/GridCarousel";
import { useTitle } from "../../hooks/router";
import { useCurrentCart } from "../../hooks/cart";
import CatalogHeader from "../Catalog/CatalogHeader";
import { catalogFilter } from "../../constants/enums";
import { useFavorites } from "../../hooks/favorites";
import ScrollBackToTop from "../../components/ScrollBackToTop";
import {useIsAuth, useProfileIds} from "../../hooks/auth";
import {PuffLoader} from "react-spinners";

const useStyles = makeStyles((theme) => ({
  root: {},
  menuButton: {
    fontWeight: "bold",
    fontSize: 12,
  },
  bgLight: {
    backgroundColor: theme.palette.secondary.lightNav,
    paddingBottom: 8,
  },
}));

const productsUrl = ApiEndpoint[EntityType.Product];

function NewArrivals(props) {
  const classes = useStyles();

  const { context, match, history, location } = props;

  const [openPreview, setOpenPreview] = useState(false);
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(new Paging());
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [showCollections, setShowCollections] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [favoriteProduct, setFavoriteProduct] = useState({});
  const isAuth = useIsAuth()
  const { activeUserId } = useProfileIds()

  const rootParams = location.search.replaceAll("?", "&");
  const term = getParameterByName("term");

  const { handleAddMemo, cart } = useCurrentCart();

  const handleSort = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorEl(null);
  };

  useFavorites(favoriteProduct, products, setFavoriteProduct, activeUserId);

  const {
    handleDeleteAttribute,
    handleAddAttribute,
    attributes,
  } = useAttributes(location, history, match);

  const { handleSortBy, sortOptions, isActive } = useNewArrivalsProductSorting(
    location,
    history,
    match,
    context.Auth
  );

  const {
    featuredPlacements,
    visibleFilters,
    allFilters,
    defaultFilterIds,
    toggleFilter,
    loadingFilters,
    loadingPlacements,
  } = useCatalogFilters(isAuth, rootParams, catalogFilter.NEW_ARRIVAL);

  // const collectionIds = useMemo(() => featuredPlacements.map(x => x.id), [featuredPlacements])

  const categoryName = "New Arrivals";
  const [page, setPage] = useState(0)

  //searchInput will be returning a Boolean state from CatalogHeader.js if there is an input Value on searchBox
  //It was requested to always hide the collections (carousel) if the searchBox input is filled
  const [searchInput, setSearchInput] = useState(false)
  const noCatSelected = new URLSearchParams(window.location.search).get("featuredPlacementId") ? new URLSearchParams(window.location.search).get("featuredPlacementId") === "" : true
  const tabTitle = useCatalogTabTitle(
    categoryName,
    attributes,
    allFilters,
    term
  );
  useTitle(tabTitle);

  const handleCollectionClick = (featuredPlacementId) => {
    const search = updateURLParameter(
      location.search,
      "featuredPlacementId",
      featuredPlacementId
    );
    history.push({
      pathname: match.url,
      search: search,
    });

    if(searchInput)
      setShowCollections(false)
  };

  const handlePreview = useCallback((product) => {
    setSelectedProduct(product);
    setOpenPreview(true);
  }, []);

  // const defaultParams = useMemo(() => {
  //   const collectionIdParams = getParameterByName("collectionId")
  //   if (!collectionIdParams) {
  //     return updateURLParameter(rootParams, "collectionId", collectionIds.toString())
  //   }
  //   return rootParams
  // }, [collectionIds, rootParams])

  const handleNext = useCallback(async () => {
    !searchInput && setShowCollections(true)
    try {
      setLoading(true)
      const url = `${productsUrl}?${
        rootParams ? rootParams : "&sort=arrivalDate,desc,itemNumber,desc"
      }&page=${results.number + 1}&size=24`;
      setPage(results.number + 1)
      const response = await fetchData(url);
      setResults(response);
      setProducts((prevState) => [...prevState, ...response.content]);
    } catch (ex) {}
    setLoading(false)
    // eslint-disable-next-line
  }, [results.number, rootParams]);

  useEffect(() => {
    (async () => {
      setResults(new Paging());
      setProducts([]);
      setPage(0)
      setShowCollections(!searchInput && noCatSelected)
      try {
        setLoading(true)
        const url = `${productsUrl}?${
          rootParams ? rootParams : "&sort=arrivalDate,desc,itemNumber,desc"
        }&size=24`;
        const response = await fetchData(url);
        setResults(response);
        setProducts(response.content);
      } catch (e) {}
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, [rootParams, isAuth]);

  const featuredPlacementId = useMemo(
    () => parseInt(getParameterByName("featuredPlacementId", location.search)),
    [location.search]
  );
  const selectedCollection = useMemo(
    () => featuredPlacements.find((fp) => fp.id === featuredPlacementId),
    [featuredPlacementId, featuredPlacements]
  );

  useEffect(() => {
    setShowCollections(!Boolean(featuredPlacementId));
  }, [featuredPlacementId]);

  function renderGridLoader() {
    return (
      <Grid container spacing={4}>
        {[...Array(24)].map((v, i) => (
          <Grid key={i} item lg={3} md={4} sm={6} xs={12}>
            <ProductCard isLoading product={{}} />
          </Grid>
        ))}
      </Grid>
    );
  }

  function renderFeaturedPlacement(featuredPlacement, loading) {
    return (
      <FeaturedPlacementCard
        handleClick={() => handleCollectionClick(featuredPlacement?.id)}
        loading={loading}
        featuredPlacement={featuredPlacement}
        active={featuredPlacementId === featuredPlacement?.id}
      />
    );
  }

  return (
    <Box style={{ marginTop: 10 }}>
      <div className={classes.bgLight}>
        <Box py={2}>
          <Container maxWidth="lg">
            <CatalogHeader
              history={history}
              match={match}
              location={location}
              categoryName={categoryName}
              setShowCollections={setShowCollections}
              showCollections={showCollections}
              setSearchInput={setSearchInput}
            />
          </Container>
        </Box>
        <Collapse
          in={
            (featuredPlacements.length > 0 && showCollections) ||
            loadingPlacements
          }
        >
          <Container>
            <GridCarousel
              renderItem={renderFeaturedPlacement}
              loading={loadingPlacements}
              loadingItems={3}
              items={featuredPlacements}
              hideProgress
              spacing={8}
              breakpoints={{
                xl: 3,
                lg: 3,
                md: 2,
                sm: 1,
                xs: 1,
              }}
            />
          </Container>
        </Collapse>
        {!showCollections && selectedCollection && (
          <Container>
            <Box display={"flex"} justifyContent={"center"}>
              <IconButton onClick={() => handleCollectionClick("")}>
                <CloseIcon />
              </IconButton>
              <Button onClick={() => setShowCollections(!showCollections)}>
                {selectedCollection?.title}
                {!showCollections ? <ExpandMoreIcon /> : <ExpandLess />}
              </Button>
            </Box>
          </Container>
        )}
      </div>
      <Container>
        <Box mt={2} />
        <CatalogFilters
          handleAddAttribute={handleAddAttribute}
          handleDeleteAttribute={handleDeleteAttribute}
          attributes={attributes}
          visibleFilters={visibleFilters}
          allFilters={allFilters}
          toggleFilter={toggleFilter}
          loadingFilters={loadingFilters}
          defaultFilterIds={defaultFilterIds}
        />
        <Toolbar>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            {results.totalElements} Results
          </Typography>
          <div style={{ margin: "auto" }} />
          <Button
            className={classes.menuButton}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleSort}
          >
            Sort by <ExpandMoreIcon />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseSort}
          >
            {sortOptions.map((option, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  handleCloseSort();
                  handleSortBy(option);
                }}
              >
                <Text bold={isActive(option)} variant={"inherit"}>
                  {option.name}
                </Text>
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
        {/*<InfiniteScroll*/}
        {/*  dataLength={products.length} //This is important field to render the next data*/}
        {/*  next={handleNext}*/}
        {/*  scrollThreshold={0.4}*/}
        {/*  hasMore={results.number + 1 < results.totalPages}*/}
        {/*  style={{ width: "100%", overflow: "hidden" }}*/}
        {/*  loader={renderGridLoader()}*/}
        {/*>*/}
          {loading && page === 0? (
            renderGridLoader()
          ) : (
            <Grid container spacing={4}>
              {products.map((product) => (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <ProductCardWithHooks
                    product={product}
                    handlePreview={handlePreview}
                    handleMemo={handleAddMemo}
                    activeCart={cart}
                    toggleFavorite={() => setFavoriteProduct(product)}
                    isExcluded={true}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        {(results.number + 1 < results.totalPages) &&
        <Grid container alignItems={'center'} alignContent={'center'} justify={'center'}
              style={{marginTop: 40}}>
          <Grid item>
            <Button variant={'contained'} color={'secondary'}
                    style={{width:250}}
                    disabled={(results.number + 1 > results.totalPages) || loading} onClick={handleNext}
                    size={'large'}>{loading ? <>Loading <PuffLoader size={10} css={"margin-left:8px;"} loading={true}/></> : 'Load More...'}</Button>
          </Grid>
        </Grid>
        }
        {/*</InfiniteScroll>*/}
      </Container>
      <ProductDialog
        open={openPreview}
        handleClose={() => setOpenPreview(false)}
        itemNumber={selectedProduct?.itemNumber}
        products={products}
      />
      <ScrollBackToTop
        loading={loadingPlacements || loadingFilters || loadingFilters}
      />
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      NavigationMenu: state.NavigationMenu,
      Cart: state.Cart,
      Auth: state.Auth,
    },
  };
};

export default connect(mapStateToProps, {})(NewArrivals);
