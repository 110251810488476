import { connect } from "react-redux";
import { logoutUser } from "../../../store/auth/account/actions";
import Navigation from "../../Navigation/Navigation";
import AccountButton from "./AccountButton";
import CartButton from "./CartButton";
import { useNavigationLinks } from "../../../hooks/catalog";

const topLinks = [
  {
    name: "Frederic Magazine",
    href: "https://fredericmagazine.com/",
  },
  {
    name: "Patterson Flynn",
    href: "https://pattersonflynnmartin.com/",
  },
  {
    name: "BACKDROP",
    href: "https://www.backdrophome.com/",
  },
  {
    name: "Freddie",
    href: "https://joinfreddie.com/",
  },
  {
    name: "Collaborators",
    path: "/collaborators",
  },
  {
    customEl: () => <div style={{ flex: 1 }} />,
  },
  {
    name: "Usd",
    path: "/",
    showType: "auth",
  },
  {
    component: AccountButton,
  },
  {
    name: "Favorites",
    path: "/account/favorites",
    showType: "auth",
  },
];

function AppBar({ context, isSticky }) {
  const { isAuth, canOrder } = context.Auth;
  const navLinks = useNavigationLinks(context.NavigationMenu);
  return (
    <Navigation
      topLinks={topLinks}
      navLinks={navLinks}
      CartButton={CartButton}
      isAuth={isAuth}
      canOrder={canOrder}
      isSticky={isSticky}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      NavigationMenu: state.NavigationMenu,
      Auth: state.Auth,
    },
  };
};
export default connect(mapStateToProps, { logoutUser })(AppBar);
