import {Form, Formik, useFormikContext} from 'formik'
import {Box, Button, Grid, Typography} from "@material-ui/core";
import FormikSelect from "../../../../components/Core/FormikFields/FormikSelect";
import FormikTextField from "../../../../components/Core/FormikFields/FormikTextField";
import FormikCheckbox from "../../../../components/Core/FormikFields/FormikCheckbox";
import {SectionTitle} from "./common/SectionTitle";
import * as Yup from "yup";
import {USCountryID} from "constants/constants";
import {useEffect, useRef, useState} from 'react'
import {API_URL} from 'config'
import axios from 'axios'
import {combineStrings, fetchData, getAxiosConfig} from 'utils'
import {FormikContext} from "components/FormikDisabler";
import {phoneStatus} from "constants/enums";
import {ApiEndpoint} from "store/@core/endpoint";
import {EntityType} from "store/@core/entityType";
// import {createMap} from "reactcoregk/utils";
import FormikGooglePlacesAutoComplete from "components/Core/FormikFields/FormikGooglePlacesAutoComplete";
import UPSSuggestionBox from 'components/UPSValidation'

export const ContactInformation = (props) => {

  const {
    formData,
    setFormData,
    step,
    setStep,
    countries,
    states,
    countryId,
    setCountryId,
    formRef,
    disabled=false,
    validatePhone=false,
    disableActions=false,
    locked=false
  } = props

  const tradeAccountValidation = Yup.object().shape({
    businessAddressLine1: Yup.string().required('This field is required'),
    businessZipCode: Yup.string().required('This field is required'),
    businessCity: Yup.string().required('This field is required'),
    businessPhoneNumber: Yup.string().required('This field is required'),
    businessState: Yup.string().test('selected-state','This field is required',(value)=>(!(!value&&states.length!==0))),

    billingAddressLine1: Yup.string().when('sameAsBusinessAddressBilling',{
      is: val=>(!val), then: Yup.string().required('This field is required')}),
    billingZipCode: Yup.string().when('sameAsBusinessAddressBilling',{
      is: val=>(!val), then: Yup.string().required('This field is required')}),
    billingCity: Yup.string().when('sameAsBusinessAddressBilling',{
      is: val=>(!val), then: Yup.string().required('This field is required')}),
    billingState: Yup.string().when('sameAsBusinessAddressBilling',{
      is: val=>(!val), then: Yup.string().test('selected-state','This field is required',(value)=>(!(!value&&states.length!==0)))}),


    shippingAddressLine1: Yup.string().when('sameAsBusinessAddressShipping',{
      is: val=>(!val), then: Yup.string().required('This field is required')}),
    shippingZipCode: Yup.string().when('sameAsBusinessAddressShipping',{
      is: val=>(!val), then: Yup.string().required('This field is required')}),
    shippingCity: Yup.string().when('sameAsBusinessAddressShipping',{
      is: val=>(!val), then: Yup.string().required('This field is required')}),
    shippingState: Yup.string().when('sameAsBusinessAddressShipping',{
      is: val=>(!val), then: Yup.string().test('selected-state','This field is required',(value)=>(!(!value&&states.length!==0)))})
  })

  const businessZipRef = useRef()
  const shippingZipRef = useRef()
  const billingZipRef = useRef()

  const zipCodeRefs = {businessZipRef, shippingZipRef, billingZipRef}

  const HandlePostalCodeChange = ({contactType}) => {
    const { values , setFieldValue } = useFormikContext();

    useEffect(()=>{
      const zipCode = values[`${contactType}ZipCode`]

      if (values[`${contactType}ZipCode`]&&countryId===USCountryID) {
        const url = `${API_URL}/ups/get-state-id/${zipCode}`

        clearTimeout(zipCodeRefs[`${contactType}ZipRef`].current)
        zipCodeRefs[`${contactType}ZipRef`].current = setTimeout(()=>{
          axios
            .get(url, getAxiosConfig())
            .then((response) => {
              if (response.status === 200) {
                setFieldValue(`${contactType}State`, response.data)
              }
            })
            .catch((err) => {
              // Silent error
              console.log(err);
            });
        },400)

      }
    },[contactType, values, setFieldValue])

    return null
  }

  const phoneVerifyTimeout = useRef()

  const [phoneError, setPhoneError] = useState()

  const PhoneHandler = (type='business') => {
    const { values } = useFormikContext();

    useEffect(()=>{
      const phone = values[`${type}PhoneNumber`]
      if (phone&&phone!==''&&phone.length>4) {
        clearTimeout(phoneVerifyTimeout.current)
        const url = `${API_URL}/trade-account/application/validate?phone-number=${phone}`
        phoneVerifyTimeout.current = setTimeout(()=>{
          axios
            .get(url, getAxiosConfig())
            .then((response) => {
              if (response.status === 200) {
                if (response.data.status === phoneStatus.PHONE_NUMBER_EXISTS) {
                  setPhoneError(true)
                } else {
                  setPhoneError(false)
                }
              }
            })
            .catch((err) => {
              // Silent error
              console.log(err);
            });
        },400)
      }
      // eslint-disable-next-line
    },[values.businessPhoneNumber])
    return null
  }

  const [countryCode, setCountryCode] = useState()

  useEffect(()=>{
    if (countryId) {
      const countryObj = countries.find((c)=>c.id===countryId)
      if (countryObj) {
        setCountryCode(countryObj.countryCode)
      }
    }
  },[countryId, countries])

  const businessValidationRef = useRef()
  const shippingValidationRef = useRef()
  const billingValidationRef = useRef()

  const addressRefs = {businessValidationRef, shippingValidationRef, billingValidationRef}

  const HandleAddressChange = ({contactType}) => {
    const { values , setFieldValue } = useFormikContext();
    // const [isValid, setIsValid] = useState();
    // const [suggestions, setSuggestions] = useState([]);
    // eslint-disable-next-line
    const [isBusy, setBusy] = useState()
    const [lastAddress,setLastAddress] = useState()
    const [validation, setValidation] = useState(null);

    useEffect(()=>{

      const stateObj = states.find((s)=>s.id===+values[`${contactType}State`])
      const addressLine1 = values[`${contactType}AddressLine1`]
      const city = values[`${contactType}City`]
      const postalCode = values[`${contactType}ZipCode`]
      const stateProvinceCode = stateObj?stateObj.code:''
      const countryObj = countries.find((c)=>c.id===+countryId)
      const countryCode = countryObj?countryObj.code:''

      const address = {
        addressLine1,
        addressLine2 : values[`${contactType}AddressLine2`],
        city,
        countryCode,
        stateProvinceCode,
        postalCode,
        customerContext: contactType
      }

      if (JSON.stringify(address)===JSON.stringify(lastAddress)) return false

      setLastAddress(address)

      if (addressLine1&&city&&postalCode) {
        const url = `${API_URL}/ups/address-validation`

        clearTimeout(addressRefs[`${contactType}ValidationRef`].current)
        addressRefs[`${contactType}ValidationRef`].current = setTimeout(()=>{
          setBusy(true)
          axios
            .post(url, address, getAxiosConfig())
            .then((response) => {
              if (response.status === 200) {
                // setFieldValue(`${contactType}State`, response.data)
                // setIsValid(response.data.isValid)
                // setSuggestions(response.data.suggestions)
                setValidation(response.data)
              }
              setBusy(false)
            })
            .catch((err) => {
              // Silent error
              setBusy(false)
              console.log(err);
            });
        },600)

      }
    },[contactType, values, setFieldValue, lastAddress])

    const setSuggestedAddress = (addressObj) => {
      if (addressObj.AddressLines[0]) setFieldValue(`${contactType}AddressLine1`,addressObj.AddressLines[0])
      if (addressObj.AddressLines[1]) setFieldValue(`${contactType}AddressLine2`,addressObj.AddressLines[1])

      if (addressObj.stateProvinceCode) {
        const stateObj = states.find((s)=>s.code===addressObj.stateProvinceCode)
        if (stateObj) {
          setFieldValue(`${contactType}State`, stateObj.id)
        }
      }

      if (addressObj.City) setFieldValue(`${contactType}City`, addressObj.City)
      if (addressObj.PostalCode) setFieldValue(`${contactType}ZipCode`, addressObj.PostalCode)
      if (addressObj.CountryCode) {
        let selectedCountry = countries.find((c)=>c.code===addressObj.CountryCode)
        if (selectedCountry) {
          setCountryId(selectedCountry.id)
          setFieldValue('country',selectedCountry.id)
        }
      }
    }

    return <Box p={1} pb={2}>
      {validation&&<UPSSuggestionBox validation={validation} handleSuggestionSelect={setSuggestedAddress} />}
    </Box>
  }

  // const countryMap = useMemo(() => createMap(countries, "code"), [countries]);

  function readProperty(property, place) {
    return place.address_components.find((x) =>
      x.types.find((item) => item === property)
    );
  }

  const handlePlaceSelection =
    async (place, setFieldValue, type, countries) => {
      // initialize on place change
      const zipCode = readProperty("postal_code", place);
      const state = readProperty("administrative_area_level_1", place);
      const city = readProperty("locality", place);
      const country = readProperty("country", place);
      const route = readProperty("route", place);
      const streetNo = readProperty("street_number", place);
      const addressLine1 =
        combineStrings(" ", streetNo?.long_name, route?.long_name) ||
        city?.long_name ||
        state?.long_name ||
        country?.long_name;

      const payload = {
        zipCode: zipCode?.short_name,
        city: city?.short_name,
        addressLine1,
      };

      if (country) {
        // const appCountry = countryMap.get(country.short_name);
        const appCountry = countries.find((c)=>c.code===country.short_name);
        const url = `${ApiEndpoint[EntityType.State]}?countryId=${appCountry.id}`;
        payload.countryId = appCountry?.id;
        try {
          const states = await fetchData(url);
          const appState =
            states.find((x) => x.code === state?.short_name) ||
            states[0]?.id ||
            null;
          payload.stateId = appState?.id;
        } catch (ex) {}
      }

      setFieldValue(`${type}ZipCode`,payload.zipCode?payload.zipCode:'')
      setFieldValue(`${type}City`, payload.city?payload.city:'')
      setFieldValue(`${type}AddressLine1`,payload.addressLine1?payload.addressLine1:'')
      if (type==='business') {
        setFieldValue('country', payload.countryId)
        setCountryId(payload.countryId)
      }
    }


  return <>
    <Formik innerRef={formRef}
            initialValues={{...formData}}
            enableReinitialize={true}
            // validate={validate}
            validationSchema={tradeAccountValidation}
            onSubmit={(values, setFieldsetFieldValue)=>{
              setFormData(prevFormData => ({...prevFormData, ...values, contactInformationSubmitted: true }))
              if (setStep) setStep(step + 1)
            }}
    >
      {({values,errors,setFieldValue, isValid, dirty}) => (<Form noValidate>
        <FormikContext.Provider value={{disabled,locked}}>

        <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" style={{fontWeight: 700, marginTop: 16}}>
            Business Contact Information
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <SectionTitle>Business Address</SectionTitle>
          {/*{JSON.stringify(errors)}*/}
        </Grid>

          <Grid item xs={12}>
            {/*<FormikTextField*/}
            {/*  variant={'outlined'}*/}
            {/*  margin="none"*/}
            {/*  size={'small'}*/}
            {/*  fullWidth*/}
            {/*  type={'text'}*/}
            {/*  id={'businessAddressLine1'}*/}
            {/*  name={'businessAddressLine1'}*/}
            {/*  label={'Business Address Line 1'}*/}
            {/*/>*/}
            {countries&&countries.length!==0&&<FormikGooglePlacesAutoComplete
              fullWidth
              margin="none"
              size="small"
              variant='outlined'
              label={"Address Line 1"}
              placeholder={"Address Line 1"}
              disabled={false}
              type={'text'}
              id={'businessAddressLine1'}
              name={'businessAddressLine1'}
              onPlaceSelected={(p)=>handlePlaceSelection(p,setFieldValue,'business', countries)}
            />}
          </Grid>

          <Grid item xs={12}>
            <FormikTextField
              variant={'outlined'}
              margin="none"
              size={'small'}
              fullWidth
              type={'text'}
              id={'businessAddressLine2'}
              name={'businessAddressLine2'}
              label={'Address Line 2 (unit, suit, apartment, floor) (optional)'}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormikSelect
              variant={'outlined'}
              margin="none"
              fullWidth
              id={'country'}
              name={'country'}
              label={'Country'}
              options={countries}
              disabled={countries.length === 0}
              handleChange={(v) => setCountryId && setCountryId(v)}
            />
          </Grid>

        <Grid item xs={12} md={6}>
          <FormikTextField
            variant={'outlined'}
            margin="none"
            size={'small'}
            fullWidth
            type={'text'}
            id={'businessZipCode'}
            name={'businessZipCode'}
            label={'Zip / Postal code'}
          />
          <HandlePostalCodeChange contactType={'business'}/>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormikTextField
            variant={'outlined'}
            margin="none"
            size={'small'}
            fullWidth
            type={'text'}
            id={'businessCity'}
            name={'businessCity'}
            label={'Town / City'}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormikSelect
            variant={'outlined'}
            margin="none"
            fullWidth
            id={'businessState'}
            name={'businessState'}
            label={'State'}
            options={states}
            disabled={states.length === 0}
          />
        </Grid>


          <Grid item xs={12} md={12}>
            <FormikTextField
              variant={"outlined"}
              margin="none"
              size={"small"}
              fullWidth
              type={"text"}
              id={"businessPhoneNumber"}
              name={"businessPhoneNumber"}
              label={"Business Phone No."}
              countryCode={countryCode}
            />
            {validatePhone&&<PhoneHandler />}
            {validatePhone&&phoneError&&<Box pl={1} pt={1} style={{textTransform:'uppercase',color:'orange'}}>This phone is already in use.</Box>}
          </Grid>

          {!disabled&&<HandleAddressChange contactType={'business'} />}

        <Grid item xs={12}>
          <SectionTitle>Primary Billing Address</SectionTitle>
        </Grid>

        <Grid item xs={12}>
          <FormikCheckbox
            label={'Same as business address'}
            id={'sameAsBusinessAddressBilling'}
            name={'sameAsBusinessAddressBilling'}
          />
        </Grid>

        {!values.sameAsBusinessAddressBilling && <>

          <Grid item xs={12}>
            {countries&&countries.length!==0&&<FormikGooglePlacesAutoComplete
              fullWidth
              margin="none"
              size="small"
              variant='outlined'
              label={"Address Line 1"}
              placeholder={"Address Line 1"}
              disabled={false}
              type={'text'}
              id={'billingAddressLine1'}
              name={'billingAddressLine1'}
              onPlaceSelected={(p)=>handlePlaceSelection(p,setFieldValue,'billing',countries)}
            />}
          </Grid>

          <Grid item xs={12}>
            <FormikTextField
              variant={'outlined'}
              margin="none"
              size={'small'}
              fullWidth
              type={'text'}
              id={'billingAddressLine2'}
              name={'billingAddressLine2'}
              label={'Address Line 2 (unit, suit, apartment, floor) (optional)'}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormikSelect
              variant={'outlined'}
              margin="none"
              fullWidth
              id={'country'}
              name={'country'}
              label={'Country'}
              options={countries}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormikTextField
              variant={'outlined'}
              margin="none"
              size={'small'}
              fullWidth
              type={'text'}
              id={'billingZipCode'}
              name={'billingZipCode'}
              label={'Zip / Postal code'}
            />
            <HandlePostalCodeChange contactType={'billing'}/>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormikTextField
              variant={'outlined'}
              margin="none"
              size={'small'}
              fullWidth
              type={'text'}
              id={'billingCity'}
              name={'billingCity'}
              label={'Town / City'}

            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormikSelect
              variant={'outlined'}
              margin="none"
              fullWidth
              id={'billingState'}
              name={'billingState'}
              label={'STATE'}
              options={states}
              disabled={states.length === 0}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormikTextField
              variant={'outlined'}
              margin="none"
              size={'small'}
              fullWidth
              type={'email'}
              id={'billingEmail'}
              name={'billingEmail'}
              label={'Email (Optional)'}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormikTextField
              variant={'outlined'}
              margin="none"
              size={'small'}
              fullWidth
              type={'text'}
              id={'billingPhoneNumber'}
              name={'billingPhoneNumber'}
              label={'Phone No. (Optional)'}
            />
          </Grid>

          {!disabled&&<HandleAddressChange contactType={'billing'} />}

        </>}

        <Grid item xs={12}>
          <SectionTitle>Default Shipping Address</SectionTitle>
        </Grid>

        <Grid item xs={12}>
          <FormikCheckbox
            label={'Same as business address'}
            id={'sameAsBusinessAddressShipping'}
            name={'sameAsBusinessAddressShipping'}
          />
        </Grid>

        {!values.sameAsBusinessAddressShipping && <>

          <Grid item xs={12}>
            {countries&&countries.length!==0&&<FormikGooglePlacesAutoComplete
              fullWidth
              margin="none"
              size="small"
              variant='outlined'
              label={"Address Line 1"}
              placeholder={"Address Line 1"}
              disabled={false}
              type={'text'}
              id={'shippingAddressLine1'}
              name={'shippingAddressLine1'}
              onPlaceSelected={(p)=>handlePlaceSelection(p,setFieldValue,'shipping',countries)}
            />}
          </Grid>

          <Grid item xs={12}>
            <FormikTextField
              variant={'outlined'}
              margin="none"
              size={'small'}
              fullWidth
              type={'text'}
              id={'shippingAddressLine2'}
              name={'shippingAddressLine2'}
              label={'Address Line 2 (unit, suit, apartment, floor) (optional)'}

            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormikSelect
              variant={'outlined'}
              margin="none"
              fullWidth
              id={'country'}
              name={'country'}
              label={'Country'}
              options={countries}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormikTextField
              variant={'outlined'}
              margin="none"
              size={'small'}
              fullWidth
              type={'text'}
              id={'shippingZipCode'}
              name={'shippingZipCode'}
              label={'Zip / Postal code'}
            />
            <HandlePostalCodeChange contactType={'shipping'}/>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormikTextField
              variant={'outlined'}
              margin="none"
              size={'small'}
              fullWidth
              type={'text'}
              id={'shippingCity'}
              name={'shippingCity'}
              label={'Town / City'}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormikSelect
              variant={'outlined'}
              margin="none"
              fullWidth
              id={'shippingState'}
              name={'shippingState'}
              label={'State'}
              options={states}
              disabled={states.length === 0}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormikTextField
              variant={'outlined'}
              margin="none"
              size={'small'}
              fullWidth
              type={'email'}
              id={'shippingEmail'}
              name={'shippingEmail'}
              label={'Email (Optional)'}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormikTextField
              variant={'outlined'}
              margin="none"
              size={'small'}
              fullWidth
              type={'text'}
              id={'shippingPhoneNumber'}
              name={'shippingPhoneNumber'}
              label={'Phone No. (Optional)'}
            />
          </Grid>

          {!disabled&&<HandleAddressChange contactType={'shipping'} />}

        </>}

        <Box mt={4}/>

          {setStep&&<Grid item container spacing={2} justify='center' alignContent='center' alignItems="flex-start">

          <Grid item xs={12} md={6}>
            {step !== 1 && <>
              <Button size='large' variant={'outlined'} fullWidth onClick={() => setStep(step - 1)}>GO BACK</Button>
            </>}
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Button size='large' variant={'contained'} color={'secondary'} fullWidth type='submit'
                        disabled={disableActions || !isValid || (!dirty && !formData.contactInformationSubmitted) }
                > NEXT </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  size="large"
                  variant={"outlined"}
                  fullWidth
                  onClick={()=>{
                    setFormData(prevFormData => ({...prevFormData, ...values, contactInformationSubmitted: true, firstLoad:false }))
                  }}
                  disabled={disableActions || !isValid || (!dirty && !formData.contactInformationSubmitted) }
                >
                  {" "}
                  {"SAVE APPLICATION"}
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </Grid>}

      </Grid>

        </FormikContext.Provider>
      </Form>)}
    </Formik>
  </>
}
