import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import Text from "../../../components/Text/Text";
import ProductCard from "../../../components/Cards/ProductCard/ProductCard";
import Button from "../../../components/Button/Button";
import ProductCardWithHooks from "../../../components/Cards/ProductCard/ProductCartWithHooks";
import ProductDialog from "../../Catalog/ProductDialog";
import { connect } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { useCurrentCart } from "../../../hooks/cart";
import { useFavorites } from "../../../hooks/favorites";
import { getUserId } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    textTransform: "uppercase",
    textAlign: "center",
  },
  button: {
    minWidth: 400,
    margin: "auto",
    borderColor: theme.palette.secondary.main,
    borderWidth: 4,
    "&:hover": {
      borderWidth: 4,
    },
  },
}));

function CollaboratorProducts(props) {
  const classes = useStyles();
  const { collaborator, loading, context } = props;

  const { firstName, lastName, products, collection } = collaborator;
  const fullName = `${firstName} ${lastName}`;

  const [selectedProduct, setSelectedProduct] = useState({});
  const [openPreview, setOpenPreview] = useState(false);

  const { handleAddMemo, cart } = useCurrentCart();

  const handlePreview = useCallback((product) => {
    setSelectedProduct(product);
    setOpenPreview(true);
  }, []);

  const [favoriteProduct, setFavoriteProduct] = useState({});
  const userId = context.Cart.spoofingUserId || getUserId();
  useFavorites(favoriteProduct, products, setFavoriteProduct, userId);

  function renderGridLoader() {
    return (
      <Grid container spacing={4}>
        {[...Array(6)].map((v, i) => (
          <Grid key={i} item lg={2} md={4} sm={6} xs={12}>
            <ProductCard isLoading product={{}} />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Grid container direction={"column"} spacing={2}>
      <Grid item style={{ position: "relative" }}>
        <Box className={classes.titleBox}>
          {loading ? (
            <Skeleton
              variant={"rect"}
              style={{ margin: "auto" }}
              width={300}
              height={36}
            />
          ) : (
            <Text
              className={classes.title}
              variant={"h4"}
              uppercase
              color={"textSecondary"}
            >
              {`${fullName}'S Designs`}
            </Text>
          )}
        </Box>
      </Grid>
      <Grid item>
        {loading ? (
          renderGridLoader()
        ) : (
          <Grid container spacing={4}>
            {products.slice(0, 6).map((product) => (
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <ProductCardWithHooks
                  handleMemo={handleAddMemo}
                  handlePreview={handlePreview}
                  product={product}
                  activeCart={cart}
                  toggleFavorite={() => setFavoriteProduct(product)}
                  size={"auto"}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
      {!loading && collection.slug && (
        <Grid item style={{ display: "flex" }}>
          <Button
            variant={"outlined"}
            size={"large"}
            component={Link}
            to={`/collections/${collection.slug}`}
            color={"secondary"}
            className={classes.button}
          >
            SHOP THE COLLECTION
          </Button>
        </Grid>
      )}
      <ProductDialog
        open={openPreview}
        handleClose={() => setOpenPreview(false)}
        itemNumber={selectedProduct?.itemNumber}
        products={products}
      />
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      Cart: state.Cart,
    },
  };
};

export default connect(mapStateToProps, {})(CollaboratorProducts);
