import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import CategoryCard from "../../components/Cards/CategoryCard/CategoryCard";
import BrandingImage1 from "../../assets/images/Branding-Section/Branding_Tile_1.jpg";
import BrandingImage2 from "../../assets/images/Branding-Section/Branding_Tile_2.jpg";
import BrandingImage3 from "../../assets/images/Branding-Section/Branding_Tile_3.jpg";
import BrandingImage4 from "../../assets/images/Branding-Section/Branding_Tile_4.jpg";
import BrandingImage5 from "../../assets/images/Branding-Section/Branding_Tile_5.jpg";
import BrandingImage6 from "../../assets/images/Branding-Section/Branding_Tile_6.jpg";

const branding = [
  { category: "Craft", image: BrandingImage1 },
  { category: "Collaborations", image: BrandingImage2 },
  { category: "Artisans", image: BrandingImage3 },
  { category: "Home", image: BrandingImage4 },
  { category: "Community", image: BrandingImage5 },
  { category: "Inclusivity", image: BrandingImage6 },
];

function Branding(props) {
  return (
    <div>
      <Grid
        container
        spacing={4}
        justify={"space-between"}
        style={{ maxWidth: 1280, marginLeft: "auto", marginRight: "auto" }}
      >
        {branding.slice(0, 3).map(({ category, image }) => (
          <Grid item style={{ flex: 1, maxWidth: 352 }}>
            <CategoryCard category={category} image={image} />
          </Grid>
        ))}
      </Grid>
      <Box mt={6} />
      <Typography
        variant="h1"
        style={{
          textAlign: "center",
          fontSize: 56,
          maxWidth: 1280,
          margin: "auto",
          lineHeight: "100%",
          letterSpacing: "-0.025em",
          color: "#1B1C1D",
        }}
      >
        Family-owned since 1889, Schumacher is a style leader and design
        innovator. A passion for luxury and an unwavering commitment to beauty
        are woven into everything we do.
      </Typography>
      <Box mt={6} />
      <Grid
        container
        spacing={4}
        justify={"space-between"}
        style={{ maxWidth: 1280, marginLeft: "auto", marginRight: "auto" }}
      >
        {branding.slice(3, branding.length).map(({ category, image }) => (
          <Grid item style={{ flex: 1, maxWidth: 352 }}>
            <CategoryCard category={category} image={image} />
          </Grid>
        ))}
      </Grid>
      <Box mt={4} />
    </div>
  );
}

export default Branding;
