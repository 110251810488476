import {Box, Container, Grid, TableCell, TableRow, Typography} from "@material-ui/core";
import {Form, Formik} from "formik";
import Button from "../../../components/Button/Button";
import {connect} from "react-redux";
import {apiError, loginUser, resetPassword, resetPasswordSuccess} from "../../../store/auth/account/actions";
import FormikSelect from "components/Core/FormikFields/FormikSelect";
import FormikTextField from "components/Core/FormikFields/FormikTextField";
import * as Yup from "yup";
import {API_URL} from "config";
import axios from "axios";
import {getAxiosConfig} from "utils";
import {useState} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";

const NO_TRADE_ACCOUNT = 0
const HAS_TRADE_ACCOUNT = 1
const NOT_SURE = 2

const hasTradeAccountOptions = [
  {
    id: HAS_TRADE_ACCOUNT,
    name: "Yes"
  },
  {
    id: NO_TRADE_ACCOUNT,
    name: "No"
  },
  {
    id: NOT_SURE,
    name: "I’m not sure"
  }
]

const validationSchema = Yup.object().shape({
  tradeAccountNo: Yup.string().test(
    'tradeAccountNo-Test',
    'This field is required',
    (value, context) => {
      const values = context.parent
      if (!value && (!values.businessName) && (!values.zipCode) ) {
        return false
      } else {
        return (values.businessName || values.zipCode || value)
      }
    }
  ),
  businessName: Yup.string().test(
    'businessName-zipCode',
    'This field is required',
    (value, context) => {
      const values = context.parent
      return !(!values.businessName && values.zipCode)
    }
  ),
  zipCode: Yup.string().test(
    'zipCode-businessName',
    'This field is required',
    (value, context) => {
      const values = context.parent
      return !(values.businessName && !values.zipCode)
    }
  ),
})

const TradeProgram = (props) => {

  const {
    history
    // error,
    // loading,
    // resetPassword,
    // hasResetPassword,
    // loginUser
  } = props;

  const [isBusy, setIsBusy] = useState(false)
  const [results, setResults] = useState(false)

  const applyForTradeAccount = () => {
    history.push('/account/verify')
  }

  const requestToJoin = (id) => {
    history.push(`/account/join-account/${id}`)
  }

  return (
    <>
      <Container style={{minHeight: '90vh'}}>
        <Box mt={6}/>

        <Grid item container spacing={2}>

          <Grid item md={12}>
            <Box style={{fontFamily: 'Chronicle', fontSize: 56}}>
              Schumacher Trade Program
            </Box>
          </Grid>

          <Grid item md={6}>
            <Box pt={2}>
              <Typography variant='h5' fontSize={20}>
                Join today to access designated personal help, check product inventory, get exclusive net prices and so
                much more.
              </Typography>
            </Box>
          </Grid>

          <Box pt={14}/>

          <Grid item container spacing={2}>

            <Formik initialValues={{
              hasTradeAccount: null,
              zipCode: '',
              businessName: '',
              tradeAccountNo: ''
            }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {

                      const url = `${API_URL}/trade-account?zipCode=${values.zipCode}&businessName=${values.businessName}&tradeAccountNo=${values.tradeAccountNo}`;
                      setIsBusy(true);

                      axios
                        .get(url, getAxiosConfig())
                        .then((response) => {
                          setIsBusy(false);
                          setResults(response.data)
                        })
                        .catch((err) => {
                          // Silent error
                          setIsBusy(false);
                          if (err.response && err.response.data && err.response.data.message) {
                            console.log(err.response.data.message);
                          }
                          throw err;
                        });


                    }}
            >
              {({values, isValid}) => (
                <Form noValidate style={{width: '100%'}}>

                  <Grid item xs={12} sm={6}>
                    <FormikSelect
                      variant={'outlined'}
                      margin="none"
                      fullWidth
                      id={'hasTradeAccount'}
                      name={'hasTradeAccount'}
                      label={'Does your company have a Schumacher trade account?'}
                      options={hasTradeAccountOptions}
                    />
                  </Grid>

                  {(values.hasTradeAccount === HAS_TRADE_ACCOUNT || values.hasTradeAccount === NOT_SURE) &&
                  <>
                    <Grid item xs={12} sm={6}>
                      <Box p={3} pl={0} fontWeight={'bold'}>
                        Find my company’s trade account
                      </Box>
                    </Grid>

                    <Grid item container spacing={4}>

                      <Grid item xs={12} sm={6}>

                        <Grid item xs={12} sm={12}>
                          <FormikTextField
                            variant={'outlined'}
                            margin="none"
                            size={'small'}
                            fullWidth
                            type={'text'}
                            id={'tradeAccountNo'}
                            name={'tradeAccountNo'}
                            label={values.hasTradeAccount === HAS_TRADE_ACCOUNT ? 'Trade Account No. or Tax Registration ID' : 'Tax Registration ID'}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Box mt={3} mb={3} ml={1}> Or </Box>
                        </Grid>

                        <Grid item container spacing={4}>
                          <Grid item xs={12} md={5}>
                            <FormikTextField
                              variant={'outlined'}
                              margin="none"
                              size={'small'}
                              fullWidth
                              type={'text'}
                              id={'businessName'}
                              name={'businessName'}
                              label={'Business Name'}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Grid item container justify='center' alignItems='center' alignContent='center'>
                              <Grid item>
                                <Box pt={1}> and </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <FormikTextField
                              variant={'outlined'}
                              margin="none"
                              size={'small'}
                              fullWidth
                              type={'text'}
                              id={'zipCode'}
                              name={'zipCode'}
                              label={'Account Zip or Postal Code'}
                            />
                          </Grid>

                          <Grid item container justify='flex-end' alignItems='center' alignContent='center'>
                            <Grid item>
                              <Button size='large' variant='contained' color={'secondary'} type='submit' disabled={isBusy}>FIND MY
                                COMPANY</Button>
                            </Grid>
                          </Grid>

                        </Grid>

                        {results&&<Grid item container spacing={2}>

                          <Grid item xs={12}>
                            <Box fontWeight='bold' pl={1}>
                              {results.length!==0&&<>We found {results.length} results based on your inputs</>}
                              {results.length===0&&<>We can’t find your company in our system</>}
                            </Box>

                          </Grid>

                          {results&&results.length!==0&&<Grid item xs={12}>
                            <Table>
                              <TableHead>
                                <TableCell>
                                  Business Name
                                </TableCell>
                                <TableCell>
                                  Business Phone No.
                                </TableCell>
                                <TableCell>

                                </TableCell>
                              </TableHead>
                              <TableBody>
                                {results.map((row)=>{
                                  return <TableRow>
                                    <TableCell style={{maxWidth:200}}>
                                      {row.businessName}
                                    </TableCell>
                                    <TableCell>
                                      {row.businessPhone}
                                    </TableCell>
                                    <TableCell align='right'>
                                      <Button variant='contained' color='secondary' onClick={()=>requestToJoin(row.id)}> REQUEST TO JOIN </Button>
                                    </TableCell>
                                  </TableRow>
                                })}
                              </TableBody>
                            </Table>
                          </Grid>}

                          <Grid item container justify={'center'}>
                            <Grid item>
                              <Box onClick={()=>setResults(false)} p={2} style={{cursor: 'pointer', textDecoration:'underline'}}> Clear Results </Box>
                            </Grid>
                          </Grid>

                          <Grid item container>
                            <Grid item xs={12}>
                              <Box pt={2} pb={2} pl={1} fontWeight='bold'>
                                Don’t see your company listed here?
                              </Box>
                            </Grid>

                            <Grid item container justify='space-between'>
                              <Grid item xs={7}>
                                <Button fullWidth size='large' variant='outlined'
                                        onClick={applyForTradeAccount}>APPLY FOR A TRADE
                                  ACCOUNT</Button>
                              </Grid>
                              <Box pt={2} />
                              <Grid item xs={4}>
                                <Button fullWidth size='large' variant='outlined' onClick={() => history.push('/')}> SKIP IT
                                  FOR NOW </Button>
                              </Grid>
                            </Grid>

                          </Grid>

                        </Grid>}

                      </Grid>

                    </Grid>


                  </>
                  }

                  {values.hasTradeAccount === NO_TRADE_ACCOUNT && <>

                    <Grid item container spacing={2}>

                      <Grid item xs={12} md={6}>
                        <Box pt={3}/>

                        <Grid item container justify='space-between'>
                          <Grid item xs={7}>
                            <Button fullWidth size='large' variant='contained' color='secondary'
                                    onClick={applyForTradeAccount}>APPLY FOR A TRADE
                              ACCOUNT</Button>
                          </Grid>
                          <Grid item xs={4}>
                            <Button fullWidth size='large' variant='outlined' onClick={() => history.push('/')}> SKIP IT
                              FOR NOW </Button>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>

                  </>}

                </Form>
              )}

            </Formik>
          </Grid>


        </Grid>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => {
  const {error, loading, isAuth, hasResetPassword} = state.Auth;
  return {error, loading, isAuth, hasResetPassword};
};

export default connect(mapStateToProps, {resetPassword, apiError, loginUser, resetPasswordSuccess})(TradeProgram);
