import { API_URL } from "../../config";
import { EntityType } from "./entityType";

export const ApiEndpoint = {
  [EntityType.User]: `${API_URL}/api/users`,
  [EntityType.Filter]: `${API_URL}/filters`,
  [EntityType.Product]: `${API_URL}/products`,
  [EntityType.Collection]: `${API_URL}/collections`,
  [EntityType.Account]: `${API_URL}/account`,
  [EntityType.Collaborator]: `${API_URL}/collaborators`,
  [EntityType.Address]: `${API_URL}/addresses`,
  [EntityType.State]: `${API_URL}/config/states`,
  [EntityType.Country]: `${API_URL}/config/countries`,
  [EntityType.ShippingMethod]: `${API_URL}/shipping-methods`,
  [EntityType.NavigationMenu]: `${API_URL}/navigation/menu`,
  [EntityType.SalesTerritory]: `${API_URL}/sales-territories`
};
