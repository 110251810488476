import { createMuiTheme } from "@material-ui/core/styles";
import React from "react";
import * as colors from "./colors";
import { errorColor } from "./colors";
// import { useMediaQuery } from "@material-ui/@core";
// import ChronicleDisplayLight from '../assets/fonts/ChronicleDisp-Light.otf'
// import ChronicleDisplayBold from '../assets/fonts/ChronicleDisp-Bold.otf'
//
// const chronicleDisplay = {
//   fontFamily: 'Chronicle',
//   fontStyle: 'normal',
//   fontDisplay: 'swap',
//   fontWeight: 300,
//   src: `
//     local('ChronicleDisp-Light'),
//     local('ChronicleDisp-Bold'),
//     url(${ChronicleDisplayLight}) format('otf')
//   `,
//   unicodeRange:
//       'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
// };

const overrides = () => {
  return {
    MuiTooltip: {
      tooltip: {
        fontSize: "14px",
        padding: 8,
        fontWeight: 400,
        borderRadius: 10,
        boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        color: colors.textColor.secondary,
        backgroundColor: "white",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 20,
      },
    },
    MuiFilledInput: {
      root:{
        color:'black'
      },
      underline: {
        borderRadius: 0,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 30,
        color:'black',
      },
      notchedOutline: {
        borderColor: 'black'
      }
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "initial"
        }
      }
    },
    MuiFormLabel: {
      root: {
        fontFamily: "Gotham, sans-serif",
        fontSize: 14
      }
    },
    MuiButton: {
      outlinedSecondary: {
        color: colors.secondaryLight.contrastText,
        "&:hover": {
          backgroundColor: colors.secondaryLight.main,
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: colors.secondaryLight.main,
          }
        }
      },
      outlined: {
        borderColor: 'black',
        fontWeight: 'bold'
      },
      contained:{
        boxShadow:'none',
      },
      containedSecondary: {
        color: 'black',
        "&:disabled": {
          backgroundColor: colors.secondaryLight.light
        }
      },
      root: {
        borderRadius: 30,
        fontWeight: 'bold'
      },
      label: {
        fontFamily: "Gotham, sans-serif",
      },
    },
    // MuiCssBaseline: {
    //   '@global': {
    //     '@font-face': [chronicleDisplay],
    //   },
    // },
  };
};

const typography = () => {
  return {
    // fontFamily: ["Chronicle", "Gotham"],
    h1: {
      fontFamily: "Chronicle",
      fontSize: "2.5rem",
      fontWeight: "normal",
      lineHeight: 1.2,
      letterSpacing: "-2.5%"
    },
    h2: {
      fontFamily: "Chronicle",
      fontSize: "2.5rem",
      fontWeight: "normal",
      lineHeight: 1.2,
      letterSpacing: "0%"
    },
    h3: {
      fontFamily: "Chronicle",
      fontSize: "2rem",
      fontWeight: "normal",
      lineHeight: 1.2,
      letterSpacing: "0.5%"
    },
    h4: {
      fontFamily: "Chronicle",
      fontSize: "2rem",
      fontWeight: "normal",
      lineHeight: 1.2,
      letterSpacing: "1%"
    },
    h5: {
      fontFamily: "Chronicle",
      fontSize: "1.33rem",
      fontWeight: "normal",
      lineHeight: 1.2,
      letterSpacing: "1.25%"
    },
    h6: {
      fontFamily: "Chronicle",
      fontSize: "1rem",
      fontWeight: "normal",
      lineHeight: 1.2,
    },
    body1: {
      fontFamily: "Gotham, sans-serif",
      fontSize: "1rem",
      fontWeight: "normal",
      lineHeight: 1.4,
      letterSpacing: "2%"
    },
  };
};

function getTheme(prefersDarkMode) {
  return createMuiTheme({
    palette: {
      type: prefersDarkMode ? "dark" : "light",
      primary: prefersDarkMode ? colors.primaryDark : colors.primaryLight,
      bannerColor: colors.greenLight.primary,
      black: colors.black.primary,
      error: errorColor,
      text: {
        primary: prefersDarkMode ? colors.textColorDark.primary : colors.textColor.primary,
        secondary: prefersDarkMode ? colors.textColorDark.secondary: colors.textColor.secondary,
      },
      secondary: prefersDarkMode ? colors.secondaryDark : colors.secondaryLight,
      background: prefersDarkMode
        ? colors.backgroundDark
        : colors.backgroundLight,
    },
    borderRadius: 0,
    overrides: overrides(prefersDarkMode),
    typography: typography(prefersDarkMode),
  });
}

function useAppTheme() {
  const prefersDarkMode = false;
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  return React.useMemo(() => getTheme(prefersDarkMode), [prefersDarkMode]);
}

export { colors, useAppTheme, getTheme };
