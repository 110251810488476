import React from "react";
import { ApiEndpoint } from "../../store/@core/endpoint";
import { EntityType } from "../../store/@core/entityType";
import { Box, Container, Grid } from "@material-ui/core";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import CollaboratorCard from "../../components/CollaboratorCard/CollaboratorCard";
import { useFetch } from "reactcoregk/hooks";

const url = ApiEndpoint[EntityType.Collaborator];

export function renderCollaboratorsLoader() {
  return (
      <Grid container spacing={4}>
        {[...Array(8)].map((v, i) => (
            <Grid key={i} item md={3} sm={6} xs={12}>
              <CollaboratorCard isLoading collaborator={{}} />
            </Grid>
        ))}
      </Grid>
  );
}

function Collaborators(props) {
  const [loading, collaborators] = useFetch(url, {}, []);

  return (
    <Box>
      <Container>
        <Box mt={4} />
        <Breadcrumbs path={[{ name: "Collaborators", path: "#" }]} />
        <Box mt={2} />
        {loading ? (
            renderCollaboratorsLoader()
        ) : (
          <Grid container spacing={4}>
            {collaborators.map((collaborator) => (
              <Grid item md={3} sm={6} xs={12}>
                <CollaboratorCard collaborator={collaborator} />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Box>
  );
}

export default Collaborators;
