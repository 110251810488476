import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createMap } from "reactcoregk/utils";
import { API_URL } from "../config";
import { fetchData, postData } from "../utils";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { CHECKOUT_STEPS, orderItemType } from "../constants/enums";
import { useDispatch, useSelector } from "react-redux";
import {
  addMemoToCart,
  deleteProductFromCart,
  deleteProductsFromCart,
  updateProductOfCart,
  addProductToCart,
  openCheckout,
  toggleOrderItem,
  getOpenCarts,
  closeCheckout,
} from "../store/cart/actions";
import { useProfileIds } from "./auth";

export const useEntityToggle = (state = null) => {
  const [checked, setChecked] = React.useState(state);

  const handleToggle = useCallback(
    (value) => () => {
      setChecked((prevChecked) => (prevChecked === value ? null : value));
    },
    []
  );

  useEffect(() => {
    if (state) {
      setChecked(state);
    }
  }, [state]);

  return [checked, handleToggle];
};

export const useCurrentCart = () => {
  const dispatch = useDispatch();
  const cartContext = useSelector((state) => state.Cart);
  const { activeUserId } = useProfileIds();

  const {
    currentCartId,
    carts,
    orderItemUpdate,
    orderItemDelete,
    orderItemsDelete,
    memo,
    product,
    monitoring,
  } = cartContext;

  const processingCart =
    memo.isLoading ||
    product.isLoading ||
    orderItemUpdate.isLoading ||
    orderItemDelete.isLoading ||
    orderItemsDelete.isLoading;

  const cartMap = createMap(carts.result);
  const cart = cartMap.get(currentCartId);
  const numberOfItems = cart?.items.length;

  const handleRemoveItem = useCallback(
    (itemId) => {
      dispatch(
        deleteProductFromCart({
          cartId: currentCartId,
          itemId: itemId,
        })
      );
    },
    [currentCartId, dispatch]
  );

  const handleRemoveItems = useCallback(
    (itemsIds) => {
      dispatch(
        deleteProductsFromCart({
          cartId: currentCartId,
          itemIds: itemsIds,
        })
      );
    },
    [currentCartId, dispatch]
  );

  const handleUpdateItem = useCallback(
    (item, callback) => {
      dispatch(
        updateProductOfCart(
          {
            cartId: currentCartId,
            itemId: item.id,
            quantity: item.quantity,
            notes: item.notes,
            productId: item.productId
          },
          callback
        )
      );
    },
    [currentCartId, dispatch]
  );

  const handleAddMemo = useCallback(
    (item, callback) => {
      dispatch(
        addMemoToCart(
          {
            cartId: currentCartId,
            productId: item.id,
            quantity: 1,
            notes: "",
          },
          callback
        )
      );
    },
    [currentCartId, dispatch]
  );

  // TODO: Manolis -> transfer this logic to sagas
  const handleAddFavoritesToMemo = useCallback(
    (items, callback) => {
      const allFavorites = [];
      items.forEach((item) => {
        allFavorites.push({
          type: orderItemType.SAMPLE,
          productId: item.id,
          quantity: 1,
          notes: "",
        });
      });
      postData(
        `${API_URL}/users/${activeUserId}/carts/${currentCartId}/items/multiple`,
        allFavorites
      ).then(() => callback());
    },
    [currentCartId, activeUserId]
  );

  const handleAddProduct = useCallback(
    (item, callback) => {
      dispatch(
        addProductToCart(
          {
            cartId: currentCartId,
            productId: item.id,
            quantity: item.quantityIncrement,
            notes: "",
          },
          callback
        )
      );
    },
    [currentCartId, dispatch]
  );

  return {
    cart,
    numberOfItems,
    handleUpdateItem,
    handleRemoveItem,
    handleRemoveItems,
    handleAddProduct,
    handleAddMemo,
    processingCart,
    monitoring,
    handleAddFavoritesToMemo,
  };
};

export const useAmounts = (cart) => {
  return useMemo(() => {
    return {
      netAmount: cart?.items.reduce((acc, prd) => acc + prd.netAmount, 0) || 0,
    };
  }, [cart?.items]);
};

export const useOrder = (cart, triggerTaxRefresh) => {
  const { order } = useCheckout(cart);
  const { activeUserId } = useProfileIds();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [amounts, setAmounts] = useState({
    netAmount: null,
    shippingCost: null,
    salesTaxPercentage: null,
    taxAmount: null,
    totalBeforeTaxAmount: null,
    totalAmount: null,
  });
  const { netAmount } = useAmounts(cart);

  const {
    itemIds,
    shippingAddressId,
    memoShippingMethodId,
    productShippingMethodId,
    paymentMethodId,
    notes,
    poNumber,
    projectName,
    memoPackingNotes,
    productPackingNotes,
    shippingAddress,
    paymentMethodOption,
    saveAddress
  } = order;

  useEffect(() => {
    (async () => {
      if (cart?.id && order.itemIds[0]) {
        const url = `${API_URL}/users/${activeUserId}/carts/${cart?.id}/checkout/calculation`;
        setLoading(true);
        try {
          const payload = {
            shippingAddressId: shippingAddressId > 0 ? shippingAddressId : null,
            memoShippingMethodId,
            productShippingMethodId,
            paymentMethodId,
            shippingAddress,
            paymentMethodOption,
            cartItemIds: itemIds,
          };
          const res = await postData(url, payload);
          setAmounts(res);
        } catch {}
        setLoading(false);
      } else {
        setAmounts({
          netAmount: null,
          shippingCost: null,
          salesTaxPercentage: null,
          taxAmount: null,
          totalBeforeTaxAmount: null,
          totalAmount: null,
        });
      }
    })();
  }, [
    paymentMethodOption,
    activeUserId,
    cart?.id,
    itemIds,
    memoShippingMethodId,
    order.itemIds,
    paymentMethodId,
    productShippingMethodId,
    shippingAddressId,
    netAmount,
    shippingAddress,
    triggerTaxRefresh,
  ]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const url = `${API_URL}/users/${activeUserId}/carts/${cart?.id}/checkout`;
      const payload = {
        shippingAddressId: shippingAddressId > 0 ? shippingAddressId : null,
        shippingAddress,
        memoShippingMethodId,
        productShippingMethodId,
        paymentMethodId,
        notes,
        memoPackingNotes,
        productPackingNotes,
        paymentMethodOption,
        poNumber,
        saveAddress,
        projectName,
        cartItemIds: itemIds,
      };
      await postData(url, payload);
      enqueueSnackbar(
        "We have received your order and will send you notifications once the order is in process",
        {
          variant: "success",
        }
      );
      history.push("/account/order-history");
      dispatch(getOpenCarts());
      dispatch(closeCheckout());
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
    setLoading(false);
  };

  return [loading, amounts, handleSubmit];
};

export const useCheckoutSteps = (hasProducts, price) => {
  if (hasProducts || price) {
    return { steps: CHECKOUT_STEPS, skipPayment: false };
  }
  return {
    steps: CHECKOUT_STEPS.filter((x) => x !== "PAYMENT"),
    skipPayment: true,
  };
};

export const useDeliveryOptions = (cartItems, itemType) => {
  const ids = useMemo(() => {
    return cartItems.map((x) => x.id);
  }, [cartItems]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (ids.length === 0) {
      return setOptions([]);
    }
    const url = `${API_URL}/checkout/shipping-methods/${itemType}?cartItemIds=${ids.toString()}`;
    setLoading(true);
    fetchData(url)
      .then((data) => {
        setOptions(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [ids, itemType]);

  return [options, loading];
};

export const useCartItems = (cart) => {
  return useMemo(() => {
    const products =
      cart?.items.filter((x) => x.type === orderItemType.PRODUCT) || [];
    const memos =
      cart?.items.filter((x) => x.type === orderItemType.SAMPLE) || [];
    return {
      products,
      memos,
    };
  }, [cart?.items]);
};

// Validate stepper
export const useCheckoutValidation = (
  steps,
  skipPayment,
  processingCart,
  numberOfItems,
  shippingAddressId,
  memoShippingMethodId,
  productShippingMethodId,
  paymentMethodId,
  hasMemos,
  hasProducts
) => {
  const disabledStep = () => {
    // Disabled when cart is in progress
    if (processingCart) return 0;
    // Disable bag when no items
    else if (numberOfItems === 0) return 0;
    // Disable address if no selected
    else if (!shippingAddressId) return 1;
    // Disable delivery if no selected according to checkout mode
    else if (hasMemos && hasProducts) {
      if (!memoShippingMethodId || !productShippingMethodId) return 2;
    } else if (hasProducts) {
      if (!productShippingMethodId) return 2;
    } else if (hasMemos) {
      if (!memoShippingMethodId) return 2;
    }
    // Disable payment if no payment selected
    if (!paymentMethodId && !skipPayment) return 3;
    return CHECKOUT_STEPS.length;
  };
  return disabledStep();
};

export const useCheckout = (cart) => {
  const dispatch = useDispatch();
  const { memos, products } = useCartItems(cart);
  const memoIds = useMemo(() => memos.map((x) => x.id), [memos]);
  const productIds = useMemo(() => products.map((x) => x.id), [products]);
  const order = useSelector((state) => state.Cart.order);

  const handleOpenCheckoutMemos = useCallback(
    (selectedIds) => {
      dispatch(openCheckout(selectedIds[0] ? selectedIds : memoIds));
    },
    [dispatch, memoIds]
  );

  const handleOpenCheckoutProducts = useCallback(
    (selectedIds) => {
      dispatch(openCheckout(selectedIds[0] ? selectedIds : productIds));
    },
    [dispatch, productIds]
  );

  const handleOpenCheckoutAll = useCallback(
    (selectedIds) => {
      dispatch(
        openCheckout(selectedIds[0] ? selectedIds : [...memoIds, ...productIds])
      );
    },
    [dispatch, memoIds, productIds]
  );

  const onOrderItemToggle = useCallback(
    (itemId) => {
      dispatch(toggleOrderItem(itemId));
    },
    [dispatch]
  );

  const selectedMemos = useMemo(() => {
    return memos.filter((x) => order.itemIds.includes(x.id));
  }, [memos, order.itemIds]);

  const selectedProducts = useMemo(() => {
    return products.filter((x) => order.itemIds.includes(x.id));
  }, [products, order.itemIds]);

  const hasMemo = useMemo(() => {
    return selectedMemos.length > 0;
  }, [selectedMemos.length]);

  const hasProduct = useMemo(() => {
    return selectedProducts.length > 0;
  }, [selectedProducts.length]);

  const numberOfSelectedItems = useMemo(() => {
    return selectedProducts.length + selectedMemos.length;
  }, [selectedMemos.length, selectedProducts.length]);

  return {
    order,
    handleOpenCheckoutMemos,
    handleOpenCheckoutProducts,
    handleOpenCheckoutAll,
    onOrderItemToggle,
    hasMemo,
    hasProduct,
    selectedMemos,
    selectedProducts,
    numberOfSelectedItems,
  };
};
