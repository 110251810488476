import PropTypes from "prop-types";
import { useMemosAdded, useProductRules } from "../../../hooks/product";
import { connect } from "react-redux";
import ProductCard from "./ProductCard";

function ProductCardWithHooks({
  product,
  handlePreview,
  toggleFavorite,
  isLoading,
  handleMemo,
  activeCart,
  canOrder,
  size,
  isAuth,
  favoritesSelection=false,
  handleSelectedFavoriteToMemo,
  openExternally,
  isExcluded,
  highlightTerm = null
}) {
  const { sampleAvailable, sampleSupported } = useProductRules(product);
  const memosAdded = useMemosAdded(product, activeCart);


  handleMemo = canOrder ? handleMemo : undefined;

  return (
    <ProductCard
      handleMemo={handleMemo}
      product={product}
      handlePreview={handlePreview}
      isLoading={isLoading}
      toggleFavorite={toggleFavorite}
      memosAdded={memosAdded}
      sampleAvailable={sampleAvailable}
      sampleSupported={sampleSupported}
      size={size}
      isAuth={isAuth}
      favoritesSelection={favoritesSelection}
      handleSelectedFavoriteToMemo={handleSelectedFavoriteToMemo}
      openExternally={openExternally}
      isExcluded={isExcluded}
      highlightTerm={highlightTerm}
    />
  );
}

ProductCardWithHooks.propTypes = {
  product: PropTypes.object.isRequired,
  handlePreview: PropTypes.func.isRequired,
  handleMemo: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  toggleFavorite: PropTypes.func,
  activeCart: PropTypes.object,
  size: PropTypes.oneOf(["small", "auto", "normal"]),
};

ProductCardWithHooks.defaultProps = {
  activeCart: {
    items: [],
  },
};

const mapStateToProps = (state) => {
  return {
    canOrder: state.Auth.canOrder,
    isAuth: state.Auth.isAuth,
  };
};
export default connect(mapStateToProps, {})(ProductCardWithHooks);
