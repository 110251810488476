import { all } from "redux-saga/effects";
//public
import AuthSaga from "./auth/account/saga";
import FilterSaga from "./filter/saga";
import CartSaga from "./cart/saga";
import AddressSaga from "./address/saga";
import PaymentMethodSaga from "./paymentMethod/saga";
import CountrySaga from "./country/saga";
import ShippingMethodSaga from "./shippingMethod/saga";
import NavigationMenuSaga from "./navigationMenu/saga";
import SalesTerritorySaga from "./salesTerritory/saga";

export default function* rootSaga() {
  yield all([
    AuthSaga(),
    FilterSaga(),
    CartSaga(),
    AddressSaga(),
    PaymentMethodSaga(),
    CountrySaga(),
    ShippingMethodSaga(),
    NavigationMenuSaga(),
    SalesTerritorySaga()
  ]);
}
