import {
  Grid, Link,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@material-ui/core'
import List from '@material-ui/core/List'
import {API_URL} from 'config'
import {CheckCircleOutlined, RadioButtonCheckedOutlined, ReportOutlined} from '@material-ui/icons'
import Box from "@material-ui/core/Box";

export const TaxExemptListing = ({formData}) => {

  return <Grid item container spacing={2}>

    <Grid item xs={12}>
      <Typography variant="h5" style={{fontWeight: 700, marginTop: 16}}>
        Tax Exempt
      </Typography>
    </Grid>

    {formData && formData.taxExemptForms && formData.taxExemptForms.map((item, itemIndex) =>
      <Grid item container spacing={2} key={item.id}>
        <Grid item xs={12}>
          <List dense={true} disablePadding>
            {item.files.map((file, i) => {
              return <ListItem key={i} disableGutters>
                <ListItemText style={{paddingRight: 16}}
                  // eslint-disable-next-line
                              primary={<Box fontWeight="bold">
                                <Grid item container alignItems='center'>
                                  <Grid item>
                                    {item.expired&&<ReportOutlined style={{color:'#DF0006', marginTop: 4, marginRight: 8}} />}
                                    {item.pending&&<RadioButtonCheckedOutlined style={{color:'#FE7A00', marginTop: 4, marginRight: 8}} />}
                                    {!item.expired&&!item.pending&&<CheckCircleOutlined style={{color:'green', marginTop: 4, marginRight: 8}}/>}
                                  </Grid>
                                  <Grid item>
                                    {item.expired&&
                                      <>
                                        {item.stateName} - expired on {item.expiryDate}
                                      </>
                                    }
                                    {item.pending&&
                                    <>
                                      {item.stateName} - pending
                                    </>
                                    }
                                    {!item.expired&&!item.pending&&
                                    <>
                                      {item.stateName} - expires on {item.expiryDate}
                                    </>
                                    }
                                  </Grid>
                                </Grid>
                              </Box>}
                />
                <ListItemSecondaryAction>
                  <Link
                    to={'#'}
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      window.open(`${API_URL}/files/${file.id}`, '_blank')
                    }}
                  >
                    {' '}
                    <b>View File</b>{' '}
                  </Link>
                </ListItemSecondaryAction>
              </ListItem>
            })}
          </List>
        </Grid>

      </Grid>
    )}

  </Grid>
}
