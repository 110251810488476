import {useCallback, useEffect, useMemo, useState} from "react";
import {addressContactType} from "../constants/enums";
import {useDispatch, useSelector} from "react-redux";
import {ADMIN_API_URL} from "../config";
import {fetchData} from "../utils";
import {useProfileIds} from "./auth";
import {useEntityProgress} from "reactcoregk/hooks";
import {getAllAddresses} from "../store/address/actions";
import {getAllPaymentMethods} from "../store/paymentMethod/actions";

export const useAddresses = () => {
  // read from Address reducer
  const context = useSelector((state) => state.Address)
  const dispatch = useDispatch()

  // get the active user trade account
  const { activeUserTradeAccountId } = useProfileIds()

  // initialize entity
  const fetchAddresses = useCallback(() => {
    if (activeUserTradeAccountId) {
      dispatch(getAllAddresses(activeUserTradeAccountId));
    }
  }, [activeUserTradeAccountId, dispatch])

  // Refresh list after CUD
  useEntityProgress(context, fetchAddresses);

  // Get all addresses and payment methods dependant to trade account
  useEffect(fetchAddresses, [fetchAddresses])

  // return the Address reducer
  return context
};

export const useAddressesByType = () => {
  // read from address reducer
  const all = useSelector((state) => state.Address.getAll.result)

  // return addresses grouped by type
  return useMemo(() => {
    return {
      shipping: all.filter((x) => x.contactType === addressContactType.SHIPPING),
      billing: all.filter((x) => x.contactType === addressContactType.BILLING),
      business: all.filter((x) => x.contactType === addressContactType.BUSINESS),
    };
  }, [all]);
}

export const usePaymentMethods = () => {
  // read from PaymentMethod reducer
  const context = useSelector((state) => state.PaymentMethod)
  const dispatch = useDispatch()

  // get the active user trade account
  const { activeUserTradeAccountId } = useProfileIds()

  // get all addresses and payment methods dependant to a trade account
  const handleRefresh = useCallback(() => {
    if (activeUserTradeAccountId) {
      dispatch(getAllPaymentMethods(activeUserTradeAccountId));
      dispatch(getAllAddresses(activeUserTradeAccountId));
    }
  }, [activeUserTradeAccountId, dispatch])

  // initialize entity
  const handleFetch = useCallback(() => {
    if (activeUserTradeAccountId) {
      dispatch(getAllPaymentMethods(activeUserTradeAccountId));
    }
  }, [activeUserTradeAccountId, dispatch])

  // Refresh entities after CUD
  useEntityProgress(context, handleRefresh);

  // initialize list
  useEffect(handleFetch, [handleFetch])

  // return the PaymentMethod reducer
  return context
}

export const useTaxExemptions = (triggerTaxRefresh) => {
  const [taxRegions, setTaxRegions] = useState([]);

  // get the active user trade account
  const { activeUserTradeAccountId } = useProfileIds()

  // init tax regions based on trade account
  useEffect(() => {
    if (activeUserTradeAccountId) {
      const url = `${ADMIN_API_URL}/avatax/tax-exempts/${activeUserTradeAccountId}`;
      fetchData(url)
          .then((data) => setTaxRegions(data))
          .catch((ex) => console.log(ex));
    }
  }, [activeUserTradeAccountId, triggerTaxRefresh]);

  // return the list of regions
  return taxRegions
}
