import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import WithActivities from "./WithActivities";
import HomeCarousel from "../HomeCarousel";
import HeroTileLeft1 from 'assets/images/Hero-Tile-Left-V2/Hero-Tile-Left-V2-1.jpg'
import HeroTileLeft2 from 'assets/images/Hero-Tile-Left-V2/Hero-Tile-Left-V2-2.jpg'
import HeroTileRight1 from 'assets/images/Hero-Tile-Right-V2/Hero-Tile-Right-V2-1.jpg'
import HeroTileRight2 from 'assets/images/Hero-Tile-Right-V2/Hero-Tile-Right-V2-2.jpg'

const useStyles = makeStyles({
  wrapper: {
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const cards = [
  {
    text: "New Arrivals",
    action: "Shop Caroline Z Hurley's Modern Block Prints",
    image: HeroTileLeft1,
    actionPath: "/collections/caroline-z-hurley-ii"
  },
  {
    text: "New Arrivals",
    action: "Shop Molly Mahon's Newest Collection",
    image: HeroTileLeft2,
    actionPath: "collections/molly-mahon-hand-block-prints-ii"
  },
];

const cards2 = [
  {
    text: "Finishing Touches",
    action: "Shop Pillows",
    image: HeroTileRight1,
    actionPath: "/catalog/7"
  },
  {
    text: "Finishing Touches",
    action: "Shop Rugs",
    image: HeroTileRight2,
    actionPath: "/catalog/8"
  },
];

function SignedInDesignerWLoginWActivities(props) {
  const { recentOrders, content, visibleProfile } = props;
  const classes = useStyles();
  return (
    <Grid
      className={classes.wrapper}
      container
      md={12}
      spacing={10}
      justify={"center"}
    >
      <Grid item className={classes.wrapper} lg={4} md={4} sm={6} xs={12}>
        <HomeCarousel cards={cards} />
      </Grid>
      <Grid item className={classes.wrapper} lg={4} md={4} sm={6} xs={12}>
        <HomeCarousel cards={cards2} />
      </Grid>
      <WithActivities recentOrders={recentOrders} content={content} visibleProfile={visibleProfile}/>
    </Grid>
  );
}

export default SignedInDesignerWLoginWActivities;
