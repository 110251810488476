import {useEffect, useRef, useState} from "react";
import {getDefaultHeaders, getToken, getUserId} from "../utils";
import * as SockJS from "sockjs-client";
import * as StompJs from "stompjs";
import {API_URL} from "../config";

export const useWebSocket = (isReady) => {
  const clientRef = useRef();
  const retries = useRef(10);
  const isInProgress = useRef(false);

  const [connected, setConnected] = useState(false);

  const onConnect = () => {
    setConnected(true);
    retries.current = retries.current - 1;
    isInProgress.current = false;
  };

  const onDisconnect = (error) => {
    if (retries.current > 0) {
      if (!isInProgress.current) {
        console.log("STOMP: " + error);
        console.log("STOMP: Reconecting in 5 seconds");
        setConnected(false);
        isInProgress.current = true;
        setTimeout(connect, 5000);
      }
    } else {
      console.log("STOMP: Failed to connect after 10 tries. Exit.");
    }
  };

  const connect = () => {
    const headers = { Authorization: getToken() };
    const socket = new SockJS(API_URL + "/stompws");
    clientRef.current = StompJs.over(socket);
    clientRef.current.connect(headers, onConnect, onDisconnect);
  };

  useEffect(() => {
    if (isReady) {
      connect();
    }
    // eslint-disable-next-line
  }, [isReady]);

  return {
    clientRef,
    connected,
  };
};

export const useCartListener = ({ connected, clientRef }, spoofId, setMonitoring) => {

  const ref = useRef()

  useEffect(() => {
    if (spoofId && connected) {
      try {
        clientRef.current.send(`/app/cart/${spoofId}`, getDefaultHeaders());
        ref.current = setInterval(() => {
          clientRef.current.send(`/app/cart/${spoofId}`, getDefaultHeaders());
        }, 2000)
      } catch {

      }
    }
    return () => {
      clearInterval(ref.current)
    }
  }, [clientRef, connected, spoofId]);


  const receiveRef = useRef()

  useEffect(() => {
    if (connected) {
      try {
        const topic = `/topic/cart/${getUserId()}`;
        clientRef.current.subscribe(topic, (message) => {
          setMonitoring(true)
          // console.log("MONITOR")
          clearInterval(receiveRef.current)
          receiveRef.current = setTimeout(() => {
            setMonitoring(null)
            // console.log("INVALIDATE")
          }, 4000)
        });
      } catch (ex) {
        console.log(ex);
      }
    }
    return () => {
      clearInterval(receiveRef.current)
    }
  }, [clientRef, connected, setMonitoring]);
};
