import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import WithOrders from "./WithOrders";
import GreetingText from "../GreetingText";

const useStyles = makeStyles({
  wrapper: {
    height: "100%",
  },
  sectionTitle: {
    fontFamily: "Chronicle",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "20px",
    lineHeight: "140%",
    letterSpacing: "0.0125em",
    color: "#0D131D",
  },
});

const WithActivities = ({ content, visibleProfile, openCartDrawer }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.wrapper} lg={4} md={4} sm={6} xs={12} item>
      <GreetingText greeting={"Hi,"} secondLine={"Get updates on your recent orders."} user={visibleProfile}/>
      <WithOrders content={content} openCartDrawer={openCartDrawer}/>
    </Grid>
  );
};

export default WithActivities;
