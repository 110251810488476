import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import HeroTileLeft from "assets/images/Hero-Tile-Left-V1/Hero-Tile-Left-V1.jpg";
import ActionCard from "../../Cards/ActionCard/ActionCard";
import HomeCarousel from "../HomeCarousel";
import WithActivities from "./WidthActivities";
import HeroTitleRight from "assets/images/Hero-Tile-Right-V1/Hero-Tile-right-V1-1.jpg";
import HeroTitleRight2 from "assets/images/Hero-Tile-Right-V1/Hero-Tile-right-V1-2.jpg";
import Profile from 'assets/images/User-Column-Profile/User-Column-Profile.png'

const useStyles = makeStyles({
  wrapper: {
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    // maxWidth: 1280,
  },
});

const dummyActivities = [
  {
    title: "Save Your Favorites",
    text: "Bookmark items for later and organize by project.",
    action: "MY FAVORITES",
    actionPath:'/account/favorites',
    image: Profile,
  },
];

const cards = [
  {
    text: "Recommendations for You",
    action: "Shop Our Jacaranda Collection",
    actionPath: "/collections/jacaranda",
    image: HeroTitleRight,
  },
  {
    text: "Recommendations for You",
    action: "Explore Our Bohemia Collection",
    actionPath: "/collections/bohemia",
    image: HeroTitleRight2,
  },
];

function SignedInNonTradeWLoginWithActivities(props) {
  const { content, visibleProfile } = props;
  const classes = useStyles();
  return (
    <Grid
      className={classes.wrapper}
      container
      md={12}
      spacing={10}
      justify={"center"}
    >
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <ActionCard
          image={HeroTileLeft}
          title={"New Arrivals"}
          actionName={"Shop What's New"}
          actionPath={"/new-arrivals"}
        />
      </Grid>
      <Grid item className={classes.wrapper} lg={4} md={4} sm={6} xs={12}>
        <HomeCarousel cards={cards} />
      </Grid>
      <WithActivities activities={dummyActivities} content={content} visibleProfile={visibleProfile}/>
    </Grid>
  );
}

export default SignedInNonTradeWLoginWithActivities;
