export const contactUsForm = [
  {
    id: "tradeAccountNumber",
    label: "Trade Account Number (optional)",
    type: "text",
    required: false,
    variant: "outlined",
    col: 12,
  },
  {
    id: "businessName",
    label: "Business Name (optional)",
    type: "text",
    required: false,
    variant: "outlined",
    col: 12,
  },
  {
    id: "name",
    label: "Name",
    type: "text",
    required: true,
    variant: "outlined",
    col: 12,
  },
  {
    id: "email",
    label: "Email",
    type: "email",
    required: true,
    variant: "outlined",
    col: 12,
  },
  {
    id: "subject",
    label: "Subject",
    type: "text",
    required: true,
    variant: "outlined",
    col: 12,
  },
  {
    id: "message",
    label: "Message",
    type: "textarea",
    required: true,
    variant: "outlined",
    col: 12,
  }

]

export const loginForm = [
  {
    id: "email",
    label: "Email",
    type: "email",
    required: true,
    variant: "outlined",
    col: 12,
  },
  {
    id: "password",
    label: "Password",
    type: "password",
    variant: "outlined",
    required: true,
    col: 12,
  },
];

export const resetPwdForm = [
  {
    id: "password",
    label: "New Password",
    type: "password",
    variant: "outlined",
    required: true,
    col: 12,
  },
  {
    id: "confirmpassword",
    label: "Confirm Password",
    type: "password",
    variant: "outlined",
    required: true,
    col: 12,
  }
];

export const forgotForm = [
  {
    id: "email",
    label: "Email",
    type: "email",
    required: true,
    variant: "outlined",
    col: 12,
  }
];

export const newAddressForm = [
  {
    id: "countryId",
    label: "Country",
    type: "text",
    required: true,
    col: 12,
  },
  {
    id: "businessName",
    label: "Business Name",
    type: "text",
    required: true,
    col: 12,
  },
  {
    id: "name",
    label: "Name",
    type: "text",
    required: true,
    col: 12,
  },
  {
    id: "addressLineOne",
    label: "Address Line 1",
    type: "text",
    required: true,
    col: 12,
  },
  {
    id: "addressLineTwo",
    label: "Address Line 2",
    type: "text",
    required: false,
    col: 12,
  },
  {
    id: "zipCode",
    label: "Zip Code",
    type: "text",
    required: true,
    col: 4,
  },
  {
    id: "city",
    label: "City",
    type: "text",
    required: true,
    col: 4,
  },
  {
    id: "state",
    label: "State",
    type: "text",
    required: true,
    col: 4,
  },
  {
    id: "number",
    label: "Phone",
    type: "number",
    required: false,
    col: 12,
  },
];

export const creditCardInfoForm = [
  {
    id: "selectCard",
    label: "Select a Credit Card",
    type: "text",
    required: false,
    col: 12,
  },
  {
    id: "creditCard",
    label: "Credit Card Number",
    type: "text",
    required: true,
    col: 12,
  },
  {
    id: "expirationDate",
    label: "Expiration Date",
    type: "text",
    required: true,
    col: 3,
  },
  {
    id: "expirationYear",
    label: "Year",
    type: "text",
    required: true,
    col: 3,
  },
  {
    id: "cvv2",
    label: "CVV2",
    type: "text",
    required: true,
    col: 6,
  },
  {
    id: "firstName",
    label: "First Name",
    type: "text",
    required: true,
    col: 6,
  },
  {
    id: "lastName",
    label: "Last Name",
    type: "text",
    required: true,
    col: 6,
  },
  {
    id: "saveCredit",
    label: "Save Credit Card to Wallet",
    type: "checkbox",
    required: true,
    col: 12,
  },
  {
    id: "defaultCredit",
    label: "Default Credit Card",
    type: "checkbox",
    required: true,
    col: 12,
  },
]


export const creditCardBillingInfoForm = [
  {
    id: "streetAddress",
    label: "Street Address",
    type: "text",
    required: true,
    col: 12,
  },
  {
    id: "streetAddress2",
    label: "Street Address 2",
    type: "text",
    required: false,
    col: 12,
  },
  {
    id: "streetAddress3",
    label: "Street Address 3",
    type: "text",
    required: false,
    col: 12,
  },
  {
    id: "city",
    label: "City",
    type: "text",
    required: true,
    col: 4,
  },
  {
    id: "state",
    label: "State",
    type: "text",
    required: false,
    col: 4,
  },
  {
    id: "zipCode",
    label: "Zip Code",
    type: "text",
    required: true,
    col: 4,
  },
  {
    id: "country",
    label: "Country",
    type: "text",
    required: true,
    col: 12,
  },
]
