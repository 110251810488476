import { Box, Container, Grid } from "@material-ui/core";
import FeatureBox from "../../components/FeatureBox/FeatureBox";
import image from "../../images/feature.jpeg";
import {useEffect} from "react";
import {SmoothVerticalScrolling} from "../../utils";

const services = [
  {
    title: "PERSONAL SHOPPING",
    image: image,
    anchor:'personal-shopping',
    description:
      "No time? No problem. If you can’t make it to the showroom, reach out to your Account Executive or Showroom Sales Rep with the details of your project and then take a deep breath. With a library of more than 8,000 SKUs at their fingertips, the Schumacher sales team is prepared to shop our portfolio, make suggestions and ship memos — even overnight. Your rep is your partner and personal concierge, with a complete set of keys to our comprehensive product lines and services. Think of us as your one-stop shop.",
  },
  {
    title: "SUPER-FAST\nSERVICE",
    image: "https://fschumacher.com/assets/img/freddie2.jpg",
    anchor:'fast-than-ever-delivery',
    description:
      "We take ASAP seriously. Call the Schumacher showroom before 4 PM today and you can have samples in your office tomorrow. Place your product order by 1 PM EST and if the item is in stock, we’ll see that it goes out that very day. Missed the cut-off? Your in-stock order will go out first thing the next business day. Relax—you can rely on us to expedite CFAs, too.",
  },
  {
    title: "SCHUMACHER 24/7",
    image: "https://fschumacher.com/assets/img/freddie3.jpg",
    description:
      "You're on the go and so are we. Our mobile app makes it unbelievably easy to search for products, shop our collections and order memos in a snap from your iPhone or iPad. Wherever you are, user-friendly search and filter options bring whatever you need directly to your fingertips. Use your iPhone to scan memo barcodes for full product info and place orders. Download the app and take Schumacher wherever you go!",
  },
  {
    title: "WATER & STAIN\nPROTECTANTS",
    image: "https://fschumacher.com/assets/img/freddie4.jpg",
    anchor:'water-&-stain-protectants',
    description:
      "Need special treatment? If your project demands durability and performance, we're all over it. Your rep can tell you how to extend the life of Schumacher fabrics and wallcoverings with one of the many water and stain protectants that we offer, including Nanotex and Pro Seal Water Barrier finishes. If green is your goal, ask about lab-certified, earth-friendly Greenshield. Pricing is variable, so talk to your rep. Whatever you choose, we’ll handle it—from start to finish. No headaches for you, no holdups for your clients. All special finishes require one additional yard of fabric and an extra 7 to 14 days' lead time. Samples can be made for a nominal charge plus the cost of one yard of fabric.",
  },
  {
    title: "FLAME RESISTANCE",
    image: "https://fschumacher.com/assets/img/freddie5.jpg",
    anchor:'flame-resistance',
    description:
      "Commercial fire codes? Schumacher to the rescue! Provide your rep with your project's code requirements and step aside. We can finish upholstery and drapery fabrics with flame retardants to meet even the strictest standards—plus we’ll issue all necessary certificates so that you have the documentation you need. Yes, it's that simple. As with all special services, please order one additional yard of fabric and allow an extra 7 to 14 days. For a sample, there's a small fee plus the cost of one yard of fabric.",
  },
  {
    title: "FLAME BACKING",
    image: "https://fschumacher.com/assets/img/freddie6.jpg",
    anchor:'fabric--and-paper-backing-options',
    description:
      "We’ve got your back. If your workroom recommends backing for one of our fabrics, we can take care of that, too. It’s easy, and less expensive than you think. Your rep can explain the different methods we can use to improve a fabric’s stability and strength:\n" +
        "\n" +
        "Knit: Some delicate, lightweight or loosely woven textiles aren’t ideal for upholstery on their own, but don’t let that stop you. A layer of knitted backing bonded to a fabric will add the dimension and body it needs—without changing its look or hand.\n" +
        "Acrylic: A spray-on treatment that adds flexibility but prevents curling and fraying, this is yet another way we can prepare fabrics for light upholstery and wall applications.\n" +
        "Paper: Our fabrics can also be paper-backed for wall applications. Note that all paper-backed fabrics require a stain-resistant finish, which is included in the pricing. Wall upholstery made easy!\n" +
        "For all treatments, your rep will ask you to add an additional yard of fabric to your order. Allow an extra 7 to 14 days' lead time. Need a sample? For a small fee plus the cost of one yard of fabric, you can see how the treated product will look. There’s no need to source an additional vendor, generate an extra PO, or track another work order. Skip those steps and potential snags. Your rep will manage all the details.",
  },
  {
    title: "CUSTOM",
    anchor:'bespoke-customization',
    image: "https://fschumacher.com/assets/img/freddie7.jpg",
    description: "What’s ours can be yours. Whether it’s a specific color you’ve got in mind or a different ground cloth you need, consult your rep about our custom capabilities. For a minimum order, Schumacher fabrics, wallcoverings and trims can be customized to suit your project perfectly."
  },
  {
    title: "SCHUMACHER HOSPITALITY",
    image: "https://fschumacher.com/assets/img/freddie8.jpg",
    description: "Our Schumacher Hospitality team has got one focus: helping you create exceptional spaces that leave lasting impressions. Whether your project involves complicated codes, special finishes or extra-large quantities, a particularly tight budget or a super-firm deadline, Schumacher Hospitality is with you all the way. Ask your Schumacher Residential rep to connect you with Schumacher Hospitality for seamless service attending to every detail, from memo requests to code compliance. We’re your one-stop, go-to resource for designing and executing hospitality schemes with all the beauty, sophistication and nuance of residential interiors."
  },
  {
    title: "FLOORCOVERING",
    image: "https://fschumacher.com/assets/img/freddie9.jpg",
    description: "You’ll be floored. Our sister brand Patterson Flynn Martin is a leading source for high-end custom and readymade carpets and rugs. Your Schumacher rep can direct you to a PFM point person who will provide you with the same level of dedicated customer service and an entrée into the world of luxury floorcoverings. Together, we make a great team!"
  },
  {
    title: "FURNITURE\n" +
        "WITHOUT\n" +
        "THE WAIT",
    image: "https://fschumacher.com/assets/img/freddie10.jpg",
    description: "Need instant gratification? We get it. When your deadline is looming or you just have to have furniture N-O-W, come see us. All the pieces in our showrooms can be purchased right off the floor. And don’t forget about our Antiques Program! Shop for one-of-a kind items—handpicked in Europe by Schumacher’s Creative Team—at our New York, Atlanta and Los Angeles flagships, or browse the full inventory online."
  },
];

const Services = (props) => {
  const currentLocation = window.location.href;

  useEffect(()=>{
    const hasServiceAnchor = currentLocation.includes("/#");
    if (hasServiceAnchor) {
      const anchorServiceId = `${currentLocation.substring(currentLocation.indexOf("#") + 1)}`;
      const anchorService = document.getElementById(anchorServiceId);
      if(anchorService){
        SmoothVerticalScrolling(anchorService,400,'top')
      }
    }
  },[currentLocation])


  return (
    <Container maxWidth={"md"}>
      <Box mt={8} />
      <Grid container direction={"column"} spacing={2}>
        {services.map((service, index) => (
          <Grid item key={index}>
            <FeatureBox {...service} reverse={index % 2} anchorDiv={service.anchor}/>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Services;
