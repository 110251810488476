import {
  Box,
  TextField,
  Typography
} from '@material-ui/core'
import {useState} from "react";
import {getUsername} from "utils";
import Dialog from "components/Dialog/Dialog";

const AddNoteDialog = ({open, setOpen, setNotes, notes}) => {

  const [note, setNote] = useState('')

  const handleClose = () => {
    setOpen(false)
  }

  const addNote = () => {
    if (note==='') handleClose()
    setNotes([...notes, {
      timestamp: new Date(),
      text: note,
      userName: getUsername()
    }])
    handleClose()
  }

  return <>
    <Dialog
      onClose={handleClose}
      open={open}
      title={'Add note'}
      maxWidth="sm"
      primaryActionTitle={'Add notes'}
      secondaryActionTitle={'CANCEL'}
      primaryAction={addNote}
      secondaryAction={()=>{handleClose()}}
      inProgress={false}
    >
      <Typography variant='h6' style={{ fontWeight:800 }}>
        Add New Notes
      </Typography>

      <Box>
        <Box mt={2} mb={2} >
          <TextField variant='outlined' rows={7} type='text' multiline={true} fullWidth onChange={(e)=>setNote(e.target.value)} value={note} />
        </Box>
        {/*<Box fontSize={12} p={1} pt={0} fontWeight='600'>TOTAL SIZE OF UPLOADING FILES CANNOT EXCEED 10MB</Box>*/}
      </Box>

    </Dialog>
  </>
}

export default AddNoteDialog
