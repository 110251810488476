import React from "react";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Link as MuiLink,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

function Breadcrumbs(props) {
  const { path } = props;
  return (
    <MuiBreadcrumbs aria-label="breadcrumb">
      {path.map((link) => (
        <MuiLink color="inherit" component={Link} to={link.path}>
          {link.name}
        </MuiLink>
      ))}
    </MuiBreadcrumbs>
  );
}

export default Breadcrumbs;

Breadcrumbs.propTypes = {
  path: PropTypes.arrayOf(PropTypes.object),
};
