import {makeStyles} from "@material-ui/core/styles";
import {Box, Container, Grid} from "@material-ui/core";
import Text from "../../components/Text/Text";
import ProductCard from "../../components/Cards/ProductCard/ProductCard";
import ProductCardWithHooks from "../../components/Cards/ProductCard/ProductCartWithHooks";
import {useCallback, useState} from "react";
import {connect} from "react-redux";
import ProductDialog from "../Catalog/ProductDialog";
import {useCurrentCart} from "../../hooks/cart";
import {useFavorites} from "../../hooks/favorites";
import {getUserId} from "../../utils";

const useStyles = makeStyles({
  designerContainer: {
    marginTop: 40,
    paddingBottom: 30,
  },
});

function Products(props) {
  const {
    loadingContent,
    content,
    context,
  } = props;
  const productColumns = content?.content?.value?.productGrid || [];
  const [selectedProduct, setSelectedProduct] = useState({});
  const [openPreview, setOpenPreview] = useState(false);

  const products = Object.keys(productColumns).map((col) => productColumns[col]);

  const [favoriteProduct, setFavoriteProduct] = useState({});
  const userId = context.Cart.spoofingUserId || getUserId();
  useFavorites(
    favoriteProduct,
    products.map((p) => p.product),
    setFavoriteProduct,
    userId
  );

  const classes = useStyles();

  const { handleAddMemo, cart } = useCurrentCart();

  const handlePreview = useCallback((product) => {
    setSelectedProduct(product);
    setOpenPreview(true);
  }, []);

  return (
    <Container className={classes.designerContainer}>
      <Box mt={6} />
      <Text
        gutterBottom
        variant="h3"
        color={"textSecondary"}
        style={{
          fontStyle: "normal",
          fontWeight: 300,
          fontSize: "32px",
          lineHeight: "112.5%",
          letterSpacing: "0.005em",
          color: "#1B1C1D",
          marginBottom: 24,
          textAlign: "center",
        }}
      >
        Panels & Murals to Transform Any Space Into a Dreamy Escape
      </Text>
      <Grid container spacing={2}>
        {loadingContent
          ? [...Array(6)].map((x, i) => (
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <ProductCard
                  product={{}}
                  size={"auto"}
                  isLoading={loadingContent}
                />
              </Grid>
            ))
          : products.map(({ product }) => (
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <ProductCardWithHooks
                  product={product}
                  handlePreview={handlePreview}
                  handleMemo={handleAddMemo}
                  activeCart={cart}
                  size={"auto"}
                  toggleFavorite={() => setFavoriteProduct(product)}
                />
              </Grid>
            ))}
      </Grid>
      <ProductDialog
        open={openPreview}
        handleClose={() => setOpenPreview(false)}
        itemNumber={selectedProduct?.itemNumber}
        products={products.map((p) => p.product)}
      />
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      Cart: state.Cart,
    },
  };
};
export default connect(mapStateToProps, {
})(Products);
