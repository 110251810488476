import {Box, Grid} from "@material-ui/core";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import Text from "../../../components/Text/Text";
import StepContent from "../Common/StepContent";
import { useDispatch, useSelector } from "react-redux";
import {
  setOrderMemoShippingMethodId,
  setOrderProductShippingMethodId,
} from "../../../store/cart/actions";
import DeliveryOptionCard from "../../../components/Cards/DeliveryOptionCard/DeliveryOptionCard";
import Heading from "../Common/Heading";

function Delivery(props) {
  const {
    setStep,
    hasMemo,
    hasProduct,
    memoDeliveryOptions,
    productDeliveryOptions,
  } = props;
  const dispatch = useDispatch();
  const [showOverview, setShowOverview] = useState(false);
  const productShippingMethodId = useSelector(
    (state) => state.Cart.order.productShippingMethodId
  );
  const memoShippingMethodId = useSelector(
    (state) => state.Cart.order.memoShippingMethodId
  );

  const handleProductMethod = useCallback(
    (method) => {
      dispatch(setOrderProductShippingMethodId(method.id));
    },
    [dispatch]
  );

  const handleMemoMethod = useCallback(
    (method) => {
      dispatch(setOrderMemoShippingMethodId(method.id));
    },
    [dispatch]
  );

  const handleNext = () => {
    setStep((prevState) => prevState + 1);
  };

  const handleBack = () => {
    if (showOverview) {
      setShowOverview(false);
    } else {
      setStep((prevState) => prevState - 1);
    }
  };

  const isDisabled = () => {
    if (hasMemo && hasProduct) {
      return !Boolean(memoShippingMethodId && productShippingMethodId);
    } else if (hasMemo) {
      return !Boolean(memoShippingMethodId);
    }
    return !Boolean(productShippingMethodId);
  };

  useEffect(() => {
    if (hasMemo && !memoShippingMethodId && memoDeliveryOptions[0]) {
      handleMemoMethod(memoDeliveryOptions[0])
    }
  }, [handleMemoMethod, hasMemo, memoDeliveryOptions, memoShippingMethodId])

  return (
    <StepContent
      onBack={handleBack}
      onNext={handleNext}
      disabledNext={isDisabled()}
      nextTitle={"Next"}
    >
      <Heading title={"Shipping Method"} />
      <Grid container direction={"column"} spacing={2}>
        {hasMemo && (
          <Fragment>
            <Grid item>
              <Text bold>Memos Shipment</Text>
            </Grid>
            <Grid item>
              <Grid container spacing={4}>
                {memoDeliveryOptions.map((delivery, index) => (
                  <Grid item key={index} sm={3}>
                    <DeliveryOptionCard
                      deliveryOption={delivery}
                      hideEta
                      onClick={handleMemoMethod}
                      checked={memoShippingMethodId === delivery.id}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Fragment>
        )}
        {hasProduct && (
          <Fragment>
            <Box mt={3}/>
            <Grid item>
              <Text bold>Product Shipment</Text>
            </Grid>
            <Grid item>
              <Grid container spacing={4}>
                {productDeliveryOptions.map((delivery, index) => (
                  <Grid item key={index} sm={3}>
                    <DeliveryOptionCard
                      deliveryOption={delivery}
                      onClick={handleProductMethod}
                      checked={productShippingMethodId === delivery.id}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </StepContent>
  );
}

export default Delivery;
