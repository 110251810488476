import {
  Checkbox,
  Grid
} from '@material-ui/core'
import {useState} from 'react'
import Dialog from 'components/Dialog/Dialog'
import {roleOptions} from 'constants/enums'
import {ADMIN_API_URL} from "config";
import axios from "axios";
import {getAxiosConfig} from "utils";
import {useSnackbar} from "notistack";

const MembersDialog = ({open, setOpen, members}) => {
  const { enqueueSnackbar } = useSnackbar();

  // eslint-disable-next-line
  const [isBusy, setIsBusy] = useState(false)
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const [selected, setSelected] = useState([])

  const handleChange = (id) => {
    let idPos = selected.findIndex((s)=>s===id)
    let newSelected = [...selected]
    if (idPos===-1) {
      newSelected.push(id)
      setSelected(newSelected)
    } else {
      newSelected.splice(idPos,1)
      setSelected(newSelected)
    }
  }

  const submit = () => {
    setIsBusy(true)
    const url =`${ADMIN_API_URL}/account/reset-password/members`
    axios.post(url,{emails:selected},getAxiosConfig(true))
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar(`Password reset link was sent to selected members.`, {variant:'success'});
        }
        setIsBusy(false);
        handleClose();

      })
      .catch((err) => {
        // Silent error
        setIsBusy(false);
        enqueueSnackbar(`There was an error sending the password reset links.`, {variant:'error'});
        console.log(err)
        throw err;
      });
  }

  if (isLoading) {
    return <> </>
  }

  return <Dialog
        onClose={handleClose}
        open={open}
        title={`Reset Password`}
        maxWidth="sm"
        primaryActionTitle={'SEND RESET PASSWORD LINK'}
        secondaryActionTitle={'CANCEL'}
        primaryAction={submit}
        secondaryAction={()=>{handleClose()}}
        inProgress={isBusy||isLoading}
        disableBackdropClick={isBusy||isLoading}
        disableEscapeKeyDown={isBusy||isLoading}
      >

        <Grid item container spacing={2}>

          {members.map((member)=>{
            const roleObj = roleOptions.find((r)=>r.id===member.accountType)

            return <Grid item xs={12}>
              <Grid item container spacing={2} alignItems='center' onClick={()=>handleChange(member.email)} style={{cursor:'pointer'}}>
                <Grid item xs={1}>
                  <Checkbox
                    checked={selected.includes(member.email)}
                    onChange={()=>handleChange(member.email)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    color={'primary'}
                  />
                </Grid>
                <Grid item xs={4}>
                  {member.fullName}
                </Grid>
                <Grid item xs={4}>
                  {member.email}
                </Grid>
                <Grid item xs={3}>
                  {roleObj?roleObj.name:'-'}
                </Grid>
              </Grid>
            </Grid>

          })}

        </Grid>

      </Dialog>
}

export default MembersDialog
