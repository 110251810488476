import {
  createOperationState,
  handleAsyncState,
} from "reactcoregk/store/reducer";
import {
  ADD_MEMO_TO_CART,
  ADD_PRODUCT_TO_CART,
  CLOSE_CHECKOUT,
  DELETE_MULTI_PRODUCT_FROM_CART,
  DELETE_PRODUCT_FROM_CART,
  GET_OPEN_CARTS,
  OPEN_CHECKOUT,
  SET_MONITORING,
  SET_ORDER_ITEM_IDS,
  SET_ORDER_MEMO_PACKING_NOTES,
  SET_ORDER_MEMO_SHIPPING_METHOD_ID,
  SET_ORDER_NOTES,
  SET_ORDER_PAYMENT_METHOD_ID,
  SET_ORDER_PAYMENT_METHOD_OPTION,
  SET_ORDER_PO_NUMBER,
  SET_ORDER_PRODUCT_PACKING_NOTES,
  SET_ORDER_PRODUCT_SHIPPING_METHOD_ID,
  SET_ORDER_PROJECT_NAME, SET_ORDER_SAVE_ADDRESS,
  SET_ORDER_SHIPPING_ADDRESS,
  SET_SPOOFING_USER_ID,
  TOGGLE_ORDER_ITEM,
  UPDATE_CART,
  UPDATE_PRODUCT_OF_CART,
} from "./actionTypes";
import { ActionStates } from "reactcoregk/store/actions";
import { paymentMethodOption } from "../../constants/enums";
import {CLOSE_CART_DRAWER} from "../element/actionTypes";

const initialState = {
  carts: createOperationState([]),
  product: createOperationState({}),
  memo: createOperationState({}),
  orderItemsDelete: createOperationState({}),
  orderItemDelete: createOperationState({}),
  orderItemUpdate: createOperationState({}),
  cartUpdate: createOperationState({}),
  currentCartId: null,
  spoofingUserId: null,
  monitoring: null,
  validatingSpoofingUser: false,
  spoofingUserDetails: null,
  highlightItemId: null,
  order: {
    isOpen: false,
    itemIds: [],
    productShippingMethodId: null,
    memoShippingMethodId: null,
    paymentMethodId: null,
    shippingAddressId: null,
    poNumber: "",
    notes: "",
    memoPackingNotes: "",
    productPackingNotes: "",
    projectName: "",
    paymentMethodOption: paymentMethodOption.PAY_NOW,
    saveAddress: false
  },
};

const Cart = (state = initialState, action) => {
  const actionType = action.type.split(".")[1];
  const actionState = action.type.split(".")[2];

  if (action.type === CLOSE_CART_DRAWER) {
    return {
      ...state,
      highlightItemId: null
    }
  }

  switch (actionType) {
    case GET_OPEN_CARTS: {
      const newState = handleAsyncState(state, action, "carts");
      if (actionState === ActionStates.success) {
        const currentCard = action.payload.find((x) => x.isActive);
        const currentCardItemIds = currentCard?.items.map((x) => x.id) || [];
        return {
          ...newState,
          currentCartId: currentCard?.id || null,
          order: {
            ...state.order,
            itemIds: state.order.itemIds.filter((id) =>
              currentCardItemIds.includes(id)
            ),
          },
        };
      }
      return newState;
    }
    case ADD_PRODUCT_TO_CART: {
      const newState = handleAsyncState(state, action, "product");
      if (actionState === ActionStates.request) {
        return {
          ...newState,
          highlightItemId: action.payload.productId
        }
      }
      return newState
    }
    case ADD_MEMO_TO_CART: {
      const newState = handleAsyncState(state, action, "memo");
      if (actionState === ActionStates.request) {
        return {
          ...newState,
          highlightItemId: action.payload.productId
        }
      }
      return newState
    }
    case DELETE_PRODUCT_FROM_CART: {
      return handleAsyncState(state, action, "orderItemDelete");
    }
    case DELETE_MULTI_PRODUCT_FROM_CART: {
      return handleAsyncState(state, action, "orderItemsDelete");
    }
    case UPDATE_PRODUCT_OF_CART: {
      const newState = handleAsyncState(state, action, "orderItemUpdate");
      if (actionState === ActionStates.request) {
        return {
          ...newState,
          highlightItemId: action.payload.productId
        }
      }
      return newState
    }
    case UPDATE_CART: {
      return handleAsyncState(state, action, "cartUpdate");
    }
    case SET_ORDER_PAYMENT_METHOD_OPTION: {
      return {
        ...state,
        order: {
          ...state.order,
          paymentMethodOption: action.payload,
        },
      };
    }
    case SET_SPOOFING_USER_ID:
      if (actionState === ActionStates.request) {
        return {
          ...state,
          spoofingUserId: action.payload,
          spoofingUserDetails: null,
          validatingSpoofingUser: true,
        };
      } else if (actionState === ActionStates.success) {
        return {
          ...state,
          spoofingUserDetails: action.payload,
          validatingSpoofingUser: false,
        };
      } else {
        return {
          ...state,
          validatingSpoofingUser: false,
        };
      }
    case SET_MONITORING:
      return { ...state, monitoring: action.payload };
    case OPEN_CHECKOUT:
      return {
        ...state,
        order: {
          ...state.order,
          isOpen: true,
          itemIds: action.payload,
        },
      };
    case CLOSE_CHECKOUT:
      return {
        ...state,
        order: {
          ...initialState.order,
        },
      };
    case TOGGLE_ORDER_ITEM:
      if (state.order.itemIds.includes(action.payload)) {
        return {
          ...state,
          order: {
            ...state,
            itemIds: state.order.itemIds.filter((id) => id !== action.payload),
          },
        };
      }
      return {
        ...state,
        order: {
          ...state.order,
          itemIds: [...state.order.itemIds, action.payload],
        },
      };
    case SET_ORDER_ITEM_IDS:
      return {
        ...state,
        order: {
          ...state.order,
          itemIds: action.payload,
        },
      };
    case SET_ORDER_PRODUCT_SHIPPING_METHOD_ID:
      return {
        ...state,
        order: {
          ...state.order,
          productShippingMethodId: action.payload,
        },
      };
    case SET_ORDER_MEMO_SHIPPING_METHOD_ID:
      return {
        ...state,
        order: {
          ...state.order,
          memoShippingMethodId: action.payload,
        },
      };
    case SET_ORDER_PAYMENT_METHOD_ID:
      return {
        ...state,
        order: {
          ...state.order,
          paymentMethodId: action.payload,
        },
      };
    case SET_ORDER_NOTES:
      return {
        ...state,
        order: {
          ...state.order,
          notes: action.payload,
        },
      };
    case SET_ORDER_MEMO_PACKING_NOTES:
      return {
        ...state,
        order: {
          ...state.order,
          memoPackingNotes: action.payload,
        },
      };
    case SET_ORDER_PRODUCT_PACKING_NOTES:
      return {
        ...state,
        order: {
          ...state.order,
          productPackingNotes: action.payload,
        },
      };
    case SET_ORDER_PO_NUMBER:
      return {
        ...state,
        order: {
          ...state.order,
          poNumber: action.payload,
        },
      };
    case SET_ORDER_PROJECT_NAME:
      return {
        ...state,
        order: {
          ...state.order,
          projectName: action.payload,
        },
      };
    case SET_ORDER_SHIPPING_ADDRESS: {
      const { id } = action.payload;
      return {
        ...state,
        order: {
          ...state.order,
          shippingAddressId: id || null,
          shippingAddress: id > 0 ? null : action.payload,
        },
      };
    }
    case SET_ORDER_SAVE_ADDRESS: {
      return {
        ...state,
        order: {
          ...state.order,
          saveAddress: action.payload
        }
      }
    }
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Cart;
