import React, { createContext, useState } from "react";
import { Helmet } from "react-helmet";
import { combineStrings } from "../utils";
import ErrorBoundary from "../components/Core/ErrorBoundary";

export const ExtraTitle = createContext(null);

function TitleWrapper(props) {
  const { title } = props;
  const [extraTitle, setExtraTitle] = useState("");

  return (
    <ExtraTitle.Provider value={{ extraTitle, setExtraTitle }}>
      <Helmet>
        <title>{combineStrings(" | ", title, extraTitle, "Schumacher")}</title>
      </Helmet>
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </ExtraTitle.Provider>
  );
}

export default TitleWrapper;
