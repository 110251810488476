import {ErrorMessage, useField} from "formik";
import {Box, FormControl, InputAdornment, TextField} from "@material-ui/core";
import {useState} from "react";

const FormikPasswordField = (props) => {
    const {required = false, type, variant = "standard"} = props;
    const [fieldType,setFieldType] = useState(type)
    const [field, meta] = useField(props.name);

    const handleShowPassword = () => {
        if (fieldType==='password') {
            setFieldType('text')
        } else {
            setFieldType('password')
        }
    }

    const hasError = meta.error && meta.touched

    return (
        <FormControl fullWidth required={required} error={hasError}>
            <TextField
                id="outlined-basic"
                variant={variant}
                {...field}
                {...props}
                {...props.rest}
                type={fieldType}
                error={hasError}
                // helperText={meta.error}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <Box fontSize={13} onClick={handleShowPassword} style={{textTransform:'initial',textDecoration:'underline', cursor:'pointer', color: hasError?'red':'black',useSelect:'none'}}> {fieldType==='password'?'Show':'Hide'} </Box>
                        </InputAdornment>
                    ),
                }}
            />
            <ErrorMessage name={field.name} render={msg => <Box pl={1} pt={0.5} style={{
                textTransform: 'uppercase',
                color: '#DF0006', fontWeight: 'bold', fontSize: 13
            }}>{msg}</Box>}/>
        </FormControl>
    );
};

export default FormikPasswordField;
