import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import Text from "../Text/Text";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  description: {
    textAlign: (props) => (props.reverse ? "start" : "end"),
    marginBottom: 16,
  },
  title: {
    fontWeight: "600",
  },
  textBox: {
    borderBottom: "6px solid #FFD700",
  },
});

const FeatureBox = (props) => {
  const { description, image, title, reverse,anchorDiv=''} = props;
  const classes = useStyles(props);
  return (
    <Grid
      container
      spacing={4}
      direction={reverse ? "row-reverse" : "row"}
      alignItems={"center"}
    >
      <Grid item md={6} xs={12} id={anchorDiv}>
        <Grid
          container
          direction={"column"}
          alignItems={reverse ? "flex-start" : "flex-end"}
          className={classes.textBox}
        >
          <Text
            className={classes.title}
            variant={"h4"}
            color={"textSecondary"}
          >
            {title}
          </Text>
          <Text variant={"body1"} className={classes.description}>
            {description}
          </Text>
        </Grid>
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid container alignItems={"center"} justify={"center"}>
          <img src={image} alt={title} />
        </Grid>
      </Grid>
    </Grid>
  );
};

FeatureBox.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  anchorDiv:PropTypes.string,
  description: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
};

FeatureBox.defaultProps = {};

export default FeatureBox;
