import React, {forwardRef, Fragment} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {FormControlLabel, Grid, TextField} from "@material-ui/core";
import {IMaskInput} from "react-imask";
import Text from "../../../components/Text/Text";
import Box from "@material-ui/core/Box";
import PaymentTypeCard from "../../../components/Cards/PaymentTypeCard/PaymentTypeCard";
import {CreditCard} from "@material-ui/icons";
import Heading from "../Common/Heading";
import Checkbox from "../../../components/Checkbox/Checkbox";

const useStyles = makeStyles({
  root: {
    maxWidth: 580,
    margin: 0,
    padding: 0,
  },
});

const ExpirationInput = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="#0/#0"
      definitions={{
        "#": /[1-9]/,
        // '@': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const CveInput = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="#00"
      definitions={{
        "#": /[1-9]/,
        // '@': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const CardNumberInput = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="#000 #000 #000 #000 #000"
      definitions={{
        "#": /[1-9]/,
        // '@': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

function PaymentForm({ form, handleChange, setForm, inProgress }) {
  const classes = useStyles();

  const handleChangeExpiration = (e) => {
    const expiration = e.target.value;
    const expirationMonth = expiration.split("/")[0] || "";
    const expirationYear = expiration.split("/")[1] || "";
    setForm((prevState) => ({
      ...prevState,
      expiration,
      expirationMonth,
      expirationYear,
    }));
  };

  return (
    <Fragment>
      <Box className={classes.root}>
        <Text bold>Add New Payment Method</Text>
        <Box mt={2} />
        <Grid container spacing={4}>
          <Grid item md={4}>
            <PaymentTypeCard
              checked
              paymentType={{ name: "Credit Card", icon: <CreditCard /> }}
            />
          </Grid>
          {/*<Grid item md={4}>*/}
          {/*  <PaymentTypeCard*/}
          {/*    disabled*/}
          {/*    paymentType={{ name: "Pay Pal", icon: <CreditCard /> }}*/}
          {/*  />*/}
          {/*</Grid>*/}
          {/*<Grid item md={4}>*/}
          {/*  <PaymentTypeCard*/}
          {/*    disabled*/}
          {/*    paymentType={{ name: "Apple Pay", icon: <CreditCard /> }}*/}
          {/*  />*/}
          {/*</Grid>*/}
        </Grid>
        <Box mt={4} />
        <Text bold>Credit Card Information</Text>
        <Box mt={2} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              autoFocus
              variant={"outlined"}
              margin={"dense"}
              label={"Card Holder"}
              disabled={inProgress}
              value={form.holderName}
              onChange={handleChange("holderName")}
              autoComplete={"disabled"}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant={"outlined"}
              label={"Card Number"}
              disabled={inProgress}
              margin={"dense"}
              value={form.number}
              InputProps={{
                inputComponent: CardNumberInput,
              }}
              onChange={handleChange("number")}
              autoComplete={"disabled"}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              variant={"outlined"}
              label={"Expiration Date"}
              margin={"dense"}
              disabled={inProgress}
              value={form.expiration}
              InputProps={{
                inputComponent: ExpirationInput,
              }}
              onChange={handleChangeExpiration}
              autoComplete={"disabled"}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              margin={"dense"}
              variant={"outlined"}
              label={"CVC"}
              disabled={inProgress}
              value={form.cvc}
              InputProps={{
                inputComponent: CveInput,
              }}
              onChange={handleChange("cvc")}
              autoComplete={"disabled"}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControlLabel
                margin={"dense"}
                control={
                  <Checkbox
                      checked={form.isDefault}
                      onChange={handleChange("isDefault")}
                      disabled={inProgress}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      color={"secondary"}
                  />
                }
                label="Set as Default"
            />
          </Grid>
        </Grid>
        <Box mt={4}/>
        <Heading title={"Billing Address"} mb={2}/>
        <FormControlLabel
            margin={"dense"}
            control={
              <Checkbox
                  checked={form.sameAsShipping}
                  onChange={handleChange("sameAsShipping")}
                  disabled={inProgress}
                  inputProps={{ "aria-label": "primary checkbox" }}
                  color={"secondary"}
              />
            }
            label="Same as Shipping"
        />
      </Box>
    </Fragment>
  );
}

export default PaymentForm;
