import PropTypes from "prop-types";
import CheckoutCard from "../CheckoutCard/CheckoutCard";
import Text from "../../Text/Text";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  text: {
    marginTop: 8
  },
});

const PaymentTypeCard = (props) => {
  const classes = useStyles();
  const { onClick, checked, disabled, paymentType } = props;
  const { name, icon } = paymentType;

  return (
    <CheckoutCard
      checked={checked}
      disabled={disabled}
      onClick={onClick}
      direction={"column-reverse"}
    >
      <Grid container direction={"column"} alignItems={"center"}>
        {icon && <Grid item>{icon}</Grid>}
        <Grid item>
          <Text className={icon && classes.text} bold>
            {name}
          </Text>
        </Grid>
      </Grid>
    </CheckoutCard>
  );
};

PaymentTypeCard.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  paymentType: PropTypes.object,
};

export default PaymentTypeCard;
