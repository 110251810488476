import {API_URL} from "../config";
import {deleteData, getUserId, postData} from "../utils";
import {useCallback, useEffect, useState} from "react";
import {useSelector} from 'react-redux';

export const useFavorites = (
    product,
    allProducts,
    setFavoriteProduct,
    userId
) => {
    const {id, isFavorite} = product;
    const spoofingUserId = useSelector(state => state.Cart.spoofingUserId);
    const [loggedInUserId, setLoggedInUserId] = useState(userId)
    useEffect(() => {
        if (!userId)
            setLoggedInUserId(spoofingUserId || getUserId())
    }, [isFavorite,spoofingUserId,userId])

    const handleFavorite = useCallback(() => {
        if (id && !isFavorite) {
            const url = `${API_URL}/users/${loggedInUserId}/favorites/${id}`;
            if (Array.isArray(allProducts)) {
                const changeFav = allProducts.find((fav) => fav.id === id);
                changeFav.isFavorite = true;
                product.isFavorite = true;
                postData(url, {"product-id": id}).catch(() => {
                    changeFav.isFavorite = false;
                    product.isFavorite = false;
                });
            } else {
                product.isFavorite = true;
                postData(url, {"product-id": id}).catch(() => {
                    product.isFavorite = false;
                });
            }
        } else if (id && isFavorite) {
            const url = `${API_URL}/users/${loggedInUserId}/favorites/${id}`;
            if (Array.isArray(allProducts)) {
                const changeFav = allProducts.find((fav) => fav.id === id);
                changeFav.isFavorite = false;
                product.isFavorite = false;
                deleteData(url, {"product-id": id}).catch(() => {
                    changeFav.isFavorite = true;
                    product.isFavorite = true;
                });
            } else {
                product.isFavorite = false;
                deleteData(url, {"product-id": id}).catch(() => {
                    product.isFavorite = true;
                });
            }
        }
        setFavoriteProduct({});
    }, [allProducts, id, isFavorite, product, setFavoriteProduct, loggedInUserId]);


    useEffect(() => {
        if (product?.id) {
            handleFavorite();
        }
    }, [handleFavorite, product?.id]);

    return {handleFavorite};
};
