import React, {useCallback, useEffect, useState} from "react";
// import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, Collapse, Container, Fade, Grid, Menu, MenuItem, Typography,} from "@material-ui/core";
import {API_URL} from "../../config";
import {combineStrings, fetchData, getParameterByName, getUserId, updateURLParameter,} from "../../utils";
import {Link} from "react-router-dom";
import Tabs from "../../components/Tabs/Tabs";
import CatalogFilters from "../../components/Core/CatalogFilters";
import Toolbar from "@material-ui/core/Toolbar";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Text from "../../components/Text/Text";
import InfiniteScroll from "react-infinite-scroll-component";
import ProductCardWithHooks from "../../components/Cards/ProductCard/ProductCartWithHooks";
import {useAttributes, useCatalogFilters, useProductSorting,} from "../../hooks/catalog";
import {connect} from "react-redux";
import {useCurrentCart} from "../../hooks/cart";
import {Paging} from "reactcoregk/models";
import {ApiEndpoint} from "../../store/@core/endpoint";
import {EntityType} from "../../store/@core/entityType";
import ProductCard from "../../components/Cards/ProductCard/ProductCard";
import ProductDialog from "../Catalog/ProductDialog";
import {useTitle} from "../../hooks/router";
import {catalogFilter} from "../../constants/enums";
import {useFavorites} from "../../hooks/favorites";
import {StickySub} from "hooks/sticky";
import CollectionBox from "components/CollectionBox/CollectionBox";
import CollaboratorCard from "components/CollaboratorCard/CollaboratorCard";
import {useCounters} from "hooks";
import SearchBox from "../../components/Searchbox/SearchBox";

//
// const useStyles = makeStyles({
//   root: {},
//   searchHeaderSticky: {
//     backgroundColor: "red",
//   },
// });

const collectionsUrl = `${ApiEndpoint[EntityType.Collection]}/search`;
const collaboratorsUrl = `${ApiEndpoint[EntityType.Collaborator]}/search`;

const tabs = ["Products", "Collections", "Collaborators"];

function Discover(props) {
  // const classes = useStyles();
  const {
    context,
    match,
    history,
    location,
  } = props;
  const { isAuth } = context.Auth;

  const rootParams = location.search
    .replaceAll("?", "&")
    .replace("search", "term");
  const searchQuery = getParameterByName("search") || "";
  const termCleaned = searchQuery
  const typeQuery = getParameterByName("type") || "products";

  const selectedTab = tabs.findIndex((t) => t.toLowerCase() === typeQuery);
  const [activeTab, setActiveTab] = useState(
    selectedTab !== -1 ? selectedTab : 0
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [items, setItems] = useState([]);

  const [results, setResults] = useState(new Paging());
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [openPreview, setOpenPreview] = useState(false);
  const [favoriteProduct, setFavoriteProduct] = useState({});
  const userId = context.Cart.spoofingUserId || getUserId();
  useFavorites(favoriteProduct, items, setFavoriteProduct, userId);
  const productsUrl = userId?`${API_URL}/users/${userId}/products/search`:`${ApiEndpoint[EntityType.Product]}/search`;
  useTitle(combineStrings(" - ", tabs[activeTab], searchQuery));

  const tabUrl = (tab) => {
    const urls = {
      products: productsUrl,
      collections: collectionsUrl,
      collaborators: collaboratorsUrl,
    };

    return urls[tab] ? urls[tab] : "";
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      setResults(new Paging());
      setItems([]);
      try {
        const url = `${tabUrl(typeQuery)}?${rootParams}`;
        const response = await fetchData(url);
        setResults(response);
        setItems(response.content);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [rootParams, isAuth, activeTab, typeQuery]);

  const {
    handleDeleteAttribute,
    handleAddAttribute,
    attributes,
  } = useAttributes(location, history, match);

  // Handle sorting
  const { handleSortBy, sortOptions, isActive } = useProductSorting(
    location,
    history,
    match,
    context.Auth
  );

  // Custom sorting for Collections / Collaborators (only AZ/ZA)
  const sortOptionsAZ = [
    {
      id: "name",
      name: "A to Z",
      dir: "asc",
    },
    {
      id: "name",
      name: "Z to A",
      dir: "desc",
    },
  ];

  // Sorting map according to tab
  const sortingOptionsByTab = [sortOptions, sortOptionsAZ, sortOptionsAZ];

  const { handleAddMemo, cart } = useCurrentCart();

  const {
    visibleFilters,
    allFilters,
    defaultFilterIds,
    toggleFilter,
    loadingFilters,
  } = useCatalogFilters(isAuth, rootParams, catalogFilter.GLOBAL_SEARCH);

  // Handlers
  const handleNext = useCallback(async () => {
    try {
      const url = `${tabUrl(typeQuery)}?${rootParams}&page=${
        results.number + 1
      }`;
      const response = await fetchData(url);
      setResults(response);
      setItems((prevState) => [...prevState, ...response.content]);
    } catch (ex) {}
    // eslint-disable-next-line
  }, [results.number, rootParams, typeQuery]);

  // const handleSort = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleCloseSort = () => {
    setAnchorEl(null);
  };

  const handlePreview = useCallback((product) => {
    setSelectedProduct(product);
    setOpenPreview(true);
  }, []);

  const updateSearch = useCallback(
    (query) => {
      const search = updateURLParameter(location.search, "search", query);
      history.replace({
        pathname: match.url,
        search: search,
      });
    },
    [history, location.search, match.url]
  );

  const onSearch = useCallback(
    (query) => {
      if (query.categoryId) {
        props.history.push(`/catalog/${query.categoryId}?term=${query.term}`);
      } else if (query.productItemNumber) {
        props.history.push(`/catalog/products/${query.productItemNumber}`);
      } else if (query.collectionSlug) {
        props.history.push(
          `/collections/${query.collectionSlug}?term=${query.term || ""}`
        );
      } else if (query.term) {
        updateSearch(query.term);
      } else if (query.name) {
        updateSearch(query.name);
      } else {
        updateSearch(query);
      }
    },
    [props.history, updateSearch]
  );

  // Handle Counters for each tab
  const { productsCount, collectionsCount, collaboratorsCount } = useCounters(
    searchQuery
  );

  // URLs for each tab
  const queryURLs = {
    Products: `/discover?type=products&search=${searchQuery}`,
    Collections: `/discover?type=collections&search=${searchQuery}`,
    Collaborators: `/discover?type=collaborators&search=${searchQuery}`,
  };

  // Handle tab change
  const changeTab = (newTab) => {
    setActiveTab(newTab);
    history.push(queryURLs[tabs[newTab]]);
  };

  function renderGridLoader() {
    return (
      <Grid container spacing={4}>
        {[...Array(8)].map((v, i) => (
          <Grid key={i} item lg={3} md={4} sm={6} xs={12}>
            <ProductCard isLoading product={{}} />
          </Grid>
        ))}
      </Grid>
    );
  }

  const { searchHeaderRef, isStickySub, headerShown } = StickySub(true, true);

  return (
    <Container maxWidth={"lg"} style={{ minHeight: 700 }}>
      <Box mt={6} />

      <div
        position="static"
        className={[
          isStickySub ? "sticky" : "",
          headerShown ? "stickyWithHeader" : "",
        ].join(" ")}
        ref={searchHeaderRef}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Button component={Link} to={"/?search="}>
            <b>Go Back</b>
          </Button>
          <Fade in>
            <div style={{ maxWidth: 992, margin: "auto", flex: 1 }}>
              <SearchBox
                url={`${API_URL}/search/suggestions?term=`}
                nameProp={"term"}
                valueProp={"term"}
                namePropFallback={"name"}
                valuePropFallback={"name"}
                extraProp={"categoryName"}
                onSubmit={onSearch}
                autoSubmit
                autoSubmitDelay={500}
                placeholder={`Discover Schumacher Classics`}
                initialQuery={searchQuery}
                submitLabel={"Discover"}
              />
            </div>
          </Fade>
        </Box>
        <Box mt={3} />
        {(loading||(items&&items.length!==0))&&<Tabs
          value={activeTab}
          setValue={changeTab}
          tabs={tabs}
          counters={(productsCount||collectionsCount||collaboratorsCount)?[productsCount, collectionsCount, collaboratorsCount]:false}
        />}

        {(loading||(items&&items.length!==0))&&<>
        <Collapse in={activeTab === 0} style={{ marginTop: -1 }}>
          <CatalogFilters
            handleAddAttribute={handleAddAttribute}
            handleDeleteAttribute={handleDeleteAttribute}
            attributes={attributes}
            visibleFilters={visibleFilters}
            allFilters={allFilters}
            toggleFilter={toggleFilter}
            loadingFilters={loadingFilters}
            defaultFilterIds={defaultFilterIds}
          />
        </Collapse>
        <Toolbar>
          <Typography variant="body1">
            {results.totalElements} Results
          </Typography>
          <div style={{ margin: "auto" }} />
          {/*<Button*/}
          {/*  className={classes.menuButton}*/}
          {/*  aria-controls="simple-menu"*/}
          {/*  aria-haspopup="true"*/}
          {/*  onClick={handleSort}*/}
          {/*>*/}
          {/*  Sort by <ExpandMoreIcon />*/}
          {/*</Button>*/}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseSort}
          >
            {sortingOptionsByTab[activeTab].map((option, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  handleCloseSort();
                  handleSortBy(option);
                }}
              >
                <Text bold={isActive(option)} variant={"inherit"}>
                  {option.name}
                </Text>
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
        </>}
      </div>

      <InfiniteScroll
        dataLength={items.length} //This is important field to render the next data
        next={handleNext}
        scrollThreshold={0.6}
        hasMore={results.number + 1 < results.totalPages}
        style={{ width: "100%", overflow: "hidden" }}
        loader={renderGridLoader()}
      >
        {loading ? (
          renderGridLoader()
        ) : (
          <Grid container spacing={4}>
            {activeTab === 0 &&
              items.map((product) => (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  {"itemNumber" in product && (
                    <ProductCardWithHooks
                      highlightTerm={termCleaned}
                      product={product}
                      handlePreview={handlePreview}
                      handleMemo={handleAddMemo}
                      activeCart={cart}
                      toggleFavorite={() => setFavoriteProduct(product)}
                      openExternally={true}
                    />
                  )}
                </Grid>
              ))}

            {activeTab === 1 &&
              items.map((collection) => (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <CollectionBox
                    title={collection.name}
                    collectionImage={collection.imageUrl}
                    link={`/collections/${collection.slug}`}
                    subtitle={""}
                    variant={"secondary"}
                    openExternally={true}
                  />
                </Grid>
              ))}

            {activeTab === 2 &&
              items.map((product) => (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <CollaboratorCard collaborator={product} openExternally={true} />
                </Grid>
              ))}
          </Grid>
        )}
      </InfiniteScroll>

      {items&&items.length===0&&<>
        <Grid item container justify={'center'} alignContent={'center'} alignItems={'center'} style={{minHeight:300}}>
          <Grid item>
            <Typography variant={'h3'}> No results found. Try something else? </Typography>
          </Grid>
        </Grid>
      </>}

      <ProductDialog
        open={openPreview}
        handleClose={() => setOpenPreview(false)}
        itemNumber={selectedProduct?.itemNumber}
      />
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      NavigationMenu: state.NavigationMenu,
      Cart: state.Cart,
      Auth: state.Auth,
    },
  };
};

export default connect(mapStateToProps, {
})(Discover);
