export const createTradeAccountDefaultValues = {
  firstName: '', // not sent
  lastName: '', // not sent
  email: '', // not sent
  title: '',
  businessFocus: '',
  focus: 'RESIDENTIAL',
  focusOnResidentialHospitality: false,// this is set by checkbox (both residential/hospitality)
  fedTaxId: '',
  businessOwner: '',

  businessType: '',
  otherBusinessType: '',
  businessLink: '',
  uploadedFiles: false,

  contacts: [
    {
      name: '',
      contactType: 'BILLING', // or SHIPPING or BUSINESS
      countryId: '',
      phone: '',
      addressLine1: '',
      addressLine2: '',
      zipCode: '',
      city: '',
      stateId: ''
    }
  ],

  proofOfBusinessFiles: [],
  skipProofOfBusiness: false,

  taxExemptForms: [
    {
      stateId: '',
      files: []
    }
  ],

  sameAsBusinessAddressShipping: true,
  sameAsBusinessAddressBilling: true,

  // formik fields
  phone: '',
  country: '',
  businessTypes: '',

  businessName:'',
  businessCity:'',
  businessAddressLine1: '',
  businessZipCode:'',
  businessState: '',

  billingName: '',
  billingAddressLine1: '',
  billingZipCode: '',
  billingCity: '',
  billingState: '',
  shippingName: '',
  shippingAddressLine1: '',
  shippingZipCode: '',
  shippingCity: '',
  shippingState: '',

}
