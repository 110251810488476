import {Box, Button, Grid, Typography} from "@material-ui/core";
import {SectionTitle} from "../../../Account/CreateTradeAccount/steps/common/SectionTitle";
import {Form, Formik} from 'formik'
import FormikSelect from "../../../../components/Core/FormikFields/FormikSelect";
import AddIcon from '@material-ui/icons/Add';
import AddNoteDialog from "./AddNoteDialog";
import {useEffect, useState} from "react";
import moment from "moment";
import {getUsername} from "utils";
import {connect} from "react-redux";
import {FormikContext} from "components/FormikDisabler";

const SalesTerritory = ({formData, setFormData, formRef, tradeAccount=false, context, disabled=false,locked=false}) => {

  // const [salesTerritories] = useConfig(`sales-territories`, [])

  const salesTerritories = context.SalesTerritory.getAll.result;

  const [openAddNote,setOpenAddNote] = useState(false)
  const [territories, setTerritories] = useState([])
  const [aes, setAes] = useState([])

  useEffect(()=>{
    if (salesTerritories) {
      const territories = []

      salesTerritories.forEach((t)=>{
        if (!territories.find((tt)=>tt.id===t.territory)) {
          if (t.territory) {
            territories.push({
              id: t.territory,
              name: t.territory
            })
          }
        }
      })

      setTerritories(territories)

      const aes = {}

      territories.forEach((territory)=>{

        salesTerritories.filter((t)=>t.territory===territory.id).forEach((t)=>{

          if (!aes[t.territory]) {
            if (t.ae) {
              aes[t.territory] = [{
                id: t.ae,
                name: t.ae
              }]
            }
          } else {
            if (!aes[t.territory].find((a)=>a.id===t.ae)) {
              if (t.ae) {
                aes[t.territory].push({
                  id: t.ae,
                  name: t.ae
                })
              }
            }
          }
        })

      })

      setAes(aes)

    }
  },[salesTerritories])


  const setNotes = (notes) => {
    setFormData(prevFormData => ({...prevFormData, notes}))
  }

  // const RepsHandler = () => {
  //   const {values} = useFormikContext()
  //
  //   useEffect(()=>{
  //     if (values.salesTerritory&&values.ae) {
  //       const matchedReps = salesTerritories.filter((st)=>st.territory===values.salesTerritory&&st.ae===values.ae)
  //       const newReps = []
  //
  //       matchedReps.forEach((rep)=>{
  //         if (rep.rep&&!newReps.find((r)=>r.id===rep.rep)) {
  //           newReps.push({
  //             id: rep.rep,
  //             name:rep.rep
  //           })
  //         }
  //       })
  //       setReps(newReps)
  //       console.log('set reps')
  //     }
  //   },[values.salesTerritory, values.ae])
  //
  //   return null
  // }

  const getReps = (values) => {
    const matchedReps = salesTerritories.filter((st)=>st.territory===values.salesTerritory&&st.ae===values.ae)
    const newReps = []

    matchedReps.forEach((rep)=>{
      if (rep.rep&&!newReps.find((r)=>r.id===rep.rep)) {
        newReps.push({
          id: rep.rep,
          name:rep.rep
        })
      }
    })
    return newReps
  }

  return <> <Formik innerRef={formRef}

    initialValues={{...formData}}
                     enableReinitialize={true}
                     // validationSchema={validationSchema}
                     onSubmit={(values)=>{
                       setFormData(prevFormData => ({...prevFormData, ...values}))
                     }}
  >
    {({values,errors,setFieldValue}) => (<Form noValidate>
      <FormikContext.Provider value={{disabled, locked}}>

        <Grid item container spacing={2}>

          <Grid item xs={12}>
          <Typography variant="h5" style={{fontWeight: 700, marginTop: 16}}>
            Sales Territory & Notes
          </Typography>
        </Grid>

          <Box pt={6} />

          <Grid item xs={12}>
            <SectionTitle>Territory Info</SectionTitle>
          </Grid>

          <Grid item container spacing={2}>
          <Grid item xs={12} md={tradeAccount?4:6}>
            <FormikSelect
              variant={'outlined'}
              margin="none"
              fullWidth
              id={'salesTerritory'}
              name={'salesTerritory'}
              label={'Sales Territory'}
              options={territories}
              disabled={territories&&territories.length===0}
              loading={territories&&territories.length===0}
            />
          </Grid>

          <Grid item xs={12} md={tradeAccount?4:6}>
            <FormikSelect
              variant={'outlined'}
              margin="none"
              fullWidth
              id={'ae'}
              name={'ae'}
              label={'AE'}
              options={aes&&aes[values.salesTerritory]&&aes[values.salesTerritory].length!==0?aes[values.salesTerritory]:[{id:0,name:'-'}]}
              disabled={((aes&&aes.length===0)||(territories&&territories.length===0))}
              loading={((aes&&aes.length===0)||(territories&&territories.length===0))}
            />
          </Grid>

          {tradeAccount&&<Grid item xs={12} md={4}>
            <FormikSelect
              variant={'outlined'}
              margin="none"
              fullWidth
              id={'rep'}
              name={'rep'}
              label={'Sale Rep'}
              options={getReps(values)}
            />
          </Grid>}


          <Grid item xs={12}>
            <SectionTitle>Account Notes</SectionTitle>
          </Grid>

          <Grid item xs={12}>
            <Box width={'100%'} minHeight={150} style={{borderRadius:16, border:'1px solid #000', overflowY:'scroll', opacity: disabled?0.3:1}}>
              {formData.notes&&formData.notes.length!==0&&<Box p={1} maxHeight={150}>
                {formData.notes.map((note)=>{
                  return <Box p={1}>[ {note.userName?note.userName:getUsername()} - {moment(note.timestamp).format("MM/DD/yyyy HH:mm:ss")}] {note.text}</Box>
                })}
              </Box>}
            </Box>
            <Grid item container justify='flex-end' alignContent='flex-end'>
              <Grid item>
                <Button size='large' onClick={()=>{setOpenAddNote(true)}} disabled={disabled}>
                  <Box fontWeight='bold' display='flex'>
                    <AddIcon/>&nbsp; Add new notes
                  </Box></Button>
              </Grid>
            </Grid>
          </Grid>

        </Grid>

        </Grid>

      </FormikContext.Provider>

    </Form>)}
  </Formik>
    {openAddNote&&<AddNoteDialog open={openAddNote} setOpen={setOpenAddNote} notes={formData.notes} setNotes={setNotes} />}
  </>
}

const mapStateToProps = (state) => {
  return {
    context: {
      Auth: state.Auth,
      SalesTerritory: state.SalesTerritory
    }
  };
};

export default connect(mapStateToProps, { })(SalesTerritory);
