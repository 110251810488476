import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import clsx from "clsx"

const useStyles = makeStyles({
  root: {
    color: "inherit",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});
const UnderlinedLink = (props) => {
  const classes = useStyles();
  return <Link {...props}  className={clsx(classes.root, props.className)} />;
};

export default UnderlinedLink;
