import Dialog from '../Dialog/Dialog'
import {
  Box, FormControl,
  Link,
  ListItem,
  ListItemSecondaryAction,
  ListItemText, MenuItem, Select,
  Typography
} from '@material-ui/core'
import {useDropzone} from "react-dropzone";
import {getAxiosConfig} from "../../utils";
import RootRef from '@material-ui/core/RootRef';
import {useEffect, useState} from "react";
import List from "@material-ui/core/List";
import {API_URL} from "../../config";
import axios from "axios";
import TaxExemptInfoDialog from "pages/Account/CreateTradeAccount/steps/TaxExemptInfoDialog";

const TaxExemptFormDialog = ({open, setOpen, setFiles, files, states, selectedState, setSelectedState, deleteFile, deletingFile, onUploadSuccess}) => {

  const [loadedFiles, setLoadedFiles] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [exampleFormId, setExampleFormId] = useState()
  const [showInfoDialog, setShowInfoDialog] = useState()

  useEffect(()=>{
    if (selectedState) {
      setExampleFormId(false)
      const url = `${API_URL}/config/tax-exempt-form-samples/${selectedState}`
      axios.get(url,getAxiosConfig(true))
        .then((response) => {
          if (response.data.fileId) {
            setExampleFormId(response.data.fileId)
          }
        })
        .catch((e)=>{
          setExampleFormId(false)
          console.log(e)
        })
    }
  },[selectedState])

  const onDrop = async (acceptedFiles) => {
    setLoadedFiles([...loadedFiles,...acceptedFiles])
  }

  const uploadSelected = () => {

    const url = `${API_URL}/files/upload`

    const formData = new FormData();

    loadedFiles.forEach((file)=>formData.append('files', file))

    setIsBusy(true)

    axios.post(url,formData,getAxiosConfig(true))
      .then((response) => {
        if (response.status === 200) {

          let newFiles = {...files}

          // If state files exist, append
          if (newFiles[selectedState]) {
            newFiles[selectedState] = [...newFiles[selectedState], ...response.data]
          } else { // else add
            newFiles[selectedState] = [...response.data]
          }

          setFiles({...newFiles})
        }
        setIsBusy(false);
        handleClose();
        onUploadSuccess && onUploadSuccess()
      })
      .catch((err) => {
        // Silent error
        setIsBusy(false);
        console.log(err)
        throw err;
      });

  }

  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const {ref, ...rootProps} = getRootProps()

  const handleClose = () => {
    setOpen(false)
    setLoadedFiles([])
  }
  return <>
    <TaxExemptInfoDialog open={showInfoDialog} setOpen={setShowInfoDialog} handleCancel={handleClose}/>
    <Dialog
      onClose={handleClose}
      open={open}
      title={'Tax Exempt Form'}
      maxWidth="sm"
      primaryActionTitle={'UPLOAD'}
      secondaryActionTitle={'CANCEL'}
      primaryActionColor={"secondary"}
      primaryActionVariant={"contained"}
      primaryAction={()=>{uploadSelected()}}
      secondaryAction={()=>{handleClose()}}
      inProgress={isBusy}
      disableBackdropClick={isBusy}
      disableEscapeKeyDown={isBusy}
    >

      <Box display='flex' pt={1} mb={3}>
        Learn more about circumstances that might affect your tax exemption&nbsp;
        <span style={{textDecoration:'underline', fontWeight:'bold', cursor:'pointer'}} onClick={()=>setShowInfoDialog(true)}>HERE. </span>
      </Box>

      <Typography variant='h6' style={{ fontWeight:800 }}>
        Exempt State
      </Typography>

      <Box mt={2} mb={2} maxWidth={400} display={'flex'}>

        <Box display='flex' width={200}>
          <FormControl
            variant={"outlined"}
            margin="none"
            fullWidth
            id={"state"}
            options={states}
            disabled={states&&states.length===0}
            required
            handleChange={(v)=>setSelectedState(v)}
            value={selectedState}
          >
            {/*<InputLabel id="demo-simple-select-outlined-label">*/}
            {/*  Tax Exempt State*/}
            {/*</InputLabel>*/}
            <Select
              value={selectedState}
              onChange={(e) => {
                setSelectedState(e.target.value)
              }}
            >
              {states.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        <Box display='flex' p={2} pt={1}>
          {exampleFormId&&<a href={`${API_URL}/files/${exampleFormId}`} target='_blank' rel='noreferrer'>Show Example Form</a>}&nbsp;
        </Box>


      </Box>

      <Typography variant='h6' style={{ fontWeight:800 }}>
        Exempt Form
      </Typography>

      <Box>
        <Box mt={2} mb={2} style={{border: '1px solid #000', borderRadius: 16, minHeight: 160}}>

          {loadedFiles.length!==0&&<>

            <List dense={true}>
              {loadedFiles.map((file,i)=>{
                return <ListItem>
                  <ListItemText style={{paddingRight: 16}}
                                primary={file.name}
                  />
                  <ListItemSecondaryAction>
                    <Link to={"#"} style={{cursor:'pointer'}} onClick={()=>{
                      let newFiles = [...loadedFiles];
                      newFiles.splice(i, 1);
                      setLoadedFiles(newFiles);
                    }}> <b>Remove</b> </Link>
                  </ListItemSecondaryAction>
                </ListItem>
              })}
            </List>

          </>}


          {files[selectedState]&&files[selectedState].length!==0&&<>

            <List dense={true}>
              {files[selectedState].map((file,i)=>{
                return <ListItem>
                  <ListItemText style={{paddingRight: 16}}
                                primary={file.filename}
                  />
                  <ListItemSecondaryAction>
                    <Link to={"#"} style={{cursor:'pointer', opacity:deletingFile?0.3:1}} onClick={()=>{
                      if (!deletingFile) {
                        deleteFile(file.id)
                      }
                    }}> <b>Remove</b> </Link>
                  </ListItemSecondaryAction>
                </ListItem>
              })}
            </List>

          </>}

          <RootRef rootRef={ref}>
            <Box m={3} textAlign='center' lineHeight={2} {...rootProps}>
              <input name='image' type='file' {...getInputProps()} accept='.png, .jpg, .jpeg, .tiff, .pdf'/>
              {loadedFiles.length===0&&<>Drag and drop images (jpeg, tiff, png files) or digital PDF file(s) into this box or <br/></>}
              <span style={{fontWeight:'bold',textDecoration:'underline', cursor:'pointer'}}>Browse Files</span>
            </Box>
          </RootRef>

        </Box>
        <Box fontSize={12} p={1} pt={0} fontWeight='600'>TOTAL SIZE OF UPLOADING FILES CANNOT EXCEED 50MB</Box>
      </Box>

    </Dialog>
  </>
}

export default TaxExemptFormDialog
