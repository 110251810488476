import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { getStock, ProductItemCell } from "../ProductViews";
import { toCurrency } from "../../utils";
import {
  Check,
  ExpandLess,
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreVert,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { orderItemType } from "../../constants/enums";
import Text from "../Text/Text";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Popover from "../Popover/Popover";
import { List } from "react-virtualized";
import Tooltip from "../Tooltip/Tooltip";
import { useSelector } from "react-redux";
import Pluralize from "pluralize";
import CloseIcon from "@material-ui/icons/Close";
import UnderlinedButton from "../Core/UnderlinedButton";

const useStyles = makeStyles((theme) => ({
  menu: {
    padding: "16px",
  },
  btnSave: {
    backgroundColor: theme.palette.secondary.main,
    width: 28,
    height: 28,
  },
  btnClose: {
    backgroundColor: theme.palette.secondary.light,
    width: 28,
    height: 28,
  },
  menuItem: {
    padding: "8px",
  },
  menuButton: {
    fontWeight: "bold",
    fontSize: 12,
    marginBottom: 2,
    borderBottom: "1px solid",
    borderRadius: 0,
    padding: 0,
    minWidth: 55,
  },
  moreButton: {
    "&:hover": {
      boxShadow: "0px 0px 16px rgba(27, 28, 29, 0.1)",
      backgroundColor: "white",
    },
  },
  selectedRow: {
    backgroundColor: theme.palette.secondary.light,
  },
  noteField: {
    borderRadius: 10,
    textAlign: "center",
  },
  divider: {
    backgroundColor: "#1B1C1D",
  },
  list: {
    maxHeight: "calc(100vh - 356px)",
    overflow: "auto",
  },
}));

function NotesColumn({ item, handleUpdate, processingCart, dirtyChanged }) {
  const classes = useStyles();
  const [notes, setNotes] = useState(item.notes);

  const ref = useRef(null);
  useEffect(() => {
    ref.current = setTimeout(() => {
      dirtyChanged(item.id, item.notes !== notes);
    }, 500);
    return () => {
      clearTimeout(ref.current);
    };
  }, [dirtyChanged, item.id, item.notes, notes]);

  useEffect(() => {
    setNotes(item.notes);
  }, [item.notes]);

  return (
    <Fragment>
      <Box display={"flex"} flexDirection={"column"}>
        <TextField
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          variant={"outlined"}
          color={"primary"}
          multiline
          style={{ maxWidth: 144 }}
          rowsMax={2}
          placeholder={"Item Notes \nClick to Edit"}
          disabled={processingCart}
          InputProps={{
            classes: {
              root: classes.noteField,
            },
          }}
        />
        <Collapse in={item.notes !== notes}>
          <Box display={"flex"} justifyContent={"space-between"} px={3} mt={1}>
            <IconButton
              className={classes.btnClose}
              size={"small"}
              disabled={processingCart}
              onClick={() => {
                setNotes(item.notes);
              }}
            >
              <CloseIcon />
            </IconButton>
            <IconButton
              className={classes.btnSave}
              size={"small"}
              disabled={notes.length > 35 || processingCart}
            >
              <Check
                fontSize={"small"}
                onClick={() => handleUpdate({ ...item, notes })}
              />
            </IconButton>
          </Box>
        </Collapse>
      </Box>
    </Fragment>
  );
}

export function QuantitySelect({ cartItem, busy, handleUpdateItem }) {
  const {
    quantity,
    // quantityAvailable,
    quantityIncrement,
    quantityMinimum,
    quantityMaximum,
  } = cartItem;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMemo = useMemo(
    () => cartItem.type === orderItemType.SAMPLE,
    [cartItem.type]
  );
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleNewQuantity = (e, opt) => {
    handleClose(e);
    if (opt !== quantity) {
      handleUpdateItem({
        ...cartItem,
        quantity: opt,
      });
    }
  };

  const options = useMemo(() => {
    let arr = [];
    const step = isMemo ? 1 : quantityIncrement || 1;
    const min = isMemo ? 1 : quantityMinimum || 1;
    for (let i = min; i <= quantityMaximum; i += step) {
      if (i <= quantityMaximum) {
        arr.push(i);
      }
    }
    return arr;
  }, [isMemo, quantityMaximum, quantityIncrement, quantityMinimum]);

  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) {
    const opt = options[index];
    return (
      <div style={style} key={key}>
        <MenuItem key={opt} onClick={(e) => handleNewQuantity(e, opt)}>
          <Text noWrap bold={quantity === opt}>
            {opt}
          </Text>
        </MenuItem>
      </div>
    );
  }

  return (
    <div>
      <Button
        className={classes.menuButton}
        aria-controls="simple-menu"
        aria-haspopup="true"
        color={"primary"}
        onClick={handleClick}
        disabled={busy}
        endIcon={!open ? <ExpandMoreIcon /> : <ExpandLess />}
      >
        {quantity}
      </Button>
      <Popover
        open={open}
        containerStyles={{ padding: 0 }}
        placement={"bottom-start"}
        handleClose={handleClose}
        anchorEl={anchorEl}
      >
        <div style={{ height: 200, width: 90 }}>
          <List
            width={90}
            height={200}
            rowCount={options.length}
            rowHeight={33}
            rowRenderer={rowRenderer}
            scrollToIndex={
              options.findIndex((o) => o === quantity) !== -1
                ? options.findIndex((o) => o === quantity) + 5
                : 0
            }
          />
        </div>
      </Popover>
    </div>
  );
}

function OrderItemListRow({
  row,
  processingCart,
  handleUpdateItem,
  handleRemoveItem,
  handleSelectItem,
  selectableMode,
  selectedIds,
  isSelectedForCheckout,
  dirtyChanged,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onDelete = useCallback(() => {
    handleRemoveItem(row.id);
    setAnchorEl(null);
  }, [handleRemoveItem, row.id]);

  const onSelect = useCallback(() => {
    setAnchorEl(null);
    handleSelectItem(row.id);
  }, [handleSelectItem, row.id]);

  const toCheckout = isSelectedForCheckout(row.type, row.id);
  const isProd = row.type === orderItemType.PRODUCT;

  return (
    <Box mb={1} style={{ flex: 1 }} id={`cart_item_${row.id}`}>
      <Grid
        container
        alignItems={"center"}
        className={toCheckout && classes.selectedRow}
      >
        <Grid item xs={4} component={Box}>
          <ProductItemCell item={row} />
        </Grid>
        <Grid item xs={2} component={Box} pl={4}>
          <Box display={"flex"} alignItems={"center"} mb={1}>
            <QuantitySelect
              cartItem={row}
              busy={processingCart}
              handleUpdateItem={handleUpdateItem}
            />
            <Box ml={1}>
              <Text>
                {Pluralize(
                  row.type === orderItemType.SAMPLE ? "Item" : row.pricedBy,
                  row.quantity
                )}
              </Text>
            </Box>
          </Box>
          {getStock(row.quantityAvailable)}
        </Grid>
        <Grid item xs={3} component={Box} pl={4}>
          <div>
            <Box display={"flex"} alignItems={"center"}>
              {isProd && (
                <Tooltip
                  title={`Minimum Order Quantity: ${
                    row.quantityMinimum
                  } ${Pluralize(
                    row.pricedBy,
                    row.quantityMinimum
                  )} Order Increment: ${row.quantityIncrement} ${Pluralize(
                    row.pricedBy,
                    row.quantityIncrement
                  )}`}
                >
                  <InfoOutlined />
                </Tooltip>
              )}
              {isProd && <Text bold>{toCurrency(row.netAmount)}</Text>}
            </Box>
            <Text bold={!isProd}>
              {toCurrency(row.price, true)}
              {isProd && <Fragment>per {row.pricedBy}</Fragment>}
            </Text>
          </div>
        </Grid>
        <Grid item xs={3} component={Box} pl={4} pr={1}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <NotesColumn
              item={row}
              handleUpdate={handleUpdateItem}
              processingCart={processingCart}
              dirtyChanged={dirtyChanged}
            />
            <Box ml={2}>
              {selectableMode ? (
                <Checkbox
                  checked={selectedIds.includes(row.id)}
                  onChange={() => handleSelectItem(row.id)}
                  color={"secondary"}
                  disabled={processingCart}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              ) : (
                <IconButton
                  size={"small"}
                  className={classes.moreButton}
                  disabled={processingCart}
                  onClick={(e) => handleClick(e, row)}
                >
                  <MoreVert />
                </IconButton>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.menu }}
      >
        <MenuItem className={classes.menuItem} onClick={onSelect}>
          Select Item
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={onDelete}>
          Delete Item
        </MenuItem>
      </Menu>
    </Box>
  );
}

function Toolbar({
  selectableMode,
  handleDeleteSelection,
  selectedIds,
  setSelectedIds,
  processingCart,
  setSelectableMode,
  items,
}) {
  const handleCancelSelection = useCallback(() => {
    setSelectedIds([]);
    setSelectableMode(false);
  }, [setSelectableMode, setSelectedIds]);

  const handleSelectAll = () => {
    if (selectedIds.length < items.length) {
      setSelectedIds(items.map((x) => x.id));
    } else {
      setSelectedIds([]);
    }
  };

  return (
    <Collapse in={selectableMode}>
      <Box pr={1} py={1}>
        <Grid container spacing={1} justify={"flex-end"} alignItems={"center"}>
          <Grid item>
            <UnderlinedButton
              onClick={handleCancelSelection}
              disabled={processingCart}
            >
              Cancel
            </UnderlinedButton>
          </Grid>

          <Grid item>
            <UnderlinedButton
              onClick={handleDeleteSelection}
              disabled={processingCart}
            >
              Delete
            </UnderlinedButton>
          </Grid>
          <Grid item>
            <Box display={"flex"}>
              <UnderlinedButton
                onClick={handleSelectAll}
                disabled={processingCart}
              >
                {items.length > 0 && selectedIds.length === items.length
                  ? "Unselect All"
                  : "Select All"}
              </UnderlinedButton>
              <Checkbox
                onClick={handleSelectAll}
                indeterminate={
                  selectedIds.length > 0 && selectedIds.length < items.length
                }
                checked={
                  items.length > 0 && selectedIds.length === items.length
                }
                inputProps={{ "aria-label": "indeterminate checkbox" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Collapse>
  );
}

function IconToggle({ expanded }) {
  const Icon = expanded ? KeyboardArrowDown : KeyboardArrowUp;
  return <Icon color={"primary"} />;
}

function Header({ expanded, toggle, size, amounts, ...rest }) {
  const classes = useStyles();
  if (!rest.collapsable) return <Toolbar {...rest} />;
  return (
    <Box>
      <Divider className={classes.divider} />
      <Box style={{ height: 48 }} display={"flex"} alignItems={"center"}>
        <IconButton onClick={toggle} style={{ width: 40, height: 40 }}>
          <IconToggle expanded={expanded} />
        </IconButton>
        <Text bold style={{textTransform: "capitalize"}}>
          {productReadable(rest.type)} ({rest.items.length})
          {amounts?.netAmount > 0 && ` - ${toCurrency(amounts?.netAmount)}`}
        </Text>
        <div style={{ flex: 1 }} />
        <Toolbar {...rest} />
      </Box>
      <Divider className={classes.divider} />
    </Box>
  );
}


function productReadable(type) {
  return type === orderItemType.PRODUCT ? "products" : "memos";
}

export default function OrderItemList({
  items,
  processingCart,
  handleRemoveItem,
  handleRemoveItems,
  handleUpdateItem,
  type,
  activeTab,
  collapsable,
  amounts,
  setRootSelectedIds,
  expandedType,
  setExpandedType,
  dirtyChanged,
}) {
  const classes = useStyles();
  const [selectableMode, setSelectableMode] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    setRootSelectedIds(selectedIds);
  }, [selectedIds, setRootSelectedIds]);

  useEffect(() => {
    setSelectedIds([]);
    setSelectableMode(false);
    return () => {
      setRootSelectedIds([]);
    };
  }, [activeTab, setRootSelectedIds]);

  const isSelectedForCheckout = useCallback(
    (type, id) => {
      return !!selectedIds.find((x) => x.id === id);
    },
    [selectedIds]
  );
  const highlightItemId = useSelector((state) => state.Cart.highlightItemId);

  useLayoutEffect(() => {
    if (!highlightItemId) return;
    const cartItem = items.find((x) => x.productId === highlightItemId);
    if (!cartItem) return;
    setTimeout(() => {
      const el = document.getElementById(`cart_item_${cartItem.id}`);
      if (!el) return;
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 1000);
  }, [highlightItemId, items]);

  const handleSelectItem = useCallback(
    (itemId) => {
      if (!selectableMode) {
        // handleSelectAll();
        setSelectedIds([itemId]);
        setSelectableMode(true);
      } else {
        setSelectedIds((prevState) => {
          if (prevState.includes(itemId)) {
            return prevState.filter((id) => id !== itemId);
          }
          return [...prevState, itemId];
        });
      }
    },
    [selectableMode]
  );

  const handleDeleteSelection = useCallback(() => {
    handleRemoveItems(selectedIds);
  }, [handleRemoveItems, selectedIds]);

  // Remove an item id if the items is removed
  const itemIds = useMemo(() => items.map((x) => x.id), [items]);
  useEffect(() => {
    setSelectedIds((prevState) => {
      return prevState.filter((id) => itemIds.includes(id));
    });
  }, [itemIds]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      width={"100%"}
      style={{ marginTop: -1 }}
    >
      <Header
        processingCart={processingCart}
        selectableMode={selectableMode}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        setSelectableMode={setSelectableMode}
        handleDeleteSelection={handleDeleteSelection}
        items={items}
        collapsable={collapsable}
        type={type}
        expanded={expandedType === type}
        toggle={() => {
          setExpandedType(expandedType === type ? null : type);
        }}
        amounts={amounts}
      />
      {items.length === 0 && (
          <Box display={"flex"} justifyContent={"center"} p={2}>
            <Typography>No {productReadable(type)} added yet</Typography>
          </Box>
      )}
      <Collapse in={!collapsable || expandedType === type}>
        <Box
          pt={items.length > 0}
          display={"flex"}
          flexDirection={"column"}
          width={"100%"}
          className={collapsable && classes.list}
        >
          {items.map((row) => (
            <OrderItemListRow
              row={row}
              key={row.id}
              handleSelectItem={handleSelectItem}
              handleUpdateItem={handleUpdateItem}
              handleRemoveItem={handleRemoveItem}
              processingCart={processingCart}
              selectableMode={selectableMode}
              selectedIds={selectedIds}
              isSelectedForCheckout={isSelectedForCheckout}
              dirtyChanged={dirtyChanged}
            />
          ))}
        </Box>
      </Collapse>
    </Box>
  );
}
