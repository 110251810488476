import {entityType} from "./module";
import {createDeleteMethod, createGetAllMethod, createPostMethod, createPutMethod} from "reactcoregk/store/actions";
import { getDefaultHeaders} from "../../utils";
import ApiHandler from "reactcoregk/models/apiHandler";
import {API_URL} from "../../config";

const getAllApiHandler = (tradeAccountId) => {
    const endpoint = `${API_URL}/trade-account/${tradeAccountId}/contact-information`
    return new ApiHandler(false, false, getDefaultHeaders(), "default", "", endpoint)
}

const createApiHandler = (tradeAccountId) => {
    const endpoint = `${API_URL}/trade-account/${tradeAccountId}/contact-information`
    return new ApiHandler(false, false, getDefaultHeaders(), "default", "", endpoint)
}

const updateApiHandler = (payload, tradeAccountId) => {
    const endpoint = `${API_URL}/trade-account/${tradeAccountId}/contact-information/${payload.id}`
    return new ApiHandler(false, false, getDefaultHeaders(), "default", "", endpoint)
}

const deleteApiHandler = () => {
    const endpoint = `${API_URL}/contact-information`
    return new ApiHandler(false, false, getDefaultHeaders(), "default", "", endpoint)
}

export const getAllAddresses = (tradeAccountId) => createGetAllMethod(entityType, "", getAllApiHandler(tradeAccountId))
export const createAddress = (payload, tradeAccountId) => createPostMethod(entityType, payload, createApiHandler(tradeAccountId))
export const updateAddress = (payload, tradeAccountId) => createPutMethod(entityType, payload, updateApiHandler(payload, tradeAccountId))
export const deleteAddress = (payload) => createDeleteMethod(entityType, payload, deleteApiHandler())

