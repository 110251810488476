import {
  fetchData,
  getParameterByName,
  getRoles,
  getUserId,
  updateURLParameter,
} from "../utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { catalogFilter, userRoles } from "../constants/enums";
import { API_URL } from "../config";
import { createMap } from "reactcoregk/utils";

export const useAttributes = (location, history, match) => {
  const attributeParam = getParameterByName("attributeId");
  const attributes = useMemo(
    () => (attributeParam ? attributeParam.split(",") : []),
    [attributeParam]
  );

  const handleAddAttribute = useCallback(
    (value) => {
      const newAttributes = [...attributes, value].toString();
      const search = updateURLParameter(
        location.search,
        "attributeId",
        newAttributes
      );
      history.push({
        pathname: match.url,
        search: search,
      });
    },
    [attributes, history, location.search, match.url]
  );

  const handleDeleteAttribute = useCallback(
    (value) => {
      const newAttributes = attributes.filter((x) => x !== value.toString());
      const search = updateURLParameter(
        location.search,
        "attributeId",
        newAttributes
      );
      history.push({
        pathname: match.url,
        search: search,
      });
    },
    [attributes, history, location.search, match.url]
  );

  return {
    attributes,
    handleAddAttribute,
    handleDeleteAttribute,
  };
};

export const useCatalogFilters = (
  isAuth,
  rootParams,
  type,
  categoryId = ""
) => {
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [loadingPlacements, setLoadingPlacements] = useState(false);
  const [featuredPlacements, setFeaturedPlacements] = useState([]);
  const [filters, setFilters] = useState([]);
  const userId = getUserId();

  // Clear filters immediately when category is changed
  useEffect(() => {
    setFeaturedPlacements([]);
    setFilters([]);
  }, [categoryId]);

  useEffect(() => {
    (async () => {
      setLoadingFilters(true);
      const url =
        type !== catalogFilter.FAVORITES
          ? `${API_URL}/products/filters/${type}?${rootParams}&categoryId=${categoryId}&sort=count,descending`
          : `${API_URL}/users/${userId}/products/filters/favorites?${rootParams}`;
      try {
        const res = await fetchData(url);
        setFilters(res.filters);
      } catch (e) {}
      setLoadingFilters(false);
    })();
  }, [categoryId, rootParams, isAuth, type, userId]);

  useEffect(() => {
    (async () => {
      let url = "";
      if (type !== catalogFilter.NEW_ARRIVAL && type !== catalogFilter.CATEGORY)
        return;
      else
        url =
          type === catalogFilter.NEW_ARRIVAL
            ? `${API_URL}/collections/new-arrivals/featured-placements`
            : `${API_URL}/categories/${categoryId}/featured-placements`;

      setLoadingPlacements(true);

      try {
        const featuredPlacements = await fetchData(url);
        setFeaturedPlacements(featuredPlacements);
      } catch (e) {}
      setLoadingPlacements(false);
    })();
  }, [categoryId, type, userId]);

  const [defaultFilterIds, setDefaultFilterIds] = useState([]);

  useEffect(() => {
    const stored = getDefaultFilters(categoryId);
    if (stored[0]) setDefaultFilterIds(stored);
    else
      setDefaultFilterIds(
        filters.filter((x) => x.defaultActive).map((x) => x.typeId)
      );
  }, [filters, categoryId]);

  const selectFilter = useCallback(
    (typeId) => {
      setDefaultFilterIds((prevIds) => {
        const result = [...prevIds, typeId];
        localStorage.setItem(`filters-${categoryId}`, JSON.stringify(result));
        return result;
      });
    },
    [categoryId]
  );

  const deselectFilter = useCallback(
    (typeId) => {
      setDefaultFilterIds((prevIds) => {
        const result = prevIds.filter((x) => x !== typeId);
        localStorage.setItem(`filters-${categoryId}`, JSON.stringify(result));
        return result;
      });
    },
    [categoryId]
  );

  const toggleFilter = useCallback(
    (typeId) => {
      const exist = defaultFilterIds.includes(typeId);
      if (exist) deselectFilter(typeId);
      else selectFilter(typeId);
    },
    [defaultFilterIds, deselectFilter, selectFilter]
  );

  const visibleFilters = useMemo(() => {
    return filters
      .filter((x) => defaultFilterIds.includes(x.typeId))
      .map((filter) => ({
        ...filter,
        options: filter.options
          .filter((opt) => opt.typeId !== null)
          // .sort((a, b) => compareProp(a, b, "value")),
      }));
  }, [filters, defaultFilterIds]);

  return {
    featuredPlacements,
    loadingFilters,
    loadingPlacements,
    visibleFilters: visibleFilters,
    allFilters: filters,
    selectFilter,
    deselectFilter,
    defaultFilterIds,
    toggleFilter,
  };
};

function getDefaultFilters(categoryId) {
  const filters = localStorage.getItem(`filters-${categoryId}`);
  return filters ? JSON.parse(filters) : [];
}

// function getNavLink(menu, parent) {
//   if (parent.categoryId) {
//     return `/catalog/${
//       parent.categoryId
//     }?attributeId=${menu.attributeTypeIds?.toString()}`;
//   } else {
//     return `/pages/${menu.slug}`;
//   }
// }

export const useNavigationLinks = (NavigationMenuContext) => {
  const menuItems = NavigationMenuContext.getAll.result;
  return useMemo(
    () =>
      menuItems.map((menu) => {
        const { relativeUrl, absoluteUrl, name } = menu;
        return {
          name,
          path: relativeUrl,
          href: absoluteUrl,
          // children: menu.children.map((child) => ({
          //   name: child.name.toLowerCase(),
          //   path: getNavLink(child, menu),
          // })),
        };
      }),
    [menuItems]
  );
};

export const useProductSorting = (location, history, match, auth) => {
  const { isAuth, validatingUser } = auth;
  const sortParam = getParameterByName("sort") || "popularityScore,desc,itemNumber,desc";

  const handleSortBy = useCallback(
    (option) => {
      const search = updateURLParameter(
        location.search,
        "sort",
        `${option.id},${option.dir}`
      );
      if (option.id ==='itemNumber')
      history.push({
        pathname: match.url,
        search: `${search}`,
      });
      else
      {
        history.push({
          pathname: match.url,
          search: `${search},itemNumber,desc`,
        });
      }
    },
    [history, location.search, match.url]
  );

  const isActive = useCallback(
    (opt) => {
      const { id, dir } = opt;
      if (id==='itemNumber')
      return `${id},${dir}` === sortParam;
      return `${id},${dir},itemNumber,desc` === sortParam;
    },
    [sortParam]
  );

  const canFilterByPrice = useMemo(() => {
    return isAuth && !getRoles().includes(userRoles.WEB_USER);
  }, [isAuth]);

  useEffect(() => {
    if (!validatingUser && !isAuth && sortParam.split(",")[0] === "priceUsd") {
      const search = updateURLParameter(location.search, "sort", "");
      history.push({
        pathname: match.url,
        search: search,
      });
    }
  }, [history, isAuth, location.search, match.url, sortParam, validatingUser]);

  const sortOptions = useMemo(() => {
    const options = [
      {
        id: "popularityScore",
        name: "Popularity",
        dir: "desc",
      },
      {
        id: "arrivalDate",
        name: "Recently Added",
        dir: "desc",
      },
      {
        id: "priceUsd",
        name: "Price: Low to High",
        dir: "asc",
        protected: true,
      },
      {
        id: "priceUsd",
        name: "Price: High to Low",
        dir: "desc",
        protected: true,
      },
      {
        id: "itemNumber",
        name: "SKU Number",
        dir: "desc",
      },
    ];

    return canFilterByPrice ? options : options.filter((x) => !x.protected);
  }, [canFilterByPrice]);

  return {
    sortOptions,
    handleSortBy,
    isActive,
  };
};

export const useNewArrivalsProductSorting = (
  location,
  history,
  match,
  auth
) => {
  const { isAuth, validatingUser } = auth;
  const sortParam = getParameterByName("sort") || "arrivalDate,desc,itemNumber,desc";

  const handleSortBy = useCallback(
    (option) => {
      const search = updateURLParameter(
        location.search,
        "sort",
        `${option.id},${option.dir}`
      );
      if (option.id ==='itemNumber')
        history.push({
          pathname: match.url,
          search: `${search}`,
        });
      else
      {
        history.push({
          pathname: match.url,
          search: `${search},itemNumber,desc`,
        });
      }
    },
    [history, location.search, match.url]
  );

  const isActive = useCallback(
    (opt) => {
      const { id, dir } = opt;
      if (id ==='itemNumber')
      return `${id},${dir}` === sortParam;
      return `${id},${dir},itemNumber,desc` === sortParam;
    },
    [sortParam]
  );

  const canFilterByPrice = useMemo(() => {
    return isAuth && !getRoles().includes(userRoles.WEB_USER);
  }, [isAuth]);

  useEffect(() => {
    if (!validatingUser && !isAuth && sortParam.split(",")[0] === "priceUsd") {
      const search = updateURLParameter(location.search, "sort", "");
      history.push({
        pathname: match.url,
        search: search,
      });
    }
  }, [history, isAuth, location.search, match.url, sortParam, validatingUser]);

  const sortOptions = useMemo(() => {
    const options = [
      {
        id: "popularityScore",
        name: "Popularity",
        dir: "desc",
      },
      {
        id: "arrivalDate",
        name: "Recently Added",
        dir: "desc",
      },
      {
        id: "priceUsd",
        name: "Price: Low to High",
        dir: "asc",
        protected: true,
      },
      {
        id: "priceUsd",
        name: "Price: High to Low",
        dir: "desc",
        protected: true,
      },
      {
        id: "itemNumber",
        name: "SKU Number",
        dir: "desc",
      },
    ];

    return canFilterByPrice ? options : options.filter((x) => !x.protected);
  }, [canFilterByPrice]);

  return {
    sortOptions,
    handleSortBy,
    isActive,
  };
};

export const useCategoryName = (navigationMenuContext, categoryId) => {
  const categoriesMap = createMap(navigationMenuContext.getAll.result);
  return useMemo(() => {
    return categoriesMap.get(parseInt(categoryId))?.name;
  }, [categoriesMap, categoryId]);
};

export const useCatalogTabTitle = (
  categoryName,
  attributes,
  allFilters,
  term
) => {
  const activeAttributeNames = useMemo(() => {
    const names = attributes.map((attr) => {
      const filter = allFilters.find((filter) => {
        return filter?.options.find((opt) => opt.id === parseInt(attr));
      });
      const option = filter?.options.find((x) => x.id === parseInt(attr));
      return option?.value;
    });
    return [term, ...names]
      .filter((x) => !!x)
      .splice(0, 2)
      .toString()
      .replaceAll(",", " - ");
  }, [allFilters, attributes, term]);

  return useMemo(() => {
    if (!categoryName) return "";
    return (
      (activeAttributeNames ? `${activeAttributeNames} - ` : "") + categoryName
    );
  }, [activeAttributeNames, categoryName]);
};
