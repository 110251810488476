import {Box, Button, Container, Grid, Typography} from '@material-ui/core'
import {Link} from 'react-router-dom'

const ThankYouPage = (props) => {

  return (
      <Container>
        <Grid item xs={12} md={7}>
          <Box mt={6} />
          <Typography variant="h1" style={{ fontWeight:300, fontFamily:'Chronicle', fontSize: 48 }}>
            Thank you for your application!
          </Typography>
          <Box pt={3} style={{fontFamily:'Chronicle', fontSize: 18}}>

            <p>We have received your application and will get back to you shortly.</p>

            <p>If you have any questions, please feel free to email <Link to={'mailto:newaccounts@fsco.com'}>newaccounts@fsco.com</Link>.</p>

            <p>Till then, your discovery journey can start now.</p>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box mt={6} />
          <Button fullWidth variant='contained' color='secondary' href={'/new-arrivals'}>SEE WHAT’S NEW</Button>
        </Grid>

      </Container>
  );
};

export default ThankYouPage;
