import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton } from "@material-ui/core";
import Text from "../../components/Text/Text";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import SearchBox from "../../components/Searchbox/SearchBox";
import {useCallback, useEffect, useMemo} from "react";
import { getParameterByName, updateURLParameter } from "../../utils";
import { ApiEndpoint } from "../../store/@core/endpoint";
import { EntityType } from "../../store/@core/entityType";

const useStyles = makeStyles({
  root: {},
  expandButton: {
    width: 40,
    height: 40,
    border: "1px solid #1B1C1D",
  },
});
const productsUrl = ApiEndpoint[EntityType.Product] + "/suggestions";

function CatalogHeader({
  categoryName,
  categoryId,
  showCollections,
  setShowCollections,
  history,
  location,
  match,
  setSearchInput,
}) {
  const termQuery = getParameterByName("term");
  const attributeId = getParameterByName("attributeId");

  const featuredPlacementId = useMemo(
      () => parseInt(getParameterByName("featuredPlacementId", location.search)),
      [location.search]
  );
  const searchUrl = useMemo(() => {
    const param1 = attributeId || ""
    const param2 = categoryId || ""
    const param3 = featuredPlacementId || ""
    return `${productsUrl}?attributeId=${param1}&categoryId=${param2}&featuredPlacementId=${param3}&term=`;
  }, [attributeId, categoryId, featuredPlacementId]);

  const handleSearchSubmit = useCallback((query) => {
      const search = updateURLParameter(location.search, "term", query?.name || query);
      history.replace({ pathname: match.url, search: search });
    },
    [history, location.search, match.url]
  );

  const classes = useStyles();

  const excludedCategories = ['8' ,'9' ,'7','11','12'] //TODO: To be changed
  const isExcluded = excludedCategories.findIndex((cat)=>cat === categoryId) !== -1

  useEffect(()=>{
      setShowCollections(!isExcluded)
    //eslint-disable-next-line
  },[isExcluded])

  useEffect(() => {
    if (termQuery) setShowCollections(false);
  }, [termQuery, setShowCollections]);

  return (
    <Grid container spacing={1} alignItems={"center"}>
      <Grid item>
        <Text
          variant="h4"
          style={{ textTransform: "capitalize", fontSize: 56 }}
        >
          {categoryName}
        </Text>
      </Grid>
      {!isExcluded ?
          <Grid item>
            <IconButton
                className={classes.expandButton}
                onClick={() => setShowCollections(!showCollections)}
            >
              {showCollections ? <ExpandLess/> : <ExpandMore/>}
            </IconButton>
          </Grid>
          :<></>
      }
      <Grid item style={{ maxWidth: 480, flex: 1, marginLeft: "auto" }} key={categoryId}>
        <SearchBox
          url={searchUrl}
          onSubmit={handleSearchSubmit}
          autoSubmit
          autoSubmitDelay={500}
          valueProp={"itemNumber"}
          nameProp={"name"}
          placeholder={`Search ${categoryName}`}
          initialQuery={termQuery}
          onFocus={() => setShowCollections(false)}
          hasText={setSearchInput}
        />
      </Grid>
    </Grid>
  );
}

export default CatalogHeader;
