import "./App.css";
import {CssBaseline, MuiThemeProvider} from "@material-ui/core";
import {BrowserRouter, Switch} from "react-router-dom";
import {useAppTheme} from "./constants/theme";
import {HOME_PAGE} from "./config";
import AppRoute from "./routes/route";
import NotFound from "./pages/NotFound";
import {authProtectedRoutes, publicRoutes} from "./routes";
import {connect} from "react-redux";
import {useEffect} from "react";
import DefaultLayout from "./layouts/DefaultLayout";
import {SnackbarProvider} from "notistack";
import {validateUser,} from "./store/auth/account/actions";
import {getOpenCarts, setMonitoring, setSpoofingId,} from "./store/cart/actions";
import {useCartListener, useWebSocket} from "./hooks/websocket";
import {SpoofingWrapper} from "./components/SpoofingWrapper";
import {getSpoofingUserId, initSpoofingId} from "./store/cart/api";
import {getAllCountries} from "./store/country/actions";
import CartDrawer from "./components/CartDrawer";
import {getAllNavigationMenus} from "./store/navigationMenu/actions";
import {useIsAuth, useProfileIds} from "./hooks/auth";

function App(props) {
  const theme = useAppTheme();

  const {
    getAllNavigationMenus,
    validateUser,
    getOpenCarts,
    setSpoofingId,
    setMonitoring,
    getAllCountries,
  } = props;
  const isAuth = useIsAuth()
  const { spoofingUserId } = useProfileIds()

  const ws = useWebSocket(isAuth);

  useCartListener(ws, spoofingUserId, setMonitoring);

  useEffect(() => {
    validateUser();
    getAllNavigationMenus();
    initSpoofingId();
  }, [getAllNavigationMenus, validateUser]);

  useEffect(() => {
    const spoofId = getSpoofingUserId();
    if (spoofId && isAuth) {
      setSpoofingId(spoofId);
    }
  }, [isAuth, setSpoofingId, ws.clientRef]);

  useEffect(() => {
    if (isAuth) {
      getOpenCarts();
    }
  }, [isAuth, getOpenCarts, spoofingUserId]);

  // TODO: examine this
  useEffect(() => {
    if (isAuth) {
      getAllCountries();
    }
  }, [isAuth, getAllCountries]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={3}
        preventDuplicate
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <SpoofingWrapper
          active={isAuth && spoofingUserId}
          exitSpoofing={() => setSpoofingId(null)}
        >
          <BrowserRouter basename={HOME_PAGE}>
            <CartDrawer />
            <Switch>
              {publicRoutes.map((route, idx) => (
                <AppRoute {...route} key={idx} />
              ))}
              {authProtectedRoutes.map((route, idx) => (
                <AppRoute {...route} key={idx} isAuthProtected />
              ))}
              <AppRoute component={NotFound} layout={DefaultLayout} />
            </Switch>
          </BrowserRouter>
        </SpoofingWrapper>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}

export default connect(null, {
  getAllNavigationMenus,
  getOpenCarts,
  setSpoofingId,
  validateUser,
  setMonitoring,
  getAllCountries,
})(App);
