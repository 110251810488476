import PropTypes from "prop-types";
import CheckoutCard from "../CheckoutCard/CheckoutCard";
import Text from "../../Text/Text";
import { Box, Grid, IconButton, Menu, MenuItem } from "@material-ui/core";
import { EditOutlined, MoreVert } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { combineStrings } from "../../../utils";
import { useState } from "react";

const useStyles = makeStyles({
  text: {
    marginTop: 2,
    marginBottom: 2,
    maxWidth: "100%",
  },
  default: {
    color: "#FE7A00",
    fontSize: 16,
    textTransform: "uppercase",
  },
});

function formatCard(card) {
  return card?.substring(card.length - 4, card.length);
}

const PaymentMethodCard = (props) => {
  const classes = useStyles();
  const {
    onClick,
    checked,
    disabled,
    paymentMethod,
    onEdit,
    onDelete,
    address,
  } = props;
  const { holderName, maskedCardNumber, isDefault } = paymentMethod || {};

  const { addressLine1, city, state, country } = address || {};

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <CheckoutCard
      checked={checked}
      disabled={disabled}
      onClick={() => onClick && onClick(paymentMethod)}
    >
      <Grid container>
        <Grid item xs={7} container direction={"column"}>
          <Text className={classes.text} bold>
            {holderName}
          </Text>
          <Text bold className={classes.text}>
            Card ending in {formatCard(maskedCardNumber)}
          </Text>
          <Text noWrap className={classes.text}>
            {combineStrings(
              ": ",
              "Billing Address",
              combineStrings(", ", addressLine1, city, state, country)
            )}
          </Text>
        </Grid>
        <Grid
          item
          xs={5}
          container
          direction={"row"}
          wrap={"nowrap"}
          alignItems={"center"}
          justify={"flex-end"}
        >
          <Grid item container direction={"column"} alignItems={"flex-end"}>
            {isDefault && (
              <Grid item>
                <Text bold className={classes.default}>
                  Default
                </Text>
              </Grid>
            )}
          </Grid>
          <Box mx={2} />
          <Grid item>
            <Grid item>
              {onEdit && onDelete ? (
                <IconButton size={"small"} onClick={handleClick}>
                  <MoreVert />
                </IconButton>
              ) : (
                <IconButton
                  size={"small"}
                  disabled={!address}
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit && onEdit(paymentMethod, address);
                  }}
                >
                  <EditOutlined />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {address && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              onEdit(paymentMethod, address);
              handleClose();
            }}
          >
            Edit Billing
          </MenuItem>
        )}
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            onDelete(paymentMethod);
            handleClose();
          }}
        >
          Delete Card
        </MenuItem>
      </Menu>
    </CheckoutCard>
  );
};

PaymentMethodCard.propTypes = {
  checked: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  address: PropTypes.object,
};

export default PaymentMethodCard;
