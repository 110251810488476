import React from "react";
import SectionTitle from "./SectionTitle";

function GreetingText({greeting = "Hi,", user, text,secondLine=null}) {
  return (
    <SectionTitle
      title={
        <>
          <b>{greeting} {user?.firstName}!</b> {text}
            {secondLine && <p>{secondLine}</p>}
        </>
      }
    />
  );
}

export default GreetingText;
