import AccountProfileLayout from "../components/AccountProfileLayout";

const Projects = (props) =>{
    return <AccountProfileLayout
        title={'Projects'}
        {...props}>
        Projects
    </AccountProfileLayout>
}

export default Projects