import {
    Collapse,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core'
import Text from "../../../../components/Text/Text";
import MemoIcon from '../../../../assets/icons/memo.png'
import ProductIcon from '../../../../assets/icons/product.png'
import React, {useEffect, useState} from "react";
import { InfoOutlined, KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import {Skeleton} from "@material-ui/lab";
import {capitalizeFirstLetter} from "../../../../utils";
import Button from "../../../../components/Button/Button";

const OrderSendersDetails = (props) => {
    const {data} = props
    const {createdBy, shippingAddress, orderNotes} = data

    return <>
        <Grid item container direction={'column'} spacing={1} style={{marginBottom: 16}}>
            <Grid item>
                <Text bold>ORDER BELONG TO</Text>
            </Grid>
            <Grid item>
                <Text>{createdBy}</Text>
            </Grid>
        </Grid>
        <Grid item container direction={'column'} style={{marginBottom: 16}}>
            <Grid item style={{marginBottom: 16}}>
                <Text bold>SHIP TO</Text>
            </Grid>
            <Grid item>
                <Text>{shippingAddress?.attention}</Text>
            </Grid> <Grid item>
                <Text>{shippingAddress?.addressLine1}</Text>
            </Grid>
            <Grid item>
                <Text>{shippingAddress?.addressLine2}</Text>
            </Grid>
            <Grid item>
                <Text>{`${shippingAddress?.city}, ${shippingAddress?.state} ${shippingAddress?.zipCode}`}</Text>
            </Grid>
            <Grid item>
                <Text>{`${shippingAddress?.country}`}</Text>
            </Grid>
        </Grid>
        <Grid item container direction={'column'}>
            <Grid item style={{marginBottom: 16}}>
                <Text bold>ORDER NOTES</Text>
            </Grid>
            <Grid item>
                <Text>{orderNotes?orderNotes : 'No order notes'}</Text>
            </Grid>
        </Grid>
            <Grid container item style={{borderTop:'1px solid',padding:20,minHeight:70,marginTop:10,marginBottom:-10}} alignItems={'center'}>
                <Grid item xs={5}>
                    <Text bold>PRODUCT SHIPMENT 1</Text>
                </Grid>
                <Grid item style={{marginLeft:'auto'}}>
                    <Text bold>{`$480.00`}</Text>
                </Grid>
            </Grid>
            <Grid container item style={{borderTop:'1px solid',padding:20,minHeight:70}} alignItems={'center'}>
                <Grid item xs={5}>
                    <Text bold>PRODUCT SHIPMENT 2</Text>
                </Grid>
                <Grid item style={{marginLeft:'auto'}}>
                    <Text bold>{`$1,204.54`}</Text>
                </Grid>
            </Grid>
            <Grid container item style={{borderTop:'1px solid',borderBottom:'1px solid',paddingLeft:20,paddingRight:20,paddingTop:10,paddingBottom:10,marginTop:-10,height:50}} alignItems={'center'}>
                <Grid item xs={8}>
                    <Text bold>GRAND TOTAL</Text>
                </Grid>
                <Grid item style={{marginLeft:'auto'}}>
                    <Text bold>{`$1,684.54`}</Text>
                </Grid>
            </Grid>
        <Grid container item style={{marginTop:10}} alignItems={'center'} spacing={2}>
            <Grid item xs={12}>
                <Button style={{width:'100%',height:56}} variant={'outlined'} color={'primary'}>DOWNLOAD ALL INVOICES</Button>
            </Grid>
            <Grid item xs={12}>
                <Button style={{width:'100%',height:56}} variant ={'contained'} color={'secondary'}>PAY ALL INVOICES</Button>
            </Grid>
        </Grid>
    </>
}

const ProductCard = (props) => {
    const {product} = props
    return <Grid container direction={'row'} spacing={1}>
        <Grid item>
            <img src={product.imageUrl} alt={product.name} width={'104px'} height={'104px'}/>
        </Grid>
        <Grid item container direction={'column'} xs={5} justify={'center'}>
            <Grid item><Text>{product.category}</Text></Grid>
            <Grid item><Text bold>{product.name}</Text></Grid>
            <Grid item><Text>{`${product.colorName} ${product.itemNumber}`}</Text></Grid>
        </Grid>
    </Grid>
}

const OrderProcessing = (props) => {
    const {order, totalItems} = props
    const [openDetails, setOpenDetails] = useState(false)
    return <>
        <Grid item container direction={'row'} style={{height: 72, backgroundColor: '#FCF9E8'}}
              alignContent={'center'} justify={'flex-start'} alignItems={'center'} spacing={1}>
            <Grid item>
                <IconButton style={{border: '1px solid #1B1C1D'}} size={'small'}
                            onClick={() => setOpenDetails(!openDetails)}>
                    {openDetails ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                </IconButton>
            </Grid>
            <Grid item>
                <Text style={{
                    fontFamily: "Gotham",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "125%",
                    letterSpacing: "0.01em",
                    color: "#1B1C1D"
                }}>STATUS: IN PROCESS</Text>
            </Grid>
            <Grid item style={{marginLeft: 'auto'}}>
                <Text style={{
                    fontFamily: "Gotham",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "125%",
                    letterSpacing: "0.01em",
                    color: "#1B1C1D",
                }}>{`${order.length} OUT OF ${totalItems} ${order.length > 1 ? 'ITEMS' : 'ITEM'} `}</Text>
            </Grid>
        </Grid>
        <Collapse in={openDetails}>
            <Grid container direction={'column'} style={{padding: 35}} spacing={2}>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>Item</Text></TableCell>
                            <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>Item notes</Text></TableCell>
                            <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>qty</Text></TableCell>
                            <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>item total</Text></TableCell>
                            <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>type</Text></TableCell>
                            <TableCell/>
                            <TableCell/>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {order.map((order) => {
                            return <OrderRow data={order} colSpan={5} showType={true}/>
                        })}
                    </TableBody>
                </Table>
            </Grid>
        </Collapse>
    </>
}


const OrderShipped = (props) => {
    const {order, totalItems} = props
    const [openDetails, setOpenDetails] = useState(false)
    const orderTypes = order.reduce((acc, item) => {
        if (!acc[item.type]) {
            acc[item.type] = [];
        }

        acc[item.type].push(item);
        return acc;
    }, {})
    const memoShippingOrders = orderTypes?.SAMPLE?.reduce((acc, item) => {
        if (!acc[item.shippingId]) {
            acc[item.shippingId] = [];
        }

        acc[item.shippingId].push(item);
        return acc;
    }, {})

    const productShippingOrders = orderTypes?.PRODUCT?.reduce((acc, item) => {
        if (!acc[item.shippingId]) {
            acc[item.shippingId] = [];
        }

        acc[item.shippingId].push(item);
        return acc;
    }, {})
    return <>
        <Grid item container direction={'row'} style={{height: 72, backgroundColor: '#FCF9E8'}}
              alignContent={'center'} justify={'flex-start'} alignItems={'center'} spacing={1}>
            <Grid item>
                <IconButton style={{border: '1px solid #1B1C1D'}} size={'small'}
                            onClick={() => setOpenDetails(!openDetails)}>
                    {openDetails ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                </IconButton>
            </Grid>
            <Grid item>
                <Text style={{
                    fontFamily: "Gotham",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "125%",
                    letterSpacing: "0.01em",
                    color: "#1B1C1D"
                }} uppercase>STATUS: SHIPPED</Text>
            </Grid>
            <Grid item style={{marginLeft: 'auto'}}>
                <Text style={{
                    fontFamily: "Gotham",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "125%",
                    letterSpacing: "0.01em",
                    color: "#1B1C1D",
                }}>{`${order.length} OUT OF ${totalItems} ${order.length > 1 ? 'ITEMS' : 'ITEM'} `}</Text>
                <Text style={{
                    textAlign: 'right',
                    fontFamily: "Gotham",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "125%",
                    letterSpacing: "0.01em",
                    color: "#1B1C1D",
                }}>{`${(memoShippingOrders) ? `${Object?.values(memoShippingOrders)?.length} SHIPMENTS` : `1 SHIPMENT`} `}</Text>
            </Grid>
        </Grid>
        <Collapse in={openDetails}>
            <Grid container direction={'column'} style={{padding: 35}} spacing={2}>
                {memoShippingOrders && Object.values(memoShippingOrders)?.map((list,index)=> {
                    return <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{whiteSpace: 'nowrap'}}>
                                    <Grid container alignItems={'center'} alignContent={'center'} wrap={'nowrap'}>
                                        <Grid item>
                                            <img src={MemoIcon}
                                                 alt={'SAMPLE'} width={'24px'} height={'24px'}/>
                                        </Grid>
                                        <Grid item><Text bold uppercase>Memo Shipment {index+1}</Text>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>Item
                                    notes</Text></TableCell>
                                <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>qty</Text></TableCell>
                                <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>item
                                    total</Text></TableCell>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(list)?.map((order) => {
                                return <OrderRow key={order.id} data={order} colSpan={6} showType={false}/>
                            })}
                            <TrackPackageButton colSpan={10}/>
                        </TableBody>
                    </Table>
                })}
                {productShippingOrders && Object.values(productShippingOrders)?.map((list,index)=> {
                    return <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{whiteSpace: 'nowrap'}}>
                                    <Grid container alignItems={'center'} alignContent={'center'} wrap={'nowrap'}>
                                        <Grid item>
                                            <img src={ProductIcon}
                                                 alt={'Product'} width={'24px'} height={'24px'}/>
                                        </Grid>
                                        <Grid item><Text bold uppercase>Product Shipment {index+1}</Text>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>Item
                                    notes</Text></TableCell>
                                <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>qty</Text></TableCell>
                                <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>item
                                    total</Text></TableCell>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(list)?.map((order) => {
                                return <OrderRow key={order.id} data={order} colSpan={6} showType={false}/>
                            })}
                            <TrackPackageButton colSpan={10}/>
                        </TableBody>
                    </Table>
                })}
            </Grid>
        </Collapse>
    </>
}



const OrderDelivered = (props) => {
    const {order, totalItems} = props
    const [openDetails, setOpenDetails] = useState(false)
    const orderTypes = order.reduce((acc, item) => {
        if (!acc[item.type]) {
            acc[item.type] = [];
        }

        acc[item.type].push(item);
        return acc;
    }, {})
    const memoShippingOrders = orderTypes?.SAMPLE?.reduce((acc, item) => {
        if (!acc[item.shippingId]) {
            acc[item.shippingId] = [];
        }

        acc[item.shippingId].push(item);
        return acc;
    }, {})

    const productShippingOrders = orderTypes?.PRODUCT?.reduce((acc, item) => {
        if (!acc[item.shippingId]) {
            acc[item.shippingId] = [];
        }

        acc[item.shippingId].push(item);
        return acc;
    }, {})
    return <>
        <Grid item container direction={'row'} style={{height: 72, backgroundColor: '#FCF9E8'}}
              alignContent={'center'} justify={'flex-start'} alignItems={'center'} spacing={1}>
            <Grid item>
                <IconButton style={{border: '1px solid #1B1C1D'}} size={'small'}
                            onClick={() => setOpenDetails(!openDetails)}>
                    {openDetails ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                </IconButton>
            </Grid>
            <Grid item>
                <Text style={{
                    fontFamily: "Gotham",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "125%",
                    letterSpacing: "0.01em",
                    color: "#1B1C1D"
                }} uppercase>STATUS: DELIVERED</Text>
            </Grid>
            <Grid item style={{marginLeft: 'auto'}}>
                <Text style={{
                    fontFamily: "Gotham",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "125%",
                    letterSpacing: "0.01em",
                    color: "#1B1C1D",
                }}>{`${order.length} OUT OF ${totalItems} ${order.length > 1 ? 'ITEMS' : 'ITEM'} `}</Text>
                <Text style={{
                    textAlign: 'right',
                    fontFamily: "Gotham",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "125%",
                    letterSpacing: "0.01em",
                    color: "#1B1C1D",
                }}>{`${(memoShippingOrders) ? `${Object?.values(memoShippingOrders)?.length} SHIPMENTS` : `1 SHIPMENT`} `}</Text>
            </Grid>
        </Grid>
        <Collapse in={openDetails}>
            <Grid container direction={'column'} style={{padding: 35}} spacing={2}>
                {memoShippingOrders && Object.values(memoShippingOrders)?.map((list,index)=> {
                    return <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{whiteSpace: 'nowrap'}}>
                                    <Grid container alignItems={'center'} alignContent={'center'} wrap={'nowrap'}>
                                        <Grid item>
                                            <img src={MemoIcon}
                                                 alt={'SAMPLE'} width={'24px'} height={'24px'}/>
                                        </Grid>
                                        <Grid item><Text bold uppercase>Memo Shipment {index+1}</Text>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>Item
                                    notes</Text></TableCell>
                                <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>qty</Text></TableCell>
                                <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>item
                                    total</Text></TableCell>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(list)?.map((order) => {
                                return <OrderRow key={order.id} data={order} colSpan={8} showOrder={true} showType={false}/>
                            })}
                            <OrderProductButton colSpan={10}/>
                        </TableBody>
                    </Table>
                })}
                {productShippingOrders && Object.values(productShippingOrders)?.map((list,index)=> {
                    return <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{whiteSpace: 'nowrap'}}>
                                    <Grid container alignItems={'center'} alignContent={'center'} wrap={'nowrap'}>
                                        <Grid item>
                                            <img src={ProductIcon}
                                                 alt={'Product'} width={'24px'} height={'24px'}/>
                                        </Grid>
                                        <Grid item><Text bold uppercase>Product Shipment {index+1}</Text>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>Item
                                    notes</Text></TableCell>
                                <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>qty</Text></TableCell>
                                <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>item
                                    total</Text></TableCell>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(list)?.map((order) => {
                                return <OrderRow key={order.id} data={order} colSpan={6} showType={false}/>
                            })}
                            <DownloadInvoiceButton colSpan={10}/>
                        </TableBody>
                    </Table>
                })}
            </Grid>
        </Collapse>
    </>
}


const OrdersBackordered = (props) => {
    const {order, totalItems} = props
    const [openDetails, setOpenDetails] = useState(false)
    const orderTypes = order.reduce((acc, item) => {
        if (!acc[item.type]) {
            acc[item.type] = [];
        }

        acc[item.type].push(item);
        return acc;
    }, {})
    const memoShippingOrders = orderTypes?.SAMPLE?.reduce((acc, item) => {
        if (!acc[item.shippingId]) {
            acc[item.shippingId] = [];
        }

        acc[item.shippingId].push(item);
        return acc;
    }, {})

    const productShippingOrders = orderTypes?.PRODUCT?.reduce((acc, item) => {
        if (!acc[item.shippingId]) {
            acc[item.shippingId] = [];
        }

        acc[item.shippingId].push(item);
        return acc;
    }, {})
    return <>
        <Grid item container direction={'row'} style={{height: 72, backgroundColor: '#FCF9E8'}}
              alignContent={'center'} justify={'flex-start'} alignItems={'center'} spacing={1}>
            <Grid item>
                <IconButton style={{border: '1px solid #1B1C1D'}} size={'small'}
                            onClick={() => setOpenDetails(!openDetails)}>
                    {openDetails ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                </IconButton>
            </Grid>
            <Grid item>
                <Text style={{
                    fontFamily: "Gotham",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "125%",
                    letterSpacing: "0.01em",
                    color: "#1B1C1D"
                }} uppercase>STATUS: BACKORDERED</Text>
            </Grid>
            <Grid item style={{marginLeft: 'auto'}}>
                <Text style={{
                    fontFamily: "Gotham",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "125%",
                    letterSpacing: "0.01em",
                    color: "#1B1C1D",
                }}>{`${order.length} OUT OF ${totalItems} ${order.length > 1 ? 'ITEMS' : 'ITEM'} `}</Text>
            </Grid>
        </Grid>
        <Collapse in={openDetails}>
            <Grid container direction={'column'} style={{padding: 35}} spacing={2}>
                {memoShippingOrders && Object.values(memoShippingOrders)?.map((list,index)=> {
                    return <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{whiteSpace: 'nowrap'}}>
                                    <Grid container alignItems={'center'} alignContent={'center'} wrap={'nowrap'}>
                                        <Grid item><Text bold uppercase>ITEM</Text>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>Item
                                    notes</Text></TableCell>
                                <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>qty</Text></TableCell>
                                <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>item
                                    total</Text></TableCell>
                                <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>type</Text></TableCell>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(list)?.map((order) => {
                                return <OrderRow key={order.id} data={order} colSpan={6} showType={false}/>
                            })}
                            <ContactSalesButton colSpan={10}/>
                        </TableBody>
                    </Table>
                })}
                {productShippingOrders && Object.values(productShippingOrders)?.map((list,index)=> {
                    return <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{whiteSpace: 'nowrap'}}>
                                    <Grid container alignItems={'center'} alignContent={'center'} wrap={'nowrap'}>
                                        <Grid item><Text bold uppercase>ITEM</Text>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>Item
                                    notes</Text></TableCell>
                                <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>qty</Text></TableCell>
                                <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>item
                                    total</Text></TableCell>
                                <TableCell style={{whiteSpace: 'nowrap'}}><Text bold uppercase>type</Text></TableCell>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(list)?.map((order) => {
                                return <OrderRow key={order.id} data={order} colSpan={6} showType={true}/>
                            })}
                            <ContactSalesButton colSpan={10}/>
                        </TableBody>
                    </Table>
                })}
            </Grid>
        </Collapse>
    </>
}

const OrderRow = (props) => {
    const {data, showType = false,colSpan=0,showOrder=false} = props
    return <TableRow key={data.id}>
        <TableCell>
            <ProductCard product={data}/>
        </TableCell>
        <TableCell>
            <Text>{data.notes}</Text>
        </TableCell>
        <TableCell>
            <Text style={{textAlign:'center'}}>{data.quantity}</Text>
        </TableCell>
        <TableCell>
            <Text bold>{`$ ${(data.total).toFixed(2)}`}</Text>
            {data.type === 'PRODUCT' &&
            <Grid container alignItems={'center'} direction={'row'} wrap={'nowrap'} alignContent={'center'} justify={'flex-start'} spacing={1}>
                <Grid item>
                    <InfoOutlined/>
                </Grid>
                <Grid item xs={10}>
                    <Text>{`$ ${(data.pricePerUnit).toFixed(2)} price per ${capitalizeFirstLetter(data.unit.toLowerCase())}`}  </Text>
                </Grid>
            </Grid>
            }

        </TableCell>

        <TableCell colSpan={colSpan}>
            {showType &&
            <Grid container alignItems={'center'} alignContent={'center'} wrap={'nowrap'}>
                <Grid item>
                    <img src={data.type === 'SAMPLE' ? MemoIcon : ProductIcon}
                         alt={data.type === 'SAMPLE' ? 'Memo' : 'Product'} width={'24px'} height={'24px'}/>
                </Grid>
                <Grid item><Text bold>{data.type === 'SAMPLE' ? 'Memo' : 'Product'}</Text>
                </Grid>
            </Grid>
            }
            {showOrder &&
            <Grid container alignItems={'center'} alignContent={'center'} wrap={'nowrap'}>
                <Grid item>
                    <Button color={'primary'} variant={'outlined'} size={'small'} style={{width:'100%'}}>Order Product</Button>
                </Grid>
            </Grid>
            }
        </TableCell>
    </TableRow>
}

const TrackPackageButton = (props) =>{
    const {colSpan=6} = props
    return <TableCell colSpan={colSpan} align={'right'} style={{border:'none'}}>
        <Button style={{height:56,width:220}} color={'secondary'} variant={'contained'}>Track Package</Button>
    </TableCell>

}

const OrderProductButton = (props) =>{
    const {colSpan=6} = props
    return <TableCell colSpan={colSpan} align={'right'} style={{border:'none'}}>
        <Button style={{height:56,width:220}} color={'primary'} variant={'outlined'}>Order Product</Button>
    </TableCell>
}

const DownloadInvoiceButton = (props) =>{
    const {colSpan=6} = props
    return <TableCell colSpan={colSpan} align={'right'} style={{border:'none'}}>
        <Button style={{height:56,width:220}} color={'primary'} variant={'outlined'}>Download Invoice</Button>
    </TableCell>
}

const ContactSalesButton = (props) =>{
    const {colSpan=6} = props
    return <TableCell colSpan={colSpan} align={'right'} style={{border:'none'}}>
        <Button style={{height:56,width:220}} color={'primary'} variant={'outlined'}>Contact sales</Button>
    </TableCell>
}

const OrderDetailsForm = (props) => {
    const {data, loading = true} = props
    const [groupedOrders, setGroupedOrders] = useState([])

    useEffect(() => {
        if (data.items) {
            const group = data.items.reduce((acc, item) => {
                if (item.status === "In Process")
                {
                    if (!acc['Processing']) {
                        acc['Processing'] = [];
                    }

                    acc['Processing'].push(item);
                }
                else {
                    if (!acc[item.status]) {
                        acc[item.status] = [];
                    }

                    acc[item.status].push(item);
                }
                return acc;
            }, {})
            setGroupedOrders(group)
        }

    }, [data])

    if (loading)
        return <Grid container style={{marginTop: 32, marginBottom: 32}} spacing={2}>
            <Grid item container spacing={1} direction={'column'} xs={3}>
                <Grid item>
                    <Skeleton variant={'rect'} width={'60%'} height={'20px'}/>
                </Grid>
                <Grid item>
                    <Skeleton variant={'rect'} width={'45%'} height={'20px'}/>
                </Grid>
                <Grid item>
                    <Skeleton variant={'rect'} width={'35%'} height={'20px'}/>
                </Grid>
                <Grid item>
                    <Skeleton variant={'rect'} width={'60%'} height={'20px'}/>
                </Grid>
                <Grid item>
                    <Skeleton variant={'rect'} width={'45%'} height={'20px'}/>
                </Grid>
                <Grid item>
                    <Skeleton variant={'rect'} width={'60%'} height={'20px'}/>
                </Grid>
            </Grid>
            <Grid item container spacing={1} direction={'column'} xs={9}>
                <Skeleton variant={'rect'} width={'100%'} height={'80px'} style={{marginBottom: 20}}/>
                <Skeleton variant={'rect'} width={'100%'} height={'80px'} style={{marginBottom: 20}}/>
                <Skeleton variant={'rect'} width={'100%'} height={'80px'}/>
            </Grid>
        </Grid>

    return <Grid container style={{marginTop: 32, marginBottom: 32}} spacing={2}>
        <Grid item container direction={'column'} xs={3}>
            <OrderSendersDetails data={data}/>
        </Grid>
        <Grid item container spacing={2} direction={'column'} xs={9}>
            {groupedOrders.hasOwnProperty('Processing') &&
            <Grid item>
                <OrderProcessing order={groupedOrders.Processing} totalItems={data.items.length}/>
            </Grid>
            }
            {groupedOrders.hasOwnProperty('Shipped') &&
            <Grid item wrap={'nowrap'}>
                <OrderShipped order={groupedOrders.Shipped} totalItems={data.items.length}/>
            </Grid>}
            {groupedOrders.hasOwnProperty('Delivered') &&
            <Grid item wrap={'nowrap'}>
                <OrderDelivered order={groupedOrders.Delivered} totalItems={data.items.length}/>
            </Grid>}
            {groupedOrders.hasOwnProperty('Delivered') &&
            <Grid item wrap={'nowrap'}>
                <OrdersBackordered order={groupedOrders.Delivered} totalItems={data.items.length}/>
            </Grid>}
        </Grid>
    </Grid>
}

export default OrderDetailsForm