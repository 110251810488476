import PropTypes from "prop-types";
import {Typography} from "@material-ui/core";

function getComponent(variant) {
  switch (variant) {
    case "body1":
    case "body2":
      return "p";
    default:
      return variant;
  }
}

const Text = (props) => {
  const { children, className, bold, uppercase, ...rest } = props;

  const styles = {
    ...rest.style,
  };
  if (bold) {
    styles.fontWeight = "bold";
  }
  if (uppercase) {
    styles.textTransform = "uppercase"
  }

  return (
    <Typography
      className={className}
      {...rest}
      style={styles}
      component={getComponent(rest.variant)}
    >
      {children}
    </Typography>
  );
};

Text.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  uppercase: PropTypes.bool,
  bold: PropTypes.bool,
  variant: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "body1", "body2", "caption", "inherit"]),
  color: PropTypes.oneOf([
    "textPrimary",
    "textSecondary",
    "primary",
    "secondary",
  ]),
};

Text.defaultProps = {
  color: "textPrimary",
  uppercase: false,
  bold: false,
};

export default Text;
