import React from "react";
import { Box, Grid } from "@material-ui/core";
import Button from "../../../components/Button/Button";

function CheckoutButtons({
  onBack,
  onNext,
  onContinue,
  backTitle = "Back",
  nextTitle = "Next",
  continueTitle = "Next",
  disabledNext,
}) {
  return (
    <Box mt={4}>
      <Grid container spacing={4} alignItems={"center"}>
        <Grid item style={{ flex: 1 }}>
          {Boolean(onBack) && (
            <Button
              color={"primary"}
              fullWidth
              variant={"outlined"}
              href={"#"}
              size={"large"}
              onClick={onBack}
            >
              {backTitle}
            </Button>
          )}
        </Grid>
        {Boolean(onContinue) && (
          <Grid item style={{ flex: 1 }}>
            <Button
              color={"primary"}
              fullWidth
              variant={"contained"}
              href={"#"}
              size={"large"}
              disabled={disabledNext}
              onClick={onContinue}
            >
              {continueTitle}
            </Button>
          </Grid>
        )}
        <Grid item style={{ flex: 1 }}>
          <Button
            fullWidth
            disabled={disabledNext}
            color={"secondary"}
            href={"#"}
            size={"large"}
            variant={"contained"}
            onClick={onNext}
          >
            {nextTitle}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CheckoutButtons;
