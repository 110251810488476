import Home from "../pages/Home/index";
import DefaultLayout from "../layouts/DefaultLayout";
import NewArrivals from "../pages/NewArrivals/NewArrivals";
import Catalog from "../pages/Catalog";
import ProductPage from "../pages/ProductPage";
import Checkout from "../pages/Checkout";
import AdminLayout from "../layouts/AdminLayout";
import Admin from "../pages/Admin/admin";
import Services from "../pages/Services";
// import Hospitality from "../pages/Hospitality";
import CollectionDetails from "../pages/Collections/CollectionProducs";
import Collections from "../pages/Collections";
import ResetPassword from "../pages/Account/ResetPassword";
import CreateTradeAccount from "../pages/Account/CreateTradeAccount";
import Confirm from "../pages/Account/Confirm";
import Verify from 'pages/Account/Verify'
import AccountManagement from 'pages/Admin/AccountManagement/AccountManagement'
import {userRoles} from "../constants/enums";
import Collaborators from "../pages/Collaborators";
import CollaboratorDetails from "../pages/Collaborators/CollaboratorDetails";
import ApplicationEditNew from "pages/Admin/Applications/ApplicationEditNew";
import DynamicPage from "../pages/DynamicPage";
import ShopByPage from "../pages/ShopByPage";
import RecentlyViewed from "../pages/RecentlyViewed";
import RecommendedProducts from "../pages/RecommendedProducts";
import AccountDetails from 'pages/Admin/Account/AccountDetails'
import AccountEdit from "pages/Admin/Account/AccountEdit";
import UsersProfile from "../pages/UsersProfile/AccountProfile";
import OrderHistory from "../pages/UsersProfile/OrderHistory";
import Wallet from "../pages/UsersProfile/Wallet";
import Projects from "../pages/UsersProfile/Projects";
import Favorites from "../pages/UsersProfile/Favorites";
import Discover from "../pages/Discover";
import SignUp from "pages/Account/SignUp/SignUp";
import TradeProgram from "pages/Account/TradeProgram";
import ThankYouPage from "pages/Account/ThankYouApplication";
import HeaderLayout from "../layouts/HeaderLayout";
import Orders from "../pages/Admin/Orders";
import RequestToJoin from 'pages/Account/RequestToJoin/RequestToJoin'
import MemberJoined from 'pages/Account/RequestToJoin/AcceptRequest'
import Faq from "../pages/StaticPages/faq";
import DecoratingGlossary from "../pages/StaticPages/decoratingGlossary";
import ContactUs from "../pages/StaticPages/ContactUs";
import OurStory from "../pages/StaticPages/OurStory";

const internalRoles = [
    userRoles.ADMIN,
    userRoles.INTERNAL_SALES_REP,
    userRoles.ADMIN_MERCHANDISE,
    userRoles.ADMIN_CREATIVE_MARKETING
]

const authProtectedRoutes = [
    { path: "/admin/account-details/view/:id", component: AccountDetails, layout: AdminLayout, exact: true, roles: internalRoles, title:'Account Details - Schumacher'},
    { path: "/admin/account-details/edit/:id", component: AccountEdit, layout: AdminLayout, exact: true, roles: internalRoles, title:'Account Details (Edit) - Schumacher'},
    { path: "/admin/account-applications/new", component: ApplicationEditNew, layout: AdminLayout, exact: true, roles: internalRoles, title:'Account Application (New)'},
    { path: "/admin/account-applications/edit/:id", component: ApplicationEditNew, layout: AdminLayout, exact: true, roles: internalRoles, title:'Account Application (Edit)'},
    { path: "/admin/account-management/:view?", component: AccountManagement, layout: AdminLayout, exact: true, roles: internalRoles, title:'Account Management'},
    { path: "/admin/orders", component: Orders, layout: AdminLayout, roles: [userRoles.ADMIN], title: "Orders" },
    { path: "/admin/:page", component: Admin, layout: AdminLayout, title:'Admin'},
    { path: "/checkout", component: Checkout, layout: HeaderLayout, title:'Checkout'},
    { path: "/account/create-trade-account", component: CreateTradeAccount, layout: DefaultLayout, title:'Create Trade Account'},
    { path: "/account/verify", component: Verify, layout: DefaultLayout, title:'Verify'},
    { path: "/products/recently-viewed", component: RecentlyViewed, layout: DefaultLayout },
    { path: "/products/more", component: RecommendedProducts, layout: DefaultLayout },
    { path: "/account/profile", component: UsersProfile, layout: DefaultLayout },
    { path: "/account/order-history", component: OrderHistory, layout: DefaultLayout },
    { path: "/account/wallet", component: Wallet, layout: DefaultLayout },
    { path: "/account/projects", component: Projects, layout: DefaultLayout },
    { path: "/account/favorites", component: Favorites, layout: DefaultLayout, isSticky: true },
    { path: "/join-trade-program/success", component: ThankYouPage, layout: DefaultLayout},
    { path: "/join-trade-program", component: TradeProgram, layout: DefaultLayout},
    { path: "/account/join-account/:id", component: RequestToJoin, layout: DefaultLayout},
    { path: "/account/accept-request", component: MemberJoined, layout: DefaultLayout}
];

const publicRoutes = [
    { path: "/", component: Home, layout: DefaultLayout, exact: true, title:'A Designer\'s Resource For Fabric, Wallpaper, and Trim', isSticky: true },
    { path: "/discover", component: Discover, layout: DefaultLayout, exact: true, title:'Discover', isSticky: true},
    { path: "/pages/:slug", component: DynamicPage, layout: DefaultLayout },
    { path: "/shop-by/:slug/:attributeId", component: ShopByPage, layout: DefaultLayout },
    { path: "/new-arrivals", component: NewArrivals, layout: DefaultLayout, title:''},
    { path: "/services", component: Services, layout: DefaultLayout, title:'Services' },
    // { path: "/hospitality", component: Hospitality, layout: DefaultLayout, title:'Hospitality'},
    { path: "/catalog/:categoryId", component: Catalog, layout: DefaultLayout, exact: true, title:''},
    { path: "/catalog/products/:itemNumber", component: ProductPage, layout: DefaultLayout, title:'Product'},
    { path: "/collections", component: Collections, layout: DefaultLayout, exact: true, title:'Collections'},
    { path: "/collections/:slug", component: CollectionDetails, layout: DefaultLayout, title:''},
    { path: "/account/reset-password", component: ResetPassword, layout: DefaultLayout, title:'Reset Password'},
    { path: "/account/confirm", component: Confirm, layout: DefaultLayout, title:'Confirm'},
    { path: "/collaborators", component: Collaborators, layout: DefaultLayout, exact: true, title:'Collaborators'},
    { path: "/collaborators/:slug", component: CollaboratorDetails, layout: DefaultLayout, title:'Collaborator'},
    { path: "/signup", component: SignUp, layout: DefaultLayout, title:'Sign Up'},
    {path:"/faq",component:Faq,layout:DefaultLayout,title:'FAQs'},
    {path:"/decorating-glossary",component:DecoratingGlossary,layout:DefaultLayout,title:'Decorating Glossary'},
    {path:"/contact-us",component:ContactUs,layout:DefaultLayout,title:'Contact Us'},
    {path:"/our-story",component:OurStory,layout:DefaultLayout,title:'Our Story'},
];

export { authProtectedRoutes, publicRoutes};
