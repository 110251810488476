import {Box, Button, Grid, Typography} from '@material-ui/core'
import {Form, Formik} from 'formik'
import FormikSelect from '../../../../components/Core/FormikFields/FormikSelect'
import {accountStatusOptions} from 'constants/enums'
import {useState} from 'react'
import FormikCheckbox from 'components/Core/FormikFields/FormikCheckbox'
import MembersDialog from 'pages/Admin/Account/MembersDialog'
import moment from 'moment'
import {FormikContext} from "components/FormikDisabler";

export const AccountOverview = ({formData, setFormData, formRef, members=[], disabled=false}) => {

  const [openReset, setOpenReset] = useState(false)

  const openResetPassword = () => {
    if (disabled) return false
    setOpenReset(true)
  }

  return <> <Formik innerRef={formRef}
                    initialValues={{...formData}}
                    enableReinitialize={true}
    // validationSchema={validationSchema}
                    onSubmit={(values) => {
                      setFormData(prevFormData => ({...prevFormData, ...values}))
                    }}
  >
    {({values, errors, setFieldValue}) => (<Form noValidate>
      <FormikContext.Provider value={{disabled}}>

      <Grid item container spacing={2}>

      <Grid item xs={12}>
        <Typography variant="h5" style={{fontWeight: 700, marginTop: 16}}>
          Account Overview
        </Typography>
      </Grid>

      <Box pt={6}/>

      <Grid item xs={12} md={6}>
        <FormikSelect
          variant={'outlined'}
          margin="none"
          fullWidth
          id={'accountStatus'}
          name={'accountStatus'}
          label={'Status'}
          options={accountStatusOptions}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Button variant='outlined' size='large' fullWidth onClick={openResetPassword} disabled={disabled}>
          RESET PASSWORD
        </Button>
      </Grid>

      <Grid item xs={12}>
        <FormikCheckbox
          label={'On Finance Hold'}
          id={'onHold'}
          name={'onHold'}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Box fontWeight={'bold'}>Opened: {moment(formData.createdAt).local().format("MM/DD/yyyy HH:mm:ss")}</Box>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box fontWeight={'bold'}>Last Modified: {moment(formData.updatedAt).local().format("MM/DD/yyyy HH:mm:ss")}</Box>
      </Grid>

      <Box pb={6} />

    </Grid>
      </FormikContext.Provider>
      </Form>)}
  </Formik>
    {openReset&&<MembersDialog open={openReset} setOpen={setOpenReset} members={members} />}
  </>
}
