import {Fab} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {ArrowUpwardOutlined} from "@material-ui/icons";
import {SmoothVerticalScrolling} from "../utils";

const useStyles = makeStyles((theme) => ({
    root: {},
    scrollFab: {
        zIndex: 999,
        position: 'fixed',
        right: 60,
        bottom: 40,
        cursor: 'pointer',
        backgroundColor: '#FFF595',
        color: '#000',
        '&:hover': {
            backgroundColor: '#FFD700'
        }
    }
}));

const ScrollBackToTop = ({appearAt = window.document.body.offsetHeight / 6, loading}) => {
    const classes = useStyles();
    const [top, setTop] = useState(0);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const fabTop = fabRef.current.offsetTop;
        const threshold = appearAt - fabTop;
        if (loading)
            setVisible(false)
        else {
            if (top > threshold && !visible) {
                setVisible(true)
            } else if (top < threshold && visible) {
                setVisible(false)
            }
        }
    }, [top, appearAt, visible,loading])

    const ticking = useRef(false)
    const fabRef = useRef()

    const throttleScroll = function (e) {
        let yPos = window.scrollY;

        if (!ticking.current) {
            window.requestAnimationFrame(function () {
                setTop(yPos);
                ticking.current = false;
            });

            ticking.current = true;
        }
    }

    useEffect(() => {
        document.addEventListener('scroll', throttleScroll);
        return () => {
            document.removeEventListener('scroll', throttleScroll)
        }
    }, [])

    return <Fab color="primary" aria-label="add" onClick={() => {
        SmoothVerticalScrolling(document.getElementById('root'),600,'top')
    }} className={classes.scrollFab}
                ref={fabRef} style={{opacity: visible ? 1 : 0}}>
        <ArrowUpwardOutlined/>
    </Fab>
}

export default ScrollBackToTop