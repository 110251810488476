import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import {Grid} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import clsx from "clsx";
import {Link} from "react-router-dom";

const useStyles = makeStyles({
  buttonText: {
    fontFamily: "Gotham",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "15px",
    lineHeight: "125%",
    textTransform: "uppercase",
    textDecoration: "none",
    // color: "black"
  },
  button: {
    cursor: "pointer",
    userSelect: "none",
    textDecoration: "none",
    color: "black"
  },
  animateIconOff: {
    animation: ".3s alternate ease infinite",
    transition: "transform 0.3s ease",
    transform: "translateX(0px)",
  },
  animateIconOn: {
    transform: "translateX(10px)",
  },
});

export function Hyperlink(props) {
  const { href, actionPath, children, ...rest } = props;
  if (href) {
    return (
        <a rel={"noreferrer"} href={href} target={"_blank"} {...rest}>
          {children}
        </a>
    );
  }
  return (
      <Link to={actionPath} {...rest}>
        {children}
      </Link>
  );
}

const ActionButton = (props) => {
  const { actionPath, href, title, size = 12, actionColor = "#1B1C1D",target="", ...rest } = props;
  const classes = useStyles();
  const [animate, setAnimate] = useState(false);

  return (
    <Grid
      container
      className={classes.button}
      style={{ color: actionColor }}
      spacing={0}
      justify={"flex-start"}
      alignContent={"center"}
      wrap={"nowrap"}
      onMouseOver={() => setAnimate(true)}
      onMouseOut={() => setAnimate(false)}
      component={Hyperlink}
      to={actionPath}
      href={href}
      target={target}
      xs={size}
      {...rest}
    >
      <Grid item className={classes.buttonText} xs={"auto"}>
        {title}
      </Grid>
      <Grid
        item
        xs={"auto"}
        className={clsx(
          classes.animateIconOff,
          animate ? classes.animateIconOn : null
        )}
        style={{ marginTop: -3, color: actionColor }}
      >
        <ArrowRightAltIcon />
      </Grid>
    </Grid>
  );
};

export default ActionButton;
