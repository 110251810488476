import { Fragment, useLayoutEffect, useRef, useState } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import Button from "../../Button/Button";
import Popover from "../../Popover/Popover";
import SignInForm from "../SignInForm";
import ForgotForm from "../ForgotForm";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { logoutUser } from "../../../store/auth/account/actions";
import clsx from "clsx";
import { Divider, ListItem, ListItemText } from "@material-ui/core";
import { getRoles } from "../../../utils";
import { userRoles } from "../../../constants/enums";
import List from "@material-ui/core/List";

const useStyles = makeStyles((theme) => ({
  topLinkButton: {
    // color: "white",
  },
  label: {
    fontSize: 10,
    textTransform: "none",
  },
}));

function AccountButton({
  userDetails,
  isAuth,
  logoutUser,
  spoofingUserDetails,
}) {
  const classes = useStyles();
  const [openSignIn, setOpenSignIn] = useState(false);
  const [openSignOut, setOpenSignOut] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { pathname } = useLocation();
  const anchorRef = useRef(null);
  const role = getRoles();
  const isTradeAccountOwner = role?.includes(userRoles.TRADE_ACCOUNT_OWNER);
  const isTradeAccountMember = role?.includes(userRoles.TRADE_ACCOUNT_MEMBER);
  const isAdmin = role?.includes(userRoles.ADMIN);
  const profile = spoofingUserDetails || userDetails;

  const [
    redirectToCreateTradeAccount,
    // setRedirectToCreateTradeAccount,
  ] = useState(false);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpenSignIn(true);
    setOpenForgot(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenSignOut(false)
  };

  const timeoutRef = useRef();
  useLayoutEffect(() => {
    const skip = localStorage.getItem("skipAutoSignIn");
    if (!isAuth && pathname === "/" && !Boolean(skip)) {
      let anchorElement = document.getElementById("signInButton");
      setAnchorEl(anchorElement);
      timeoutRef.current = setTimeout(() => setOpenSignIn(true), 5000);
      // localStorage.setItem("skipAutoSignIn", true)
    }
    return () => {
      clearInterval(timeoutRef.current);
    };
  }, [isAuth, pathname]);

  if (redirectToCreateTradeAccount) {
    return <Redirect to={`/account/create-trade-account`} />;
  }

  return (
    <Fragment>
      {!isAuth && (
        <Button
          id={"signInButton"}
          ref={anchorRef}
          onClick={handleClick}
          className={clsx(classes.topLinkButton, classes.label)}
          style={{ fontWeight: "bold", fontSize: 10 }}
        >
          SIGN IN
        </Button>
      )}
      <Popover
        open={openSignIn}
        handleClose={() => setOpenSignIn(false)}
        anchorEl={anchorEl}
      >
        <SignInForm
          setOpenSignIn={setOpenSignIn}
          setOpenForgot={setOpenForgot}
        />
      </Popover>
      <Popover
        open={openForgot}
        handleClose={() => setOpenForgot(false)}
        anchorEl={anchorEl}
      >
        <ForgotForm
          setOpenSignIn={setOpenSignIn}
          setOpenForgot={setOpenForgot}
        />
      </Popover>
      {isAuth && (
        <Button
          className={classes.topLinkButton}
          key={"signout"}
          style={{ fontWeight: "bold", fontSize: 10 }}
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            setOpenSignOut(true);
          }}
        >
          HI, {profile?.firstName?.toUpperCase()}
        </Button>
      )}
      <Popover
        open={openSignOut}
        handleClose={() => setOpenSignOut(false)}
        anchorEl={anchorEl}
      >
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          {(isTradeAccountOwner || isTradeAccountMember) && (
            <ListItem onClick={handleClose} button component={Link} to={"/account/order-history"}>
              <ListItemText primary="Order History" />
            </ListItem>
          )}
          {isAdmin && (
            <ListItem onClick={handleClose} button component={Link} to={"/admin/orders"}>
              <ListItemText primary="Manage Orders" />
            </ListItem>
          )}
          {(isAdmin || isTradeAccountOwner || isTradeAccountMember) && (
            <Divider />
          )}
          <ListItem button>
            <ListItemText
              primary="Sign Out"
              onClick={() => {
                logoutUser();
                handleClose()
              }}
            />
          </ListItem>
        </List>
      </Popover>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  const { spoofingUserDetails } = state.Cart;
  const { isAuth, userDetails } = state.Auth;
  return { isAuth, userDetails, spoofingUserDetails };
};
export default connect(mapStateToProps, { logoutUser })(AccountButton);
