import {Box, Button, Container, Grid, Typography} from '@material-ui/core'
import {connect} from "react-redux";
import {apiError} from "../../../store/auth/account/actions";
import axios from 'axios'
import {API_URL} from 'config'
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const AcceptRequest = () => {

  const [isBusy, setIsBusy] = useState(true)
  const [data, setData] = useState()
  const [error, setError] = useState(false)

  let token = useQuery().get('token')

  const acceptRequest = () => {
    const url = `${API_URL}/trade-account/join-request/approve`

    axios.post(url,{token})
      .then((response) => {
        if (response.status === 200) {
          setData(response.data)
        } else {
          setError(true)
        }
        setIsBusy(false);
      })
      .catch((err) => {
        setError(true)
        setIsBusy(false);
        throw err;
      });
  }

  useEffect(() => {
    acceptRequest()
    // eslint-disable-next-line
  }, [])

  if (isBusy) return <> </>

  if (error) return <>
      <Container style={{minHeight: '50vh'}}>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={7}>
            <Box mt={6}/>
            <Typography variant="h1" style={{fontWeight: 300, fontFamily: 'Chronicle', fontSize: 32}}>
              There was an error verifying the request to join. Please try again.
            </Typography>
          </Grid>
        </Grid>
      </Container>
  </>

  if (!data) return <Container style={{minHeight: '50vh'}}> </Container>

  return (
    <>
      <Container style={{minHeight: '50vh'}}>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={7}>
            <Box mt={6}/>
            <Typography variant="h1" style={{fontWeight: 300, fontFamily: 'Chronicle', fontSize: 32}}>
              New Member Joined
            </Typography>
            <Box pt={3} style={{fontFamily: 'Chronicle', fontSize: 18}}>

              <p>Congratulations, your team is growing! {data.memberFirstName} {data.memberLastName}, ${data.memberEmail}, is now a  member under your account.</p>

            </Box>
          </Grid>

          <Grid item container>
            <Grid item xs={4}>
              <Button size='large' variant='contained' color='secondary' fullWidth>
                MANAGE MY TEAM
              </Button>
            </Grid>
          </Grid>


        </Grid>


      </Container>
    </>
  );
};
const mapStateToProps = (state) => {
  const {error, loading, isAuth} = state.Auth;
  return {error, loading, isAuth};
};

export default connect(mapStateToProps, {apiError})(AcceptRequest);
