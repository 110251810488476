import {Container, FormControl, Grid, MenuItem, Select, TextField} from '@material-ui/core'
import {Pagination} from "@material-ui/lab";
import {useEffect, useState} from 'react'
import DataTable from "components/DataTable/DataTable";
import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/styles'
import {getAxiosConfig, getRoles, getUserId} from 'utils'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import {ADMIN_API_URL} from 'config'
import EditUserDialog from 'pages/Admin/EditUserDialog'
import {useSnackbar} from 'notistack'
import moment from 'moment'
import {connect} from "react-redux";
import {getAllFilters} from "../../../store/filter/actions";
import {loginSuccess, logoutUser, validateUser} from "../../../store/auth/account/actions";
import {getOpenCarts, setMonitoring} from "../../../store/cart/actions";
import {getAllCountries} from "../../../store/country/actions";
import {
  accountStatusOptions,
  applicationStatusOptions, businessTypesOptions,
  roleOptions,
  userRoles,
  userStatus,
  userStatusOptions
} from 'constants/enums'
import {useParams} from "react-router-dom";
import {getAllSalesTerritories} from "store/salesTerritory/actions";
import ConfirmDialog from 'pages/Admin/ConfirmDialog'

const useStyles = makeStyles({
  button: {
    textTransform: 'uppercase',
    borderRadius: 12,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    padding: 8,
    backgroundColor: '#969798',
    color: '#FFF',
  },
  searchButton: {
    marginRight: -14,
    boxShadow: 'none',
    padding: '8px 32px'
  }
});

const TabButton = ({children, active, disabled, onClick, ...props}) => {

  const useStyles = makeStyles({
    button: {
      textTransform: 'uppercase',
      borderRadius: 12,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      border: 0,
      padding: 8,
      transitionDuration: 0,
      backgroundColor: active?'#5A5B5C':'#969798',
      color: '#FFF',
      borderColor: active?'#5A5B5C':'#969798',
      '&:hover': {
        backgroundColor: active?'#5A5B5C':'#969798',
      }
    },
    searchButton: {
      marginRight: -14,
      boxShadow: 'none',
      padding: '8px 32px'
    }
  });

  const classes = useStyles();

  return <Button onClick={onClick&&!disabled?onClick:null} size={"large"} variant='contained' fullWidth  {...props} classes={{label:classes.button, root: classes.button}}>
    {children}
  </Button>
}

const Link = ({children,...props}) => {
  return <span {...props} style={{cursor:'pointer', textDecoration:'underline'}}>{children}</span>
}

const countryRegions = [
  {
    id: 'OTHER',
    name: 'Other'
  },
  {
    id: 'US',
    name: 'US'
  },
  {
    id: 'UK',
    name: 'UK'
  }
]

const AccountManagement = ({history, context}) => {

  const salesTerritories = context.SalesTerritory.getAll.result

  const {view} = useParams()

  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState({})
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const classes = useStyles();

  const [isSalesRep, setIsSalesRep] = useState(false)

  useEffect(()=>{
    const roles = getRoles();
    setIsSalesRep(roles.includes(userRoles.INTERNAL_SALES_REP))

    window.scrollTo(0,0)
  },[])



  const getSalesTerritories = () => {
    let st = {}
    if (salesTerritories) {
      salesTerritories.forEach((s)=>{
        st[s.territory] = ''
      })
    }
    return Object.keys(st)
  }

  const getAEs = () => {
    let st = {}
    if (salesTerritories) {
      salesTerritories.forEach((s)=>{
        st[s.ae] = ''
      })
    }
    return Object.keys(st)
  }

  const getCities = () => {
    return data.cities?data.cities.filter((c)=>c!==''):[]
  }

  const APPLICATIONS_MAPPING = [
    {
      label: "Status",
      key: "applicationStatus",
      transform: (status) => {
        const statusObj = applicationStatusOptions.find((r)=>r.id===status)
        return statusObj?statusObj.name:''
      },
      filters: [
        ...applicationStatusOptions.map((r)=>r.name)
      ]
    },
    {
      label: "Time",
      key: "time",
      transform: (time)=>{
        return moment(time).local().format("MM/DD/yyyy HH:mm:ss")
      }
    },
    {
      label: "Account Name",
      key: "accountName"
    },
    {
      label: "Town / City",
      key: "city",
      filters: getCities()
    },
    {
      label: "Sales Territory",
      key: "salesTerritory",
      filters: getSalesTerritories()
    },
    {
      label: "phone no.",
      key: "phone"
    },
    {
      label: "Email",
      key: "email"
    },
    {
      label: "Account Type",
      key: "accountType",
      transform: (status) => {
        const statusObj = businessTypesOptions.find((r)=>r.id===status)
        return statusObj?statusObj.name:''
      },
      filters: [
        ...businessTypesOptions.map((r)=>r.name)
      ]
    }
  ]

  const ACCOUNTS_MAPPING = [
    {
      label: "Account No.",
      key: "accountNo"
    },
    {
      label: "Account Name",
      key: "accountName"
    },
    {
      label: "Sales Territory",
      key: "salesTerritory",
      filters: getSalesTerritories()
    },
    {
      label: "AE",
      key: "ae",
      filters: getAEs()
    },
    {
      label: "Town / City",
      key: "city",
      filters: getCities()
    },
    {
      label: "Zip code",
      key: "zipCode"
    },
    {
      label: "Phone no.",
      key: "phoneNumber"
    },
    {
      label: "Status",
      key: "accountStatus",
      transform: (status) => {
        const statusObj = accountStatusOptions.find((r)=>r.id===status)
        return statusObj?statusObj.name:''
      },
      filters: [
        ...accountStatusOptions.map((r)=>r.name)
      ]
    }
  ]

  const WEB_USERS_MAPPING = [
    {
      label: "User Role",
      key: "role",
      transform: (role) => {
        const roleObj = roleOptions.find((r)=>r.id===role)
        return roleObj?roleObj.name:''
      }
    },
    {
      label: "E-mail",
      key: "email"
    },
    {
      label: "Name",
      key: "name"
    },
    {
      label: "Account Name",
      key: "accountName",
      transform: (name, row) => {
        return <a href={`/admin/account-details/view/${row.accountId}`}>{name}</a>
      }
    },
    {
      label: "Sales Territory",
      key: "salesTerritory",
      filters: getSalesTerritories()
    },
    {
      label: "AE",
      key: "ae",
      filters: getAEs()
    },
    {
      label: "Status",
      key: "userStatus",
      transform: (role) => {
        const userStatusObj = userStatusOptions.find((r)=>r.id===role)
        return userStatusObj?userStatusObj.name:''
      },
      filters: Object.values(userStatus)
    }
  ]

  const dataMapping = {
    APPLICATIONS : APPLICATIONS_MAPPING,
    ACCOUNTS: ACCOUNTS_MAPPING,
    WEB_USERS: WEB_USERS_MAPPING
  }

  const accountTypes = [
    {
      id: 'RESIDENTIAL',
      name: "Residential"
    },
    {
      id: 'HOSPITALITY',
      name: 'Hospitality / Contract'
    }
  ]

  const handleChangeAccountType = (e) => {
    const value = e.target.value
    setAccountType(value)
    localStorage.setItem(`accountManagement-filter-AccountType-${getUserId()}`,value)
  }

  const handleChangeCountry = (e) => {
    const value = e.target.value
    setCountry(value)
    localStorage.setItem(`accountManagement-filter-Country-${getUserId()}`,value)
  }

  const defaultCountry = localStorage.getItem(`accountManagement-filter-Country-${getUserId()}`)
  const defaultAccountType = localStorage.getItem(`accountManagement-filter-AccountType-${getUserId()}`)

  const [accountType, setAccountType] = useState(defaultAccountType?defaultAccountType:0)
  const [country, setCountry] = useState(defaultCountry?defaultCountry:0)
  const [term, setTerm] = useState('')
  // const [searchValue, setSearchValue] = useState(null)
  const [page, setPage] = useState(0)
  const [tab, setTab] = useState(view?view.toUpperCase():'APPLICATIONS')
  const [sorting, setSorting] = useState({
    APPLICATIONS: [
      {
        field: 'time',
        asc: false
      }],
    ACCOUNTS: [],
    WEB_USERS: []
  })

  const [filters, setFilters] = useState({
    APPLICATIONS: {
      applicationStatus: '',
      time: '',
      city: '',
      salesTerritory: '',
      accountType: '',
    },
    ACCOUNTS: {
      salesTerritory: '',
      ae: '',
      city: '',
      accountStatus: ''
    },
    WEB_USERS: {
      role: '',
      salesTerritory: '',
      ae: '',
      userStatus: ''
    }
  })

  const setSortingByType = (type) => {
    return (value)=>setSorting({...sorting, [type]: value})
  }

  const changeTab = (newTab) => {
    if (isBusy||isLoading) return false

    if (newTab===tab) return false

    history.push(`/admin/account-management/${newTab.toLowerCase()}`)

      setTableData([])
      // setData({})
      setPage(0)
      setTab(newTab)

  }

  // Reset options if selected ALL
  useEffect(()=>{
    if (country===-1) {
      setCountry(0)
    }
  },[country])

  useEffect(()=>{
    if (accountType===-1) {
      setAccountType(0)
    }
  },[accountType])

  // Actual data fetch
  const getData = (accountType, country, term, page, tab, sorting, filters) => {

    const url = `${ADMIN_API_URL}/manage/account/search`

    setIsLoading(true)

    let payLoad = {}

    if (!(accountType||country||term||page||tab||sorting)) {
      payLoad = {
        accountType: null,
        countryRegion: country?country==="-1"?'ALL':country:null,
        term: '',
        order: sorting[tab],
        page: 0,
        itemsPerPage: 10,
        tab: tab,
        city: filters&&filters.city?filters.city:null,
        userStatus: filters&&filters.userStatus?userStatusOptions.find((o)=>o.name===filters.userStatus).id:null,
        ae: filters&&filters.ae?filters.ae:null,
        salesTerritory: filters&&filters.salesTerritory?filters.salesTerritory:null,
        accountStatus: filters&&filters.accountStatus?accountStatusOptions.find((s)=>s.name===filters.accountStatus).id:null,
      }
    } else {
      // console.log('Look for ',accountType, country, term,'page ',page, tab, sorting)
      payLoad = {
        accountType: filters&&filters.accountType?businessTypesOptions.find((s)=>s.name===filters.accountType).id:null,
        countryRegion: country?country==="-1"?'ALL':country:null,
        term: term,
        order: sorting[tab],
        page: page,
        itemsPerPage: 10,
        tab: tab,
        city: filters&&filters.city?filters.city:null,
        userStatus: filters&&filters.userStatus?userStatusOptions.find((o)=>o.name===filters.userStatus).id:null,
        ae: filters&&filters.ae?filters.ae:null,
        salesTerritory: filters&&filters.salesTerritory?filters.salesTerritory:null,
        applicationStatus: filters&&filters.applicationStatus?applicationStatusOptions.find((s)=>s.name===filters.applicationStatus).id:null,
        accountStatus: filters&&filters.accountStatus?accountStatusOptions.find((s)=>s.name===filters.accountStatus).id:null,
      }
    }

    axios.post(url,payLoad,getAxiosConfig(true))
      .then((response) => {
        if (response.status === 200) {
          setData(response.data)
          setTableData(response.data.results.content)

        }
        setIsLoading(false);
      })
      .catch((err) => {
        // Silent error
        setIsLoading(false);
        console.log(err)
        throw err;
      });

  }

  // Handle dropdown changes
  useEffect(()=>{
    if (accountType===-1 || country===-1 ) return false
    getData(accountType, country, term,page, tab, sorting, filters)
    setHasInit(true)
    // eslint-disable-next-line
  },[accountType, country])

  // // Handle search term changes
  // const searchTimeout = useRef()
  const [hasInit, setHasInit] = useState(false)

  // Handle page change
  useEffect(()=>{
    if (!hasInit) return false
    if (page||page===0) {
      getData(accountType,country,term,page,tab,sorting,filters)
    }
    // eslint-disable-next-line
  },[page])

  useEffect(()=>{
    if (!hasInit) return false
    if (tab) {
      getData(accountType,country,term,page,tab,sorting,filters)
    }
    // eslint-disable-next-line
  },[tab,view])

  useEffect(()=>{
    if (!hasInit) return false
    if (sorting) {
      getData(accountType,country,term,page,tab,sorting,filters)
    }
    // eslint-disable-next-line
  },[sorting])

  useEffect(()=>{
    if (!hasInit) return false
    if (filters) {
      getData(accountType,country,term,page,tab,sorting, filters)
    }
    // eslint-disable-next-line
  },[filters])

  const doSearch = (e) => {
    e.stopPropagation()
    e.preventDefault()
    getData(accountType,country,term,page,tab,sorting)
  }

  const [isBusy, setIsBusy] = useState(false)

  const disableUser = (id) => {
    if (isSalesRep) return false
    setIsBusy(true)
    const url = `${ADMIN_API_URL}/users/status/disable/${id}`
    axios.put(url,getAxiosConfig(true))
      .then((response) => {
        if (response.status === 204) {
          const newData = [...tableData]
          const userIndex = newData.findIndex((u)=>u.id===id)
          newData[userIndex].userStatus = userStatus.DISABLED
          setTableData(newData)
        }
        setIsBusy(false);
      })
      .catch((err) => {
        // Silent error
        setIsBusy(false);
        console.log(err)
        throw err;
      });
  }

  const enableUser = (id) => {
    if (isSalesRep) return false
    setIsBusy(true)
    const url = `${ADMIN_API_URL}/users/status/activate/${id}`
    axios.put(url,getAxiosConfig(true))
      .then((response) => {
        if (response.status === 204) {
          const newData = [...tableData]
          const userIndex = newData.findIndex((u)=>u.id===id)
          newData[userIndex].userStatus = userStatus.ACTIVE
          setTableData(newData)
        }
        setIsBusy(false)
      })
      .catch((err) => {
        // Silent error
        setIsBusy(false)
        console.log(err)
        throw err;
      });
  }

  const [editingUser, setEditingUser] = useState(false)
  const [selectedUser, setSelectedUser] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState(false)
  // const [confirmDialogAction, setConfirmDialogAction] = useState(null)
  // const [confirmId, setConfirmId] = useState(null)

  const handleDisableUser = (id, email) => {
    setConfirmDialog({
      title: `Are you sure you want to deactivate user ${email}?`,
      action: disableUser,
      id
    })
  }

  const handleEnableUser = (id, email) => {
    setConfirmDialog({
      title: `Are you sure you want to activate user ${email}?`,
      action: enableUser,
      id
    })
  }

  const handleCloseConfirmDialog = () => {
    // setConfirmDialogAction(null)
    setConfirmDialog(false)
  }

  const editUser = (id) => {
    setSelectedUser(id)
  }

  useEffect(()=>{
    if (editingUser===false) {
      setSelectedUser(false)
    }
  },[editingUser])

  useEffect(()=>{
    if (selectedUser) {
      setEditingUser(true)
    }
  },[selectedUser])

  const resetPassword = (email) => {
    setIsBusy(true)
    const url = `${ADMIN_API_URL}/account/forgot-password`
    axios.post(url,{email},getAxiosConfig(true))
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar(`An email containing a password reset link was sent to the user.`, {variant:'success'});
        }
        setIsBusy(false)
      })
      .catch((err) => {
        // Silent error
        setIsBusy(false)
        console.log(err)
        enqueueSnackbar(`An error occurred while sending a password reset link via email. Please try again.`, {variant:'error'});
        throw err;
      });
  }

  const spoof = (id) => {
    if (isSalesRep) return false
    window.open(window.location.origin + "?userId=" + id)
  }

  const rowActions = {
    APPLICATIONS : null,
    WEB_USERS : ({row}) => <Grid item container spacing={2} justify='center' alignContent='center' alignItems='center' style={{opacity: isBusy?0.3:1}}>
      <Grid item style={{opacity:isSalesRep?0.3:1}}>
        {row.userStatus===userStatus.ACTIVE?
          <Link onClick={()=>handleDisableUser(row.id, row.email)}>Deactivate</Link>:
          <Link onClick={()=>handleEnableUser(row.id, row.email)}>Activate</Link>}
      </Grid>
      <Grid item>
        <Link onClick={()=>editUser(row.id)}>Edit</Link>
      </Grid>
      <Grid item>
        <Link onClick={()=>resetPassword(row.email)}>Reset Password</Link>
      </Grid>
      <Grid item style={{opacity:isSalesRep?0.3:1}}>
        <Link onClick={()=>spoof(row.id)}>Spoof</Link>
      </Grid>
    </Grid>,
    ACCOUNTS: null
  }

  const rowClick = {
    APPLICATIONS: (row) => {
      history.push(`/admin/account-applications/edit/${row.id}`)
    },
    ACCOUNTS: (row) => {
      history.push(`/admin/account-details/view/${row.accountNo}`)
    },
    WEB_USERS: false
  }

  return <Container maxWidth="xl">
    <ConfirmDialog options={confirmDialog} setOpen={setConfirmDialog} onClose={handleCloseConfirmDialog} />
    <Grid container spacing={2} style={{padding:36}}>

      <Grid item xs={12}>
        <Typography variant='h1'>
          Account Management
        </Typography>
      </Grid>

      <Grid item container spacing={2}>
        <Grid item md={2} xs={6}>

          <FormControl
            fullWidth
            variant="outlined"
          >
            <Select
              value={accountType}
              onChange={handleChangeAccountType}
            >
              <MenuItem key={0} value={0} disabled>
                  Account Type
              </MenuItem>

              <MenuItem key={-1} value={-1}>
                All
              </MenuItem>

              {accountTypes.map((option, index) => {
                return (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} xs={6}>
          <FormControl
            fullWidth
            variant="outlined"
          >
            <Select
              value={country}
              onChange={handleChangeCountry}
            >
              <MenuItem key={0} value={0} disabled>
                Country Region
              </MenuItem>
              <MenuItem key={-1} value={-1}>
                All
              </MenuItem>
              {countryRegions.map((option, index) => {
                return (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={isSalesRep?8:5} xs={isSalesRep?12:7}>

          <form noValidate onSubmit={doSearch} >
          <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              variant='outlined'
              size={'small'}
              placeholder={'Account no., name, email, & keyword'}
              value={term}
              onChange={(e)=>{setTerm(e.target.value)}}
              classes={{root:classes.searchBox}}
              InputProps={{
                endAdornment: <Button type='submit' variant='contained' classes={{root:classes.searchButton}} onClick={doSearch}> Search </Button>,
              }}
            />
          </FormControl>
        </form>

        </Grid>
        {!isSalesRep&&<Grid item md={3} xs={5}>
          <Button variant='contained' size='large' fullWidth onClick={()=>{history.push('/admin/account-applications/new')}}>CREATE NEW APPLICATION</Button>
        </Grid>}
      </Grid>

      <Grid item container spacing={1}>
        <Grid item xs={4}>
          <TabButton disabled={isLoading} active={tab==='APPLICATIONS'} onClick={()=>changeTab('APPLICATIONS')}> Applications {<>({data.applicationsTotal})</>} </TabButton>
        </Grid>
        <Grid item xs={4}>
          <TabButton disabled={isLoading} active={tab==='ACCOUNTS'}  onClick={()=>changeTab('ACCOUNTS')}> Accounts {<>({data.accountsTotal})</>} </TabButton>
        </Grid>
        <Grid item xs={4}>
          <TabButton disabled={isLoading} active={tab==='WEB_USERS'} onClick={()=>changeTab('WEB_USERS')}> Web Users {<>({data.webUsersTotal})</>} </TabButton>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{marginTop: -16}}>
        <DataTable
          mapping={dataMapping[tab]}
          data={tableData}
          order={sorting[tab]}
          setOrder={setSortingByType(tab)}
          isLoading={isLoading}
          rowAction={rowActions[tab]}
          rowClick={rowClick[tab]}
          filters={filters}
          setFilters={setFilters}
          isSticky={true}
        />
      </Grid>
      <Grid item container justify='flex-end' spacing={2}>
        <Grid item>
          <Pagination count={data&&data.results&&data.results.totalPages} page={page+1} onChange={(e,value)=>setPage(value-1)}/>
        </Grid>
      </Grid>
    </Grid>
    {editingUser&&<EditUserDialog open={editingUser} setOpen={setEditingUser} isSalesRep={isSalesRep} id={selectedUser} setTableData={setTableData} tableData={tableData} />}
  </Container>
}
const mapStateToProps = (state) => {
  const { spoofingUserId } = state.Cart;
  return {
    context: {
      Auth: state.Auth,
      Country: state.Country,
      SalesTerritory: state.SalesTerritory,
      spoofingUserId,
    },
  };
};
export default connect(mapStateToProps, {
  getAllFilters,
  loginSuccess,
  logoutUser,
  getOpenCarts,
  validateUser,
  setMonitoring,
  getAllCountries,
  getAllSalesTerritories
})(AccountManagement);
