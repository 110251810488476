import { combineReducers } from "redux";
import Cart from "./cart/reducer";
import Auth from "./auth/account/reducer";
import Filter from "./filter/reducer";
import Address from "./address/reducer";
import PaymentMethod from "./paymentMethod/reducer";
import Country from "./country/reducer";
import ShippingMethod from "./shippingMethod/reducer";
import Element from "./element/reducer";
import NavigationMenu from "./navigationMenu/reducer";
import SalesTerritory from "./salesTerritory/reducer";

// Authentication

const rootReducer = combineReducers({
    Cart,
    Auth,
    Filter,
    Address,
    PaymentMethod,
    Country,
    ShippingMethod,
    Element,
    NavigationMenu,
    SalesTerritory
});

export default rootReducer;
