import Dialog from 'components/Dialog/Dialog'
import React from 'react'

const ConfirmDialog = ({options, setOpen, onClose}) => {
  const {title, action, id} = options
  return <>{Boolean(options)&&<Dialog
      onClose={onClose}
      open={Boolean(options)}
      title={title}
      maxWidth={"sm"}
      fullWidth
      hideCloseIcon
      primaryActionTitle={"Yes"}
      primaryAction={()=>{
        action(id)
        onClose()
      }}
      secondaryAction={() => setOpen(false)}
      secondaryActionTitle={"Cancel"}
    />}</>
}

export default ConfirmDialog
