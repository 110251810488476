import {Grid, Menu, MenuItem, Typography} from "@material-ui/core";
import AccountProfileLayout from "../components/AccountProfileLayout";
import CatalogFilters from "../../../components/Core/CatalogFilters";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Text from "../../../components/Text/Text";
import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {Paging} from "reactcoregk/models";
import {useProductCart} from "../../../hooks/product";
// import {orderItemType} from "../../../constants/enums";
import {useAttributes, useCatalogFilters, useCatalogTabTitle, useProductSorting} from "../../../hooks/catalog";
import {useTitle} from "../../../hooks/router";
import {fetchData, getParameterByName, getUserId} from "../../../utils";
import {connect} from "react-redux";
import {getOpenCarts} from "../../../store/cart/actions";
import ProductCardWithHooks from "../../../components/Cards/ProductCard/ProductCartWithHooks";
import {Pagination} from "@material-ui/lab";
import {useFavorites} from "../../../hooks/favorites";
import {API_URL} from "../../../config";
import ProductCard from "../../../components/Cards/ProductCard/ProductCard";
import {catalogFilter} from "../../../constants/enums";
import {useCurrentCart} from "../../../hooks/cart";
import {openCartDrawer} from "../../../store/element/actions";
import Dialog from "../../../components/Dialog/Dialog";
import {Prompt, Redirect} from "react-router-dom";
import {createMap} from "reactcoregk/utils";

const useStyles = makeStyles((theme) => ({
  root: {},
  menuButton: {
    fontWeight: "bold",
    fontSize: 12,
  },
  bgLight: {
    backgroundColor: theme.palette.secondary.lightNav,
    paddingBottom: 8,
  },
}));

const Favorites = (props) => {
  const classes = useStyles();
  const {
    context,
    match,
    history,
    location,
    openCartDrawer,
    getOpenCarts
  } = props;

  // const [openPreview, setOpenPreview] = useState(false);
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(new Paging());
  const [products, setProducts] = useState([]);
  //eslint-disable-next-line
  const [selectedProduct, setSelectedProduct] = useState({});
  const categoryId = 'favorites';
  const [anchorEl, setAnchorEl] = useState(null);
  const userId = context.Cart.spoofingUserId || getUserId();
  const productsUrl = `${API_URL}/users/${userId}/products/favorites`;
  const term = getParameterByName("term")


  const [favoriteProduct, setFavoriteProduct] = useState({})
  useFavorites(favoriteProduct, products, setFavoriteProduct,userId)


  const rootParams = location.search.replaceAll("?", "&");
  const {
    //eslint-disable-next-line
    memosAdded,//eslint-disable-next-line
    productsAdded,//eslint-disable-next-line
    onUpdateConfirm,//eslint-disable-next-line
    addedProductItem,//eslint-disable-next-line
    addedMemoItem,//eslint-disable-next-line
    cart,
  } = useProductCart(selectedProduct);

  const handleSort = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorEl(null);
  };

  // const onConfirm = (payload) => {
  //     onUpdateConfirm(orderItemType.SAMPLE, payload);
  //     setOpenCartDialog(false);
  // };

  const {
    handleDeleteAttribute,
    handleAddAttribute,
    attributes,
  } = useAttributes(location, history, match);

  const {handleSortBy, sortOptions, isActive} = useProductSorting(
      location,
      history,
      match,
      context.Auth
  );

  const {
    // featuredPlacements,
    visibleFilters,
    allFilters,
    defaultFilterIds,
    toggleFilter,
    loadingFilters,
    // loadingPlacements,
  } = useCatalogFilters(null, rootParams, catalogFilter.FAVORITES, categoryId);

  const categoryName = "My Favorites";
  const tabTitle = useCatalogTabTitle(categoryName, attributes, allFilters, term)
  useTitle(tabTitle)

  function renderGridLoader() {
    return (
        <Grid container spacing={4}>
          {[...Array(20)].map((v, i) => (
              <Grid key={i} item lg={3} md={4} sm={6} xs={12}>
                <ProductCard isLoading product={{}} />
              </Grid>
          ))}
        </Grid>
    );
  }

  // const handleCollectionClick = (collectionId) => {
  //     const search = updateURLParameter(
  //         location.search,
  //         "collectionId",
  //         collectionId
  //     );
  //     history.push({
  //         pathname: match.url,
  //         search: search,
  //     });
  //     setShowCollections(!Boolean(collectionId));
  // };

  // const handlePreview = useCallback((product) => {
  //     setSelectedProduct(product);
  //     setOpenPreview(true);
  // }, []);

  // const handleMemo = useCallback((product) => {
  //     setSelectedProduct(product);
  //     setOpenCartDialog(true);
  // }, []);
  const [openPrompt,setOpenPrompt] = useState(false)
  const [page, setPage] = useState(0)
  const [pageTmp,setPageTmp] = useState(0)
  const [toggle,setToggle] = useState(false)
  const [leave,setLeave] = useState(false)
  const handleSelectFavorites = (state) =>{
    setToggle(state)
  }

  const clearSelectedFavorites = () =>{
    products.map((p)=>p.checked=false)
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      setResults(new Paging());
      setProducts([]);
      try {
        const url = `${productsUrl}?${rootParams}&page=${page}`;
        const response = await fetchData(url);
        setResults(response);
        //deep cloning to avoid mutation
        const responseTmp = JSON.parse(JSON.stringify(response.content))
        responseTmp.map((p)=>p.checked=false)
        setProducts(responseTmp);
        setLoading(false);
        // setProducts(response.content);
      } catch (e) {
        console.log(e)
        setLoading(false);
      }
    })();
    //eslint-disable-next-line
  }, [categoryId, rootParams,page]);

  const handleSelectedFavoriteToMemo = (product) =>{
    const productIndex = products.findIndex((p)=>p.id === product.id)
    const productsTmp = JSON.parse(JSON.stringify(products));
    productsTmp[productIndex].checked = !productsTmp[productIndex].checked
    setProducts(productsTmp)
  }

  function has(attr) {
    return attr?.value[0]?.value === "true";
  }

  const handleSelectAllFavorites = () =>{
    const productsTmp = JSON.parse(JSON.stringify(products));
    productsTmp.forEach((p)=>{
      const attrs = p.attributes || [];
      const attributeMap = createMap(attrs, "name");
      if(has(attributeMap.get("sampleSupported")) && has(attributeMap.get("sampleAvailable")))
        p.checked = true
      })
    setProducts(productsTmp)
  }

  const { handleAddFavoritesToMemo } = useCurrentCart();

  const selectedFavorites = products.some(p=>p.checked===true)
  // const collectionId = useMemo(
  //     () => parseInt(getParameterByName("collectionId", location.search)),
  //     [location.search]
  // );
  // const selectedCollection = useMemo(
  //     () => featuredPlacements.find((product)=>product.collectionId === collectionId),
  //     [collectionId,featuredPlacements]
  // );

  // useEffect(() => {
  //     if (collectionId) {
  //         setShowCollections(false);
  //     }
  // }, [collectionId]);

  useEffect(()=>{
    console.log(products)
  },[products])

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[page])

  const handleCallback = () => {
    clearSelectedFavorites()
    setToggle(!toggle)
    fetchData(`${API_URL}/users/${userId}/carts/open`).then(()=>{
      getOpenCarts()
    }).then(()=>openCartDrawer(1))
  }
  const addSelectionsToBag = () =>{
    const productsTmp = products.filter((p)=>p.checked === true)
    handleAddFavoritesToMemo(productsTmp,handleCallback)
  }

  const [redirectTo,setRedirectTo] = useState(null)

  const handlePrompt = nextLocation =>{
    setOpenPrompt(true)
    setRedirectTo(nextLocation)
     if (!redirectTo)
       return false
  }
  const handleChangePage = (pageNumber) =>{
    window.scrollTo(0, 0);
    if (pageNumber !== page) {
      setPageTmp(pageNumber)
      if (toggle && selectedFavorites) {
        setOpenPrompt(true)
      } else {
        setPage(pageNumber)
        setToggle(false)
      }
    }
  }

  const handleModalPrimaryAction = () =>{
    if (!redirectTo) {
      setToggle(false)
      setOpenPrompt(false)
      setPage(pageTmp)
    }
    else
    {
      setLeave(true)
    }
  }

  return <AccountProfileLayout
      title={'My Favorites'}
      {...props}>
    <Grid item>
      <CatalogFilters
          handleAddAttribute={handleAddAttribute}
          handleDeleteAttribute={handleDeleteAttribute}
          attributes={attributes}
          visibleFilters={visibleFilters}
          allFilters={allFilters}
          toggleFilter={toggleFilter}
          loadingFilters={loadingFilters}
          toggleSelectFavorites={handleSelectFavorites}
          clearSelectedFavorites={clearSelectedFavorites}
          toggleSelect={toggle}
          setToggleSelect={setToggle}
          defaultFilterIds={defaultFilterIds}
          favoritesPage={true}
          handleSelectAllFavorites={handleSelectAllFavorites}
          isSticky={true}
          selectedFavorites={selectedFavorites}
          addSelectionsToBag={addSelectionsToBag}
      />
      <Toolbar>
        <Typography variant="body1" style={{fontWeight:'bold'}}>
          {results.totalElements} Results
        </Typography>
        <div style={{margin: "auto"}}/>
        <Button
            className={classes.menuButton}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleSort}
        >
          Sort by <ExpandMoreIcon/>
        </Button>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseSort}
        >
          {sortOptions.map((option, index) => (
              <MenuItem
                  key={index}
                  onClick={() => {
                    handleCloseSort();
                    handleSortBy(option);
                  }}
              >
                <Text bold={isActive(option)} variant={"inherit"}>
                  {option.name}
                </Text>
              </MenuItem>
          ))}
        </Menu>
      </Toolbar>
      <Grid item>
        {!loading && results.totalElements === 0 &&
        <>
          <Grid item style={{marginTop: 34, marginBottom: 24}}>
            <Typography style={{
              fontFamily: "Chronicle",
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: "24px",
              lineHeight: "133.33%",
              letterSpacing: "0.01em",
              color: "#1B1C1D"
            }}>You don’t have any favorites yet. Explore our new collections and find your
              likes.</Typography>
          </Grid>
          <Grid item>
            <Button color={'secondary'} variant={'contained'} href={'/new-arrivals'} style={{width: 352,
              height: 56, fontFamily: "Gotham",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "16px",
              lineHeight: "125%",
              letterSpacing: "0.01em",
              textTransform: "uppercase",
              color: "#1B1C1D"}}>NEW ARRIVALS</Button>
          </Grid>
        </>
        }
        {loading ? (
            renderGridLoader()
        ) : (
            <Grid container spacing={4}>
              {products.map((product) => (
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <ProductCardWithHooks
                        product={product}
                        activeCart={cart}
                        toggleFavorite={() => setFavoriteProduct(product)}
                        favoritesSelection={toggle}
                        handleSelectedFavoriteToMemo={()=>handleSelectedFavoriteToMemo(product)}
                    />
                  </Grid>
              ))}
            </Grid>
        )}
      </Grid>
      {!loading && products.length > 0 &&
      <Grid item container justify='flex-end' spacing={2}>
        <Grid item>
          <Pagination count={results.totalPages} page={page + 1} onChange={(e, value) => handleChangePage(value - 1)}/>
        </Grid>
      </Grid>
      }
    </Grid>
    <Dialog
        onClose={() => setOpenPrompt(false)}
        open={openPrompt}
        maxWidth={"xs"}
        primaryAction={handleModalPrimaryAction}
        primaryActionTitle={"Yes"}
        secondaryAction={() => {
          setRedirectTo(null)
          setOpenPrompt(false)
        }}
        secondaryActionTitle={"Cancel"}
        hideCloseIcon={true}
    >
      <Text variant={"body1"} style={{ marginTop:4,fontFamily: "Chronicle",
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: "24px",
        lineHeight: "133.33%"}}>
        Do you want to go to the new page and clear your selections?
      </Text>
    </Dialog>
    <Prompt when={toggle && selectedFavorites} message={handlePrompt}/>
    {leave && <Redirect to={redirectTo}/>}
  </AccountProfileLayout>
}


const mapStateToProps = (state) => {
  return {
    context: {
      NavigationMenu: state.NavigationMenu,
      Cart: state.Cart,
      Auth: state.Auth,
    },
  };
};

export default connect(mapStateToProps, {
  openCartDrawer,
  getOpenCarts
})(Favorites);

