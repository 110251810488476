import {useMemo, useCallback, useContext, useEffect} from "react";
import qs from "qs";
import {ExtraTitle} from "../layouts/TitleWrapper";

const getHash = ()  => window.location.hash;
const setHash = (hash) => window.history.pushState({}, '', hash);

export const useRouterHashParam = (param) => {
  //const dispatch = useDispatch();
  const hash = getHash() || "";
  const query = useMemo(() => qs.parse(hash.replace(/^#/, "")), [hash]);

  const setParam = useCallback(
    (value) => {
      return setHash(`#${qs.stringify({ ...query, [param]: value })}`)
    },
    [ param, query]
  );
  return [query[param], setParam];
};

export const useTitle = (title) => {
  const { setExtraTitle } = useContext(ExtraTitle);

  useEffect(() => {
    if (title) setExtraTitle(title)
  }, [setExtraTitle, title])
}
