import { Collapse, Grid, TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "../../../components/Button/Button";
import { Schema } from "../../../store/@core/schema";
import { EntityType } from "../../../store/@core/entityType";
import { AccountBalanceWalletOutlined, Add } from "@material-ui/icons";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {useEntityProgress, useForm, useProgress} from "reactcoregk/hooks";
import StepContent from "../Common/StepContent";
import PaymentForm from "./PaymentForm";
import PaymentAddressForm from "./PaymentAddressForm";
import { useAlert } from "../../../hooks/alert";
import { useDispatch, useSelector } from "react-redux";
import {createPaymentMethod, deletePaymentMethod} from "../../../store/paymentMethod/actions";
import { updateAddress } from "../../../store/address/actions";
import {
  setOrderPaymentMethodId,
  setOrderPaymentMethodOption,
} from "../../../store/cart/actions";
import PaymentMethodCard from "../../../components/Cards/PaymentMethodCard/PaymentMethodCard";
import { fadeAnimation } from "../../../constants/animations";
import Heading from "../Common/Heading";
import { TabPanel } from "../../../components/Core/TabPanel";
import SearchIcon from "@material-ui/icons/Search";
import Text from "../../../components/Text/Text";
import PaymentTypeCard from "../../../components/Cards/PaymentTypeCard/PaymentTypeCard";
import { paymentMethodOption } from "../../../constants/enums";
import { createMap } from "reactcoregk/utils";
import Dialog from "../../../components/Dialog/Dialog";

function Payment(props) {
  const {
    context,
    addresses,
    countries,
    setStep,
    tradeAccountId,
    addressContext,
  } = props;
  const dispatch = useDispatch();
  const addressesMap = createMap(addresses.billing);
  const paymentMethodId = useSelector(
    (state) => state.Cart.order.paymentMethodId
  );
  const shippingAddressId = useSelector(
    (state) => state.Cart.order.shippingAddressId
  );
  const paymentOption = useSelector(
    (state) => state.Cart.order.paymentMethodOption
  );

  const [form, handleChange, setForm] = useForm(
    Schema[EntityType.PaymentMethod]
  );
  const [billingForm, handleChangeBillingForm, setBillingForm] = useForm(
    Schema[EntityType.Address]
  );
  const [query, setQuery] = useState("");
  const [currentView, setCurrentView] = useState(0);
  const [openBillingForm, setOpenBillingForm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false)
  const [cardToDelete, setCardToDelete] = useState(null)

  const paymentMethods = context.getAll.result;
  const selectedPaymentMethod = paymentMethods.find(
    (x) => x.id === paymentMethodId
  );
  const defaultAddress = addresses.shipping.find(
    (x) => x.id === shippingAddressId
  );

  const recently = paymentMethods.filter((x) => x.accessedAt);

  const dismissForms = useCallback(() => {
    setCurrentView(0);
    setOpenBillingForm(false);
    setForm(Schema[EntityType.PaymentMethod]);
  }, [setForm]);

  const [inProgress, error] = useEntityProgress(context);
  useProgress(null, dismissForms, null, context.create)
  useProgress(null, dismissForms, null, context.update)
  const [inProgressAddress, errorAddress] = useEntityProgress(addressContext, dismissForms);

  const handleAfterDelete = useCallback(() => {
    if (paymentMethodId === cardToDelete?.id) {
      dispatch(setOrderPaymentMethodId(null))
    }
    setOpenDelete(false)
  }, [cardToDelete?.id, dispatch, paymentMethodId])

  useProgress(null, handleAfterDelete, null, context.delete)

  useAlert(error, "error");
  useAlert(errorAddress, "error");

  const nextTitle = useMemo(() => {
    if (currentView === 2) {
      if (form.id) {
        return "Update Address"
      } else {
        return "Save Payment";
      }
    }
    return "Next";
  }, [currentView, form.id]);

  const handleSelectMethod = useCallback(
    (method) => {
      dispatch(setOrderPaymentMethodOption(method));
    },
    [dispatch]
  );

  const handleBack = () => {
    if (currentView === 2 || currentView === 1) {
      setCurrentView(0);
    } else {
      setStep((step) => step - 1);
    }
  };

  const handleNext = () => {
    if (currentView === 2) {
      if (form.id) {
        dispatch(updateAddress(billingForm, tradeAccountId));
      } else {
        const billingAddress = form.sameAsShipping ? defaultAddress : billingForm;
        const payload = {
          ...form,
          billingAddress,
        };
        dispatch(createPaymentMethod(payload, tradeAccountId));
      }
    } else if (openBillingForm) {
      dispatch(updateAddress(billingForm, tradeAccountId));
    } else {
      setStep((step) => step + 1);
    }
  };

  const handleDeleteCard = useCallback(() => {
    dispatch(deletePaymentMethod(cardToDelete, tradeAccountId))
  }, [cardToDelete, dispatch, tradeAccountId])

  const handleOpenDelete = useCallback((card) => {
    setCardToDelete(card)
    setOpenDelete(true)
  }, [])

  const handlePaymentSelect = useCallback(
    (payment) => {
      dispatch(setOrderPaymentMethodId(payment.id));
      setCurrentView(0);
    },
    [dispatch]
  );

  const defaultPaymentMethod = useMemo(() => {
    return paymentMethods.find(x => x.isDefault) || paymentMethods.find(x => x.accessedAt) || paymentMethods[0]
  }, [paymentMethods])

  useEffect(() => {
    if (
      !paymentMethodId &&
      defaultPaymentMethod &&
      paymentOption === paymentMethodOption.PAY_NOW
    ) {
      dispatch(setOrderPaymentMethodId(defaultPaymentMethod.id));
    }
  }, [dispatch, paymentMethodId, defaultPaymentMethod, paymentOption]);

  useEffect(() => window.scrollTo(0, 0), [currentView]);

  const isDisabled = () => {
    if (inProgress || inProgressAddress) return true;
    if (!paymentMethodId && currentView !== 2 && !openBillingForm) return true;
    if (currentView === 2) {
      if (form.id) {
        const required = ["name", "surname", "addressLine1", "countryId", "city", "zipCode"]
        return !!Object.keys(billingForm).find(prop => required.includes(prop) && !billingForm[prop])
      } else {
        const required = ["holderName", "number", "cvc", "expiration"]
        const hasError = !!Object.keys(form).find(prop => required.includes(prop) && !form[prop])
        if (!form.sameAsShipping) {
          const required = ["name", "surname", "addressLine1", "countryId", "city", "zipCode"]
          return hasError && !!Object.keys(billingForm).find(prop => required.includes(prop) && !billingForm[prop])
        }
        return hasError
      }
    }
    return false;
  };

  const handleEdit = useCallback(
    (payment, address) => {
      setForm(payment);
      setBillingForm(address);
      setCurrentView(2);
    },
    [setBillingForm, setForm]
  );

  return (
    <StepContent
      onBack={handleBack}
      onNext={handleNext}
      nextTitle={nextTitle}
      disabledNext={isDisabled()}
    >
      <TabPanel index={0} value={currentView} animation={fadeAnimation}>
        <Grid container direction={"column"} spacing={2}>
          <Grid item>
            <Heading title={"Payment Information"} mb={1} />
          </Grid>
          <Grid item>
            <Text bold>Payment Reference</Text>
            <Box mt={2} />
            <Grid item container spacing={4}>
              <Grid item md={4}>
                <PaymentTypeCard
                  checked={paymentOption === paymentMethodOption.PAY_NOW}
                  onClick={() =>
                    handleSelectMethod(paymentMethodOption.PAY_NOW)
                  }
                  paymentType={{ name: "Pay Now" }}
                />
              </Grid>
              <Grid item md={4}>
                <PaymentTypeCard
                  checked={paymentOption === paymentMethodOption.PAY_BY_TERM}
                  onClick={() =>
                    handleSelectMethod(paymentMethodOption.PAY_BY_TERM)
                  }
                  paymentType={{ name: "Pay By TERMS" }}
                />
              </Grid>
            </Grid>
          </Grid>
          {paymentOption === paymentMethodOption.PAY_NOW && (
            <Grid item style={{ display: "flex", flexDirection: "column" }}>
              <Text bold>Payment Method</Text>
              <Box mt={2} />
              {selectedPaymentMethod && (
                <PaymentMethodCard
                  paymentMethodId={paymentMethodId}
                  paymentMethod={selectedPaymentMethod}
                  checked={paymentMethodId === selectedPaymentMethod?.id}
                  onClick={() => {}}
                  onEdit={handleEdit}
                  onDelete={handleOpenDelete}
                  address={addressesMap.get(
                    selectedPaymentMethod?.billingAddressId
                  )}
                />
              )}
              <Box mt={2} />
              {paymentMethods[0] && (
                <Button
                  onClick={() => setCurrentView(1)}
                  color={"primary"}
                  style={{ alignSelf: "flex-start" }}
                  startIcon={<AccountBalanceWalletOutlined />}
                >
                  Use Another Payment Method
                </Button>
              )}
              <Button
                style={{ alignSelf: "flex-start" }}
                color={"primary"}
                startIcon={<Add />}
                onClick={() => {
                  setForm(Schema[EntityType.PaymentMethod])
                  setCurrentView(2)
                }}
              >
                Add Payment Method
              </Button>
            </Grid>
          )}
        </Grid>
      </TabPanel>
      <TabPanel index={1} value={currentView} animation={fadeAnimation}>
        <Fragment>
          <Grid container spacing={2}>
            <Grid item>
              <Heading title={"Recently Used"} />
            </Grid>
            {recently.slice(0, 2).map((payment, index) => (
              <Grid item xs={12} key={index}>
                <PaymentMethodCard
                  paymentMethod={payment}
                  checked={payment.id === paymentMethodId}
                  onClick={handlePaymentSelect}
                  onEdit={handleEdit}
                  onDelete={handleOpenDelete}
                  address={addressesMap.get(payment.billingAddressId)}
                />
              </Grid>
            ))}
          </Grid>
          <Box mt={1} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Heading title={"All Payment Methods"} mb={1}>
                <TextField
                  variant={"outlined"}
                  margin={"dense"}
                  InputProps={{
                    startAdornment: <SearchIcon />,
                  }}
                  label={"Search Card"}
                  disabled={inProgress}
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </Heading>
              <Button
                color={"primary"}
                startIcon={<Add />}
                onClick={() => {
                  setForm(Schema[EntityType.PaymentMethod])
                  setCurrentView(2)
                }}
              >
                Add Payment Method
              </Button>
            </Grid>
            {paymentMethods
              .filter((x) =>
                [x.maskedCardNumber].find((value) =>
                  value?.toLowerCase()?.includes(query.toLowerCase())
                )
              )
              .map((payment, index) => (
                <Grid item xs={12} key={index}>
                  <PaymentMethodCard
                    paymentMethod={payment}
                    checked={payment.id === paymentMethodId}
                    onClick={handlePaymentSelect}
                    onEdit={handleEdit}
                    onDelete={handleOpenDelete}
                    address={addressesMap.get(payment.billingAddressId)}
                  />
                </Grid>
              ))}
          </Grid>
        </Fragment>
      </TabPanel>
      <TabPanel index={2} value={currentView} animation={fadeAnimation}>
        {!form.id && (
          <PaymentForm
            form={form}
            setForm={setForm}
            handleChange={handleChange}
            inProgress={inProgress}
            context={context}
          />
        )}
        <Collapse in={!form.sameAsShipping}>
          <PaymentAddressForm
            form={billingForm}
            setForm={setBillingForm}
            handleChange={handleChangeBillingForm}
            countries={countries}
            inProgress={inProgress}
            context={context}
          />
        </Collapse>
      </TabPanel>
      <Dialog
          onClose={() => setOpenDelete(false)}
          open={openDelete}
          title={"Delete Card"}
          maxWidth={"xs"}
          primaryAction={handleDeleteCard}
          primaryActionTitle={"Delete"}
          primaryActionColor={"secondary"}
          primaryActionVariant={"contained"}
          secondaryAction={() => setOpenDelete(false)}
          secondaryActionTitle={"Cancel"}
          inProgress={inProgress}
      >
        <Text variant={"body1"} style={{ textAlign: "center" }}>
          Do you want to delete this payment method? This action cannot be undone.
        </Text>
      </Dialog>
    </StepContent>
  );
}

export default Payment;
