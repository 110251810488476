import {buildActionType, createAction, createAsyncAction} from "reactcoregk/store/actions";
import {
    ADD_MEMO_TO_CART,
    ADD_PRODUCT_TO_CART,
    CLOSE_CHECKOUT,
    DELETE_MULTI_PRODUCT_FROM_CART,
    DELETE_PRODUCT_FROM_CART,
    GET_OPEN_CARTS,
    OPEN_CHECKOUT,
    SET_MONITORING,
    SET_ORDER_ITEM_IDS, SET_ORDER_MEMO_PACKING_NOTES,
    SET_ORDER_MEMO_SHIPPING_METHOD_ID, SET_ORDER_NOTES,
    SET_ORDER_PAYMENT_METHOD_ID, SET_ORDER_PAYMENT_METHOD_OPTION, SET_ORDER_PO_NUMBER, SET_ORDER_PRODUCT_PACKING_NOTES,
    SET_ORDER_PRODUCT_SHIPPING_METHOD_ID, SET_ORDER_PROJECT_NAME, SET_ORDER_SAVE_ADDRESS,
    SET_ORDER_SHIPPING_ADDRESS,
    SET_SPOOFING_USER_ID,
    TOGGLE_ORDER_ITEM,
    UPDATE_CART,
    UPDATE_PRODUCT_OF_CART
} from "./actionTypes";
import {entityType} from "./module";
import {orderItemType} from "../../constants/enums";

export const getOpenCartActions = createAsyncAction(entityType, GET_OPEN_CARTS)
export const addProductActions = createAsyncAction(entityType, ADD_PRODUCT_TO_CART)
export const addMemoActions = createAsyncAction(entityType, ADD_MEMO_TO_CART)
export const updateProductActions = createAsyncAction(entityType, UPDATE_PRODUCT_OF_CART)
export const deleteProductActions = createAsyncAction(entityType, DELETE_PRODUCT_FROM_CART)
export const deleteProductsActions = createAsyncAction(entityType, DELETE_MULTI_PRODUCT_FROM_CART)
export const updateCartActions = createAsyncAction(entityType, UPDATE_CART)
export const spoofingUserActions = createAsyncAction(entityType, SET_SPOOFING_USER_ID)
export const getOpenCarts = () => getOpenCartActions.request()
export const addProductToCart = (payload, callback) => addProductActions.request({...payload, callback, type: orderItemType.PRODUCT})
export const addMemoToCart = (payload, callback) => addMemoActions.request({...payload, callback, type: orderItemType.SAMPLE})
export const deleteProductFromCart = (payload) => deleteProductActions.request(payload)
export const deleteProductsFromCart = (payload) => deleteProductsActions.request(payload)
export const updateProductOfCart = (payload, callback) => updateProductActions.request({data: payload, callback})
export const updateCart = (payload) => updateCartActions.request(payload)
export const setSpoofingId = (id) => spoofingUserActions.request(id)
export const setMonitoring = (fromId) => createAction(buildActionType(entityType, SET_MONITORING), fromId)

// Actions for checkout
export const openCheckout = (payload) => createAction(buildActionType(entityType, OPEN_CHECKOUT), payload)
export const closeCheckout = (payload) => createAction(buildActionType(entityType, CLOSE_CHECKOUT), payload)
export const toggleOrderItem = (payload) => createAction(buildActionType(entityType, TOGGLE_ORDER_ITEM), payload)
export const setOrderItemIds = (payload) => createAction(buildActionType(entityType, SET_ORDER_ITEM_IDS), payload)
export const setOrderProductShippingMethodId = (payload) => createAction(buildActionType(entityType, SET_ORDER_PRODUCT_SHIPPING_METHOD_ID), payload)
export const setOrderMemoShippingMethodId = (payload) => createAction(buildActionType(entityType, SET_ORDER_MEMO_SHIPPING_METHOD_ID), payload)
export const setOrderPaymentMethodId = (payload) => createAction(buildActionType(entityType, SET_ORDER_PAYMENT_METHOD_ID), payload)
export const setOrderShippingAddress = (payload) => createAction(buildActionType(entityType, SET_ORDER_SHIPPING_ADDRESS), payload)
export const setOrderPoNumber = (payload) => createAction(buildActionType(entityType, SET_ORDER_PO_NUMBER), payload)
export const setOrderNotes = (payload) => createAction(buildActionType(entityType, SET_ORDER_NOTES), payload)
export const setOrderProjectName = (payload) => createAction(buildActionType(entityType, SET_ORDER_PROJECT_NAME), payload)
export const setOrderMemoPackingNotes = (payload) => createAction(buildActionType(entityType, SET_ORDER_MEMO_PACKING_NOTES), payload)
export const setOrderProductPackingNotes = (payload) => createAction(buildActionType(entityType, SET_ORDER_PRODUCT_PACKING_NOTES), payload)
export const setOrderPaymentMethodOption = (payload) => createAction(buildActionType(entityType, SET_ORDER_PAYMENT_METHOD_OPTION), payload)
export const setOrderSaveAddress = (payload) => createAction(buildActionType(entityType, SET_ORDER_SAVE_ADDRESS), payload)
