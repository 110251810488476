import { useCallback, useMemo, useState, useEffect } from "react";
import { useSnackbar } from "notistack";

export const useMoreOrLess = (array, limit, showMoreInitial = false) => {
  const [showMore, setShowMore] = useState(showMoreInitial);

  let toggleMore = useCallback(
    () => setShowMore((prevState) => !prevState),
    []
  );
  let label = !showMore ? "Show More" : "Show Less";

  const list = useMemo(() => {
    if (showMore) return array;
    return array.slice(0, limit);
  }, [array, limit, showMore]);

  if (array.length <= limit) {
    toggleMore = null;
    label = null;
  }

  return [list, toggleMore, label];
};

export const useAsyncErrorCallback = (stopLoader) => {
  const { enqueueSnackbar } = useSnackbar();
  return useCallback(
    (error, data) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
      stopLoader();
    },
    [enqueueSnackbar, stopLoader]
  );
};

export const useKeyDown = (code, callback) => {
  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === code) {
        callback();
      }
    },
    [callback, code]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);
};
