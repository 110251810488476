import {Form, Formik} from 'formik'
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Link,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import FormikTextField from "components/Core/FormikFields/FormikTextField";
import List from "@material-ui/core/List";
import * as Yup from "yup";
import { getAxiosConfig } from "utils";
import {useState} from "react";
import { API_URL } from "config";
import axios from "axios";
import ProofOfBusinessDialog from "components/TradeAccount/ProofOfBusinessDialog";
import {FormikContext} from "components/FormikDisabler";
import WarnEditOverlay from 'components/Core/FormikFields/WarnEditOverlay'
import FormikCheckbox from 'components/Core/FormikFields/FormikCheckbox'

export const ProofOfBusiness = (props) => {
  const {
    setFormData,
    formData,
    step,
    setStep,
    proofOfBusinessFiles,
    setProofOfBusinessFiles,
    formRef,
    disableActions,
    proofOfBusiness=true,
    disabled=false,
    isUserView=true,
    locked=false,
    isAccountEdit=false
  } = props;

  const [proofOfBusinessOpen, setProofOfBusinessOpen] = useState(false);

  const step1Validation = Yup.object().shape({
    fedTaxId: Yup.string().when("typeOfProof", {
      is: (val) => val==='taxId',
      then: Yup.string().required('This field is required')
    }),
    businessLink: Yup.string().when("typeOfProof", {
      is: (val) => val==='other',
      then: Yup.string().required('This field is required')
    }).when("typeOfProof", {
      is: (val) => val==='other',
      then: Yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Please enter a valid link'
      ),
    }),
    uploadedFiles: Yup.boolean().when("typeOfProof", {
      is: (val) => val==='other'&&!isAccountEdit,
      then: Yup.boolean().required("Qualified documents must also be provided.")
      .oneOf([true], "Qualified documents must also be provided.")
    })
  })

  const [deletingFileProof, setDeletingFileProof] = useState();

  const deleteFileProof = (id, setFieldValue) => {
    const url = `${API_URL}/files/${id}`;
    setDeletingFileProof(true);
    axios
      .delete(url, getAxiosConfig())
      .then((response) => {
        if (response.status === 200) {
          let newFiles = [...proofOfBusinessFiles];
          let fileIndex = newFiles.findIndex((f) => f.id === id);
          newFiles.splice(fileIndex, 1);
          if (newFiles.length===0) {
            setFieldValue('uploadedFiles',false)
          }
          setProofOfBusinessFiles(newFiles);
          setDeletingFileProof(false);
        }
      })
      .catch((err) => {
        // Silent error
        console.log(err);
        setDeletingFileProof(false);
        throw err;
      });
  };

  const [showWarning, setShowWarning] = useState(null)

  return (
    <>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={formData}
        validationSchema={step1Validation}
        onSubmit={(values) => {
          setFormData(prevFormData => ({...prevFormData, ...values, proofOfBusinessSubmitted: true }))
          if (setStep) setStep(step + 1);
        }}
        validateOnChange={true}
      >
        {({ values, errors, setFieldValue, isValid, dirty}) => (
          <Form noValidate>

            {proofOfBusiness&&!isAccountEdit&&<ProofOfBusinessDialog
              open={proofOfBusinessOpen}
              setOpen={setProofOfBusinessOpen}
              setFiles={setProofOfBusinessFiles}
              files={proofOfBusinessFiles}
              setFieldValue={setFieldValue}
            />}

            <FormikContext.Provider value={{disabled,locked}}>
              <Grid item container spacing={3}>

                {!isAccountEdit&&<>
                <Grid item xs={12}>
                  <Typography variant="h5" style={{fontWeight: 700, marginTop: 16}}>
                    Business Proof
                  </Typography>
                  {/*{JSON.stringify(errors)}*/}
                </Grid>

                <Grid item xs={12}>
                  <Typography variant={'h6'}> Please provide us one of the following to help us verify you as a business </Typography>
                </Grid>


                <Grid item xs={12}>
                  <RadioGroup
                    aria-label="typeOfProof"
                    name="typeOfProof"
                    value={values.typeOfProof}
                    style={{ flexDirection: "initial", position:'relative' }}
                    disabled={disabled}
                  >
                    {locked&&<WarnEditOverlay showWarning={showWarning} setShowWarning={setShowWarning} />}
                    <Grid item xs={12}>
                      <FormControlLabel
                        value={"taxId"}
                        control={<Radio color={"secondary"} disabled={disabled} onChange={ ()=>{
                          setFieldValue("typeOfProof","taxId")
                        }} />}
                        label="Tax registration ID*"
                      />
                    </Grid>

                    {values.typeOfProof==='taxId'&&<Box pt={2} pb={2}>
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          *If you are in the U.S., please provide your Federal Tax ID. In the E.U., please provide your VAT number. Please do not provide your State Tax ID.
                        </Grid>

                        <Grid item xs={12}>
                          <FormikTextField
                            variant={"outlined"}
                            margin="none"
                            size={"small"}
                            fullWidth
                            type={"text"}
                            id={"fedTaxId"}
                            name={"fedTaxId"}
                            label={"Tax Registration No."}
                            disabled={values.skipProofOfBusiness}
                          />
                        </Grid>
                      </Grid>
                    </Box>}

                    <Grid item xs={12}>
                      <FormControlLabel
                        value={"other"}
                        control={<Radio color={"secondary"} disabled={disabled} onChange={ ()=>{
                          setFieldValue("typeOfProof","other")
                          setFieldValue("fedTaxId","")
                        }} />}
                        label="Other qualified documents and a web link."
                      />
                    </Grid>
                  </RadioGroup>
                </Grid>

                {values.typeOfProof==='other'&& <Grid
                  item
                  container
                  style={{ opacity: values.skipProofOfBusiness ? 0.3 : 1 }}
                  spacing={2}
                >
                  <><Grid item xs={12}>
                  Please provide a link to your:
                  <ul>
                    <li>Business website</li>
                    <li>Most recent design work</li>
                    <li>Business social media profile page (e.g. www.instagram.com/youraccounthandle)</li>
                  </ul>

                </Grid></>

                  <Grid item xs={12}>
                    <FormikTextField
                      variant={"outlined"}
                      margin="none"
                      size={"small"}
                      fullWidth
                      type={"text"}
                      id={"businessLink"}
                      name={"businessLink"}
                      label={"WEB LINK"}
                      disabled={values.skipProofOfBusiness}
                      link={!isUserView?values.businessLink:false}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <b>And</b>
                    <p>upload any of the following qualified documents:</p>
                    <ul>
                      <li>Resale certificate, business license, or VAT</li>
                      <li>Membership of ASID or a professional design association</li>
                      <li>Interior design portfolio</li>
                    </ul>
                  </Grid>

                  {proofOfBusinessFiles&&proofOfBusinessFiles.length !== 0 && (
                    <Grid item xs={12}>
                      <List dense={true}>
                        {proofOfBusinessFiles.map((file, i) => {
                          return (
                            <ListItem>
                              <ListItemText
                                style={{ paddingRight: 16 }}
                                primary={file.filename?file.filename:file.name?file.name:''}
                              />
                              <ListItemSecondaryAction>
                                <Link
                                  to={"#"}
                                  style={{
                                    cursor: "pointer",
                                    opacity: deletingFileProof ? 0.3 : 1,
                                  }}
                                  onClick={() => {
                                    if (deletingFileProof) return
                                    window.open(`${API_URL}/files/${file.id}`,'_blank');
                                  }}
                                >
                                  {" "}
                                  <b>View File</b>{" "}
                                </Link>
                                &nbsp;
                                <Link
                                  to={"#"}
                                  style={{
                                    cursor: "pointer",
                                    opacity: deletingFileProof ? 0.3 : 1,
                                  }}
                                  onClick={() => {
                                    if (!deletingFileProof) {
                                      deleteFileProof(file.id, setFieldValue);
                                    }
                                  }}
                                >
                                  {" "}
                                  <b>Remove</b>{" "}
                                </Link>
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Grid>
                  )}

                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      size="large"
                      fullWidth
                      onClick={() => setProofOfBusinessOpen(true)}
                      disabled={values.skipProofOfBusiness||disabled}
                    >
                      UPLOAD FILES
                    </Button>
                  </Grid>

                  <FormikTextField hidden={true} name={'uploadedFiles'} />

                </Grid>}

                <Box mt={4} />

              </>}

                {isAccountEdit&&<>
                  <Grid item container spacing={2}>
                    <Grid item xs={12}>
                      <FormikTextField
                        variant={"outlined"}
                        margin="none"
                        size={"small"}
                        fullWidth
                        type={"text"}
                        id={"fedTaxId"}
                        name={"fedTaxId"}
                        label={"Tax Registration No."}
                        disabled={values.skipProofOfBusiness}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      variant={"outlined"}
                      margin="none"
                      size={"small"}
                      fullWidth
                      type={"text"}
                      id={"businessLink"}
                      name={"businessLink"}
                      label={"WEB LINK"}
                      disabled={values.skipProofOfBusiness}
                      link={!isUserView?values.businessLink:false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikCheckbox
                      label={
                          "This company focuses on both residential & hospitality projects"
                      }
                      id={"focusOnResidentialHospitality"}
                      name={"focusOnResidentialHospitality"}
                    />
                  </Grid>
                </>}

              {setStep && (
                <Grid
                  item
                  container
                  spacing={2}
                  justify="center"
                  alignContent="center"
                  alignItems="flex-start"
                >
                  <Grid item xs={12} md={6}>
                    {step !== 1 && (
                      <>
                        <Button
                          size="large"
                          variant={"outlined"}
                          fullWidth
                          onClick={() => setStep(step - 1)}
                        >
                          GO BACK
                        </Button>{" "}
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid item container spacing={2}>
                      <Grid item xs={12}>
                        <Button
                          size="large"
                          variant={"contained"}
                          color='secondary'
                          fullWidth
                          type={"submit"}
                          disabled={disableActions || !isValid || (!dirty && !formData.proofOfBusinessSubmitted) }
                        >
                          {" "}
                          NEXT{" "}
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          size="large"
                          variant={"outlined"}
                          fullWidth
                          onClick={()=>{
                            setFormData(prevFormData => ({...prevFormData, ...values, proofOfBusinessSubmitted: true, firstLoad:false }))
                          }}
                          disabled={disableActions || !isValid || (!dirty && !formData.proofOfBusinessSubmitted) }
                        >
                          {" "}
                          {"SAVE APPLICATION"}
                        </Button>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>
              )}
            </Grid>
            </FormikContext.Provider>
          </Form>
        )}
      </Formik>
    </>
  );
};
