import {
  CLOSE_CART_DRAWER,
  OPEN_CART_DRAWER,
  TOGGLE_CART_DRAWER,
} from "./actionTypes";

const initialState = {
  // Cart drawer on possible values (false, 0, 1, 2) false = not initialized, 0 = closed, 1 = Memos, 2 = Products, 3 = All tabs
  cartDrawerOn: false,
};

const Element = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CART_DRAWER:
      return { ...state, cartDrawerOn: action.activeTab };
    case CLOSE_CART_DRAWER:
      return { ...state, cartDrawerOn: 0 };
    case TOGGLE_CART_DRAWER:
      return { ...state, cartDrawerOn: !Boolean(state.cartDrawerOn) };
    default:
      return state;
  }
};

export default Element
