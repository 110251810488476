import {makeStyles} from '@material-ui/core/styles'
import {Container, Grid} from '@material-ui/core'
import {useParams} from 'react-router-dom'

import {useSnackbar} from 'notistack'
import AccountInformation from 'pages/Admin/Account/components/AccountInformation'
import AccountMembers from 'pages/Admin/Account/components/AccountMembers'
import BusinessInformation from 'pages/Admin/Account/components/BusinessInformation'
import ContactInformation from 'pages/Admin/Account/components/ContactInformation'
import Box from '@material-ui/core/Box'
import ListIcon from "@material-ui/icons/List";
import EditIcon from '@material-ui/icons/Edit';
import HeaderButton from "pages/Admin/Applications/components/headerButton";
import {useEffect, useState} from 'react'
import {ADMIN_API_URL} from 'config'
import axios from 'axios'
import {getAxiosConfig, getRoles, useConfig} from 'utils'
import {addressContactType, userRoles} from 'constants/enums'
import {USCountryID} from 'constants/constants'

const useStyles = makeStyles({
  anchors: {
    '& a': {
      color: '#000 !important'
    }
  },
  widgetBox: {
    boxShadow: '0px 0px 16px rgba(27, 28, 29, 0.1)',
    borderRadius: 24,
    padding: 24,
    height: '100%',
    width: '100%'
  }
})

function AccountDetails({history}) {
  const classes = useStyles()
  const {id} = useParams()
  const {enqueueSnackbar} = useSnackbar()
  const [isBusy, setIsBusy] = useState(false)
  const [data, setData] = useState()

  const [states] = useConfig(`config/states/?countryId=${USCountryID}`, [])

  const [isSalesRep, setIsSalesRep] = useState(false)

  useEffect(()=>{
    const roles = getRoles();
    setIsSalesRep(roles.includes(userRoles.INTERNAL_SALES_REP))
  },[])

  useEffect(()=>{
    const url = `${ADMIN_API_URL}/accountDetails/${id}`

    axios.get(url, getAxiosConfig())
      .then((response) => {

        if (response.status===200) {
          let addresses = {}
          Object.values(addressContactType).forEach((type)=>{
            const contactTypeVar = `${type.toLowerCase()}Address`
            const contactInd = response.data.contacts.findIndex((c)=>c.contactType===type)
            if (contactInd!==-1) {
              const contact = response.data.contacts[contactInd]
              if (contact.countryId===USCountryID) {
                const state = states.find((s)=>s.id===contact.stateId)
                contact.state = state?state.name:''
              }
              addresses[contactTypeVar] = contact
            } else {
              addresses[contactTypeVar] = false
            }
          })

          setData({...response.data, ...addresses})
        }

      }).catch((err) => {
        enqueueSnackbar(`An error occurred while loading the account`, {variant: 'error'})
        console.log(err)
        setIsBusy(false)
      throw err
    })
  // eslint-disable-next-line
  },[id])

  return <>
    <Container style={{opacity: isBusy?0.3:1}}>
      <Box pt={4}/>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box fontSize={48} fontFamily={'Chronicle Display'}>{data&&<>{data.businessAddress.name}</>}</Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid item container justify={'flex-end'} spacing={3}>
            <Grid item>
              <HeaderButton label={`${isSalesRep?'View':'Edit'} Account Details`} to={`/admin/account-details/edit/${id}`} icon={EditIcon} />
            </Grid>
            <Grid item>
              <HeaderButton label={`Go to accounts list`} to={`/admin/account-management/accounts`} icon={ListIcon} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box className={classes.widgetBox}>
            <AccountInformation accountInfo={data} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={classes.widgetBox}>
            <AccountMembers members={data&&data.accountMembers} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={classes.widgetBox}>
            <BusinessInformation businessInfo={data} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={classes.widgetBox}>
            {data&&<ContactInformation addresses={data} />}
          </Box>
        </Grid>
      </Grid>
    </Container>
    <Box pt={12}/>
  </>
}

export default AccountDetails
