import {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import ReactImageMagnify from "react-image-magnify";
import PropTypes from "prop-types";
import { ImagePicker } from "../ImagePicker/ImagePicker";
import { Skeleton } from "@material-ui/lab";
import Text from "../Text/Text";


const ProductImagePlaceHolder = () =>{
    return <Grid container style={{background: '#FCF9E8',width:'100%',height:'100%',aspectRatio:'1/1',position:'relative',zIndex:9}} justify={'center'} alignItems={'center'} alignContent={'center'}>
            <Grid item style={{zIndex:10}}>
                <Text variant={'body1'} style={{fontFamily:'Gotham',color:'#3B3C3E',fontSize:'16px',fontWeight:300}}>IMAGE IS COMING SOON (IICS)</Text>
            </Grid>
    </Grid>
}

export function ProductViewer(props) {
  const { images, direction, rimProps, isLoading,setSelectedThumb } = props;
  const [activeVariation, setActiveVariation] = useState(0);

  const thumbs = images.map((x) => x.thumbnailUrl);
  const large = images.map((x) => x.largeUrl);

  useEffect(() => {
      setActiveVariation(0)
  }, [images])

    //used for Pinterest
    useEffect(()=>{
        setSelectedThumb(activeVariation)
        // eslint-disable-next-line
    },[activeVariation])

  return (
    <Grid container direction={direction} wrap="nowrap" spacing={2}>
      <Grid item>
        <ImagePicker
          size="lg"
          direction={direction === "row" ? "column" : "row"}
          images={thumbs}
          selectedIndex={activeVariation}
          setSelectedIndex={setActiveVariation}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item style={{minHeight: 470}}>
        {isLoading ? (
          <Skeleton variant={"rect"} height={470} />
        ) : (
            large[0]?
          <ReactImageMagnify
            style={{ zIndex: 1 }}
            // enlargedImagePosition="over"
            {...{
              smallImage: {
                alt: "Wristwatch by Versace",
                isFluidWidth: true,
                src: large[activeVariation],
                // srcSet: src.srcSet,
                sizes:
                  "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
              },
              largeImage: {
                src: large[activeVariation],
                width: 1426,
                height: 2000,
              },
              lensStyle: { backgroundColor: "rgba(0,0,0,.6)" },
            }}
            {...rimProps}
          />
                :
            <ProductImagePlaceHolder/>
        )}
      </Grid>
    </Grid>
  );
}
ProductViewer.defaultProps = {
  direction: "row",
  images: [],
};

ProductViewer.propTypes = {
  direction: PropTypes.oneOf(["column", "row", "column-reverse"]),
  images: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
};
