import HomeSkeleton from "../../components/HomeSections/HomeSkeleton";
import { userCategory, userRoles } from "../../constants/enums";
import SignedInNonTradeWLoginNoActivities from "../../components/HomeSections/SignedInNonTradeWLoginNoActivities";
import SignedInNonTradeWLoginWithActivities from "../../components/HomeSections/SignedInNonTradeWLoginWithActivities";
import SignedInDesignerWLoginNoActivities from "../../components/HomeSections/SignedInDesignerWLoginNoActivities";
import SignedInDesignerWLoginWActivities from "../../components/HomeSections/SignedInDesignerWLoginWActivities";
import SignedInDesignerWAccountWActivitiesWOrders from "../../components/HomeSections/SignedInDesignerWAccountWActivitiesWOrders";
import SignedInDesignerWAccountWActivitiesNoOrders from "../../components/HomeSections/SignedInDesignerWAccountWActivitiesNoOrders";
import SignedInDesignerWAccountNoActivityNoOrders from "../../components/HomeSections/SignedInDesignerWAccountNoActivityNoOrders";
import NonSignedIn from "../../components/HomeSections/NonSignedIn";
import { useMemo } from "react";

function UserColumn({ validatingUser, content, visibleProfile, openCartDrawer }) {
  const permissions = useMemo(() => {
    if (visibleProfile) {
      return visibleProfile;
    }
    return { role: userRoles.NON_SIGNED };
  }, [visibleProfile]);

  const { role, category, hasActivity, hasOrders } = permissions;

  const getComponentBasedOnRole = () => {
    if (validatingUser) return HomeSkeleton;
    switch (role) {
      case userRoles.WEB_USER:
        if (
          category === userCategory.DESIGN_ENTHUSIAST ||
          category === userCategory.HOME_OWNER
        ) {
          if (!hasActivity) {
            // Signed in NonTrade w Login - No Activities
            return SignedInNonTradeWLoginNoActivities;
          } else {
            // Signed in NonTrade w Login - w Activities
            return SignedInNonTradeWLoginWithActivities;
          }
        } else if (
          category === userCategory.DESIGNER ||
          category === userCategory.TRADE_PROFESSIONAL ||
          category === userCategory.DESIGN_PROFESSIONAL
        ) {
          if (!hasActivity) {
            // Signed In - Designer w Login - No Activities
            return SignedInDesignerWLoginNoActivities;
          } else {
            // Signed In - Designer w Login - w Activities
            return SignedInDesignerWLoginWActivities;
          }
        }
        break;
      case userRoles.TRADE_ACCOUNT_OWNER:
      case userRoles.TRADE_ACCOUNT_MEMBER:
        if (hasActivity && hasOrders) {
          // Signed In - Designer w Account - w Activities w Orders
          return SignedInDesignerWAccountWActivitiesWOrders;
        } else if (hasActivity) {
          // Signed In - Designer w Account - w Activities No Orders
          return SignedInDesignerWAccountWActivitiesNoOrders;
        } else {
          // Signed In - Designer w Account - No Activities No Orders
          return SignedInDesignerWAccountNoActivityNoOrders;
        }
      // case userRoles.NON_SIGNED:
      // case userRoles.ADMIN:
      // case userRoles.INTERNAL_SALES_REP:
      //   return; // NON_SIGNED_IN
      default:
        return NonSignedIn;
    }
  };

  const UserColumn = getComponentBasedOnRole();

  return <UserColumn visibleProfile={visibleProfile} content={content} openCartDrawer={openCartDrawer}/>
}

export default UserColumn;
