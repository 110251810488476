import Button from "../Button/Button";
import Text from "../Text/Text";
import { Box, Grid, Link } from "@material-ui/core";
import {Form, Formik, useFormikContext} from "formik";
import { connect } from "react-redux";
import {apiError, forgotPassword} from "../../store/auth/account/actions";
import {useEffect, useRef, useState} from "react";
import {useAlert} from "../../hooks/alert";
import * as Yup from "yup";
import FormikTextField from "components/Core/FormikFields/FormikTextField";
import {getAxiosConfig} from "utils";
import axios from "axios";
import {API_URL} from "config";

const forgotValidation = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

function ForgotForm(props) {
  const {
    apiError, forgotPassword,
    error,
    isAuth,
    setOpenSignIn,
    setOpenForgot,
  } = props;

  const [sent,setSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [userExists, setUserExists] = useState(null)

  useEffect(() => {
    apiError("");
  }, [apiError]);

  useEffect(() => {
    if (isAuth) {
      setOpenSignIn(false);
    }
  }, [isAuth, setOpenSignIn]);

  useAlert(error, "error")

  const searchTimeout = useRef()
  const [lastEmail, setLastEmail] = useState('');

  const FindUser = () => {
    const { values } = useFormikContext();

    const checkEmailAddress = () => {

      let schema = Yup.string().email();
      let isValid = schema.isValidSync(values.email);

      setUserExists(null);

      if (isValid) {

        setLoading(true);

        axios
          .get(`${API_URL}/account/user/exists?email=${values.email}`, getAxiosConfig())
          .then((response) => {
            if (response.status===200) {
              setUserExists(response.data)
            } else {
              setUserExists(null)
            }
            setLoading(false)
          })
          .catch((err) => {
            // Silent error
            setLoading(false)
            setUserExists(null)
            console.log(err);
          });

      }

    }

    useEffect(()=>{
      if (values.email===lastEmail) return false
      setLastEmail(values.email)

      if (values.email) {
        clearTimeout(searchTimeout.current)
        searchTimeout.current = setTimeout(checkEmailAddress,400)
      }
      // eslint-disable-next-line
    },[values.email])

    return null
  }

  return (
    <Box>
      <Text variant={"h5"}>Forgot Password</Text>
      <Box mt={2} />
      <Box style={{ width: 340, fontSize:14 }}>
          {sent?'A link was just sent to your inbox.':'Enter your registered email below and we will send a password reset link to your inbox.'}
      </Box>
      <Box style={{ width: 340 }} mt={3}>
        <Formik initialValues={{email:''}} validateOnChange validationSchema={forgotValidation} onSubmit={(values) => {
            forgotPassword(values)
            setSent(true)
        }}>
          {({isValid}) => (
            <Form noValidate style={{width:'100%'}}>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <FormikTextField
                    variant={'outlined'}
                    margin="none"
                    size={'small'}
                    fullWidth
                    type={'email'}
                    id={'email'}
                    name={'email'}
                    label={'Email'}
                    loading={loading}
                    error={userExists===false}
                    valid={userExists===true&&isValid}
                  />
                  {userExists===false&&<Box pl={1} pt={0.5} style={{textTransform:'uppercase',color:'#DF0006', fontWeight:'bold'}}> NO MATCHING EMAIL FOUND </Box>}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    size={"large"}
                    color={"secondary"}
                    variant={"contained"}
                    type={"submit"}
                    disabled={loading||!userExists||!isValid}
                  >
                    {sent?'RESEND RESET PASSWORD LINK':'SEND RESET PASSWORD LINK'}
                  </Button>
                </Grid>
                <FindUser />
              </Grid>

            </Form>)}

        </Formik>
      </Box>
      <Box p={1} pt={2} fontSize={14}>Remember password?  <Link href="#" style={{color:"black", fontWeight:"bold"}} onClick={()=>{ setOpenForgot(false); setOpenSignIn(true); }}>SIGN IN.</Link></Box>
    </Box>
  );
}

const mapStateToProps = (state) => {
  const { error, loading, isAuth } = state.Auth;
  return { error, loading, isAuth };
};

export default connect(mapStateToProps, { forgotPassword, apiError })(ForgotForm);
