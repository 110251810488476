import {connect} from "react-redux";
import {apiError} from "../../../store/auth/account/actions";
import {Redirect, useLocation} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {API_URL} from 'config'
import axios from 'axios'
import {useSnackbar} from 'notistack'
import Box from '@material-ui/core/Box'

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const Confirm = (props) => {
    const { enqueueSnackbar} = useSnackbar();
    // eslint-disable-next-line
    const [isBusy, setIsBusy] = useState(false)
    const [redirect, setRedirect] = useState(false)
    let token = useQuery().get('token')

    useEffect(()=>{

        const url = `${API_URL}/account/confirm`

        axios.post(url,{token})
          .then((response) => {
              setIsBusy(false);
              if (response.status===204) {
                setRedirect(true)
              } else {
                enqueueSnackbar(`There was an error confirming your account.`, {variant:'error'});
              }
          })
          .catch((err) => {
              enqueueSnackbar(`There was an error confirming your account.`, {variant:'error'});
              setIsBusy (false);
              throw err;
          });

        //eslint-disable-next-line
    },[])

    return (
      <Box height={'80vh'}>{redirect&&<Redirect to={'/account/create-trade-account'} />}</Box>
  );
};
const mapStateToProps = (state) => {
    const { error, loading, isAuth} = state.Auth;
    return { error, loading, isAuth };
};

export default connect(mapStateToProps, { apiError })(Confirm);
