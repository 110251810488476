import {makeStyles} from "@material-ui/core/styles";
import {Favorite, FavoriteBorderOutlined, LocalMallOutlined} from "@material-ui/icons";
import {CardActionArea, CardMedia, Grid, IconButton} from "@material-ui/core";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {addMemoToCart, addProductToCart} from "../../store/cart/actions";
import {createMap} from "reactcoregk/utils";
import {openCartDrawer} from "../../store/element/actions";
import {useCallback, useEffect, useRef, useState} from "react";
import Text from "../Text/Text";

const useStyles = makeStyles(theme => ({
    favoriteButton: {
        bottom: 0,
        right: 0,
        marginBottom: 12,
        marginRight: 12,
        position: "absolute",
        boxShadow: "0px 0px 12px rgba(27, 28, 29, 0.1)",
    },
    image: {
        height: 0,
        paddingTop: "100%",
        width: "100%",
        // borderRadius: theme.borderRadius
    },
    border: {
        // borderRadius: theme.borderRadius
    }
}));

const ProductPlaceHolderImage = () => {
    const boxRef = useRef()
    const [thumbWidth, setThumbWidth] = useState(180)

    const boxResize = useCallback((e) => {
        boxRef && boxRef.current &&
        setThumbWidth(boxRef.current?.offsetWidth)
    },[])

    useEffect(() => {
        window.addEventListener('resize', boxResize, false)

        return () => {
            window.removeEventListener('resize', boxResize, false)
        }
    }, [boxResize])


    return <Grid container ref={boxRef} style={{
        background: '#FCF9E8',
        width: '100%',
        height: '100%',
        position: 'relative',
        aspectRatio: '1/1'
    }} alignContent={'center'} justify={'center'} alignItems={'center'}>
        <Grid item style={{marginTop: 0, width: '80%', textAlign: 'center'}}>
            <Text variant={'body1'} style={{
                fontFamily: 'Gotham',
                color: '#3B3C3E',
                fontSize: '12px',
                fontWeight: 300,
            }}>{thumbWidth <= 100 ? 'IICS' : 'IMAGE IS COMING SOON (IICS)'}</Text>
        </Grid>
    </Grid>
}

function SmallProductCard({product, showBasket = false, context, handleFavorite, addMemoToCart}) {
    const classes = useStyles();
    const attrs = product.attributes || [];
    const attributeMap = createMap(attrs, "name");

    function has(attr) {
        return attr?.value[0]?.value === "true";
    }

    const isValid = has(attributeMap.get("sampleSupported")) && has(attributeMap.get("sampleAvailable"))
    const handleAddMemo = useCallback(() => {
        addMemoToCart({
            productId: product.id,
            quantity: 1,
            cartId: context.Cart.currentCartId,
            notes: "",
        })
    }, [addMemoToCart, context.Cart.currentCartId, product.id])

    return (
        <div style={{position: "relative"}}>
            <CardActionArea
                component={Link}
                className={classes.border}
                to={`/catalog/products/${product.itemNumber}`}
            >
                {product.imageUrl ?
                    <CardMedia
                        className={classes.image}
                        image={product.imageUrl}
                        alt={product.itemNumber}
                    />
                    :
                    <ProductPlaceHolderImage classes={classes}/>
                }

            </CardActionArea>
            {showBasket ? (
                isValid &&
                <IconButton className={classes.favoriteButton} onClick={handleAddMemo} size={'small'}
                            style={{background: "white"}}>
                    <LocalMallOutlined/>
                </IconButton>
            ) : (
                <IconButton size={'small'} onClick={handleFavorite} className={classes.favoriteButton}
                            style={{background: "white", color: !product.isFavorite ? '' : '#FFD700'}}>
                    {product.isFavorite ? <Favorite/> : <FavoriteBorderOutlined/>}
                </IconButton>
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        context: {
            Cart: state.Cart,
            Auth: state.Auth
        },
    };
};

export default connect(mapStateToProps, {
    addProductToCart,
    addMemoToCart,
    openCartDrawer,
})(SmallProductCard)
