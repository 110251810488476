import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import ActionButton from "../../Core/ActionButton";
import FooterSocialMedia from "../../Footer/SocialMedia";
import SmallProductCard from "../SmallProductCard";
import ActivityCard from "../ActivityCard";
import SectionTitle from "../SectionTitle";
import GreetingText from "../GreetingText";
import {getUserId} from "../../../utils";
import {useFavorites} from "../../../hooks/favorites";
import {connect} from "react-redux";
import {addMemoToCart, addProductToCart} from "../../../store/cart/actions";

const useStyles = makeStyles({
  wrapper: {
    height: "100%",
  },
});

const WithActivities = ({ activities, content, visibleProfile,context }) => {
  const classes = useStyles();
    const [favoriteProduct, setFavoriteProduct] = useState({});
    const userId = context.Cart.spoofingUserId || getUserId();
    useFavorites(favoriteProduct, [...content.relevantProducts,...content.lastViewedProducts], setFavoriteProduct, userId);
  return (
    <Grid className={classes.wrapper} lg={4} md={3} sm={6} xs={12} item>
      <GreetingText text={"You've got great taste."} user={visibleProfile} />
      {activities.map((dummy, i) => {
        return (
          <Grid key={i} item>
            <ActivityCard activity={dummy} />
          </Grid>
        );
      })}
      <Box mt={4} />
      <SectionTitle title={"You’ve got great taste."} />
      <Grid container spacing={2}>
        {content?.lastViewedProducts.map((product, index) => (
          <Grid item md={4} key={index}>
            <SmallProductCard handleFavorite={()=>setFavoriteProduct(product)} product={product} />
          </Grid>
        ))}
      </Grid>
      <Box mt={2}>
      <ActionButton title={"Recently Viewed"} actionPath={"/products/recently-viewed"}/>
      </Box>
      <FooterSocialMedia
        footerStyle={"dark"}
        title={
          "Don't miss a beat! Follow us on social for endless inspiration."
        }
        style={{ marginTop: 10 }}
      />
    </Grid>
  );
};
const mapStateToProps = (state) => {
    return {
        context: {
            Cart: state.Cart,
            Auth: state.Auth
        },
    };
};

export default connect(mapStateToProps, {
    addProductToCart,
    addMemoToCart
})(WithActivities);
