import TaxExemptFormDialog from 'components/TradeAccount/TaxExemptFormDialog'
import {
  Box,
  Button, Checkbox,
  FormControlLabel,
  Grid,
  Link,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@material-ui/core'
import List from '@material-ui/core/List'
import {useState} from 'react'
import {API_URL} from 'config'
import axios from 'axios'
import {getAxiosConfig} from 'utils'
import {USCountryID} from 'constants/constants'
import DisableOverlay from 'components/DisableOverlay'
import {FormikContext} from 'components/FormikDisabler'

export const TaxExempt = (props) => {

  const {
    setTaxExemptFiles,
    taxExemptFiles,
    setTaxExemptState,
    taxExemptState,
    states,
    countryId,
    setStep,
    step,
    isUserView = true,
    disabled = false,
    saveApplication
  } = props

  const [skipExempt, setSkipExempt] = useState(false)
  const [taxExemptOpen, setTaxExemptOpen] = useState(false)
  const isUSA = countryId === USCountryID

  const [deletingFileState, setDeletingFileState] = useState(false)

  const deleteFileState = (id) => {
    const url = `${API_URL}/files/${id}`
    setDeletingFileState(true)
    axios.delete(url, getAxiosConfig())
      .then((response) => {
        if (response.status === 200) {

          let newFiles = [...taxExemptFiles[taxExemptState]]
          let fileIndex = newFiles.findIndex((f) => f.id === id)
          newFiles.splice(fileIndex, 1)

          let newStates = {...taxExemptFiles}
          newStates[taxExemptState] = [...newFiles]

          setTaxExemptFiles({...newStates})
        }
        setDeletingFileState(false)
      })
      .catch((err) => {
        // Silent error
        console.log(err)
        setDeletingFileState(false)
        throw err
      })
  }

  const deleteFilesState = (id) => {
    const url = `${API_URL}/files`
    setDeletingFileState(true)
    axios.delete(url, {...getAxiosConfig(), data: {files: [...taxExemptFiles[id].map((f) => f.id)]}})
      .then((response) => {
        if (response.status === 200) {
          let newFiles = {...taxExemptFiles}
          delete newFiles[id]
          setTaxExemptFiles(newFiles)
        }
        setDeletingFileState(false)
      })
      .catch((err) => {
        // Silent error
        console.log(err)
        setDeletingFileState(false)
        throw err
      })
  }

  return <>
    <FormikContext.Provider value={{disabled}}>

      <Grid item container spacing={2} style={{position: 'relative'}}>
        <DisableOverlay disabled={!isUSA}/>
        <TaxExemptFormDialog
          open={taxExemptOpen}
          setOpen={setTaxExemptOpen}
          files={taxExemptFiles}
          setFiles={setTaxExemptFiles}
          selectedState={taxExemptState}
          setSelectedState={setTaxExemptState}
          states={states}
          deletingFile={deletingFileState}
          deleteFile={deleteFileState}
        />

        <Grid item xs={12}>
          <Typography variant="h5" style={{fontWeight: 700, marginTop: 16}}>
            Tax Exempt
          </Typography>
        </Grid>

        {isUserView && <Grid item xs={12}>
          <Box>
            <p>
              Simply upload your form(s) here for all states in which you are registered.<br/>
              Yes, it's that easy.
            </p>
          </Box>
        </Grid>}

        {Object.keys(taxExemptFiles).length !== 0 && <Grid item xs={12}>
          <List dense={true}>
            {Object.keys(taxExemptFiles).map((state, i) => {

              if (taxExemptFiles[state].length === 0) return <></>
              return <ListItem>
                <ListItemText style={{paddingRight: 16}}
                  // eslint-disable-next-line
                              primary={<b>{`${states.find((s) => s.id == state).name} Tax Exempt Form`}</b>}
                />
                <ListItemSecondaryAction>
                  <Link to={'#'} style={{cursor: 'pointer', opacity: deletingFileState ? 0.3 : 1}} onClick={() => {
                    if (!deletingFileState) {
                      deleteFilesState(state)
                    }
                  }}> <b>Remove</b> </Link>
                </ListItemSecondaryAction>
              </ListItem>
            })}
          </List>
        </Grid>}

        <Grid item xs={12} md={7}>
          <Button variant='outlined' size='large' fullWidth disabled={skipExempt} onClick={() => {
            if (!skipExempt) setTaxExemptOpen(true)
          }}>Find Example Form & Upload</Button>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={skipExempt}
                onClick={(e) => setSkipExempt(!skipExempt)}
              />
            }
            label={'Skip this for now'}
          />
        </Grid>

        {isUserView && <Grid item xs={12}>
          Questions? Give us a call at <b>1-800-523-1200</b> or email us at <b>newaccounts@fsco.com</b>
        </Grid>}


        <Box mt={12}/>

        {setStep && <Grid item container spacing={2} justify='center' alignContent='center' alignItems='flex-start'>

          <Grid item xs={12} md={6}>
            {step !== 1 && <><Button size='large' variant={'outlined'} fullWidth
                                     onClick={() => setStep(step - 1)}>GO BACK</Button> </>}
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Button size='large' variant={'contained'} color={'secondary'} fullWidth type='submit'
                        disabled={Object.keys(taxExemptFiles).length === 0 && !skipExempt} onClick={() => {
                  setStep(step + 1)
                }}> NEXT </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  size="large"
                  variant={"outlined"}
                  fullWidth
                  onClick={saveApplication}
                  disabled={Object.keys(taxExemptFiles).length === 0 && !skipExempt}
                >
                  {" "}
                  {"SAVE APPLICATION"}
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </Grid>}

      </Grid>

    </FormikContext.Provider>
  </>
}
