import {useState, useEffect, useRef, useCallback} from "react";

export const StickySub = (defaultSticky = false,withHeader=false,offset=220) => {
    const [isStickySub, setIsStickySub] = useState(defaultSticky);
    const [headerShown,setHeaderShown] = useState(false)
    const ref = useRef(null);

    const [y, setY] = useState(window.scrollY);

    const handleNavigation = useCallback(
        e => {
            const window = e.currentTarget;
            if (ref.current)
                setIsStickySub(ref.current.getBoundingClientRect().top <= 0 && !headerShown);
            if (y > window.scrollY && window.pageYOffset > offset) {
                setHeaderShown(true)
            } else if (y < window.scrollY) {
                setHeaderShown(false)
            }
            setY(window.scrollY);
            //eslint-disable-next-line
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);


    return {ref, isStickySub,headerShown};
};


export const StickyHeader = (defaultSticky = false,offset=220) => {
    const [isStickySub, setIsStickySub] = useState(defaultSticky);
    const ref = useRef(null);
    const [y, setY] = useState(window.scrollY);

    const handleNavigation = useCallback(
        e => {
            const window = e.currentTarget;
            if (y > window.scrollY && window.pageYOffset > offset) {
                setIsStickySub(true)
            } else if (y < window.scrollY) {
                setIsStickySub(false)
            }
            setY(window.scrollY);
            //eslint-disable-next-line
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    return {ref, isStickySub};
};